



import { Component, Vue } from 'vue-property-decorator';

interface Window {
  webkitURL: any;
  URL: any;
}

declare let window: Window;

@Component
export default class PlayGround extends Vue {}
