






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import SettingsInfoModule from '@/store/SettingsInfo';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import Label from '@/components/atoms/labels/Label.vue';

@Component({
  components: {
    BaseTable,
    Label
  }
})
export default class LabelsTable extends Vue {
  selected: any = [];

  @Prop()
  value: any;

  get labels() {
    return SettingsInfoModule.labels;
  }

  /**
   * すべてのチェックボックスが checked かどうかを返す
   */
  get areAllChecked(): boolean {
    if (this.selected.length === 0) return false;
    return this.selected.length === this.labels.length;
  }

  /**
   * すべての項目のチェックボックスの checked を付けたり外したりする
   */
  changeCheckedAll(): void {
    if (this.areAllChecked) {
      this.selected = [];
      return;
    }
    this.selected = this.labels;
  }

  @Watch('value')
  update_value(new_value) {
    this.selected = new_value;
  }

  @Watch('selected')
  update_selected(new_value) {
    this.$emit('input', new_value);
  }
}
