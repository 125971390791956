



































































































import { Component, Vue } from 'vue-property-decorator';
import { CreateAccountInterface } from '@/interfaces/Account';
import moment from 'moment';

import AppButton from '@/components/atoms/buttons/AppButton.vue';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import vLoading from 'vue-loading-overlay';

import SERVICE_PLANS from '@/consts/ServicePlans';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import notify from '@/functions/notify';
import UserInfoModule from '@/store/UserInfo';
import { dateFormatter } from '@/functions/FormatDate';

const AccountRepository = RepositoryFactory.get('accounts');

@Component({
  components: {
    AppButton,
    BaseTable,
    BaseWindow,
    TextOnlyWindow,
    SelectBox,
    vLoading
  }
})
export default class Accounts extends Vue {
  accounts: CreateAccountInterface[] = [];

  selected: any = [];

  isShowingChangePlan: boolean = false;
  isShowingStopService: boolean = false;
  changeTargetPlan: any = null;
  isLoading: boolean = false;

  get isSelected(): boolean {
    return this.selected.length > 0;
  }

  /**
   * すべてのチェックボックスが checked かどうかを返す
   */
  get areAllChecked(): boolean {
    if (this.selected.length === 0) return false;
    return this.selected.length === this.accounts.length;
  }

  /**
   * すべての項目のチェックボックスの checked を付けたり外したりする
   */
  changeCheckedAll(): void {
    if (this.areAllChecked) {
      this.selected = [];
      return;
    }
    this.selected = this.accounts;
  }

  get servicePlans() {
    return Object.entries(SERVICE_PLANS).map(([key, val]) => {
      return {
        key,
        service_plan_id: val.service_plan_id,
        service_plan: val.service_plan,
        sort_order: val.sort_order
      };
    });
  }

  formatDate(date) {
    return dateFormatter(date);
  }

  async created() {
    await this.fetch();
  }

  async fetch() {
    this.isLoading = true;
    const idToken = await UserInfoModule.getIdToken();

    await AccountRepository.list(idToken)
      .then(res => {
        this.accounts = res.data;
      })
      .catch(e => {
        notify.error({ text: '企業様一覧の取得に失敗しました。' });
      });
    this.isLoading = false;
  }

  getServicePlanByName(planName) {
    for (const plan in SERVICE_PLANS) {
      if (planName === SERVICE_PLANS[plan].service_plan) {
        return SERVICE_PLANS[plan];
      }
    }
  }

  openChangePlan() {
    this.changeTargetPlan = this.getServicePlanByName(
      this.selected[0].service_plan
    );
    this.isShowingChangePlan = true;
  }

  async clickChangePlan() {
    this.isLoading = true;
    const idToken = await UserInfoModule.getIdToken();

    await AccountRepository.update(
      {
        corporation_id: this.selected[0].corporation_id,
        service_plan_id: this.changeTargetPlan.service_plan_id
      },
      idToken
    )
      .then(() => {
        notify.success({ text: 'サービスプランを変更しました' });
      })
      .catch(e => {
        notify.error({ text: 'サービスプランの変更に失敗しました。' });
      });

    await this.fetch();

    this.isLoading = false;
    this.selected = [];
    this.closeChangePlan();
  }

  closeChangePlan() {
    this.changeTargetPlan = null;
    this.isShowingChangePlan = false;
  }

  openStopService() {
    this.isShowingStopService = true;
  }

  async stopService() {
    this.isLoading = true;

    const idToken = await UserInfoModule.getIdToken();
    const payterms = moment()
      .subtract(1, 'days')
      .format('YYYY-MM-DD');

    await Promise.all(
      this.selected.map(async corporation => {
        await AccountRepository.update(
          {
            corporation_id: corporation.corporation_id,
            payterms: payterms
          },
          idToken
        );
      })
    )
      .then(() => {
        notify.success({ text: 'サービスの利用を停止しました' });
      })
      .catch(e => {
        notify.error({ text: 'サービス利用停止に失敗しました。' });
      });

    await this.fetch();

    this.isLoading = false;

    this.selected = [];
    this.closeStopService();
  }

  closeStopService() {
    this.isShowingStopService = false;
  }
}
