


















































import { Component, Prop, Vue } from 'vue-property-decorator';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import ClickOutside from 'vue-click-outside';
dayjs.locale('ja');

const titleTypes = {
  comment_no_mention: {
    type: -1,
    suffix: 'にコメントしました。'
  },
  comment: {
    type: 0,
    suffix: 'にコメントしてあなたをメンションしました。'
  },
  check: {
    type: 1,
    suffix: 'の確認をあなたに依頼しました。'
  },
  create: {
    type: 2,
    suffix: 'の作成をあなたに依頼しました。'
  },
  change_status: {
    type: 3,
    suffix: 'の締結ステータスを変更しました。'
  },
  assign: {
    type: 4,
    suffix: 'の担当者をあなたに割り当てました。'
  },
  alert_need_to_check_contract: {
    type: 5,
    suffix:
      'の<font color="#cc1414">自動更新</font>または<font color="#cc1414">契約終了</font>の確認が必要です。'
  },
  alert_contract_expiration: {
    type: 6,
    suffix: 'は契約終了まで<font color="#cc1414">0日</font>を切りました。'
  },
  alert_contract_attrtibute: {
    type: 7,
    suffix: '{attribute_key}まで<font color="#cc1414">0日</font>を切りました。'
  }
};

@Component({
  components: {
    TextAvatar,
    ImageContent
  },
  directives: {
    ClickOutside
  }
})
export default class NotificationMenu extends Vue {
  @Prop({ default: [] })
  items: any[];

  @Prop()
  userName!: string;

  @Prop({ default: false })
  isDisplayMenu: boolean;

  getLink(item) {
    return {
      path: `/document-detail/${item.contract_id}`
    };
  }

  get UnreadNum() {
    let unread_counter = 0;
    for (const i in this.items) {
      if (!this.items[i].is_read) {
        unread_counter += 1;
      }
    }
    if (unread_counter > 99) {
      unread_counter = 99;
    }
    return unread_counter;
  }

  cleaningComment(item) {
    let comment = item.comment;
    if (comment) {
      comment = comment.replace(/@{[a-z0-9]+}/, '');
    }
    return comment.replace(/^\s+/, '');
  }

  isMention(item) {
    return item.action_number === titleTypes.comment.type;
  }

  isNoMentionComment(item) {
    return item.action_number === titleTypes.comment_no_mention.type;
  }

  isAlert(item) {
    return item.action_number > titleTypes.assign.type;
  }

  isExpirationAlert(item) {
    if (
      item.action_number === titleTypes.alert_contract_expiration.type ||
      item.action_number === titleTypes.alert_contract_attrtibute.type
    ) {
      return true;
    }
    return false;
  }

  getTitle(item) {
    /**
     * @type {Array}
     * [
     *  "承認",
     *  {
     *    type: "approved",
     *    suffix: "を承認しました。"
     *  }
     * ]
     */
    const itemType = Object.values(titleTypes).find(
      title => title.type === item.action_number
    );
    if (!this.isAlert(item)) {
      return `<strong>${item.user_name}</strong> さんが「<strong>${item.contract_title}</strong>」${itemType.suffix}`;
    } else {
      if (this.isExpirationAlert(item)) {
        let text = itemType.suffix;
        if (item.expiration_limit_days != null) {
          text = text.replace('0', String(item.expiration_limit_days));
          if (item.expiration_limit_days === 0) {
            text = text.replace(
              'は契約終了まで<font color="#cc1414">0日</font>を切りました。',
              'は<font color="#cc1414">本日</font>が契約終了日です。'
            );
          }
        }
        if (item.attribute_key) {
          text = text.replace('{attribute_key}', item.attribute_key);
        }
        return `「<strong>${item.contract_title}</strong>」${text}`;
      } else {
        return `「<strong>${item.contract_title}</strong>」${itemType.suffix}`;
      }
    }
  }

  getStatusText(status) {
    return `${status.before} → ${status.after}`;
  }

  getDate(item) {
    const itemDate = dayjs(item);
    const today = dayjs();

    // dayjs の日数の diff は 24 時間単位で測るため、日付単位に戻す必要がある
    const diffDay = dayjs(today.format('YYYYMMDD')).diff(
      dayjs(itemDate.format('YYYYMMDD')),
      'day'
    );
    if (diffDay === 0) {
      return `今日 ${dayjs(item).format('HH:mm')}`;
    }
    if (diffDay === 1) {
      return `昨日 ${dayjs(item).format('HH:mm')}`;
    }
    if (diffDay < 7) {
      return `${diffDay}日前 ${dayjs(item).format('HH:mm')}`;
    }
    return dayjs(item).format('YYYY/MM/DD');
  }
}
