const LABELS = {
  COLORS: [
    {
      background_color: '#EA3356',
      font_color: '#FFFFFF'
    },
    {
      background_color: '#E6751C',
      font_color: '#FFFFFF'
    },
    {
      background_color: '#009E73',
      font_color: '#FFFFFF'
    },
    {
      background_color: '#0D7CDB',
      font_color: '#FFFFFF'
    },
    {
      background_color: '#723F00',
      font_color: '#FFFFFF'
    },
    {
      background_color: '#2E2E2E',
      font_color: '#FFFFFF'
    },
    {
      background_color: '#F2AA08',
      font_color: '#000000'
    },
    {
      background_color: '#EFE343',
      font_color: '#000000'
    },
    {
      background_color: '#81F86B',
      font_color: '#000000'
    },
    {
      background_color: '#7DCDFA',
      font_color: '#000000'
    },
    {
      background_color: '#FF8ECC',
      font_color: '#000000'
    },
    {
      background_color: '#D8D8D8',
      font_color: '#000000'
    }
  ]
};

export default LABELS;
