

















































































import { Component, Vue } from 'vue-property-decorator';
import UserInfoModule from '@/store/UserInfo';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
const axios = require('axios').default;
import TextFormName from '@/components/atoms/texts/TextFormName.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import notify from '@/functions/notify';
import KEY_CODE from '@/consts/KeyCode';
import NOTIFY_TEXT from '../../consts/NotifyText';
import {
  validateCorporateTelNumber,
  validateCorporateFaxNumber,
  validateEmail
} from '@/functions/Validation';

interface Business {
  business_category_id: string;
  business_category_name: string;
}

interface Employee {
  employee_count_id: string;
  employee_count_name: string;
}

@Component({
  components: {
    FormTitle,
    AppButton,
    SelectBox,
    TextFormName,
    TextForm
  }
})
export default class CorporateInformationForm extends Vue {
  corporate_name_error_message: string = '';
  corporate_name_furigana_error_message: string = '';
  zipcode_error_message: string = '';
  street_address_error_message: string = '';
  corporate_tel_error_message: string = '';
  corporate_fax_error_message: string = '';

  email_address: string = '';
  email_error_message: string = '';
  corporate_name: string = '';
  corporate_name_furigana: string = '';
  zipcode: string = '';
  street_address: string = '';
  phone_number: string = '';
  fax_number: string = '';
  business: Business;
  employees: Employee;

  business_options: Business[] = [];
  employees_options: Employee[] = [];

  mounted() {
    this.get_business_options();
    this.get_employees_options();
    // 管理者のみ表示
    if (!UserInfoModule.isAdmin) this.$router.push({ name: 'login' });
  }

  get buttonEnabled(): boolean {
    return (
      !!this.email_address &&
      !!this.corporate_name &&
      !!this.corporate_name_furigana &&
      !!this.zipcode &&
      !!this.street_address &&
      !!this.phone_number
    );
  }

  get_business_options() {
    const url = '/user-management/accounts/business_categories';
    axios.get(url).then(({ data }) => {
      this.business_options = data;
    });
  }

  get_employees_options() {
    const url = '/user-management/accounts/employee_counts';
    axios.get(url).then(({ data }) => {
      this.employees_options = data;
    });
  }

  set_selected_business(selected: string) {
    this.business = this.business_options.find(({ business_category_name }) => {
      return business_category_name === selected;
    });
  }

  set_selected_employees(selected: string) {
    this.employees = this.employees_options.find(({ employee_count_name }) => {
      return employee_count_name === selected;
    });
  }

  register_corporate_info() {
    let error: boolean = false;

    this.email_error_message = validateEmail(this.email_address);
    if (this.email_error_message) {
      error = true;
    }

    if (this.zipcode.length < 7) {
      this.zipcode_error_message = '郵便番号は半角数字７桁で入力してください。';
      error = true;
    }

    // 電話番号とFAXはの全角数字はすべて半角に変換する
    this.phone_number = this.phone_number.replace(/[０-９]/g, s =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );
    this.fax_number = this.fax_number.replace(/[０-９]/g, s =>
      String.fromCharCode(s.charCodeAt(0) - 0xfee0)
    );

    // 電話番号のバリデーション
    this.corporate_tel_error_message = validateCorporateTelNumber(
      this.phone_number
    );
    if (this.corporate_tel_error_message) {
      error = true;
    }

    // FAXのバリデーション
    this.corporate_fax_error_message = validateCorporateFaxNumber(
      this.fax_number
    );
    if (this.corporate_fax_error_message) {
      error = true;
    }

    if (error) return;

    const url = `user-management/accounts`;
    const request_data = {
      email: this.email_address,
      corporation_name: this.corporate_name,
      furigana: this.corporate_name_furigana,
      postal_code: this.zipcode,
      address: this.street_address,
      phone_number: this.phone_number,
      fax: this.fax_number,
      employee_count: this.employees,
      business_category: this.business
    };

    axios
      .post(url, request_data)
      .then(response => {
        // 成功なら画面移動
        this.$router.push({ name: 'corporate_information_created' });
      })
      .catch(err => {
        if (err.response) {
          const res = err.response.data;
          notify.error({
            text: res['description']
          });
        } else {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
        }
      });
  }

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER && this.buttonEnabled) {
      this.register_corporate_info();
    }
  }
}
