
















import dayjs from 'dayjs';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class VersionContent extends Vue {
  @Prop({}) version;
  @Prop({ default: false, type: Boolean }) selected: boolean;

  get lastUpdateDate() {
    if (!this.version.last_updated_at) {
      return '';
    }
    return dayjs(this.version.last_updated_at).format('YYYY/MM/DD HH:mm');
  }
}
