

















import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';

@Component
export default class TextFormFlexibleWidthForNumber extends Vue {
  @PropSync('numbers', { default: '' })
  value: any;

  @Prop({ default: '' })
  name!: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: '' })
  maxlength?: string;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ type: Object as () => {} })
  classes?: {};

  @Prop({ default: '' })
  width?: string;

  @Prop({ default: '8em' })
  minWidth?: string;

  @Prop({ default: '100%' })
  maxWidth?: string;

  @Prop({ default: '' })
  errorMessage?: string;

  @Prop({ default: 'left' })
  textAlign?: string;

  type: string = 'text';

  get styleObject() {
    const styles = {
      minWidth: this.minWidth,
      maxWidth: this.maxWidth,
      textAlign: this.textAlign
    };
    if (this.width) {
      styles['width'] = this.width;
    }
    return styles;
  }

  @Watch('value')
  validMaxLength(val) {
    if (this.maxlength && val && val.length > Number(this.maxlength)) {
      val = this.value.slice(0, Number(this.maxlength));
    }
    this.value = val;
    this.addComma();
  }

  mounted() {
    this.adjustHeight();
  }

  blur($event) {
    this.type = 'text';
    if (typeof this.value == 'string') {
      if (this.value) {
        this.$emit('blur', $event, {
          [this.name]: parseInt(this.value.replace(/,/g, ''), 10)
        });
      } else {
        this.$emit('blur', $event, {
          [this.name]: this.value
        });
      }
    } else {
      this.$emit('blur', $event, {
        [this.name]: this.value
      });
    }
    this.addComma();
  }

  focus($event) {
    this.type = 'number';
    if (typeof this.value == 'string') {
      if (this.value) {
        this.value = parseInt(this.value.replace(/,/g, ''), 10);
      }
    }
    this.$emit('focus', $event, {
      [this.name]: this.value
    });
  }

  addComma() {
    if (this.type === 'text' && Number(this.value) > 0) {
      this.value = Number(this.value).toLocaleString();
    }
  }

  adjustHeight() {
    return;
  }
}
