








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

@Component({
  components: {
    BaseWindow,
    TextForm,
    AppButton
  }
})
export default class SingleInputFormWindow extends Vue {
  @Prop()
  value!: boolean;

  @Prop({ default: '' })
  label?: string;

  @Prop({ default: '' })
  text?: string;

  @Prop()
  windowSize!: string;

  @Prop()
  title!: string;

  @Prop()
  description?: string;

  @Prop()
  type?: string;

  @Prop()
  message?: string;

  @Prop({ default: '作成' })
  mainBtnLabel: string;

  @Prop({ default: 'primary' })
  mainBtnColorClass: string;

  @Prop({ default: 'small' })
  mainBtnSizeClass: string;

  @Prop({ default: 96 })
  mainBtnWidth: number;

  @Prop({ default: 'キャンセル' })
  subBtnLabel: string;

  @Prop({ default: 'tertiary' })
  subBtnColorClass: string;

  @Prop({ default: 'small' })
  subBtnSizeClass: string;

  @Prop({ default: 96 })
  subBtnWidth: number;

  @Prop({ default: false })
  mainButtonDisabled: boolean;

  inputValue: string = this.text;

  @Watch('value')
  changeOpen(newVal, oldVal) {
    // open
    if (newVal && !oldVal) this.inputValue = this.text;
    // close
    if (!newVal && oldVal) this.inputValue = '';
  }

  changeWindowStatus(val) {
    this.inputValue = '';
    this.$emit('changeWindowStatus');
  }

  clickMainBtn() {
    if (!this.checkDisabled) {
      this.$emit('clickMainBtn', this.inputValue.trim());
    }
  }

  get isOpen(): boolean {
    return this.value;
  }
  set isOpen(val) {
    if (!this.inputValue) true;
  }

  get checkDisabled(): boolean {
    return (
      !this.inputValue ||
      !this.inputValue.match(/\S/g) ||
      this.mainButtonDisabled
    );
  }
}
