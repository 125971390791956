










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CheckCircle extends Vue {
  @Prop({ default: '80px' })
  height?: string;
  @Prop({ default: '#000000' })
  color?: string;

  get icon_style() {
    return {
      color: this.color
    };
  }
}
