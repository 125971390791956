





























import { Component, Vue, PropSync, Prop, Watch } from 'vue-property-decorator';

@Component
export default class AttachDocumentCard extends Vue {
  @PropSync('attachFileList', { type: Array, default: [] })
  fileList: File[];
  @Prop()
  showAttachDocsDialog: boolean;
  @Prop()
  canUploadAttachments: boolean;

  @Watch('showAttachDocsDialog')
  reset_fileList() {
    if (!this.showAttachDocsDialog) {
      this.fileList = [];
    }
  }

  upload(event) {
    const files: File[] = event.target.files || event.dataTransfer.files;
    if (files.length === 0) {
      // ファイル添付をキャンセルした場合
      return;
    }

    files.forEach(file => {
      this.fileList.push(file);
    });

    const element: HTMLInputElement = document.getElementById(
      'inputFile'
    ) as HTMLInputElement;
    element.value = '';
  }

  cancel(index: number) {
    this.fileList = this.fileList.filter((file, i) => i !== index);
  }
}
