





































import { Component, Vue } from 'vue-property-decorator';

import AppButton from '@/components/atoms/buttons/AppButton.vue';

@Component({
  components: {
    AppButton
  }
})
export default class GMOSignFormCompleted extends Vue {
  contractId = null;

  async created() {
    this.contractId = this.$route.query.id;
    history.pushState(null, null, location.href);
    window.addEventListener('popstate', e => {
      history.go(1);
    });
  }

  destroyed() {
    window.removeEventListener('popstate', e => {
      history.go(1);
    });
  }

  backDocumentDetail() {
    this.$router.push({
      name: 'document_detail',
      params: {
        id: this.contractId
      }
    });
  }

  goToGMO() {
    const url = 'https://secure.gmosign.com/top/';
    window.open(url, '_blank', 'noreferrer');
  }
}
