










import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Icon from '@/components/atoms/icons/Icon.vue';

@Component({
  components: {
    Icon
  }
})
export default class PasswordCheckItem extends Vue {
  color_name: string = '#b3b3b3';
  @Prop({ default: '' })
  text?: string;
  @Prop({ default: false })
  is_pw_good?: boolean;

  created() {
    this.color_name = '#b3b3b3';
  }

  @Watch('is_pw_good')
  change_icon_color() {
    if (this.is_pw_good) {
      this.color_name = '#1452cc';
    } else {
      this.color_name = '#b3b3b3';
    }
  }
}
