const axios = require('axios').default;

const resource = '/contract-management';

type UploadNewVersionParams = {
  contract_id: string;
  is_last_version: boolean;
  is_stored: boolean;
  formData: Blob;
};

type UploadNewContractedVersionParams = {
  contract_id: string;
  formData: Blob;
};

type SaveViewPermissionParams = {
  contract_id_list: [string];
  directory_path_list: [string];
  directory_id_list: [string];
  department_id_list: [string];
  user_id_list: [string];
};

type RenameContractFileNameParams = {
  contract_id: string;
  contract_title: string;
};

type RenameContractFolderNameParams = {
  directory_name: string;
  directory_name_furigana: string;
  directory_path: string;
};

type GetSearchFileAndFolderResultParams = {
  contract_date_end: string;
  contract_date_start: string;
  contract_title: string;
  contract_type_id: string;
  counterparty_name: string;
  current_directory_path: string;
  expiration_end: string;
  expiration_start: string;
  is_all_folder: boolean;
  is_search: boolean;
  keywords: string;
  update_date_end: string;
  update_date_start: string;
};

type RestoreContractFilesParams = {
  auto_update_date: string;
  contract_date: string;
  contract_id: string;
  contract_title: string;
  contract_title_furigana: string;
  counterparty_name: string;
  counterparty_name_furigana: string;
  expiration: string;
  file_extension: string;
  is_auto_extracted: boolean;
  is_none_expiration: boolean;
  is_scheduled_end: boolean;
  is_shared: boolean;
};

type RestoreContractFoldersParams = {
  directory_name: string;
  directory_name_furigana: string;
  directory_path: string;
};

type PostCommentParams = {
  comment: string;
  contract_id: string;
  mention_users: [string];
};

type UpdateCommentParams = {
  comment: string;
  comment_id: string;
  mention_users: [string];
};

type GetTrashesParams = {
  directory_path: string;
  trash_directory_name: string;
  trash_directory_path: string;
  is_signed: boolean;
};

type PutTrashesParams = {
  trash_directory_path: string;
  directory_path: string;
  contract_id_list: [string];
  directory_path_list: [string];
  is_signed: boolean;
};

type DeleteContractParams = {
  auto_update_date: string;
  contract_date: string;
  contract_id: string;
  contract_title: string;
  contract_title_furigana: string;
  counterparty_name: string;
  counterparty_name_furigana: string;
  expiration: string;
  file_extension: string;
  is_auto_extracted: boolean;
  is_none_expiration: boolean;
  is_scheduled_end: boolean;
  is_shared: boolean;
};

type DeleteContractFolder = {
  directory_name: string;
  directory_name_furigana: string;
  directory_path: string;
};

type CreateNewFolderParams = {
  new_directory_path: string;
};

type GetContractFileExtractionParams = {
  formData: Blob;
};

type GetTemplatesParams = {
  current_directory: string;
};

type GetCsvs = {
  directory_id_list: string[];
  contract_id_list: string[];
};

export default {
  /**
   * 新しいバージョンの契約書をアップロード
   *
   * @param { Object } params
   */
  uploadNewVersion(params: UploadNewVersionParams) {
    return axios.post(
      `${resource}/contracts/${params.contract_id}/version/new`,
      params.formData,
      {
        timeout: 1200 * 1000,
        params: {
          is_last_version: params.is_last_version,
          is_stored: params.is_stored
        }
      }
    );
  },

  /**
   * 新しいバージョンの締結済み契約書をアップロード
   *
   * @param { Object } params
   */
  uploadNewContractedVersion(params: UploadNewContractedVersionParams) {
    return axios.post(
      `${resource}/contracts/${params.contract_id}/version/contracted/new`,
      params.formData,
      {
        timeout: 1200 * 1000
      }
    );
  },

  /**
   * バージョン削除
   *
   * @param { String } contractVersionId
   */
  deleteVersion(contractVersionId) {
    return axios.delete(`${resource}/contracts/version/${contractVersionId}`);
  },

  /**
   * 契約書アーカイブ
   *
   * @param { String } contractId
   */
  deleteContract(contractId) {
    return axios.put(
      `${resource}/contracts/move/trashes`,
      [{ contract_id: contractId }],
      {
        params: {
          restore: false
        }
      }
    );
  },

  /**
   * 契約書の閲覧権限更新
   *
   * @param { Object } params
   */
  saveViewPermission(params: SaveViewPermissionParams) {
    return axios.put('/user-management/contracts/permissions', params);
  },

  /**
   * 契約書名変更
   *
   * @param { Object } params
   */
  renameContractFileName(params: RenameContractFileNameParams) {
    return axios.put(`${resource}/contracts/${params.contract_id}/rename`, {
      contract_title: params.contract_title
    });
  },

  /**
   * フォルダ名変更
   *
   * @param { Object } params
   */
  renameContractFolderName(params: RenameContractFolderNameParams) {
    return axios.put(`${resource}/directories/rename`, params);
  },

  /**
   * 通知取得
   */
  getNotifications() {
    return axios.get(`${resource}/notifications/me`);
  },

  /**
   * ファイル、フォルダ情報取得
   *
   * @param { String } currentDirectory
   */
  async getFilesAndFolders(currentDirectory: string) {
    return axios.get(
      `${resource}/contracts/signed/viewable/me?current_directory_path=${currentDirectory}&timestamp=${new Date().getTime()}`
    );
  },

  /**
   * 閲覧可能なフォルダ一覧情報取得
   *
   * @param { String } currentDirectory
   */
  getDirectoryList(currentDirectory: string) {
    return axios.get(
      `${resource}/directories/viewable?current_directory_path=${currentDirectory}`
    );
  },

  /**
   * フォルダ情報取得
   *
   * @param { String } currentDirectory
   */
  getDirectoryInfo(currentDirectory: string) {
    const params = { directory_path_list: [currentDirectory] };
    return axios.post(
      `${resource}/directories/directories/ids/from/path`,
      params
    );
  },

  /**
   * ファイル、フォルダ情報取得
   */
  getRootDirectory() {
    return axios.get(`${resource}/directories/root`);
  },

  /**
   * ファイル、フォルダ検索結果取得
   *
   * @param { Object } params
   */
  getSearchFilesAndFoldersResult(params: GetSearchFileAndFolderResultParams) {
    return axios.post(`${resource}/contracts/signed/viewable/me`, params);
  },

  /**
   * 最近表示ファイル取得
   *
   * @param { Boolean } isSigned
   */
  getRecentlyViewedFiles(isSigned: boolean) {
    return axios.get(`${resource}/contracts/recently?is_signed=${isSigned}`);
  },

  /**
   * ファイル、フォルダ情報取得
   */
  getControlNumbers() {
    return axios.get(`${resource}/contracts/signed/control_number/list`);
  },

  /**
   * 契約書詳細情報取得
   *
   * @param { String } contractId
   */
  getContractDetail(contractId: string) {
    const source = axios.CancelToken.source();

    return axios.get(`${resource}/contracts/${contractId}/detail`, {
      cancelToken: source.token,
      timeout: 300 * 1000
    });
  },

  /**
   * 契約書詳細更新
   *
   * @param { Object } params
   */
  updateContractDetail(params) {
    return axios.put(
      `${resource}/contracts/${params.contract_id}/detail`,
      params
    );
  },

  /**
   * 契約書ラベル一覧情報取得
   */
  getContractLabels() {
    return axios.get(`${resource}/contract_labels`);
  },

  /**
   * 契約書ラベル更新
   */
  updateContractLabels(params) {
    return axios.put(
      `${resource}/contract_labels/${params.contract_id}`,
      params.label_id_list
    );
  },

  /**
   * ファイル削除取り消し
   *
   * @param { Array } files
   */
  restoreContractFiles(files: RestoreContractFilesParams[]) {
    return axios.put(`${resource}/contracts/move/trashes`, files, {
      params: {
        restore: true
      }
    });
  },

  /**
   * フォルダ削除取り消し
   *
   * @param { Array } folders
   */
  restoreContractFolders(folders: RestoreContractFoldersParams[]) {
    return axios.put(`${resource}/directories/move/trash`, folders, {
      params: {
        restore: true
      }
    });
  },

  /**
   * プレビュー取得
   *
   * @param { String } contractVersionId
   */
  getVersionPreviewHtml(
    contractVersionId: string,
    ext: string = 'html',
    is_link: boolean = false
  ) {
    const is_local_request = process.env.VUE_APP_IS_LOCALHOST || false;
    return axios.get(
      `${resource}/contracts/versions/${contractVersionId}?ext=${ext}&is_link=${is_link}&is_local_request=${is_local_request}`,
      { timeout: 5 * 60 * 1000 }
    );
  },

  /**
   * メンション可能ユーザー取得
   *
   * @param { String } contractId
   */
  getMentionUsers(contractId: string) {
    return axios.get(
      `${resource}/comments/contracts/${contractId}/can/mentions`
    );
  },

  /**
   * コメント取得
   *
   * @param { String } contractId
   */
  getComments(contractId) {
    return axios.get(`${resource}/comments/contracts/${contractId}`);
  },

  /**
   * コメント投稿
   *
   * @param { Object } params
   */
  postComment(params: PostCommentParams) {
    return axios.post(`${resource}/comments/contracts/${params.contract_id}`, {
      comment: params.comment,
      mentions: params.mention_users
    });
  },

  /**
   * コメント更新
   *
   * @param { Object } params
   */
  updateComment(params: UpdateCommentParams) {
    return axios.put(`${resource}/comments/${params.comment_id}`, {
      comment: params.comment,
      mentions: params.mention_users
    });
  },

  /**
   * コメント削除
   *
   * @param { String } commentId
   */
  deleteComment(commentId) {
    return axios.delete(`${resource}/comments/${commentId}`);
  },

  /**
   * contractId がない新規依頼作成時にメンション可能ユーザーを取得
   */
  getCanMentionUsers() {
    return axios.post(`${resource}/comments/contracts/can/mentions`, {});
  },

  /**
   * ゴミ箱内ファイル取得
   *
   * @param { Object } params
   */
  getTrashes(params: GetTrashesParams) {
    return axios.get(
      `${resource}/trashes?current_directory_path=${params.directory_path}&current_trash_directory_path=${params.trash_directory_path}&is_signed=${params.is_signed}`
    );
  },

  /**
   * ゴミ箱から復元
   *
   * @param { Object } params
   */
  putTrashes(params: PutTrashesParams) {
    return axios.put(
      `${resource}/trashes?current_directory_path=${params.directory_path}&current_trash_directory_path=${params.trash_directory_path}&is_signed=${params.is_signed}`,
      {
        directory_path_list: params.directory_path_list,
        contract_id_list: params.contract_id_list
      }
    );
  },

  /**
   * ゴミ箱内ファイル削除
   *
   * @param { Object } params
   */
  deleteTrashes(params: PutTrashesParams) {
    return axios.post(
      `${resource}/trashes/delete?current_directory_path=${params.directory_path}&current_trash_directory_path=${params.trash_directory_path}&is_signed=${params.is_signed}`,
      {
        directory_path_list: params.directory_path_list,
        contract_id_list: params.contract_id_list
      }
    );
  },

  /**
   * ゴミ箱内ファイル全削除
   */
  deleteAllTrashes(isSigned: boolean) {
    return axios.post(`${resource}/trashes/all/delete?is_signed=${isSigned}`);
  },

  /**
   * 締結済ドキュメントアップロード
   *
   * @param { Blob } formData
   */
  uploadSignedContract(formData: Blob) {
    return axios.post(`${resource}/contracts/signed`, formData, {
      cancelToken: axios.CancelToken.source().token,
      timeout: 1200 * 1000
    });
  },

  /**
   * 締結済ドキュメントアップロードジョブリスト取得
   *
   * @param { String } groupId
   */
  getSignedContractUploadJobs(groupId: string) {
    return axios.get(
      `${resource}/contracts/signed/upload_jobs?group_id=${groupId}&timestamp=${new Date().getTime()}`
    );
  },

  /**
   * 締結済ドキュメントファイル削除
   *
   * @param { Array } params
   */
  deleteContractFile(params: DeleteContractParams[]) {
    return axios.put(
      `${resource}/contracts/move/trashes?restore=false`,
      params
    );
  },

  /**
   * 締結済ドキュメントフォルダ削除
   *
   * @param { Array } params
   */
  deleteContractFolder(params: DeleteContractFolder[]) {
    return axios.put(
      `${resource}/directories/move/trash?restore=false`,
      params
    );
  },

  /**
   * 締結済ドキュメントフォルダ作成
   *
   * @param { Object } params
   */
  createNewFolder(params: CreateNewFolderParams) {
    return axios.post(`${resource}/directories/contracts/table`, null, {
      params
    });
  },

  /**
   * 締結済ドキュメントダウンロードリンク取得
   * @param {String} contractVersionId
   */
  getDownloadLink(contractVersionId: string) {
    return axios.get(
      `${resource}/contracts/download_link/${contractVersionId}`
    );
  },

  /**
   * 契約種別情報取得
   */
  getContractTypes() {
    return axios.get(`${resource}/contracts/types`);
  },

  /**
   * ファイルの拡張子取得
   *
   * @param { Object } params
   */
  getContractFileExtraction(params: GetContractFileExtractionParams) {
    return axios.post(
      `${resource}/contracts/signflow/extractions/texts`,
      params,
      { headers: { 'content-type': 'multipart/form-data' } }
    );
  },

  /**
   * 契約書類のバックアップ
   *
   * @param contractId
   */
  backupContractFile(contractId: string) {
    return axios.get(`${resource}/contracts/backup/${contractId}`, {
      responseType: 'blob',
      headers: {
        Accept: 'application/zip'
      }
    });
  },

  /**
   * Lirisテンプレート情報取得
   *
   * @param { Object } params
   */
  getLirisTemplates(params: GetTemplatesParams) {
    return axios.get(`${resource}/templates/liris`, {
      params: {
        current_directory: params.current_directory
      }
    });
  },

  /**
   * クライントテンプレート情報取得
   *
   * @param { Object } params
   */
  getClientTemplates(params: GetTemplatesParams) {
    return axios.get(`${resource}/templates/clients`, {
      params: {
        current_directory: params.current_directory
      }
    });
  },

  /**
   * 契約書のリストア
   * @param formData Blob
   */
  restoreContract(formData: Blob) {
    return axios.post(`${resource}/contracts/restore`, formData, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  },

  /**
   * 契約書の閲覧可否を取得
   * @param contractId String
   */
  async getCanViewContract(contractId: string) {
    return axios.get(`${resource}/contracts/${contractId}/can/view`);
  },

  /**
   * ディレクトリ及び複数ファイルをCSV出力
   *
   * @param params GetCsvs
   */
  getCsvs(params: GetCsvs) {
    return axios.post(`${resource}/contracts/output/csvs`, params);
  },

  /**
   * 契約書追加項目を取得
   * contractId を指定すれば values も取得
   *
   * @param { String } contractId
   */
  getContractAttributes(contractId: string = undefined) {
    return axios.get(`${resource}/attributes/corporations`, {
      params: {
        contract_id: contractId
      }
    });
  },

  /**
   * 契約書追加項目を新規作成
   */
  postContractAttributes(params) {
    return axios.post(`${resource}/attributes/corporations`, params);
  },

  /**
   * 契約書追加項目を更新
   */
  updateContractAttributes(params) {
    return axios.put(`${resource}/attributes/corporations`, params);
  },

  /**
   * 契約書追加項目を削除
   */
  deleteContractAttributes(contractAttributeId) {
    return axios.delete(
      `${resource}/attributes/corporations/${contractAttributeId}`
    );
  },

  /**
   * 契約書追加項目のselectオプション行を新規作成
   */
  postContractAttributeItem(params) {
    return axios.post(`${resource}/attributes/items`, params);
  },

  /**
   * 契約書追加項目のselectオプション行を更新
   */
  updateContractAttributeItem(params) {
    return axios.put(`${resource}/attributes/items`, params);
  },

  /**
   * 契約書追加項目の値を新規作成
   */
  postContractAttributeValue(params) {
    return axios.post(`${resource}/attributes/contracts`, params);
  },

  /**
   * 契約書追加項目の値を更新
   */
  updateContractAttributeValue(params) {
    return axios.put(`${resource}/attributes/contracts`, params);
  },

  /**
   * ファイルの処理状態を取得
   */
  getContractFileProcessStatus(contractId) {
    return axios.get(
      `${resource}/contracts/${contractId}/file/process/status?timestamp=${new Date().getTime()}`
    );
  },

  /**
   * 全ファイルデータのダウンロードジョブ開始
   */
  postAllContractDownloadJob() {
    return axios.post(`${resource}/contracts/download/all/files`);
  },

  /**
   * 全ファイルデータのダウンロードジョブ開始
   */
  postSelectedContractDownloadJob(params) {
    return axios.post(`${resource}/contracts/download/multiple/files`, params);
  }
};
