






































import { Component, Vue } from 'vue-property-decorator';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import TextFormWithTitle from '@/components/molecules/text_form_with_title/TextFormWithTitle.vue';
import TextFormWithTitle_PasswordChange from '@/components/molecules/text_form_with_title/TextFormWithTitle_PasswordChange.vue';
import PasswordCheckItem from '@/components/molecules/icon_text/PasswordCheckItem.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import UserInfoModule from '@/store/UserInfo';
import notify from '@/functions/notify';
import KEY_CODE from '@/consts/KeyCode';
import HTTP_STATUS from '@/consts/HttpStatus';
const axios = require('axios').default;

@Component({
  components: {
    FormTitle,
    TextFormWithTitle,
    TextFormWithTitle_PasswordChange,
    PasswordCheckItem,
    AppButton
  }
})
export default class ChangePassword extends Vue {
  pw_length: number = 0;
  pw_has_lower_case: boolean = false;
  pw_has_upper_case: boolean = false;
  pw_has_number: boolean = false;

  errorMessage: string = '';
  button_disabled: boolean = true;

  created() {
    this.check_qualification_of_change_password();
  }

  mounted() {
    this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'user_info/SET_PASSWORD') {
        this.pw_length = UserInfoModule.pw_length;
        this.pw_has_lower_case = UserInfoModule.pw_has_lower_case;
        this.pw_has_upper_case = UserInfoModule.pw_has_upper_case;
        this.pw_has_number = UserInfoModule.pw_has_number;

        if (
          this.pw_length > 7 &&
          this.pw_has_lower_case &&
          this.pw_has_upper_case &&
          this.pw_has_number
        ) {
          this.button_disabled = false;
        } else {
          this.button_disabled = true;
        }
      }
    });
  }

  async check_qualification_of_change_password() {
    if (!UserInfoModule.is_required_changing_password) {
      notify.error({
        text:
          '初期パスワード変更はすでに実施されています。ログイン画面に戻ります。'
      });
      this.$router.push({ name: 'login' });
      return;
    }
  }

  request_change_password() {
    // TODO: 新エンドポイントにしたが、変更ないか確認する
    const url = `user-management/auth/challenges/password`;
    const request = {
      user_id: UserInfoModule.user_id,
      new_password: UserInfoModule.password
    };
    axios
      .put(url, request)
      .then(({ data }) => {
        UserInfoModule.SET_ID_TOKEN(data.id_token);

        this.$router.push({ name: 'first_time_password_change_complete' });
      })
      .catch(err => {
        console.log({ err });

        if (err.response.status === HTTP_STATUS.CONFLICT) {
          this.errorMessage =
            '新しいパスワードの設定は5分以内にお願いいたします。　再度仮パスワードでログイン後、新しいパスワードを設定してください。';
          return;
        }

        this.errorMessage =
          '登録したメールアドレスと同じ、もしくは@マークよりも前の文字と同じパスワードは登録できません。';
      });
  }

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER && !this.button_disabled) {
      this.request_change_password();
    }
  }

  save_text_val(val) {
    UserInfoModule.SET_PASSWORD(val);
  }
}
