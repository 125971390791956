const WINDOW_MESSAGES = {
  CONFIRM_MOVE:
    '移動をクリックすると、移動するファイル・フォルダの閲覧権限設定が、移動先フォルダの閲覧権限設定で上書きされます。<br><br>（移動先のフォルダに閲覧権限設定がない場合は、移動するファイル・フォルダの閲覧権限設定が解除されます。）<br><br>移動しますか？',
  CONFIRM_MOVE_FOR_CONTRACT_MANAGEMENT:
    '移動をクリックすると、移動するファイルの閲覧権限設定が、移動先フォルダの閲覧権限設定で上書きされます。<br><br>（移動先のフォルダに閲覧権限設定がない場合は、移動するファイルの閲覧権限設定が解除されます。）<br><br>移動しますか？',
  BACKUP_DESCRIPTION:
    'バックアップを行うと、パスワードロック付きのZipファイルがダウンロードされます。<br>このZipファイルは、内容の改ざん防止の為、パスワードの入力による解凍はできません。<br><br>オーナーまたは管理者権限のユーザー様が、「ファイルの復元」ページから、ダウンロードされたZipファイルをそのままアップロードすることで、契約書データを復元することができます。',
  DELETE_GMOSIGN: `
    GMOサインにアップロードしたファイルを削除します。署名依頼中、電子署名後のファイルであっても削除されます。
    削除してもよろしいですか？
  `
};

export default WINDOW_MESSAGES;
