















































import { Component, Prop, Vue } from 'vue-property-decorator';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import ClickOutside from 'vue-click-outside';
dayjs.locale('ja');
import { LirisNotificationList } from '@/interfaces/LirisNotification';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import NOTIFY_TEXT from '@/consts/NotifyText';

import notify from '@/functions/notify';
import FileDownload from '@/functions/FileDownload';

const LirisNotificationRepository = RepositoryFactory.get('lirisNotifications');

@Component({
  components: {},
  directives: {
    ClickOutside
  }
})
export default class LirisNotificationMenu extends Vue {
  @Prop({ default: [] })
  items: any[];

  @Prop({ default: false })
  isDisplayMenu: boolean;

  changeRead(item) {
    if (item.liris_notification_id) {
      LirisNotificationRepository.read(item.liris_notification_id);
      item.is_read = true;
    }
  }

  get UnreadNum() {
    let unread_counter = 0;
    for (const i in this.items) {
      if (!this.items[i].is_read) {
        unread_counter += 1;
      }
    }
    if (unread_counter > 99) {
      unread_counter = 99;
    }
    return unread_counter;
  }

  getDate(item) {
    const itemDate = dayjs(item);
    const today = dayjs();

    // dayjs の日数の diff は 24 時間単位で測るため、日付単位に戻す必要がある
    const diffDay = dayjs(today.format('YYYYMMDD')).diff(
      dayjs(itemDate.format('YYYYMMDD')),
      'day'
    );
    if (diffDay === 0) {
      return `今日 ${dayjs(item).format('HH:mm')}`;
    }
    if (diffDay === 1) {
      return `昨日 ${dayjs(item).format('HH:mm')}`;
    }
    if (diffDay < 7) {
      return `${diffDay}日前 ${dayjs(item).format('HH:mm')}`;
    }
    return dayjs(item).format('YYYY/MM/DD');
  }

  onOpenUrl(item: LirisNotificationList) {
    // アップデートは遷移時に既読にする
    window.open(item.url, '_blank', 'noopener=yes,noreferrer=yes');
  }

  onClickFileDownLoadLink(item: LirisNotificationList) {
    const result = FileDownload.downloadLirisNotificationFile(
      item.liris_notification_id,
      item.filename
    );
    if (!result) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.GENERAL
      });
    }
    return;
  }
}
