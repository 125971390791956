







import { Component, Vue } from 'vue-property-decorator';
import CommentList from '@/components/organisms/DocumentDetail/CommentList.vue';
import Documents from '@/store/Documents';

@Component({
  components: {
    CommentList
  }
})
export default class DocumentPreviewSidebarComment extends Vue {
  created() {
    Documents.SET_IS_ONLY_COMMENT(true);
  }
}
