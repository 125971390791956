import { render, staticRenderFns } from "./IconContractCreate.vue?vue&type=template&id=5c74f4ff&scoped=true&"
var script = {}
import style0 from "./IconContractCreate.vue?vue&type=style&index=0&id=5c74f4ff&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c74f4ff",
  null
  
)

export default component.exports