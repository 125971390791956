









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class SendEmail extends Vue {
  @Prop({ default: '64px' })
  height?: string;
}
