























































import { Component, Vue, Watch } from 'vue-property-decorator';

import notify from '@/functions/notify';
import NOTIFY_TEXT from '@/consts/NotifyText';

import AppNotifications from '@/components/atoms/AppNotifications.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import GMOSignInfoModule from '@/store/GMOSign';
import DocumentsModule from '@/store/Documents';

@Component({
  components: {
    AppButton,
    AppNotifications,
    ImageContent,
    TextOnlyWindow
  }
})
export default class GMOSignPage extends Vue {
  headerHeight: number = 0;
  isDroppedHeader: boolean = false;
  contractId = null;
  isOpenConfirmModalWindow = false;
  progressItems = [
    {
      label: '契約書情報入力',
      pathName: 'gmosign_form_contract'
    },
    {
      label: '署名情報入力',
      pathName: 'gmosign_form_signer'
    },
    {
      label: '署名順の設定',
      pathName: 'gmosign_form_sign_order'
    },
    {
      label: '署名位置の指定',
      pathName: 'gmosign_form_sign_position'
    },
    {
      label: '送信完了',
      pathName: 'gmosign_form_completed'
    }
  ];

  @Watch('isDroppedHeader')
  notifyNoDropZone() {
    if (this.isDroppedHeader === true) {
      notify.success({
        text: NOTIFY_TEXT.SUCCESS.NO_DROP_ZONE
      });
    }
  }

  async created() {
    this.contractId = this.$route.query.id;
    // await GMOSignInfoModule.getFormData(this.contractId);
    window.addEventListener('beforeunload', this.confirm);
  }

  mounted() {
    this.setHeaderHeight();
  }

  beforeRouteLeave(to, from, next) {
    if (
      to.fullPath.includes('confirm=true') ||
      from.fullPath.includes('/gmosign/form/completed')
    ) {
      next();
    } else {
      this.isOpenConfirmModalWindow = true;
    }
  }

  beforeDestroy() {
    this.isOpenConfirmModalWindow = true;
  }

  destroyed() {
    GMOSignInfoModule.RESET_SIGNFORM();
    // GMOSignInfoModule.deleteFormData(this.contractId);
    window.removeEventListener('beforeunload', this.confirm);
    Vue.cookies.remove('pdf_link');
  }

  get pdfLink() {
    let link = '';
    if (DocumentsModule.previewPdfLink) {
      link = DocumentsModule.previewPdfLink;
    }
    if (Vue.cookies.get('pdf_link')) {
      link = Vue.cookies.get('pdf_link').toString();
    }
    return link;
  }

  confirm(event) {
    // GMOSignInfoModule.saveFormData(this.contractId);
    event.preventDefault();
    return (event.returnValue = '編集中の内容は破棄されます。');
  }

  openConfirmModal() {
    if (this.$route.path.includes('/gmosign/form/completed')) {
      this.closeGMOSignPage();
      return;
    }
    this.isOpenConfirmModalWindow = true;
  }

  get isShowingPrevButton() {
    let index = 0;
    this.progressItems.forEach((val, i) => {
      if (val.pathName === this.$route.name) {
        index = i;
      }
    });
    if (index === 0 || index === this.progressItems.length - 1) {
      return false;
    }
    return true;
  }

  setHeaderHeight() {
    const el = document
      .getElementById('gmosign-header')
      .getBoundingClientRect();
    this.headerHeight = el.height;
  }

  prevStep() {
    let index = 0;
    this.progressItems.forEach((val, i) => {
      if (val.pathName === this.$route.name) {
        index = i - 1;
      }
    });
    this.$router.push({
      name: this.progressItems[index].pathName,
      query: {
        id: this.contractId
      }
    });
  }

  closeGMOSignPage() {
    this.$router.replace({
      path: `/document-detail/${this.contractId}`,
      query: {
        confirm: 'true'
      }
    });
  }
}
