






























import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import { change_folder } from '@/functions/DocumentRouter';

@Component({
  components: {},
  directives: {
    ClickOutside
  }
})
export default class MakeBreadCrumb extends Vue {
  @Prop({ default: [{ name: '締結済ドキュメント', path: '/' }] })
  folders: object[];

  @Prop({ default: 'home' })
  root?: string;

  @Prop({ default: () => ({ left: '190px' }) })
  omit_style?: object[];

  display_folders: object[] = [];

  omitted_folders: object[] = [];

  is_omitted: boolean = false;

  is_display_omitted_folders: boolean = false;

  created() {
    this.display_folders = this.folders;
  }

  mounted() {
    this.update_folders(this.folders);
  }

  change_folder_table(path: string) {
    if (this.display_folders.length <= 1) {
      return;
    }
    if (this.root === 'template') {
      this.$router.push({
        name: this.root,
        query: { dir: decodeURI(path) }
      });
    } else if (this.root === 'trash') {
      // TODO: trash のディレクトリ判定構成に合わせて分岐させている。trash 側を修正してこの分岐を削除する。
      const query = {
        dir: decodeURI(path)
      };
      if (path !== '/') {
        query['trash_dir'] = decodeURI(path);
      }
      this.$router.push({
        name: this.root,
        query
      });
    } else {
      change_folder(this.$router, path);
    }
    this.close_omit_path();
  }

  open_omit_path() {
    this.is_display_omitted_folders = true;
  }

  close_omit_path() {
    this.is_display_omitted_folders = false;
  }

  @Watch('folders', { deep: true })
  update_folders(new_value) {
    this.display_folders = new_value;
    if (new_value.length >= 4) {
      this.is_omitted = true;
      this.display_folders = this.folders.slice(-2);
      this.omitted_folders = this.folders.slice(1, this.folders.length - 2);
    } else {
      this.omitted_folders = this.folders;
      this.is_omitted = false;
    }

    this.is_display_omitted_folders = false;
  }
}
