














































import { Component, Vue, Watch } from 'vue-property-decorator';
import AppNotifications from '@/components/atoms/AppNotifications.vue';
import HomeHeader from '@/components/organisms/Home/HomeHeader.vue';
import HomeSideMenuBar from '@/components/organisms/Home/HomeSideMenuBar.vue';
import TrashBody from '@/components/organisms/Trash/TrashBody.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';

import notify from '@/functions/notify';
import Documents from '@/store/Documents';
import SettingsInfoModule from '@/store/SettingsInfo';
import CorporateInfo from '@/store/CorporateInfo';
import vLoading from 'vue-loading-overlay';

import NOTIFY_TEXT from '@/consts/NotifyText';

@Component({
  components: {
    AppNotifications,
    HomeHeader,
    TrashBody,
    HomeSideMenuBar,
    TextOnlyWindow,
    vLoading
  }
})
export default class Trash extends Vue {
  breadcrumbData = [];

  canOpenDeleteWindow: boolean = false;

  isInitLoading: boolean = false;
  isLoading: boolean = false;

  selectedFolder: any = {
    trash_directory_name: '',
    trash_directory_path: '/',
    directory_path: '/',
    is_signed: true
  };

  deletingTrash: boolean = false;

  isContractedDocumentTabSelected: boolean = true;

  get deleteButtonText(): string {
    if (this.is_all_delete && this.deletingTrash) return '処理中';
    if (this.is_all_delete && !this.deletingTrash) return 'ゴミ箱を空にする';
    if (!this.is_all_delete && this.deletingTrash) return '削除中';
    if (!this.is_all_delete && !this.deletingTrash) return '完全に削除';
  }
  async created() {
    Documents.SET_CURRENT_DIRECTORY('/');
    this.$router
      .replace({
        query: {
          dir: ''
        }
      })
      .catch(err => {});

    this.isInitLoading = true;

    Documents.getNotifications();
    Documents.getLirisNotifications();
    CorporateInfo.get_service_plan();
    SettingsInfoModule.get_setting_alert();

    Documents.SET_SELECTED_TRASH_FILES_OF_TABLE([]);
    Documents.SET_SELECTED_TRASH_FOLDERS_OF_TABLE([]);
  }

  destroyed() {
    this.resetTrashes();
  }

  get side_bar_status() {
    return HomeComponentInfoModule.side_bar_open;
  }

  get is_all_delete(): boolean {
    if (this.files_length === 0 && this.folders_length === 0) {
      return true;
    }
    return false;
  }

  get deleteModalBody(): string {
    if (this.is_all_delete)
      return 'ゴミ箱内のすべてのフォルダとドキュメントを完全に削除してもよろしいですか？';

    let body = '';
    if (this.folders_length) body = `${this.folders_length}個のフォルダ`;
    if (this.files_length && this.folders_length) body = `${body} と`;
    if (this.files_length) body = `${body}${this.files_length}個のファイル`;

    return `${body} を完全に削除してもよろしいですか？`;

    return body;
  }

  get files_length() {
    return Documents.selected_trash_files_of_table.length;
  }

  get folders_length() {
    return Documents.selected_trash_folders_of_table.length;
  }

  get current_directory() {
    return Documents.current_directory;
  }

  toggleSideBar() {
    HomeComponentInfoModule.change_side_bar();
  }

  cancelTrash() {
    this.canOpenDeleteWindow = false;
  }

  async deleteTrash() {
    this.deletingTrash = true;
    const trashTargetText = this.trashTargetText;

    if (this.is_all_delete) {
      await Documents.deleteAllTrashes(this.isContractedDocumentTabSelected)
        .then(async () => {
          await this.fecthTrashes();
          notify.success({
            text: NOTIFY_TEXT.SUCCESS.EMPTY_TRASH
          });
        })
        .catch(err => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.EMPTY_TRASH
          });
        });
    } else {
      await Documents.deleteTrashes(this.selectedFolder)
        .then(async () => {
          await this.fecthTrashes();
          notify.success({
            text: `${trashTargetText}を完全に削除しました`
          });
        })
        .catch(err => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE
          });
        });
    }
    this.canOpenDeleteWindow = false;
    this.deletingTrash = false;
  }

  async recovorFromTrash() {
    const trashTargetText = this.trashTargetText;

    await Documents.putTrashes(this.selectedFolder).catch(err => {
      notify.error({
        text: NOTIFY_TEXT.ERROR.RESTORE_TRASH
      });
      return;
    });

    notify.success({
      text: `${trashTargetText}をゴミ箱から復元しました`
    });
    Documents.SET_IS_TRASH_RECOVOR(true);

    Documents.SET_SELECTED_TRASH_FILES_OF_TABLE([]);
    Documents.SET_SELECTED_TRASH_FOLDERS_OF_TABLE([]);
  }

  /**
   * 選択ファイルとフォルダの文言を取得
   */
  get trashTargetText(): string {
    let trash_target_text = '';
    if (this.files_length) {
      trash_target_text += `${this.files_length}個のファイル`;
    }

    if (this.folders_length) {
      if (this.files_length) {
        trash_target_text += 'と';
      }
      trash_target_text += `${this.folders_length}個のフォルダ`;
    }

    return trash_target_text;
  }

  openDeleteWindow() {
    this.canOpenDeleteWindow = true;
  }

  @Watch('$route.query', { immediate: true })
  async changeCurrentDirectory(query) {
    if (query.dir === undefined) {
      return;
    }

    this.breadcrumbData = [];

    const trashDirectory = query.trash_dir;

    // トップ階層の時は選択済みフォルダを初期化する
    if (!trashDirectory) {
      this.selectedFolder = {
        directory_path: '/',
        trash_directory_path: '/',
        trash_directory_name: '',
        is_signed: this.isContractedDocumentTabSelected
      };
      this.fecthTrashes();
      return;
    }

    // クエリからゴミ箱のディレクトリパスを取得
    let trashDirectories = trashDirectory.split('/');
    trashDirectories = trashDirectories.filter(x => x);

    let directories = query.dir.split('/');
    directories = directories.filter(x => x);

    // パンくずのデータを生成
    let trashDirectoryPath = '';
    this.breadcrumbData = trashDirectories.map(x => {
      // ゴミ箱のディレクトリパスをセット
      trashDirectoryPath = `${trashDirectoryPath}/${x}`;

      // 本来のディレクトリパスをセット
      const directryIndex = directories.findIndex(d => d === x);
      let directoryPath = '';
      directories.forEach((d, i) => {
        if (i <= directryIndex) directoryPath = `${directoryPath}/${d}`;
      });

      return {
        directory_path: directoryPath,
        trash_directory_path: trashDirectoryPath,
        trash_directory_name: x,
        is_signed: this.isContractedDocumentTabSelected
      };
    });

    // パンくずの最後のデータを選択済みのフォルダとする
    this.selectedFolder = this.breadcrumbData[this.breadcrumbData.length - 1];
    this.fecthTrashes();
  }

  resetTrashes() {
    Documents.SET_TRASHES([]);
  }

  async fecthTrashes() {
    // 削除等を実行したあと一瞬締結済ドキュメントのタブが表示されるので暫定対応として表示内容をクリアする
    this.resetTrashes();
    this.isLoading = true;
    await Documents.getTrashes(this.selectedFolder).catch(err => {
      notify.error({
        text: NOTIFY_TEXT.ERROR.GET_TRASH
      });
      return;
    });
    this.isLoading = false;
    this.isInitLoading = false;
  }

  /**
   * フォルダ名をクリックしたときの処理
   * @param {Object} params
   */
  moveDirectory(params) {
    // ダブルサブミット防止のため、フォルダが選択されたらすぐにローダーを表示する
    this.isLoading = true;
    // ルートディレクトリへ遷移
    if (params.isRoot) {
      this.breadcrumbData = [];
      this.selectedFolder.directory_path = '/';
      this.selectedFolder.trash_directory_path = '/';
      this.selectedFolder.trash_directory_name = '';
      this.$router.push({
        query: {
          dir: ''
        }
      });
      return;
    }

    this.selectedFolder = params.selectedFolder;
    this.$router.push({
      query: {
        dir: params.selectedFolder.directory_path,
        trash_dir: params.selectedFolder.trash_directory_path
      }
    });
  }

  async changeTab() {
    this.isLoading = true;
    if (this.$route.query.dir) {
      // クエリを削除
      await this.$router.replace({
        query: {
          dir: ''
        }
      });
    }
    this.selectedFolder = {
      directory_path: '/',
      trash_directory_path: '/',
      trash_directory_name: '',
      is_signed: this.isContractedDocumentTabSelected
    };
    await Documents.getTrashes(this.selectedFolder).catch(err => {
      notify.error({
        text: NOTIFY_TEXT.ERROR.GET_TRASH
      });
      return;
    });
    if (!this.isContractedDocumentTabSelected) this.breadcrumbData = [];
    this.isLoading = false;
  }
}
