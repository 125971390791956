




import { Component, Vue } from 'vue-property-decorator';
import FinishSendEmail from '@/components/molecules/FinishSendEmail.vue';

@Component({
  components: {
    FinishSendEmail
  }
})
export default class CreatedCorporateInformation extends Vue {}
