








































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Mark from 'mark.js';
import IconSvg from '@/components/atoms/icons/IconSvg.vue';
import SearchLabel from '@/components/organisms/DocumentDetail/DocumentPreview/SearchLabel.vue';

// todo: 別で定義したほうが良さそう
type SearchLabelType = {
  keyword: string;
  colorType: string;
  className: string;
  matches: any;
};

const zenkakuRegex = /[Ａ-Ｚａ-ｚ０-９！＂＃＄％＆＇（）＊＋，－．／：；＜＝＞？＠［＼］＾＿｀｛｜｝　]/g;
const hankakuRegex = /[A-Za-z0-9!"#$%&'()*+,-./:;<=>?@[\]^_`{|} ]/g;

@Component({
  components: {
    IconSvg,
    SearchLabel
  }
})
export default class DocumentPreviewSearchBar extends Vue {
  @Prop({ required: true, type: String })
  html: string;
  @Prop({ required: true, type: String })
  selectedTab: string;

  keyword: string = '';
  searchLabel: SearchLabelType;
  holdLabels: SearchLabelType[] = [];
  count: number = 1;
  strInHtml: string = '';
  markInstanse: Mark;
  composing: boolean = false;
  matchCount: string = '';
  defaultMarkClass: string = 'default-mark';

  created() {
    this.markInstanse = new Mark(document.querySelector('#page-container'));
  }

  deleteLabel(removeLabel: SearchLabelType) {
    this.holdLabels.forEach((label, index) => {
      if (label.keyword == removeLabel.keyword) {
        const deleteSearchLabel = this.holdLabels.splice(index, 1);
        const options = {
          className: deleteSearchLabel[0].className
        };

        this.markInstanse.unmark(options);
      }
    });
  }

  @Watch('html')
  async changeHtml() {
    this.keyword = '';
    this.holdLabels = [];
  }

  jump(params) {
    const { className, count } = params;

    const selected = document.getElementsByClassName('mark-selected');
    Array.from(selected).forEach(d => d.classList.remove('mark-selected'));

    const label =
      className === this.defaultMarkClass
        ? this.searchLabel
        : this.holdLabels.find(o => o.className === className);
    if (count > label.matches.length) return;

    label.matches[count - 1].forEach(n => n.classList.add('mark-selected'));
    label.matches[count - 1][0].scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    });
  }

  multiSearch(range: string, keyword: string) {
    const result = [];
    while (1) {
      const match: any = range.match(keyword);
      if (match === null)
        return result.sort(function(a: any, b: any) {
          if (a < b) return -1;
          if (a > b) return 1;
          return 0;
        });
      result.push(match.index);
      range = range.substring(match.index + keyword.length, range.length);
    }
  }
  preRunSearch() {
    if (this.holdLabels.length >= 5) return;

    this.markInstanse.unmark({
      className: this.defaultMarkClass
    });

    const matches = this.searchDocument(this.keyword, {
      className: this.defaultMarkClass
    });
    this.searchLabel = {
      keyword: this.keyword,
      colorType: '',
      className: this.defaultMarkClass,
      matches
    };
    this.matchCount = this.keyword
      ? `${Math.min(matches.length, 1)} / ${matches.length}`
      : '';
    this.jump({ className: this.defaultMarkClass, count: 1 });
  }
  runSearch() {
    if (this.holdLabels.length >= 5) return;

    this.markInstanse.unmark({
      className: this.defaultMarkClass
    });

    if (!this.keyword) return;

    const target = this.holdLabels.find(
      x =>
        x.keyword === this.convertHalfToFull(this.keyword) ||
        x.keyword === this.convertFullToHalf(this.keyword)
    );

    // すでにラベルが生成されている場合は処理終了
    if (target) {
      this.keyword = '';
      this.matchCount = '';
      return;
    }

    // まだ設定されていないclassを採用する
    let targetIndex = 0;
    for (let index = 0; index < 5; index++) {
      const hasClassName = this.holdLabels
        .map(label => label.className)
        .includes(`mark${index}`);

      if (!hasClassName) {
        targetIndex = index;
        break;
      }
    }

    const className = `mark${targetIndex}`;

    const matches = this.searchDocument(this.keyword, {
      element: 'span',
      className
    });

    if (matches.length > 0) {
      this.holdLabels.push({
        keyword: this.keyword,
        colorType: String(targetIndex % 10),
        className,
        matches
      } as SearchLabelType);

      this.keyword = '';
      this.matchCount = '';
      this.count = 1;
      this.jump({ className, count: this.count });
    }
  }

  strIns(str: any, idx: any, val: any) {
    const res = str.slice(0, idx) + val + str.slice(idx);
    return res;
  }

  closeSearchBar() {
    // 検索済みキーワードのmarkを解除する
    this.holdLabels.forEach((label, index) => {
      const options = {
        className: label.className
      };

      this.markInstanse.unmark(options);
    });
    this.$emit('close');
  }

  /**
   * 半角を全角に変換
   *
   * @param {String} keyword
   * @return {String}
   */
  convertHalfToFull(keyword: string) {
    return keyword
      .replace(hankakuRegex, function(s) {
        return String.fromCharCode(s.charCodeAt(0) + 0xfee0);
      })
      .replace(/~/g, '～')
      .replace(/ /g, '　');
  }

  /**
   * 全角を半角に変換
   *
   * @param {String} keyword
   * @return {String}
   */
  convertFullToHalf(keyword: string) {
    return keyword
      .replace(zenkakuRegex, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      })
      .replace(/[‐－―]/g, '-')
      .replace(/[～〜]/g, '~')
      .replace(/　/g, ' ');
  }

  /**
   * ドキュメント内検索処理
   *
   * @param keyword
   * @param {Object} options
   */
  searchDocument(keyword, options = {}) {
    const matches = [];
    let matchIndex = 0;

    // 検索するキーワードのパターン
    const searchKeywordPatterns = [
      this.convertHalfToFull(keyword.toLowerCase()),
      this.convertFullToHalf(keyword.toLowerCase())
    ];

    const searchOptionsBase = {
      acrossElements: true,
      exclude: ['img'],
      separateWordSearch: false,
      each: node => {
        // HTMLを除去したキーワードが全文ヒットするまでノードをmatches配列に格納する
        if (keyword.match(hankakuRegex) || keyword.match(zenkakuRegex)) {
          // 半角区切りも一緒に検索したいためmapは使用せずforEachを使用
          const matchIndexs = [];
          searchKeywordPatterns.forEach(searchKeywordPattern => {
            matchIndexs.push(
              searchKeywordPattern.indexOf(
                node.innerText.toLowerCase(),
                matchIndex
              )
            );

            // 検索キーワードが一文字以上の場合は半角スペース区切りもmatches配列に格納する
            if (keyword.length > 1) {
              matchIndexs.push(
                searchKeywordPattern
                  .split('')
                  .join(' ')
                  .indexOf(node.innerText.toLowerCase(), matchIndex)
              );
            }
          });

          matchIndex = Math.max(...matchIndexs);
        } else {
          const matchIndexs = [];
          matchIndexs.push(keyword.indexOf(node.innerText, matchIndex));

          // 検索キーワードが一文字以上の場合は半角スペース区切りもmatches配列に格納する
          if (keyword.length > 1) {
            matchIndexs.push(
              keyword
                .split('')
                .join(' ')
                .indexOf(node.innerText, matchIndex)
            );
          }
        }

        if (matchIndex < 0) {
          return;
        } else if (matchIndex === 0) {
          matches.push([node]);
        } else {
          matches[matches.length - 1].push(node);
          if (matchIndex + node.innerText.length >= keyword.length) {
            matchIndex = 0;
          }
        }
      }
    };
    options = Object.assign({}, searchOptionsBase, options);

    // 英数字記号を含むか判定
    if (keyword.match(hankakuRegex) || keyword.match(zenkakuRegex)) {
      // 英数字記号を含む場合は、全角、半角の両方で検索する
      searchKeywordPatterns.forEach(searchKeywordPattern => {
        this.markInstanse.mark(searchKeywordPattern, options);

        // 検索キーワードが一文字以上の場合は文字区切りが半角スペースのものも検索する
        if (keyword.length > 1) {
          this.markInstanse.mark(
            searchKeywordPattern.split('').join(' '),
            options
          );
        }
      });
    } else {
      this.markInstanse.mark(keyword, options);

      // 検索キーワードが一文字以上の場合は文字区切りが半角スペースのものも検索する
      if (keyword.length > 1) {
        this.markInstanse.mark(keyword.split('').join(' '), options);
      }
    }
    return matches;
  }
}
