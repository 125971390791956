const AUTHORITIES = {
  OWNER: 'オーナー',
  ADMINISTRATOR: '管理者',
  USER: '一般',
  GENERA_MOVEABLE_CARD: '一般（カード移動・削除可）',
  GENERAL_CANNOT_MOVE_CARD: '一般（カード移動・削除不可）',
  GUEST: 'ゲスト'
};

export default AUTHORITIES;
