import Vue from 'vue';
import VueRouter from 'vue-router';
import vuex from 'vuex';
import UserInfoModule from '@/store/UserInfo';
import CorporateInfo from '@/store/CorporateInfo';
import RouterModule from '@/store/Router';

import HomeComponentInfo from '@/store/HomeComponentInfo';

import NotFound from '@/components/pages/NotFound.vue';
import Top from '@/components/pages/Top.vue';
import Login from '@/components/organisms/Top/login/Login.vue';
import CreateAccount from '@/components/organisms/Top/CreateAccount.vue';
import CreatedAccountComplete from '@/components/organisms/Complete/CreatedAccountComplete.vue';
import ForgotPassword from '@/components/organisms/Top/ForgotPassword.vue';
import OneTimePasswordForm from '@/components/organisms/Top/OneTimePasswordForm.vue';
import ChangePassword from '@/components/organisms/Top/ChangePassword.vue';
import FirstTimePasswordChangeComplete from '@/components/organisms/Complete/FirstTimePasswordChangeComplete.vue';
import CorporateInformation from '@/components/pages/CorporateInformation.vue';
import CorporateInformationForm from '@/components/organisms/CorporateInformationForm.vue';
import CreatedCorporateInformation from '@/components/organisms/CreatedCorporateInformation.vue';
import Accounts from '@/components/organisms/Settings/Accounts.vue';
import EmailNotifications from '@/components/organisms/Settings/EmailNotifications.vue';
import ServicePlan from '@/components/organisms/Settings/ServicePlan.vue';
import Labels from '@/components/organisms/Settings/Labels.vue';
import Alert from '@/components/organisms/Settings/Alert.vue';
import DepartmentMembers from '@/components/organisms/Settings/DepartmentMembers.vue';
import Attributes from '@/components/organisms/Settings/Attributes.vue';
import FileRestore from '@/components/organisms/Settings/FileRestore.vue';
import AllFileDownload from '@/components/organisms/Settings/AllFileDownload.vue';
import ControlNumberRule from '@/components/organisms/Settings/ControlNumberRule.vue';
import ExternalApps from '@/components/organisms/Settings/ExternalApps.vue';
import Buntan from '@/components/organisms/Settings/Buntan.vue';
import Slack from '@/components/organisms/Settings/Slack.vue';
import GMOSign from '@/components/organisms/Settings/GMOSign.vue';
import GMOSignPage from '@/components/pages/GMOSignPage.vue';
import GMOSignFormContract from '@/components/organisms/GMOSign/GMOSignFormContract.vue';
import GMOSignFormSigner from '@/components/organisms/GMOSign/GMOSignFormSigner.vue';
import GMOSignFormSignOrder from '@/components/organisms/GMOSign/GMOSignFormSignOrder.vue';
import GMOSignFormSignPosition from '@/components/organisms/GMOSign/GMOSignFormSignPosition.vue';
import GMOSignFormCompleted from '@/components/organisms/GMOSign/GMOSignFormCompleted.vue';

// LIRIS管理者用設定画面
import LirisAccounts from '@/components/organisms/Settings/Liris/Accounts.vue';
import LirisCreateAccount from '@/components/organisms/Settings/Liris/CreateAccount.vue';
import LirisNotifications from '@/components/organisms/Settings/Liris/Notifications.vue';

import UserAccountInformationForm from '@/components/organisms/UserAccountInformationForm.vue';
import Home from '@/components/pages/Home.vue';

import Trash from '@/components/pages/Trash.vue';
import SendOneTimePassword from '@/components/organisms/Complete/SendOneTimePassword.vue';
import DocumentDetail from '@/components/pages/DocumentDetail.vue';
import ContractManagement from '@/components/pages/ContractManagement.vue';
import Settings from '@/components/pages/Settings.vue';
import Department from '@/components/organisms/Settings/Department.vue';
import SettingsCorporateInformation from '@/components/organisms/Settings/CorporateInformation.vue';
import ForgotPasswordChangeComplete from '@/components/organisms/Complete/ForgotPasswordChangeComplete.vue';
import Members from '@/components/organisms/Settings/Members.vue';
import Template from '@/components/pages/Template.vue';
import PlayGround from '@/components/pages/PlayGround.vue';
import Loading from '@/components/pages/Loading.vue';
import RecentlyView from '@/components/pages/RecentlyView.vue';

import AUTHORITIES from '@/consts/Authorities.js';
import NOTIFY_TEXT from '@/consts/NotifyText';
import SERVICE_PLANS from '@/consts/ServicePlans';

import notify from '@/functions/notify';

Vue.use(VueRouter);
Vue.use(vuex);

const routes = [
  {
    path: '/playground',
    component: PlayGround,
    name: 'playground'
  },
  {
    path: '/static/*'
  },
  {
    path: '*',
    component: NotFound,
    name: 'not_found'
  },
  {
    path: '',
    component: Top,
    children: [
      {
        path: '/',
        component: Login,
        name: 'login'
      },
      {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot_password'
      },
      {
        path: '/forgot-password/sending-email',
        component: SendOneTimePassword,
        name: 'send_onetime_password'
      },
      {
        path: '/reset_password',
        component: OneTimePasswordForm,
        name: 'one_time_password_form'
      },
      {
        path: '/reset_password/completion',
        component: ForgotPasswordChangeComplete,
        name: 'forgot_password_change_complete'
      },
      {
        path: '/new-account',
        component: CreateAccount,
        name: 'new_account'
      },
      {
        path: '/new-account/created',
        component: CreatedAccountComplete,
        name: 'new_account_created'
      },
      {
        path: '/change-password',
        component: ChangePassword,
        name: 'change_password'
      },
      {
        path: '/change-password/first-time/completion',
        component: FirstTimePasswordChangeComplete,
        name: 'first_time_password_change_complete'
      }
    ]
  },
  {
    path: '/registrations',
    component: CorporateInformation,
    children: [
      {
        // LIRIS管理画面
        path: '/registrations/corporations',
        component: CorporateInformationForm,
        name: 'corporate_information_form'
      },
      {
        // LIRIS管理画面で会社作成後
        path: '/registrations/corporations/created',
        component: CreatedCorporateInformation,
        name: 'corporate_information_created'
      },
      {
        path: '/registrations/users',
        component: UserAccountInformationForm,
        name: 'user_account_information_form'
      }
    ]
  },
  {
    path: '/home/:id',
    component: Home,
    name: 'home',
    meta: {
      need_id_token: true,
      is_service_active: true
    }
  },
  {
    path: '/recently-view',
    component: RecentlyView,
    name: 'recentlyView',
    meta: {
      need_id_token: true,
      is_service_active: true
    }
  },
  {
    path: '/document-detail/:id',
    component: DocumentDetail,
    name: 'document_detail',
    meta: {
      need_id_token: true,
      is_service_active: true
    }
  },
  {
    path: '/contract-management',
    component: ContractManagement,
    name: 'contract_management',
    meta: {
      is_not_small_plan: true,
      need_id_token: true,
      is_service_active: true
    }
  },
  {
    path: '/loading',
    component: Loading,
    name: 'loading'
  },
  {
    path: '/trash',
    component: Trash,
    name: 'trash',
    meta: {
      need_id_token: true,
      is_service_active: true
    }
  },
  {
    path: '/gmosign',
    component: GMOSignPage,
    meta: {
      need_id_token: true,
      is_service_active: true,
      is_cooperated_gmosign: true
    },
    children: [
      {
        path: '/gmosign/form/contract',
        component: GMOSignFormContract,
        name: 'gmosign_form_contract'
      },
      {
        path: '/gmosign/form/signer',
        component: GMOSignFormSigner,
        name: 'gmosign_form_signer'
      },
      {
        path: '/gmosign/form/sign-order',
        component: GMOSignFormSignOrder,
        name: 'gmosign_form_sign_order'
      },
      {
        path: '/gmosign/form/sign-position',
        component: GMOSignFormSignPosition,
        name: 'gmosign_form_sign_position'
      },
      {
        path: '/gmosign/form/completed',
        component: GMOSignFormCompleted,
        name: 'gmosign_form_completed'
      }
    ]
  },
  {
    path: '/settings',
    component: Settings,
    beforeEnter(to, from, next) {
      HomeComponentInfo.SET_SIDE_BAR_OPEN(false);
      next();
    },
    children: [
      {
        path: '/settings/accounts',
        component: Accounts,
        name: 'accounts',
        meta: {
          need_id_token: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/email_notifications',
        component: EmailNotifications,
        name: 'email_notifications',
        meta: {
          need_id_token: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/members',
        component: Members,
        name: 'members',
        meta: {
          need_id_token: true,
          need_admin: true,
          need_owner: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/corporation_info',
        component: SettingsCorporateInformation,
        name: 'settings_corporation_info',
        meta: {
          need_id_token: true,
          need_admin: true,
          need_owner: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/service_plan',
        component: ServicePlan,
        name: 'service_plan',
        meta: {
          need_id_token: true,
          need_owner: true
        }
      },
      {
        path: '/settings/labels',
        component: Labels,
        name: 'labels',
        meta: {
          need_id_token: true,
          need_admin: true,
          need_owner: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/alert',
        component: Alert,
        name: 'alert',
        meta: {
          need_id_token: true,
          need_admin: true,
          need_owner: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/department',
        component: Department,
        name: 'department',
        meta: {
          need_id_token: true,
          need_admin: true,
          need_owner: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/department/:id/members',
        component: DepartmentMembers,
        name: 'department_members',
        meta: {
          need_id_token: true,
          need_admin: true,
          need_owner: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/attributes',
        component: Attributes,
        name: 'attributes',
        meta: {
          need_id_token: true,
          need_owner: true,
          need_admin: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/file_restore',
        component: FileRestore,
        name: 'file_restore',
        meta: {
          need_id_token: true,
          need_owner: true,
          need_admin: true
        }
      },
      {
        path: '/settings/all_file_download',
        component: AllFileDownload,
        name: 'all_file_download',
        meta: {
          need_id_token: true,
          need_owner: true
        }
      },
      {
        path: '/settings/control_number_rule',
        component: ControlNumberRule,
        name: 'control_number_rule',
        meta: {
          need_id_token: true,
          need_owner: true,
          need_admin: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/external_apps',
        component: ExternalApps,
        name: 'external_apps',
        meta: {
          need_id_token: true,
          need_owner: true,
          need_admin: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/external_apps/gmosign',
        component: GMOSign,
        name: 'gmosign',
        meta: {
          need_id_token: true,
          need_owner: true,
          need_admin: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/external_apps/buntan',
        component: Buntan,
        name: 'buntan',
        meta: {
          need_id_token: true,
          need_owner: true,
          need_admin: true,
          is_service_active: true
        }
      },
      {
        path: '/settings/external_apps/slack',
        component: Slack,
        name: 'slack',
        meta: {
          need_id_token: true,
          need_owner: true,
          need_admin: true,
          is_service_active: true
        }
      },
      // LIRIS専用管理画面
      {
        path: '/settings/liris/accounts',
        component: LirisAccounts,
        name: 'liris_accounts',
        meta: {
          need_id_token: true,
          need_liris: true
        }
      },
      {
        path: '/settings/liris/account/create',
        component: LirisCreateAccount,
        name: 'liris_account_create',
        meta: {
          need_id_token: true,
          need_liris: true
        }
      },
      {
        path: '/settings/liris/notifications',
        component: LirisNotifications,
        name: 'liris_notifications',
        meta: {
          need_id_token: true,
          need_liris: true
        }
      }
    ]
  },
  {
    path: '/template',
    component: Template,
    name: 'template',
    meta: {
      need_id_token: true,
      is_service_active: true
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// router.beforeResolve(async (to, from, next) => {
router.beforeEach(async (to, from, next) => {
  RouterModule.SET_HISTORIES(to.path);
  // ページ遷移の際にリクエストエラーをリセットする
  Vue.prototype.$requestErrors = [];

  const nextName = to.name;
  // tokenなくても遷移して良いところ(基本id-token必須)
  const allowNames = [
    'not_found',
    'login',
    'forgot_password',
    'send_onetime_password',
    'one_time_password_form',
    'forgot_password_change_complete',
    'new_account',
    'new_account_created',
    'change_password',
    'first_time_password_change_complete'
  ];

  const isLogined = Vue.cookies.get('is-logined');
  // if (allowNames.includes(nextName)) next();
  if (allowNames.indexOf(nextName) >= 0) {
    next();
    return;
  } else {
    const idToken = Vue.cookies.get('id-token');
    if (!idToken || !isLogined) {
      next({
        name: 'login',
        query: {
          redirect: encodeURIComponent(to.fullPath)
        }
      });
      return;
    }
  }

  if (!isLogined) {
    next({
      name: 'login',
      query: {
        redirect: encodeURIComponent(to.fullPath)
      }
    });
    return;
  }

  const meta = to.meta;
  let authority: string = UserInfoModule.authority;
  let is_liris: boolean = UserInfoModule.isOwner && UserInfoModule.isAdmin;
  if (!authority) {
    // 初回ルーティング成立前はTokenは有効でもユーザー情報を取得できない場合があるため取得
    // 特にリダイレクトでUserInfoModuleの情報が必要な場合に判定できなくなるため、authorityが取得できない場合に取り直しをしている
    // TODO: 本来はmain.tsで認証情報は取得する方が良いと思われるが暫定対応
    await UserInfoModule.get_user_info();
    authority = UserInfoModule.authority;
    is_liris = UserInfoModule.isOwner && UserInfoModule.isAdmin;
  }

  if (meta && meta.is_service_active) {
    await CorporateInfo.get_service_plan();

    const service_plan_id: string = CorporateInfo.service_plans.service_plan_id;
    if (meta && meta.is_not_small_plan) {
      if (service_plan_id === SERVICE_PLANS.SMALL.service_plan_id) {
        next({ name: 'not_found' });
        return;
      }
    }

    if (CorporateInfo.isPastServicePlanPeriod) {
      if (UserInfoModule.isOwner) {
        next({ name: 'service_plan' });
        notify.error({
          text: NOTIFY_TEXT.ERROR.SERVICE_EXPIRED.OWNER
        });
        return;
      }
      if (from.name != 'login') {
        next({ name: 'login' });
        return;
      }
      notify.clearError();
      notify.error({
        text: NOTIFY_TEXT.ERROR.SERVICE_EXPIRED.NOT_OWNER
      });
      return;
    }
  }

  if (meta && (meta.need_owner || meta.need_admin)) {
    // オーナー権限が設定されている画面はオーナー以外404にリダイレクト
    if (meta.need_owner && authority === AUTHORITIES.OWNER) {
      next();
      return;
    }

    if (meta.need_admin && authority === AUTHORITIES.ADMINISTRATOR) {
      next();
      return;
    }
    next({ name: 'not_found' });
    return;
  }

  if (meta && meta.need_liris) {
    // LIRIS権限が設定されている画面はLIRIS権限以外404にリダイレクト
    if (meta.need_liris && is_liris) {
      next();
      return;
    }

    next({ name: 'not_found' });
    return;
  }

  if (meta && meta.is_cooperated_gmosign) {
    await UserInfoModule.get_user_info();
    if (UserInfoModule.gmosign_user_info.gmosign_user_name) {
      next();
      return;
    }
    next({ name: 'not_found' });
    return;
  }

  next();
});

export default router;
