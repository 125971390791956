






























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import AppButton from '@/components/atoms/buttons/AppButton.vue';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import DirectoriesBreadCrumb from '@/components/atoms/breadcrumb/DirectoriesBreadCrumb.vue';
import SingleInputFormWindow from '@/components/molecules/modal_windows/SingleInputFormWindow.vue';

import CorporateInfoModule from '@/store/CorporateInfo';
import Documents from '@/store/Documents';

import NOTIFY_TEXT from '@/consts/NotifyText';

import Validation from '@/functions/Validation';
import notify from '@/functions/notify';
import { spaceReplaceUnderScore } from '@/functions/replaceString';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const DocumentRepository = RepositoryFactory.get('documents');

@Component({
  components: {
    AppButton,
    BaseTable,
    ImageContent,
    BaseWindow,
    DirectoriesBreadCrumb,
    SingleInputFormWindow
  }
})
export default class DocumentMoveWindowForContractManagement extends Vue {
  directories_name: object[] = [];
  selected_path: string = '';
  selectedDirectoryCount: number = null;

  is_showing_create_new_folder_window: boolean = false;
  newFolderCreating: boolean = false;
  folderNameErrorMessage: string = '';

  @Prop(Boolean)
  value: boolean;

  @Prop()
  currentDirectory!: string;

  @Prop()
  folders!: any[];

  @Prop()
  selectedFolderPathList?: any[];

  @Prop()
  windowSize!: string;

  @Prop({ default: false })
  folderMoving: boolean;

  get moveButtonText(): string {
    return this.folderMoving ? '移動中' : '移動';
  }

  get moveButtonDisabled(): boolean {
    return this.folderMoving;
  }

  get disabledDirectory(): boolean {
    const LIMIT_MOVE_FOLDER_COUNT = 3;
    return (
      this.isSmallPlan &&
      this.selectedDirectoryCount >= LIMIT_MOVE_FOLDER_COUNT &&
      this.selectedFolders.length > 0
    );
  }

  get isSmallPlan(): boolean {
    return CorporateInfoModule.isSmallPlan;
  }

  get selectedFolders() {
    return Documents.selected_folders_of_table;
  }

  is_display_omitted_folders: boolean = false;

  @Watch('value')
  changeValue(val) {
    this.selected_path = this.currentDirectory;
  }

  @Watch('selected_path')
  set_selected_path() {
    this.$emit('selectedPath', this.selected_path);
  }

  @Watch('selected_path', { immediate: true })
  return_directories_name() {
    this.set_directories_name(this.selected_path);
  }

  change_path(path: string, isOmit: boolean = true) {
    if (this.checkIncludeSelf(path)) return;

    this.selectedDirectoryCount = path === '/' ? 1 : path.split('/').length;
    if (!isOmit && this.disabledDirectory && this.selectedDirectoryCount > 2)
      return;

    this.$emit('changePath', path);
    this.selected_path = path;
  }

  set_directories_name(path: string) {
    this.directories_name = [];
    this.directories_name.push({
      id: 1,
      name: 'ドキュメント',
      path: '/'
    });
    const del_prefix = path.slice(1);
    let p = '';
    if (del_prefix.length > 0) {
      const splited_path = del_prefix.split('/');
      for (const i in splited_path) {
        p += '/' + splited_path[i];
        const tmp = {
          id: i + 2,
          name: splited_path[i],
          path: p
        };
        this.directories_name.push(tmp);
      }
    }
  }

  select_folder(path) {
    if (this.checkIncludeSelf(path)) return;
    if (this.disabledDirectory) return;

    if (this.currentDirectory != path) {
      this.selected_path = path;
    }
  }

  cancelMove() {
    this.selectedDirectoryCount = null;
    this.$emit('cancelMove');
  }

  changeWindowStatus(val) {
    if (!val) this.selectedDirectoryCount = null;
    this.$emit('changeWindowStatus');
  }

  checkIncludeSelf(path) {
    if (!this.selectedFolderPathList) return;
    return this.selectedFolderPathList.some(x => x === path);
  }

  // フォルダ作成
  get createNewFolderButtonText(): string {
    return this.newFolderCreating ? '作成中' : '作成';
  }

  /**
   * 同名のフォルダがあるかどうかを判別する
   * this.createNewFolder で利用されている
   */
  isAlreadyExistFolder(folderName) {
    const folders = Documents.folders;
    for (const i in folders) {
      if (folders[i]) {
        if (folders[i].directory_name === folderName) {
          return true;
        }
      }
    }
    return false;
  }

  async createNewFolder(folderName): Promise<void> {
    if (this.isAlreadyExistFolder(folderName)) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.ALREADY_EXIST_FOLDER
      });
      return;
    }

    this.folderNameErrorMessage = Validation.validateFileNameAndFolderName(
      folderName,
      true
    );
    if (this.folderNameErrorMessage) return;
    this.folderNameErrorMessage = '';
    this.newFolderCreating = true;
    const current_directory = this.selected_path;
    let path = '';
    const spaceReplacedFolderName: string = spaceReplaceUnderScore(folderName);
    if (current_directory != '/') {
      path = current_directory + '/' + spaceReplacedFolderName;
    } else {
      path = current_directory + spaceReplacedFolderName;
    }
    const params = {
      new_directory_path: path
    };

    await DocumentRepository.createNewFolder(params)
      .then(res => {
        if (res.status !== 200) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CREATED_FOLDER
          });
          return;
        }
        Documents.getFilesAndFolders();
        this.closeCreateFolderWindow();
        this.change_path(path, false);
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.CREATED_FOLDER
        });
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.CREATED_FOLDER
        });
      })
      .finally(() => {
        this.newFolderCreating = false;
      });
  }

  closeCreateFolderWindow() {
    this.is_showing_create_new_folder_window = false;
    this.folderNameErrorMessage = '';
  }
}
