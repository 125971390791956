










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';

@Component({
  directives: {
    ClickOutside
  },
  components: {
    SelectBox
  }
})
export default class InfoAutoUpdate extends Vue {
  @Prop()
  rule: any;

  @Prop({ default: false, type: Boolean })
  readonly disabled: boolean;

  @Prop({ default: false, type: Boolean })
  dense: boolean;

  options = [
    { text: 'あり', value: true },
    { text: 'なし', value: false }
  ];
  units = ['日', 'ヶ月', '年'];
  ruleWatcher = null;
  isEdit = false;
  // v-click-outside があとから走らないよう遅延させるフラグ
  isEditDuty = false;

  get activeCurrentOption() {
    return this.options.find(o => o.value === this.rule.is_active);
  }

  set activeCurrentOption(active) {
    this.rule.is_active = active.value;
    if (!this.rule.is_active) {
      this.isEdit = false;
    }
    this.$emit('blur', {
      is_active: this.rule.is_active
    });
  }

  get updateRuleText() {
    if (
      this.rule.is_active &&
      (!this.rule.auto_update_number ||
        !this.rule.auto_update_unit ||
        !this.rule.pre_notice_number ||
        !this.rule.pre_notice_unit)
    ) {
      this.isEdit = true;
      return;
    } else {
      if (this.rule.is_active) {
        return `あり：${this.rule.pre_notice_number}${this.rule.pre_notice_unit}前に通知がなければ${this.rule.auto_update_number}${this.rule.auto_update_unit}間自動更新`;
      }
      return 'なし';
    }
  }

  @Watch('rule.auto_update_number')
  async changeAutoUpdateNumber(val) {
    // 全角数字を半角に変換
    const halfChar = this.replaceHalfNumber(val);

    const result = this.checkNumber(halfChar);

    if (!result) {
      this.rule.auto_update_number = null;
      return;
    }

    this.rule.auto_update_number = halfChar;
  }

  @Watch('rule.pre_notice_number')
  async changePreNoticeNumber(val) {
    // 全角数字を半角に変換
    const halfChar = this.replaceHalfNumber(val);

    const result = this.checkNumber(halfChar);

    if (!result) {
      this.rule.pre_notice_number = null;
      return;
    }

    this.rule.pre_notice_number = halfChar;
  }

  checkNumber(val) {
    const pattern = /^[-]?([1-9]\d*|0)(\.\d+)?$/;
    return pattern.test(val);
  }

  replaceHalfNumber(val) {
    return String(val).replace(/[０-９]/g, function(s) {
      return String.fromCharCode(s.charCodeAt(0) - 65248);
    });
  }

  onClickToEdit() {
    this.isEdit = true;
    this.isEditDuty = true;
    setTimeout(() => {
      this.isEditDuty = false;
    }, 10);
  }

  onOutSide() {
    if (!this.isEdit) return;
    if (this.isEditDuty) return;
    if (
      this.rule.is_active &&
      (!this.rule.auto_update_number ||
        !this.rule.auto_update_unit ||
        !this.rule.pre_notice_number ||
        !this.rule.pre_notice_unit)
    )
      return;

    this.$emit('blur', this.rule);
    this.isEdit = false;
  }
}
