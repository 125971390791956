import Vue from 'vue';

interface successNotifyOptions {
  text?: string;
  data?: {
    cancelMethod?: () => {} | void | undefined;
  };
}

const DEFAULT_SUCCESS_OPTIONS = {
  group: 'success',
  text: ''
};
export function success(options: successNotifyOptions = {}) {
  const cancelType = {
    type: '',
    data: {
      cancelMethod: undefined
    }
  };

  if (options.data && typeof options.data.cancelMethod === 'function') {
    cancelType.type = 'cancelable';
    cancelType.data.cancelMethod = options.data.cancelMethod;
  }

  const { group, text, type, data } = Object.assign(
    {},
    DEFAULT_SUCCESS_OPTIONS,
    options,
    cancelType
  );
  Vue.notify({ group, text, type, data });
}

export function successWithoutCloseButton({ text }) {
  Vue.notify({
    group: 'without_close_button',
    text
  });
}

export function clearSuccessWithoutCloseButton() {
  Vue.notify({
    group: 'without_close_button',
    clean: true
  });
}

interface errorNotifyOptions {
  text?: string;
}
const DEFAULT_ERROR_OPTIONS = {
  group: 'error',
  text: ''
};
export function error(options: errorNotifyOptions = {}) {
  const { group, text } = Object.assign({}, DEFAULT_ERROR_OPTIONS, options);
  Vue.notify({ group, text });
}

export function clearSuccess() {
  Vue.notify({
    group: 'success',
    clean: true
  });
}

export function clearError() {
  Vue.notify({
    group: 'error',
    clean: true
  });
}

const notify = {
  success,
  successWithoutCloseButton,
  error,
  clearSuccess,
  clearSuccessWithoutCloseButton,
  clearError
};

export default notify;
