













import { Component, Vue } from 'vue-property-decorator';
import ServicePlanHeader from '@/components/molecules/settings_headers/ServicePlanHeader.vue';
import ServicePlanDetail from '@/components/organisms/Settings/ServicePlanDetail.vue';
import dayjs from 'dayjs';

import CorporateInfoModule from '@/store/CorporateInfo';

@Component({
  components: {
    ServicePlanHeader,
    ServicePlanDetail
  }
})
export default class ServicePlan extends Vue {
  beforeRouteEnter() {
    // TODO: 権限がない人は表示させない
  }

  created() {
    CorporateInfoModule.get_service_plan();
  }

  /**
   * サービスプラン情報を取得
   */
  private get service_plans() {
    return CorporateInfoModule.service_plans;
  }

  get is_free_trial(): boolean {
    return CorporateInfoModule.isFreeTrialPlan;
  }

  get service_plan_expiration(): string {
    return dayjs(this.service_plans.service_plan_expiration).format(
      'YYYY/MM/DD'
    );
  }

  get service_plan_start(): string {
    const start_date = this.service_plans.service_plan_start;
    if (start_date) {
      return dayjs(start_date).format('YYYY/MM/DD');
    } else {
      return null;
    }
  }

  get service_plan_name() {
    const servicePlanName = this.service_plans.service_plan_name;
    return this.is_free_trial ? `スタンダード` : `${servicePlanName}`;
  }
}
