















import { Component, Prop, Vue } from 'vue-property-decorator';
import IconSvg from '@/components/atoms/icons/IconSvg.vue';

@Component({
  components: {
    IconSvg
  }
})
export default class DropdownMenuContainer extends Vue {
  @Prop({ default: '0' })
  offset;

  @Prop({ default: 'bottom-end' })
  placement?: string;

  @Prop()
  classes?: string;
}
