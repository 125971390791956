import HTTP_STATUS from '@/consts/HttpStatus';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');
const AttachmentRepository = RepositoryFactory.get('attachments');
const LirisNotificationRepository = RepositoryFactory.get('lirisNotifications');

/**
 * ダウンロード実行
 * @param {String} url
 * @param {String} filename
 */
function execDownload(url: string, filename: string) {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', filename);
  link.click();
}

/**
 * LIRISお知らせからファイルダウンロード
 * @param {String} lirisNotificationId
 * @param {String} filename
 * @returns {Boolean}
 */
function downloadLirisNotificationFile(
  lirisNotificationId: string,
  filename: string
) {
  return LirisNotificationRepository.getFileLink(lirisNotificationId)
    .then(res => {
      if (res.status !== HTTP_STATUS.OK) return false;

      execDownload(res.data, filename);

      return true;
    })
    .catch(e => {
      return false;
    });
}

/**
 * 締結済みドキュメント、ファイルダウンロード
 * @param {String} contractVersionId
 * @param {String} filename
 * @returns {Boolean}
 */
function downloadSignedFile(contractVersionId: string, filename: string) {
  return DocumentRepository.getDownloadLink(contractVersionId)
    .then(res => {
      if (res.status !== HTTP_STATUS.OK) return false;

      execDownload(res.data, filename);

      return true;
    })
    .catch(e => {
      return false;
    });
}

/**
 * 添付資料、ファイルダウンロード
 * @param {String} attachmentId
 * @param {String} filename
 * @returns {Boolean}
 */
function downloadAttachmentFile(attachmentId: string, filename: string) {
  return AttachmentRepository.getDownloadLink(attachmentId)
    .then(res => {
      if (res.status !== HTTP_STATUS.OK) return false;

      execDownload(res.data, filename);

      return true;
    })
    .catch(e => {
      return false;
    });
}

export default {
  downloadSignedFile,
  downloadAttachmentFile,
  downloadLirisNotificationFile
};
