const axios = require('axios').default;

const resource = '/user-management';

export default {
  /**
   * ファイルの閲覧権限設定の取得
   *
   * @param { String } contractId
   */
  getContractPermissions(contractId: string) {
    return axios.get(
      `${resource}/contracts/permissions/${contractId}/settings`
    );
  },

  /**
   * フォルダの閲覧権限設定の取得
   *
   * @param { String } directoryId
   */
  getDirectoryPermissions(directoryId: string) {
    return axios.get(
      `${resource}/directories/permissions/${directoryId}/settings`
    );
  }
};
