import Vue from 'vue';
import Vuex from 'vuex';
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators';
import store from '@/store';
import dayjs from 'dayjs';

import UserInfo from '@/store/UserInfo';

import HTTP_STATUS from '@/consts/HttpStatus';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const SettingRepository = RepositoryFactory.get('settings');

Vue.use(Vuex);

enum AlertLimitType {
  NoDate,
  NoAlert,
  DoUpdateOrScheduledEnd,
  DoScheduledEnd,
  ScheduledEnd,
  End
}
type AlertLimit = {
  type: AlertLimitType;
  limitDays: number;
};
interface Sort {
  [key: string]: {
    columnName: string;
    asc: boolean;
  };
}
interface Department {
  department_id: string;
  department_name: string;
}

interface Member {
  department_id: string;
  department_name: string;
  email: string;
  logined_at: string;
  permission_default_id: string;
  // FIXME: permission_default_nameにはよくわからないオブジェクトが入ることがあるっぽい
  // @See Members::saveAuthority
  permission_default_name: string | any;
  user_id: string;
  user_name: string;
}

interface ContractLabel {
  label_id?: string;
  label_name?: string;
  background_color: string;
  font_color: string;
}

interface EmailNotificationSetting {
  is_create: boolean;
  is_change_status: boolean;
  is_concerning: boolean;
  is_assign: boolean;
  is_comment: boolean;
  is_alert: boolean;
  is_comment_always: boolean;
}

@Module({
  dynamic: true,
  store: store,
  name: 'settings_info',
  namespaced: true
})
export class SettingsInfo extends VuexModule {
  /**
   * master管理データ
   */
  authorities: any = [];
  industry_options: any = [];
  employee_counts: any = [];

  complete_sending_email: boolean = false;

  add_explanation_authority_window: boolean = false;

  can_open_email_confirm_window: boolean = false;
  can_open_password_confirm_window: boolean = false;
  can_open_add_department_window: boolean = false;
  can_open_delete_member_window: boolean = false;
  can_open_add_users_with_department_window: boolean = false;
  can_open_delete_users_with_department_window: boolean = false;
  can_show_view_permission_window: boolean = false;
  can_open_liris_admin_password_window: boolean = false;

  labels: ContractLabel[] = [];
  departments: Department[] = [];
  owner_transfer_window: boolean = false;
  selected_users: any = [];
  invitations: any = [];
  members: Member[] = [];
  users_corporations_same_me: any = [];
  selected_users_corporations_same_me: any = [];

  departments_for_view_permission: any = [];
  selected_departments_for_view_permission: any = [];

  permission_users: any = [];

  is_first_register_permission: boolean = false;
  can_user_authority_edit: boolean = false;

  users_with_department: any = [];
  selected_users_with_department: any = [];
  current_department: any = {};

  can_change_email: boolean = false;
  can_change_password: boolean = false;

  is_display_suggest_list: boolean = false;

  setting_alerts: any = {};

  sort: Sort = {};

  email_notification_setting: EmailNotificationSetting = {
    is_create: false,
    is_change_status: false,
    is_concerning: false,
    is_assign: false,
    is_comment: false,
    is_alert: false,
    is_comment_always: false
  };

  private get getLimitDays() {
    return (date: string | Date, beforeDay: number) => {
      const today = dayjs().endOf('day');
      const limitDays: number = dayjs(date)
        .endOf('day')
        .diff(today, 'day');
      return {
        limitDays,
        isRange: beforeDay >= limitDays
      };
    };
  }
  get alertStatus() {
    return (
      expirationDate: Date | string,
      autoUpdateDate: Date | string,
      isScheduledEnd: boolean,
      isEnded: boolean
    ): AlertLimit => {
      if (!isEnded && !expirationDate) {
        return { type: AlertLimitType.NoDate, limitDays: null };
      }

      const expirationLimit = this.getLimitDays(
        expirationDate,
        this.setting_alerts.expiration_before_alert_day
      );
      const autoUpdateLimit = this.getLimitDays(
        autoUpdateDate,
        this.setting_alerts.auto_update_before_alert_day
      );

      // 契約終了フラグまたは契約終了日を超えている
      if (isEnded || expirationLimit.limitDays < 0) {
        return {
          type: AlertLimitType.End,
          limitDays: expirationLimit.limitDays
        };
      }

      // 契約終了予定の場合
      if (isScheduledEnd) {
        return {
          type: AlertLimitType.ScheduledEnd,
          limitDays: expirationLimit.limitDays
        };
      }

      // 契約更新日が未設定で、契約終了前のアラート範囲内の場合
      if (!autoUpdateDate && expirationLimit.isRange) {
        return {
          type: AlertLimitType.DoScheduledEnd,
          limitDays: expirationLimit.limitDays
        };
      }

      // 契約更新日前のアラート範囲内の場合
      if (autoUpdateDate && autoUpdateLimit.isRange) {
        return {
          type: AlertLimitType.DoUpdateOrScheduledEnd,
          limitDays: expirationLimit.limitDays
        };
      }
      return {
        type: AlertLimitType.NoAlert,
        limitDays: expirationLimit.limitDays
      };
    };
  }

  @Action({ rawError: true })
  async get_members() {
    await SettingRepository.getMembers()
      .then(response => {
        this.SET_MEMBERS(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action({ rawError: true })
  async post_members(index: number) {
    const invited_user_authority = this.invitations[index].authority;
    const post_data = {
      department_id: this.invitations[index].department.department_id,
      email: this.invitations[index].email,
      permission_default_id: invited_user_authority.permission_default_id
    };

    return await SettingRepository.createMember(post_data).catch(error => {
      console.error(error);
      return error.response;
    });
  }

  @Action({ rawError: true })
  async delete_members(members: Member[]) {
    const params = {
      member_id_list: members
    };
    return await SettingRepository.deleteMembers(params)
      .then(response => {
        this.SET_MEMBERS(response.data);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async get_authorities() {
    await SettingRepository.getAuthorities()
      .then(response => {
        this.SET_AUTHORITIES(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async get_industry_options() {
    await SettingRepository.getIndustryOptions()
      .then(response => {
        this.SET_INDUSTRY_OPTIONS(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async get_employees_count() {
    await SettingRepository.getEmployeesCount()
      .then(response => {
        this.SET_EMPLOYEE_COUNTS(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async get_setting_alert() {
    return await SettingRepository.getSettingAlert()
      .then(response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        this.SET_SETTING_ALERT(response.data);
        return Promise.resolve(response);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  @Action({ rawError: true })
  async put_setting_alert({
    auto_update_date,
    expiration_date
  }: {
    auto_update_date: number;
    expiration_date: number;
  }) {
    const params = {
      auto_update_date,
      expiration_date
    };
    await SettingRepository.updateSettingAlert(params).catch(error => {
      return Promise.reject(error);
    });
  }

  @Action({ rawError: true })
  async get_departments() {
    await SettingRepository.getDepartments()
      .then(response => {
        this.SET_DEPARTMENTS(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async post_departments(department_name: string) {
    return await SettingRepository.createDepartment({
      department_name
    })
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        this.SET_DEPARTMENTS(res.data);
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async put_departments({
    selected_departments,
    edit_name
  }: {
    selected_departments: Department[];
    edit_name: string;
  }) {
    if (selected_departments.length === 0 || selected_departments.length > 1) {
      return Promise.reject('一つ選択してください');
    }

    const params = {
      department_id: selected_departments[0].department_id,
      name: edit_name
    };

    await SettingRepository.updateDepartment(params)
      .then(response => {
        this.SET_DEPARTMENTS(response.data);
      })
      .catch(() => {
        return Promise.reject('部署名の変更に失敗しました');
      });
  }

  @Action({ rawError: true })
  async delete_departments(selected_departments: Department[]) {
    const department_id_list = [];

    selected_departments.forEach(item => {
      department_id_list.push(item.department_id);
    });

    const params = {
      department_id_list
    };

    return await SettingRepository.deleteDepartments(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        this.SET_DEPARTMENTS(res.data);
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /**
   * 部署に設定されているユーザーを取得する
   */
  @Action({ rawError: true })
  async get_users_with_department(department_id) {
    const params = {
      department_id
    };

    await SettingRepository.getUsersWithDepartment(params)
      .then(response => {
        this.SET_USERS_WITH_DEPARTMENT(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /**
   * 部署にユーザーを追加する
   */
  @Action({ rawError: true })
  async post_users_with_department(addUsers) {
    const user_id_list: string[] = addUsers.map(user => user.user_id);

    const params = {
      user_id_list,
      department_id: this.current_department.department_id
    };

    return await SettingRepository.createUsersWithDepartment(params)
      .then(response => {
        this.SET_USERS_WITH_DEPARTMENT(response.data);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /**
   * 所属部署に紐づくユーザーの削除
   */
  @Action({ rawError: true })
  async delete_users_with_department() {
    const user_id_list: string[] = this.selected_users_with_department.map(
      user => user.user_id
    );

    const params = {
      user_id_list,
      department_id: this.current_department.department_id
    };

    return await SettingRepository.deleteUsersWithDepartment(params)
      .then(response => {
        this.SET_USERS_WITH_DEPARTMENT(response.data);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async get_users_corporations_same_me(permission_order: number = 5) {
    await SettingRepository.getUsersCorporationsSameMe(permission_order)
      .then(response => {
        this.SET_USERS_CORPORATIONS_SAME_ME(response.data.user_list);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async get_email_notification_setting() {
    const userId = UserInfo.user_id;
    // メール通知設定取得
    await SettingRepository.getEmailNotificationSetting({ user_id: userId })
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        this.SET_EMAIL_NOTIFICATION_SETTING({
          is_create: res.data.is_create,
          is_change_status: res.data.is_change_status,
          is_concerning: res.data.is_concerning,
          is_assign: res.data.is_assign,
          is_comment: res.data.is_comment,
          is_alert: res.data.is_alert,
          is_comment_always: res.data.is_comment_always
        });
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async get_labels() {
    await SettingRepository.getCoporationContractLabels()
      .then(response => {
        this.SET_LABELS(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async post_label(label: ContractLabel) {
    await SettingRepository.createCoporationContractLabel(label)
      .then(response => {
        this.SET_LABELS(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async update_label(label: ContractLabel) {
    await SettingRepository.updateCoporationContractLabel(label)
      .then(response => {
        this.SET_LABEL_AT(response.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async delete_labels(labels: ContractLabel[]) {
    for (const l of labels) {
      await SettingRepository.deleteCoporationContractLabel({
        label_id: l.label_id
      });
      this.REMOVE_LABEL_AT(l);
    }
  }

  @Mutation
  SET_MEMBERS(members: Member[]) {
    this.members = members;
  }

  @Mutation
  SET_COMPLETE_SENDING_EMAIL(bool: boolean) {
    this.complete_sending_email = bool;
  }

  @Mutation
  SET_AUTHORITIES(authorities: any) {
    this.authorities = authorities;
  }

  @Mutation
  SET_INDUSTRY_OPTIONS(industry_options) {
    this.industry_options = industry_options;
  }

  @Mutation
  SET_EMPLOYEE_COUNTS(employee_counts) {
    this.employee_counts = employee_counts;
  }

  @Mutation
  SET_INVITED_USER_EMAIL(payload: { index: number; email: string }) {
    this.invitations[payload.index].email = payload.email;
  }

  @Mutation
  SET_INVITED_USER_AUTHORITY(payload: { index: number; authority: any }) {
    this.invitations[payload.index].authority = payload.authority;
  }

  @Mutation
  SET_INVITED_USER_DEPARTMENT(payload: { index: number; department: any }) {
    this.invitations[payload.index].department = payload.department;
  }

  @Mutation
  SET_INVITATIONS(array: any) {
    this.invitations = array;
  }

  @Mutation
  ADD_INVITATION() {
    this.invitations.push({
      email: '',
      department: null,
      authority: null,
      error: null
    });
  }

  @Mutation
  RESET_INVITATIONS() {
    this.invitations = [
      {
        email: '',
        department: null,
        authority: null,
        error: null
      }
    ];
  }

  @Mutation
  SET_INVITATION_ERROR(payload: { index: number; message: string }) {
    this.invitations[payload.index].error = payload.message;
  }

  @Mutation
  CLEAR_INVITATION_ERROR(index: number) {
    this.invitations[index].error = null;
  }

  @Mutation
  REMOVE_INVITATION(index: number) {
    this.invitations.splice(index, 1);
  }

  @Mutation
  SET_SELECTED_USERS(array: string[]) {
    this.selected_users = array;
  }

  @Mutation
  SET_OWNER_TRANSFER_WINDOW(bool: boolean) {
    this.owner_transfer_window = bool;
  }

  @Mutation
  SET_ADD_EXPLANATION_AUTHORITY_WINDOW(bool: boolean) {
    this.add_explanation_authority_window = bool;
  }
  @Mutation
  SET_CAN_OPEN_EMAIL_CONFIRM_WINDOW(bool: boolean) {
    this.can_open_email_confirm_window = bool;
  }

  @Mutation
  SET_CAN_OPEN_PASSWORD_CONFIRM_WINDOW(bool: boolean) {
    this.can_open_password_confirm_window = bool;
  }

  @Mutation
  SET_CAN_OPEN_ADD_DEPARTMENT_WINDOW(bool: boolean) {
    this.can_open_add_department_window = bool;
  }

  @Mutation
  SET_CAN_OPEN_DELETE_MEMBER_WINDOW(bool: boolean) {
    this.can_open_delete_member_window = bool;
  }

  @Mutation
  SET_CAN_OPEN_ADD_USERS_WITH_DEPARTMENT_WINDOW(bool: boolean) {
    this.can_open_add_users_with_department_window = bool;
  }

  @Mutation
  SET_CAN_OPEN_DELETE_USERS_WITH_DEPARTMENT_WINDOW(bool: boolean) {
    this.can_open_delete_users_with_department_window = bool;
  }

  @Mutation
  SET_CAN_SHOW_VIEW_PERMISSION_WINDOW(bool: boolean) {
    this.can_show_view_permission_window = bool;
  }

  @Mutation
  SET_CAN_OPEN_LIRIS_ADMIN_PASSWORD_WINDOW(bool: boolean) {
    this.can_open_liris_admin_password_window = bool;
  }

  @Mutation
  SET_CAN_CHANGE_EMAIL(bool: boolean) {
    this.can_change_email = bool;
  }

  @Mutation
  SET_CAN_CHANGE_PASSWORD(bool: boolean) {
    this.can_change_password = bool;
  }

  @Mutation
  SET_SETTING_ALERT(setting_alerts) {
    this.setting_alerts = setting_alerts;
  }

  @Mutation
  SET_IS_DISPLAY_SUGGEST_LIST(bool: boolean) {
    this.is_display_suggest_list = bool;
  }

  @Mutation
  SET_DEPARTMENTS(departments) {
    this.departments = departments;
  }

  @Mutation
  SET_USERS_WITH_DEPARTMENT(users_with_department) {
    this.users_with_department = users_with_department;
  }

  @Mutation
  SET_SELECTED_USERS_WITH_DEPARTMENT(selected_users_with_department) {
    this.selected_users_with_department = selected_users_with_department;
  }

  @Mutation
  SET_CURRENT_DEPARTMENT(current_department) {
    this.current_department = current_department;
  }

  @Mutation
  SET_USERS_CORPORATIONS_SAME_ME(users_corporations_same_me) {
    this.users_corporations_same_me = users_corporations_same_me;
  }

  @Mutation
  SET_DEPARTMENTS_FOR_VIEW_PERMISSION(departments_for_view_permission) {
    this.departments_for_view_permission = departments_for_view_permission;
  }

  @Mutation
  SET_LABELS(labels) {
    this.labels = labels;
  }

  @Mutation
  SET_LABEL_AT(label) {
    const index = this.labels.findIndex(l => l.label_id === label.label_id);
    this.labels[index].label_name = label.label_name;
    this.labels[index].background_color = label.background_color;
    this.labels[index].font_color = label.font_color;
  }

  @Mutation
  REMOVE_LABEL_AT(label) {
    this.labels = this.labels.filter(l => l.label_id !== label.label_id);
  }

  @Mutation
  SET_SELECTED_USERS_CORPORATIONS_SAME_ME(user) {
    const before: any = this.selected_users_corporations_same_me;
    const count = this.users_corporations_same_me.length;

    this.users_corporations_same_me = this.users_corporations_same_me.filter(
      u => u.user_id != user.user_id
    );
    if (count === this.users_corporations_same_me.length) {
      return;
    }
    this.selected_users_corporations_same_me = before.concat([user]);
  }

  @Mutation
  REMOVE_SELECTED_USERS_CORPORATIONS_SAME_ME(user) {
    const before: any = this.users_corporations_same_me;
    const count = this.selected_users_corporations_same_me.length;

    this.selected_users_corporations_same_me = this.selected_users_corporations_same_me.filter(
      u => u.user_id != user.user_id
    );
    if (count === this.selected_users_corporations_same_me.length) {
      return;
    }
    this.users_corporations_same_me = before.concat([user]);
  }

  @Mutation
  RESET_SELECTED_USERS_CORPORATIONS_SAME_ME() {
    this.users_corporations_same_me = this.users_corporations_same_me.concat(
      this.selected_users_corporations_same_me
    );
    this.selected_users_corporations_same_me = [];
  }

  @Mutation
  SET_SELECTED_DEPARTMENTS_FOR_VIEW_PERMISSION(department) {
    const before: any = this.selected_departments_for_view_permission;
    const count = this.departments_for_view_permission.length;

    this.departments_for_view_permission = this.departments_for_view_permission.filter(
      d => d.department_id != department.department_id
    );

    if (count === this.departments_for_view_permission.length) {
      return;
    }
    this.selected_departments_for_view_permission = before.concat([department]);
  }

  @Mutation
  REMOVE_SELECTED_DEPARTMENTS_FOR_VIEW_PERMISSION(department) {
    const before: any = this.departments_for_view_permission;
    const count = this.selected_departments_for_view_permission.length;

    this.selected_departments_for_view_permission = this.selected_departments_for_view_permission.filter(
      d => d.department_id != department.department_id
    );
    if (count === this.selected_departments_for_view_permission.length) {
      return;
    }
    this.departments_for_view_permission = before.concat([department]);
  }

  @Mutation
  RESET_SELECTED_DEPARTMENTS_FOR_VIEW_PERMISSION() {
    this.selected_departments_for_view_permission = [];
  }

  @Mutation
  SET_IS_FIRST_REGISTER_PERMISSION(is_first_register_permission) {
    this.is_first_register_permission = is_first_register_permission;
  }

  @Mutation
  SET_CAN_USER_AUTHORITY_EDIT(can_user_authority_edit) {
    this.can_user_authority_edit = can_user_authority_edit;
  }

  @Mutation
  SET_SORT({
    pageName,
    columnName
  }: {
    pageName: string;
    columnName: string;
  }): void {
    if (!this.sort[pageName]) {
      this.sort[pageName] = {
        columnName,
        asc: true
      };
      return;
    }

    if (this.sort[pageName].columnName === columnName) {
      this.sort[pageName].asc = !this.sort[pageName].asc;
    } else {
      this.sort[pageName].columnName = columnName;
      this.sort[pageName].asc = true;
    }
  }

  @Mutation
  SET_EMAIL_NOTIFICATION_SETTING(
    email_notification_setting: EmailNotificationSetting
  ) {
    this.email_notification_setting = email_notification_setting;
  }
}

const SettingsInfoModule = getModule(SettingsInfo);

export { AlertLimit, AlertLimitType, Member, ContractLabel };
export default SettingsInfoModule;
