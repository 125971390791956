// ソートロジックを纏めたかったが、ソート機能がある各ページでデータ構造がかなり違っていて締結済みドキュメントでしかこのファイルを利用していない
type Option = {
  isAsc: boolean;
};

// バックエンドで配列の順序が担保されていなく、同じソートレベルのオブジェクトの順序が担保されず画面のチラつきが発生したため対応
// @see https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/735
export function uniqueSort(a, b): -1 | 1 {
  // folder
  if (a.directory_name_furigana < b.directory_name_furigana) return -1;
  if (a.directory_name_furigana > b.directory_name_furigana) return 1;
  // file
  if (a.contract_id < b.contract_id) return -1;
  if (a.contract_id > b.contract_id) return 1;
  return 1;
}

export function sortBase(
  arr: any[],
  key: string,
  { isAsc = true }: Option
): void {
  // NOTE: sortメソッドは破壊的な変更をするので注意
  arr.sort((a, b) => {
    if (a[key] < b[key]) return isAsc ? -1 : 1;
    if (a[key] > b[key]) return isAsc ? 1 : -1;
    return uniqueSort(a, b);
  });
}

/**
 * ドキュメントをソートする
 *
 * @param items ソートするアイテム
 * @param folderKey フォルダーであることを判定するキー
 * @param folderSortKey フォルダーをソートするためのキー
 * @param columnName ソートするカラム名
 * @param isAsc 昇順フラグ
 */
export function sortDocuments(
  items: any,
  folderKey: string,
  folderSortKey: string,
  columnName: string,
  isAsc: boolean
): any[] {
  const FOLDER_KEY = folderKey;
  const FOLDER_SORT_KEY = folderSortKey;

  // フォルダとファイルを分ける
  let folders = items.filter(x => x[FOLDER_KEY]);
  let files = items.filter(x => x.contract_id);

  // ここだけフォルダ名と契約書名があるので特殊
  if (columnName === 'contractName') {
    folders.sort((a, b) => {
      if (a[FOLDER_SORT_KEY] < b[FOLDER_SORT_KEY]) return isAsc ? -1 : 1;
      if (a[FOLDER_SORT_KEY] > b[FOLDER_SORT_KEY]) return isAsc ? 1 : -1;
      return uniqueSort(a, b);
    });
    files.sort((a, b) => {
      if (a.contract_title_furigana < b.contract_title_furigana)
        return isAsc ? -1 : 1;
      if (a.contract_title_furigana > b.contract_title_furigana)
        return isAsc ? 1 : -1;
      return uniqueSort(a, b);
    });
  } else {
    // 他はsort.columnNameでソートしてOK
    const sortColumn = columnName;
    const foldersSortCulumsNull = folders.filter(folder => !folder[sortColumn]);
    const foldersSortCulumsNotNull = folders.filter(
      folder => folder[sortColumn]
    );
    sortBase(foldersSortCulumsNotNull, columnName, {
      isAsc: isAsc
    });
    folders = foldersSortCulumsNotNull.concat(foldersSortCulumsNull);
    const filesSortCulumsNull = files.filter(file => !file[sortColumn]);
    const filesSortCulumsNotNull = files.filter(file => file[sortColumn]);
    sortBase(filesSortCulumsNotNull, columnName, {
      isAsc: isAsc
    });
    files = filesSortCulumsNotNull.concat(filesSortCulumsNull);
  }
  items = folders.concat(files);
  return items;
}

export default { sortBase, uniqueSort, sortDocuments };
