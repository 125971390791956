














































import { Component, Vue, PropSync, Prop } from 'vue-property-decorator';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

@Component({
  components: {
    BaseWindow,
    AppButton
  }
})
export default class DeleteContractDetailCard extends Vue {
  @Prop()
  value!: boolean;
  @Prop()
  windowSize!: string;
  @Prop()
  mainBtnLabel!: string;
  @Prop()
  mainBtnWidth!: string;
  @Prop()
  showSelectDialog: boolean;
  @Prop({ default: null })
  version: string;
  @PropSync('allVersionDelete', { type: String, default: '1' })
  radioVal: string;
  @Prop({ default: false })
  mainBtnDisabled: boolean;
  @Prop()
  modalText: string;

  get getVersion(): string {
    if (this.version) {
      const version = this.version.includes('.0')
        ? `V${parseInt(this.version)}`
        : this.version;
      return '表示中のバージョン(' + version + ')のみ';
    }
    return '';
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }
}
