
























































































import { Component, Vue, Watch } from 'vue-property-decorator';

import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import CorporateInfo from '@/store/CorporateInfo';

import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const SettingRepository = RepositoryFactory.get('settings');

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    AppButton,
    TextForm,
    TextOnlyWindow
  }
})
export default class ControlNumberRule extends Vue {
  is_overwrite_rule: boolean = false;
  is_show_confirm_override_modal: boolean = false;
  is_confirm_override: boolean = false;
  overwriting_now: boolean = false;
  is_error_rule: boolean = false;
  preview_control_number_rule: string = '';
  error_message: string = '';
  override_modal_text: string =
    '既存の契約書に新しいルールを反映すると、設定していた契約書No.は破棄され、新しいNo.で上書きされます。よろしいですか？';
  help_center_page: string =
    'https://tayori.com/faq/b06d57c51f1b3d4af81b4f168f8f1af7dab460fc/category/10de216d9b421ef4f4d989eec905b7aa5973bb7b';

  form_dirty: boolean = false;
  route_leave_with_dirty: object = null;

  initialFormValue = {
    control_number_rule: '',
    is_overwrite_rule: false
  };

  get control_number_rule() {
    return CorporateInfo.control_number_rule;
  }
  set control_number_rule(value) {
    CorporateInfo.SET_CONTROL_NUMBER_RULE(value);
  }

  @Watch('control_number_rule')
  async change_control_number_rule(value) {
    if (this.isErrorControlNumberRule(value)) {
      this.errorControlNumberRule(value);
      return;
    }

    this.is_error_rule = false;
    this.error_message = '';
    await this.fetchControlNumberPreview();
  }

  @Watch('$route', { immediate: true })
  async changeRoute() {
    if (this.$route.name == 'control_number_rule') {
      await CorporateInfo.get_corporate_info();
      await this.fetchControlNumberPreview();
      this.initialFormValue.control_number_rule = this.control_number_rule;
      this.initialFormValue.is_overwrite_rule = this.is_overwrite_rule;
    }
  }

  mounted() {
    this.initialFormValue.control_number_rule = this.control_number_rule;
    this.initialFormValue.is_overwrite_rule = this.is_overwrite_rule;
  }

  async fetchControlNumberPreview() {
    if (this.isErrorControlNumberRule(this.control_number_rule)) {
      this.errorControlNumberRule(this.control_number_rule);
      return;
    }

    await this.getControlNumberPreview();
  }

  async getControlNumberPreview() {
    await SettingRepository.getControlNumberPreview(this.control_number_rule)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        this.preview_control_number_rule = res.data;
      })
      .catch(err => {
        // 採番ルール入力値が変更されるたびにAPIコールしているため、400エラーの場合はスナックバーを表示しない
        if (err.response.status === HTTP_STATUS.BAD_REQUEST) return;
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  onChange() {
    this.form_dirty = true;
  }

  get isDirty() {
    return (
      this.form_dirty &&
      (this.initialFormValue.control_number_rule !== this.control_number_rule ||
        this.initialFormValue.is_overwrite_rule !== this.is_overwrite_rule)
    );
  }

  save() {
    if (this.is_overwrite_rule) {
      this.is_show_confirm_override_modal = true;
    } else {
      this.update_control_number_rule();
    }
  }

  async update_control_number_rule() {
    this.overwriting_now = true;
    const params = {
      control_number_rule: this.control_number_rule,
      is_override: this.is_overwrite_rule
    };
    await SettingRepository.updateControlNumberPreview(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        // 初期値更新
        this.initialFormValue.control_number_rule = this.control_number_rule;
        this.form_dirty = false;

        if (this.is_overwrite_rule) {
          this.is_show_confirm_override_modal = false;
          notify.success({
            text: '契約書No.ルールを保存し、既存のNo.を上書きしました'
          });
        } else {
          notify.success({
            text: '契約書No.ルールを保存しました'
          });
        }
      })
      .catch(() => {
        notify.error({
          text: '不正なルールです'
        });
      })
      .finally(() => {
        this.overwriting_now = false;
      });
  }

  confirm_override() {
    this.update_control_number_rule();
  }

  get isLeaveWithDirty() {
    return this.route_leave_with_dirty !== null;
  }

  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.route_leave_with_dirty = to;
      return;
    }
    next();
  }

  leaveAndDiscard() {
    this.form_dirty = false;
    this.$router.push(this.route_leave_with_dirty);
    this.route_leave_with_dirty = null;
  }

  cancelLeave() {
    this.route_leave_with_dirty = null;
  }

  isErrorControlNumberRule(value) {
    const regex = RegExp('^({([YMD])}[\-_/]?)*{(連番(:[YMD])?,[1-9])}$');
    return !value || !regex.exec(value);
  }

  errorControlNumberRule(value) {
    this.is_error_rule = true;
    this.preview_control_number_rule = '';
    this.error_message = '';
    if (value) {
      this.error_message = '採番ルールの形式が正しくありません';
    }
  }
}
