import { render, staticRenderFns } from "./TableSelectTab.vue?vue&type=template&id=0ff83fd4&scoped=true&lang=pug&"
import script from "./TableSelectTab.vue?vue&type=script&lang=ts&"
export * from "./TableSelectTab.vue?vue&type=script&lang=ts&"
import style0 from "./TableSelectTab.vue?vue&type=style&index=0&id=0ff83fd4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0ff83fd4",
  null
  
)

export default component.exports