






















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BaseWindow extends Vue {
  @Prop()
  value!: boolean;

  // S, M, L, XL
  @Prop()
  windowSize!: string;

  @Prop({ default: false })
  isWhiteSpace?: boolean;

  @Prop({ type: String, default: '' })
  contentClass?: string;

  @Prop({ default: false })
  isPersistent?: boolean;

  @Prop({ default: false })
  isStickyButtons?: boolean;

  set isOpen(isOpen) {
    this.$emit('input', isOpen);
  }
  get isOpen(): boolean {
    return this.value;
  }
  get windowClass(): object {
    return {
      [`create-window--${this.windowSize}`]: true,
      'create-window--sticky-buttons': this.isStickyButtons
    };
  }
}
