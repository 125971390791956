



















































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';

import MultipleSelectBox from '@/components/atoms/selectbox/MultipleSelectBox.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import notify from '@/functions/notify';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const ObjectPermissionRepository = RepositoryFactory.get('objectPermissions');

const axios = require('axios').default;
@Component({
  components: {
    AppButton,
    MultipleSelectBox,
    BaseWindow
  }
})
export default class ViewPermissionCard extends Vue {
  departmentOptionList: any = [];
  usersOptionList: any = [];

  selectedDepartmentList: any = [];
  selectedUserList: any = [];

  @Prop({ default: null })
  contractId?: string;

  @Prop({ default: null })
  directoryId?: string;

  @Prop()
  value!: boolean;

  @Prop()
  windowSize!: string;

  @Prop()
  selectedViewPermissionData?: {
    selected_departments: [];
    selected_users: [];
  };

  @Prop({ default: false })
  permissionSaving: boolean;

  get saveButtonText(): string {
    return this.permissionSaving ? '保存中' : '保存';
  }

  get saveButtonDisabled(): boolean {
    return this.permissionSaving;
  }

  async created() {
    await Promise.all([
      this.setInitVal(),
      this.setDepartmentOption(),
      this.setUserOption()
    ]);
  }

  @Watch('contractId')
  async setInitDataContract() {
    // TODO: WatchでAPIコールしない
    // @See: https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/284
    // 契約書が変わった場合はデータを再取得
    await this.created();
  }

  @Watch('directoryId')
  async setInitDataDirectory() {
    // TODO: WatchでAPIコールしない
    // @See: https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/284
    // 契約書が変わった場合はデータを再取得
    await this.created();
  }

  async setDepartmentOption() {
    const url = '/user-management/departments/lists';
    await axios
      .get(url)
      .then(response => {
        this.departmentOptionList = response.data;
      })
      .catch(error => {
        notify.error({
          text: '選択可能な部署を取得できませんでした'
        });
      });
  }

  selectDepartment(selectedData: any) {
    this.selectedDepartmentList = selectedData;
  }

  async setUserOption() {
    const url = '/user-management/users/corporations/same/me';
    await axios
      .get(url)
      .then(response => {
        this.usersOptionList = response.data['user_list'];
      })
      .catch(error => {
        notify.error({
          text: '選択可能なユーザーを取得できませんでした'
        });
      });
  }

  /**
   * 連想配列の配列の重複削除
   */
  filterUniqueItems(array, key) {
    // idを集約した配列を作成
    const itemIds = array.map(function(item) {
      return item[key];
    });
    //
    return array.filter(function(item, index) {
      return itemIds.indexOf(item[key]) === index;
    });
  }

  /**
   * 契約書を閲覧可能なユーザー情報の取得
   */
  async setInitVal() {
    if (this.contractId && !this.directoryId) {
      await ObjectPermissionRepository.getContractPermissions(
        this.contractId
      ).then(response => {
        const data = response.data;
        this.selectedUserList = data.user_list;
        this.selectedDepartmentList = data.department_list;
      });
      return;
    }

    if (this.directoryId && !this.contractId) {
      await ObjectPermissionRepository.getDirectoryPermissions(
        this.directoryId
      ).then(response => {
        const data = response.data;
        this.selectedUserList = data.user_list;
        this.selectedDepartmentList = data.department_list;
      });
      return;
    }

    if (this.selectedViewPermissionData) {
      if (this.selectedViewPermissionData.selected_departments.length > 0) {
        this.selectedDepartmentList = this.selectedViewPermissionData.selected_departments;
      }

      if (this.selectedViewPermissionData.selected_users.length > 0) {
        this.selectedUserList = this.selectedViewPermissionData.selected_users;
      }
    }
  }

  resetSelected() {
    this.selectedUserList = [];
    this.selectedDepartmentList = [];
    this.usersOptionList = [];
    this.departmentOptionList = [];
  }

  @Emit()
  cancel() {
    return;
  }

  @Emit()
  save() {
    const data = {
      selected_users: this.selectedUserList,
      selected_departments: this.selectedDepartmentList
    };
    return data;
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }

  beforeDestroy() {
    this.resetSelected();
  }
}
