


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import InfoAssigneeItem from '@/components/organisms/DocumentDetail/InfoAssigneeItem.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';

type VueSelectboxData = {
  user_id: number;
  user_name: string;
};

@Component({
  components: {
    InfoAssigneeItem,
    SelectBox,
    AppButton,
    BaseWindow
  }
})
export default class SettingContractApplyModal extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  windowSize!: string;

  @Prop(Array)
  readonly selectOptions: Array<VueSelectboxData>;

  @Prop()
  setAssignee: string;

  selectedAssignee = null;

  mounted() {
    this.initSelectedAssignee();
  }

  @Watch('setAssignee')
  initSelectedAssignee() {
    if (this.setAssignee) {
      this.selectedAssignee = this.setAssignee;
    }
  }

  selectAssignee(value) {
    this.$emit('selectAssignee', value);
  }

  clickSubmitButton() {
    this.$emit('submit');
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
    this.closeModal();
  }

  closeModal() {
    this.$emit('close');
    setTimeout(this.initSelectedAssignee, 150);
  }

  get actionMark() {
    return '';
  }
}
