
















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import InfoAssigneeItem from '@/components/organisms/DocumentDetail/InfoAssigneeItem.vue';
import AppCalendar from '@/components/atoms/AppCalendar.vue';
import InfoListItem from '@/components/organisms/DocumentDetail/DocumentInfo/InfoListItem.vue';
import Documents from '@/store/Documents';
import ContractInfo from '@/store/ContractInfo';
import dayjs from 'dayjs';
import UserInfo from '@/store/UserInfo';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import HTTP_STATUS from '@/consts/HttpStatus';
import LOADING_STATUSES from '@/consts/LoadingStatuses';

const ContractInfoRepository = RepositoryFactory.get('contractInfos');

import { Assignee } from '@/interfaces/Users';

@Component({
  components: {
    SelectBox,
    InfoAssigneeItem,
    AppCalendar,
    InfoListItem
  }
})
export default class DocumentInfoTask extends Vue {
  @Prop({ required: true })
  contractDetail: any;
  @Prop({ default: false, type: Boolean })
  isSigned?: boolean;

  taskExpiration: string = '';
  isDisabledAssigneeSelect: boolean = false;
  selectableAssignees: Assignee[] = [];

  savingStatuses: object = {
    assignee_user_id: LOADING_STATUSES.DEFAULT,
    task_expiration: LOADING_STATUSES.DEFAULT
  };

  mounted() {
    this.taskExpiration = this.task.task_expiration;

    this.selectableAssignees = this.assignees.filter(
      x => x.user_id !== this.assignee.user_id
    );
  }

  selectedAssignee() {
    this.isDisabledAssigneeSelect = true;
    setTimeout(() => {
      this.isDisabledAssigneeSelect = false;
    }, 200);
  }

  @Watch('assignee')
  changeAssignee(assignee) {
    this.selectableAssignees = this.assignees.filter(
      x => x.user_id !== assignee.user_id
    );
  }

  @Watch('taskExpiration')
  changeTaskExpiration(val) {
    this.taskExpiration = dayjs(val).format('YYYY-MM-DD');
    Documents.SET_KANBAN_CARD_PROPS({ task_expiration: this.taskExpiration });
  }

  get permission() {
    return UserInfo.permission;
  }

  get task() {
    return this.contractDetail.kanban_card_info || {};
  }

  get assignee() {
    return this.task.assignee || {};
  }

  set assignee(assignee) {
    Documents.SET_KANBAN_CARD_PROPS({ assignee });
    this.task.assignee = assignee;
  }

  @Watch('task', { deep: true })
  changeTask(task) {
    this.taskExpiration = task.task_expiration;
  }

  get assignees() {
    return ContractInfo.assigneesList;
  }

  get contract_info() {
    return this.contractDetail.contract_info;
  }

  get selectedVersionId() {
    const selectedVersion = Documents.selectedVersion;
    if (selectedVersion) {
      return selectedVersion.contract_version_id;
    } else {
      return null;
    }
  }

  async updateTask(params) {
    const paramKeys = Object.keys(params);
    paramKeys.forEach(k => (this.savingStatuses[k] = LOADING_STATUSES.LOADING));
    await ContractInfoRepository.updateContractCardTask({
      card_id: this.task.card_id,
      contract_id: this.contract_info.contract_id,
      contract_version_id: this.selectedVersionId,
      ...params
    })
      .then(async res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          paramKeys.forEach(
            k => (this.savingStatuses[k] = LOADING_STATUSES.FAILURE)
          );
          return;
        }
        paramKeys.forEach(
          k => (this.savingStatuses[k] = LOADING_STATUSES.COMPLETE)
        );
        await Documents.getComments(
          this.contractDetail.contract_info.contract_id
        )
          .then(async res => {
            if (res.status !== HTTP_STATUS.OK) {
              paramKeys.forEach(
                k => (this.savingStatuses[k] = LOADING_STATUSES.FAILURE)
              );
              return;
            }
          })
          .catch(err => {
            paramKeys.forEach(
              k => (this.savingStatuses[k] = LOADING_STATUSES.FAILURE)
            );
          });
      })
      .catch(e => {
        paramKeys.forEach(
          k => (this.savingStatuses[k] = LOADING_STATUSES.FAILURE)
        );
      });
  }

  /**
   * 日付フォーマットを変換して返す
   */
  formatDate(date): string {
    if (date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm');
    } else {
      return '';
    }
  }
}
