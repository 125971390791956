
















import { Component, Vue, Prop } from 'vue-property-decorator';
import TextFormName from '@/components/atoms/texts/TextFormName.vue';
import TextFormMidSize from '@/components/atoms/text_forms/TextFormMidSize.vue';

@Component({
  components: {
    TextFormName,
    TextFormMidSize
  }
})
export default class TextFormWithTitle extends Vue {
  @Prop()
  title_text!: string;
  @Prop({ default: 'text' })
  form_type?: string;
  @Prop()
  val_key!: string;
  @Prop()
  message?: string;
  @Prop()
  maxlength?: number;
  @Prop({ default: false })
  isError: boolean;
}
