











import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class BaseTable extends Vue {
  height: number = 0;

  @Prop({ default: 'all' })
  scroll?: string;

  @Prop({ type: Object as () => {} })
  tableClass?: {};

  @Prop({ default: 0 })
  maxHeight?: number;

  @Prop({ default: '' })
  tableRules?: string;

  @Prop({ default: false })
  empty?: string;

  get classStickyTable() {
    return {
      'scroll-y-only': this.scroll === 'y-only',
      'scroll-x-only': this.scroll === 'x-only',
      scroll: this.scroll === 'all'
    };
  }

  mounted() {
    window.addEventListener('resize', this.calcTableHeight);
    this.calcTableHeight();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.calcTableHeight);
  }

  calcTableHeight() {
    if (this.maxHeight > 0) {
      this.height = this.maxHeight;
      return;
    }
    if (!this.$el) {
      return;
    }
    const top = this.$el.getBoundingClientRect().top;
    this.height = window.innerHeight - top;
  }
}
