


























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';

@Component({
  components: {
    SelectBox
  }
})
export default class DepartmentSimpleSelectBox extends Vue {
  @Prop()
  can_change_input: boolean;

  @Prop()
  value: any;

  @Prop()
  can_select: boolean;

  @Prop()
  departments: [];

  select_department: any = {};

  mounted() {
    this.select_department = this.value;
  }

  @Watch('value')
  update_value(new_value, value) {
    this.select_department = new_value;
  }

  @Watch('select_department')
  update_department(new_value) {
    this.$emit('input', new_value);
  }
}
