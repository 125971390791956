















































import dayjs from 'dayjs';
import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import DropdownMenuContainer from '@/components/molecules/DropdownMenuContainer.vue';
import VersionContent from '@/components/atoms/buttons/VersionContent.vue';
import Documents from '@/store/Documents';
import ContractInfo from '@/store/ContractInfo';
import UserInfo from '@/store/UserInfo';
import Associations from '@/store/Associations';
import notify from '@/functions/notify';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import FileUpload from '@/functions/FileUpload';
import { catchOnGetContractDetail } from '@/functions/DocumentRouter';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');

@Component({
  components: {
    DropdownMenuContainer,
    VersionContent
  }
})
export default class VersionInfo extends Vue {
  @Prop({ required: true }) contractDetail: any;

  get permission() {
    return UserInfo.permission;
  }

  get kanbanCardInfo() {
    return this.contractDetail.kanban_card_info;
  }

  get columns() {
    return ContractInfo.sortedContractBoardColumnsData;
  }

  get activeColumn() {
    return this.columns.find(
      c => this.kanbanCardInfo.column_id === c.column_id
    );
  }

  get isLastColumns(): boolean {
    if (!this.activeColumn) return false;
    return this.activeColumn.is_finish_column;
  }

  // 締結管理状態の契約書も締結済アップロードを行っている場合は、締結済として扱う
  // Documents.isSignedは、締結済ドキュメントかどうかを表しているため、カラムの場所も考慮する
  get isSigned(): boolean {
    return Documents.isSigned || this.isLastColumns;
  }

  get selectedVersion() {
    return Documents.selectedVersion;
  }

  get lastUpdateDate(): string {
    if (!this.contractDetail.last_updated_at) {
      return '';
    }
    return dayjs(this.contractDetail.last_updated_at).format(
      'YYYY/MM/DD HH:mm'
    );
  }

  get versions() {
    const versions = this.contractDetail.contract_version_list;
    const lastestVersion = versions[0];
    if (lastestVersion && lastestVersion.is_last_version) {
      lastestVersion.version = '締結済';
    }
    return versions;
  }

  get isUseGMOSign() {
    if (this.contractDetail.gmosign_info.xid != null) {
      return true;
    }
    return false;
  }

  get GMOSignTooltipText() {
    if (this.contractDetail.gmosign_info.document_status === '99') {
      return 'GMOサイン: 署名済み';
    }
    return 'GMOサイン: 未署名';
  }

  @Watch('versions', { deep: true })
  async changeVersionList(versions, oldVersions) {
    if (versions.length !== oldVersions.length) {
      Documents.SELECT_VERSION(versions[0]);
      // TODO: WatchでAPIコールしない
      // @See: https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/2844
      // NOTE: 更新されたversionのHTML PreviewをAPI経由で取得している
      await Documents.selectVersion(versions[0]);
    }
  }

  selectVersion(version) {
    Documents.selectVersion(version);
    this.changeVersion();
  }

  @Emit()
  startUpload(fileCount) {
    return fileCount;
  }

  @Emit()
  endUpload() {
    return;
  }

  @Emit()
  changeVersion() {
    return;
  }

  @Emit()
  async uploadVersion(event) {
    const isCheckFileSize = FileUpload.checkAllowedFileSize(
      event.target.files[0].size
    );
    if (!isCheckFileSize) return;

    const fileCount = event.target.files.length;

    this.startUpload(fileCount);

    const formData = new FormData();
    formData.append('contract', event.target.files[0]);

    const params = {
      contract_id: this.contractDetail.contract_info.contract_id,
      is_last_version: false,
      is_stored: false,
      formData
    };

    await DocumentRepository.uploadNewVersion(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
          this.endUpload();
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
        });
        this.endUpload();
        return;
      });

    await Documents.reloadContractDetail().catch(err => {
      catchOnGetContractDetail(this.$router, err);
    });
    await Associations.getCandidates();
    const version_list = this.contractDetail.contract_version_list;
    if (version_list.length) {
      await Documents.selectVersion(version_list[0]);
    }
    await this.changeVersion();

    this.endUpload();

    // アップロード完了
    notify.success({
      text: fileCount + NOTIFY_TEXT.SUCCESS.UPLOAD_SUFFIX
    });
  }

  async uploadContractedVersion(event) {
    const isCheckFileSize = FileUpload.checkAllowedFileSize(
      event.target.files[0].size
    );
    if (!isCheckFileSize) return;

    const fileCount = event.target.files.length;

    this.startUpload(fileCount);

    const formData = new FormData();
    formData.append('contract', event.target.files[0]);

    const params = {
      contract_id: this.contractDetail.contract_info.contract_id,
      formData
    };

    await DocumentRepository.uploadNewContractedVersion(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
          this.endUpload();
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
        });
        this.endUpload();
        return;
      });

    await Documents.reloadContractDetail().catch(err => {
      catchOnGetContractDetail(this.$router, err);
    });
    await Associations.getCandidates();
    const version_list = this.contractDetail.contract_version_list;
    if (version_list.length) {
      await Documents.selectVersion(version_list[0]);
    }
    await this.changeVersion();

    this.endUpload();

    // アップロード完了
    notify.success({
      text: fileCount + NOTIFY_TEXT.SUCCESS.UPLOAD_SUFFIX
    });
  }
}
