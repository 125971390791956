



























import { Component, Vue, Prop } from 'vue-property-decorator';
import ExtractIcon from '@/components/molecules/ExtractIcon.vue';
import IconLoadingSpinner from '@/components/atoms/icons/IconLoadingSpinner.vue';
import LOADING_STATUSES from '@/consts/LoadingStatuses';

@Component({
  components: {
    ExtractIcon,
    IconLoadingSpinner
  }
})
export default class InfoItem extends Vue {
  @Prop({ required: true, type: String }) name: string;
  @Prop({ default: false, type: Boolean }) disabled: boolean;
  @Prop({ default: false, type: Boolean }) isAutoExtract: boolean;
  @Prop({ default: false, type: Boolean }) isProcessing: boolean;
  @Prop({ type: String }) status: string;
  // ラベル選択コンポーネントの挙動不審回避用
  @Prop({ default: 'label', type: String }) contentContainerTagName: string;

  get loadingStatuses() {
    return LOADING_STATUSES;
  }

  get loadingStatusClasses() {
    return {
      'save-complete': this.status === this.loadingStatuses.COMPLETE
    };
  }
}
