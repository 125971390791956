












import { Component, Vue } from 'vue-property-decorator';
import Logo from '@/components/atoms/logos/Logo.vue';
import AppNotifications from '@/components/atoms/AppNotifications.vue';

@Component({
  components: {
    Logo,
    AppNotifications
  }
})
export default class Top extends Vue {}
