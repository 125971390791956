


































import { Component, Watch, Vue } from 'vue-property-decorator';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');

interface Window {
  webkitURL: any;
  URL: any;
  FormData: any;
}

declare let window: Window;

@Component({
  components: {
    TextOnlyWindow
  }
})
export default class FileRestore extends Vue {
  files: File[] = [];
  showRestoreObjectModal: boolean = false;
  uploadModalBody: string =
    '既存の契約書データがある場合、ファイルの復元によって上書きされます。<br>ファイルを復元してもよろしいでしょうか？';
  event: any = {};

  @Watch('showRestoreObjectModal')
  initFiles() {
    if (!this.showRestoreObjectModal) {
      this.files = [];
    }
  }

  selectUploadFile(event) {
    const files: File[] = event.target.files;
    const fileCount = files.length;
    if (!fileCount) {
      return;
    }

    this.files = files;
    this.event = event;
    this.showRestoreObjectModal = true;
  }

  upload() {
    const files: File[] = this.event.target.files;
    const fileCount = files.length;

    const formData = new FormData();
    files.forEach(file => {
      formData.append('contract_backup', file);
    });

    DocumentRepository.restoreContract(formData)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.RESTORE_FILE
          });
          return;
        }
        notify.success({
          text: `${fileCount}${NOTIFY_TEXT.SUCCESS.RESTORE_FILE}`
        });
      })
      .catch(err => {
        if (err.response.status === HTTP_STATUS.BAD_REQUEST) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.RESTORE_FAILED_FILE
          });
        } else if (err.response.status === HTTP_STATUS.SERVICE_UNAVAILABLE) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.RESTORING_FILE
          });
        } else {
          notify.error({
            text: NOTIFY_TEXT.ERROR.RESTORE_FILE
          });
        }
      })
      .finally(() => {
        const element: HTMLInputElement = document.getElementById(
          'inputFile'
        ) as HTMLInputElement;
        element.value = '';
        this.showRestoreObjectModal = false;
      });
  }

  openRestoreObjectModal() {
    this.showRestoreObjectModal = true;
  }

  closeRestoreObjectModal() {
    this.event.target.value = '';
    this.showRestoreObjectModal = false;
  }
}
