










import { Component, Vue, Prop } from 'vue-property-decorator';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';

@Component({
  components: {
    TextAvatar
  }
})
export default class AvatarInfoIcon extends Vue {
  @Prop({ required: true })
  user;

  @Prop()
  roleName: string;

  get avatarColor() {
    let color = '#002bff';
    if (this.user.avatar_color) {
      color = this.user.avatar_color;
    }
    return color;
  }

  get tooltipContent() {
    let content =
      '<span class="user_name">' + this.user.user_name + '<\/span><br>';
    if (this.user.department_name) {
      content = content + this.user.department_name + '<br>';
    }
    if (this.roleName) {
      content = content + this.roleName;
    }
    return content;
  }
}
