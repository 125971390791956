
















import { Component, Vue } from 'vue-property-decorator';
import vLoading from 'vue-loading-overlay';

@Component({
  components: {
    vLoading
  }
})
export default class Loading extends Vue {}
