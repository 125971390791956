


















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import FileUpload from '@/functions/FileUpload';

@Component({
  components: {
    BaseWindow,
    AppButton
  }
})
export default class SignedUploadModal extends Vue {
  @Prop(Boolean)
  value: boolean;

  @Prop()
  windowSize!: string;

  selectedFile = null;
  isStored: boolean = false;

  select(event) {
    const isCheckFileSize = FileUpload.checkAllowedFileSize(
      event.target.files[0].size
    );
    if (!isCheckFileSize) return;

    this.selectedFile = event.target.files[0];
  }

  @Watch('value')
  changeValue(val) {
    if (!val) this.selectedFile = null;
  }

  @Emit()
  save() {
    return {
      file: this.selectedFile,
      is_stored: this.isStored
    };
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }
}
