




















import { Component, Vue } from 'vue-property-decorator';
import PasswordCheckItem from '@/components/molecules/icon_text/PasswordCheckItem.vue';
import UserInfoModule from '@/store/UserInfo';

@Component({
  components: {
    PasswordCheckItem
  }
})
export default class PasswordCheckContainer extends Vue {
  public get pw_length(): number {
    return UserInfoModule.pw_length;
  }

  public get pw_has_lower_case(): boolean {
    return UserInfoModule.pw_has_lower_case;
  }

  public get pw_has_upper_case(): boolean {
    return UserInfoModule.pw_has_upper_case;
  }

  public get pw_has_number(): boolean {
    return UserInfoModule.pw_has_number;
  }
}
