


































import { Component, Vue, Prop } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

/**
 * サービスプラン情報更新画面用Component
 */
@Component({
  components: {
    AppButton
  }
})
export default class ServicePlanDetail extends Vue {
  @Prop({ default: false })
  is_free_trial: boolean;

  @Prop({ default: '' })
  service_plan_expiration: string;

  @Prop({ default: '' })
  service_plan_start: string;

  @Prop({ default: '' })
  service_plan_name: string;

  to_update_paid(): void {
    window.open('https://clmlp.liris.co.jp/inquiry', '_blank');
  }
}
