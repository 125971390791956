const NOTIFY_TEXT = {
  SUCCESS: {
    CREATED_FOLDER: 'フォルダを作成しました',
    UPLOADING_FILES: '個のファイルをアップロードしています･･･',
    DELETE: '削除しました',
    DELETE_USER: 'ユーザーを削除しました',
    ADD_DEPARTMENT: '部署を追加しました',
    DELETE_DEPARTMENT: '部署を削除しました',
    CANCEL: '取り消しが完了しました',
    CANCEL_UPLOADING: 'キャンセルしました',
    UPLOAD_SUFFIX: '個のファイルをアップロードしました',
    CHANGE_FOLDER_NAME: 'フォルダ名を変更しました',
    CHANGE_FILE_NAME: 'ファイル名を変更しました',
    CHANGE_PASSWORD: 'パスワードを変更しました',
    ATTACHING_DOCUMENT: '個の資料を添付しています･･･',
    ATTACHED_DOCUMENT: '個の資料を添付しました',
    RELATED_CONTRACT: '関連契約を紐付けました',
    SETTING_VIEW_PERMISSION: '閲覧権限を設定しました',
    MOVED_SIGNE_DOCUMENT: 'を締結済ドキュメントに移動しました',
    MOVED_BULK_SIGNE_DOCUMENT:
      'すべてのカードを締結済みドキュメントに移動しました',
    CHANGE_DEPARTMENT_NAME: '部署名を変更しました',
    DELETE_COMMENT: 'コメントを削除しました',
    REMOVE_ASSOCIATION: '関連契約書の紐付けを解除しました',
    INVITE_EMAIL: '招待メールを送信しました',
    EMPTY_TRASH: 'ゴミ箱を空にしました',
    SET_APPLY_TO: '依頼先を設定しました',
    RESTORE_FILE: '個のファイルを復元しました',
    ARCHIVED: 'をアーカイブに移動しました',
    UNARCHIVED: 'をアーカイブから移動しました',
    GMOSIGN: {
      COOPERATED:
        'GMOサインと連携しました。続いて電子署名利用ユーザーを設定してください。',
      RELEASED: 'GMOサインとの連携を解除しました',
      ADDUSERS: 'ユーザーを追加しました',
      DELETEUSERS: '電子署名利用ユーザーから除外しました',
      DELETEDOCUMENT: 'GMOサインを取り消しました。'
    },
    NO_DROP_ZONE: 'ここにはドロップできません'
  },
  ERROR: {
    GENERAL: 'エラーが発生しました',
    CREATED_FOLDER: 'フォルダの作成に失敗しました',
    CREATED_CARD: 'カードの作成に失敗しました',
    UPDATE_CONTRACT_CARD_TASK: 'カードの更新に失敗しました',
    UPDATE_CONTRACT_CARD_STATUS: 'カードステータスの更新に失敗しました',
    MOVE: '移動時にエラーが発生しました',
    UNAUTHORIZED: '権限がありません',
    DELETE: '削除できませんでした',
    DELETE_USER: 'ユーザーを削除できませんでした',
    ADD_DEPARTMENT: '部署の追加に失敗しました',
    DELETE_DEPARTMENT: '部署を削除できませんでした',
    CANCEL: '取り消しが失敗しました',
    OVERSIZE_UPLOAD_SUFFIX: '10MB以上のファイルはアップロードできません',
    OVER_UPLOAD_FILE_NUM_LIMIT:
      '一度に%nファイルを超えるアップロードはできません',
    FAKE_EXTENSION: 'は拡張子が偽装されています',
    FILE_IS_DOC: 'をWordで開いて名前を付けて保存の時にdocxを選択してください',
    NOT_EXIST_UPLOAD_FILE: 'アップロードするファイルがありません',
    CANNOT_UPLOAD: 'docx, xlsx, pdf以外のファイルはアップロードできません',
    CANNOT_UPLOAD_DOCX_ONLY: 'docx以外のファイルはアップロードできません',
    CHANGE_PASSWORD: 'パスワードの変更に失敗しました',
    ATTACHED_DOCUMENT: '資料の添付に失敗しました',
    ALREADY_EXIST_FOLDER: 'すでに同名のフォルダが存在します',
    RELATED_CONTRACT: '関連契約紐付に失敗しました',
    UPDATE_CONTRACT_DETAIL: '契約詳細の更新に失敗しました',
    MOVED_SIGNE_DOCUMENT: 'を締結済ドキュメントに移動できませんでした',
    UPLOAD_FILE: 'ファイルのアップロードに失敗しました',
    CHANGE_FILE_NAME: 'ファイル名の変更に失敗しました',
    CHANGE_FOLDER_NAME: 'フォルダ名の変更に失敗しました',
    RESTORE_TRASH: 'ゴミ箱から復元できませんでした',
    DELETE_DEPARTMENT_USER: '所属ユーザーを削除できませんでした',
    DELETE_COMMENT: 'コメントを削除できませんでした',
    REMOVE_ASSOCIATION: '関連契約書の紐付けを解除できませんでした',
    INVITE_EMAIL: '招待メールを送信できませんでした',
    ALREADY_EXIST_EMAIL: 'このメールアドレスはすでに登録されています',
    EXCEEDING_LIMIT: '追加可能なユーザー数の上限を超えています',
    EMPTY_TRASH: 'ゴミ箱を空にできませんでした',
    COMMENT: 'コメントに失敗しました',
    EDIT_COMMENT: 'コメントの編集に失敗しました',
    GET_COMMENT: 'コメントの取得に失敗しました',
    GET_COMMENTABLE_USER: 'メンション可能なユーザーの取得に失敗しました',
    GET_RECENTLY_VIEWED_FILE: '最近表示のファイル取得に失敗しました',
    GET_TRASH: 'ゴミ箱の取得に失敗しました',
    BACKUP_FILE: 'バックアップに失敗しました',
    RESTORE_FILE: 'ファイルの復元に失敗しました',
    RESTORE_FAILED_FILE: 'バックアップファイルをアップロードしてください',
    RESTORING_FILE:
      'システムがビジー状態です。20分程度の時間を置いてからやり直してください',

    SERVICE_EXPIRED: {
      OWNER: 'サービスの有効期限が切れています。プランを変更してください。',
      NOT_OWNER:
        'サービスの有効期限が切れています。オーナー権限の方がプランを変更してください。'
    },
    SETTING_VIEW_PERMISSION: {
      EMPTY: '閲覧権限を設定するファイルがありません',
      FAILED: '閲覧権限の設定に失敗しました'
    },
    NOT_ALLOWED: {
      MOVE_CARD: 'カードを移動させる権限がありません'
    },
    SEARCH: '検索に失敗しました',
    SET_APPLY_TO: '依頼先の設定でエラーが発生しました',
    CAN_NOT_CREATE_FOLDER_FOR_SMALL_PLAN:
      'スモールプランでは4階層以上のフォルダを作成できません',
    ALREADY_EXIST_CONTROL_NUMBER: 'この契約書No.はすでに登録されています',
    CANNOT_DOWNLOAD_EMPTY_FOLDER: '空のフォルダはダウンロードできません',
    FAILED_DOWNLOAD: 'ダウンロードに失敗しました',
    ARCHIVE_CONTRACT: 'アーカイブに失敗しました',
    GMOSIGN: {
      COOPERATED:
        'GMOサインとの連携に失敗しました。顧客IDとシークレットキーを確認してください。',
      RELEASED: 'GMOサインとの連携解除に失敗しました',
      ADDUSERS: 'ユーザー追加に失敗しました',
      DELETEUSERS: '電子署名利用ユーザーから除外できませんでした',
      DELETEDOCUMENT: 'GMOサインの取り消しに失敗しました',
      POSITION_ERROR: '全ての署名者の署名位置を決めてください'
    }
  }
};

export default NOTIFY_TEXT;
