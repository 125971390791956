








import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class Icon extends Vue {
  @Prop({ default: ['far', 'check-circle'] })
  icon_class?: string[];
  @Prop({ default: 'red' })
  color_name?: string;
  @Prop()
  size?: string;

  get icon_style() {
    return {
      color: this.color_name
    };
  }
}
