



















































import { Component, Vue } from 'vue-property-decorator';
import SettingsInfoModule from '@/store/SettingsInfo';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

import DepartmentMembersTable from '@/components/organisms/Settings/DepartmentMembersTable.vue';
import notify from '@/functions/notify';

/**
 * アカウント情報更新画面用Component
 * 入力部品をまとめて表示・更新を行う
 */
@Component({
  components: {
    DepartmentMembersTable,
    AppButton
  }
})
export default class DepartmentMembers extends Vue {
  department_name: string = '';
  department: any = {};
  is_loaded: boolean = false;
  department_id: string = '';

  async created() {
    this.department_id = this.$route.params.id;
    const name: any = this.$route.query.name;
    this.department_name = decodeURI(name);
    this.department = {
      department_id: this.department_id,
      department_name: this.department_name
    };
    SettingsInfoModule.SET_CURRENT_DEPARTMENT(this.department);
    await SettingsInfoModule.get_users_with_department(
      this.department_id
    ).catch(e => {
      notify.error({ text: '所属ユーザーの取得ができませんでした。' });
    });
    this.is_loaded = true;
  }

  get is_empty(): boolean {
    return this.users_with_department.length === 0;
  }

  get users_with_department() {
    return SettingsInfoModule.users_with_department;
  }

  delete_users_with_department(): void {
    SettingsInfoModule.SET_CAN_OPEN_DELETE_USERS_WITH_DEPARTMENT_WINDOW(true);
  }

  /**
   * すべてのチェックボックスが checked かどうかを返す
   */
  get areAllChecked(): boolean {
    return this.selected.length === this.users_with_department.length;
  }

  /**
   * すべての項目のチェックボックスの checked を付けたり外したりする
   */
  changeCheckedAll(): void {
    if (this.areAllChecked) {
      this.selected = [];
      return;
    }

    this.selected = this.users_with_department;
  }

  get selected() {
    return SettingsInfoModule.selected_users_with_department;
  }

  set selected(new_value) {
    SettingsInfoModule.SET_SELECTED_USERS_WITH_DEPARTMENT(new_value);
  }

  async add_users_with_department() {
    SettingsInfoModule.SET_CURRENT_DEPARTMENT(this.department);
    SettingsInfoModule.SET_CAN_OPEN_ADD_USERS_WITH_DEPARTMENT_WINDOW(true);
    await SettingsInfoModule.get_users_corporations_same_me().catch(e => {
      notify.error({ text: '所属部署情報を削除できませんでした。' });
    });
  }

  beforeDestroy() {
    SettingsInfoModule.SET_USERS_WITH_DEPARTMENT([]);
    SettingsInfoModule.SET_SELECTED_USERS_WITH_DEPARTMENT([]);
  }

  back_index() {
    this.$router.push({
      name: 'department'
    });
  }
}
