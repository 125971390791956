


















import { Component, Vue, Prop } from 'vue-property-decorator';
import AppCalendar from '@/components/atoms/AppCalendar.vue';

@Component({
  components: {
    AppCalendar
  }
})
export default class SearchBoxFormCalendarPeriod extends Vue {
  @Prop({
    type: String,
    required: true
  })
  name;
  @Prop({
    type: String,
    default: ''
  })
  startDate;
  @Prop({
    type: String,
    default: ''
  })
  endDate;

  handleSelectStartDate(value) {
    this.$emit('change', this.name, 'start', value);
  }

  handleSelectEndDate(value) {
    this.$emit('change', this.name, 'end', value);
  }
}
