
























































import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';

import { GMOSignMember } from '@/interfaces/GMOSign';
import SettingsInfoModule from '@/store/SettingsInfo';
import GMOSignInfoModule from '@/store/GMOSign';
import { sortBase } from '@/functions/Sort';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

@Component({
  components: {
    SelectBox,
    BaseTable
  }
})
export default class GMOSignMemberTable extends Vue {
  selected: string[] = [];

  @Prop()
  value: GMOSignMember[];

  beforeCreate() {
    if (!SettingsInfoModule.sort[this.$route.name]) {
      SettingsInfoModule.SET_SORT({
        pageName: this.$route.name,
        columnName: 'user_name'
      });
    }
  }

  mounted() {
    this.sortTableBodyData();
  }

  setSort(columnName: string) {
    SettingsInfoModule.SET_SORT({ pageName: this.$route.name, columnName });
    this.sortTableBodyData();
  }

  sortTableBodyData(): void {
    sortBase(this.value, this.sort.columnName, {
      isAsc: this.sort.asc
    });
  }

  get sort(): {
    columnName: string;
    asc: boolean;
  } {
    return SettingsInfoModule.sort[this.$route.name];
  }

  get selected_users() {
    return GMOSignInfoModule.selected_gmosign_users;
  }
  set selected_users(value) {
    GMOSignInfoModule.SET_SELECTED_GMOSIGN_USERS(value);
  }

  getRoleName(role: boolean) {
    if (role) {
      return '署名者ロールあり';
    }
    return '署名者ロールなし';
  }
}
