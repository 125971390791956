import {
  LirisNotificationCreate,
  LirisNotificationDetail
} from '@/interfaces/LirisNotification';

const axios = require('axios').default;
const resource = '/contract-management/liris_notifications';

export default {
  /**
   * 一覧取得
   */
  index() {
    return axios.get(resource);
  },

  /**
   * 詳細取得
   *
   * @param { String } id
   * @param { String } idToken
   */
  show(id: string, idToken: string) {
    return axios.get(`${resource}/${id}`, {
      headers: { 'id-token': idToken }
    });
  },

  /**
   * 作成
   *
   * @param { Object } params
   * @param { String } idToken
   */
  create(params: LirisNotificationCreate, idToken: string) {
    // multipart/form-data形式に変更
    const data = new FormData();
    data.append('title', params.title);
    data.append('contents', params.contents);
    data.append('url', params.url);
    data.append('type', String(params.selected_type.id));
    if (params.notification_file.name) {
      data.append('notification_file', params.notification_file);
    }

    return axios.post(resource, data, {
      headers: { 'id-token': idToken }
    });
  },

  /**
   * 更新
   *
   * @param { Object } params
   * @param { String } idToken
   */
  update(params: LirisNotificationDetail, idToken: string) {
    return axios.put(`${resource}/${params.liris_notification_id}`, params, {
      headers: { 'id-token': idToken }
    });
  },

  /**
   * 削除
   *
   * @param { String } id
   * @param { String } idToken
   */
  delete(id: string, idToken: string) {
    return axios.delete(`${resource}/delete/${id}`, {
      params: {
        liris_notification_id: id
      },
      headers: {
        'id-token': idToken
      }
    });
  },

  /**
   * お知らせ履歴に書き込み
   *
   * @param { String } id
   */
  read(id: string) {
    return axios.post(`${resource}/read/${id}`, {
      liris_notification_id: id
    });
  },

  /**
   * ファイルのダウンロードリンク取得
   *
   * @param { String } id
   */
  getFileLink(id: string) {
    return axios.get(`${resource}/download_link/${id}`);
  }
};
