
































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import ContractLabels from '@/components/molecules/labels/ContractLabels.vue';
import SettingsInfoModule, {
  AlertLimit,
  AlertLimitType
} from '@/store/SettingsInfo';
import UserInfo from '@/store/UserInfo';
import Documents from '@/store/Documents';
import AUTHORITIES from '@/consts/Authorities.js';
import { change_folder } from '@/functions/DocumentRouter';
import IconLoadingSpinner from '@/components/atoms/icons/IconLoadingSpinner.vue';

@Component({
  components: {
    ContractLabels,
    ImageContent,
    IconLoadingSpinner
  }
})
export default class ObjectTableBodyRow extends Vue {
  @Prop({ required: true }) obj;
  @Prop({ default: false }) concluded: boolean;
  @Prop({ default: false }) selectedItems: any[];
  @Prop({ default: false }) isAllSelected: boolean;
  isSelected: boolean = false;
  folderDragOvered: boolean = false;
  innerFlag: boolean = false;

  @Watch('isAllSelected')
  mark_select() {
    this.isSelected = this.isAllSelected;
  }

  dragoverEvent(event) {
    if (this.obj.contract_id) {
      // fileなら
      this.$emit('dragover');
      return;
    }
    this.$emit('folder-dragover', { folder: this.obj });
    this.innerFlag = false;
    this.folderDragOvered = true;
  }

  dragleaveEvent(event) {
    // NOTE: 対象要素から子要素へドラッグ	子要素でdragenter → 対象要素でdragleave
    if (this.innerFlag) return (this.innerFlag = false);
    else this.folderDragOvered = false;

    if (this.obj.directory_name) {
      this.$emit('folder-dragleave');
    }
  }

  drogEvent(event: DragEvent) {
    if (this.obj.contract_id) {
      // fileなら
      this.$emit('drop', event);
      return;
    }
    this.folderDragOvered = false;
    this.$emit('drop-on-folder', { event });
  }

  get checkId() {
    return `chk_${this.obj.contract_id || this.obj.directory_id}`;
  }

  get isGuest() {
    return UserInfo.authority === AUTHORITIES.GUEST;
  }

  get AlertLimitType() {
    return AlertLimitType;
  }

  get alertStatus(): AlertLimit {
    return SettingsInfoModule.alertStatus(
      this.obj.expiration,
      this.obj.auto_update_date,
      this.obj.is_scheduled_end,
      this.obj.is_ended
    );
  }

  get itemColor() {
    if (this.selected) {
      return 'item-selected';
    }

    switch (this.alertStatus.type) {
      case AlertLimitType.DoUpdateOrScheduledEnd:
      case AlertLimitType.DoScheduledEnd:
        return 'item-alert';
      case AlertLimitType.End:
        return 'item-end';
    }
    return 'item-normal';
  }

  get selected(): boolean {
    return (
      this.selectedItems.filter(item => {
        if (this.obj.contract_id) {
          return item.contract_id == this.obj.contract_id;
        } else {
          return item.directory_path == this.obj.directory_path;
        }
      }).length > 0
    );
  }
  set selected(v) {
    this.isSelected = !this.isSelected;
    if (v) {
      this.selectedItems.push(this.obj);
    } else {
      this.selectedItems.forEach((item, index) => {
        if (this.obj.contract_id) {
          if (this.obj.contract_id === item.contract_id) {
            this.selectedItems.splice(index, 1);
          }
        } else {
          if (this.obj.directory_path === item.directory_path) {
            this.selectedItems.splice(index, 1);
          }
        }
      });
    }
  }

  get uploadJobs() {
    return Documents.uploadJobs;
  }

  get uploadJobGroupIds() {
    return Documents.uploadJobGroupIds;
  }

  get isRunningUploadJob() {
    return Documents.isRunningUploadJob;
  }

  get isDeletingFoldersAndFiles() {
    return Documents.isDeletingFoldersAndFiles;
  }

  get canCheckItem(): boolean {
    if (
      this.isGuest ||
      this.isRunningUploadJob ||
      this.isDeletingFoldersAndFiles
    )
      return false;

    return true;
  }

  async move_document_detail(contract_id: string) {
    this.$router.push({ name: 'document_detail', params: { id: contract_id } });
  }

  move_page(obj: any) {
    if (obj.contract_id) {
      this.move_document_detail(obj.contract_id);
    } else if (obj.directory_path) {
      change_folder(this.$router, obj.directory_path);
    }
  }
}
