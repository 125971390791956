
































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

@Component({
  components: {
    BaseWindow,
    AppButton
  }
})
export default class TextOnlyWindow extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  windowSize!: string;

  @Prop()
  title!: string;

  @Prop()
  body!: string;

  @Prop({ default: '' })
  remarks?: string;

  @Prop()
  isRemarksTextDanger?: boolean;

  @Prop({ default: '削除' })
  mainBtnLabel: string;

  @Prop({ default: 'alert1' })
  mainBtnColorClass: string;

  @Prop({ default: 'small' })
  mainBtnSizeClass: string;

  @Prop({ default: 96 })
  mainBtnWidth: number;

  @Prop({ default: false })
  mainBtnDisabled: boolean;

  @Prop({ default: 'キャンセル' })
  subBtnLabel: string;

  @Prop({ default: 'tertiary' })
  subBtnColorClass: string;

  @Prop({ default: 'small' })
  subBtnSizeClass: string;

  @Prop({ default: 96 })
  subBtnWidth: number;

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }

  get isOpen(): boolean {
    return this.value;
  }

  nl2br(str): string {
    return str.replace(/(\n|\r)/g, '<br />');
  }
}
