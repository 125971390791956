















import { Component, Vue } from 'vue-property-decorator';
import Documents from '@/store/Documents';

import CommentList from '@/components/organisms/DocumentDetail/CommentList.vue';

@Component({
  components: {
    CommentList
  }
})
export default class DocumentInfoComment extends Vue {
  comment: string = '';
  submitting: boolean = false;
  only_comment: boolean = false;

  created() {
    Documents.SET_IS_ONLY_COMMENT(false);
  }

  get is_only_comment() {
    return Documents.is_only_comment;
  }

  set is_only_comment(val: boolean) {
    Documents.SET_IS_ONLY_COMMENT(val);
  }
}
