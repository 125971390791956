



































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ObjectTableBodyRow from '@/components/organisms/Home/tables/ObjectTableBodyRow.vue';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import Documents from '@/store/Documents';
import HomeComponentInfo from '@/store/HomeComponentInfo';
import SettingsInfoModule from '@/store/SettingsInfo';
import UserInfo from '@/store/UserInfo';
import AUTHORITIES from '@/consts/Authorities.js';
import { sortDocuments } from '@/functions/Sort';
import { MutationPayload } from 'vuex';

@Component({
  components: {
    ObjectTableBodyRow,
    BaseTable
  }
})
export default class ObjectTable extends Vue {
  select_all: boolean = false;
  selected: any[] = [];
  change_table_event: number = 0;

  get sort(): {
    columnName: string;
    asc: boolean;
  } {
    return SettingsInfoModule.sort[this.$route.name];
  }

  setSort(columnName: string) {
    SettingsInfoModule.SET_SORT({
      pageName: this.$route.name,
      columnName
    });
    this.onSort();
  }

  onSort(): void {
    this.filesAndFolders = sortDocuments(
      this.filesAndFolders,
      'directory_name',
      'directory_name_furigana',
      this.sort.columnName,
      this.sort.asc
    );
  }

  @Prop({ default: [] })
  objects!: any;
  filesAndFolders: any = this.objects;

  @Watch('objects', { deep: true })
  setDocuments() {
    this.filesAndFolders = this.objects;
    this.onSort();
  }

  @Prop({ default: false })
  concluded: boolean;

  get isGuest() {
    return UserInfo.authority === AUTHORITIES.GUEST;
  }

  private get exsistsFolder(): boolean {
    return (
      this.filesAndFolders.filter(({ directory_name }) => {
        return directory_name;
      }).length > 0
    );
  }

  get isRunningUploadJob() {
    return Documents.isRunningUploadJob;
  }

  get isDeletingFoldersAndFiles() {
    return Documents.isDeletingFoldersAndFiles;
  }

  get canAllCheck(): boolean {
    if (
      this.isGuest ||
      this.isRunningUploadJob ||
      this.isDeletingFoldersAndFiles
    )
      return false;

    return true;
  }

  // this.sortが作られる前にstoreのsettingをチェックする
  beforeCreate() {
    // デフォルトは「相手方」で「昇順」
    if (!SettingsInfoModule.sort[this.$route.name]) {
      SettingsInfoModule.SET_SORT({
        pageName: this.$route.name,
        columnName: 'counterparty_name_furigana'
      });
    }
  }

  mounted() {
    this.onSort();
    // NOTE: こうしないとWatchできなかった
    this.$store.subscribe((mutation: MutationPayload, _state) => {
      if (mutation.type === 'documents/RESET_SELECTED_TABLE')
        this.selected = [];
    });
  }

  @Watch('HomeComponentInfo')
  set_change_table_event() {
    this.change_table_event = HomeComponentInfo.change_tables;
  }

  beforeDestroy() {
    this.reset_selected();
  }

  @Watch('change_table_event')
  reset_selected() {
    this.selected = [];
    Documents.RESET_SELECTED_TABLE();
  }

  @Watch('$route')
  async change_directory(to: any, from: any) {
    this.reset_selected();
  }

  allCheck() {
    this.selected = [];
    if (!this.select_all) {
      for (const i in this.filesAndFolders) {
        const item = this.filesAndFolders[i];
        if (Object.keys(item).length) {
          this.selected.push(item);
        }
      }
    }
  }

  @Watch('selected')
  change_select_all(val: any[], oldval: any[]) {
    const num_selected = val.length;
    const num_item = this.filesAndFolders.length;
    if (num_selected != num_item) {
      this.select_all = false;
    } else {
      this.select_all = true;
    }
  }

  @Watch('selected')
  set_store() {
    const files = this.selected.filter(x => 'contract_id' in x);
    const folders = this.selected.filter(x => 'directory_path' in x);

    Documents.SET_SELECTED_FILES_OF_TABLE(files);
    Documents.SET_SELECTED_FOLDERS_OF_TABLE(folders);
  }
}
