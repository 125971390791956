




















































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
// vLoading は呼び出していないが、モーダル上で vLoading が表示されるために必要。
import 'vue-loading-overlay/dist/vue-loading.css';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import IconDelete from '@/components/atoms/icons/IconDelete.vue';
import IconContractCheck from '@/components/atoms/icons/IconContractCheck.vue';
import IconContractCreate from '@/components/atoms/icons/IconContractCreate.vue';
import ExtractIcon from '@/components/molecules/ExtractIcon.vue';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';
import AvatarCommentInput from '@/components/molecules/avatar/AvatarCommentInput.vue';
import CONTRACT_TYPES from '@/consts/ContractTypes.js';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import FileUpload from '@/functions/FileUpload';

interface Window {
  webkitURL: any;
  URL: any;
  FormData: any;
}

declare let window: Window;

/**
 * 入力必須フォーム項目
 */
const validationFormName = {
  task_expiration_date: '対応期限',
  counterparty_name: '相手方',
  contract_type_id: '契約種別'
};

@Component({
  components: {
    AppButton,
    TextForm,
    IconDelete,
    IconContractCheck,
    IconContractCreate,
    TextAvatar,
    AvatarCommentInput,
    SelectBox,
    ExtractIcon,
    BaseWindow
  }
})
export default class CreateContractModal extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  windowSize!: string;

  @Prop({ default: {} })
  requestFrom;

  @Prop({ default: {} })
  requestTo;

  @Prop({})
  contractFileExtraction;

  @Prop({})
  selectedViewPermissionData;

  formData: any = {
    task_type: CONTRACT_TYPES.CREATE, // 作成 or チェック
    task_expiration_date: null,
    counterparty_name: null,
    contract_type_id: null,
    description: null
  };
  contractTitle = null;
  errorMessages: object = {};

  attachementFiles: any = [];
  fileLimit: number = 15;
  contractFile: any = null;

  directory: boolean = false;
  headers: object = {};
  uploaded: boolean = true;

  selectedType: string = null;
  selected: string = '';

  isEditedContractTitle: boolean = true;

  remainingTextCount: number = 500;

  get refs(): any {
    return this.$refs;
  }

  @Watch('value')
  changeValue(val) {
    if (!val) {
      this.formData = {
        task_type: CONTRACT_TYPES.CREATE, // 作成 or チェック
        task_expiration_date: null,
        counterparty_name: null,
        contract_type_id: null,
        description: null
      };
      this.selectedType = '';
      this.attachementFiles = [];
      this.errorMessages = {};
      this.contractFile = null;

      const attachmentDocumentEl: HTMLInputElement = this.refs
        .documentUploader as HTMLInputElement;
      attachmentDocumentEl.value = '';

      this.refs.commentInput.cancel();
    } else {
      this.initExpirationDate();
    }
  }

  @Watch('contractFileExtraction')
  onChangeContractFileExtraction(extraction_data) {
    // アップロードしたファイルが削除されたとき、 task_type, contract_title 以外の formData をデフォルトに戻す
    if (!extraction_data) {
      Object.entries(this.formData).map(([key, data]) => {
        if (
          key !== 'task_type' &&
          key !== 'contract_title' &&
          key !== 'counterparty_name' &&
          key !== 'task_expiration_date' &&
          key !== 'description'
        ) {
          this.formData[key] = null;
        }
      });
      return;
    }
    // アップロードしたファイルの抽出データがあるとき、formdata の項目を埋める.ただしすでに入力されていたら上書きしない.
    Object.entries(extraction_data).map(([key, data]) => {
      if (!this.formData[key]) {
        this.formData[key] = data;
      }
      if (key === 'contract_title') {
        this.contractTitle = data;
        if (!this.contractTitle) {
          this.isEditedContractTitle = false;
          this.contractTitle = this.contractFile.name;
        }
      }
    });
  }

  get CONTRACT_TYPES() {
    return CONTRACT_TYPES;
  }

  get inputItems() {
    return [
      {
        key: 'create',
        text: '契約書の作成',
        image: 'IconContractCreate'
      },
      {
        key: 'check',
        text: '契約書のチェック',
        image: 'IconContractCheck'
      }
    ];
  }

  get contractTypeOptions() {
    return this.$store.state.contract_info.boardContractTypes;
  }

  selectContractType(value) {
    this.selectedType = value;
    // vue-select では id が渡せないので、選択した日本語のタイトルを元に contractTypeOptions で照合して formData に id を入れている
    const selectedOption = this.contractTypeOptions.find(
      option => option['contract_type_name'] === value
    );
    this.formData.contract_type_id = selectedOption['contract_type_id'];
  }

  get contractTypes() {
    return this.contractTypeOptions.map(option => option['contract_type_name']);
  }

  /**
   * 閲覧権限が設定されているかを返す
   */
  get isPermissionAdded() {
    return (
      this.selectedViewPermissionData &&
      ((this.selectedViewPermissionData.selected_departments &&
        this.selectedViewPermissionData.selected_departments.length) ||
        (this.selectedViewPermissionData.selected_users &&
          this.selectedViewPermissionData.selected_users.length))
    );
  }

  /**
   * formData のうち、 task_type 以外のいずれかが入力されているかを返す
   */
  get isAnyFormFilled() {
    if (this.attachementFiles.length) {
      return true;
    }
    if (this.contractFile) {
      return true;
    }
    return Object.keys(this.formData).some(data => {
      if (data === 'task_type') {
        return false;
      }
      return !!this.formData[data];
    });
  }

  /**
   * 依頼内容が「契約書のチェック」であるかを返す
   */
  get isContractTypeSelected() {
    return this.formData.task_type === CONTRACT_TYPES.CHECK;
  }

  initExpirationDate() {
    const today = new Date();
    const yyyy = today.getFullYear();
    const mm = ('0' + (today.getMonth() + 1)).slice(-2);
    const dd = ('0' + today.getDate()).slice(-2);
    this.formData.task_expiration_date = `${yyyy}-${mm}-${dd}`;
  }

  /**
   * 必須項目に blank があるかをチェック
   */
  validateFormBlank() {
    this.errorMessages = {};

    Object.keys(this.formData).forEach(key => {
      // validationFormName に含まれていない値は対象としない
      if (!validationFormName[key]) {
        return;
      }
      if (!this.formData[key]) {
        this.errorMessages[key] = `${validationFormName[key]} の項目は必須です`;
        return;
      }
    });

    if (this.isContractTypeSelected) {
      if (!this.contractFile) {
        this.errorMessages['contract_file'] =
          '契約書をアップロードしてください';
      }
      if (!this.contractTitle) {
        this.errorMessages['contract_title'] = '契約書名 の項目は必須です';
      }
    }

    return !!Object.keys(this.errorMessages).length;
  }

  updated() {
    const activeDialog = document.getElementsByClassName('v-dialog--active');
    if (!activeDialog || !activeDialog.length) {
      return;
    }
    // v-dialogのスクロール位置をtopに戻す
    activeDialog[0].scrollTop = 0;
  }

  changeTaskType(event) {
    this.formData.task_type = event.target.value;
    this.errorMessages = {};
  }

  showViewPermissionWindow() {
    this.$emit('openViewPermissionWindow');
  }

  /**
   * 依頼するボタン
   */
  clickSubmitButton() {
    if (this.validateFormBlank()) {
      return;
    }

    if (this.isContractTypeSelected) {
      this.formData = {
        ...this.formData,
        contract_title: this.contractTitle
      };
    } else {
      delete this.formData.contract_title;
    }

    // FIXME: refsを使わずに実装する
    const comment = this.refs.commentInput.convertUserNameToId(
      this.refs.commentInput.comment
    );

    // TODO: API update - サーバーサイドにselectedUsersを渡さなくても良いようにする
    const mentions: string[] = [];
    this.formData = {
      ...this.formData,
      comment,
      mentions
    };

    this.$emit('submit', {
      contractData: this.formData
    });
  }

  addContractFile(file) {
    const isCheckFileSize = FileUpload.checkAllowedFileSize(
      file.target.files[0].size
    );
    if (!isCheckFileSize) return;

    this.contractFile = file.target.files[0];
    if (/\.doc$|\.xls$/.test(this.contractFile.name)) {
      this.isEditedContractTitle = false;
      this.contractTitle = this.contractFile.name;
    }
    this.$emit('uploadContractFile', this.contractFile);
  }

  addAttachmentFiles(files) {
    this.attachementFiles = [...this.attachementFiles, ...files.target.files];
    this.$emit('uploadAttachedFile', this.attachementFiles);
  }

  closeModal() {
    this.$emit('close', this.isAnyFormFilled);
  }

  deleteAttachedFile(index) {
    this.attachementFiles.splice(index, 1);
    this.$emit('deleteAttachedFile', index);
  }
  deleteContractFile() {
    this.contractFile = null;
    this.isEditedContractTitle = true;
    this.contractTitle = null;
    this.$emit('deleteContractFile');
  }

  changeWindowStatus() {
    this.$emit('changeWindowStatus');
  }

  remainingText(val) {
    this.remainingTextCount = val;
  }
}
