const LAYOUT_UTIL = {
  HEADER: {
    HEIGHT: 56
  },
  SIDE_MENU: {
    WIDTH: {
      OPEN: 210,
      CLOSE: 48
    }
  }
};

export default LAYOUT_UTIL;
