import Vue from 'vue';
import Vuex from 'vuex';
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators';
import store from '@/store';

const axios = require('axios').default;
import HTTP_STATUS from '@/consts/HttpStatus';
import CONTRACT_MANAGEMENT_UTIL from '@/consts/ContractManagementUtil';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');
const ContractInfoRepository = RepositoryFactory.get('contractInfos');

Vue.use(Vuex);

export interface assigneeUserInfoInterface {
  user_id: string;
  user_name: string;
  department_name: string;
  avatar_name: string;
  avatar_image_path: string;
  avatar_color: string;
}

interface contractBoardCardObject {
  card_id: string;
  card_name: string;
  counterparty_name: string;
  assignee: assigneeUserInfoInterface;
  task_expiration_date: string;
  task_type: string;
  version: string;
  is_last_version: boolean;
  num_comment: number;
  can_add_user: boolean;
  is_shared: boolean;
  latest_contract_version_file_extension: string;
  latest_contract_version_file_name: string;
  latest_contract_version_id: string;
  is_archived: boolean;
  archived_at: Date;
}

interface contractBoardColumnObject {
  column_id: string;
  column_name: string;
  column_order: number;
  can_create_card: boolean;
  is_finish_column: boolean;
  is_needed_signed_file: boolean;
  is_archived: boolean;
  cards: contractBoardCardObject[];
}

interface beforeUpdateContractObject {
  card_id: string;
  name: string;
  destination_column_id: number;
  is_finished: boolean;
}

export interface contractDataInterface {
  contract_title: string;
  counterparty_name: string;
  description: string;
}

@Module({
  dynamic: true,
  store: store,
  name: 'contract_info',
  namespaced: true
})
export class ContractInfo extends VuexModule {
  contractBoardId: string = '';
  createColumnId: string = '';
  contractBoardColumnsData: contractBoardColumnObject[] = [];
  archivedContractBoardColumnsData: contractBoardColumnObject[] = [];
  assigneeUserInfo: assigneeUserInfoInterface = null;
  assigneesList: assigneeUserInfoInterface[] = [];
  boardContractTypes: string[] = [];

  contractDataExtraction: contractDataInterface = null;

  beforeUpdateContractData: beforeUpdateContractObject = null;

  get sortedContractBoardColumnsData() {
    return this.contractBoardColumnsData.sort(
      (a, b) => a['column_order'] - b['column_order']
    );
  }

  get archivedContractCardData() {
    let cards = [];
    this.archivedContractBoardColumnsData.forEach(c => {
      cards = cards.concat(c.cards);
    });
    return cards.sort((a, b) => a['archived_at'] - b['archived_at']);
  }

  get getColumnById() {
    return (columnId: string) => {
      for (const i in this.contractBoardColumnsData) {
        if (this.contractBoardColumnsData[i].column_id === columnId) {
          return this.contractBoardColumnsData[i];
        }
      }
      return null;
    };
  }

  /**
   * 自動抽出実行ステータス
   */
  get neededSignedFileColumn(): contractBoardColumnObject {
    return this.contractBoardColumnsData.find(c => c.is_needed_signed_file);
  }

  /**
   * ボードの id
   * @param id
   */
  @Mutation
  SET_CONTRACT_BOARD_ID(id: string) {
    this.contractBoardId = id;
  }

  /**
   * カンバンボードのカラムデータ
   * @param columnsData
   */
  @Mutation
  SET_CONTRACT_BOARD_COLUMNS_DATA(columnsData: contractBoardColumnObject[]) {
    this.contractBoardColumnsData = columnsData;
  }

  /**
   * カンバンボードのアーカイブされたカラムデータ
   * @param columnsData
   */
  @Mutation
  SET_ARCHIVED_CONTRACT_BOARD_COLUMNS_DATA(
    columnsData: contractBoardColumnObject[]
  ) {
    this.archivedContractBoardColumnsData = columnsData;
  }

  @Mutation
  SET_ASSIGNEE_USER_INFO(userInfo: assigneeUserInfoInterface) {
    this.assigneeUserInfo = userInfo;
  }

  @Mutation
  RESET_ASSIGNEE_USER_INFO() {
    this.assigneeUserInfo = null;
  }

  @Mutation
  SET_ASSIGNEES(assignees: assigneeUserInfoInterface[]) {
    this.assigneesList = assignees;
  }

  @Mutation
  SET_BOARD_CONTRACT_TYPES(contractTypes: string[]) {
    this.boardContractTypes = contractTypes;
  }

  @Mutation
  SET_CREATE_COLUMN_ID(columnsData: contractBoardColumnObject[]) {
    // 作成・確認依頼カラムの並び順
    const createColumnCard = columnsData.find(
      data =>
        data['column_order'] ===
        CONTRACT_MANAGEMENT_UTIL.COLUMN_ORDER.CREATE_OR_CONFIRM_REQUEST
    );
    this.createColumnId = createColumnCard['column_id'];
  }

  @Mutation
  SET_CONTRACT_EXTRACTION(contract_data_extraction: contractDataInterface) {
    this.contractDataExtraction = contract_data_extraction;
    //  仕様により契約書名だけ抽出
    this.contractDataExtraction.counterparty_name = null;
    this.contractDataExtraction.description = null;
  }

  @Mutation
  CLEAR_CONTRACT_EXTRACTION() {
    this.contractDataExtraction = null;
  }

  @Mutation
  SET_BEFORE_UPDATE_CONTRACT_DATA(
    beforeUpdateContractData: beforeUpdateContractObject
  ) {
    this.beforeUpdateContractData = beforeUpdateContractData;
  }

  @Action({ rawError: true })
  async getBoardContractTypes() {
    return DocumentRepository.getContractTypes()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_BOARD_CONTRACT_TYPES(res.data);
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  /**
   * カンバンボードのカラム全体のデータを取得する
   */
  @Action({ rawError: true })
  async getContractBoardData(params = { keyword: '', is_archived: false }) {
    return await ContractInfoRepository.getContractBoardData(params)
      .then(response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        this.SET_CONTRACT_BOARD_ID(response.data.board_id);
        if (params.is_archived) {
          this.SET_ARCHIVED_CONTRACT_BOARD_COLUMNS_DATA(response.data.columns);
        } else {
          this.SET_CONTRACT_BOARD_COLUMNS_DATA(response.data.columns);
        }
        this.SET_ASSIGNEE_USER_INFO(response.data.init_assignee_user_info);
        this.SET_CREATE_COLUMN_ID(response.data.columns);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /**
   * カンバンステータスカラムを新規作成
   * @param payload
   */
  @Action({ rawError: true })
  async createKanbanColumn(payload) {
    return await ContractInfoRepository.createKanbanColumn({
      board_id: this.contractBoardId,
      column_name: payload.column_name,
      column_order: payload.column_order,
      can_create_card: payload.can_create_card || false,
      is_needed_signed_file: payload.needed_signed_file
    }).then(response => {
      if (response.status !== HTTP_STATUS.OK) {
        return Promise.reject(response);
      }
      return Promise.resolve(response);
    });
  }

  /**
   * カンバンステータスカラムを編集
   * @param payload
   */
  @Action({ rawError: true })
  async updateKanbanColumn(payload) {
    return await ContractInfoRepository.updateKanbanColumn({
      board_id: this.contractBoardId,
      column_id: payload.column_id,
      column_name: payload.column_name,
      column_order: payload.column_order,
      can_create_card: payload.can_create_card || false,
      is_needed_signed_file: payload.needed_signed_file
    }).then(response => {
      if (response.status !== HTTP_STATUS.OK) {
        return Promise.reject(response);
      }
      return Promise.resolve(response);
    });
  }

  /**
   * カンバンステータスカラムを削除
   * @param column_id
   */
  @Action({ rawError: true })
  async deleteKanbanColumn(column_id: string) {
    return await ContractInfoRepository.deleteKanbanColumn(column_id).then(
      response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
      }
    );
  }

  /**
   * 依頼先設定先の一覧を取得
   */
  @Action({ rawError: true })
  async getAssignees() {
    return await ContractInfoRepository.getAssignees()
      .then(response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        this.SET_ASSIGNEES(response.data);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async getContractFileExtraction({ params }) {
    await DocumentRepository.getContractFileExtraction(params)
      .then(response => {
        this.SET_CONTRACT_EXTRACTION(response.data);
        return response;
      })
      .catch(error => {
        console.error(error);
      });
  }
}

const ContractManagementModule = getModule(ContractInfo);

export default ContractManagementModule;
