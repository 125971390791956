












import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SearchBoxFormInput extends Vue {
  @Prop({ default: '' })
  value!: any;

  @Prop({ default: 'text' })
  type?: string;

  @Prop({ default: false })
  disabled?: boolean;

  handleInput(e) {
    this.$emit('input', e.target.value);
  }

  handleChange(e) {
    this.$emit('change', e.target.value);
  }

  handleFocus(e) {
    this.$emit('focus', e.target.value);
  }

  handleBlur(e) {
    this.$emit('blur', e.target.value);
  }
}
