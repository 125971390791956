










































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import TextFormMidSize_Password from '@/components/atoms/text_forms/TextFormMidSize_Password.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

@Component({
  components: {
    BaseWindow,
    TextFormMidSize_Password,
    AppButton
  }
})
export default class ConfirmPasswordFormWindow extends Vue {
  @Prop()
  value!: boolean;

  @Prop({ default: '' })
  label?: string;

  @Prop({ default: '' })
  text?: string;

  @Prop()
  windowSize!: string;

  @Prop()
  title!: string;

  @Prop()
  description?: string;

  @Prop()
  type?: string;

  @Prop()
  message?: string;

  @Prop({ default: '作成' })
  mainBtnLabel: string;

  @Prop({ default: '' })
  contentClass?: string;

  @Prop({ default: 'primary' })
  mainBtnColorClass: string;

  @Prop({ default: 'small' })
  mainBtnSizeClass: string;

  @Prop({ default: 96 })
  mainBtnWidth: number;

  @Prop({ default: 'キャンセル' })
  subBtnLabel: string;

  @Prop({ default: 'tertiary' })
  subBtnColorClass: string;

  @Prop({ default: 'small' })
  subBtnSizeClass: string;

  @Prop({ default: 96 })
  subBtnWidth: number;

  inputValue: string = '';

  is_dispaly_password: boolean = false;

  @Watch('value')
  changeValue(val) {
    if (!val) this.inputValue = '';
  }

  @Watch('text')
  changeText() {
    this.inputValue = this.text;
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }

  get isOpen(): boolean {
    return this.value;
  }
  set isOpen(val) {
    if (!this.inputValue) true;
  }

  exchange_dispaly_password() {
    this.is_dispaly_password = !this.is_dispaly_password;
  }
}
