











import { Component, Vue } from 'vue-property-decorator';
import Logo from '@/components/atoms/logos/Logo.vue';

@Component({
  components: {
    Logo
  }
})
export default class CorporateInformation extends Vue {}
