import { render, staticRenderFns } from "./AppNotifications.vue?vue&type=template&id=42922931&scoped=true&lang=pug&"
import script from "./AppNotifications.vue?vue&type=script&lang=ts&"
export * from "./AppNotifications.vue?vue&type=script&lang=ts&"
import style0 from "./AppNotifications.vue?vue&type=style&index=0&id=42922931&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42922931",
  null
  
)

export default component.exports