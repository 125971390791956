













import { Component, Vue, Watch } from 'vue-property-decorator';
import { checkTimeOut } from '@/functions/CheckTimeOut';
import { throttle } from 'lodash';
import ContractInfo from '@/store/ContractInfo';
import UserInfo from '@/store/UserInfo';
import notify from '@/functions/notify';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
@Component
export default class App extends Vue {
  get throttleCheck(): Function {
    return throttle(checkTimeOut, 300000); // 5分間隔にして処理を間引く
  }
  checkTimeOut(): void {
    this.throttleCheck();
  }

  get isLogin(): boolean {
    return !!UserInfo.user_id && !!UserInfo.user_name;
  }

  @Watch('isLogin')
  // TODO: バックエンド側で対応できないか確認
  /**
   * ユーザーによる更新がないデータを取得する
   */
  fetchMasterData(isLogin) {
    if (!isLogin) return;

    // TODO: WatchでAPIコールしない
    // @See: https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/2844
    // NOTE: APIコールからContractInfo.SET_BOARD_CONTRACT_TYPESでboardContractTypesを再設定している
    ContractInfo.getBoardContractTypes()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
  }
}
