































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import Documents, { EditCommentItem, UserInfo } from '@/store/Documents';
import UserInfoModule from '@/store/UserInfo';

import AvatarCommentItem from '@/components/molecules/avatar/AvatarCommentItem.vue';
import AvatarCommentInput from '@/components/molecules/avatar/AvatarCommentInput.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import NOTIFY_TEXT from '@/consts/NotifyText';

import notify from '@/functions/notify';

@Component({
  components: {
    AvatarCommentItem,
    AvatarCommentInput,
    TextOnlyWindow
  }
})
export default class CommentList extends Vue {
  @Prop({ default: '668px' })
  input_width: string;

  is_showing_delete_comment_modal: boolean = false;
  delete_comment_id: string = null;

  get permission() {
    return UserInfoModule.permission;
  }

  get isKanbanCard() {
    return !!this.contract_detail.kanban_card_info;
  }

  public get activities() {
    return Documents.activities;
  }

  public get contract_detail() {
    return Documents.contract_detail;
  }

  get stakeholders(): UserInfo[] {
    return this.contract_detail.stakeholders;
  }

  set stakeholders(stakeholders: UserInfo[]) {
    Documents.SET_CONTRACT_DETAIL_PROPS({ stakeholders });
  }

  /**
   * コメントのみの表示かどうかを取得
   */
  public get is_only_comment(): boolean {
    return Documents.is_only_comment;
  }

  public get user_name(): string {
    return UserInfoModule.user_name;
  }

  public get avatar_color(): string {
    return UserInfoModule.avatar.avatar_color;
  }

  @Watch('contract_detail')
  async update_contract_detail(new_value) {
    // TODO: WatchでAPIコールしない
    // @See: https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/2844
    // NOTE: データもとのcontract_detailの変更時にコメントデータの再取得を行っている
    await Documents.getComments(new_value.contract_info.contract_id).catch(
      err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GET_COMMENT
        });
        return;
      }
    );
  }

  editComment(item) {
    Documents.SET_IS_EDIT(true);
    Documents.SET_EDIT_COMMENT_ITEM(item);
  }

  get edit_comment_item(): EditCommentItem {
    return Documents.editCommentItem;
  }

  /**
   * コメント送信処理
   */
  async submitComment(params) {
    const comment: string = params.comment;
    const mentionUsers: string[] = params.selctedUsers.map(user => {
      return user.user_id;
    });

    const apiParams = {
      contract_id: Documents.contract_detail.contract_info.contract_id,
      comment,
      mention_users: mentionUsers
    };

    await Documents.postComment(apiParams).catch(err => {
      notify.error({
        text: NOTIFY_TEXT.ERROR.COMMENT
      });
      return;
    });
  }

  async deleteComment() {
    await Documents.deleteComment(this.delete_comment_id).catch(err => {
      notify.error({
        text: NOTIFY_TEXT.ERROR.DELETE_COMMENT
      });
      return;
    });

    this.closeDeleteCommentModal();

    notify.success({
      text: NOTIFY_TEXT.SUCCESS.DELETE_COMMENT
    });
  }

  openDeleteCommentModal(item: EditCommentItem) {
    this.is_showing_delete_comment_modal = true;
    this.delete_comment_id = item.comment_info.comment_id;
  }

  closeDeleteCommentModal() {
    this.is_showing_delete_comment_modal = false;
    this.delete_comment_id = null;
  }
}
