import Vue from 'vue';
import VueCookies from 'vue-cookies-ts';
import notify from '@/functions/notify';

Vue.use(VueCookies);

// 画面放置のタイムアウトは1時間
const timeOutLimit: number = 3600;
export async function checkTimeOut(): Promise<void> {
  const lastRequestAt: string | object = Vue.cookies.get('last-request-at');
  const date: Date = new Date();
  // 秒で取得
  const nowUnixTime: number = Math.floor(date.getTime() / 1000);

  // sleepした後以降の処理を全て中止させるために例外を吐く
  function sleepAndStopJS(msec: number, msg: string) {
    return new Promise(() => {
      setTimeout(() => {
        throw new Error(msg);
      }, msec);
    });
  }

  if (lastRequestAt) {
    if (nowUnixTime - parseFloat(lastRequestAt.toString()) > timeOutLimit) {
      // タイムアウトの場合、Cognitoトークンなどcookieでセットしているものは全て削除する
      Vue.cookies.keys().forEach((cookieName: string) => {
        Vue.cookies.remove(cookieName);
      });
      if (location.pathname != '/') {
        alert('タイムアウトしました。再度ログインしてください。');
        // TODO: タイムアウトの場合、通信中などに表示する全てのスナックバーを消す。その為にFileUploadingPopupMessageで表示しているアイコン付きスナックバーも全てnotifyに寄せるリファクタを行う必要がある。
        Vue.cookies.set('is-logined', false);
        // エラースナックバーを表示させないためにlocationによるページの読み込みを行う
        location.pathname = '/';
        notify.clearError();
        notify.clearSuccess();
        // ログインページ再読み込みに十分な時間待機してから、以降の通信を全て実行させないためにJSの処理を中断させる
        await sleepAndStopJS(
          30000,
          'タイムアウトしたので処理を中断します'
        ).catch();
        notify.clearError();
        notify.clearSuccess();
      }
    }
  }
  Vue.cookies.set('last-request-at', nowUnixTime, { expires: 60 * 60 * 24 });
}

export default { checkTimeOut };
