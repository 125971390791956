













































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import vLoading from 'vue-loading-overlay';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import IconLoadingSpinner from '@/components/atoms/icons/IconLoadingSpinner.vue';
import AppNotifications from '@/components/atoms/AppNotifications.vue';
import DocumentDetailHeader from '@/components/organisms/DocumentDetail/DocumentDetailHeader.vue';
import ProgressBar from '@/components/organisms/DocumentDetail/ProgressBar.vue';

import DocumentPreview from '@/components/organisms/DocumentDetail/DocumentPreview.vue';
import DocumentInfo from '@/components/organisms/DocumentDetail/DocumentInfo.vue';
import VersionInfo from '@/components/organisms/DocumentDetail/VersionInfo.vue';
import Documents from '@/store/Documents';
import RouterModule from '@/store/Router';

import DocumentMoveWindow from '@/components/molecules/modal_windows/DocumentMoveWindow.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import FileUploadingPopupMessage from '@/components/molecules/popups/FileUploadingPopupMessage.vue';

import HomeComponentInfoModule from '@/store/HomeComponentInfo';
const axios = require('axios').default;
import notify from '@/functions/notify';
import ContractInfo from '@/store/ContractInfo';
import UserInfo from '@/store/UserInfo';
import MoveObject from '@/functions/MoveObject';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import WINDOW_MESSAGES from '@/consts/TextWindowMessage';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import {
  catchOnGetContractDetail,
  historyBackOrHome
} from '@/functions/DocumentRouter';
import dayjs from 'dayjs';

const DocumentRepository = RepositoryFactory.get('documents');
const ContractInfoRepository = RepositoryFactory.get('contractInfos');

@Component({
  components: {
    vLoading,
    AppButton,
    AppNotifications,
    DocumentDetailHeader,
    ProgressBar,
    DocumentMoveWindow,
    TextOnlyWindow,
    FileUploadingPopupMessage,
    DocumentPreview,
    DocumentInfo,
    VersionInfo,
    IconLoadingSpinner
  }
})
export default class DocumentDetail extends Vue {
  isInitLoading: boolean = true;
  isLoading: boolean = false;
  isFileUploading: boolean = false;
  uploading_files_num: number = 0;
  selectedTab: string = 'DocumentInfo';
  currentDirectory: string = Documents.current_directory;
  folders: any = [];
  isMoveSuccess: boolean = false;
  // ↓現在使われていない。パンくず（SelectedFile.vue）に伝搬させるようにリファクタするかは要検討
  filePath: string = '';
  folderMoving: boolean = false;
  displayedOncePreview = false;
  isOpenConfirmObjectMove: boolean = false;
  backToPath: string = '';

  uploadingFilesText: string = NOTIFY_TEXT.SUCCESS.UPLOADING_FILES;
  arraySetIntervalId = [];

  get pdfLink() {
    return Documents.previewPdfLink;
  }

  get contractDetail() {
    return Documents.contract_detail;
  }

  get isArchived() {
    if (this.contractDetail.kanban_card_info) {
      return this.contractDetail.kanban_card_info.is_archived;
    }
    return false;
  }

  get isSigned(): boolean {
    return this.contractDetail.is_signed;
  }

  get contractId(): string {
    return this.contractDetail.contract_info.contract_id;
  }

  get routerHistories() {
    const strHistories = RouterModule.histories;
    return strHistories.split(',');
  }

  public get move_selected_objects_window() {
    return HomeComponentInfoModule.move_selected_objects_window;
  }

  /**
   * $refsのTypescript Error回避
   * https://qiita.com/tsumasakky/items/03a4bdf74e3c765c2077
   */
  get refs(): any {
    return this.$refs;
  }

  get windowMessageConfirmMove() {
    return WINDOW_MESSAGES.CONFIRM_MOVE;
  }

  @Watch('$route')
  resetVersion() {
    Documents.SET_SELECTEDFILE([]);
  }

  get fileStatus() {
    return Documents.file_status;
  }

  get isAlreadyAutoExtraction() {
    if (!this.fileStatus) {
      return false;
    }
    if (
      this.fileStatus &&
      (this.fileStatus.autoextraction_status === 0 ||
        this.fileStatus.autoextraction_status === 1)
    ) {
      return false;
    }
    return true;
  }

  async fetchContractFileProcessStatus(contractId) {
    Documents.SET_ALREADY_FETCH_CONTRACT(false);
    const setIntervalId = setInterval(async () => {
      await Documents.getContractFileProcessStatus(contractId)
        .then(async res => {
          if (res.status !== HTTP_STATUS.OK) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.GENERAL
            });
          }
        })
        .catch(err => {
          // 404の場合はまだレコードが作成されていない場合があるのでスルー
          if (err.response.status === 404) {
            return;
          }
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
        });
      if (this.isAlreadyAutoExtraction) {
        await Documents.getContractDetailOnlyAutoextractionItems(contractId);
        Documents.SET_ALREADY_FETCH_CONTRACT(true);
        clearInterval(this.arraySetIntervalId.shift());
        return;
      }
    }, 15000);
    this.arraySetIntervalId.push(setIntervalId);
  }

  async loadDetail(contractId) {
    ContractInfo.getContractBoardData()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });

    ContractInfo.getAssignees()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });

    Documents.getContractLabels()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });

    UserInfo.get_user_info()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });

    await Documents.getContractFileProcessStatus(contractId)
      .then(res => {
        if (!this.isAlreadyAutoExtraction) {
          this.fetchContractFileProcessStatus(contractId);
        } else {
          // フェッチしたことにしておく
          Documents.SET_ALREADY_FETCH_CONTRACT(true);
        }
      })
      .catch(err => {
        if (err.response.status === 404) {
          // 「未作成」の場合があるのでフェッチしたことにしておく
          Documents.SET_ALREADY_FETCH_CONTRACT(true);
          return;
        }
      });

    await Documents.getContractDetail(contractId).catch(err => {
      catchOnGetContractDetail(this.$router, err);
    });
    DocumentRepository.getMentionUsers(contractId)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
        Documents.SET_MENTION_USERS(res.data);
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
  }

  async created() {
    this.isInitLoading = true;
    const contractId = this.$route.params.id;

    await this.loadDetail(contractId);

    if (this.$route.query.v) {
      const version = this.contractDetail.contract_version_list.find(
        v => v.contract_version_id === this.$route.query.v
      );
      if (version) {
        Documents.selectVersion(version);
      }
    }

    if (this.$route.query.preview) {
      if (this.contractDetail.contract_info.file_extension) {
        // プレビューの表示は非同期で取得するようにしたためコメントアウト
        // this.selectedTab = 'DocumentPreview';
        // this.displayedOncePreview = true;
        const query = Object.assign({}, this.$route.query);
        delete query['preview'];
        this.$router.replace({
          query
        });
      } else {
        const query = Object.assign({}, this.$route.query);
        delete query['preview'];
        this.$router.replace({
          query
        });
      }
    }

    if (this.contractDetail.directory_path) {
      this.filePath = '締結済ドキュメント';
      this.filePath += this.contractDetail.directory_path.replace(/\//g, ' > ');
      this.filePath += this.contractDetail.contract_info.contract_title;
    }

    if (this.$route.query.backto) {
      this.backToPath = this.$route.query.backto as string;
    }

    // プレビュー後に戻る動作をした際に直前に開いていたページへ戻るように追加
    if (!this.backToPath && this.routerHistories.length > 0) {
      this.backToPath = this.routerHistories.slice(-2)[0];
    }

    this.isInitLoading = false;
  }

  async mounted() {
    this.get_folders(this.currentDirectory);
  }

  beforeDestroy() {
    Documents.SET_CONTRACT_DETAIL(null);
    Documents.SELECT_VERSION(null);
    Documents.SET_FILESTATUS(null);
    Documents.SET_ALREADY_FETCH_CONTRACT(false);
    clearInterval(this.arraySetIntervalId.shift());
  }

  get isOnlyComment() {
    return Documents.is_only_comment;
  }

  async changeTab(tabName) {
    if (!this.pdfLink) {
      return;
    }
    if (this.selectedTab != 'DocumentInfo' && tabName === 'DocumentInfo') {
      Documents.SET_IS_ONLY_COMMENT(false);
    }
    this.isLoading = true;
    this.selectedTab = tabName;
    const query: {
      preview?: string;
      v?: string;
    } = {};

    if (tabName === 'DocumentPreview') {
      query.preview = 'true';
      this.displayedOncePreview = true;
      Documents.SET_IS_ONLY_COMMENT(true);
    }
    if (
      this.contractDetail.contract_version_list[0] &&
      this.contractDetail.contract_version_list[0].contract_version_id !==
        Documents.selectedVersion.contract_version_id
    ) {
      query.v = Documents.selectedVersion.contract_version_id;
    }
    this.$router
      .replace({
        query
      })
      .catch(err => {});
    this.isLoading = false;
  }

  formatDate(date) {
    const dateDiff = this.getDateDiff(date);
    if (dateDiff === -1) {
      return '明日';
    }
    if (dateDiff === 0) {
      return '今日';
    }
    if (dateDiff === 1) {
      return '昨日';
    }
    return dayjs(date).format('YYYY/MM/DD');
  }

  getDateDiff(date): number {
    const itemDate = dayjs(date);
    const today = dayjs();

    // dayjs の日数の diff は 24 時間単位で測るため、日付単位に戻す必要がある
    return dayjs(today.format('YYYYMMDD')).diff(
      dayjs(itemDate.format('YYYYMMDD')),
      'day'
    );
  }

  /**
   * 閲覧権限設定
   */
  async saveViewPermission(data) {
    const userIdList = data.selected_users.map(user => user.user_id);
    const departmentIdList = data.selected_departments.map(
      department => department.department_id
    );

    const params = {
      user_id_list: userIdList,
      department_id_list: departmentIdList,
      contract_id_list: [Documents.contract_detail.contract_info.contract_id],
      directory_path_list: []
    };

    await DocumentRepository.saveViewPermission(params)
      .then(res => {
        if (res.status === HTTP_STATUS.BAD_REQUEST) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.EMPTY
          });
          return;
        }
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.FAILED
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.FAILED
        });
        return;
      });

    await DocumentRepository.getCanViewContract(this.contractId)
      .then(async res => {
        if (!res.data) {
          notify.success({
            text: `閲覧権限を設定しました。権限がなくなりましたので自動的に戻ります。`
          });
          setTimeout(() => {
            historyBackOrHome(this.$router, this.$route, this.isSigned);
          }, 3000);
          return;
        }
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.SETTING_VIEW_PERMISSION
        });
        await Documents.getContractDetail(this.$route.params.id)
          .then(res => {
            if (res.status !== HTTP_STATUS.OK) {
              notify.error({
                text: NOTIFY_TEXT.ERROR.GENERAL
              });
              return;
            }

            DocumentRepository.getMentionUsers(this.$route.params.id)
              .then(res => {
                if (res.status !== HTTP_STATUS.OK) {
                  notify.error({
                    text: NOTIFY_TEXT.ERROR.GENERAL
                  });
                  return;
                }
                Documents.SET_MENTION_USERS(res.data);
              })
              .catch(err => {
                notify.error({
                  text: NOTIFY_TEXT.ERROR.GENERAL
                });
                return;
              });
          })
          .catch(error => {
            if (
              error.response &&
              error.response.status === HTTP_STATUS.FORBIDDEN
            ) {
              return;
            }
            catchOnGetContractDetail(this.$router, error);
          });
      })
      .catch(() => {
        notify.error({
          text: '閲覧権限の確認に失敗しました'
        });
        return;
      });
  }

  /**
   * 移動ボタン押下時
   */
  async moveObject(): Promise<void> {
    this.isOpenConfirmObjectMove = false;
    this.folderMoving = true;
    const result: boolean = await MoveObject.moveFileOrFolder();
    notify.success({
      text: '移動が完了しました'
    });
    this.folderMoving = false;

    this.isMoveSuccess = result;
  }

  cancelMove() {
    HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW(false);

    if (!this.isMoveSuccess) {
      this.filePath = '締結済ドキュメント';
      this.filePath += this.contractDetail.directory_path.replace(/\//g, ' > ');
      this.filePath += this.contractDetail.contract_info.contract_title;
    }
    this.get_folders(this.currentDirectory);
  }

  async get_folders(path: string) {
    await DocumentRepository.getDirectoryList(path)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        this.folders = res.data;
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  selectedPath(path) {
    HomeComponentInfoModule.SET_MOVE_TO_PATH(path);
  }

  async changePath(path) {
    await this.get_folders(path);
  }

  startUpload(fileCount) {
    this.isFileUploading = true;
    this.uploading_files_num = fileCount;
  }

  async endUpload() {
    await Documents.getContractFileProcessStatus(this.$route.params.id)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
        }
      })
      .catch(() => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });

    this.isFileUploading = false;
    this.uploading_files_num = 0;
  }

  /**
   * 移動ボタン押下時
   */
  async checkViewPermissionWhenMoveObject() {
    this.isOpenConfirmObjectMove = true;
  }

  async archived(val) {
    this.isLoading = true;
    let isSuccessed = true;
    await ContractInfoRepository.archiveCard(
      this.contractDetail.kanban_card_info.card_id,
      val
    )
      .then(async () => {
        await this.loadDetail(this.$route.params.id);
      })
      .catch(e => {
        isSuccessed = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.ARCHIVE_CONTRACT
        });
        return;
      });
    this.isLoading = false;
    if (isSuccessed) {
      if (val) {
        notify.success({
          text:
            `${this.contractDetail.contract_info.contract_title} ` +
            NOTIFY_TEXT.SUCCESS.ARCHIVED
        });
      } else {
        notify.success({
          text:
            `${this.contractDetail.contract_info.contract_title} ` +
            NOTIFY_TEXT.SUCCESS.UNARCHIVED
        });
      }
    }
  }
}
