import Vue from 'vue';
import Vuex from 'vuex';
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators';
import store from '@/store';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import HTTP_STATUS from '@/consts/HttpStatus';

const AssociationRepository = RepositoryFactory.get('associations');

type AssociationBase = {
  contract_id: string;
};

type AssociatedContract = {
  file_name: string;
  contract_title: string;
};

type AssociationInfo = AssociationBase & {
  contract_title: string;
  file_name: string;
  file_extension: string;
  file_path: string;
  counterparty_name: string;
  associated_contracts: AssociatedContract[];
};

Vue.use(Vuex);

@Module({ dynamic: true, store: store, name: 'associations', namespaced: true })
export class Associations extends VuexModule {
  candidates: AssociationInfo[] = [];

  @Mutation
  SET_CANDIDATES(data: AssociationInfo[]) {
    this.candidates = data;
  }

  @Action({ rawError: true })
  async getCandidates() {
    return await AssociationRepository.getCandidates()
      .then(response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        this.SET_CANDIDATES(response.data);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }
}

const AssociationsModule = getModule(Associations);

export { AssociationInfo };
export default AssociationsModule;
