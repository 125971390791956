










import { Component, Vue, Prop } from 'vue-property-decorator';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';

import ContractInfo from '@/store/ContractInfo';

@Component({
  components: {
    SelectBox
  }
})
export default class ContractTypeSelectBox extends Vue {
  @Prop({ default: null })
  value;

  get contractTypes() {
    return ContractInfo.boardContractTypes;
  }

  changeType(type) {
    this.$emit('change', type);
  }
}
