import Regex from '@/consts';

function validateFileNameAndFolderName(
  name: string,
  isFolder: boolean = false
) {
  const unauthorizedStringItems = ['/', '..', '#'];
  if (isFolder) {
    unauthorizedStringItems.push('&');
  }
  if (unauthorizedStringItems.every(item => !name.includes(item))) {
    return;
  }
  const usedItems = unauthorizedStringItems.filter(item => {
    if (!name.includes(item)) {
      return;
    }
    return item;
  });
  const resolvedDuplicatedItems = Array.from(new Set(usedItems));
  const convertedItems =
    resolvedDuplicatedItems.length > 1
      ? resolvedDuplicatedItems.join(' と ')
      : resolvedDuplicatedItems;
  let message = '';
  if (isFolder) {
    message = `${convertedItems} はフォルダ名に使用できません。`;
  } else {
    message = `${convertedItems} は契約書名に使用できません。`;
  }
  return message;
}

export function validateEmpty(value, column: string) {
  if (!value) {
    return `${column}を入力してください`;
  }

  return '';
}

export function validateZipCode(zipCode: string) {
  if (!zipCode) {
    return '郵便番号を入力してください。';
  }

  if (zipCode.length < 7 || !isFinite(Number(zipCode))) {
    return '郵便番号は半角数字７桁で入力してください。';
  }

  return '';
}

export function validateEmail(email: string) {
  if (!email) {
    return 'メールアドレスを入力してください。';
  }

  const error_message = '正しいメールアドレスを入力してください。';
  // 先頭文字列の判定
  let not_allow_str = email
    .slice(0, 1)
    .replace(Regex.email_top_end_pattern_str, '');
  if (not_allow_str) {
    return `${error_message}${not_allow_str} は先頭にご利用いただけません。`;
  }
  // 最後の文字列の判定
  not_allow_str = email.slice(-1).replace(Regex.email_top_end_pattern_str, '');
  if (not_allow_str) {
    return `${error_message}${not_allow_str} は最後にご利用いただけません。`;
  }
  // アットマークが0個の場合
  if (!email.match(/@/g)) {
    return `${error_message}@がありません。`;
  }
  // アットマークが複数ないか確認
  if (email.match(/@/g).length > 1) {
    return `${error_message}@を複数個ご利用いただけません。`;
  }
  const email_local = email.split('@')[0];
  const email_domain = email.split('@')[1];
  // ローカル部のバリデーション
  if (process.env.VUE_APP_IS_LOCALHOST === 'true') {
    not_allow_str = email_local.replace(Regex.dev_email_local_pattern_str, '');
  } else {
    not_allow_str = email_local.replace(Regex.email_local_pattern_str, '');
  }
  if (not_allow_str) {
    return `${error_message}${not_allow_str} は@より前にご利用いただけません。`;
  }
  // ドメイン部のバリデーション
  not_allow_str = email_domain
    .slice(0, 1)
    .replace(Regex.email_top_end_pattern_str, '');
  if (not_allow_str) {
    return `${error_message}@の直後は英数字のみご利用いただけます。`;
  }
  not_allow_str = email_domain.replace(Regex.email_domain_pattern_str, '');
  if (not_allow_str) {
    return `${error_message}${not_allow_str} は@より後にご利用いただけません。`;
  }
  // 何も問題ない場合
  return '';
}

export function validateCorporateTelNumber(tel_number: string) {
  if (Regex.tel_pattern.test(tel_number)) {
    return null;
  }
  if (!tel_number) {
    return '会社電話番号を入力してください。';
  }
  return '半角数字で10~11桁の間で入力してください。';
}

export function validateCorporateFaxNumber(fax_number: string) {
  if (Regex.fax_pattern.test(fax_number)) {
    return null;
  }
  if (!fax_number) {
    return null;
  }

  return '半角数字で10~11桁の間で入力してください。';
}

export default { validateFileNameAndFolderName };
