














































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AppNotifications from '@/components/atoms/AppNotifications.vue';
import HomeHeader from '@/components/organisms/Home/HomeHeader.vue';
import HomeSideMenuBar from '@/components/organisms/Home/HomeSideMenuBar.vue';
import DocumentMoveWindow from '@/components/molecules/modal_windows/DocumentMoveWindow.vue';
import ViewPermissionCard from '@/components/molecules/ViewPermissionCard.vue';
import NoRecentlyViewedFilesImage from '@/components/molecules/NoRecentlyViewedFilesImage.vue';
import TableSelectTab from '@/components/molecules/Table/TableSelectTab.vue';
import PartedFileTable from '@/components/organisms/Home/tables/PartedFileTable.vue';
import HomeBodyHeader from '@/components/organisms/Home/HomeBodyHeader.vue';
import vLoading from 'vue-loading-overlay';

import HomeComponentInfoModule from '@/store/HomeComponentInfo';
import SettingsInfoModule from '@/store/SettingsInfo';
import Documents from '@/store/Documents';
import SearchInfo from '@/store/SearchInfo';
import AssociationsModule from '@/store/Associations';
import UserInfo from '@/store/UserInfo';
import CorporateInfo from '@/store/CorporateInfo';
import DocumentsModule from '@/store/Documents';

import TAB_MENUS from '@/consts/TabMenus';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';
import MoveObject from '@/functions/MoveObject';
import HTTP_STATUS from '@/consts/HttpStatus';

const DocumentRepository = RepositoryFactory.get('documents');
const tabMenuNames = {
  contracted_document: '締結済ドキュメント',
  contract_management: '締結管理'
};
const tabMenuNamesSmallPlan = {
  contracted_document: '締結済ドキュメント'
};

@Component({
  components: {
    AppNotifications,
    HomeHeader,
    HomeSideMenuBar,
    DocumentMoveWindow,
    HomeBodyHeader,
    PartedFileTable,
    TableSelectTab,
    NoRecentlyViewedFilesImage,
    ViewPermissionCard,
    vLoading
  }
})
export default class RecentlyView extends Vue {
  currentDirectory: string = '';
  folders: any = [];
  selectedTab: string = TAB_MENUS.CONTRACTED_DOCUMENT;
  objects: any = [];
  bodyHeight: number = 1280;
  bodyWidth: number = 1024;
  isLoading: boolean = false;
  showPermissionModal: boolean = false;
  selctedFiles: any = [];
  permissionSaving: boolean = false;
  folderMoving: boolean = false;

  /**
   * サイドバーの開閉状態取得
   */
  get sideBarStatus(): boolean {
    return HomeComponentInfoModule.side_bar_open;
  }

  /**
   * ウィンドウの横幅取得
   */
  get windowWidth() {
    let w = window.innerWidth;
    if (this.sideBarStatus) {
      w = w - 200;
    } else {
      w = w - 48;
    }
    return {
      width: String(w) + 'px'
    };
  }

  /**
   * タブのキー取得
   */
  get TAB_MENUS() {
    return TAB_MENUS;
  }

  /**
   * スモールプランかどうか確認
   */
  get isSmallPlan() {
    return CorporateInfo.isSmallPlan;
  }

  /**
   * タブの種類の名称取得
   */
  get tabMenus() {
    if (this.isSmallPlan) {
      return tabMenuNamesSmallPlan;
    }
    return tabMenuNames;
  }

  /**
   * 最近表示したファイル情報取得
   */
  get recentlyViewedFiles() {
    return Documents.recently_viewed_files;
  }

  /**
   * 最近表示したファイル情報のリセット
   */
  resetRecentlyViewedFiles() {
    Documents.SET_RECENTLY_VIEWED_FILES([]);
  }

  /**
   * ファイル情報取得
   */
  private get files() {
    return Documents.files;
  }

  /**
   * ファイル、フォルダ情報取得
   */
  private get foldersAndFiles() {
    if (this.files.length > 0) {
      const objects = [];
      if (this.folders) {
        Object.keys(this.folders).map(folder => {
          objects.push(folder);
        });
      }
      return objects.concat(this.files);
    } else {
      return this.folders;
    }
  }

  /**
   * 締結済みドキュメントタブが選択されているか
   */
  get isContractedDocumentTabSelected() {
    return this.selectedTab === TAB_MENUS.CONTRACTED_DOCUMENT;
  }

  /**
   * 締結管理タブが選択されているか
   */
  get isContractManagementTabSelected() {
    return this.selectedTab === TAB_MENUS.CONTRACT_MANAGEMENT;
  }

  /**
   * 最近表示のファイルのデータがあるかを返す
   */
  private get existRecentlyViewedFiles(): boolean {
    return Object.keys(this.recentlyViewedFiles).some(key => {
      if (key === 'alert_setting') {
        return false;
      }
      return this.recentlyViewedFiles[key].length;
    });
  }

  /**
   * 選択されているファイルのcontract_id取得
   */
  get selectedFileContractIdList() {
    const selectedFileContractId_list = Documents.selected_files_of_table.map(
      function(data) {
        return data.contract_id;
      }
    );
    return selectedFileContractId_list;
  }

  /**
   * 選択されているフォルダのパス取得
   */
  get selectedFolderPathList() {
    const selected_folder_path_list = Documents.selected_folders_of_table.map(
      function(data) {
        return data.directory_path;
      }
    );
    return selected_folder_path_list;
  }

  /**
   * 1ファイルが選択されている時のcontract_id取得
   */
  get selectedSingleFileContractId() {
    if (
      this.selectedFileContractIdList.length === 1 &&
      this.selectedFolderPathList.length === 0
    ) {
      return this.selectedFileContractIdList[0];
    }
    return null;
  }

  /**
   * ディレクトリパス取得
   */
  get directoryPath() {
    if (Documents.selected_files_of_table.length !== 1) return '';
    let path = Documents.selected_files_of_table[0].directory_path;
    if (path === '/') return path;
    if (path.slice(-1) === '/') path = path.slice(0, -1);
    return path;
  }

  /**
   * 移動モーダルの表示状態取得
   */
  get moveSelectedObjectsWindow(): boolean {
    if (HomeComponentInfoModule.move_selected_objects_window) {
      this.currentDirectory = this.directoryPath;
      this.getFolders(this.currentDirectory);
    } else {
      this.folders = [];
      this.getFolders('/');
    }
    return HomeComponentInfoModule.move_selected_objects_window;
  }

  @Watch('selectedTab')
  syncStoreSelectedTab(newval) {
    // 削除の取り消しのためにstoreにも持つことで暫定対応
    SearchInfo.SET_SELECTED_TAB(newval);
  }

  async created() {
    DocumentsModule.SET_CURRENT_DIRECTORY('/');
    Promise.all([
      UserInfo.get_user_info(),
      CorporateInfo.get_service_plan(),
      Documents.getNotifications(),
      Documents.getLirisNotifications(),
      AssociationsModule.getCandidates(),
      SettingsInfoModule.get_setting_alert()
    ])
      .then(values => {
        if (values.find(x => x.status !== HTTP_STATUS.OK)) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
  }

  async mounted() {
    if (DocumentsModule.is_delete_card) {
      notify.success({
        text: NOTIFY_TEXT.SUCCESS.DELETE,
        data: {
          cancelMethod: this.cancelDeleteFileOrFolder
        }
      });
      DocumentsModule.SET_IS_DELETE_CARD(false);
    }

    await Documents.SET_CURRENT_DIRECTORY('/');
    this.currentDirectory = Documents.current_directory;

    if (Documents.is_back_page) {
      this.selectedTab = Documents.selectedTabForRecentlyViewed;
    }
    this.isLoading = true;
    await this.getRecentlyViewdFiles();
    window.addEventListener('resize', this.setBodyShape);
    this.setBodyShape();
    this.isLoading = false;
  }

  destroyed() {
    Documents.SET_IS_BACK_PAGE(false);
    this.resetRecentlyViewedFiles();
  }

  /**
   * ウィンドウの高さ、幅をセット
   */
  setBodyShape() {
    this.bodyHeight = window.innerHeight - 56;
    if (this.sideBarStatus) {
      this.bodyWidth = window.innerWidth - 200;
    } else {
      this.bodyWidth = window.innerWidth - 48;
    }
  }

  /**
   * サイドバーの開閉
   */
  toggleSideBar() {
    HomeComponentInfoModule.change_side_bar();
  }

  /**
   * 移動可能なフォルダ情報取得
   */
  async getFolders(path: string) {
    if (!path) return;

    await DocumentRepository.getDirectoryList(path)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        this.folders = res.data;
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  /**
   * ファイル移動処理
   */
  async moveObject() {
    this.folderMoving = true;
    await MoveObject.moveFileOrFolder();
    this.folderMoving = false;
    notify.success({
      text: '移動が完了しました'
    });
    await this.getRecentlyViewdFiles();
  }

  /**
   * 移動モーダルにて選択されたフォルダパスをセット
   */
  selectedPath(path) {
    HomeComponentInfoModule.SET_MOVE_TO_PATH(path);
  }

  /**
   * 移動モーダルにて選択されたフォルダパスからフォルダ情報取得
   */
  changePath(path) {
    this.getFolders(path);
  }

  /**
   * 移動モーダルキャンセル処理
   */
  cancelMove() {
    HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW(false);
  }

  /**
   * タブを切り替える
   */
  async selectTab(value) {
    if (this.selectedTab === value) {
      return;
    }
    this.selectedTab = value;
    this.isLoading = true;
    Documents.SET_SELECTED_TAB_FOR_RECENTLY_VIEWED(value);
    await this.getRecentlyViewdFiles();
    this.isLoading = false;
  }

  /**
   * 最近表示ファイル情報取得
   */
  async getRecentlyViewdFiles() {
    await Documents.getRecentlyViewedFiles(
      this.isContractedDocumentTabSelected
    ).catch(err => {
      notify.error({
        text: NOTIFY_TEXT.ERROR.GENERAL
      });
    });
  }

  /**
   * 閲覧権限設定
   */
  async saveViewPermission(data) {
    this.permissionSaving = true;
    const selectedFileContractIdList = this.selectedFileContractIdList;

    const userIdList = data.selected_users.map(user => user.user_id);
    const departmentIdList = data.selected_departments.map(
      department => department.department_id
    );

    const request = {
      user_id_list: userIdList,
      department_id_list: departmentIdList,
      contract_id_list: selectedFileContractIdList,
      directory_path_list: this.selectedFolderPathList
    };

    await DocumentRepository.saveViewPermission(request)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.FAILED
          });
          return;
        }

        notify.success({
          text: NOTIFY_TEXT.SUCCESS.SETTING_VIEW_PERMISSION
        });
        // 閲覧権限設定できたらテーブルのリセット
        Documents.RESET_SELECTED_TABLE();
        Documents.getRecentlyViewedFiles(this.isContractedDocumentTabSelected);
        // v-ifでカードを表示してカード非表示時にカード内の値を初期化
        this.showPermissionModal = false;
      })
      .catch(err => {
        if (err.response.status === HTTP_STATUS.BAD_REQUEST) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.EMPTY
          });
        } else {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.FAILED
          });
        }
      })
      .finally(() => {
        this.permissionSaving = false;
      });
  }

  async cancelDeleteFileOrFolder(): Promise<void> {
    // 表示されているスナックバーを消す
    notify.clearSuccess();
    this.isLoading = true;
    const responses = await DocumentsModule.restoreDeletedFileOrFolder();
    this.isLoading = false;

    // ファイルもしくはフォルダの削除取り消しでエラーがあれば notify を表示し処理を終える。
    const failed: boolean = responses.some(
      res => !res || res.status !== HTTP_STATUS.NO_CONTENT
    );
    if (failed) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.CANCEL
      });
      return;
    }

    this.isLoading = true;
    DocumentsModule.RESET_SELECTED_TABLE();
    this.isLoading = false;
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CANCEL
    });
  }
}
