




































































































































































































































































import { Component, Vue, PropSync, Watch } from 'vue-property-decorator';
import ContractTypeSelectBox from '@/components/molecules/search_box/ContractTypeSelectBox.vue';
import SearchBoxFormCalendarPeriod from '@/components/molecules/search_box/SearchBoxFormCalendarPeriod.vue';
import SearchBoxFormInput from '@/components/molecules/search_box/SearchBoxFormInput.vue';
import SearchBoxFormRadioGroup from '@/components/molecules/search_box/SearchBoxFormRadioGroup.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import SelectLabels from '@/components/molecules/labels/SelectLabels.vue';

import SearchInfo from '@/store/SearchInfo';
import Documents from '@/store/Documents';
import ContractManagementModule from '@/store/ContractInfo';

const KEYWORD_RANGE_TYPES = {
  CONTAINS: 'contains',
  EQUALS: 'equals'
};

const TARGET_SEARCH_TYPES = {
  ONLY_DISPLAYED: 'only_displayed',
  ALL: 'all'
};

@Component({
  components: {
    SelectBox,
    ContractTypeSelectBox,
    SearchBoxFormRadioGroup,
    SearchBoxFormInput,
    SearchBoxFormCalendarPeriod,
    AppButton,
    SelectLabels
  }
})
export default class ContractSearchDetail extends Vue {
  formData = {
    targetType: TARGET_SEARCH_TYPES.ONLY_DISPLAYED,
    keyword: null,
    keywordType: KEYWORD_RANGE_TYPES.CONTAINS,
    exclude_file_text: false,
    contractTitleType: KEYWORD_RANGE_TYPES.CONTAINS,
    counterPartyType: KEYWORD_RANGE_TYPES.CONTAINS,
    contract_labels: [],
    control_number_start: null,
    control_number_end: null,
    contract_type: null,
    search_null_contract_type: false,
    contract_title: '',
    counterparty: null,
    search_null_counterparty: false,
    contract_start: null,
    contract_end: null,
    contract_start_start: null,
    contract_start_end: null,
    contract_expiration_start: null,
    contract_expiration_end: null,
    update_limit_start: null,
    update_limit_end: null,
    alert_only_expirated: false,
    alert_only_before_expiration: false,
    alert_only_before_auto_update: false,
    transaction_amount_start: null,
    transaction_amount_end: null,
    search_null_transaction_amount: null
  };

  transactionAmountType: string = 'text';

  @PropSync('isAndSearch', { type: Boolean })
  syncIsAndSearch!: boolean;

  @PropSync('isAllFolderSearch', { type: Boolean })
  syncIsAllFolderSearch!: boolean;

  async mounted() {
    this.formData.targetType =
      this.searching || SearchInfo.all_docs
        ? TARGET_SEARCH_TYPES.ALL
        : TARGET_SEARCH_TYPES.ONLY_DISPLAYED;

    this.formData.keyword = SearchInfo.free_keyword;
    this.formData.keywordType = SearchInfo.and_search
      ? KEYWORD_RANGE_TYPES.EQUALS
      : KEYWORD_RANGE_TYPES.CONTAINS;
    this.formData.exclude_file_text = SearchInfo.exclude_file_text;

    this.formData.control_number_start = SearchInfo.control_number_start;
    this.formData.control_number_end = SearchInfo.control_number_end;
    this.formData.contract_type = ContractManagementModule.boardContractTypes.find(
      (t: any) => {
        return t.contract_type_id == SearchInfo.contract_type_id;
      }
    );
    this.formData.search_null_contract_type =
      SearchInfo.search_null_contract_type;
    this.formData.contract_title = SearchInfo.contract_title;
    this.formData.counterparty = SearchInfo.counterparty_name;
    this.formData.contractTitleType = SearchInfo.contract_title_and_search
      ? KEYWORD_RANGE_TYPES.EQUALS
      : KEYWORD_RANGE_TYPES.CONTAINS;
    this.formData.counterPartyType = SearchInfo.counterparty_name_and_search
      ? KEYWORD_RANGE_TYPES.EQUALS
      : KEYWORD_RANGE_TYPES.CONTAINS;
    this.formData.search_null_counterparty =
      SearchInfo.search_null_counterparty;

    this.formData.transaction_amount_start =
      SearchInfo.transaction_amount_start;
    this.formData.transaction_amount_end = SearchInfo.transaction_amount_end;
    this.formData.search_null_transaction_amount =
      SearchInfo.search_null_transaction_amount;

    this.formData.contract_start = SearchInfo.contract_date_start;
    this.formData.contract_end = SearchInfo.contract_date_end;
    this.formData.contract_start_start = SearchInfo.contract_start_start;
    this.formData.contract_start_end = SearchInfo.contract_start_end;
    this.formData.contract_expiration_start =
      SearchInfo.contract_expiration_start;
    this.formData.contract_expiration_end = SearchInfo.contract_expiration_end;
    this.formData.update_limit_start = SearchInfo.update_data_start;
    this.formData.update_limit_end = SearchInfo.update_data_end;
    this.formData.alert_only_expirated = SearchInfo.alert_only_expirated;
    this.formData.alert_only_before_expiration =
      SearchInfo.alert_only_before_expiration;
    this.formData.alert_only_before_auto_update =
      SearchInfo.alert_only_before_auto_update;

    await Promise.all([
      Documents.getControlNumbers(),
      Documents.getContractLabels()
    ]);

    this.formData.contract_labels = Documents.contract_labels.filter(label => {
      return SearchInfo.label_ids.includes(label.label_id);
    });

    this.transactionAmountType = 'text';
    this.transactionAmountBlur();
  }

  beforeDestroy() {
    this.syncIsAndSearch = false;
    if (this.searching) {
      this.syncIsAllFolderSearch = true;
    } else {
      this.syncIsAllFolderSearch = false;
    }
  }

  @Watch('searching')
  watchSearching() {
    if (this.searching) {
      this.formData.targetType = TARGET_SEARCH_TYPES.ALL;
    }
  }

  get disableValue() {
    if (this.searching) {
      return TARGET_SEARCH_TYPES.ONLY_DISPLAYED;
    }
    return null;
  }

  get keywordRangeTypes() {
    return {
      contains: 'いずれかを含む',
      equals: 'すべて含む'
    };
  }

  get targetSearchTypes() {
    return {
      only_displayed: '表示中のフォルダ',
      all: 'すべてのフォルダ'
    };
  }

  get controlNumbers() {
    return Documents.control_numbers;
  }

  get searching(): boolean {
    return SearchInfo.searching;
  }

  get contractLabels() {
    return Documents.contract_labels;
  }

  transactionAmountBlur() {
    this.transactionAmountType = 'text';
    if (parseInt(this.formData.transaction_amount_start, 10) >= 0) {
      SearchInfo.SET_TRANSACTION_AMOUNT_START(
        this.formData.transaction_amount_start
      );
      this.formData.transaction_amount_start = Number(
        this.formData.transaction_amount_start
      ).toLocaleString();
    }
    if (parseInt(this.formData.transaction_amount_end, 10) >= 0) {
      SearchInfo.SET_TRANSACTION_AMOUNT_END(
        this.formData.transaction_amount_end
      );
      this.formData.transaction_amount_end = Number(
        this.formData.transaction_amount_end
      ).toLocaleString();
    }
  }

  transactionAmountFocus() {
    if (parseInt(this.formData.transaction_amount_start, 10) >= 0) {
      this.formData.transaction_amount_start = parseInt(
        this.formData.transaction_amount_start.replace(/,/g, ''),
        10
      );
    }
    if (parseInt(this.formData.transaction_amount_end, 10) >= 0) {
      this.formData.transaction_amount_end = parseInt(
        this.formData.transaction_amount_end.replace(/,/g, ''),
        10
      );
    }
    this.transactionAmountType = 'number';
  }

  changeKeyword(val) {
    SearchInfo.SET_FREE_KEYWORD(val);
  }

  resetForm() {
    this.formData.targetType = TARGET_SEARCH_TYPES.ONLY_DISPLAYED;
    this.formData.keywordType = KEYWORD_RANGE_TYPES.CONTAINS;
    this.formData.exclude_file_text = false;
    this.formData.contract_type = null;
    this.formData.search_null_contract_type = false;
    this.formData.search_null_counterparty = false;
    this.formData.search_null_transaction_amount = false;
    this.formData.contractTitleType = KEYWORD_RANGE_TYPES.CONTAINS;
    this.formData.counterPartyType = KEYWORD_RANGE_TYPES.CONTAINS;

    this.formData.contract_labels = [];

    this.formData.alert_only_expirated = false;
    this.formData.alert_only_before_expiration = false;
    this.formData.alert_only_before_auto_update = false;

    [
      'keyword',
      'control_number_start',
      'control_number_end',
      'contract_title',
      'counterparty',
      'contract_start',
      'contract_end',
      'contract_start_start',
      'contract_start_end',
      'contract_expiration_start',
      'contract_expiration_end',
      'update_limit_start',
      'update_limit_end',
      'transaction_amount_start',
      'transaction_amount_end'
    ].forEach(key => {
      this.formData[key] = '';
    });
  }

  /**
   * ラジオボタンの切り替え
   */
  changeRadioSelected(name, value) {
    if (value === 'equals') {
      this.syncIsAndSearch = true;
    } else if (value === 'contains') {
      this.syncIsAndSearch = false;
    } else if (value === 'all') {
      this.syncIsAllFolderSearch = true;
    } else if (value === 'only_displayed') {
      this.syncIsAllFolderSearch = false;
    }
    this.formData[name] = value;
  }

  /**
   * カレンダーの入力
   */
  changeDate(name, suffix, date) {
    const keyName = `${name}_${suffix}`;
    this.formData[keyName] = date;
  }

  /**
   * 契約種別の選択切り替え
   */
  changeContractType(type) {
    this.formData.contract_type = type;
  }

  async searchDocumentByQuery(e) {
    SearchInfo.SET_IS_SEARCHING_NOW(true);

    const contractTypeId =
      this.formData.contract_type &&
      this.formData.contract_type.contract_type_id
        ? this.formData.contract_type.contract_type_id
        : '';

    const query: any = await SearchInfo.get_query({
      all_docs: this.formData.targetType === TARGET_SEARCH_TYPES.ALL,

      // キーワード
      free_keyword: this.formData.keyword,
      and_search: this.formData.keywordType === KEYWORD_RANGE_TYPES.EQUALS,
      exclude_file_text: this.formData.exclude_file_text,

      // 契約書 No.
      control_number_start: this.formData.control_number_start,
      control_number_end: this.formData.control_number_end,

      // 契約書名
      contract_title: this.formData.contract_title,
      contract_title_and_search:
        this.formData.contractTitleType === KEYWORD_RANGE_TYPES.EQUALS,

      // 相手方名
      counterparty_name: this.formData.counterparty,
      counterparty_name_and_search:
        this.formData.counterPartyType === KEYWORD_RANGE_TYPES.EQUALS,
      search_null_counterparty: this.formData.search_null_counterparty,

      // 契約種別
      contract_type_id: contractTypeId,
      search_null_contract_type: this.formData.search_null_contract_type,

      label_ids: this.formData.contract_labels.map(label => label.label_id),

      // 取引金額
      transaction_amount_start: this.formData.transaction_amount_start,
      transaction_amount_end: this.formData.transaction_amount_end,
      search_null_transaction_amount: this.formData
        .search_null_transaction_amount,

      // 契約日
      contract_date_start: this.formData.contract_start,
      contract_date_end: this.formData.contract_end,

      // 契約開始日
      contract_start_start: this.formData.contract_start_start,
      contract_start_end: this.formData.contract_start_end,

      // 契約終了日
      contract_expiration_start: this.formData.contract_expiration_start,
      contract_expiration_end: this.formData.contract_expiration_end,

      // 更新期限日
      update_data_start: this.formData.update_limit_start,
      update_data_end: this.formData.update_limit_end,

      alert_only_expirated: this.formData.alert_only_expirated,
      alert_only_before_expiration: this.formData.alert_only_before_expiration,
      alert_only_before_auto_update: this.formData.alert_only_before_auto_update
    });

    // TODO: store に持たせている検索条件をこのコンポーネントの data に閉じる
    // SearchInfo 内の action である search_documents あたりの調整が必要そう。
    this.$router
      .push({
        path: '/home/searching',
        query
      })
      .catch(err => {});
  }

  clearContractType() {
    this.formData.contract_type = null;
  }

  clearCounterparty() {
    this.formData.counterparty = null;
  }

  clearTransactionAmount() {
    this.formData.transaction_amount_start = null;
    this.formData.transaction_amount_end = null;
  }
}
