









import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SettingsSideMenuBar extends Vue {
  items: any = [
    {
      name: 'アカウント情報',
      route_name: 'accounts'
    },
    {
      name: 'ユーザー管理',
      route_name: 'members'
    },
    {
      name: '締結管理',
      route_name: 'contract_management'
    },
    {
      name: '会社情報',
      route_name: 'settings_corporation_info'
    }
  ];
  window_height() {}

  routing(route_name) {
    const name = this.$route.name;
    if (name != 'settings_corporation_info') {
      this.$router.push({ name: route_name });
    }
  }
}
