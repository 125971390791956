const axios = require('axios').default;

const resource = '/user-management/collaboration';

export default {
  /**
   * シークレットキーの作成
   */
  generateSecretKey() {
    return axios.get(`${resource}/generate/secret-key`);
  },

  /**
   * BUNTAN連携
   */
  enableBuntan(params) {
    return axios.get(`${resource}/buntan`, { params });
  }
};
