import store from '@/store';
import Vue from 'vue';
import Vuex from 'vuex';
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';

Vue.use(Vuex);

@Module({
  dynamic: true,
  store: store,
  name: 'home_component_info',
  namespaced: true
})
export class HomeComponentInfo extends VuexModule {
  side_bar_open: boolean = true;
  upload_menu_switch: boolean = false;
  document_list_menu: boolean = false;
  move_selected_objects_window: boolean = false;
  move_selected_objects_window_for_contract_management: boolean = false;
  move_to_path: string = '';
  change_tables: number = 0;
  uploaded: boolean = true;

  @Mutation
  SET_UPLOADED(bool: boolean) {
    this.uploaded = bool;
  }

  @Mutation
  SET_MOVE_TO_PATH(path: string) {
    this.move_to_path = path;
  }

  @Mutation
  change_tables_countup() {
    this.change_tables += 1;
  }

  @Mutation
  SET_MOVE_SELECTED_OBJECTS_WINDOW(bool: boolean) {
    this.move_selected_objects_window = bool;
  }

  @Mutation
  SET_MOVE_SELECTED_OBJECTS_WINDOW_FOR_CONTRACT_MANAGEMENT(bool: boolean) {
    this.move_selected_objects_window_for_contract_management = bool;
  }

  @Mutation
  SET_SIDE_BAR_OPEN(open: boolean) {
    this.side_bar_open = open;
  }

  @Mutation
  SET_DOCUMENT_LIST_MENU(document_list_menu: boolean) {
    this.document_list_menu = document_list_menu;
  }

  @Mutation
  SET_UPLOAD_MENU_SWITCH(upload_menu_switch: boolean) {
    this.upload_menu_switch = upload_menu_switch;
  }

  @Action({ rawError: true })
  finishUpload() {
    this.SET_UPLOADED(true);
  }

  @Action({ rawError: true })
  change_side_bar() {
    this.SET_SIDE_BAR_OPEN(!this.side_bar_open);
  }

  @Action({ rawError: true })
  change_document_list_menu() {
    const newVal: boolean = !this.document_list_menu;
    this.SET_DOCUMENT_LIST_MENU(newVal);
  }

  @Action({ rawError: true })
  change_upload_menu_switch(bool: boolean) {
    this.SET_UPLOAD_MENU_SWITCH(bool);
  }
}

const HomeComponentInfoModule = getModule(HomeComponentInfo);

export default HomeComponentInfoModule;
