










import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class IconSvg extends Vue {
  @Prop()
  fileName!: string;
  @Prop({})
  alt!: string;
  mode: string = 'enabled';
  @Prop({ default: false })
  isDisabled!: boolean;

  setHover() {
    if (this.isDisabled) return;
    this.mode = 'hover';
  }

  setEnabled() {
    this.mode = 'enabled';
  }

  actPressed() {
    if (this.isDisabled) return;
    this.$emit('click');
    this.mode = 'pressed';
    setTimeout(() => {
      this.mode = this.mode === 'enabled' ? 'enabled' : 'hover';
    }, 150);
  }
}
