

















































































































































































import AppButton from '@/components/atoms/buttons/AppButton.vue';
import AttachDocumentCard from '@/components/molecules/AttachDocumentCard.vue';
import SelectContractModal from '@/components/molecules/modal_windows/SelectContractModal.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import InfoItem from '@/components/organisms/DocumentDetail/DocumentPreview/InfoItem.vue';
import HTTP_STATUS from '@/consts/HttpStatus';
import LOADING_STATUSES from '@/consts/LoadingStatuses';
import NOTIFY_TEXT from '@/consts/NotifyText';
import { catchOnGetContractDetail } from '@/functions/DocumentRouter';
import FileDownload from '@/functions/FileDownload';
import notify from '@/functions/notify';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import AssociationsModule, { AssociationInfo } from '@/store/Associations';
import AttachmentsModule, { Attachment } from '@/store/Attachments';
import Documents from '@/store/Documents';
import UserInfo from '@/store/UserInfo';
import vLoading from 'vue-loading-overlay';
import { Component, Vue, Watch } from 'vue-property-decorator';

const axios = require('axios').default;
const AssociationRepository = RepositoryFactory.get('associations');
const AttachmentRepository = RepositoryFactory.get('attachments');
const DocumentRepository = RepositoryFactory.get('documents');

@Component({
  components: {
    AttachDocumentCard,
    AppButton,
    SelectContractModal,
    InfoItem,
    vLoading,
    TextOnlyWindow
  }
})
export default class DocumentInfoReferences extends Vue {
  isShowingSelectContractModal: boolean = false;
  isShowingRemoveAssociationModal: boolean = false;
  isShowingRemoveAttachmentModal: boolean = false;
  is_showing_attachment_document_window: boolean = false;
  showAttachDocsDialog: boolean = false;
  attachFileList: File[] = [];
  selectedRemoveAssociation: AssociationInfo = null;
  selectedRemoveAttachment: Attachment = null;
  fileLimit: number = 15;
  isLoading: boolean = false;
  fileAttaching: boolean = false;
  approvingContract: boolean = false;

  savingStatuses: object = {
    is_stored: LOADING_STATUSES.DEFAULT,
    note: LOADING_STATUSES.DEFAULT
  };

  editNote: boolean = false;

  mounted() {
    this.editNote = false;
  }

  formatHrefLink(string) {
    const regex = /(https?:\/\/[\w\.\d:;?&=#/_+!$'()*@~%\-]+)/gm;
    const subst = `$1<a href="$1" target="_blank" rel="noopener noreferrer"><img id="openLinkIcon" src="/static/icons/external-link-blue.svg" style='width: 14px; margin: 0 8px;'></a>`;
    const result = string.replace(regex, subst);
    return result;
  }

  handleLinkClick(e) {
    const clickedElId = e.target.id
    if (clickedElId === 'openLinkIcon') {
      return true
    }
    return false
  }

  clickNote(e) {
    if (this.handleLinkClick(e)) {
      this.editNote = false;
    } else {
      this.editNote = true;
    }
  }

  get canEditNote() {
    if (this.permission.can_edit_contract_info) {
      if (!this.note) {
        this.editNote = true;
      }
    } else {
      this.editNote = false;
    }
    return this.editNote;
  }

  get attachDocumentButtonText(): string {
    return this.fileAttaching ? '保存中' : '保存';
  }

  get permission() {
    return UserInfo.permission;
  }

  get contractDetail() {
    return Documents.contract_detail;
  }

  // 締結管理から締結済になった場合は、締結済であることを確認
  // 直接、締結済になった場合は常にtrue
  get canFinish(): boolean {
    if (!this.contractDetail || !this.contractDetail.kanban_card_info) {
      return true;
    }
    const kanbanCardInfo = this.contractDetail.kanban_card_info;
    return kanbanCardInfo.can_finish;
  }

  get isStored(): boolean {
    return this.contractDetail.is_stored;
  }
  set isStored(is_stored) {
    this.updateContractDetail({ is_stored });
  }

  get associatedContracts(): AssociationInfo[] {
    if (!this.contractDetail.associated_contract_list) return [];
    return this.contractDetail.associated_contract_list;
  }

  // 紐付けモーダルには閲覧中のドキュメントも含まれてほしい
  get associatedContractsForModal(): AssociationInfo[] {
    const contractId = this.contractDetail.contract_info.contract_id;
    return [
      ...this.candidatesContracts.filter(c => c.contract_id === contractId)
    ];
  }

  get candidatesContracts(): AssociationInfo[] {
    if (!AssociationsModule) return [];
    return AssociationsModule.candidates;
  }

  get attachments(): Attachment[] {
    return this.contractDetail.attachment_list;
  }

  get canUploadAttachments(): boolean {
    const fileCount = this.attachments.length + this.attachFileList.length;
    return fileCount < this.fileLimit;
  }

  get canUploadAssociatedContracts(): boolean {
    const contractId = this.contractDetail.contract_info.contract_id;
    const contractes = [
      ...this.candidatesContracts.filter(c => c.contract_id === contractId),
      ...this.associatedContracts
    ];

    // 関連元となるファイルも含まれているため -1 を行う
    const fileCount = contractes.length - 1;
    return fileCount < this.fileLimit;
  }

  @Watch('note')
  onNoteChanged(newVal, oldVal) {
    if (!newVal) {
      this.editNote = true;
    } else {
      this.editNote = false;
    }
  }

  get note(): string {
    return this.contractDetail.note;
  }
  set note(note) {
    this.updateContractDetail({ note });
  }

  get iconImgSrcByExt() {
    return ext => {
      switch (ext) {
        case 'pdf':
          return 'icon_pdf_normal.svg';
        case 'docx':
          return 'icon_word_normal.svg';
        case 'xlsx':
          return 'icon_excel_normal.svg';
        default:
          return '';
      }
    };
  }

  openRemoveAssociationModal(targetContract: AssociationInfo) {
    this.selectedRemoveAssociation = targetContract;
    this.isShowingRemoveAssociationModal = true;
  }

  openRemoveAttachmentModal(targetAttachment: Attachment) {
    this.selectedRemoveAttachment = targetAttachment;
    this.isShowingRemoveAttachmentModal = true;
  }

  async created() {
    await AssociationsModule.getCandidates();
  }

  async reload() {
    await Documents.getContractDetail(this.$route.params.id).catch(err => {
      catchOnGetContractDetail(this.$router, err);
    });
  }

  async createAssociation(select_contracts) {
    this.approvingContract = true;
    const params = {
      select_contracts
    };

    await AssociationRepository.create(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.RELATED_CONTRACT
          });
          return;
        }
        await AssociationsModule.getCandidates();
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.RELATED_CONTRACT
        });

        this.isShowingSelectContractModal = false;
        await this.reload();
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.RELATED_CONTRACT
        });
      })
      .finally(() => {
        this.approvingContract = false;
      });
  }

  async cancelMethod() {
    // 通信をキャンセルする
    await AttachmentsModule.cancelRequest();
    // キャンセル後にファイル情報を再取得する
    Documents.getFilesAndFolders();
  }

  async attachDocs() {
    const attachFileCount = this.attachFileList.length;
    notify.success({
      text: `${attachFileCount}個の資料を添付しています･･･`
      // 通信のキャンセルが現状できないため現状コメントアウト、できるようになったら外す
      // data: {
      //   cancelMethod: this.cancelMethod
      // }
    });
    this.fileAttaching = true;
    const formData = new FormData();
    for (const file of this.attachFileList) {
      formData.append('attachment_list', file);
    }
    const params = {
      contract_id: this.contractDetail.contract_info.contract_id,
      attach_files: this.attachFileList,
      formData
    };

    const source = axios.CancelToken.source();
    await AttachmentsModule.ADD_CANCEL_TOKEN(source);

    await AttachmentRepository.upload(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
          return;
        }

        notify.clearSuccess();
        this.is_showing_attachment_document_window = false;
        this.fileAttaching = false;

        setTimeout(() => {
          notify.success({
            text: `${attachFileCount}${NOTIFY_TEXT.SUCCESS.ATTACHED_DOCUMENT}`
          });
        }, 300);

        this.attachFileList = [];
        await this.reload();
      })
      .catch(e => {
        this.is_showing_attachment_document_window = false;
        this.fileAttaching = false;
        notify.clearSuccess();
        notify.error({
          text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
        });
      });
  }

  async removeAssociation() {
    const params = {
      contract_id: this.contractDetail.contract_info.contract_id,
      delete_associated_contract_id: this.selectedRemoveAssociation.contract_id
    };
    this.isLoading = true;
    await AssociationRepository.delete(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.REMOVE_ASSOCIATION
          });
          return;
        }

        await this.reload();
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.REMOVE_ASSOCIATION
        });
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.REMOVE_ASSOCIATION
        });
      })
      .finally(() => {
        this.isShowingRemoveAssociationModal = false;
        this.isLoading = false;
      });
  }

  async removeAttachment() {
    this.isLoading = true;

    await AttachmentRepository.delete(
      this.selectedRemoveAttachment.attachment_id
    )
      .then(async res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: `${this.selectedRemoveAttachment.file_name}を削除できませんでした`
          });
          return;
        }

        await this.reload();
        notify.success({
          text: `${this.selectedRemoveAttachment.file_name}を削除しました`
        });
      })
      .catch(async e => {
        // 既に削除されている場合は404が返ってくるのでリロードを行う
        if (e.response && e.response.status === HTTP_STATUS.NOT_FOUND) {
          notify.error({
            text: 'このファイルはすでに削除されています'
          });
          await this.reload();
        } else {
          notify.error({
            text: `${this.selectedRemoveAttachment.file_name}を削除できませんでした`
          });
        }
      })
      .finally(() => {
        this.isShowingRemoveAttachmentModal = false;
        this.isLoading = false;
      });
  }

  updateContractDetail(params) {
    const paramKeys = Object.keys(params);
    paramKeys.forEach(k => (this.savingStatuses[k] = LOADING_STATUSES.LOADING));
    DocumentRepository.updateContractDetail({
      contract_id: this.contractDetail.contract_info.contract_id,
      ...params
    })
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          paramKeys.forEach(
            k => (this.savingStatuses[k] = LOADING_STATUSES.FAILURE)
          );
          return;
        }
        Documents.SET_CONTRACT_DETAIL_PROPS({ ...params });
        paramKeys.forEach(
          k => (this.savingStatuses[k] = LOADING_STATUSES.COMPLETE)
        );
      })
      .catch(e => {
        paramKeys.forEach(
          k => (this.savingStatuses[k] = LOADING_STATUSES.FAILURE)
        );
        return;
      });
  }

  get attachDocsNum() {
    return this.attachments.length + this.attachFileList.length;
  }

  get disabledAttachDocs() {
    if (this.attachFileList.length === 0 || this.fileAttaching) {
      return true;
    } else if (this.attachDocsNum > this.fileLimit) {
      return true;
    } else {
      return false;
    }
  }

  async downloadAttachmentFile(attachment) {
    const attachmentId = attachment.attachment_id;
    const filename = attachment.file_name;

    const result = await FileDownload.downloadAttachmentFile(
      attachmentId,
      filename
    );

    if (!result) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.GENERAL
      });
    }
  }
}
