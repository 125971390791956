import HTTP_STATUS from '@/consts/HttpStatus';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const DocumentRepository = RepositoryFactory.get('documents');

/**
 * バックアップファイルダウンロード
 *
 * @param contractId string
 */
async function downloadBackupFile(contractId: string) {
  await DocumentRepository.backupContractFile(contractId)
    .then(res => {
      if (res.status !== HTTP_STATUS.OK) {
        return false;
      }

      const contentDisposition = res.headers['content-disposition'];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      let filename = '';
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }

      const blob = new Blob([res.data], { type: 'application/zip' });
      const uri = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = filename;
      link.href = uri;
      link.click();

      return true;
    })
    .catch(() => {
      return false;
    });
}

export default {
  downloadBackupFile
};
