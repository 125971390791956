


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

const EXTENSION_DATES = [
  {
    label: '14日前',
    value: '14'
  },
  {
    label: '30日前',
    value: '30'
  },
  {
    label: '60日前',
    value: '60'
  }
];

/**
 */
@Component({})
export default class AlertTermRadioButton extends Vue {
  @Prop()
  name: string;

  @Prop()
  value: any;

  @Prop()
  disabled: boolean;

  extension_dates: any = EXTENSION_DATES;
  selected: string = '';
  custom_input: string = '';
  can_change_custom: boolean = false;

  mounted() {
    this.refs.customInput.addEventListener('keypress', e => {
      if (!/[0-9]/.test(e.key)) {
        e.preventDefault();
      }
    });
  }

  get refs(): any {
    return this.$refs;
  }

  update_alert_date(): void {
    if (!this.value) {
      this.selected = this.value;
      return;
    }

    if (this.selected === '0') {
      return;
    }

    EXTENSION_DATES.forEach(date => {
      if (date.value == this.value) {
        this.selected = this.value;
      }
    });

    if (!this.selected) {
      this.selected = '0';
      this.custom_input = this.value;
      this.can_change_custom = true;
    }
  }

  @Watch('value')
  update_value(): void {
    this.update_alert_date();
  }

  @Watch('selected')
  update_selected(new_value: string) {
    if (new_value === '0') {
      this.can_change_custom = true;
      this.custom_input = this.value;
      return;
    }
    this.can_change_custom = false;
    this.custom_input = '';
    this.$emit('input', new_value);
  }

  @Watch('custom_input')
  update_custom_input(new_value: string) {
    if (Number(new_value) <= 0) {
      this.custom_input = '';
      return;
    } else if (new_value.length > 2) {
      this.custom_input = new_value.slice(0, 2);
    }
    this.$emit('input', new_value);
  }
}
