




import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class IconLoadingSpinner extends Vue {
  @Prop({ default: '1.7em' })
  size?: string;

  hoge: boolean = true;

  get loaderStyle() {
    return {
      width: this.size,
      height: this.size
    };
  }
}
