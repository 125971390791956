














import { Component, Prop, Vue } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';

@Component({
  components: {},
  directives: {
    ClickOutside
  }
})
export default class UploadButton extends Vue {
  @Prop()
  disabled: boolean;

  onClickOutside(event: any) {
    this.$emit('onUploadMenu', false);
  }
}
