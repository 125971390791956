



































import { Component, Vue, Prop } from 'vue-property-decorator';
import ExtractIcon from '@/components/molecules/ExtractIcon.vue';
import ClickOutside from 'vue-click-outside';
import LOADING_STATUSES from '@/consts/LoadingStatuses';
import Icon from '@/components/atoms/icons/Icon.vue';
import IconLoadingSpinner from '@/components/atoms/icons/IconLoadingSpinner.vue';

@Component({
  components: {
    ExtractIcon,
    Icon,
    IconLoadingSpinner
  },
  directives: {
    ClickOutside
  }
})
export default class InfoListItem extends Vue {
  @Prop({ required: true, type: String }) name: string;
  @Prop({ default: false, type: Boolean }) isAutoExtract: boolean;
  @Prop({ default: false, type: Boolean }) isProcessing: boolean;
  @Prop({ default: false, type: Boolean }) disabled: boolean;
  @Prop({ type: String }) status: string;
  // ラベル選択/自動更新コンポーネントの挙動不審回避用
  @Prop({ default: 'label', type: String }) contentContainerTagName: string;
  @Prop({ type: Object }) updateTarget: object;

  isSelected: boolean = false;
  isOutSideClick: boolean = false;

  get loadingStatuses() {
    return LOADING_STATUSES;
  }

  get loadingStatusClasses() {
    return {
      'save-complete': this.status === this.loadingStatuses.COMPLETE
    };
  }

  /**
   * 対象のエレメントの領域外をクリック時にフラグをtrue
   */
  onOutSideItem() {
    if (this.disabled) {
      return;
    }
    this.isOutSideClick = true;

    if (this.isSelected) this.$emit('updateItem', this.updateTarget);

    this.isSelected = false;
    this.isOutSideClick = false;
  }

  /**
   * 対象エレメントをクリック時にフラグをtrue
   */
  onSelectedItem() {
    if (this.disabled) {
      return;
    }
    this.isSelected = true;
  }

  onUpdateFromFailure() {
    this.onSelectedItem();
    this.onOutSideItem();
  }
}
