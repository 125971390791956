












import { Component, Prop, Vue } from 'vue-property-decorator';
import MenuContent from '@/components/atoms/buttons/MenuContent.vue';

@Component({
  components: {
    MenuContent
  }
})
export default class MenuContainer extends Vue {
  @Prop({ default: () => [] })
  contents_prop?: string[];
  @Prop()
  mode?: string;

  contents: string[] = this.contents_prop;

  width: string = '';
  marginTop: string = '-23px';
  marginLeft: string = '100px';

  click(val: any) {
    this.$emit('click', val);
  }
}
