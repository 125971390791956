






























































































































































































































































































































































































import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import MenuContent from '@/components/atoms/buttons/MenuContent.vue';
import IconZoomOut from '@/components/atoms/icons/IconZoomOut.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import Label from '@/components/atoms/labels/Label.vue';
import DropdownMenuContainer from '@/components/molecules/DropdownMenuContainer.vue';
import ExtractIcon from '@/components/molecules/ExtractIcon.vue';
import MenuContainer from '@/components/molecules/MenuContainer.vue';
import ViewPermissionCard from '@/components/molecules/ViewPermissionCard.vue';
import ContractLabels from '@/components/molecules/labels/ContractLabels.vue';
import AddKanbanColumnWindow from '@/components/molecules/modal_windows/AddKanbanColumnWindow.vue';
import DeleteContractDetailCard from '@/components/molecules/modal_windows/DeleteContractDetailCard.vue';
import DocumentMoveWindowForContractManagement from '@/components/molecules/modal_windows/DocumentMoveWindowForContractManagement.vue';
import SignedUploadModal from '@/components/molecules/modal_windows/SignedUploadModal.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import ContractCard from '@/components/organisms/ContractKanban/ContractCard.vue';
import { Component, Emit, Prop, Vue, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';

import FileBackup from '@/functions/FileBackup';
import FileDownload from '@/functions/FileDownload';
import MoveObject from '@/functions/MoveObject';
import notify from '@/functions/notify';

import ContractManagementModule from '@/store/ContractInfo';
import CorporateInfoModule from '@/store/CorporateInfo';
import DocumentsModule from '@/store/Documents';
import GMOSignInfoModule from '@/store/GMOSign';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';
import UserInfo from '@/store/UserInfo';

import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import WINDOW_MESSAGES from '@/consts/TextWindowMessage';

import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import vLoading from 'vue-loading-overlay';
dayjs.locale('ja');

import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');
const ContractInfoRepository = RepositoryFactory.get('contractInfos');

type DragCardLocation = {
  column_id: string;
  card_index: number;
};
@Component({
  components: {
    Label,
    ContractLabels,
    ImageContent,
    IconZoomOut,
    ExtractIcon,
    draggable,
    AppButton,
    MenuContainer,
    DropdownMenuContainer,
    MenuContent,
    ViewPermissionCard,
    TextOnlyWindow,
    TextAvatar,
    vLoading,
    SignedUploadModal,
    DeleteContractDetailCard,
    AddKanbanColumnWindow,
    ContractCard,
    DocumentMoveWindowForContractManagement
  }
})
export default class ContractKanban extends Vue {
  searchInputText: string = null;
  searchArchivedInputText: string = null;
  cardListHeight: number = 0;
  isOpenCardFinishedWindow: boolean = false;
  isOpenAllardFinitedWindow: boolean = false;
  isShowingSignedUploadModal = false;
  isLoading: boolean = false;
  isArchivedLoading: boolean = false;
  selectedCard: {
    assignee?: { [key: string]: any };
    can_add_user?: boolean;
    card_id?: string;
    card_name?: string;
    contract_id?: string;
    counterparty_name?: string;
    is_last_version?: boolean;
    is_shared?: boolean;
    num_comment?: number;
    sort_order?: number;
    task_expiration_date?: string;
    task_type?: string;
    version?: string;
    is_archived?: boolean;
    archived_at?: any;
    gmosign_info?: { [key: string]: any };
  } = {};
  dragCardLocation: DragCardLocation = {
    column_id: null,
    card_index: null
  };

  editKanbanColumn: any = null;

  isDisplayedPermissionModal: boolean = false;
  showBackupModal: boolean = false;
  backupModalDescription: string = WINDOW_MESSAGES.BACKUP_DESCRIPTION;
  deleteGMOSignModalDescription: string = WINDOW_MESSAGES.DELETE_GMOSIGN;

  isDisplayDeleteContractModal: boolean = false;
  deleteButtonName: string = '削除';
  deleteButtonSize: number = 96;
  deleteButtonDisabled: boolean = false;
  deleteModalText: string = '';
  allVersionDelete: string = '1';

  isShowingKanbanColumnModal: boolean = false;
  isShowingDeleteKanbanColumnModal: boolean = false;
  isShowingDeleteGMOSignFileModal: boolean = false;

  isShowArchiveDrawer: boolean = false;

  allFinishingItem: any = null;

  folders: any = [];
  folderMoving: boolean = false;
  isOpenConfirmObjectMove: boolean = false;

  @Prop({ default: [] })
  contractData;

  @Prop({ default: [] })
  archivedCardData;

  @Prop({ default: false })
  isCompleteGetAssignees: boolean;

  grabbedData = {
    card_id: null,
    name: null,
    column_id_from: null,
    destination_column_id: null,
    is_finished: null,
    card_order: null,
    version: null
  };

  showNotAllowedTooltip(item) {
    if (!this.permission.can_move_delete_contract_status && item.cards.length) {
      return true;
    }
    return false;
  }

  version(card) {
    const ver = card.version;
    const is_last_version = card.is_last_version;
    if (!ver) {
      return '未作成';
    } else {
      if (is_last_version) {
        return '締結済';
      } else {
        return `V${parseInt(ver)}`;
      }
    }
  }

  @Watch('contractData')
  changedContractData() {
    this.$nextTick(() => {
      this.handleResize();
    });
  }

  @Watch('isShowArchiveDrawer')
  async getArchivedContractData() {
    if (!this.isShowArchiveDrawer) {
      this.searchArchivedInputText = null;
    }
    this.isArchivedLoading = true;
    await ContractManagementModule.getContractBoardData({
      keyword: '',
      is_archived: true
    });
    this.isArchivedLoading = false;
  }

  MENU_ACTION = {
    PERMISSION: '閲覧権限設定',
    DOWNLOAD: 'ダウンロード',
    GMOSIGN: 'GMOサインへアップロード',
    GMOSIGN_DELETE: 'GMOサインファイル削除',
    OUTPUT_CSV: 'CSV出力',
    BACKUP: 'ファイルをバックアップ',
    ARCHIVE: 'アーカイブ',
    DELETE: '削除'
  };

  get permission() {
    return UserInfo.permission;
  }

  get columnData() {
    return this.contractData;
  }

  get finishColumnId() {
    return this.columnData.slice(-1)[0].column_id;
  }

  get neededSignedFileColumn() {
    return ContractManagementModule.neededSignedFileColumn;
  }

  /**
   * カードがあるか判定
   */
  get isNoCard() {
    if (!this.columnData.length) return false;

    const cardData = x => x.cards.length;
    return !this.columnData.some(cardData);
  }

  /**
   * カードを追加できるカラムであり、かつカードが 0 枚であることを判定.
   */
  isCreateColumnEmpty(item) {
    return item.can_create_card && !item.cards.length;
  }

  /**
   * '依頼先' が設定されているかを返す
   */
  get isContactRequestSet() {
    return !!ContractManagementModule.assigneeUserInfo;
  }

  /**
   * ステータスの変更・削除ができるかどうかを返す
   */
  get canChangeKanbanColumns() {
    return item => {
      return !item.can_create_card && !item.is_finish_column;
    };
  }

  /**
   * ステータスを削除する際のモーダルメッセージを返す
   */
  get deleteColumnModalMassage() {
    // このメッセージが表示されることはないはず
    if (!this.editKanbanColumn) {
      return '削除するステータスがありません';
    }
    // これ以上削除するカラムがない
    if (
      this.columnData.filter(c => this.canChangeKanbanColumns(c)).length <= 1
    ) {
      return '自動抽出実行ステータスを削除することはできません';
    }

    let message = `ステータス「${this.editKanbanColumn.column_name}」を削除してもよろしいですか？\nステータス内のカードは１つ前のステータスに移動します。`;
    // 削除するステータスが自動抽出実行ステータスだった場合
    if (
      this.neededSignedFileColumn &&
      this.editKanbanColumn.column_id === this.neededSignedFileColumn.column_id
    ) {
      message += `\n削除後、「締結済み」直前のステータスに自動抽出機能が付与されます。`;
    }
    return message;
  }

  getColumnStyle(item) {
    const col_style = {
      paddingBottom:
        !this.isContactRequestSet &&
        this.permission.can_create_contract &&
        item.can_create_card &&
        item.cards.length
          ? '66px'
          : '16px'
    };
    return col_style;
  }

  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  async menuSelect(
    card: {
      assignee: { [key: string]: any };
      can_add_user: boolean;
      card_id: string;
      card_name: string;
      contract_id: string;
      counterparty_name: string;
      is_last_version: boolean;
      is_shared: boolean;
      latest_contract_version_file_extension: string;
      latest_contract_version_file_name: string;
      latest_contract_version_id: string;
      num_comment: number;
      sort_order: number;
      task_expiration_date: string;
      task_type: string;
      version: string;
      gmosign_info: { [key: string]: any };
    },
    menuName: string
  ): Promise<void> {
    this.selectedCard = card;

    // 閲覧権限設定
    if (menuName === this.MENU_ACTION.PERMISSION) {
      this.isDisplayedPermissionModal = true;
      return;
    }

    // ダウンロード
    if (menuName === this.MENU_ACTION.DOWNLOAD) {
      const contractVersionId = card.latest_contract_version_id;
      const fileExtension = card.latest_contract_version_file_extension;
      const filename = `${card.latest_contract_version_file_name}.${fileExtension}`;
      const result = await FileDownload.downloadSignedFile(
        contractVersionId,
        filename
      );
      if (!result) {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      }
      return;
    }

    // CSV出力
    if (menuName === this.MENU_ACTION.OUTPUT_CSV) {
      const params = {
        contract_id_list: [card.contract_id]
      };
      await DocumentRepository.getCsvs(params)
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `LIRIS_${dayjs().format('YYYYMMDD')}.csv`;
          link.click();
          return;
        })
        .catch(() => {
          notify.error({
            text: 'CSV出力に失敗しました'
          });
        });
    }

    // バックアップ
    if (menuName === this.MENU_ACTION.BACKUP) {
      this.showBackupModal = true;
    }

    // アーカイブ
    if (menuName === this.MENU_ACTION.ARCHIVE) {
      this.archiveContract();
    }

    // 削除
    if (menuName === this.MENU_ACTION.DELETE) {
      this.deleteButtonName = '削除';
      this.deleteModalText =
        'すべてのバージョンを含むこのファイルを削除しますか？';
      this.allVersionDelete = '1';
      this.isDisplayDeleteContractModal = true;
    }

    // GMOサインへアップロード
    if (menuName === this.MENU_ACTION.GMOSIGN) {
      this.isLoading = true;
      GMOSignInfoModule.SET_DOCUMENT_NAME(card.card_name);
      GMOSignInfoModule.SET_OWN_ORGANIZE_NM(CorporateInfoModule.corporate_name);
      await DocumentsModule.getContractDetail(card.contract_id);
      this.isLoading = false;
      this.$router.push({
        name: 'gmosign_form_contract',
        query: {
          id: card.contract_id
        }
      });
    }

    // GMOサインファイル削除
    if (menuName === this.MENU_ACTION.GMOSIGN_DELETE) {
      this.isShowingDeleteGMOSignFileModal = true;
    }
  }

  /**
   * 閲覧権限設定の値を親コンポーネントにわたす
   */
  @Emit()
  selectViewPermissionData(data: {
    selected_users: any[];
    selected_departments: any[];
    contract_id: string;
  }): {
    selected_users: any[];
    selected_departments: any[];
    contract_id: string;
  } {
    this.isDisplayedPermissionModal = false;
    data.contract_id = this.selectedCard.contract_id;
    return data;
  }

  clickBackup(): void {
    const result = FileBackup.downloadBackupFile(this.selectedCard.contract_id);

    this.showBackupModal = false;

    if (!result)
      notify.error({
        text: NOTIFY_TEXT.ERROR.BACKUP_FILE
      });
  }

  // 契約書をアーカイブ
  @Emit()
  async archiveContract() {
    this.isLoading = true;
    this.isArchivedLoading = true;
    let isSuccessed = true;
    await ContractInfoRepository.archiveCard(this.selectedCard.card_id, true)
      .then(() => {})
      .catch(e => {
        isSuccessed = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.ARCHIVE_CONTRACT
        });
        return;
      });
    await ContractManagementModule.getContractBoardData();
    await ContractManagementModule.getContractBoardData({
      keyword: this.searchArchivedInputText,
      is_archived: true
    });
    this.isLoading = false;
    this.isArchivedLoading = false;
    if (isSuccessed) {
      notify.success({
        text: `${this.selectedCard.card_name} ` + NOTIFY_TEXT.SUCCESS.ARCHIVED
      });
    }
  }

  // 契約書をアーカイブから元に戻す
  @Emit()
  async unArchiveContract(card) {
    this.selectedCard = card;
    this.isLoading = true;
    this.isArchivedLoading = true;
    let isSuccessed = true;
    await ContractInfoRepository.archiveCard(this.selectedCard.card_id, false)
      .then(() => {})
      .catch(e => {
        isSuccessed = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.ARCHIVE_CONTRACT
        });
        return;
      });
    await ContractManagementModule.getContractBoardData();
    await ContractManagementModule.getContractBoardData({
      keyword: this.searchArchivedInputText,
      is_archived: true
    });
    this.isLoading = false;
    this.isArchivedLoading = false;
    if (isSuccessed) {
      notify.success({
        text: `${this.selectedCard.card_name} ` + NOTIFY_TEXT.SUCCESS.UNARCHIVED
      });
    }
  }

  // 削除後に再取得するため親に渡す
  @Emit()
  async deleteContract() {
    this.deleteButtonDisabled = true;
    this.deleteButtonName = '削除中';

    await DocumentRepository.deleteContract(this.selectedCard.contract_id)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE
          });
          return;
        }
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.DELETE
        });
        return;
      });

    this.isDisplayDeleteContractModal = false;
    this.deleteButtonDisabled = false;
    this.deleteButtonName = '削除';
    const message = `${this.selectedCard.card_name}を削除しました`;
    notify.success({
      text: message
    });
    return;
  }

  handleResize() {
    const listEl = this.$el.getElementsByClassName('column_card_list')[0];
    if (!listEl) {
      return;
    }
    const top = listEl.getBoundingClientRect().top;
    this.cardListHeight = window.innerHeight - top - 32; // padding-bottom 分
  }

  computeCardIndexForStart(cards, id): number | null {
    const cardIds = cards.map(card => {
      return card.card_id;
    });
    return cardIds.indexOf(id) > -1 ? cardIds.indexOf(id) + 1 : null;
  }

  // sstartMoveで保持したidをEndでは利用する
  // Elementのidにcard_idを振っているのを利用してカード順序を算出している
  computeCardIndexForEnd(cards: HTMLCollection): number | null {
    const arr: Element[] = Array.from(cards);
    const cardIds: string[] = arr.map((cardDOM: Element) => {
      return cardDOM.id;
    });
    const id: string = this.grabbedData.card_id;
    return cardIds.indexOf(id) > -1 ? cardIds.indexOf(id) + 1 : null;
  }

  /**
   * ドラッグ開始
   */
  startMove(event, index) {
    this.grabbedData.card_id = event.item.id;
    this.grabbedData.column_id_from = event.from.parentElement.id;
    this.grabbedData.name = event.item.children[0].innerText;
    this.grabbedData.card_order = index;
    this.grabbedData.version = event.item.getAttribute('data-version');
    this.dragCardLocation.column_id = event.from.parentElement.id;
    this.dragCardLocation.card_index = index;
  }

  /**
   * カラムIDからカラム情報を返す
   */
  getColumn(columnId) {
    for (const i in this.contractData) {
      if (this.contractData[i].column_id === columnId) {
        return this.contractData[i];
      }
    }
    return null;
  }

  /**
   * ドラッグしてドロップしたときの処理
   */
  async endMove(event, index) {
    this.grabbedData.destination_column_id = event.to.parentElement.id;
    this.grabbedData.is_finished = false;
    this.grabbedData.card_order = index;

    const colData = this.getColumn(this.grabbedData.destination_column_id);
    const cards = colData.cards;
    for (const i in cards) {
      const card = cards[i];
      if (this.grabbedData.card_id === card.card_id) {
        this.selectedCard = card;
        break;
      }
    }
    if (this.selectedCard && this.selectedCard.gmosign_info.xid) {
      this.$emit('clearKeyword', null);
      notify.error({
        text:
          'GMOサインで締結中のため移動できません。全ての署名が完了していれば、カードを開いた際に、自動的に「締結済み」へ移動します。'
      });
      this.selectedCard = {};
      return;
    }

    if (
      this.grabbedData.column_id_from !== this.grabbedData.destination_column_id
    ) {
      if (this.grabbedData.destination_column_id === this.finishColumnId) {
        if (!this.permission.can_move_delete_contract_status) {
          this.$emit('clearKeyword', null);
          notify.error({
            text: NOTIFY_TEXT.ERROR.NOT_ALLOWED.MOVE_CARD
          });
          return;
        }
        const column = ContractManagementModule.getColumnById(
          this.grabbedData.column_id_from
        );
        if (
          column &&
          column.column_order < this.neededSignedFileColumn.column_order
        ) {
          this.$emit('clearKeyword', null);
          notify.error({
            text: `「締結済み」ステータスへのカードの移動は、「${this.neededSignedFileColumn.column_name}」ステータス以降のカードのみ可能です`
          });
          return;
        }
        if (this.permission.can_move_delete_contract_status) {
          if (this.grabbedData.version === null) {
            this.$emit('clearKeyword', null);
            notify.error({
              text: `「未作成」の状態のカードは「締結済み」ステータスへ移動できません。`
            });
            return;
          }
          this.isShowingSignedUploadModal = true;
          return;
        }
      }
    }
    const dragCardLocation: DragCardLocation = {
      column_id: event.to.parentElement.id,
      card_index: index
    };

    // cardの位置が変わったらリクエストする
    if (this.dragCardLocation.column_id != dragCardLocation.column_id) {
      // カラムが変わった時のみスナックバーなどを表示する
      this.dragCardLocation = dragCardLocation;
      await this.$emit('updateCardStatus', this.grabbedData);
    } else if (
      this.dragCardLocation.card_index != dragCardLocation.card_index
    ) {
      // columnは変わらずindexが変わった場合はスナックバーなど表示せずリクエストする
      this.dragCardLocation = dragCardLocation;
      await this.$emit('updateCardIndex', this.grabbedData);
    }
    this.clearGrabbedItem();
  }

  clearGrabbedItem() {
    Object.keys(this.grabbedData).forEach(key => {
      this.grabbedData[key] = null;
    });
  }

  handleFilterCardsByText() {
    this.$emit('filterCardsByText', this.searchInputText);
  }

  async handleFilterArchivedCardsByText() {
    this.isArchivedLoading = true;
    await ContractManagementModule.getContractBoardData({
      keyword: this.searchArchivedInputText,
      is_archived: true
    });
    this.isArchivedLoading = false;
  }

  async onFinishCard(card) {
    this.selectedCard = card;
    this.isLoading = true;
    await DocumentsModule.getContractDetail(this.selectedCard.contract_id);
    const contract = DocumentsModule.contract_detail.contract_info;
    DocumentsModule.SET_SELECTED_FILES_OF_TABLE([contract]);
    this.setMoveDialog();
    this.isLoading = false;
  }

  openChangeKanbanColumnModal(status) {
    this.editKanbanColumn = status;
    this.isShowingKanbanColumnModal = true;
  }

  openDeleteKanbanColumnModal(status) {
    this.editKanbanColumn = status;
    this.isShowingDeleteKanbanColumnModal = true;
  }

  /**
   * カンバンステータスを追加/編集
   */
  async changeKanbanColumn(payload) {
    this.isLoading = true;
    const isUpdate = !!payload.column_id;
    const method = isUpdate ? 'updateKanbanColumn' : 'createKanbanColumn';
    await ContractManagementModule[method](payload)
      .then(() => {
        const message = isUpdate ? '編集' : '追加';
        notify.success({
          text: `ステータスを${message}しました`
        });
      })
      .catch(() => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
    this.closeKanbanColumn();

    await ContractManagementModule.getContractBoardData();
    this.isLoading = false;
  }

  async deleteKanbanColumn() {
    if (!this.editKanbanColumn || !this.editKanbanColumn.column_id) {
      return;
    }
    this.isLoading = true;
    await ContractManagementModule.deleteKanbanColumn(
      this.editKanbanColumn.column_id
    )
      .then(() => {
        notify.success({
          text: 'ステータスを削除しました'
        });
      })
      .catch(() => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.DELETE
        });
        return;
      });
    this.closeKanbanColumn();

    await ContractManagementModule.getContractBoardData();
    this.isLoading = false;
  }

  /**
   * カンバンステータスを追加/編集/削除を閉じる
   */
  closeKanbanColumn() {
    this.isShowingKanbanColumnModal = false;
    this.isShowingDeleteKanbanColumnModal = false;
    this.editKanbanColumn = null;
  }

  /**
   * 各カードの完了ボタン
   */
  finishedContract() {
    this.$emit('updateCardStatus', {
      card_id: this.selectedCard.card_id,
      name: this.selectedCard.card_name,
      destination_column_id: this.finishColumnId,
      is_finished: true
    });
    this.isOpenCardFinishedWindow = false;
  }

  /**
   * 'すべて完了にする' メニューをクリックした際にカラムデータを保存
   */
  openAllardFinitedWindow(item) {
    this.allFinishingItem = item;
    this.isOpenAllardFinitedWindow = true;
  }
  closeAllardFinitedWindow() {
    this.allFinishingItem = null;
    this.isOpenAllardFinitedWindow = false;
  }

  /**
   * 'すべて完了にする' ボタンをクリックした際の処理
   * coliumn_id と destination_column_id が必要になるが、いずれも同じカラムになるはずなので同じ値を渡す。
   */
  archiveAllContract() {
    this.$emit('updateBulkCardStatus', {
      cards: this.allFinishingItem.cards,
      destination_column_id: this.finishColumnId,
      is_finished: true
    });

    this.closeAllardFinitedWindow();
  }

  /**
   * 契約書の詳細ページに遷移する
   */
  goToDetailPage(contract_id) {
    this.$router.push(`document-detail/${contract_id}`);
  }

  /**
   * 作成・確認依頼カラムの新規作成ボタンをクリックしたときにモーダルを開く
   */
  toggleCreateCardModal() {
    this.$emit('toggleCreateCardModal');
  }

  /**
   * 「依頼先を設定」ボタンをクリックしたときにモーダルを開く
   */
  toggleContractRequestModal() {
    this.$emit('toggleContractRequestSettingModal');
  }

  formatDate(date) {
    const dateDiff = this.getDateDiff(date);
    if (dateDiff === -1) {
      return '明日';
    }
    if (dateDiff === 0) {
      return '今日';
    }
    if (dateDiff === 1) {
      return '昨日';
    }
    return dayjs(date).format('YYYY/MM/DD');
  }

  getClassDate(date) {
    const dateDiff = this.getDateDiff(date);
    if (dateDiff === -1) {
      return 'tomorrow';
    }
    if (dateDiff === 0) {
      return 'today';
    }
    if (dateDiff >= 1) {
      return 'before';
    }
    return '';
  }

  getDateDiff(date): number {
    const itemDate = dayjs(date);
    const today = dayjs();

    // dayjs の日数の diff は 24 時間単位で測るため、日付単位に戻す必要がある
    return dayjs(today.format('YYYYMMDD')).diff(
      dayjs(itemDate.format('YYYYMMDD')),
      'day'
    );
  }

  @Watch('searchInputText')
  isClearKeyword() {
    if (!this.searchInputText) {
      this.$emit('clearKeyword', null);
    }
  }

  async lastVersionUpload(params) {
    this.grabbedData.card_id;
    const cardData = [];
    this.contractData.forEach(x => {
      x.cards.forEach(y => {
        cardData.push(y);
      });
    });

    const targetContractId = cardData.find(
      x => x.card_id === this.grabbedData.card_id
    ).contract_id;

    this.isShowingSignedUploadModal = false;

    this.isLoading = true;

    const formData = new FormData();
    formData.append('contract', params.file);

    const apiParams = {
      contract_id: targetContractId,
      is_last_version: true,
      is_stored: params.is_stored,
      formData
    };
    await DocumentRepository.uploadNewVersion(apiParams)
      .then(async res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          this.$emit('clearKeyword', null);
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
          await this.$emit('updateCardStatus', this.grabbedData);
        }
      })
      .catch(e => {
        this.$emit('clearKeyword', null);
        notify.error({
          text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
        });
      })
      .finally(async () => {
        ContractManagementModule.getContractBoardData();
        this.isLoading = false;
      });

    this.clearGrabbedItem();
    this.isLoading = false;
  }

  async cancelVersionUpload() {
    this.isLoading = true;
    await ContractManagementModule.getContractBoardData();
    this.isLoading = false;
    this.isShowingSignedUploadModal = false;
  }

  /**
   * 検索キーワードを削除する
   */
  onClearKeyword() {
    this.searchInputText = '';
    this.handleFilterCardsByText();
  }

  /**
   * アーカイブ済み検索キーワードを削除する
   */
  onClearArchivedKeyword() {
    this.searchArchivedInputText = '';
    this.handleFilterArchivedCardsByText();
  }

  omitText(text, n = 10) {
    if (text.length <= n) {
      return text;
    }
    const omit = text.slice(0, n);
    return omit + '...';
  }

  closeDeleteGMOSignFileModal() {
    this.isShowingDeleteGMOSignFileModal = false;
    this.selectedCard = {};
  }

  // GMOサインファイル削除
  async deleteGMOSignFile() {
    const contract_id = this.selectedCard.contract_id;
    await GMOSignInfoModule.deleteDocument(contract_id)
      .then(() => {
        notify.success({ text: NOTIFY_TEXT.SUCCESS.GMOSIGN.DELETEDOCUMENT });
      })
      .catch(() => {
        notify.error({ text: NOTIFY_TEXT.ERROR.GMOSIGN.DELETEDOCUMENT });
      });
    this.$emit('deleteGMOSignFile');
    this.closeDeleteGMOSignFileModal();
  }

  // CSV出力
  async outputCSVs() {
    const contract_id_list = [];
    for (const i in this.contractData) {
      const col = this.contractData[i];
      for (const j in col.cards) {
        contract_id_list.push(col.cards[j].contract_id);
      }
    }
    const params = {
      directory_id_list: [],
      contract_id_list: contract_id_list,
      is_signed: false,
    };

    await DocumentRepository.getCsvs(params)
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `LIRIS_${dayjs().format('YYYYMMDD')}.csv`;
        link.click();
        return;
      })
      .catch(() => {
        notify.error({
          text: 'CSV出力に失敗しました'
        });
      });
  }

  // 完了後のファイル移動
  async get_folders(path: string) {
    await DocumentRepository.getDirectoryList(path)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        this.folders = res.data;
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  get selectedFolderPathList() {
    const selected_folder_path_list = DocumentsModule.selected_folders_of_table.map(
      function(data) {
        return data.directory_path;
      }
    );
    return selected_folder_path_list;
  }

  async checkViewPermissionWhenMoveObject() {
    this.isOpenConfirmObjectMove = true;
  }

  get currentDirectory() {
    return DocumentsModule.current_directory;
  }

  get moveSelectedObjectsWindow(): boolean {
    if (
      HomeComponentInfoModule.move_selected_objects_window_for_contract_management
    ) {
      this.get_folders(this.currentDirectory);
    } else {
      this.folders = [];
    }
    return HomeComponentInfoModule.move_selected_objects_window_for_contract_management;
  }

  get windowMessageConfirmMove() {
    return WINDOW_MESSAGES.CONFIRM_MOVE_FOR_CONTRACT_MANAGEMENT;
  }

  async moveObject(): Promise<void> {
    this.isOpenConfirmObjectMove = false;
    this.folderMoving = true;
    await MoveObject.moveFileOrFolder(false);
    this.finishedContract();
    this.folderMoving = false;
    this.cancelMove();
  }

  selectedPath(path) {
    HomeComponentInfoModule.SET_MOVE_TO_PATH(path);
  }

  changePath(path) {
    this.folders = [];
    this.get_folders(path);
  }

  cancelMove() {
    HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW_FOR_CONTRACT_MANAGEMENT(
      false
    );
  }

  async setMoveDialog() {
    HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW_FOR_CONTRACT_MANAGEMENT(
      true
    );
  }
}
