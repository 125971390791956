







































import { Component, Vue } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextFormWithTitle from '@/components/molecules/text_form_with_title/TextFormWithTitle.vue';
import RouterLinkText from '@/components/atoms/links/RouterLinkText.vue';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import UserInfo from '@/store/UserInfo';
import CorporateInfoModule from '@/store/CorporateInfo';
import KEY_CODE from '@/consts/KeyCode';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';
const axios = require('axios').default;

@Component({
  components: {
    AppButton,
    TextFormWithTitle,
    RouterLinkText,
    FormTitle
  }
})
export default class CreateAccount extends Vue {
  email_error_message: string = '';
  corporate_name_error_message: string = '';
  corporate_tel_error_message: string = '';

  reset_messages() {
    this.email_error_message = '';
    this.corporate_name_error_message = '';
    this.corporate_tel_error_message = '';
  }

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.send_email();
    }
  }

  send_email() {
    this.reset_messages();

    const email = UserInfo.email;
    const corporate_name = CorporateInfoModule.corporate_name;
    const corporate_tel = CorporateInfoModule.corporate_tel;

    let error: boolean = false;

    if (!email) {
      this.email_error_message = 'メールアドレスは必須項目です。';
      error = true;
    }
    if (!corporate_name) {
      this.corporate_name_error_message = '会社名は必須項目です。';
      error = true;
    }
    if (!corporate_tel) {
      this.corporate_tel_error_message = '会社電話番号は必須項目です。';
      error = true;
    }
    if (!this.email_error_message && !UserInfo.is_email) {
      this.email_error_message = 'メールアドレスが正しくありません。';
      error = true;
    }
    if (
      !this.corporate_tel_error_message &&
      !CorporateInfoModule.is_corporate_tel
    ) {
      this.corporate_tel_error_message =
        '0から始まる半角数字で11桁以内で入力してください。';
      error = true;
    }
    if (!error) {
      const url = '/accounts/new';
      const request_data = {
        email: email,
        corporate_name: corporate_name,
        phone_number: corporate_tel,
        liris_admin: false
      };

      axios
        .post(url, request_data)
        .then(response => {
          this.$router.push({ name: 'new_account_created' });
        })
        .catch(err => {
          if (err.response) {
            const res = err.response.data;
            notify.error({
              text: res['description']
            });
          } else {
            notify.error({
              text: NOTIFY_TEXT.ERROR.GENERAL
            });
          }
        });
    }
  }
}
