import Vue from 'vue';
import Vuex from 'vuex';
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators';
import store from '@/store';
import Regex from '../consts';
const axios = require('axios').default;
import dayjs from 'dayjs';
import HTTP_STATUS from '@/consts/HttpStatus';
import SERVICE_PLANS from '@/consts/ServicePlans';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const SettingRepository = RepositoryFactory.get('settings');

Vue.use(Vuex);

type ServicePlansType = {
  service_plan_id: string;
  service_plan_name: string;
  service_plan_expiration: string;
  service_plan_start: string;
  max_upload_file_size: number;
  uploadable_file_size: number;
  max_upload_file_num_at_once: number;
};

@Module({
  dynamic: true,
  store: store,
  name: 'corporate_info',
  namespaced: true
})
export class CorporateInfo extends VuexModule {
  corporate_name: string = '';
  corporation_id: string = '';
  corporate_name_furigana: string = '';
  corporate_tel: string = '';
  corporate_fax: string = null;
  zipcode: string = '';
  street_address: string = '';
  business_category: any = {
    business_category_id: '',
    business_category_name: ''
  };
  employee_count: any = {
    employee_count_id: '',
    employee_count_name: ''
  };
  upload_size_alert_threshold: number = 0.9;

  is_corporate_tel: boolean = false;
  service_plans: ServicePlansType = {
    service_plan_id: null,
    service_plan_name: null,
    service_plan_expiration: null,
    service_plan_start: null,
    max_upload_file_size: null,
    uploadable_file_size: null,
    max_upload_file_num_at_once: 10
  };
  control_number_rule: string = '';

  is_cooperated_gmosign: boolean = false;
  is_cooperated_buntan: boolean = false;
  is_cooperated_slack: boolean = false;
  secret_key: string = null;

  get uploadFileLimit(): number {
    return this.service_plans.max_upload_file_num_at_once;
  }

  get total_file_size(): number {
    return (
      this.service_plans.max_upload_file_size -
      this.service_plans.uploadable_file_size
    );
  }

  get usage_rate(): number {
    return this.total_file_size / this.service_plans.max_upload_file_size;
  }

  get upload_size_alert(): boolean {
    return this.usage_rate >= this.upload_size_alert_threshold;
  }

  get isPastServicePlanPeriod() {
    if (this.service_plans) {
      const now = dayjs();
      const diff = dayjs(this.service_plans.service_plan_expiration).diff(
        now,
        'day'
      );
      if (diff < 0) {
        return true;
      }
    }
    return false;
  }

  get isFreeTrialPlan(): boolean {
    return (
      this.service_plans.service_plan_id ===
      SERVICE_PLANS.FREE_TRIAL.service_plan_id
    );
  }

  get isSmallPlan(): boolean {
    return (
      this.service_plans.service_plan_id === SERVICE_PLANS.SMALL.service_plan_id
    );
  }

  get isStandardPlan(): boolean {
    return (
      this.service_plans.service_plan_id ===
      SERVICE_PLANS.STANDARD.service_plan_id
    );
  }

  get isEnterprisePlan(): boolean {
    return (
      this.service_plans.service_plan_id ===
      SERVICE_PLANS.ENTERPRISE.service_plan_id
    );
  }

  @Mutation
  SET_BUSINESS_CATEGORY(business_category) {
    this.business_category = business_category;
  }

  @Mutation
  SET_EMPLOYEE_COUNT(employee_count) {
    this.employee_count = employee_count;
  }

  @Mutation
  SET_CORPORATION_ID(id: string) {
    this.corporation_id = id;
  }

  @Mutation
  SET_SERVICE_PLANS(plans: ServicePlansType) {
    this.service_plans = plans;
  }

  @Mutation
  SET_IS_COOPERATED_GMOSIGN(bool: boolean) {
    this.is_cooperated_gmosign = bool;
  }

  @Mutation
  SET_IS_COOPERATED_BUNTAN(bool: boolean) {
    this.is_cooperated_buntan = bool;
  }

  @Mutation
  SET_IS_COOPERATED_SLACK(bool: boolean) {
    this.is_cooperated_slack = bool;
  }

  @Mutation
  SET_SECRET_KEY(str: string) {
    this.secret_key = str;
  }

  @Action({ rawError: true })
  async get_service_plan() {
    return await SettingRepository.getServicePlan()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_SERVICE_PLANS(res.data);
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async get_corporate_info() {
    return SettingRepository.getCorporateInfo()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        const data = res.data;
        this.SET_CORPORATE_NAME(data['corporation_name']);
        this.SET_CORPORATION_ID(data['corporation_id']);
        this.SET_CORPORATE_NAME_FURIGANA(data['furigana']);
        this.SET_ZIPCODE(data['postal_code']);
        this.SET_STREET_ADDRESS(data['address']);
        this.SET_CORPORATE_TEL(data['phone_number']);
        this.SET_CORPORATE_FAX(data['fax']);
        this.SET_BUSINESS_CATEGORY(data['business_category']);
        this.SET_EMPLOYEE_COUNT(data['employee_count']);
        this.SET_CONTROL_NUMBER_RULE(data['control_number_rule']);
        this.SET_IS_COOPERATED_GMOSIGN(data['is_cooperated_gmosign']);
        this.SET_IS_COOPERATED_BUNTAN(data['is_cooperated_buntan']);
        this.SET_IS_COOPERATED_SLACK(data['is_cooperated_slack']);
        this.SET_SECRET_KEY(data['secret_key']);
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Mutation
  SET_CORPORATE_FAX(corporate_fax: string) {
    if (corporate_fax) {
      this.corporate_fax = corporate_fax.replace(/[０-９]/g, s =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );
    } else {
      this.corporate_fax = '';
    }
  }

  @Mutation
  SET_STREET_ADDRESS(street_address: string) {
    this.street_address = street_address;
  }

  @Mutation
  SET_ZIPCODE(zipcode: string) {
    this.zipcode = zipcode;
  }

  @Mutation
  SET_CORPORATE_NAME_FURIGANA(corporate_name_furigana: string) {
    this.corporate_name_furigana = corporate_name_furigana;
  }

  @Mutation
  SET_CORPORATE_NAME(corporate_name: string) {
    this.corporate_name = corporate_name;
  }

  @Mutation
  SET_CORPORATE_TEL(corporate_tel: string) {
    if (corporate_tel) {
      this.corporate_tel = corporate_tel.replace(/[０-９]/g, s =>
        String.fromCharCode(s.charCodeAt(0) - 0xfee0)
      );
      if (Regex.tel_pattern.test(this.corporate_tel)) {
        this.is_corporate_tel = true;
      } else {
        this.is_corporate_tel = false;
      }
    } else {
      this.corporate_tel = '';
      this.is_corporate_tel = false;
    }
  }

  @Mutation
  SET_CONTROL_NUMBER_RULE(control_number_rule: string) {
    this.control_number_rule = control_number_rule;
  }
}

const CorporateInfoModule = getModule(CorporateInfo);

export default CorporateInfoModule;
