


































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import SettingsInfoModule, { Member } from '@/store/SettingsInfo';
import UserInfoModule from '@/store/UserInfo';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import TextFormWithTitle from '@/components/molecules/text_form_with_title/TextFormWithTitle.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import DepartmentSimpleSelectBox from '@/components/atoms/selectbox/DepartmentSimpleSelectBox.vue';
import SingleInputFormWindow from '@/components/molecules/modal_windows/SingleInputFormWindow.vue';

import UserInfo from '@/store/UserInfo';

import AUTHORITIES from '@/consts/Authorities.js';
import notify from '@/functions/notify';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import { validateEmail } from '@/functions/Validation';

const UserInfoRepository = RepositoryFactory.get('userInfos');
const axios = require('axios').default;

/**
 * アカウント情報更新画面用Component
 * 入力部品をまとめて表示・更新を行う
 */
@Component({
  components: {
    TextForm,
    TextFormWithTitle,
    DepartmentSimpleSelectBox,
    SelectBox,
    AppButton,
    SingleInputFormWindow
  }
})
export default class AccountsInput extends Vue {
  updated_user_name: string = '';
  update_department_name: string = '';
  updated_authority: any = {};
  user_email: string = '';
  update_password: string = '';

  user_name_error_message: string = '';
  email_error_message: string = '';

  can_change_name: boolean = false;
  can_change_department: boolean = false;
  can_change_authority: boolean = false;

  select_department: any = {};

  canChangeEmail: boolean = false;
  isOpenVerifyCodeModal: boolean = false;
  verifyCodeErrorMessage: string = '';

  async mounted() {
    await UserInfo.get_user_info();
    if (this.is_over_owner) {
      await SettingsInfoModule.get_authorities().catch(e =>
        notify.error({ text: '権限一覧を取得できませんでした。' })
      );
      this.updated_authority = this.getAuthorityFromName(this.authority);
    }
    if (this.authority === AUTHORITIES.OWNER) {
      await SettingsInfoModule.get_members();
    }
    this.updated_user_name = this.user_name;
    this.user_email = UserInfoModule.email;
  }

  get department() {
    return UserInfoModule.department;
  }

  get departments() {
    return SettingsInfoModule.departments;
  }

  get refs(): any {
    return this.$refs;
  }

  public get user_name(): string {
    return UserInfoModule.user_name;
  }

  public set user_name(value) {
    this.updated_user_name = value;
  }

  public get current_user_email(): string {
    return UserInfoModule.email;
  }

  public get user_department_name(): string {
    return UserInfoModule.department_name;
  }

  public set user_department_name(value) {
    this.update_department_name = value;
  }

  public get password(): string {
    return this.can_change_password ? '' : 'XXXXXXXX';
  }

  public set password(value) {
    this.update_password = value;
  }

  public get can_change_password(): boolean {
    return SettingsInfoModule.can_change_password;
  }

  public get authority() {
    return UserInfoModule.authority;
  }

  public set authority(value) {
    this.updated_authority = value;
  }

  get authorities() {
    if (UserInfo.authority !== AUTHORITIES.OWNER) {
      return SettingsInfoModule.authorities.filter(
        x => x.permission_default_name !== AUTHORITIES.OWNER
      );
    } else {
      return SettingsInfoModule.authorities;
    }
  }

  get members(): Member[] {
    return SettingsInfoModule.members;
  }

  @Watch('department')
  update_department(new_value) {
    this.select_department = new_value;
  }

  get is_over_owner() {
    if (
      this.authority == AUTHORITIES.OWNER ||
      this.authority == AUTHORITIES.ADMINISTRATOR
    ) {
      return true;
    }
    return false;
  }

  getAuthorityFromName(permission_name) {
    return SettingsInfoModule.authorities.find(
      x => x.permission_default_name == permission_name
    );
  }

  /**
   * ユーザー名変更時の動作
   */
  clickChangeNameButton(): void {
    if (this.can_change_name) {
      if (!this.updated_user_name) {
        this.user_name_error_message = '氏名を入力してください';
        return;
      }
      if (!this.updated_user_name.match(/^\S+( |　)+\S+/)) {
        this.user_name_error_message = '姓と名の間にはスペースを空けてください';
        return;
      }
      UserInfo.update_user_account_info({
        user_name: this.updated_user_name
      })
        .then(response => {
          this.user_name_error_message = '';
          this.can_change_name = !this.can_change_name;
          UserInfo.SET_USER_NAME(this.updated_user_name);
        })
        .catch(error => {
          notify.error({
            text: '氏名の更新に失敗しました。'
          });
        });
      return;
    }

    this.can_change_name = !this.can_change_name;
    this.refs.input_change_name.focus();
  }

  /**
   * 所属部署変更時の動作
   */
  clickChangeDepartmentButton() {
    if (!this.is_over_owner) {
      return;
    }

    if (this.can_change_department) {
      UserInfo.update_user_account_info({
        department_id: this.select_department.department_id
      })
        .then(response => {
          this.can_change_department = !this.can_change_department;
        })
        .catch(error => {
          notify.error({
            text: '所属部署の更新に失敗しました。'
          });
        });
      return;
    }

    this.can_change_department = !this.can_change_department;
  }

  /**
   * 権限変更時の処理
   */
  async clickChangeAuthorityButton() {
    this.can_change_authority = !this.can_change_authority;
    if (this.can_change_authority) {
      return;
    }

    if (!UserInfo.permission.can_change_permission) {
      notify.error({
        text: '変更権限がありません'
      });
      return;
    }

    const params = [
      {
        user_id: UserInfo.user_id,
        permission_default_id: this.updated_authority.permission_default_id
      }
    ];

    // 自身がオーナーでかつオーナー以外に権限を変更しようとした場合、オーナーが自信以外にいなければメッセージを出してスルー
    if (
      this.authority === AUTHORITIES.OWNER &&
      this.updated_authority.permission_default_id !==
        this.getAuthorityFromName(AUTHORITIES.OWNER).permission_default_id
    ) {
      const isExistsOwner = this.members.some((x: Member) =>
        x.user_id !== UserInfo.user_id &&
        typeof x.permission_default_name === 'string'
          ? x.permission_default_name === AUTHORITIES.OWNER
          : x.permission_default_name.permission_default_name ===
            AUTHORITIES.OWNER
      );

      if (!isExistsOwner) {
        notify.error({
          text: 'オーナーを0人にすることはできません'
        });
        return;
      }
    }

    const url = '/user-management/users';
    await axios
      .put(url, params)
      .then(res => {
        if (res.status === HTTP_STATUS.FORBIDDEN) {
          notify.error({
            text: '変更権限がありません'
          });
          return;
        }

        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: 'ユーザーの権限を変更できませんでした'
          });
          return;
        }

        notify.success({
          text: `ユーザーの権限を変更しました`
        });

        UserInfo.get_user_info();
      })
      .catch(error => {
        notify.error({
          text: 'ユーザーの権限を変更できませんでした'
        });
      });
  }

  /**
   * アドレス変更用認証コード送信処理
   */
  async clickChangeEmailButton() {
    this.email_error_message = '';

    if (!this.canChangeEmail) {
      this.canChangeEmail = true;
      return;
    }

    if (!this.user_email) {
      this.email_error_message = '新しいメールアドレスを入力してください';
      return;
    }

    this.email_error_message = validateEmail(this.user_email);
    if (this.email_error_message) {
      return;
    }

    // 認証コードメール送信
    UserInfoRepository.sendChangeEmailVerifyEmail(this.user_email)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: '認証コードの送信に失敗しました'
          });
          return;
        }

        this.isOpenVerifyCodeModal = true;
        notify.success({
          text: '新しいメールアドレス宛てに認証コードを送信しました'
        });
      })
      .catch(err => {
        if (err.response.status === HTTP_STATUS.CONFLICT) {
          this.email_error_message =
            'このメールアドレスはすでに登録されています';
          return;
        }

        notify.error({
          text: '認証コードの送信に失敗しました'
        });
        return;
      });
  }

  /**
   * メールアドレス送信処理
   */
  updateEmail(verificationCode) {
    UserInfoRepository.updateEmail(verificationCode)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        UserInfo.SET_EMAIL(res.data);
        notify.success({
          text: 'メールアドレスを変更しました'
        });
        this.isOpenVerifyCodeModal = false;
        this.canChangeEmail = false;
      })
      .catch(err => {
        if (err.response.status === HTTP_STATUS.BAD_REQUEST) {
          this.verifyCodeErrorMessage = '認証コードの有効期限がすぎています';
          return;
        }
        if (err.response.status === HTTP_STATUS.CONFLICT) {
          this.verifyCodeErrorMessage =
            'このメールアドレスはすでに登録されています';
          return;
        }
        this.verifyCodeErrorMessage = '認証コードが正しくありません';
        return;
      });
  }

  closeVerifyCodeModal() {
    this.isOpenVerifyCodeModal = false;
    this.verifyCodeErrorMessage = '';
  }

  /**
   * パスワード変更時の動作
   */
  clickChangePasswordButton() {
    if (this.can_change_password) {
      SettingsInfoModule.SET_CAN_CHANGE_PASSWORD(false);
      return;
    }

    SettingsInfoModule.SET_CAN_OPEN_PASSWORD_CONFIRM_WINDOW(true);
  }

  beforeDestroy() {
    SettingsInfoModule.SET_CAN_CHANGE_EMAIL(false);
    SettingsInfoModule.SET_CAN_CHANGE_PASSWORD(false);
  }

  cancelChangeName() {
    UserInfo.SET_USER_NAME(this.user_name);
    this.updated_user_name = this.user_name;
    this.can_change_name = false;
    this.user_name_error_message = '';
  }

  cancelDepatment() {
    this.select_department = this.department;
    this.can_change_department = false;
  }

  cancelAuthority() {
    this.updated_authority = this.getAuthorityFromName(this.authority);
    this.can_change_authority = false;
  }

  cancelChangeEmail() {
    this.user_email = this.current_user_email;
    this.canChangeEmail = false;
    this.email_error_message = '';
  }
}
