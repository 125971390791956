




















































import { Component, Vue, Prop } from 'vue-property-decorator';
import vLoading from 'vue-loading-overlay';

import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import DocumentPreview from '@/components/organisms/GMOSign/DocumentPreview.vue';

import GMOSignInfoModule from '@/store/GMOSign';

@Component({
  components: {
    vLoading,
    SelectBox,
    AppButton,
    DocumentPreview
  }
})
export default class GMOSignFormContract extends Vue {
  contractId = null;
  isInitLoading: boolean = false;
  validateCount: number = 0;

  @Prop({ default: '' })
  pdfLink!: string;

  async created() {
    this.contractId = this.$route.query.id;
    await GMOSignInfoModule.getGMOSignMembers();
  }

  get signForm() {
    return GMOSignInfoModule.signForm;
  }

  get assignees() {
    return GMOSignInfoModule.current_gmosign_users;
  }

  get document_name() {
    return GMOSignInfoModule.signForm.document_name;
  }
  set document_name(val) {
    GMOSignInfoModule.SET_DOCUMENT_NAME(val);
  }

  get send_user() {
    return GMOSignInfoModule.signForm.send_user;
  }
  set send_user(val) {
    GMOSignInfoModule.SET_SEND_USER(val);
  }

  get own_organize_nm() {
    return GMOSignInfoModule.signForm.own_organize_nm;
  }
  set own_organize_nm(val) {
    GMOSignInfoModule.SET_OWN_ORGANIZE_NM(val);
  }

  next() {
    this.validateCount += 1;
    if (!this.validateAll()) {
      return;
    }
    this.$router.push({
      name: 'gmosign_form_signer',
      query: {
        id: this.contractId
      }
    });
  }

  validateAll() {
    if (!this.send_user) {
      return false;
    }
    if (!this.document_name) {
      return false;
    }
    return true;
  }
}
