


























































































import { Component, Vue, Watch } from 'vue-property-decorator';

import vLoading from 'vue-loading-overlay';
import AppNotifications from '@/components/atoms/AppNotifications.vue';
import HomeHeader from '@/components/organisms/Home/HomeHeader.vue';
import HomeSideMenuBar from '@/components/organisms/Home/HomeSideMenuBar.vue';
import ContractKanban from '@/components/organisms/ContractKanban.vue';
import CreateContractModal from '@/components/molecules/modal_windows/CreateContractModal.vue';
import SettingContractApplyModal from '@/components/molecules/modal_windows/SettingContractApplyModal.vue';
import ViewPermissionCard from '@/components/molecules/ViewPermissionCard.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import HomeComponentInfoModule from '@/store/HomeComponentInfo';

import ContractManagementModule, {
  assigneeUserInfoInterface,
  contractDataInterface
} from '@/store/ContractInfo';

import CONTRACT_TYPES from '@/consts/ContractTypes.js';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import DocumentsModule from '@/store/Documents';
import UserInfo from '@/store/UserInfo';
import CorporateInfo from '@/store/CorporateInfo';

import notify from '@/functions/notify';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const ContractInfoRepository = RepositoryFactory.get('contractInfos');
const DocumentRepository = RepositoryFactory.get('documents');

@Component({
  components: {
    vLoading,
    HomeHeader,
    HomeSideMenuBar,
    ContractKanban,
    AppNotifications,
    CreateContractModal,
    SettingContractApplyModal,
    ViewPermissionCard,
    TextOnlyWindow
  }
})
export default class Home extends Vue {
  showContractRequestSettingModal: boolean = false;
  showCreateCardModal: boolean = false;
  showPermissionModal: boolean = false;
  isShowingApplyModal: boolean = false;
  isCompleteGetAssignees: boolean = false;
  selectedRadioInputKey = null;
  selectedAssigneeId: number = null;
  attachementFiles = [];
  contractFile = null;
  file_upload: boolean = false;
  isLoading: boolean = false;
  finishedCard: { card_id: string } = {
    card_id: null
  };

  selectedViewPermissionData: any = null;
  timeoutId = null;
  cancelBulkFlag: boolean = false;

  /**
   * $refsのTypescript Error回避
   * https://qiita.com/tsumasakky/items/03a4bdf74e3c765c2077
   */
  get refs(): any {
    return this.$refs;
  }

  setViewPermissionData(data) {
    this.selectedViewPermissionData = data;
    this.showPermissionModal = false;
  }

  private get createColumnId() {
    return this.$store.state.contract_info.createColumnId;
  }

  private get contractBoardData() {
    return ContractManagementModule.sortedContractBoardColumnsData;
  }

  private get archivedContractCardData() {
    return ContractManagementModule.archivedContractCardData;
  }

  // サイドメニューの開閉ステータス
  private get side_bar_status(): boolean {
    return this.$store.state.home_component_info.side_bar_open;
  }

  private get setAssignee() {
    return ContractManagementModule.assigneeUserInfo;
  }

  private get contractTypeOptions() {
    return ContractManagementModule.boardContractTypes;
  }

  get isDeleteCard(): boolean {
    return DocumentsModule.is_delete_card;
  }

  async created() {
    DocumentsModule.SET_CURRENT_DIRECTORY('/');
    this.isLoading = true;

    DocumentsModule.SELECT_VERSION(null);
    await this.load();
    this.isLoading = false;

    await Promise.all([
      DocumentsModule.getNotifications(),
      DocumentsModule.getLirisNotifications(),
      CorporateInfo.get_service_plan()
    ])
      .then(values => {
        this.isLoading = false;
        if (values.find(x => x.status !== HTTP_STATUS.OK)) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        this.isLoading = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
  }

  async mounted() {
    // TODO: refs の使用をやめる。
    this.refs.kanban.handleResize();

    if (this.isDeleteCard) {
      notify.success({
        text: NOTIFY_TEXT.SUCCESS.DELETE,
        data: {
          cancelMethod: this.cancelDeleteFileOrFolder
        }
      });
    }
  }

  beforeDestroy() {
    DocumentsModule.SET_IS_DELETE_CARD(false);
  }

  /**
   * 初期表示に必要なデータのみ読み込む
   */
  async load() {
    await Promise.all([
      UserInfo.get_user_info(),
      // カンバンボード全体のデータを取得
      ContractManagementModule.getContractBoardData(),
      // 依頼先に設定できるユーザー一覧を取得する。カンバンボードのレスポンスデータを使用するため getContractBoardData の処理のあとに配置する。
      ContractManagementModule.getAssignees()
      // 依頼する際のコメントにメンションできるユーザーを取得する。
      //   DocumentsModule.getCanMentionUsers()
    ])
      .then(values => {
        this.isLoading = false;
        this.isCompleteGetAssignees = true;
        if (values.find(x => x.status !== HTTP_STATUS.OK)) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        this.isLoading = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
  }

  // 依頼者データ
  private get requestFrom(): assigneeUserInfoInterface {
    const {
      user_id,
      user_name,
      department_name,
      avatar
    } = this.$store.state.user_info;
    return {
      user_id,
      user_name,
      department_name,
      avatar_name: user_name,
      avatar_image_path: '',
      avatar_color: avatar ? avatar.avatar_color : '1452cc'
    };
  }

  // 依頼先データ
  private get requestTo(): assigneeUserInfoInterface {
    return this.$store.state.contract_info.assigneeUserInfo;
  }

  getNotifications() {
    return this.$store.state.documents.notifications;
  }

  private get assigneeList(): assigneeUserInfoInterface[] {
    // TODO: エンドポイントがレスポンスを返すようになったら ContractManagementModule の assigneesList に置き換える
    return this.$store.state.contract_info.assigneesList;
  }

  private get contractFileExtraction(): contractDataInterface {
    return this.$store.state.contract_info.contractDataExtraction;
  }

  /**
   * @return {Array<Object<number, string>>}
   */
  get selectOptions() {
    return this.assigneeList.map(assignee => ({
      user_id: assignee.user_id,
      user_name: assignee.user_name,
      department_name: assignee.department_name,
      avatar_color: assignee.avatar_color
    }));
  }

  /**
   * 依頼先の user を選択する
   */
  selectAssignee({ user_id }) {
    this.selectedAssigneeId = user_id;
  }

  /**
   * 依頼先を設定するボタン
   * TODO: 処理を追加
   */
  async setApplyTo() {
    this.showContractRequestSettingModal = !this
      .showContractRequestSettingModal;

    // 依頼先のuser_idが存在しない場合はリクエストせずにモーダルを閉じる
    if (!this.selectedAssigneeId) return;

    this.isLoading = true;

    const params = {
      user_id: this.selectedAssigneeId,
      contract_board_id: ContractManagementModule.contractBoardId
    };

    await ContractInfoRepository.updateRequestUserApplyTo(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SET_APPLY_TO
          });
          return;
        }

        await ContractManagementModule.getContractBoardData();
        await ContractManagementModule.getAssignees();
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.SET_APPLY_TO
        });
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.SET_APPLY_TO
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  async cancelDeleteFileOrFolder(): Promise<void> {
    // 表示されているスナックバーを消す
    notify.clearSuccess();
    this.isLoading = true;
    const responses = await DocumentsModule.restoreDeletedFileOrFolder();
    this.isLoading = false;

    // ファイルもしくはフォルダの削除取り消しでエラーがあれば notify を表示し処理を終える。
    const failed: boolean = responses.some(
      res => !res || res.status !== HTTP_STATUS.NO_CONTENT
    );
    if (failed) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.CANCEL
      });
      return;
    }

    this.isLoading = true;
    // 締結管理ドキュメント
    if (this.$route.name === 'contract_management')
      await ContractManagementModule.getContractBoardData();
    DocumentsModule.RESET_SELECTED_TABLE();
    this.isLoading = false;
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CANCEL
    });
  }

  async cancelUpdateBulkCardStatus(
    cards: { card_id: string; card_name?: string }[],
    destination_column_id: number,
    is_finished: boolean
  ): Promise<void> {
    this.cancelBulkFlag = true;
    let errorText: string = null;
    // 表示されている成功スナックバーを消す
    notify.clearSuccess();

    this.isLoading = true;

    // キャンセルの処理
    for (const i in cards) {
      const card: { card_id: string; card_name?: string } = cards[i];
      const card_id: string = card.card_id;

      const res = await this.exeUpdateCardStatusApi({
        card_id,
        destination_column_id: null,
        is_finished: false,
        card_order: null
      });

      if (res instanceof Error) errorText = NOTIFY_TEXT.ERROR.CANCEL;
    }

    if (!!errorText) {
      // 取り消し成功したものも締結済みに戻す
      for (const i in cards) {
        const card: { card_id: string; card_name?: string } = cards[i];
        const card_id: string = card.card_id;

        const res = await this.exeUpdateCardStatusApi({
          card_id,
          destination_column_id,
          is_finished,
          card_order: null
        });
      }
      // 締結管理再取得
      await this.getContractBoardData();
      notify.error({
        text: NOTIFY_TEXT.ERROR.CANCEL
      });
    } else {
      // 締結管理再取得
      await this.getContractBoardData();
      notify.success({
        text: NOTIFY_TEXT.SUCCESS.CANCEL
      });
    }
  }

  /**
   * サイドバーの開閉
   */
  toggleSideBar() {
    HomeComponentInfoModule.change_side_bar();
  }

  /**
   * 閲覧権限を設定するモーダルを表示する
   */
  openViewPermissionWindow() {
    this.showPermissionModal = true;
  }

  /**
   * カードを作成するモーダルを閉じる。
   * モーダル内のフォームのいずれかに入力がある場合、確認モーダルを表示する。
   */
  closeCreateContractModal(is_any_filled) {
    if (is_any_filled) {
      this.isShowingApplyModal = !this.isShowingApplyModal;
      return;
    }
    this.showCreateCardModal = !this.showCreateCardModal;
  }

  /**
   * 新たな締結管理を作成する際に初期化が必要な設定
   */
  @Watch('showCreateCardModal')
  async initializeCreateSettings(newVal: boolean, oldVal: boolean) {
    if (this.showCreateCardModal) {
      // 依頼する際のコメントにメンションできるユーザーを取得する。
      await DocumentsModule.getCanMentionUsers();
    }
    if (newVal && !oldVal) {
      // 閲覧権限設定を初期化
      this.selectedViewPermissionData = null;
    }
  }

  /**
   * カードの検索処理。入力したテキストを元にリクエスト投げる。
   */
  async filterCardsByText(searchInputText) {
    this.isLoading = true;
    await ContractManagementModule.getContractBoardData({
      keyword: searchInputText,
      is_archived: false
    });
    this.isLoading = false;
  }

  /**
   * カードを動かしたときの挙動
   */
  async updateCardStatus({
    card_id,
    name,
    destination_column_id,
    is_finished,
    column_id_from,
    card_order,
    version
  }): Promise<void> {
    // スナックバーの取り消し処理のためにデータを一時保持する。
    this.finishedCard = {
      card_id
    };

    if (is_finished) this.isLoading = true;
    const res = await this.exeUpdateCardStatusApi({
      card_id,
      destination_column_id,
      is_finished,
      card_order
    });
    this.isLoading = false;

    const columnName = this.contractBoardData.find(
      data => data.column_id === destination_column_id
    ).column_name;

    notify.clearSuccess();

    if (res instanceof Error) {
      if (res && is_finished) {
        notify.error({
          text: `${name} ${NOTIFY_TEXT.ERROR.MOVED_SIGNE_DOCUMENT}`
        });
      } else {
        notify.error({
          text: `${name}を${columnName}に移動できませんでした`
        });
      }
      await ContractManagementModule.getContractBoardData();
      return;
    }

    if (res.status === HTTP_STATUS.FORBIDDEN) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.NOT_ALLOWED.MOVE_CARD
      });
      await ContractManagementModule.getContractBoardData();
      return;
    }

    if (res.status !== HTTP_STATUS.OK) {
      if (res && is_finished) {
        notify.error({
          text: `${name} ${NOTIFY_TEXT.ERROR.MOVED_SIGNE_DOCUMENT}`
        });
      } else {
        notify.error({
          text: `${name}を${columnName}に移動できませんでした`
        });
      }

      await ContractManagementModule.getContractBoardData();
      return;
    }

    // 締結済みカラムに移動したとき、締結済カラムから移動するときに看板のカード情報を更新することでバージョン情報を更新する
    // todo: versionの表示を算出プロパティにするとここのAPIリクエスト処理は無くせそう
    const departureColumn = ContractManagementModule.getColumnById(
      column_id_from
    );
    const destinationColumn = ContractManagementModule.getColumnById(
      destination_column_id
    );

    if (
      (departureColumn && departureColumn.is_finish_column) ||
      (destinationColumn && destinationColumn.is_finish_column)
    ) {
      await ContractManagementModule.getContractBoardData();
    }
    // 自動抽出完了のスナックバーは非表示にすることになったためコメントアウト
    // if (version !== null && destinationColumn.is_needed_signed_file) {
    //   notify.success({
    //     text: '契約情報を自動抽出しました'
    //   });
    // }

    if (is_finished) this.isLoading = true;
    this.isLoading = false;

    if (is_finished) {
      await ContractManagementModule.getContractBoardData();
      // router遷移するとスナックバーも消えるので3秒ほど確認できる時間を設ける
      // this.timeoutId = setTimeout(() => {
      //   this.$router.push('/home/contracted_document');
      // }, 3000);
      notify.success({
        text: `${name} ${NOTIFY_TEXT.SUCCESS.MOVED_SIGNE_DOCUMENT}`,
        data: {
          cancelMethod: this.cancelFinishedContractCardStatus
        }
      });
      return;
    } else {
      ContractManagementModule.SET_BEFORE_UPDATE_CONTRACT_DATA({
        card_id,
        name,
        destination_column_id: column_id_from,
        is_finished
      });
    }
  }

  /**
   * カードを動かしたときの挙動（カードの順序を変えた場合）
   */
  updateCardIndex({
    card_id,
    destination_column_id,
    is_finished,
    card_order
  }): void {
    this.exeUpdateCardStatusApi({
      card_id,
      destination_column_id,
      is_finished,
      card_order
    });
  }

  /**
   * カードステータス更新API実行
   */
  async exeUpdateCardStatusApi({
    card_id,
    destination_column_id,
    is_finished,
    card_order
  }) {
    return await ContractInfoRepository.updateContractCardStatus({
      card_id,
      destination_column_id,
      is_finished,
      card_order
    }).catch(e => {
      return e;
    });
  }

  async cancelFinishedContractCardStatus() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
    this.isLoading = true;
    notify.clearSuccess();
    const res = await this.exeUpdateCardStatusApi({
      ...this.finishedCard,
      destination_column_id: null,
      is_finished: false,
      card_order: null
    });
    if (res instanceof Error) {
      this.isLoading = false;
      notify.error({
        text: NOTIFY_TEXT.ERROR.CANCEL
      });
      return;
    }
    await ContractManagementModule.getContractBoardData();
    this.isLoading = false;
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CANCEL
    });
  }

  /**
   * 締結済みカラムにあるカードを一括で完了させる
   */
  async updateBulkCardStatus({ cards, destination_column_id, is_finished }) {
    this.isLoading = true;
    this.cancelBulkFlag = false;
    let errorText: string = null;

    for (const i in cards) {
      const card: { card_id: string; card_name: string } = cards[i];
      const card_id: string = card.card_id;
      const name: string = card.card_name;

      const res = await this.exeUpdateCardStatusApi({
        card_id,
        destination_column_id,
        is_finished,
        card_order: null
      });

      if (res instanceof Error || res.status !== HTTP_STATUS.OK) {
        errorText = `${name} ${NOTIFY_TEXT.ERROR.MOVED_SIGNE_DOCUMENT}`;
      }
    }

    if (!!errorText) {
      // 成功したものも全て締結管理に戻す
      for (const i in cards) {
        const card: { card_id: string; card_name?: string } = cards[i];
        const card_id: string = card.card_id;

        const res = await this.exeUpdateCardStatusApi({
          card_id,
          destination_column_id: null,
          is_finished: false,
          card_order: null
        });

        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPDATE_CONTRACT_CARD_STATUS
          });
          return;
        }
      }
      this.cancelBulkFlag = true;
      await this.getContractBoardData();
      notify.error({
        text: errorText
      });
    } else {
      await this.getContractBoardData();
      notify.success({
        text: NOTIFY_TEXT.SUCCESS.MOVED_BULK_SIGNE_DOCUMENT,
        data: {
          cancelMethod: async () => {
            await this.cancelUpdateBulkCardStatus(
              cards,
              destination_column_id,
              is_finished
            );
          }
        }
      });
    }

    this.isLoading = false;

    setTimeout(() => {
      // 取り消しされたことを考慮
      if (!this.cancelBulkFlag) this.$router.push('/home/contracted_document');
    }, 5000);
  }

  // 締結管理の情報を取得する
  async getContractBoardData(): Promise<void> {
    this.isLoading = true;
    await ContractManagementModule.getContractBoardData();
    this.isLoading = false;
  }

  /**
   * 「破棄する」をクリックしたときの挙動
   */
  submitCancelRequest() {
    this.showCreateCardModal = false;
    this.isShowingApplyModal = false;
  }

  async uploadAttachedFile(fileList) {
    this.isLoading = true;
    const files = [];
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.size > 10 * 1024 * 1024) {
        if (
          confirm(
            file.name +
              'のファイルサイズは10MBを超えています。アップロードを中断しますか？'
          )
        ) {
          break;
        } else {
          continue;
        }
      }
      const uploadFile = new File([file], file.name);
      files.push(uploadFile);
    }
    this.attachementFiles = files;
    this.isLoading = false;
  }

  async uploadContractFile(contract_file) {
    this.isLoading = true;
    const uploadFile = new File([contract_file], contract_file.name);
    this.contractFile = uploadFile;
    await this.extractContractText();
    this.isLoading = false;
  }

  async extractContractText() {
    this.isLoading = true;
    const formData = new FormData();
    formData.append('contract', this.contractFile);
    await ContractManagementModule.getContractFileExtraction({
      params: formData
    });
    this.isLoading = false;
  }

  /**
   * カードを追加するボタン
   */
  async contactRequest({ contractData }) {
    this.isLoading = true;
    const formData = new FormData();

    const sendFiles = {
      ...contractData,
      column_id: this.createColumnId
    };
    // FormData に入力データを挿入する
    Object.entries(sendFiles).map(([key, _]) => {
      formData.append(key, sendFiles[key]);
    });

    // 資料添付のファイルがある場合はファイルを追加
    if (this.attachementFiles.length) {
      for (const file of this.attachementFiles) {
        formData.append('attachment_list', file);
      }
    }

    // 閲覧権限設定
    if (this.selectedViewPermissionData) {
      let viewable_user_id = '';
      let viewable_department_id = '';
      const data = this.selectedViewPermissionData;
      if (data.selected_users) {
        const user_id_list = [];
        for (const i in data.selected_users) {
          user_id_list.push(data.selected_users[i].user_id);
        }
        viewable_user_id = user_id_list.join(',');
        formData.append('viewable_user_id', viewable_user_id);
      }
      if (data.selected_departments) {
        const department_id_list = [];
        for (const i in data.selected_departments) {
          department_id_list.push(data.selected_departments[i].department_id);
        }
        viewable_department_id = department_id_list.join(',');
        formData.append('viewable_department_id', viewable_department_id);
      }
    }

    if (contractData.task_type === CONTRACT_TYPES.CHECK) {
      if (!this.contractFile) {
        return;
      }
      // 契約書のファイルを追加
      formData.append('contract', this.contractFile);
    }

    await ContractInfoRepository.createContractCard(formData)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CREATED_CARD
          });
          return;
        }

        this.isShowingApplyModal = false;
        this.showCreateCardModal = false;

        const selectedOption = this.contractTypeOptions.find(
          option => option['contract_type_id'] === contractData.contract_type_id
        );
        if (contractData.task_type === CONTRACT_TYPES.CREATE) {
          notify.success({
            text: `${selectedOption['contract_type_name']}に関する契約書 の作成を依頼しました`
          });
        } else {
          notify.success({
            text: `${contractData.contract_title} の確認を依頼しました`
          });
        }
        await ContractManagementModule.getContractBoardData();
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.CREATED_CARD
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  deleteAttachedFile(index) {
    this.attachementFiles.splice(index, 1);
  }

  deleteContractFile() {
    this.contractFile = null;
    ContractManagementModule.CLEAR_CONTRACT_EXTRACTION();
  }

  /**
   * 閲覧権限設定
   */
  async saveViewPermission(data: {
    selected_users: any[];
    selected_departments: any[];
    contract_id: string;
  }) {
    const user_id_list = data.selected_users.map(user => user.user_id);
    const department_id_list = data.selected_departments.map(
      department => department.department_id
    );

    const params = {
      user_id_list,
      department_id_list,
      contract_id_list: [data.contract_id],
      directory_path_list: []
    };

    await DocumentRepository.saveViewPermission(params)
      .then(res => {
        if (res.status === HTTP_STATUS.BAD_REQUEST) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.EMPTY
          });
          return;
        }
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.FAILED
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.FAILED
        });
        return;
      });

    await ContractManagementModule.getContractBoardData()
      .then(async res => {
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.SETTING_VIEW_PERMISSION
        });
      })
      .catch(() => {
        notify.error({
          text: '閲覧権限の確認に失敗しました'
        });
        return;
      });
  }
}
