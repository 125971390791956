const axios = require('axios').default;

const resource = '/contract-management/templates';
const clientResource = 'clients';
const lirisResource = 'liris';

type DirectoryParams = {
  directory_path: string;
  directory_name: string;
};

export default {
  /**
   * 自社テンプレートのプレビューを返却する
   *
   * @param { String } client_template_id
   */
  getClientTemplatePreview({ client_template_id }) {
    return axios.get(
      `${resource}/${clientResource}/${client_template_id}/html`
    );
  },

  /**
   * LIRISテンプレートのプレビューを返却する
   *
   * @param { String } liris_template_id
   */
  getLirisTemplatePreview({ liris_template_id }) {
    return axios.get(`${resource}/${lirisResource}/${liris_template_id}/html`);
  },

  /**
   * 自社テンプレートアップロード
   *
   * @param { Object } params
   */
  uploadClientTemplate({ params }) {
    const headers = { 'content-type': 'multipart/form-data' };

    return axios.post(`${resource}/${clientResource}/files`, params, {
      headers,
      timeout: 1200 * 1000
    });
  },

  /**
   * LIRISテンプレートアップロード
   *
   * @param { Object } params
   */
  uploadLirisTemplate({ params }) {
    const headers = { 'content-type': 'multipart/form-data' };

    return axios.post(`${resource}/${lirisResource}/files`, params, {
      headers,
      timeout: 1200 * 1000
    });
  },

  /**
   * 自社テンプレートディレクリ作成
   *
   * @param { DirectoryParams } params
   */
  addClientTemplateDirectory(params: DirectoryParams) {
    return axios.post(`${resource}/${clientResource}/directories`, params);
  },

  /**
   * LIRISテンプレートディレクトリ作成
   *
   * @param { DirectoryParams } params
   */
  addLirisTemplateDirectory(params: DirectoryParams) {
    return axios.post(`${resource}/${lirisResource}/directories`, params);
  },

  /**
   * 自社テンプレートのファイル名変更
   *
   * @param { String } client_template_id
   * @param { String } file_name
   * @param { String } client_directory
   */
  renameClientTemplate({ client_template_id, file_name, current_directory }) {
    const data = {
      client_template_id,
      file_name,
      current_directory
    };
    return axios.put(`${resource}/${clientResource}/files/rename`, data);
  },

  /**
   * 自社テンプレートのフォルダ名変更
   *
   * @param { String } target_directory_path
   * @param { String } new_name
   * @param { String } current_directory
   */
  renameClientFolder({ target_directory_path, new_name, current_directory }) {
    return axios.put(`${resource}/${clientResource}/directories/rename`, {
      target_directory_path,
      new_name,
      current_directory
    });
  },

  /**
   * LIRISテンプレートのフォルダ名変更
   *
   * @param { String } target_directory_path
   * @param { String } new_name
   * @param { String } current_directory
   */
  renameLirisFolder({ target_directory_path, new_name, current_directory }) {
    return axios.put(`${resource}/${lirisResource}/directories/rename`, {
      target_directory_path,
      new_name,
      current_directory
    });
  },

  /**
   * ダウンロードリンクの作成
   *
   * @param { Array } dir_path_list
   * @param { Array } template_id_list
   * @param { Boolean } is_client_tab_selected
   */
  createDownloadLink({
    dir_path_list,
    template_id_list,
    is_client_tab_selected
  }) {
    const url = is_client_tab_selected
      ? '/contract-management/templates/clients/download_link'
      : '/contract-management/templates/liris/download_link';

    return axios.post(url, {
      dir_path_list,
      template_id_list
    });
  },

  /**
   * ダウンロードリンクを取得
   * todo
   * エンドポイントがtemplate関連じゃないので別のところに移動させる？
   *
   * @param { String } job_id
   */
  getDownloadLink({ job_id }) {
    return axios.get(`/contract-management/downloads/jobs/${job_id}`);
  },

  /**
   * ダウンロードジョブIDを取得
   */
  getMyDownloadJobIds() {
    return axios.get(`/contract-management/downloads/jobs/me`);
  },

  /**
   * 自社テンプレートの削除
   *
   * @param { String } current_directory
   * @param { Object } data_params
   */
  deleteClientTemplate({ current_directory, data_params }) {
    return axios.delete(`${resource}/${clientResource}/delete`, {
      data: data_params,
      params: { current_directory }
    });
  },

  /**
   * LIRISテンプレートの削除
   *
   * @param { String } current_directory
   * @param { Object } data_params
   */
  deleteLirisTemplate({ current_directory, data_params }) {
    return axios.delete(`${resource}/${lirisResource}`, {
      data: data_params,
      params: { current_directory }
    });
  }
};
