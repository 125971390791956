
































import { PropType } from 'vue';
import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import { AssociationInfo } from '@/store/Associations';

@Component
export default class SelectContractModalItem extends Vue {
  @Prop({ type: Object as PropType<AssociationInfo> })
  readonly contract: AssociationInfo;
  @Prop({ type: Boolean, default: false }) readonly preview: boolean;
  @Prop({ type: Boolean, default: false }) readonly removable: boolean;

  get iconImgSrc() {
    switch (this.contract.file_extension) {
      case 'pdf':
        return 'icon_pdf_normal.svg';
      case 'docx':
        return 'icon_word_normal.svg';
      case 'xlsx':
        return 'icon_excel_normal.svg';
      default:
        return '';
    }
  }
  get filepath() {
    if (!this.contract.file_path) return '';
    const filePath = this.contract.file_path.split('/').filter(path => path);
    return filePath.join(' > ');
  }
  get filename() {
    return this.contract.contract_title;
  }
  get fullFileName() {
    if (!this.contract.file_name || !this.contract.file_extension) return null;

    return `${this.contract.file_name}.${this.contract.file_extension}`;
  }
  get associatedContracts() {
    if (
      !this.contract.associated_contracts ||
      !this.contract.associated_contracts.length
    )
      return 'なし';
    return this.contract.associated_contracts
      .map(c => c.contract_title)
      .join(', ');
  }

  @Emit()
  remove() {
    return this.contract.contract_id;
  }

  onPreview() {
    window.open(
      `/document-detail/${this.contract.contract_id}?preview=true`,
      '_blank'
    );
  }
}
