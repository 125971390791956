






















































import { Component, Vue } from 'vue-property-decorator';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import CorporateInfoModule from '@/store/CorporateInfo';

// import notify from '@/functions/notify';

// import NOTIFY_TEXT from '@/consts/NotifyText';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const CollaboRepository = RepositoryFactory.get('collabo');

@Component({
  components: {
    TextForm,
    AppButton,
    TextOnlyWindow
  }
})
export default class GMOSign extends Vue {
  isOpenReleaseModal: boolean = false;

  async created() {
    await this.fetchData();
  }

  async fetchData() {
    await CorporateInfoModule.get_corporate_info();
  }

  get is_cooperated_buntan(): boolean {
    return CorporateInfoModule.is_cooperated_buntan;
  }

  get corporation_id(): string {
    return CorporateInfoModule.corporation_id;
  }

  get secret_key(): string {
    return CorporateInfoModule.secret_key;
  }

  closeReleaseModal() {
    this.isOpenReleaseModal = false;
  }

  async enable() {
    const params = { enable: true };
    await CollaboRepository.enableBuntan(params);
    await this.fetchData();
    if (!this.secret_key) {
      await CollaboRepository.generateSecretKey();
      await this.fetchData();
    }
  }

  async release() {
    const params = { enable: false };
    await CollaboRepository.enableBuntan(params);
    await this.fetchData();
    this.closeReleaseModal();
  }
}
