const axios = require('axios').default;

import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';
import DocumentsModule from '@/store/Documents';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';

async function moveFileOrFolder(reload: boolean = true): Promise<boolean> {
  let result = null;

  // ファイルの移動処理
  const selectedFiles = DocumentsModule.selected_files_of_table;
  if (selectedFiles.length) result = await this.moveFiles(selectedFiles);

  // フォルダの移動処理
  const selectedFolders = DocumentsModule.selected_folders_of_table;
  if (selectedFolders.length) result = await this.moveFolders(selectedFolders);

  if (result === HTTP_STATUS.UNAUTHORIZED) {
    notify.error({
      text: NOTIFY_TEXT.ERROR.CAN_NOT_CREATE_FOLDER_FOR_SMALL_PLAN
    });
    return false;
  }

  if (result !== HTTP_STATUS.NO_CONTENT) {
    notify.error({
      text: NOTIFY_TEXT.ERROR.MOVE
    });
    return false;
  }

  if (reload) {
    await DocumentsModule.getFilesAndFolders();
    await HomeComponentInfoModule.change_tables_countup();
    if (location.pathname.includes('document-detail'))
      await DocumentsModule.reloadContractDetail();
  }

  DocumentsModule.RESET_SELECTED_TABLE();
  HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW(false);

  return true;
}

/**
 * ファイルの移動処理
 *
 * @param {Array} selectedFiles
 * @returns {Boolean}
 */
async function moveFiles(selectedFiles) {
  if (selectedFiles[0].hasOwnProperty('contract_id'))
    selectedFiles = selectedFiles.map(x => x.contract_id);

  return await axios
    .put('/contract-management/contracts/move/directory', {
      move_to_dir_path: HomeComponentInfoModule.move_to_path,
      contract_id_list: selectedFiles
    })
    .then(res => {
      return res.status;
    })
    .catch(err => {
      return err.response.status;
    });
}

/**
 * フォルダの移動処理
 *
 * @param {Array} selectedFolders
 * @returns {Boolean}
 */
async function moveFolders(selectedFolders) {
  // 移動先に移動元のフォルダが含まれていたら除外する
  const move_to_dir_path = HomeComponentInfoModule.move_to_path;
  const dir_path_list = selectedFolders.map(f => f.directory_path);
  const checked_dir_path_list = [];

  for (const i in dir_path_list) {
    const dir_path = dir_path_list[i];
    if (move_to_dir_path.search(dir_path) === -1) {
      checked_dir_path_list.push(dir_path);
    }
  }

  return await axios
    .put('/contract-management/directories/move/directory', {
      move_to_dir_path: move_to_dir_path,
      dir_path_list: checked_dir_path_list
    })
    .then(res => {
      return res.status;
    })
    .catch(err => {
      return err.response.status;
    });
}

export default { moveFileOrFolder, moveFiles, moveFolders };
