





















import { Component, Vue, Emit } from 'vue-property-decorator';
import IconSvg from '@/components/atoms/icons/IconSvg.vue';
import DocumentInfoComment from '@/components/organisms/DocumentDetail/DocumentInfo/DocumentInfoComment.vue';
import DocumentInfoContract from '@/components/organisms/DocumentDetail/DocumentInfo/DocumentInfoContract.vue';
import DocumentInfoReferences from '@/components/organisms/DocumentDetail/DocumentInfo/DocumentInfoReferences.vue';
import DocumentInfoTask from '@/components/organisms/DocumentDetail/DocumentInfo/DocumentInfoTask.vue';
import Documents from '@/store/Documents';

@Component({
  components: {
    IconSvg,
    DocumentInfoComment,
    DocumentInfoContract,
    DocumentInfoReferences,
    DocumentInfoTask
  }
})
export default class DocumentInfo extends Vue {
  @Emit()
  startUpload(fileCount) {
    return fileCount;
  }

  @Emit()
  endUpload() {
    return;
  }

  get kanbanCardInfo() {
    if (!Documents.contract_detail) return null;
    return Documents.contract_detail;
  }

  get isSigned(): boolean {
    return Documents.isSigned;
  }

  scrollTop() {
    this.$el.scrollTop = 0;
  }
  scrollBottom() {
    this.$el.scrollTop = this.$el.scrollHeight;
  }
}
