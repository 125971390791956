










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ImageContent extends Vue {
  @Prop({ default: '' })
  src?: string;

  @Prop({ default: '' })
  alt?: string;

  @Prop({ default: '18px' })
  height?: string;

  @Prop({ default: 'auto' })
  width?: string;
}
