const axios = require('axios').default;

const resource = '/user-management';

type CreateMemberParams = {
  department_id: string;
  email: string;
  permission_default_id: string;
};

type DeleteMembersParams = {
  member_id_list: [string];
};

type UpdateSettingAlertParams = {
  auto_update_date: number;
  expiration_date: number;
};

type CreateDepartmentParams = {
  department_name: string;
};

type UpdateDepartmentParams = {
  department_id: number;
  name: string;
};

type DeleteDepartmentsParams = {
  department_id_list: [string];
};

type GetUsersWithDepartmentParams = {
  department_id: string;
};

type CreateUsersWithDepartmentParams = {
  user_id_list: [string];
  department_id: string;
};

type UpdateUserInfoParams = {
  user_name: string;
  department_id: string;
  email: string;
  is_showing_extraction_dialog: boolean;
};

type GetEmailNotificationSettingParams = {
  user_id: string;
};

type UpdateEmailNotificationSettingParams = {
  is_create: boolean;
  is_change_status: boolean;
  is_assign: boolean;
  is_comment: boolean;
  is_alert: boolean;
};

type UpdateControlNumberRule = {
  control_number_rule: string;
  is_override: boolean;
};

export default {
  /**
   * ユーザー情報取得
   */
  getUserInfo(idToken: string) {
    return axios.get(`${resource}/users/info/me`, {
      headers: { 'id-token': idToken }
    });
  },

  /**
   * ユーザー情報更新
   *
   * @param { Object } params
   */
  updateUserInfo(params: UpdateUserInfoParams) {
    return axios.put(`${resource}/users/info/me`, params);
  },

  /**
   * サービスプラン情報取得
   */
  getServicePlan() {
    return axios.get(`${resource}/settings/serviceplans`);
  },

  /**
   * 会社情報取得
   */
  getCorporateInfo() {
    return axios.get(`${resource}/corporations`);
  },

  /**
   * メンバー情報取得
   */
  getMembers() {
    return axios.get(`${resource}/users`);
  },

  /**
   * メンバー新規登録
   *
   * @param { Object } params
   */
  createMember(params: CreateMemberParams) {
    return axios.post(`${resource}/users`, params);
  },

  /**
   * メンバー削除
   *
   * @param { Object } params
   */
  deleteMembers(params: DeleteMembersParams) {
    return axios.put(`${resource}/users/delete`, {
      user_id_list: params.member_id_list
    });
  },

  /**
   * 権限一覧情報取得
   */
  getAuthorities() {
    return axios.get(`${resource}/permissions/defaults/lists`);
  },

  /**
   * 業種一覧情報取得
   */
  getIndustryOptions() {
    return axios.get(`${resource}/accounts/business_categories`);
  },

  /**
   * 従業員数一覧情報取得
   */
  getEmployeesCount() {
    return axios.get(`${resource}/accounts/employee_counts`);
  },

  /**
   * アラート設定情報取得
   */
  getSettingAlert() {
    return axios.get(`${resource}/settings/alerts`);
  },

  /**
   * アラート設定情報更新
   *
   * @param { Object } params
   */
  updateSettingAlert(params: UpdateSettingAlertParams) {
    return axios.put(`${resource}/settings/alerts`, {
      auto_update_before_alert_day: params.auto_update_date,
      expiration_before_alert_day: params.expiration_date
    });
  },

  /**
   * 部署情報取得
   */
  getDepartments() {
    return axios.get(`${resource}/departments`);
  },

  /**
   * 部署新規登録
   */
  createDepartment(params: CreateDepartmentParams) {
    return axios.post(`${resource}/departments`, params);
  },

  /**
   * 部署名変更
   *
   * @param { Object } params
   */
  updateDepartment(params: UpdateDepartmentParams) {
    return axios.put(`${resource}/departments/${params.department_id}/rename`, {
      department_name: params.name
    });
  },

  /**
   * 部署削除
   *
   * @param { Object } params
   */
  deleteDepartments(params: DeleteDepartmentsParams) {
    return axios.put(`${resource}/departments/delete`, {
      department_id_list: params.department_id_list
    });
  },

  /**
   * 部署に設定されているユーザーを取得
   *
   * @param { Object } params
   */
  getUsersWithDepartment(params: GetUsersWithDepartmentParams) {
    return axios.get(`${resource}/departments/${params.department_id}/users`);
  },

  /**
   * 部署にユーザーを新規登録
   *
   * @param { Object } params
   */
  createUsersWithDepartment(params: CreateUsersWithDepartmentParams) {
    return axios.post(`${resource}/departments/${params.department_id}/users`, {
      user_id_list: params.user_id_list
    });
  },

  /**
   * 部署からユーザーを削除
   *
   * @param { Object } params
   */
  deleteUsersWithDepartment(params: CreateUsersWithDepartmentParams) {
    return axios.delete(
      `${resource}/departments/${params.department_id}/users`,
      {
        data: {
          user_id_list: params.user_id_list
        }
      }
    );
  },

  /**
   * 同じ会社のユーザー情報取得
   *
   * @param { number } permission_order
   */
  getUsersCorporationsSameMe(permission_order: number = 5) {
    return axios.get(
      `${resource}/users/corporations/same/me?permission_order=${permission_order}`
    );
  },

  /**
   * メール通知設定情報取得
   *
   * @param { Object } params
   */
  getEmailNotificationSetting(params: GetEmailNotificationSettingParams) {
    return axios.get(
      `/contract-management/notifications/email/setting?user_id=${params.user_id}`
    );
  },

  /**
   * メール通知設定更新
   *
   * @param { Object } params
   */
  updateEmailNotificationSetting(params: UpdateEmailNotificationSettingParams) {
    return axios.put(
      '/contract-management/notifications/email/setting',
      params
    );
  },

  /**
   * 契約書ラベル取得
   */
  getCoporationContractLabels() {
    return axios.get(`${resource}/corporation_contract_labels`);
  },

  /**
   * 契約書ラベル新規作成
   */
  createCoporationContractLabel(payload) {
    return axios.post(`${resource}/corporation_contract_labels`, {
      label_name: payload.label_name,
      background_color: payload.background_color,
      font_color: payload.font_color
    });
  },

  /**
   * 契約書ラベル編集
   */
  updateCoporationContractLabel(payload) {
    return axios.patch(
      `${resource}/corporation_contract_labels/${payload.label_id}`,
      {
        label_name: payload.label_name,
        background_color: payload.background_color,
        font_color: payload.font_color
      }
    );
  },

  /**
   * 契約書ラベル削除
   */
  deleteCoporationContractLabel(payload) {
    return axios.delete(
      `${resource}/corporation_contract_labels/${payload.label_id}`
    );
  },

  /**
   * 契約書No.ルールプレビュー取得
   *
   * @param { String } control_number_rule
   */
  getControlNumberPreview(control_number_rule: string) {
    return axios.get(
      `${resource}/corporations/preview/control_number_rule?control_number_rule=${control_number_rule}`
    );
  },

  /**
   * 契約書No.ルール更新
   *
   * @param { Object } params
   */
  updateControlNumberPreview(params: UpdateControlNumberRule) {
    return axios.put(`${resource}/corporations/control_number_rule`, {
      control_number_rule: params.control_number_rule,
      is_override: params.is_override
    });
  }
};
