























import { Component, Vue } from 'vue-property-decorator';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import Icon from '@/components/atoms/icons/Icon.vue';
import SendEmail from '@/components/atoms/images/SendEmail.vue';
import KEY_CODE from '@/consts/KeyCode';

@Component({
  components: {
    FormTitle,
    Icon,
    AppButton,
    SendEmail
  }
})
export default class CreatedAccountComplete extends Vue {
  icon_class: string[] = ['far', 'check-circle'];

  mounted() {
    this.focusDiv();
  }

  focusDiv() {
    const div: any = this.$refs.div;
    if (!div) {
      return;
    } else {
      div.focus();
    }
  }

  next_page() {
    this.$router.push({ name: 'login' });
  }

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.next_page();
    }
  }
}
