











































import { Component, Vue, Prop } from 'vue-property-decorator';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import FileUploadedDisplay from '@/components/molecules/side_menu_item/FileUploadedDisplay.vue';
import IconContractedDocument from '@/components/atoms/icons/IconContractedDocument.vue';
import IconContractManagement from '@/components/atoms/icons/IconContractManagement.vue';
import IconClock from '@/components/atoms/icons/IconClock.vue';
import IconSingleFile from '@/components/atoms/icons/IconSingleFile.vue';
import IconTrashCan from '@/components/atoms/icons/IconTrashCan.vue';

import SearchInfo from '@/store/SearchInfo';
import CorporateInfoModule from '@/store/CorporateInfo';
import Documents from '@/store/Documents';

@Component({
  components: {
    ImageContent,
    FileUploadedDisplay,
    IconContractedDocument,
    IconContractManagement,
    IconClock,
    IconSingleFile,
    IconTrashCan
  }
})
export default class HomeSideMenuBar extends Vue {
  @Prop({})
  sideBarOpenStatus: boolean;
  @Prop({ default: true })
  hasToggle: boolean;

  get linkItems() {
    return [
      {
        key: 'contracted_document',
        name: '締結済ドキュメント',
        icon: 'IconContractedDocument',
        path: '/home/contracted_document',
        permission: true
      },
      {
        key: 'contract_management',
        name: '締結管理',
        icon: 'IconContractManagement',
        path: '/contract-management',
        permission: !CorporateInfoModule.isSmallPlan
      },
      {
        key: 'recently_viewed',
        name: '最近表示',
        icon: 'IconClock',
        path: '/recently-view',
        permission: true
      },
      {
        key: 'template',
        name: '契約書テンプレート',
        icon: 'IconSingleFile',
        path: '/template',
        permission: true
      },
      {
        key: 'trash',
        name: 'ゴミ箱',
        icon: 'IconTrashCan',
        path: '/trash',
        permission: true
      }
    ];
  }

  toggleSideBar() {
    this.$emit('click');
  }

  isCurrentPath(pathName) {
    return this.$route.path === pathName;
  }

  goToLink(pathName) {
    if (this.isCurrentPath(pathName)) return;
    SearchInfo.SET_SEARCHING(false);
    SearchInfo.SET_FREE_KEYWORD('');
    Documents.getRootDirectory();
    return this.$router.push(pathName);
  }

  watchWindowWidth() {
    if (this.$route.matched.some(r => r.path === '/settings')) {
      return;
    }
    if (this.sideBarOpenStatus && window.innerWidth < 960) {
      this.toggleSideBar();
    } else if (!this.sideBarOpenStatus && window.innerWidth > 960) {
      this.toggleSideBar();
    }
  }

  created() {
    CorporateInfoModule.get_corporate_info();
  }

  mounted() {
    window.addEventListener('resize', this.watchWindowWidth);
    this.watchWindowWidth();
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.watchWindowWidth);
  }
}
