



























































import { Component, Vue } from 'vue-property-decorator';

import AppButton from '@/components/atoms/buttons/AppButton.vue';

import GMOSignInfoModule from '@/store/GMOSign';

@Component({
  components: {
    AppButton
  }
})
export default class GMOSignFormSignOrder extends Vue {
  items = [];
  contractId = null;

  async created() {
    this.contractId = this.$route.query.id;
    this.items = this.items.concat(this.our_signing_field);
    this.items = this.items.concat(this.partner_signing_field);
    this.sortItems();
  }

  get topText() {
    if (this.items.length > 1) {
      return '契約書に署名する順番を設定してください。';
    }
    return '署名者は1名のみのため、署名順の設定は不要です。';
  }

  get signForm() {
    return GMOSignInfoModule.signForm;
  }

  get our_signing_field() {
    return GMOSignInfoModule.signForm.our_signing_field;
  }
  set our_signing_field(val) {
    GMOSignInfoModule.SET_OUR_SIGNING_FIELD(val);
  }

  get partner_signing_field() {
    return GMOSignInfoModule.signForm.partner_signing_field;
  }
  set partner_signing_field(val) {
    GMOSignInfoModule.SET_PARTNER_SIGNING_FIELD(val);
  }

  sortItems() {
    this.items.sort((a, b) => a.order_no - b.order_no);
  }

  changeOrder(item, i, cal) {
    this.items[i].order_no += cal;
    if (cal > 0) {
      this.items[i + 1].order_no -= cal;
    } else {
      this.items[i - 1].order_no -= cal;
    }
    this.sortItems();
  }

  getIsOurSigner(item) {
    if ('signer_nm' in item) {
      return true;
    }
    return false;
  }

  getRole(item) {
    if (this.getIsOurSigner(item) === true) {
      return '自社署名';
    }
    return '契約相手先署名';
  }

  getName(item) {
    if (this.getIsOurSigner(item) === true) {
      return item.signer_nm;
    }
    return item.name_nm;
  }

  getCorporationName(item) {
    if (this.getIsOurSigner(item) === true) {
      return this.signForm.own_organize_nm;
    }
    return item.organize_nm;
  }

  getEmail(item) {
    if (this.getIsOurSigner(item) === true) {
      return item.sign_user.email;
    }
    return item.email;
  }

  next() {
    this.$router.push({
      name: 'gmosign_form_sign_position',
      query: {
        id: this.contractId
      }
    });
  }
}
