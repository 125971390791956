












import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

@Component({})
export default class DocumentPreview extends Vue {
  @Prop({ default: '' })
  pdfLink!: string;

  @Prop({ default: true })
  isSigned?: boolean;

  @PropSync('ifdoc', { default: null })
  iframeElement: any;

  @PropSync('el', { default: null })
  documentElement: any;

  get getFilePath() {
    return `/generic/web/viewer.html?file=${encodeURIComponent(this.pdfLink)}`;
  }

  load() {
    const el: any = document.getElementById('contract-viewer');
    this.documentElement = el;
    const ifdoc = el.contentWindow.document;
    this.iframeElement = ifdoc;
  }
}
