


















































import AppButton from "@/components/atoms/buttons/AppButton.vue";
import TextForm from "@/components/atoms/text_forms/TextForm.vue";
import TextOnlyWindow from "@/components/molecules/modal_windows/TextOnlyWindow.vue";
import { Component, Vue } from "vue-property-decorator";

import CorporateInfoModule from "@/store/CorporateInfo";

import notify from "@/functions/notify";

// import NOTIFY_TEXT from '@/consts/NotifyText';

import { RepositoryFactory } from "@/repositories/RepositoryFactory";
const SlackRepository = RepositoryFactory.get("slack");

@Component({
  components: {
    TextForm,
    AppButton,
    TextOnlyWindow,
  },
})
export default class GMOSign extends Vue {
  isOpenReleaseModal: boolean = false;

  async created() {
    await this.fetchData();
    const savedState = localStorage.getItem("slack_state");
    const currentState = this.$route.query.state;
    const redirect = this.$route.query.redirect;
    if (redirect && savedState === currentState) {
      notify.success({
        text: "Slackと連携しました。",
      });
      localStorage.removeItem("slack_state");
      return;
    }
    if (redirect && savedState !== currentState) {
      notify.error({
        text: "Slackとの連携に失敗しました。",
      });
      localStorage.removeItem("slack_state");
    }
  }

  async fetchData() {
    await CorporateInfoModule.get_corporate_info();
  }

  get is_cooperated_slack(): boolean {
    return CorporateInfoModule.is_cooperated_slack;
  }

  get corporation_id(): string {
    return CorporateInfoModule.corporation_id;
  }

  closeReleaseModal() {
    this.isOpenReleaseModal = false;
  }

  generateRandomStrings(length: number) {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let randomStrings = "";
    for (let i = 0; i < length; i++) {
      randomStrings += chars[Math.floor(Math.random() * chars.length)];
    }
    return randomStrings;
  }

  async enable() {
    const clientId = process.env.VUE_APP_CLM_SLACK_CLIENT_ID;
    const redirectUri = process.env.VUE_APP_CLM_SLACK_REDIRECT_URI;
    const scope = "chat:write,users:read,users:read.email";
    const state = this.generateRandomStrings(10);
    localStorage.setItem("slack_state", state);
    await SlackRepository.postSlackState(state);
    const url = `https://slack.com/oauth/v2/authorize?scope=${scope}&client_id=${clientId}&redirect_uri=${redirectUri}&state=${state}`;
    window.open(url, "_self");
  }

  async release(needNotify: boolean = true) {
    await SlackRepository.disconnectSlack();
    await this.fetchData();
    this.closeReleaseModal();
    if (needNotify) {
      notify.success({ text: "Slackとの連携を解除しました。" });
    }
  }
}
