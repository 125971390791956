const axios = require('axios').default;

const resource = '/user-management/gmosign';
const contract_resource = '/contract-management/gmosign';
const gmosign_resource = 'https://api.gmosign.com/agree-api/v0/api';

import { GMOSignAuth, GMOSignMember, GMOSignForm } from '@/interfaces/GMOSign';

export default {
  /**
   * GMOサインの認証情報取得
   */
  getGMOSignAuth() {
    return axios.get(`${resource}`);
  },

  /**
   * GMOサインの認証情報取得
   *
   * @param { GMOSignAuth } params
   */
  updateGMOSignAuth(params: GMOSignAuth) {
    return axios.put(`${resource}`, params);
  },

  /**
   * GMOサインの認証を解除
   */
  deleteGMOSignAuth() {
    return axios.delete(`${resource}`);
  },

  /**
   * GMOサインのメンバー取得
   */
  getGMOSignMembers() {
    return axios.get(`${resource}/members`);
  },

  /**
   * GMOサインのメンバー更新
   *
   * @param { GMOSignMember[] } params
   */
  updateGMOSignMembers(params: GMOSignMember[]) {
    return axios.put(`${resource}/members`, params);
  },

  /**
   * GMOサインのメンバー削除
   *
   * @param { GMOSignMember[] } params
   */
  deleteGMOSignMembers(params: GMOSignMember[]) {
    return axios.delete(`${resource}/members`, { data: params });
  },

  /**
   * GMOサインAPI: 電子署名処理
   *
   * @param { GMOSignForm } params
   */
  degitalSigning(params: GMOSignForm) {
    return axios.post(`${contract_resource}/${params.contract_id}`, params);
  },

  /**
   * GMOサインAPI: 署名状況の取得
   *
   * @param { string } contractId
   */
  getDocumentStatus(contractId: string) {
    return axios.get(`${contract_resource}/${contractId}/status`);
  },

  /**
   * GMOサインAPI: 署名状況の取得
   *
   * @param { string } contractId
   */
  deleteDocument(contractId: string) {
    return axios.delete(`${contract_resource}/${contractId}`);
  }

  // 以下仕様変更のためコメントアウト
  // /**
  //  * GMOサインAPI: フォーム内容の一時データ保存
  //  *
  //  * @param { GMOSignForm } params
  //  * @param { string } contractId
  //  */
  // saveFormData(contractId: string, params: GMOSignForm) {
  //   return axios.post(`${contract_resource}/${contractId}/tmp`, params);
  // },

  // /**
  //  * GMOサインAPI: フォーム内容の一時データ取得
  //  *
  //  * @param { string } contractId
  //  */
  // getFormData(contractId: string) {
  //   return axios.get(`${contract_resource}/${contractId}/tmp`);
  // },

  // /**
  //  * GMOサインAPI: フォーム内容の一時データ削除
  //  *
  //  * @param { string } contractId
  //  */
  // deleteFormData(contractId: string) {
  //   return axios.delete(`${contract_resource}/${contractId}/tmp`);
  // }
};
