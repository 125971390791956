const ATTRIBUTES = {
  TYPES: {
    text: {
      key: 'text',
      label: '自由記述',
      icon: 'i-edit.svg',
      summary: '自由にテキストを入力できます'
    },
    datetime: {
      key: 'datetime',
      label: '日付',
      icon: 'calendar-date.svg',
      summary: '任意の日付を指定できます'
    },
    select: {
      key: 'select',
      label: 'ドロップダウン',
      icon: 'circle-e-down.svg',
      summary: '作成した選択肢から１つ選べます'
    }
  },

  DEFAULT_ATTRIBUTES: [
    {
      key: '契約書No.',
      type: 'text'
    },
    {
      key: 'ラベル',
      type: 'select'
    },
    {
      key: 'ファイル',
      type: 'file'
    },
    {
      key: '契約書名',
      type: 'text'
    },
    {
      key: '相手方',
      type: 'text'
    },
    {
      key: '契約種別',
      type: 'select'
    },
    {
      key: '取引内容',
      type: 'text'
    },
    {
      key: '取引金額',
      type: 'text'
    },
    {
      key: '契約締結日',
      type: 'datetime'
    },
    {
      key: '契約開始日',
      type: 'datetime'
    },
    {
      key: '契約終了日',
      type: 'datetime'
    },
    {
      key: '自動更新',
      type: 'select'
    },
    {
      key: '更新期限日',
      type: 'datetime'
    }
  ],

  RESERVED_WORDS: ['担当者', '対応期限', '作成者']
};

export default ATTRIBUTES;
