






































































import { Component, Vue } from "vue-property-decorator";

import CorporateInfoModule from "@/store/CorporateInfo";

@Component({
  components: {},
})
export default class ExternalApps extends Vue {
  gmosign = {
    title: "GMOサイン",
    text:
      "GMOサインのAPI連携オプションを利用して、LIRISの締結管理から電子署名を行うことができます。",
    img: require("@/assets/img/gmosign_logo.svg"),
    to: "gmosign",
  };
  buntan = {
    title: "BUNTAN",
    text:
      "株式会社ＳＲＩ提供のBUNTANに、LIRISの締結済ドキュメントの情報を連携することができます。",
    img: require("@/assets/img/buntan.png"),
    to: "buntan",
  };
  slack = {
    title: "Slack",
    text:
      "SlackのワークスペースにLIRIS CLMアプリを追加して、LIRISの通知をSlackのダイレクトメッセージに送信できます。",
    img: require("@/assets/img/slack.png"),
    to: "slack",
  };

  async created() {
    await CorporateInfoModule.get_corporate_info();
  }

  get is_cooperated_gmosign(): boolean {
    return CorporateInfoModule.is_cooperated_gmosign;
  }

  get is_cooperated_buntan(): boolean {
    return CorporateInfoModule.is_cooperated_buntan;
  }

  get is_cooperated_slack(): boolean {
    return CorporateInfoModule.is_cooperated_slack;
  }

  get all_app_is_not_cooperated(): boolean {
    if (
      !this.is_cooperated_gmosign &&
      !this.is_cooperated_buntan &&
      !this.is_cooperated_slack
    ) {
      return true;
    }
    return false;
  }

  get all_app_is_cooperated(): boolean {
    if (
      this.is_cooperated_gmosign &&
      this.is_cooperated_buntan &&
      this.is_cooperated_slack
    ) {
      return true;
    }
    return false;
  }
}
