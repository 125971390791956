import { render, staticRenderFns } from "./SendEmail.vue?vue&type=template&id=421a0b84&scoped=true&lang=pug&"
import script from "./SendEmail.vue?vue&type=script&lang=ts&"
export * from "./SendEmail.vue?vue&type=script&lang=ts&"
import style0 from "./SendEmail.vue?vue&type=style&index=0&id=421a0b84&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "421a0b84",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
