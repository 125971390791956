














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TableSelectTab extends Vue {
  @Prop({ type: Object, required: true })
  tabMenus;
  @Prop({ type: String, required: true })
  selectedTab;

  handleClick(key) {
    this.$emit('click', key);
  }
}
