






















import { Component, Vue } from 'vue-property-decorator';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import Logo from '@/components/atoms/logos/Logo.vue';
import KEY_CODE from '@/consts/KeyCode';

@Component({
  components: {
    FormTitle,
    Logo
  }
})
export default class NotFound extends Vue {
  route_push_top() {
    this.$router.push({ name: 'login' });
  }

  mounted() {
    this.focusDiv();
  }

  focusDiv() {
    const div: any = this.$refs.div;
    if (!div) {
      return;
    } else {
      div.focus();
    }
  }

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.route_push_top();
    }
  }
}
