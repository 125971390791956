






































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import ContractLabels from '@/components/molecules/labels/ContractLabels.vue';
import DropdownMenuContainer from '@/components/molecules/DropdownMenuContainer.vue';
import MenuContent from '@/components/atoms/buttons/MenuContent.vue';
import UserInfo from '@/store/UserInfo';
import { GMOSignInfo } from '@/store/Documents.ts';
import dayjs from 'dayjs';

interface Card {
  assignee: { [key: string]: any };
  can_add_user: boolean;
  card_id: string;
  card_name: string;
  contract_id: string;
  counterparty_name: string;
  is_last_version: boolean;
  is_shared: boolean;
  latest_contract_version_file_extension: string;
  latest_contract_version_file_name: string;
  latest_contract_version_id: string;
  num_comment: number;
  sort_order: number;
  task_expiration_date: string;
  task_type: string;
  version: string;
  is_archived?: boolean;
  archived_at?: string;
  gmosign_info?: GMOSignInfo;
}

@Component({
  components: {
    TextAvatar,
    ImageContent,
    ContractLabels,
    DropdownMenuContainer,
    MenuContent
  }
})
export default class ContractCard extends Vue {
  @Prop()
  card: Card;

  @Prop({ default: false })
  draggable: boolean;

  MENU_ACTION = {
    PERMISSION: '閲覧権限設定',
    DOWNLOAD: 'ダウンロード',
    GMOSIGN: 'GMOサインへアップロード',
    GMOSIGN_DELETE: 'GMOサインファイル削除',
    OUTPUT_CSV: 'CSV出力',
    BACKUP: 'ファイルをバックアップ',
    ARCHIVE: 'アーカイブ',
    DELETE: '削除'
  };

  get permission() {
    return UserInfo.permission;
  }

  version() {
    const ver = this.card.version;
    const is_last_version = this.card.is_last_version;
    if (!ver) {
      return '未作成';
    } else {
      if (is_last_version) {
        return '締結済';
      } else {
        return `V${parseInt(ver)}`;
      }
    }
  }

  formatDate(date) {
    const dateDiff = this.getDateDiff(date);
    if (dateDiff === -1) {
      return '明日';
    }
    if (dateDiff === 0) {
      return '今日';
    }
    if (dateDiff === 1) {
      return '昨日';
    }
    return dayjs(date).format('M月D日');
  }

  getClassDate(date) {
    const dateDiff = this.getDateDiff(date);
    if (dateDiff === -1) {
      return 'tomorrow';
    }
    if (dateDiff === 0) {
      return 'today';
    }
    if (dateDiff >= 1) {
      return 'before';
    }
    return '';
  }

  getDateDiff(date): number {
    const itemDate = dayjs(date);
    const today = dayjs();

    // dayjs の日数の diff は 24 時間単位で測るため、日付単位に戻す必要がある
    return dayjs(today.format('YYYYMMDD')).diff(
      dayjs(itemDate.format('YYYYMMDD')),
      'day'
    );
  }

  menuSelect(menuName) {
    this.$emit('menuSelect', this.card, menuName);
  }

  get isUseGMOSign() {
    if (this.card.gmosign_info.xid != null) {
      return true;
    }
    return false;
  }

  get GMOSignTooltipText() {
    if ('gmosign_info' in this.card) {
      if (this.card.gmosign_info.document_status === '99') {
        return 'GMOサイン: 署名済み';
      } else {
        return 'GMOサイン: 未署名';
      }
    } else {
      return '';
    }
  }
}
