























import { Component, Vue, Prop, PropSync, Emit } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

interface UploadFile {
  size: number;
  name: string;
  type: string;
  file: File;
}

@Component({
  components: {
    AppButton
  }
})
export default class SameNameConfirmDialog extends Vue {
  @PropSync('showUploadDialog', { type: Boolean, default: false })
  showDialog: boolean;

  @Prop({ default: [] })
  fileList: UploadFile[];
  @Prop({ default: [] })
  currentFolderFileNames: string[];

  get sameNameFileOrFolder(): string[] {
    const sameNameList: string[] = [];
    let nameList: string[] = [];
    // ファイル名、フォルダ名の配列を作る
    this.fileList.forEach(file => {
      nameList.push(this.getFolderOrFileName(file.name));
    });
    // uniqな配列にする
    nameList = [...new Set(nameList)];
    // 同名の配列を作る
    nameList.forEach(name => {
      if (this.currentFolderFileNames.includes(name)) {
        sameNameList.push(name);
      }
    });
    return sameNameList;
  }

  getFolderOrFileName(name: string): string {
    const index = name.indexOf('/');
    if (index < 0) {
      // フォルダーではないのでファイル名を返す
      return name;
    } else {
      // フォルダー名を返す
      return name.substring(0, index);
    }
  }

  remakeFileList(fileList: UploadFile[]): UploadFile[] {
    fileList.map(file => {
      if (
        this.currentFolderFileNames.includes(
          this.getFolderOrFileName(file.name)
        )
      ) {
        file.name = this.addNumberingName(file.name);
        return file;
      } else {
        return file;
      }
    });
    return fileList;
  }

  addNumberingName(name: string): string {
    // スラッシュ / があればフォルダー。なければファイル
    if (name.includes('/')) {
      // folder
      return this.addNumberingFolderName(name);
    } else {
      // file
      return this.addNumberingFileName(name);
    }
  }

  addNumberingFolderName(name: string): string {
    const slashIndex: number = name.indexOf('/');
    // ex) file.pdf, /second_directory/file.docx
    const suffix: string = name.substring(slashIndex, name.length);
    let folderName: string = name.substring(0, slashIndex);
    // uniqなフォルダ名ならナンバリングせずに返す
    if (!this.currentFolderFileNames.includes(folderName)) {
      return folderName + suffix;
    }
    let numbering: number = 1;
    folderName = folderName + `(${numbering})`;

    while (this.currentFolderFileNames.includes(folderName)) {
      numbering++;
      // 今まで付けていたナンバリングを取る
      const index = folderName.lastIndexOf('(');
      folderName = folderName.substring(0, index);
      // 新しいナンバリングを付与
      folderName = folderName + `(${numbering})`;
    }

    return folderName + suffix;
  }

  addNumberingFileName(fileName: string): string {
    // uniqなファイル名ならナンバリングせずに返す
    if (!this.currentFolderFileNames.includes(fileName)) {
      return fileName;
    }
    const dotIndex: number = fileName.indexOf('.');
    // ex) .docx .pdf.zip
    const extensions: string = fileName.substring(dotIndex, fileName.length);
    let numbering: number = 1;
    // 拡張子を取る
    fileName = fileName.replace(extensions, '');
    fileName = fileName + `(${numbering})`;
    while (this.currentFolderFileNames.includes(fileName + extensions)) {
      numbering++;
      // 付けたナンバリングを取る
      const index = fileName.lastIndexOf('(');
      fileName = fileName.substring(0, index);
      // 新しいナンバリングを付与
      fileName = fileName + `(${numbering})`;
    }

    return fileName + extensions;
  }

  @Emit()
  upload(): object {
    this.fileList = this.remakeFileList(this.fileList);
    this.showDialog = false;

    return { fileList: this.fileList };
  }
}
