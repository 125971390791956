import store from '@/store';
import Documents from '@/store/Documents';
import Vue from 'vue';
import Vuex from 'vuex';
import {
  Action,
  getModule,
  Module,
  Mutation,
  VuexModule
} from 'vuex-module-decorators';

Vue.use(Vuex);

function toBoolean(b: any): boolean {
  if (typeof b === 'boolean') {
    return b;
  }
  if (b && String(b).toLowerCase() === 'true') {
    return true;
  }
  return false;
}

export interface SearchQuery {
  all_docs: boolean;

  // キーワード
  free_keyword: string;
  and_search: boolean;
  exclude_file_text?: boolean; // 検索対象を詳細情報に絞る

  // 契約書 No.
  control_number_start?: string;
  control_number_end?: string;

  // 契約書名
  contract_title?: string;
  contract_title_and_search?: boolean;

  // 相手方名
  counterparty_name?: string;
  counterparty_name_and_search?: boolean;
  search_null_counterparty?: boolean;

  // 契約種別
  contract_type_id?: string;
  search_null_contract_type?: boolean;

  label_ids?: any;

  // 取引金額
  transaction_amount_start?: any;
  transaction_amount_end?: any;
  search_null_transaction_amount?: boolean;

  // 契約日
  contract_date_start?: string;
  contract_date_end?: string;

  // 契約開始日
  contract_start_start?: string;
  contract_start_end?: string;

  // 契約終了日
  contract_expiration_start?: string;
  contract_expiration_end?: string;

  // 更新期限日
  update_data_start?: string;
  update_data_end?: string;

  alert_only_expirated?: boolean; // 契約終了したもののみ
  alert_only_before_expiration?: boolean; // 契約終了前アラートが表示されているもののみ
  alert_only_before_auto_update?: boolean; // 契約更新前アラートが表示されているもののみ
}

@Module({ dynamic: true, store: store, name: 'search_info', namespaced: true })
export class SearchInfo extends VuexModule {
  all_docs: boolean = false;

  free_keyword = '';
  contract_type_id: string = '';
  search_null_contract_type: boolean = false;
  label_ids: string[] = [];
  and_search: boolean = false;
  exclude_file_text: boolean = false;
  control_number_start: string = '';
  control_number_end: string = '';
  contract_title: string = '';
  contract_title_and_search: boolean = false;
  counterparty_name: string = '';
  counterparty_name_and_search: boolean = false;
  search_null_counterparty: boolean = false;
  contract_date_start: string = '';
  contract_date_end: string = '';
  contract_start_start: string = '';
  contract_start_end: string = '';
  contract_expiration_start: string = '';
  contract_expiration_end: string = '';
  update_data_start: string = '';
  update_data_end: string = '';
  alert_only_expirated: boolean = false;
  alert_only_before_expiration: boolean = false;
  alert_only_before_auto_update: boolean = false;
  searching: boolean = false;
  detailSearching: boolean = false;
  selected_tab: string = '';
  is_searching_now: boolean = false;
  is_open_contract_search_detail: boolean = false;
  transaction_amount_start: any = '';
  transaction_amount_end: any = '';
  search_null_transaction_amount: boolean = false;

  @Mutation
  SET_IS_SEARCHING_NOW(bool: boolean) {
    this.is_searching_now = bool;
  }

  @Action({ rawError: true })
  async get_query(data: SearchQuery) {
    const query: SearchQuery = {
      all_docs: toBoolean(data.all_docs),
      free_keyword: data.free_keyword || '',
      and_search: !!data.and_search
    };

    if (data.free_keyword) {
      query.exclude_file_text = data.exclude_file_text;
    }

    if (data.control_number_start || data.control_number_end) {
      query.control_number_start = data.control_number_start;
      query.control_number_end = data.control_number_end;
    }

    if (data.contract_title) {
      query.contract_title = data.contract_title;
      query.contract_title_and_search = data.contract_title_and_search;
    }

    if (data.counterparty_name) {
      query.counterparty_name = data.counterparty_name;
      query.counterparty_name_and_search = data.counterparty_name_and_search;
    }
    if (data.search_null_counterparty) {
      query.search_null_counterparty = data.search_null_counterparty;
    }

    if (data.contract_type_id) {
      query.contract_type_id = data.contract_type_id;
    }
    if (data.search_null_contract_type) {
      query.search_null_contract_type = data.search_null_contract_type;
    }

    if (data.label_ids && data.label_ids.length > 0) {
      query.label_ids = data.label_ids;
    }

    if (
      data.transaction_amount_start != null ||
      data.transaction_amount_end != null
    ) {
      query.transaction_amount_start = parseInt(
        data.transaction_amount_start.replace(/,/g, ''),
        10
      );
      query.transaction_amount_end = parseInt(
        data.transaction_amount_end.replace(/,/g, ''),
        10
      );
    }
    if (data.search_null_transaction_amount) {
      query.search_null_transaction_amount =
        data.search_null_transaction_amount;
    }

    if (data.contract_date_start || data.contract_date_end) {
      query.contract_date_start = data.contract_date_start;
      query.contract_date_end = data.contract_date_end;
    }

    if (data.contract_start_start || data.contract_start_end) {
      query.contract_start_start = data.contract_start_start;
      query.contract_start_end = data.contract_start_end;
    }

    if (data.contract_expiration_start || data.contract_expiration_end) {
      query.contract_expiration_start = data.contract_expiration_start;
      query.contract_expiration_end = data.contract_expiration_end;
    }

    if (data.update_data_start || data.update_data_end) {
      query.update_data_start = data.update_data_start;
      query.update_data_end = data.update_data_end;
    }

    if (data.alert_only_expirated) {
      query.alert_only_expirated = data.alert_only_expirated;
    }

    if (data.alert_only_before_expiration) {
      query.alert_only_before_expiration = data.alert_only_before_expiration;
    }

    if (data.alert_only_before_auto_update) {
      query.alert_only_before_auto_update = data.alert_only_before_auto_update;
    }

    return query;
  }

  @Action({ rawError: true })
  set_query(data: SearchQuery) {
    if (!data) {
      data = {
        all_docs: false,
        free_keyword: '',
        and_search: false
      };
    }
    this.SET_ALL_DOCS(toBoolean(data.all_docs));

    this.SET_FREE_KEYWORD(data.free_keyword);
    this.SET_AND_SEARCH(toBoolean(data.and_search));
    this.SET_EXCLUDE_FILE_TEXT(toBoolean(data.exclude_file_text));

    this.SET_CONTROL_NUMBER_START(data.control_number_start || '');
    this.SET_CONTROL_NUMBER_END(data.control_number_end || '');

    this.SET_CONTRACT_TITLE(data.contract_title || '');
    this.SET_CONTRACT_TITLE_AND_SEARCH(
      toBoolean(data.contract_title_and_search)
    );

    this.SET_COUNTERPARTY_NAME(data.counterparty_name || '');
    this.SET_COUNTERPARTY_NAME_AND_SEARCH(
      toBoolean(data.counterparty_name_and_search)
    );
    this.SET_SEARCH_NULL_COUNTERPARTY(toBoolean(data.search_null_counterparty));

    this.SET_CONTRACT_TYPE(data.contract_type_id || '');
    this.SET_SEARCH_NULL_CONTRACT_TYPE(
      toBoolean(data.search_null_contract_type)
    );

    if (typeof data.label_ids === 'string') {
      data.label_ids = [data.label_ids];
    }
    this.SET_LABEL_IDS(data.label_ids || '');

    if (data.transaction_amount_start != null) {
      this.SET_TRANSACTION_AMOUNT_START(data.transaction_amount_start);
    } else {
      this.SET_TRANSACTION_AMOUNT_START('');
    }
    if (data.transaction_amount_end != null) {
      this.SET_TRANSACTION_AMOUNT_END(data.transaction_amount_end);
    } else {
      this.SET_TRANSACTION_AMOUNT_END('');
    }

    this.SET_SEARCH_NULL_TRANSACTION_AMOUNT(
      toBoolean(data.search_null_transaction_amount)
    );

    this.SET_CONTRACT_START_DATE(data.contract_date_start || '');
    this.SET_CONTRACT_END_DATE(data.contract_date_end || '');

    this.SET_CONTRACT_START_START_DATE(data.contract_start_start || '');
    this.SET_CONTRACT_START_END_DATE(data.contract_start_end || '');

    this.SET_CONTRACT_EXPIRATION_START_DATE(
      data.contract_expiration_start || ''
    );
    this.SET_CONTRACT_EXPIRATION_END_DATE(data.contract_expiration_end || '');

    this.SET_UPDATE_LIMIT_START_DATE(data.update_data_start || '');
    this.SET_UPDATE_LIMIT_END_DATE(data.update_data_end || '');

    this.SET_ALERT_ONLY_EXPIRATED(toBoolean(data.alert_only_expirated));
    this.SET_ALERT_ONLY_BEFORE_EXPIRATION(
      toBoolean(data.alert_only_before_expiration)
    );
    this.SET_ALERT_ONLY_BEFORE_AUTO_UPDATE(
      toBoolean(data.alert_only_before_auto_update)
    );
  }

  get search_post_data() {
    let folder_path = '/';
    if (!this.all_docs) {
      folder_path = Documents.current_directory;
    }

    const query = {
      is_search: true,
      current_directory_path: folder_path,
      is_all_folder: this.all_docs,
      keywords_object: undefined,
      contract_title_object: undefined,
      counterparty_name_object: undefined,
      control_number_object: undefined,
      contract_type_id: this.contract_type_id,
      search_null_contract_type: this.search_null_contract_type,
      label_ids: undefined,
      contract_date_object: undefined,
      contract_start_date_object: undefined,
      expiration_object: undefined,
      auto_update_date_object: undefined,
      alert_object: undefined,
      transaction_amount: undefined
    };
    if (this.free_keyword) {
      query.keywords_object = {
        keywords: this.free_keyword,
        is_and_searching: this.and_search,
        exclude_file_text: this.exclude_file_text
      };
    }
    if (this.contract_title) {
      query.contract_title_object = {
        keywords: this.contract_title,
        is_and_searching: this.contract_title_and_search
      };
    }
    if (this.counterparty_name) {
      query.counterparty_name_object = {
        keywords: this.counterparty_name,
        is_and_searching: this.counterparty_name_and_search,
        is_null_search: this.search_null_counterparty
      };
    }
    if (this.search_null_counterparty) {
      query.counterparty_name_object = {
        is_null_search: this.search_null_counterparty
      };
    }
    if (this.label_ids && this.label_ids.length > 0) {
      query.label_ids = this.label_ids;
    }

    if (this.control_number_start || this.control_number_end) {
      query.control_number_object = {
        start: this.control_number_start,
        end: this.control_number_end
      };
    }

    if (
      parseInt(this.transaction_amount_start, 10) != null ||
      parseInt(this.transaction_amount_end, 10) != null
    ) {
      query.transaction_amount = {
        start: parseInt(this.transaction_amount_start, 10),
        end: parseInt(this.transaction_amount_end, 10)
      };
      if (parseInt(this.transaction_amount_start, 10) === 0) {
        query.transaction_amount.start = 0;
      }
      if (parseInt(this.transaction_amount_end, 10) === 0) {
        query.transaction_amount.end = 0;
      }
    }
    if (this.search_null_transaction_amount) {
      query.transaction_amount = {
        is_null_search: this.search_null_transaction_amount
      };
    }

    if (this.contract_date_start || this.contract_date_end) {
      query.contract_date_object = {
        start: this.contract_date_start
          ? this.contract_date_start.replace(/\//g, '-')
          : null,
        end: this.contract_date_end
          ? this.contract_date_end.replace(/\//g, '-')
          : null
      };
    }
    if (this.contract_start_start || this.contract_start_end) {
      query.contract_start_date_object = {
        start: this.contract_start_start
          ? this.contract_start_start.replace(/\//g, '-')
          : null,
        end: this.contract_start_end
          ? this.contract_start_end.replace(/\//g, '-')
          : null
      };
    }
    if (this.contract_expiration_start || this.contract_expiration_end) {
      query.expiration_object = {
        start: this.contract_expiration_start
          ? this.contract_expiration_start.replace(/\//g, '-')
          : null,
        end: this.contract_expiration_end
          ? this.contract_expiration_end.replace(/\//g, '-')
          : null
      };
    }
    if (this.update_data_start || this.update_data_end) {
      query.auto_update_date_object = {
        start: this.update_data_start
          ? this.update_data_start.replace(/\//g, '-')
          : null,
        end: this.update_data_end
          ? this.update_data_end.replace(/\//g, '-')
          : null
      };
    }
    if (
      this.alert_only_expirated ||
      this.alert_only_before_expiration ||
      this.alert_only_before_auto_update
    ) {
      query.alert_object = {
        only_expirated: this.alert_only_expirated,
        only_alert_before_expiration: this.alert_only_before_expiration,
        only_alert_before_auto_update: this.alert_only_before_auto_update
      };
    }

    return query;
  }

  @Mutation
  SET_TRANSACTION_AMOUNT_START(value: any) {
    if (!Number.isNaN(value)) {
      this.transaction_amount_start = value;
    }
  }

  @Mutation
  SET_TRANSACTION_AMOUNT_END(value: any) {
    if (!Number.isNaN(value)) {
      this.transaction_amount_end = value;
    }
  }

  @Mutation
  SET_SEARCH_NULL_TRANSACTION_AMOUNT(bool: boolean) {
    this.search_null_transaction_amount = bool;
  }

  @Mutation
  SET_FREE_KEYWORD(text: string) {
    this.free_keyword = text;
  }

  // 検索状態の有無
  @Mutation
  SET_SEARCHING(bool: boolean) {
    this.searching = bool;
  }

  @Mutation
  SET_DETAIL_SEARCHING(bool: boolean) {
    this.detailSearching = bool;
  }

  @Mutation
  SET_AND_SEARCH(and_search: boolean) {
    this.and_search = and_search;
  }

  @Mutation
  SET_ALL_DOCS(all_docs: boolean) {
    this.all_docs = all_docs;
  }

  @Mutation
  SET_EXCLUDE_FILE_TEXT(exclude: boolean) {
    this.exclude_file_text = exclude;
  }

  @Mutation
  SET_CONTROL_NUMBER_START(no: string) {
    this.control_number_start = no;
  }

  @Mutation
  SET_CONTROL_NUMBER_END(no: string) {
    this.control_number_end = no;
  }

  @Mutation
  SET_CONTRACT_TYPE(contract_type_id: string) {
    this.contract_type_id = contract_type_id;
  }

  @Mutation
  SET_SEARCH_NULL_CONTRACT_TYPE(search_null_contract_type: boolean) {
    this.search_null_contract_type = search_null_contract_type;
  }

  @Mutation
  SET_LABEL_IDS(label_ids: string[]) {
    this.label_ids = label_ids;
  }

  @Mutation
  SET_CONTRACT_TITLE(contract_title: string) {
    this.contract_title = contract_title;
  }

  @Mutation
  SET_CONTRACT_TITLE_AND_SEARCH(and_search: boolean) {
    this.contract_title_and_search = and_search;
  }

  @Mutation
  SET_COUNTERPARTY_NAME(name: string) {
    this.counterparty_name = name;
  }

  @Mutation
  SET_COUNTERPARTY_NAME_AND_SEARCH(and_search: boolean) {
    this.counterparty_name_and_search = and_search;
  }

  @Mutation
  SET_SEARCH_NULL_COUNTERPARTY(bool: boolean) {
    this.search_null_counterparty = bool;
  }

  @Mutation
  SET_CONTRACT_START_DATE(date: string) {
    this.contract_date_start = date;
  }

  @Mutation
  SET_CONTRACT_END_DATE(date: string) {
    this.contract_date_end = date;
  }

  @Mutation
  SET_CONTRACT_START_START_DATE(date: string) {
    this.contract_start_start = date;
  }

  @Mutation
  SET_CONTRACT_START_END_DATE(date: string) {
    this.contract_start_end = date;
  }

  @Mutation
  SET_CONTRACT_EXPIRATION_START_DATE(date: string) {
    this.contract_expiration_start = date;
  }

  @Mutation
  SET_CONTRACT_EXPIRATION_END_DATE(date: string) {
    this.contract_expiration_end = date;
  }

  @Mutation
  SET_UPDATE_LIMIT_START_DATE(date: string) {
    this.update_data_start = date;
  }

  @Mutation
  SET_UPDATE_LIMIT_END_DATE(date: string) {
    this.update_data_end = date;
  }

  @Mutation
  SET_ALERT_ONLY_EXPIRATED(expired: boolean) {
    this.alert_only_expirated = expired;
  }

  @Mutation
  SET_ALERT_ONLY_BEFORE_EXPIRATION(before_expiration: boolean) {
    this.alert_only_before_expiration = before_expiration;
  }

  @Mutation
  SET_ALERT_ONLY_BEFORE_AUTO_UPDATE(before_auto_apdate: boolean) {
    this.alert_only_before_auto_update = before_auto_apdate;
  }

  @Mutation
  SET_SELECTED_TAB(selectedTab: string) {
    this.selected_tab = selectedTab;
  }

  @Mutation
  SET_OPEN_CONTRACT_SEARCH_DETAIL(val: boolean) {
    this.is_open_contract_search_detail = val;
  }
}

const SearchInfoModule = getModule(SearchInfo);

export default SearchInfoModule;
