import { render, staticRenderFns } from "./DocumentInfoContract.vue?vue&type=template&id=32bd2923&scoped=true&lang=pug&"
import script from "./DocumentInfoContract.vue?vue&type=script&lang=ts&"
export * from "./DocumentInfoContract.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentInfoContract.vue?vue&type=style&index=0&id=32bd2923&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32bd2923",
  null
  
)

export default component.exports