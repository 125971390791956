















































































































import { Component, Vue } from 'vue-property-decorator';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import AddUsersWithGMOSignWindow from '@/components/molecules/modal_windows/AddUsersWithGMOSignWindow.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import GMOSignMemberTable from '@/components/organisms/Settings/GMOSIgnMemberTable.vue';

import { GMOSignAuth, GMOSignMember } from '@/interfaces/GMOSign';

import GMOSignInfoModule from '@/store/GMOSign';
import SettingsInfoModule from '@/store/SettingsInfo';

import notify from '@/functions/notify';

import NOTIFY_TEXT from '@/consts/NotifyText';

@Component({
  components: {
    TextForm,
    AppButton,
    AddUsersWithGMOSignWindow,
    GMOSignMemberTable,
    TextOnlyWindow
  }
})
export default class GMOSign extends Vue {
  isEnabled: boolean = false;
  isOpenAddUsersModal: boolean = false;
  isOpenDeleteUsersModal: boolean = false;
  isOpenReleaseModal: boolean = false;
  isLoading: boolean = false;

  async created() {
    await GMOSignInfoModule.getGMOSignAuth();
    await GMOSignInfoModule.getGMOSignMembers();
  }

  beforeDestroy() {
    this.resetForm();
    GMOSignInfoModule.RESET_SELECTED_GMOSIGN_USERS();
    GMOSignInfoModule.RESET_CURRENT_GMOSIGN_USERS();
  }

  get gmosignAuth(): GMOSignAuth {
    return GMOSignInfoModule.gmosignAuth;
  }
  set gmosignAuth(value: GMOSignAuth) {
    GMOSignInfoModule.SET_GMOSIGN_AUTH(value);
  }

  get selectableUsers(): any {
    return SettingsInfoModule.users_corporations_same_me;
  }

  get selected_users_corporations_same_me() {
    return SettingsInfoModule.selected_users_corporations_same_me;
  }

  get gmosign_users() {
    return GMOSignInfoModule.gmosign_users;
  }

  get current_gmosign_users() {
    return GMOSignInfoModule.current_gmosign_users;
  }
  set current_gmosign_users(value: GMOSignMember[]) {
    GMOSignInfoModule.SET_CURRENT_GMOSIGN_USERS(value);
  }

  get existCurrentGMOSignUsers() {
    if (this.current_gmosign_users.length) {
      return true;
    }
    return false;
  }

  get selected_gmosign_users() {
    return GMOSignInfoModule.selected_gmosign_users;
  }

  get existSelectedGMOSignUsers() {
    if (this.selected_gmosign_users.length > 0) {
      return true;
    }
    return false;
  }

  resetForm() {
    GMOSignInfoModule.SET_GMOSIGN_AUTH({});
  }

  validate() {
    if (
      this.gmosignAuth.gmosign_customer_id &&
      this.gmosignAuth.gmosign_secret_key
    ) {
      this.isEnabled = true;
      return;
    }
    this.isEnabled = false;
  }

  async cooperateGMOSign() {
    await GMOSignInfoModule.updateGMOSignAuth(this.gmosignAuth)
      .then(() => {
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.GMOSIGN.COOPERATED
        });
      })
      .catch(() => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GMOSIGN.COOPERATED
        });
      });
  }

  async releaseGMOSign() {
    this.resetForm();
    await GMOSignInfoModule.deleteGMOSignMembers(this.current_gmosign_users);
    await GMOSignInfoModule.deleteGMOSignAuth()
      .then(() => {
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.GMOSIGN.RELEASED
        });
      })
      .catch(() => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GMOSIGN.RELEASED
        });
      });
    this.closeReleaseModal();
  }

  async openAddUsersModal() {
    this.isOpenAddUsersModal = true;
    await SettingsInfoModule.get_users_corporations_same_me(3);
  }

  closeAddUsersModal() {
    this.isOpenAddUsersModal = false;
    GMOSignInfoModule.RESET_GMOSIGN_USERS();
  }

  async openDeleteUsersModal() {
    this.isOpenDeleteUsersModal = true;
  }

  closeDeleteUsersModal() {
    this.isOpenDeleteUsersModal = false;
    GMOSignInfoModule.RESET_SELECTED_GMOSIGN_USERS();
  }

  removeSelectedUser(user) {
    SettingsInfoModule.REMOVE_SELECTED_USERS_CORPORATIONS_SAME_ME(user);
  }

  async openReleaseModal() {
    this.isOpenReleaseModal = true;
  }

  closeReleaseModal() {
    this.isOpenReleaseModal = false;
    GMOSignInfoModule.RESET_SELECTED_GMOSIGN_USERS();
  }

  /**
   * AddMemberWindow の複数ユーザー追加ボタン押下時
   */
  clickMore() {
    GMOSignInfoModule.ADD_GMOSIGN_USERS();
  }

  clickRemove(index) {
    GMOSignInfoModule.REMOVE_GMOSIGN_USERS(index);
  }

  /**
   * AddMemberWindow, AddUsersForDepartmentWindow の送信ボタン押下時
   */
  async clickAdd() {
    await GMOSignInfoModule.updateGMOSignMembers(this.gmosign_users)
      .then(res => {
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.GMOSIGN.ADDUSERS
        });
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GMOSIGN.ADDUSERS
        });
      });
    this.closeAddUsersModal();
  }

  setGMOSignUserName(payload) {
    GMOSignInfoModule.SET_GMOSIGN_USER_NAME(payload);
  }

  setUser(payload) {
    GMOSignInfoModule.SET_GMOSIGN_LIRIS_USER(payload);
  }

  setIsSignRole(payload) {
    GMOSignInfoModule.SET_GMOSIGN_SIGN_ROLE(payload);
  }

  deleteGMOSignUsers() {
    GMOSignInfoModule.deleteGMOSignMembers(this.selected_gmosign_users)
      .then(res => {
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.GMOSIGN.DELETEUSERS
        });
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GMOSIGN.DELETEUSERS
        });
      });
    this.closeDeleteUsersModal();
  }
}
