





































import { Component, Vue } from 'vue-property-decorator';
import DocumentPreviewSidebarComment from '@/components/organisms/DocumentDetail/DocumentPreview/DocumentPreviewSidebarComment.vue';
import DocumentPreviewSidebarProperty from '@/components/organisms/DocumentDetail/DocumentPreview/DocumentPreviewSidebarProperty.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';

@Component({
  components: {
    DocumentPreviewSidebarComment,
    DocumentPreviewSidebarProperty,
    ImageContent
  }
})
export default class DocumentTabBar extends Vue {
  selectedContent: string = 'DocumentPreviewSidebarProperty';
  isShowingSidebar: boolean = false;

  selectTab(val: string) {
    this.selectedContent = val;
    this.isShowingSidebar = true;
  }

  toggleShowingSidebar() {
    this.isShowingSidebar = !this.isShowingSidebar;
    this.$emit('toggle', this.isShowingSidebar);
  }
}
