









































































































import { Component, Vue } from 'vue-property-decorator';

import CorporateInfoModule from '@/store/CorporateInfo';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

const axios = require('axios').default;

import SettingsInfoModule from '@/store/SettingsInfo';
import notify from '@/functions/notify';
import {
  validateCorporateTelNumber,
  validateCorporateFaxNumber
} from '@/functions/Validation';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const UserInfoRepository = RepositoryFactory.get('userInfos');

import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    FormTitle,
    SelectBox,
    AppButton,
    TextForm,
    TextOnlyWindow
  }
})
export default class CorporateInformationForm extends Vue {
  update_corporate_name: string = '';
  update_corporate_name_furigana: string = '';
  update_zipcode: string = '';
  update_street_address: string = '';
  update_corporate_tel: string = '';
  update_corporate_fax: string = '';
  update_employees: any = {
    employee_count_name: ''
  };
  update_industry: any = {
    business_category_name: ''
  };

  corporate_name_error_message: string = '';
  corporate_name_furigana_error_message: string = '';
  zipcode_error_message: string = '';
  street_address_error_message: string = '';
  corporate_tel_error_message: string = '';
  corporate_fax_error_message: string = '';

  initialFormValue = {
    corporate_name: '',
    corporate_name_furigana: '',
    zipcode: '',
    street_address: '',
    corporate_tel: '',
    corporate_fax: '',
    industry_id: '',
    employees_id: ''
  };

  form_dirty: boolean = false;
  route_leave_with_dirty: object = null;

  async created() {
    await SettingsInfoModule.get_industry_options().catch(e =>
      notify.error({ text: '業種一覧を取得できませんでした。' })
    );
    await SettingsInfoModule.get_employees_count().catch(e =>
      notify.error({ text: '従業員数一覧を取得できませんでした。' })
    );
  }

  async set_values() {
    await CorporateInfoModule.get_corporate_info();
    this.update_corporate_name = this.initialFormValue.corporate_name =
      CorporateInfoModule.corporate_name;
    this.update_corporate_name_furigana = this.initialFormValue.corporate_name_furigana =
      CorporateInfoModule.corporate_name_furigana;
    this.update_zipcode = this.initialFormValue.zipcode =
      CorporateInfoModule.zipcode;
    this.update_street_address = this.initialFormValue.street_address =
      CorporateInfoModule.street_address;
    this.update_corporate_tel = this.initialFormValue.corporate_tel =
      CorporateInfoModule.corporate_tel;
    this.update_corporate_fax = this.initialFormValue.corporate_fax =
      CorporateInfoModule.corporate_fax;
    this.update_industry = CorporateInfoModule.business_category;
    this.initialFormValue.industry_id =
      CorporateInfoModule.business_category.business_category_id;
    this.update_employees = CorporateInfoModule.employee_count;
    this.initialFormValue.employees_id =
      CorporateInfoModule.employee_count.employee_count_id;
  }

  async mounted() {
    this.set_values();
  }

  async updatedZipcode(newValue) {
    if (newValue.length === 7 && isFinite(Number(newValue))) {
      UserInfoRepository.getAddressFromZipcode(newValue)
        .then(response => {
          if (response.status == HTTP_STATUS.OK) {
            if (
              response.data &&
              (!this.update_street_address ||
                this.update_street_address.indexOf(response.data) != 0)
            ) {
              this.update_street_address = response.data;
            }
          } else {
            notify.error({
              text: NOTIFY_TEXT.ERROR.GENERAL
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
      this.onChange();
    }
  }

  get industry_options() {
    return SettingsInfoModule.industry_options;
  }

  get employee_counts() {
    return SettingsInfoModule.employee_counts;
  }

  reset_error_message() {
    this.corporate_name_error_message = '';
    this.corporate_name_furigana_error_message = '';
    this.zipcode_error_message = '';
    this.street_address_error_message = '';
    this.corporate_tel_error_message = '';
  }

  onChange() {
    this.form_dirty = true;
  }

  get isDirty() {
    return (
      this.form_dirty &&
      Object.keys(this.initialFormValue).some(k => {
        if (k === 'industry_id') {
          return (
            this.update_industry.business_category_id !==
            this.initialFormValue.industry_id
          );
        } else if (k === 'employees_id') {
          return (
            this.update_employees.employee_count_id !==
            this.initialFormValue.employees_id
          );
        }
        return this[`update_${k}`] !== this.initialFormValue[k];
      })
    );
  }

  async update_corporate_info() {
    this.reset_error_message();

    let error: boolean = false;

    if (!this.update_corporate_name) {
      this.corporate_name_error_message = '会社名を入力してください。';
      error = true;
    }
    if (!this.update_corporate_name_furigana) {
      this.corporate_name_furigana_error_message =
        '会社名ふりがなを入力してください。';
      error = true;
    }
    if (!this.update_zipcode) {
      this.zipcode_error_message = '郵便番号を入力してください。';
      error = true;
    }
    if (!this.update_street_address) {
      this.street_address_error_message = '住所を入力してください。';
      error = true;
    }
    if (
      this.update_zipcode.length < 7 ||
      !isFinite(Number(this.update_zipcode))
    ) {
      this.zipcode_error_message = '郵便番号は半角数字７桁で入力してください。';
      error = true;
    }

    // 電話番号のバリデーション
    this.corporate_tel_error_message = validateCorporateTelNumber(
      this.update_corporate_tel
    );
    if (this.corporate_tel_error_message) {
      error = true;
    }

    // FAXのバリデーション
    this.corporate_fax_error_message = validateCorporateFaxNumber(
      this.update_corporate_fax
    );
    if (this.corporate_fax_error_message) {
      error = true;
    }

    if (!error) {
      const url = '/user-management/corporations';
      const request_data = {
        corporation_name: this.update_corporate_name,
        furigana: this.update_corporate_name_furigana,
        postal_code: this.update_zipcode,
        address: this.update_street_address,
        phone_number: this.update_corporate_tel,
        employee_count_id: this.update_employees.employee_count_id,
        business_category_id: this.update_industry.business_category_id,
        fax: this.update_corporate_fax
      };

      await axios
        .put(url, request_data)
        .then(res => {
          if (res.status !== HTTP_STATUS.OK) {
            notify.error({
              text: '会社情報を更新できませんでした'
            });
            return;
          }

          CorporateInfoModule.SET_CORPORATE_NAME(this.update_corporate_name);
          CorporateInfoModule.SET_CORPORATE_NAME_FURIGANA(
            this.update_corporate_name_furigana
          );
          CorporateInfoModule.SET_ZIPCODE(this.update_zipcode);
          CorporateInfoModule.SET_STREET_ADDRESS(this.update_street_address);
          CorporateInfoModule.SET_CORPORATE_TEL(this.update_corporate_tel);
          CorporateInfoModule.SET_CORPORATE_FAX(this.update_corporate_fax);
          CorporateInfoModule.SET_EMPLOYEE_COUNT(this.update_employees);
          CorporateInfoModule.SET_BUSINESS_CATEGORY(this.update_industry);

          // 更新後の処理が必要なら書く
          notify.success({
            text: '会社情報を更新しました'
          });

          // 初期値更新
          this.set_values();
          this.form_dirty = false;
        })
        .catch(err => {
          notify.error({
            text: '会社情報を更新できませんでした'
          });
        });
    }
  }

  setIndustry(val) {
    this.onChange();
    if (!val) {
      this.update_industry = {
        business_category_id: '',
        business_category_name: ''
      };
    }
  }

  setEmployees(val) {
    this.onChange();
    if (!val) {
      this.update_employees = {
        employee_count_id: '',
        employee_count_name: ''
      };
    }
  }

  get isLeaveWithDirty() {
    return this.route_leave_with_dirty !== null;
  }

  beforeRouteLeave(to, from, next) {
    if (this.form_dirty) {
      this.route_leave_with_dirty = to;
      return;
    }
    next();
  }

  leaveAndDiscard() {
    this.form_dirty = false;
    this.$router.push(this.route_leave_with_dirty);
    this.route_leave_with_dirty = null;
  }

  cancelLeave() {
    this.route_leave_with_dirty = null;
  }
}
