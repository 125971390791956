import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';
import MAGICBYTE from '@/consts/MagicByte';

interface DropFile {
  size: number;
  name: string;
  type: string;
  file: File;
}

/**
 * ファイルサイズチェック
 * @param fileSize number
 */
function checkAllowedFileSize(fileSize: number): boolean {
  if (fileSize >= 10 * 1024 * 1024) {
    notify.error({
      text: NOTIFY_TEXT.ERROR.OVERSIZE_UPLOAD_SUFFIX
    });
    return false;
  }
  return true;
}

function buf2hex(buffer) {
  // buffer is an ArrayBuffer
  return Array.prototype.map
    .call(new Uint8Array(buffer), x => ('00' + x.toString(16)).slice(-2))
    .join('');
}

async function isFakeExtension(files: any) {
  return await Promise.all(
    files.map(async x => {
      const file = x.file;
      return {
        filename: file.name,
        buffer: await file.arrayBuffer()
      };
    })
  ).then(async (items: any[]) => {
    return await items.some(item => {
      const { filename, buffer } = item;
      const hex = buf2hex(buffer);
      const magicNum = hex.slice(0, 10);
      const includePdf = /\.pdf$/;
      const includeDocx = /\.docx$/;
      const includeDoc = /\.doc$/;
      const includeXlsx = /\.xlsx$/;
      const includeXls = /\.xls$/;

      if (
        (MAGICBYTE.DOCX.includes(magicNum) && includeDocx.test(filename)) ||
        (MAGICBYTE.XLSX.includes(magicNum) && includeXlsx.test(filename)) ||
        (magicNum == MAGICBYTE.DOC && includeDoc.test(filename)) ||
        (magicNum == MAGICBYTE.XLS && includeXls.test(filename)) ||
        (magicNum == MAGICBYTE.PDF && includePdf.test(filename))
      ) {
        return false;
      } else {
        notify.error({
          text: `${filename} ${NOTIFY_TEXT.ERROR.FAKE_EXTENSION}`
        });
        return true;
      }
    });
  });
}

/**
 * アップロードが可能なファイルか判定
 * @param files any
 * @param errorMessage string
 */
function checkAllowedUploadFile(files: any, errorMessage: string): boolean {
  // アップロード可能なファイルがない場合は拡張子エラースナックバー表示
  if (!files.length) {
    notify.error({
      text: errorMessage
    });
    return false;
  }

  // アップロードされたファイルが全てアップロード可能サイズを超えている場合はサイズエラースナックバー表示
  const oversizeFiles = files.filter(x => x.size > 10 * 1024 * 1024);
  if (files.length === oversizeFiles.length) {
    notify.error({
      text: NOTIFY_TEXT.ERROR.OVERSIZE_UPLOAD_SUFFIX
    });
    return false;
  }
  if (files.length === oversizeFiles.length && oversizeFiles.length) {
    notify.error({
      text: NOTIFY_TEXT.ERROR.OVERSIZE_UPLOAD_SUFFIX
    });
  }

  return true;
}

/**
 * 転送データを追加
 * @param dataTransfer
 */
function addDataTransfer(dataTransfer: any): Promise<DropFile[]> {
  const files = [];
  if (dataTransfer.items && dataTransfer.items.length) {
    const items = [];
    for (let i = 0; i < dataTransfer.items.length; i++) {
      let item = dataTransfer.items[i];
      if (item.getAsEntry) {
        item = item.getAsEntry() || item.getAsFile();
      } else if (item.webkitGetAsEntry) {
        item = item.webkitGetAsEntry() || item.getAsFile();
      } else {
        item = item.getAsFile();
      }
      if (item) {
        items.push(item);
      }
    }
    return new Promise((resolve, reject) => {
      const forEach = i => {
        const item = items[i];

        if (!item) {
          return resolve(files);
        }
        this.getEntry(item).then(function(results: any) {
          files.push(...results);
          forEach(i + 1);
        });
      };
      forEach(0);
    });
  }
  if (dataTransfer.files.length) {
    for (let i = 0; i < dataTransfer.files.length; i++) {
      files.push(dataTransfer.files[i]);

      break;
    }
    return Promise.resolve(files);
  }
  return Promise.resolve([]);
}

function getEntry(entry, path = '') {
  return new Promise((resolve, reject) => {
    if (entry.isFile) {
      entry.file(function(file) {
        resolve([
          {
            size: file.size,
            name: path + file.name,
            type: file.type,
            file
          }
        ]);
      });
    } else if (entry.isDirectory) {
      const files = [];
      const dirReader = entry.createReader();
      const readEntries = () => {
        dirReader.readEntries(entries => {
          const forEach = i => {
            if (!entries[i] && i === 0) {
              return resolve(files);
            }
            if (!entries[i]) {
              return readEntries();
            }
            this.getEntry(entries[i], path + entry.name + '/').then(
              (results: any) => {
                files.push(...results);
                forEach(i + 1);
              }
            );
          };
          forEach(0);
        });
      };
      readEntries();
    } else {
      resolve([]);
    }
  });
}

/**
 * DnDされたアイテムにフォルダが含まれているかチェック
 *
 * @param dataTransfer
 */
function checkIncludeDirectory(dataTransfer: any): boolean {
  if (dataTransfer.items && dataTransfer.items.length) {
    const items = [];
    for (let i = 0; i < dataTransfer.items.length; i++) {
      let item = dataTransfer.items[i];
      if (item.getAsEntry) {
        item = item.getAsEntry() || item.getAsFile();
      } else if (item.webkitGetAsEntry) {
        item = item.webkitGetAsEntry() || item.getAsFile();
      } else {
        item = item.getAsFile();
      }
      if (item) {
        items.push(item);
      }
    }

    return items.some(x => x.isDirectory);
  }
}

export default {
  checkAllowedUploadFile,
  addDataTransfer,
  getEntry,
  checkAllowedFileSize,
  checkIncludeDirectory,
  isFakeExtension
};
