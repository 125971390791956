<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 15.207 15.207"
  >
    <g transform="translate(.5 .5)">
      <circle
        :class="`is_${color}`"
        class="icon_path"
        cx="6"
        cy="6"
        r="6"
        data-name="楕円形 2"
      />
      <path
        :class="`is_${color}`"
        class="icon_path"
        d="M3.758 3.758L0 0"
        data-name="線 7"
        transform="translate(10.242 10.242)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconZoomOut',
  props: {
    color: {
      type: String,
      default: 'gray',
      validator(value) {
        return ['gray', 'white', ''].includes(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.icon_path {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 1;
}

.is_gray {
  stroke: #8a8f99;
}

.is_white {
  stroke: #ffffff;
}
</style>
