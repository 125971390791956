





















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import SelectedItemsView from '@/components/molecules/SelectedItemsView.vue';
import MakeBreadCrumb from '@/components/atoms/breadcrumb/MakeBreadCrumb.vue';
import Documents from '@/store/Documents';
import UserInfo from '@/store/UserInfo';
import LAYOUT_UTIL from '@/consts/LayoutUtil';

@Component({
  components: {
    AppButton,
    SelectedItemsView,
    MakeBreadCrumb
  }
})
export default class TrashBodyHeader extends Vue {
  @Prop({ default: true })
  side_bar_status?: boolean;

  @Prop()
  files_length: number;

  @Prop()
  folders_length: number;

  @Prop()
  selectedFolder: any;

  @Prop()
  breadcrumbData: any;

  get permission() {
    return UserInfo.permission;
  }

  get num_files(): number {
    return this.files_length;
  }

  get num_folders(): number {
    return this.folders_length;
  }

  get trashes() {
    return Documents.trashes;
  }

  get is_empty(): boolean {
    if (this.num_files === 0 && this.num_folders === 0) {
      return true;
    }
    return false;
  }

  // TODO: MakeBreadCrumb を使うため、trash の元データの key を置換している。処理場、key 名が現在の名称である必要はなさそうなので、元データの key を変更する。
  get convertedBreadcrumbData() {
    const firstDirectory = {
      path: '/',
      name: 'ゴミ箱'
    };
    if (!this.breadcrumbData) {
      return firstDirectory;
    }
    const remainingDirectory = this.breadcrumbData.map(data => {
      return {
        path: data.directory_path,
        name: data.trash_directory_name
      };
    });
    return [firstDirectory, ...remainingDirectory];
  }

  @Watch('side_bar_status')
  left_position() {
    let left = LAYOUT_UTIL.SIDE_MENU.WIDTH.OPEN;
    if (!this.side_bar_status) {
      left = LAYOUT_UTIL.SIDE_MENU.WIDTH.CLOSE;
    }
    return {
      left: String(left) + 'px'
    };
  }
}
