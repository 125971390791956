














import { Component, Vue } from 'vue-property-decorator';
import ImageContent from '@/components/atoms/images/ImageContent.vue';

@Component({
  components: {
    ImageContent
  }
})
export default class DragAndDropImage extends Vue {}
