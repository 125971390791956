const axios = require('axios').default;

const resource = '/contract-management/associations';

type AssociatedContract = {
  associated_contract_id: string;
  contract_title: string;
  file_name: string;
};

type CreateParams = {
  select_contracts: AssociatedContract[];
  contract_id: string;
  contract_title: string;
  file_extension: string;
  file_name: string;
  file_path: string;
};

type DeleteParams = {
  contract_id: string;
  delete_associated_contract_id: string;
};

export default {
  /**
   * 作成
   *
   * @param { Object } params
   */
  create(params: CreateParams) {
    return axios.post(resource, params.select_contracts);
  },

  /**
   * 削除
   *
   * @param { Object } params
   */
  delete(params: DeleteParams) {
    return axios.delete(`${resource}/contracts/${params.contract_id}`, {
      params: {
        delete_associated_contract_id: params.delete_associated_contract_id
      }
    });
  },

  /**
   * 関連契約書情報取得
   */
  getCandidates() {
    return axios.get(`${resource}/candidates`);
  }
};
