








import { Component, Vue } from 'vue-property-decorator';
import SettingsAccountsHeader from '@/components/molecules/settings_headers/SettingsAccountsHeader.vue';
import AccountsInput from '@/components/organisms/Settings/AccountsInput.vue';

@Component({
  components: {
    SettingsAccountsHeader,
    AccountsInput
  }
})
export default class Accounts extends Vue {}
