

























import { Component, Vue, Prop, Emit } from 'vue-property-decorator';

@Component({})
export default class AppCalendar extends Vue {
  @Prop({ default: '' })
  value!: string;

  @Prop({ default: '' })
  name!: string;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: () => ({ 'flat-input': true }) })
  classes?: object;

  @Prop({ default: 'calendar-tooltip' })
  tooltipClasses?: string;

  @Emit()
  input($event) {
    this.blur($event);
    return $event.target.value;
  }

  change($event) {
    // 1970-01-01 より前の日付の場合はイベントを発火しない
    if (new Date($event.target.value) < new Date(0)) {
      return;
    }
    this.$emit('change', $event, {
      [this.name]: $event.target.value
    });
  }

  blur($event) {
    // 1970-01-01 より前の日付の場合はイベントを発火しない
    if (new Date($event.target.value) < new Date(0)) {
      return;
    }
    this.$emit('blur', $event, {
      [this.name]: $event.target.value
    });
  }
}
