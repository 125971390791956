














import { Component, Vue } from 'vue-property-decorator';
import UsageRateBar from '@/components/atoms/progress_bars/UsageRateBar.vue';
import CorporateInfoModule from '@/store/CorporateInfo';
import ServicePlanAlert from '@/components/molecules/ServicePlanAlert.vue';

@Component({
  components: {
    UsageRateBar,
    ServicePlanAlert
  }
})
export default class FileUploadedDisplay extends Vue {
  get alert() {
    return CorporateInfoModule.upload_size_alert;
  }

  get corporate_name() {
    return CorporateInfoModule.corporate_name;
  }

  get max_file_size() {
    const giga_byte =
      CorporateInfoModule.service_plans.max_upload_file_size / 1024 ** 3;
    return Math.round(giga_byte * 10) / 10;
  }
  get total_uploaded_file_size() {
    const mega_byte = CorporateInfoModule.total_file_size / 1024 ** 2;
    return Math.floor(mega_byte * 10) / 10;
  }
}
