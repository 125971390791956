


















































































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import UserInfo from '@/store/UserInfo';
import AUTHORITIES from '@/consts/Authorities.js';
import SettingsInfoModule from '@/store/SettingsInfo';

interface Folder {
  directory_name: string;
  directory_path: string;
}

interface ColumnItem {
  icon_type: string;
  icon_with_label: string;
  key: string;
  label: string;
  label_color: string;
  link: string;
  type: string;
  value: string;
}

@Component({
  components: {
    BaseTable
  }
})
export default class TableWrapper extends Vue {
  @Prop({ default: () => [] })
  tableHeadData;
  @PropSync('tableBodyData', { type: Array, default: () => [] })
  filesAndFolders: ColumnItem[][];
  @Prop({ default: false })
  headerChecked: boolean;
  @Prop({ default: () => {} })
  permission;
  @Prop({ default: false })
  isLirisTemplate: boolean;

  folderDragOveredIndex: number = null;
  innerFlag: boolean = false;
  sortedFilesAndFolders: ColumnItem[][] = null;

  @Watch('filesAndFolders')
  updateFilesAndFolders(val) {
    this.sortTableBodyData();
  }

  get sort(): {
    columnName: string;
    asc: boolean;
  } {
    return SettingsInfoModule.sort[this.$route.name];
  }

  beforeCreate() {
    if (!SettingsInfoModule.sort[this.$route.name]) {
      SettingsInfoModule.SET_SORT({
        pageName: this.$route.name,
        columnName: 'name'
      });
    }
  }

  mounted() {
    this.sortTableBodyData();
  }

  setSort(columnName: string) {
    SettingsInfoModule.SET_SORT({ pageName: this.$route.name, columnName });
    this.sortTableBodyData();
  }

  sortTableBodyData(): void {
    // フォルダとファイルを分ける
    const folders: ColumnItem[][] = this.filesAndFolders.filter(
      (items: ColumnItem[]) =>
        items.some((item: ColumnItem) => item.key === 'folder_name')
    );

    const files: ColumnItem[][] = this.filesAndFolders.filter(
      (items: ColumnItem[]) =>
        items.some((item: ColumnItem) => item.key === 'name')
    );

    // item[index][key]でソートする必要があるので先にindexを算出する
    const index = this.filesAndFolders[0].findIndex((item: ColumnItem) => {
      // 'name'の時はフォルダ/契約書名のカラムを見ている
      if (this.sort.columnName === 'name')
        return item.key === this.sort.columnName || item.key === 'folder_name';
      else return item.key === this.sort.columnName;
    });

    // keyがcolumnNameのlabelでソートする
    folders.sort((a: ColumnItem[], b: ColumnItem[]) => {
      if (a[index]['label'] < b[index]['label']) return this.sort.asc ? -1 : 1;
      if (a[index]['label'] > b[index]['label']) return this.sort.asc ? 1 : -1;
      return 1;
    });

    files.sort((a, b) => {
      if (a[index]['label'] < b[index]['label']) return this.sort.asc ? -1 : 1;
      if (a[index]['label'] > b[index]['label']) return this.sort.asc ? 1 : -1;
      return 1;
    });

    this.sortedFilesAndFolders = folders.concat(files);
  }

  get isGuest() {
    return UserInfo.authority === AUTHORITIES.GUEST;
  }

  changeHeaderCheckbox() {
    this.$emit('changeHeaderCheckbox');
  }

  changeSingleCheckbox(items, index: number) {
    this.$emit('changeSingleCheckbox', items, index);
  }

  clickLink(data) {
    this.$emit('clickLink', data);
  }

  private isFolder(rowItem): boolean {
    const isFolder: boolean = rowItem.some(col => {
      return col.icon_type === 'folder';
    });
    return isFolder;
  }

  // folder情報を抽出する
  private extractFoler(rowItem): Folder {
    const folderCol = rowItem.find(col => col.key === 'folder_name');
    if (!folderCol) return;
    const folder: Folder = {
      directory_name: folderCol.label,
      directory_path: folderCol.link
    };
    return folder;
  }

  dragoverEvent(event, rowItem, rowIndex) {
    // fileなら
    if (!this.isFolder(rowItem)) {
      this.$emit('dragover');
      this.folderDragOveredIndex = null;
      return;
    }

    this.$emit('folder-dragover', { folder: this.extractFoler(rowItem) });
    this.innerFlag = false;
    this.folderDragOveredIndex = rowIndex;
  }

  dragleaveEvent(event, rowItem, rowIndex) {
    // NOTE: 対象要素から子要素へドラッグ	子要素でdragenter → 対象要素でdragleave
    if (this.innerFlag) return (this.innerFlag = false);
    else this.folderDragOveredIndex = null;

    if (this.isFolder(rowItem)) {
      this.$emit('folder-dragleave');
    }
  }

  drogEvent(event: DragEvent, rowItem, rowIndex) {
    // fileなら
    if (!this.isFolder(rowItem)) {
      this.$emit('drop', event);
      return;
    }
    this.folderDragOveredIndex = null;
    this.$emit('drop-on-folder', { event });
  }
}
