















import { Component, Vue, Prop } from 'vue-property-decorator';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';
import UserInfo from '../../../store/UserInfo';

@Component({
  components: {
    TextAvatar
  }
})
export default class AvatarMenu extends Vue {
  @Prop()
  userName!: string;

  @Prop()
  isAdmin!: boolean;

  get avatar_color() {
    return UserInfo.avatar.avatar_color;
  }
}
