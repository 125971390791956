






























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import TextFormName from '@/components/atoms/texts/TextFormName.vue';
import TextFormMidSize_Password from '@/components/atoms/text_forms/TextFormMidSize_Password.vue';
import PasswordCheckContainer from '@/components/molecules/PasswordCheckContainer.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';

@Component({
  components: {
    PasswordCheckContainer,
    TextFormName,
    TextFormMidSize_Password,
    AppButton,
    BaseWindow
  }
})
export default class ChangePasswordConfirmWindow extends Vue {
  @Prop()
  value: boolean;

  @Prop()
  errorMessage: string;

  @Prop()
  windowSize!: string;

  @Prop()
  canSubmitPassword!: boolean;

  password: string = '';
  new_password: string = '';
  password_error_message: string = '';

  is_dispaly_current_password: boolean = false;
  is_dispaly_new_password: boolean = false;

  @Watch('value')
  changeValue(val) {
    if (!val) {
      this.password = '';
      this.new_password = '';
    }
  }

  get isOpenWindow() {
    return this.value;
  }

  set isOpenWindow(isOpenWindow) {
    this.$emit('input', isOpenWindow);
  }

  get isDisabled() {
    if (this.canSubmitPassword && this.password) {
      return false;
    }
    return true;
  }

  closeModal() {
    this.password = '';
    this.new_password = '';
    this.is_dispaly_current_password = false;
    this.is_dispaly_new_password = false;
    this.$emit('cancel');
  }

  confirmPassword() {
    const params = {
      password: this.password,
      newPassword: this.new_password
    };
    this.$emit('changePassword', params);

    this.password = '';
    this.new_password = '';
    this.is_dispaly_current_password = false;
    this.is_dispaly_new_password = false;
  }

  exchange_dispaly_current_password() {
    this.is_dispaly_current_password = !this.is_dispaly_current_password;
  }

  exchange_dispaly_new_password() {
    this.is_dispaly_new_password = !this.is_dispaly_new_password;
  }

  @Watch('new_password')
  validate_new_password(new_value) {
    this.$emit('changeNewPassword', new_value);
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }
}
