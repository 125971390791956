









































































































































import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import SettingsInfo from '@/store/SettingsInfo';
import UserInfoModule from '@/store/UserInfo';
import { Component, Vue, Watch } from 'vue-property-decorator';

const SlackRepository = RepositoryFactory.get('slack');
const SettingRepository = RepositoryFactory.get('settings');

interface NotificationSetting {
  is_create: boolean;
  is_change_status: boolean;
  is_concerning: boolean;
  is_assign: boolean;
  is_comment: boolean;
  is_alert: boolean;
  is_comment_always: boolean;
}

Component.registerHooks(['beforeRouteLeave']);

@Component({
  components: {
    AppButton,
    TextOnlyWindow
  }
})
export default class EmailNotifications extends Vue {
  isSomeSelected: boolean = false;

  initialFormValue: NotificationSetting = {
    is_create: false,
    is_change_status: false,
    is_concerning: false,
    is_assign: false,
    is_comment: false,
    is_alert: false,
    is_comment_always: false
  };

  form_dirty: boolean = false;
  route_leave_with_dirty: object = null;

  isSlackSelected: boolean = false;
  initSlackSelected: boolean = false;
  initSlackMemberId: string = null;
  slack_form_dirty: boolean = false;

  get emailNotificationSetting(): NotificationSetting {
    return SettingsInfo.email_notification_setting;
  }

  async fetchData() {
    await UserInfoModule.get_user_info();
    await SettingsInfo.get_email_notification_setting().catch(e =>
      notify.error({ text: 'メール通知設定を取得できませんでした。' })
    );
  }

  async created() {
    await this.fetchData();
    this.initializeSelected();
  }

  get slackNotification() {
    return UserInfoModule.slack_notification;
  }

  get slackCooperated() {
    return UserInfoModule.is_cooperated_slack;
  }

  @Watch('$route', { immediate: true })
  cahngeRoute() {
    this.initializeSelected();
  }

  @Watch('emailNotificationSetting', { deep: true })
  changeSelected(emailNotificationSetting) {
    const isSomeSelected = Object.entries(emailNotificationSetting)
      .map(([key, value]) => value)
      .some(x => x);

    this.isSomeSelected = isSomeSelected;
  }

  onChange(e) {
    this.form_dirty = true;

    if (
      e.target.name === 'is_change_status' &&
      this.emailNotificationSetting.is_change_status === false
    ) {
      this.emailNotificationSetting.is_concerning = false;
    }
    if (
      e.target.name === 'is_concerning' &&
      this.emailNotificationSetting.is_concerning === true
    ) {
      this.emailNotificationSetting.is_change_status = true;
    }

    if (e.target.name === 'use_slack') {
      this.slack_form_dirty = true;
    }
    if (e.target.name === 'slack_id') {
      this.slack_form_dirty = true;
    }
  }

  get isDisableUpdate() {
    if (this.slack_form_dirty) {
      if (this.initSlackSelected === false && this.isSlackSelected === false) {
        this.slack_form_dirty = false;
        return true;
      }
    }
    if (!this.isDirty) {
      if (this.slack_form_dirty) {
        return false;
      }
      return true;
    }
    return false;
  }

  get isDirty() {
    return (
      this.form_dirty &&
      Object.keys(this.initialFormValue).some(k => {
        return this.emailNotificationSetting[k] !== this.initialFormValue[k];
      })
    );
  }

  /**
   * 初期選択値を保存
   */
  initializeSelected() {
    this.isSomeSelected = Object.entries(this.emailNotificationSetting)
      .map(([key, value]) => {
        this.initialFormValue[key] = value;
        return value;
      })
      .some(x => x);
    this.isSlackSelected = this.slackNotification;
  }

  /**
   * 全選択
   */
  onAllSelected(e) {
    for (const [key, value] of Object.entries(this.emailNotificationSetting)) {
      this.emailNotificationSetting[key] = this.isSomeSelected;
    }
    this.onChange(e);
  }

  /**
   * Slack通知設定更新処理
   */
  async updateSlackNotification() {
    const data = { slack_notification: this.isSlackSelected };
    await SlackRepository.updateSlackNotification(data);
    this.initSlackSelected = this.isSlackSelected;
    this.slack_form_dirty = false;
  }

  /**
   * メール通知設定更新処理
   */
  async updateEmailNotificationSetting() {
    await this.updateSlackNotification();
    SettingsInfo.SET_EMAIL_NOTIFICATION_SETTING(this.emailNotificationSetting);
    await SettingRepository.updateEmailNotificationSetting(
      this.emailNotificationSetting
    )
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        // 初期値更新
        Object.entries(this.emailNotificationSetting).forEach(
          ([key, value]) => {
            this.initialFormValue[key] = value;
          }
        );

        notify.success({
          text: 'メール通知設定を保存しました'
        });
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
    this.form_dirty = false;
    await this.fetchData();
  }

  get isLeaveWithDirty() {
    return this.route_leave_with_dirty !== null;
  }

  beforeRouteLeave(to, from, next) {
    if (this.isDirty) {
      this.route_leave_with_dirty = to;
      return;
    }
    next();
  }

  leaveAndDiscard() {
    this.form_dirty = false;
    this.$router.push(this.route_leave_with_dirty);
    this.route_leave_with_dirty = null;
  }

  cancelLeave() {
    this.route_leave_with_dirty = null;
  }
}
