














































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import vLoading from 'vue-loading-overlay';
import AppNotifications from '@/components/atoms/AppNotifications.vue';
import HomeHeader from '@/components/organisms/Home/HomeHeader.vue';
import HomeSideMenuBar from '@/components/organisms/Home/HomeSideMenuBar.vue';
import TableWrapper from '@/components/molecules/Table/TableWrapper.vue';
import TableSelectTab from '@/components/molecules/Table/TableSelectTab.vue';
import UploadMenu from '@/components/molecules/UploadMenu.vue';
import UploadButton from '@/components/atoms/buttons/UploadButton.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import SelectedItemsView from '@/components/molecules/SelectedItemsView.vue';
import FileUploadingPopupMessage from '@/components/molecules/popups/FileUploadingPopupMessage.vue';

import iconCircledCheck from '@/components/atoms/icons/IconCircledCheck.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import IconZoomOut from '@/components/atoms/icons/IconZoomOut.vue';

import UploadDialog from '@/components/molecules/UploadDialog.vue';
import SingleInputFormWindow from '@/components/molecules/modal_windows/SingleInputFormWindow.vue';
import MoveTemplateModal from '@/components/molecules/modal_windows/MoveTemplateModal.vue';

import DeleteTemplateWindow from '@/components/molecules/modal_windows/DeleteTemplateWindow.vue';

import MakeBreadCrumb from '@/components/atoms/breadcrumb/MakeBreadCrumb.vue';

import HomeComponentInfoModule from '@/store/HomeComponentInfo';
import TemplateInfoModule from '@/store/TemplateInfo';
import UserInfo from '@/store/UserInfo';
import SettingsInfoModule from '@/store/SettingsInfo';
import CorporateInfo from '@/store/CorporateInfo';
import Documents from '@/store/Documents';

import Validation from '@/functions/Validation';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import LAYOUT_UTIL from '@/consts/LayoutUtil';
import notify from '@/functions/notify';
import FileUpload from '@/functions/FileUpload';
import { spaceReplaceUnderScore } from '@/functions/replaceString';
const axios = require('axios').default;
import ClickOutside from 'vue-click-outside';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const TemplateRepository = RepositoryFactory.get('templates');
const TemplateInfoRepository = RepositoryFactory.get('templateInfos');

interface DropFile {
  size: number;
  name: string;
  type: string;
  file: File;
}

interface Folder {
  directory_name: string;
  directory_path: string;
}

const tabMenuNames = {
  client_template: '自社テンプレート',
  liris_template: 'LIRISテンプレート'
};

const TEMPLATE_TYPES = {
  CLIENT_TEMPLATE: 'client_template',
  LIRIS_TEMPLATE: 'liris_template'
};

const ROOT_DIRECTORY_NAME = '契約書テンプレート';

@Component({
  components: {
    HomeHeader,
    HomeSideMenuBar,
    TableWrapper,
    TableSelectTab,
    AppNotifications,
    UploadButton,
    UploadMenu,
    SingleInputFormWindow,
    MoveTemplateModal,
    UploadDialog,
    DeleteTemplateWindow,
    iconCircledCheck,
    vLoading,
    SelectedItemsView,
    ImageContent,
    FileUploadingPopupMessage,
    AppButton,
    MakeBreadCrumb,
    IconZoomOut
  },
  directives: {
    ClickOutside
  }
})
export default class Template extends Vue {
  dragover: boolean = false;
  uploading_files_num: number = 0;
  clientTemplatesTableBodyData = [];
  lirisTemplatesTableBodyData = [];
  selectedTab: string = '';
  newFolderName: string = '';
  headerChecked = false;
  fileList = [];
  uploadTargetFolder: Folder = {
    directory_name: null,
    directory_path: null
  };
  dragoverFolder: boolean = false;

  // ファイルアップロード中の場合のみtrue。あとでアップロード機能つけるときに書き換える。
  file_upload: boolean = false;
  showUploadDialog: boolean = false;
  showPreviewDialog: boolean = false;
  showCreateNewFolderDialog: boolean = false;
  showDeleteConfirmDialog: boolean = false;
  previewHtml: string = null;
  deletingTemplate: boolean = false;

  renameTargetFile = {
    name: '',
    id: ''
  };
  renameTargetFolder = {
    name: '',
    path: ''
  };
  targetFileIds = [];
  targetFolderNames = [];
  deleteTargetFiles = [];
  isShowingRenameFileModal: boolean = false;
  isShowingRenameFolderModal: boolean = false;
  isShowingMoveObjectModal: boolean = false;
  isShowingUploadMenu: boolean = false;
  isLoading: boolean = false;
  downloadLink: string = '';
  getDownloadInterval = null;
  directories = [];
  finishedUpdated: boolean = false;
  newFolderCreating: boolean = false;

  selectedDirectories = [
    {
      name: ROOT_DIRECTORY_NAME,
      path: ''
    }
  ];

  crumbsList = null;

  fileNameOrFolderNameErrorMessage: string = '';

  uploadingFilesText: string = NOTIFY_TEXT.SUCCESS.UPLOADING_FILES;
  folderNameEditing: boolean = false;
  fileNameEditing: boolean = false;

  keyword: string = '';

  get editFileNameButtonText(): string {
    return this.fileNameEditing ? '保存中' : '保存';
  }

  async created() {
    Documents.SET_CURRENT_DIRECTORY('/');
    await Promise.all([
      Documents.getNotifications(),
      Documents.getLirisNotifications(),
      UserInfo.get_user_info(),
      CorporateInfo.get_service_plan()
    ])
      .then(values => {
        if (values.find(x => x.status !== HTTP_STATUS.OK)) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
  }

  async mounted() {
    this.isLoading = true;
    // query 情報からディレクトリ階層を取得する。
    if (this.$route.query.dir) {
      const directories = (this.$route.query.dir as string)
        .split('/')
        .filter(p => !!p);
      let path = '';
      directories.forEach(d => {
        path = `${path}/${d}`;
        this.selectedDirectories.push({ name: d, path });
      });
    }
    this.selectedTab =
      this.$route.query.type === TEMPLATE_TYPES.LIRIS_TEMPLATE
        ? TEMPLATE_TYPES.LIRIS_TEMPLATE
        : TEMPLATE_TYPES.CLIENT_TEMPLATE;
    if (this.isClientTabSelected) {
      await this.getClientTemplateByQuery();
    } else {
      await this.getLirisTemplateByQuery();
    }
    this.clearAllCheckbox();
    this.isLoading = false;
  }

  get editFolderNameButtonText(): string {
    return this.folderNameEditing ? '保存中' : '保存';
  }

  get createNewFolderButtonText(): string {
    return this.newFolderCreating ? '作成中' : '作成';
  }

  get permission() {
    return UserInfo.permission;
  }

  onClickOutside(event: any) {
    this.isShowingUploadMenu = false;
  }

  get isDownloadable() {
    return !(this.isLirisTabSelected && CorporateInfo.isFreeTrialPlan);
  }

  initializeUploadTargetFolder(): void {
    this.uploadTargetFolder = {
      directory_name: null,
      directory_path: null
    };
  }

  folderDragover({ folder }) {
    this.uploadTargetFolder = folder;
    this.dragoverFolder = true;
  }

  async dropOnFolder({ event }) {
    this.dragoverFolder = false;
  }

  dragleaveHomeBody(event: DragEvent) {
    // sidebar, headerに行ったらleaveとする
    const sidebarWidth: number = this.sideBarStatus
      ? LAYOUT_UTIL.SIDE_MENU.WIDTH.OPEN
      : LAYOUT_UTIL.SIDE_MENU.WIDTH.CLOSE;
    const headerHeight: number = LAYOUT_UTIL.HEADER.HEIGHT;
    if (event.pageX < sidebarWidth || event.pageY < headerHeight)
      this.dragover = false;
    return;
  }

  get tableHeadData() {
    if (this.isClientTabSelected) {
      return TemplateInfoModule.clientTemplatesTableHeadData;
    }
    return TemplateInfoModule.lirisTemplatesTableHeadData;
  }

  get tableBodyData() {
    if (this.isClientTabSelected) {
      return this.clientTemplatesTableBodyData;
    }
    return this.lirisTemplatesTableBodyData;
  }

  set tableBodyData(data) {
    if (this.isClientTabSelected) {
      this.clientTemplatesTableBodyData = data;
      return;
    }
    this.lirisTemplatesTableBodyData = data;
  }

  private get sideBarStatus() {
    return this.$store.state.home_component_info.side_bar_open;
  }

  get tabMenuNames() {
    return tabMenuNames;
  }

  get TEMPLATE_TYPES() {
    return TEMPLATE_TYPES;
  }

  get isClientTabSelected() {
    return this.selectedTab === TEMPLATE_TYPES.CLIENT_TEMPLATE;
  }

  get isLirisTabSelected() {
    return this.selectedTab === TEMPLATE_TYPES.LIRIS_TEMPLATE;
  }

  get isAllChecked() {
    return this.tableBodyData.every(data => data[0].value);
  }

  get isAnyChecked() {
    const bodyData = this.isClientTabSelected
      ? this.clientTemplatesTableBodyData
      : this.lirisTemplatesTableBodyData;
    if (!bodyData) {
      return false;
    }
    return bodyData.some(data => data[0].value);
  }

  get isOneChecked() {
    const bodyData = this.isClientTabSelected
      ? this.clientTemplatesTableBodyData
      : this.lirisTemplatesTableBodyData;
    return bodyData && bodyData.filter(data => data[0].value).length === 1;
  }

  get currentDirectory() {
    return this.directories[this.directories.length - 1];
  }

  get showFolderButton() {
    if (this.isClientTabSelected) {
      if (this.permission.can_operation_folder_own_template) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.permission.can_operation_folder_file_liris_template) {
        return true;
      } else {
        return false;
      }
    }
  }

  get showFileButton() {
    if (this.isClientTabSelected) {
      if (this.permission.can_operation_file_own_template) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.permission.can_operation_folder_file_liris_template) {
        return true;
      } else {
        return false;
      }
    }
  }

  get SselectedDirectories() {
    return TemplateInfoModule.selectedDirectories;
  }

  async selectTab(value) {
    if (this.selectedTab === value && !this.directories.length) {
      return;
    }
    if (
      ![TEMPLATE_TYPES.CLIENT_TEMPLATE, TEMPLATE_TYPES.LIRIS_TEMPLATE].includes(
        value
      )
    ) {
      return;
    }

    // クエリを削除
    await this.$router.push({
      query: {
        dir: '/',
        type: value
      }
    });
    this.selectedTab = value;
    this.keyword = '';

    this.isLoading = true;
    // columnNameが name -> nameの変更だと昇/降順が入れ替わってしまうので初期化してからMutate
    SettingsInfoModule.SET_SORT({ pageName: this.$route.name, columnName: '' });
    SettingsInfoModule.SET_SORT({
      pageName: this.$route.name,
      columnName: 'name'
    });
    if (this.isClientTabSelected) {
      await this.getClientTemplateByQuery();
    } else {
      await this.getLirisTemplateByQuery();
    }
    this.isLoading = false;
    this.clearAllCheckbox();
    this.selectedDirectories = [
      {
        name: ROOT_DIRECTORY_NAME,
        path: ''
      }
    ];
  }

  toggleSideBar() {
    HomeComponentInfoModule.change_side_bar();
  }

  changeHeaderCheckbox() {
    this.headerChecked = !this.headerChecked;
    this.tableBodyData.map(data => (data[0].value = this.headerChecked));

    this.targetFolderNames = [];
    this.targetFileIds = [];

    if (this.isClientTabSelected) {
      TemplateInfoModule.clientTemplates.directory_path_list.map(x => {
        this.targetFolderNames = [...this.targetFolderNames, x.directory_path];
      });

      TemplateInfoModule.clientTemplates.client_templates.map(x => {
        this.targetFileIds = [...this.targetFileIds, x.template_id];
      });
    } else {
      TemplateInfoModule.lirisTemplates.directory_path_list.map(x => {
        this.targetFolderNames = [...this.targetFolderNames, x.directory_path];
      });

      TemplateInfoModule.lirisTemplates.liris_templates.map(x => {
        this.targetFileIds = [...this.targetFileIds, x.template_id];
      });
    }
  }

  async getClientTemplateByQuery() {
    if (this.$route.query.dir) {
      this.directories = (this.$route.query.dir as string)
        .split('/')
        .filter(str => str.length);
      await TemplateInfoModule.getClientTemplates(this.getCurrentDirectory());
    } else {
      this.directories = [];
      await TemplateInfoModule.getClientTemplates(this.getCurrentDirectory());
    }
    this.clientTemplatesTableBodyData = await TemplateInfoModule.clientTemplatesTableBodyData;
  }

  async getLirisTemplateByQuery() {
    if (this.$route.query.dir) {
      this.directories = (this.$route.query.dir as string)
        .split('/')
        .filter(str => str.length);
      await TemplateInfoModule.getLirisTemplates(this.getCurrentDirectory());
    } else {
      this.directories = [];
      await TemplateInfoModule.getLirisTemplates(this.getCurrentDirectory());
    }
    this.lirisTemplatesTableBodyData = await TemplateInfoModule.lirisTemplatesTableBodyData;
  }

  /**
   * チェックボックスを変更したとき、ファイルとフォルダでの処理をそれぞれ行う。
   */
  changeBodySingleCheckbox(items, index) {
    // テーブル用のデータの checkbox を変更する
    if (items[0].id) {
      // ファイルの場合
      const target_id = items[0].id;
      this.tableBodyData = this.tableBodyData.map(x => {
        if (x[0].id == target_id) {
          x[0].value = !x[0].value;
        }
        return x;
      });
    } else {
      // フォルダの場合
      const target_dir = items[2].label;
      this.tableBodyData = this.tableBodyData.map(x => {
        if (x[2].label == target_dir) {
          x[0].value = !x[0].value;
        }
        return x;
      });
    }

    this.headerChecked = !!this.isAllChecked;

    // チェックされているテーブル用データを取得
    const selectedItems = this.tableBodyData.filter(x => x[0].value);

    if (selectedItems.length !== 1) {
      this.renameTargetFolder.name = '';
      this.renameTargetFolder.path = '';
      this.renameTargetFile.name = '';
      this.renameTargetFile.id = '';
    }

    // チェックされているものがなければ初期化
    if (!selectedItems.length) {
      this.targetFolderNames = [];
      this.targetFileIds = [];
      return;
    }

    let selectedFolders = [];
    let selectedFiles = [];

    // チェックされているテーブル用データをフォルダとファイルに分類
    const selectedFoldersAndFiles = selectedItems.map(x => {
      if (!x[0].value) return;

      if (x[2].type === 'link' && x[2].key === 'folder_name') {
        // フォルダ
        selectedFolders = [
          ...selectedFolders,
          { path: x[2].link, name: x[2].label }
        ];
      } else {
        // ファイル
        selectedFiles = [...selectedFiles, { id: x[2].id, name: x[2].label }];
      }

      Documents.SET_SELECTED_FOLDERS_OF_TABLE(
        selectedFolders.map(x => {
          return {
            directory_name: x.name,
            directory_path: x.path
          };
        })
      );

      return {
        folders: selectedFolders,
        files: selectedFiles
      };
    });

    const result = selectedFoldersAndFiles[selectedItems.length - 1];

    this.targetFolderNames = result.folders.map(x => x.name);
    this.targetFileIds = result.files.map(x => x.id);

    // チェックされているデータが1つの時、名前変更用のデータを格納
    if (selectedItems.length === 1) {
      if (this.targetFolderNames.length === 1) {
        const target = (this.renameTargetFolder.name = result.folders[0].name);
        this.renameTargetFolder.path = result.folders[0].path;
      }

      if (this.targetFileIds.length === 1) {
        this.renameTargetFile.name = result.files[0].name;
        this.renameTargetFile.id = result.files[0].id;
      }
    }
  }

  clearAllCheckbox() {
    this.targetFileIds = [];
    this.targetFolderNames = [];
    this.headerChecked = false;
    this.tableBodyData.map(data => (data[0].value = false));
  }

  inputFolder(files) {
    this.initializeUploadTargetFolder();
    for (let i = 0; i < Object.keys(files.target.files).length; i++) {
      const file = files.target.files[i];
      if (this.is_allowed_upload_file(file)) {
        Object.defineProperty(file, 'name', {
          writable: true,
          value: file.webkitRelativePath
        });
        this.fileList.push(file);
      }
    }
    if (this.fileList.length) {
      this.showUploadDialog = true;
    }
  }

  inputFile(files) {
    this.initializeUploadTargetFolder();
    Object.keys(files.target.files).map(key =>
      this.fileList.push(files.target.files[key])
    );
    this.showUploadDialog = true;
  }

  getCurrentDirectory() {
    let current_directory = this.$route.query.dir as string;
    if (!current_directory) {
      current_directory = '/';
    }
    return current_directory;
  }

  cancelUploadingFiles() {
    // TODO: アップロードを中止するメソッドの追加が必要
    this.file_upload = false;
  }

  closeUploadDialog() {
    this.showUploadDialog = false;
    this.fileList = [];
  }

  async uploadFile(uploadArgs) {
    const formData = new FormData();
    this.file_upload = true;

    for (let i = 0; i < uploadArgs.fileList.length; i++) {
      const targetFile = uploadArgs.fileList[i];
      if (targetFile.size > 10 * 1024 * 1024) {
        if (
          confirm(
            targetFile.name +
              'のファイルサイズは10MBを超えています。アップロードを中断しますか？'
          )
        ) {
          // アップロードで使用するプロパティを初期化する
          this.initializeUploadProperty();
          return;
        }
      }
      let uploadFile = null;
      // D&Dのときとinputタグからのファイル形式が異なるため対応
      if (!targetFile.file) {
        uploadFile = new File(
          [targetFile],
          // ファイル、フォルダ名に空白文字が入らないようにする
          spaceReplaceUnderScore(targetFile.name)
        );
      } else {
        uploadFile = new File(
          [targetFile.file],
          // ファイル、フォルダ名に空白文字が入らないようにする
          spaceReplaceUnderScore(targetFile.name)
        );
      }
      formData.append('template_files', uploadFile);

      this.showUploadDialog = false;
    }

    // カレントディレクトリをパラメータに追加
    const current_directory = this.getCurrentDirectory();
    formData.append(
      'current_directory',
      this.uploadTargetFolder.directory_path
        ? this.uploadTargetFolder.directory_path
        : current_directory
    );

    this.fileList = [];

    let res;

    if (this.isClientTabSelected) {
      await TemplateInfoRepository.uploadClientTemplate({
        params: formData
      })
        .then(async res => {
          if (res.status !== 204) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
            });
            return;
          }

          notify.success({
            text: uploadArgs.fileList.length + NOTIFY_TEXT.SUCCESS.UPLOAD_SUFFIX
          });

          await TemplateInfoModule.getClientTemplates(
            this.getCurrentDirectory()
          );
          this.clientTemplatesTableBodyData =
            TemplateInfoModule.clientTemplatesTableBodyData;
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
        })
        .finally(() => {
          // アップロードで使用するプロパティを初期化する
          this.initializeUploadProperty();
        });
    } else if (this.isLirisTabSelected) {
      // 無料トライアルで表示するようにパラメータ追加
      formData.append('is_viewable_free_trial', 'true');

      await TemplateInfoRepository.uploadLirisTemplate({
        params: formData
      })
        .then(async res => {
          if (res.status !== HTTP_STATUS.NO_CONTENT) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
            });
            return;
          }

          notify.success({
            text: uploadArgs.fileList.length + NOTIFY_TEXT.SUCCESS.UPLOAD_SUFFIX
          });

          await TemplateInfoModule.getLirisTemplates(
            this.directories[this.directories.length - 1]
          );
          this.lirisTemplatesTableBodyData =
            TemplateInfoModule.lirisTemplatesTableBodyData;
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
        })
        .finally(() => {
          // アップロードで使用するプロパティを初期化する
          this.initializeUploadProperty();
        });
    }
  }

  async createNewFolder(folderName) {
    if (this.isClientTabSelected) {
      const existFolder = this.$store.state.template_info.clientTemplates.directory_path_list.find(
        x => x.directory_name === folderName
      );

      if (existFolder) {
        notify.error({
          text: NOTIFY_TEXT.ERROR.ALREADY_EXIST_FOLDER
        });
        return;
      }
      notify.clearError();

      this.fileNameOrFolderNameErrorMessage = Validation.validateFileNameAndFolderName(
        folderName,
        true
      );
      if (this.fileNameOrFolderNameErrorMessage) return;
      this.fileNameOrFolderNameErrorMessage = '';
      this.newFolderCreating = true;

      const spaceReplacedFolderName: string = spaceReplaceUnderScore(
        folderName
      );

      await TemplateInfoRepository.addClientTemplateDirectory({
        directory_path: this.getCurrentDirectory() || '/',
        directory_name: spaceReplacedFolderName
      })
        .then(async res => {
          if (res.status !== HTTP_STATUS.OK) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.CREATED_FOLDER
            });
            return;
          }

          await TemplateInfoModule.getClientTemplates(
            this.getCurrentDirectory()
          );
          this.clientTemplatesTableBodyData =
            TemplateInfoModule.clientTemplatesTableBodyData;

          notify.success({
            text: NOTIFY_TEXT.SUCCESS.CREATED_FOLDER
          });
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CREATED_FOLDER
          });
        })
        .finally(() => {
          this.newFolderCreating = false;
          this.showCreateNewFolderDialog = false;
          this.newFolderName = '';
        });
    } else if (this.isLirisTabSelected) {
      const spaceReplacedFolderName: string = spaceReplaceUnderScore(
        folderName
      );
      await TemplateInfoRepository.addLirisTemplateDirectory({
        directory_path: this.getCurrentDirectory() || '/',
        directory_name: spaceReplacedFolderName
      })
        .then(async res => {
          if (res.status !== HTTP_STATUS.OK) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.CREATED_FOLDER
            });
            return;
          }

          await TemplateInfoModule.getLirisTemplates(
            this.directories[this.directories.length - 1]
          );
          this.lirisTemplatesTableBodyData =
            TemplateInfoModule.lirisTemplatesTableBodyData;

          notify.success({
            text: NOTIFY_TEXT.SUCCESS.CREATED_FOLDER
          });
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CREATED_FOLDER
          });
        })
        .finally(() => {
          this.newFolderCreating = false;
          this.showCreateNewFolderDialog = false;
          this.newFolderName = '';
        });
    }
  }

  async createDownloadLink() {
    const resCreateLink = await TemplateInfoRepository.createDownloadLink({
      dir_path_list: this.targetFolderNames,
      template_id_list: this.targetFileIds,
      is_client_tab_selected: this.isClientTabSelected
    })
      .then(res => {
        return res;
      })
      .catch(e => {
        const status = e.response.status;
        if (status == HTTP_STATUS.BAD_REQUEST) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CANNOT_DOWNLOAD_EMPTY_FOLDER
          });
        } else {
          notify.error({
            text: NOTIFY_TEXT.ERROR.FAILED_DOWNLOAD
          });
        }
        return;
      });

    if (!resCreateLink || !resCreateLink.data) {
      return;
    }

    // 単体ファイル1つのみのリクエストを投げたときは、ダウンロードリンクが返ってくるため、そのままダウンロード処理を行う。
    if (this.targetFileIds.length === 1 && !this.targetFolderNames.length) {
      this.downloadLink = resCreateLink.data;
      this.clearAllCheckbox();
      this.targetFileIds = [];
      this.targetFolderNames = [];

      // ダウンロード完了タイミングで「ダウンロード済み」を表示するためXmlHttpRequestを利用する
      this.downloadByXmlHttpRequest(this.downloadLink, file => {
        this.downloadFile(this.renameTargetFile.name, file);
      });

      return;
    }
    notify.successWithoutCloseButton({
      text: 'ダウンロード処理中'
    });

    this.clearAllCheckbox();
    this.targetFileIds = [];
    this.targetFolderNames = [];

    // 複数ダウンロードのときはダウンロードリンクを取得のためリクエストを投げる。
    // 初回は直接投げ、2回目以降は setInterval 内で行う。
    this.getDownloadLink({
      job_id: resCreateLink.data
    });
    this.getDownloadInterval = setInterval(() => {
      this.getDownloadLink({
        job_id: resCreateLink.data
      });
    }, 5000);
  }

  downloadByXmlHttpRequest(file, callback) {
    const request = new XMLHttpRequest();
    request.responseType = 'blob';
    request.open('GET', file);
    request.send();
    request.addEventListener('load', async () => {
      await callback(request.response);

      if (this.isClientTabSelected) {
        await this.getClientTemplateByQuery();
      } else {
        await this.getLirisTemplateByQuery();
      }
    });
  }

  async getDownloadLink({ job_id }) {
    if (!this.downloadLink) {
      // createDownloadLink で setInterval で呼び出しているため、ローディングは表示しない。
      await TemplateInfoRepository.getDownloadLink({
        job_id
      })
        .then(res => {
          if (res.status !== 200) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.GENERAL
            });
            return;
          }

          this.downloadLink = res.data;
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
        });

      return;
    }

    clearInterval(this.getDownloadInterval);
    notify.clearSuccessWithoutCloseButton();
    this.getDownloadInterval = null;

    this.downloadFile();
    await this.getLirisTemplateByQuery();
  }

  downloadFile(name = '', file = '', extension = '.docx') {
    const link = document.createElement('a');
    if (file) {
      link.href = URL.createObjectURL(file);
      link.download = `${name}${extension}`;
    } else {
      link.href = this.downloadLink;
    }

    link.click();

    this.downloadLink = '';
  }

  openCreateNewFolderDialog() {
    this.showCreateNewFolderDialog = true;
  }

  closeCreateFolderWindow() {
    this.showCreateNewFolderDialog = false;
    this.newFolderName = '';
    this.fileNameOrFolderNameErrorMessage = '';
  }

  openDeleteConfirm() {
    const originalTemplateData: any = this.isClientTabSelected
      ? TemplateInfoModule.clientTemplates.client_templates
      : TemplateInfoModule.lirisTemplates.liris_templates;

    this.deleteTargetFiles = this.targetFileIds.reduce(
      (arr: any, id: number) => {
        const file = originalTemplateData.find(
          original => original.template_id === id
        );
        arr.push(file.file_name);
        return arr;
      },
      []
    );

    this.showDeleteConfirmDialog = true;
  }

  closeDeleteConfirm() {
    this.showDeleteConfirmDialog = false;
  }

  async updateFileName(newFileName) {
    this.fileNameOrFolderNameErrorMessage = Validation.validateFileNameAndFolderName(
      newFileName
    );
    if (this.fileNameOrFolderNameErrorMessage) return;
    this.fileNameOrFolderNameErrorMessage = '';

    this.fileNameEditing = true;

    let res = null;
    if (this.isClientTabSelected) {
      res = await TemplateInfoRepository.renameClientTemplate({
        client_template_id: this.renameTargetFile.id,
        file_name: newFileName,
        current_directory: this.getCurrentDirectory()
      })
        .then(res => {
          if (res.status !== 200) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.CHANGE_FILE_NAME
            });
            return;
          }

          return res;
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CHANGE_FILE_NAME
          });
        });
    } else {
      // TODO: LIRISテンプレートのファイル名変更
    }

    this.clearAllCheckbox();

    this.isShowingRenameFileModal = false;
    this.renameTargetFile.id = '';
    this.renameTargetFile.name = '';
    this.fileNameEditing = false;

    if (!res) return;

    await TemplateInfoModule.getClientTemplates(this.getCurrentDirectory());
    this.lirisTemplatesTableBodyData =
      TemplateInfoModule.lirisTemplatesTableBodyData;
    this.clientTemplatesTableBodyData =
      TemplateInfoModule.clientTemplatesTableBodyData;

    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CHANGE_FILE_NAME
    });
  }

  async updateFolderName(newFolderName) {
    if (this.renameTargetFolder.name.trim() === newFolderName) {
      this.closeFolderModal();
      return;
    }
    this.fileNameOrFolderNameErrorMessage = Validation.validateFileNameAndFolderName(
      newFolderName,
      true
    );
    if (this.fileNameOrFolderNameErrorMessage) return;
    this.fileNameOrFolderNameErrorMessage = '';

    const spaceReplacedFolderName: string = spaceReplaceUnderScore(
      newFolderName
    );

    this.folderNameEditing = true;

    let res = null;
    if (this.isClientTabSelected) {
      res = await TemplateInfoRepository.renameClientFolder({
        target_directory_path: this.renameTargetFolder.path,
        new_name: spaceReplacedFolderName,
        current_directory: this.getCurrentDirectory() || '/'
      })
        .then(res => {
          if (res.status !== 200) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.CHANGE_FOLDER_NAME
            });
            return;
          }

          return res;
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CHANGE_FOLDER_NAME
          });
        });
    } else {
      res = await TemplateInfoRepository.renameLirisFolder({
        target_directory_path: this.renameTargetFolder.path,
        new_name: spaceReplacedFolderName,
        current_directory: this.getCurrentDirectory() || '/'
      })
        .then(res => {
          if (res.status !== 200) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.CHANGE_FOLDER_NAME
            });
            return;
          }

          return res;
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CHANGE_FOLDER_NAME
          });
        });
    }
    this.clearAllCheckbox();

    this.isShowingRenameFolderModal = false;
    this.renameTargetFolder.name = '';
    this.renameTargetFolder.path = '';
    this.folderNameEditing = false;

    if (!res) return;

    await TemplateInfoModule.getClientTemplates(this.getCurrentDirectory());
    this.lirisTemplatesTableBodyData =
      TemplateInfoModule.lirisTemplatesTableBodyData;
    this.clientTemplatesTableBodyData =
      TemplateInfoModule.clientTemplatesTableBodyData;

    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CHANGE_FOLDER_NAME
    });
  }

  async deleteTemplate() {
    this.deletingTemplate = true;
    let res;

    // 現在表示しているのパス
    let currentDirectory = this.selectedDirectories[
      this.selectedDirectories.length - 1
    ].path;

    if (!currentDirectory) currentDirectory = '/';

    // 削除対象フォルダのパス
    const deleteFoldersPath = this.targetFolderNames.map(
      x => `${currentDirectory}${currentDirectory === '/' ? '' : '/'}${x}`
    );

    const id_list_key = this.isLirisTabSelected
      ? 'liris_template_id_list'
      : 'client_template_id_list';
    const params = {
      current_directory: currentDirectory,
      data_params: {
        directory_path_list: deleteFoldersPath,
        [id_list_key]: this.targetFileIds
      }
    };

    if (this.isClientTabSelected) {
      await TemplateInfoRepository.deleteClientTemplate(params)
        .then(async res => {
          if (res.status !== HTTP_STATUS.OK) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.DELETE
            });
            return;
          }

          await TemplateInfoModule.getClientTemplates(
            this.getCurrentDirectory()
          );
          this.clientTemplatesTableBodyData =
            TemplateInfoModule.clientTemplatesTableBodyData;

          notify.success({
            text: NOTIFY_TEXT.SUCCESS.DELETE
          });
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE
          });
        });
    } else if (this.isLirisTabSelected) {
      await TemplateInfoRepository.deleteLirisTemplate(params)
        .then(async res => {
          if (res.status !== 200) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.DELETE
            });
            return;
          }

          await this.getLirisTemplateByQuery();
          this.lirisTemplatesTableBodyData =
            TemplateInfoModule.lirisTemplatesTableBodyData;

          notify.success({
            text: NOTIFY_TEXT.SUCCESS.DELETE
          });
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE
          });
        });
    }

    this.targetFileIds = [];
    this.targetFolderNames = [];

    this.clearAllCheckbox();
    this.showDeleteConfirmDialog = false;
    this.deletingTemplate = false;
  }

  closeFileModal() {
    this.isShowingRenameFileModal = false;
    this.fileNameOrFolderNameErrorMessage = '';
  }

  closeFolderModal() {
    this.isShowingRenameFolderModal = false;
    this.fileNameOrFolderNameErrorMessage = '';
  }

  // ファイル名をクリックした際の処理
  async clickLabel(data) {
    // フォルダをクリックした場合
    if (data.key === 'folder_name') {
      this.moveIntoFolder(data.link);
      return;
    }
    this.isLoading = true;
    // res.dataがファイルのプレビュー
    let res = null;
    if (this.isClientTabSelected) {
      res = await TemplateInfoRepository.getClientTemplatePreview({
        client_template_id: data.id
      })
        .then(res => {
          if (res.status !== 200) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.GENERAL
            });
            return;
          }

          return res;
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
        });
    } else {
      res = await TemplateInfoRepository.getLirisTemplatePreview({
        liris_template_id: data.id
      })
        .then(res => {
          if (res.status !== 200) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.GENERAL
            });
            return;
          }

          return res;
        })
        .catch(e => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
        });
    }

    if (!res) return;

    this.isLoading = false;
    this.showPreviewDialog = true;
    this.previewHtml = res.data;

    const pageContainerEle = await document.getElementById('page-container');
    if (pageContainerEle !== null) {
      // pageContainerEleのスクロール位置をtopに戻す
      pageContainerEle.scrollTop = 0;
    }
  }

  @Watch('$route.query.dir')
  // TODO: WatchでAPIコールしない
  // @See: https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/2844
  // URL変わっているのでデータ再取得
  async changeCurrentDirectory(dir: string) {
    if (dir && dir !== '/') {
      const directories = dir.split('/');
      const results = [];
      let path = '';
      directories.forEach(x => {
        path = x ? `${path}/${x}` : '';
        results.push({ name: x ? x : ROOT_DIRECTORY_NAME, path });
      });

      this.selectedDirectories = results;
    } else {
      this.selectedDirectories = [
        {
          name: ROOT_DIRECTORY_NAME,
          path: ''
        }
      ];
    }

    this.headerChecked = false;
    this.targetFileIds = [];
    this.isLoading = true;
    if (this.isClientTabSelected) {
      await this.getClientTemplateByQuery();
      this.isLoading = false;
      return;
    }
    await this.getLirisTemplateByQuery();
    this.isLoading = false;
  }

  @Watch('keyword')
  async emptyKeyword(keyword) {
    if (keyword) return;

    this.selectedDirectories = this.SselectedDirectories;
    this.isLoading = true;
    if (this.isClientTabSelected) {
      await this.getClientTemplateByQuery();
      this.clientTemplatesTableBodyData = await TemplateInfoModule.clientTemplatesTableBodyData;
    } else {
      await this.getLirisTemplateByQuery();
      this.lirisTemplatesTableBodyData = await TemplateInfoModule.lirisTemplatesTableBodyData;
    }
    this.isLoading = false;
  }

  // フォルダ名をクリックしたときの処理
  async moveIntoFolder(folder_name = null, is_root = false, index = null) {
    // ルートディレクトリへの移動ではなくフォルダ名をクリックしたときの移動
    if (!is_root && folder_name) {
      if (index == null) {
        this.directories = folder_name.split('/');
        this.$router.push({
          query: {
            dir: folder_name,
            type: this.selectedTab
          }
        });
        return;
      } else {
        const move_to_path = this.directories.slice(0, index + 1);
        this.directories = move_to_path;
        this.$router.push({
          query: {
            dir: this.directories.join('/'),
            type: this.selectedTab
          }
        });
        return;
      }
    }

    if (!is_root && this.getCurrentDirectory() === folder_name) {
      return;
    }
    if (is_root && folder_name === this.getCurrentDirectory()) {
      return;
    }

    // ルートディレクトリへ遷移
    if (is_root) {
      this.directories = [];
      this.$router.push({
        query: {
          dir: '',
          type: this.selectedTab
        }
      });
      return;
    }
  }

  // ドラッグ＆ドロップのアップロードで追加
  private get showDropzone(): boolean {
    return true;
  }

  async drop_process(event): Promise<void> {
    if (!this.permission.can_upload_file) {
      this.dragover = false;
      return;
    }

    this.dragover = false;
    this.fileList = await FileUpload.addDataTransfer(event.dataTransfer);

    // アップロード可能な拡張子のファイルを取得
    const collectFiles = this.fileList.filter(
      x => /\.(docx)$/i.test(x.name) && !/\.(DS_Store)$/i.test(x.name)
    );

    // アップロード可能なファイルがない場合は処理を中止
    if (!this.is_allowed_upload_file(collectFiles)) return;

    this.fileList = collectFiles.filter(x => x.size <= 10 * 1024 * 1024);

    // 拡張子偽装確認、あれば処理中止
    const is_fake_extension = await FileUpload.isFakeExtension(this.fileList);
    if (is_fake_extension) {
      return;
    }

    this.uploading_files_num = this.fileList.length;
    // ファイル、フォルダ名に空白文字が入らないようにする
    this.fileList = this.fileList.map((file: DropFile) => {
      file.name = spaceReplaceUnderScore(file.name);
      return file;
    });
    if (this.uploading_files_num) this.showUploadDialog = true;
    else this.showUploadDialog = false;
  }

  is_allowed_upload_file(files: any): boolean {
    return FileUpload.checkAllowedUploadFile(
      files,
      'docx以外のファイルはアップロードできません'
    );
  }

  /** アップロード中のファイル数を返す */
  addFilesNum(num: number): void {
    this.uploading_files_num = num;
  }

  async moveObject(path: string) {
    const directory_path_list = this.targetFolderNames.map(
      folder => `${this.getCurrentDirectory()}/${folder}`
    );
    const params = {
      directory_path_list,
      client_template_id_list: this.targetFileIds,
      move_to: path
    };

    this.isLoading = true;
    await TemplateRepository.moveTemplate(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        if (this.isClientTabSelected) {
          await this.getClientTemplateByQuery();
        } else {
          await this.getLirisTemplateByQuery();
        }

        notify.success({
          text: '移動が完了しました'
        });
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.MOVE
        });
      })
      .finally(() => {
        this.isLoading = false;
        this.closeMoveModal();
      });
  }

  closeMoveModal() {
    this.clearAllCheckbox();
    this.isShowingMoveObjectModal = false;
  }

  // vue-upload-componentを用いたボタンでのアップロードをした際の処理
  async buttonUpload(fileList) {
    // アップロード可能な拡張子のファイルを取得
    const collectFiles = fileList.filter(
      x => /\.(docx)$/i.test(x.name) && !/\.(DS_Store)$/i.test(x.name)
    );
    this.initializeUploadTargetFolder();
    const new_fileList = [];
    const file_names = [];
    for (let i = 0; i < collectFiles.length; i++) {
      if (file_names.indexOf(collectFiles[i].name) == -1) {
        file_names.push(collectFiles[i].name);
        // ファイル、フォルダ名に空白文字が入らないようにする
        fileList[i].name = spaceReplaceUnderScore(fileList[i].name);
        const isCheckFileSize = FileUpload.checkAllowedFileSize(
          fileList[i].size
        );
        if (isCheckFileSize) {
          new_fileList.push(collectFiles[i]);
        }
      }
    }

    // 拡張子偽装確認、あれば処理中止
    const is_fake_extension = await FileUpload.isFakeExtension(new_fileList);
    if (is_fake_extension) {
      return;
    }

    this.fileList = new_fileList;
    if (this.fileList.length) {
      this.addFilesNum(this.fileList.length);
      this.showUploadDialog = true;
    } else {
      this.showUploadDialog = false;
    }
    this.isShowingUploadMenu = false;
  }

  /**
   * アップロードエラーをハンドリング
   */
  uploadError(err) {
    notify.error({
      text: (err && err.message) || NOTIFY_TEXT.ERROR.UPLOAD_FILE
    });
  }

  /**
   * アップロードに使用するプロパティを初期化する
   */
  initializeUploadProperty(): void {
    this.file_upload = false;
    this.uploading_files_num = 0;
    this.isLoading = false;
  }

  async onSearch() {
    if (!this.keyword) return;

    const keyword = this.keyword.trim();

    const type = this.isClientTabSelected
      ? TEMPLATE_TYPES.CLIENT_TEMPLATE
      : TEMPLATE_TYPES.LIRIS_TEMPLATE;

    TemplateInfoModule.SET_SELECTED_DIRECTORIES(this.selectedDirectories);

    this.selectedDirectories = [
      {
        name: ROOT_DIRECTORY_NAME,
        path: ''
      }
    ];

    this.isLoading = true;
    if (this.isClientTabSelected) {
      await TemplateInfoModule.searchClientTemplate(keyword);
      this.clientTemplatesTableBodyData = await TemplateInfoModule.clientTemplatesTableBodyData;
    } else {
      await TemplateInfoModule.searchLirisTemplate(keyword);
      this.lirisTemplatesTableBodyData = await TemplateInfoModule.lirisTemplatesTableBodyData;
    }
    this.isLoading = false;
  }

  async clearKeyword() {
    this.keyword = '';
  }
}
