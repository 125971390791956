








import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class MenuContent extends Vue {
  @Prop({ default: '' })
  text?: string;

  @Prop({ default: false })
  disabled?: boolean;

  onClick() {
    if (this.disabled) return;

    this.$emit('click', this.text);
  }
}
