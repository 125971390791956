



































































import { Component, Vue } from 'vue-property-decorator';
import ImageContent from '@/components/atoms/images/ImageContent.vue';

@Component({
  components: {
    ImageContent
  }
})
export default class AppNotifications extends Vue {
  isShownCancelButton(propsItem) {
    return (
      propsItem.type === 'cancelable' &&
      propsItem.data &&
      typeof propsItem.data.cancelMethod === 'function'
    );
  }
  handleCancelMethod(data) {
    data.cancelMethod();
  }
}
