import store from '@/store';
import CorporateInfo from '@/store/CorporateInfo';
import HomeComponentInfo from '@/store/HomeComponentInfo';
import SearchInfo from '@/store/SearchInfo';
import Vue from 'vue';
import VueCookies from 'vue-cookies-ts';
import Vuex from 'vuex';
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';

const axios = require('axios').default;

import HTTP_STATUS from '@/consts/HttpStatus';
import TAB_MENUS from '@/consts/TabMenus';

import { LirisNotificationList } from '@/interfaces/LirisNotification';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import TemplateInfoRepository from '@/repositories/TemplateInfoRepository';

const DocumentRepository = RepositoryFactory.get('documents');
const LirisNotificationRepository = RepositoryFactory.get('lirisNotifications');

Vue.use(VueCookies);
Vue.use(Vuex);

type Folder = {
  directory_path: string;
  directory_name: string;
  directory_name_furigana?: string;
  directory_id?: string;
  is_shared?: boolean;
};

type ContractType = {
  contract_type_id: string;
  contract_type_name: string;
};

interface ContractLabel {
  label_id: string;
  label_name: string;
  background_color: string;
  font_color: string;
}

type ContractInfoBase = {
  contract_id: string;
  file_extension: string;
  contract_title: string;
  counterparty_name: string;
  counterparty_name_frigane?: string;
  contract_date: string;
  expiration: string;
  auto_update_date: string;
  contract_labels: ContractLabel[];
  contract_type: ContractType;
  client_position: string;
  description: string;
  transaction_amount: any;
  contract_start_date: any;
  auto_update: boolean;
  auto_update_rule: any;
  is_shared: boolean;
  is_scheduled_end: boolean;
  is_none_expiration: boolean;
  is_ended: boolean;
  directory_path: string;
  attributes: any;
  is_already_autoextraction: boolean;
};

interface UserInfo {
  user_id: string;
  user_name: string;
  department_name: string;
  avatar_name: string;
  avatar_color: string;
}

interface Contributer {
  user_id: string;
  user_name: string;
}

interface MentionUser {
  user_id: string;
  user_name: string;
}

interface CommentInfo {
  comment_id: string;
  comment: string;
  created_at: string;
  contributer: Contributer;
  mention_list: MentionUser[];
  is_edited: boolean;
  can_delete: boolean;
  can_edit: boolean;
}

interface KanbanCardStatus {
  before_status_column_id: string;
  before_status_column_name: string;
  after_status_column_id: string;
  after_status_column_name: string;
}

interface ChangeAssignee {
  before_assignee_name: string;
  after_assignee_name: string;
}

interface CommentContract {
  is_comment: boolean;
  user_info: UserInfo;
  created_at: string;
  comment_info: CommentInfo;
  kanban_card_status: KanbanCardStatus;
  change_assignee: ChangeAssignee;
}

interface ContractVersion {
  contract_version_id: string;
  version: string;
  file_name: string;
  file_extension: string;
  download_link: string;
  last_updated_user_name: string;
  last_updated_at: string;
  is_last_version: boolean;
}

interface EditCommentItem {
  comment_info: CommentInfo;
  created_at: string;
  is_comment: boolean;
  kanban_card_status: any;
  user_info: UserInfo;
}

interface ContractAttribute {
  key: string;
  type: string;
  order: number;
  default: boolean;
}

interface GMOSignInfo {
  document_status?: string;
  xid?: string;
}

interface ContractDetail {
  is_stored: boolean;
  is_signed: boolean;
  note: any;
  control_number: any;
  last_updated_at: string;
  last_updated_by: string;
  auto_extraction_done: boolean;
  directory_path: string;
  contract_info: ContractInfoBase;
  attachment_list: any;
  associated_contract_list: any;
  activity_list: CommentContract[];
  stakeholders: UserInfo[];
  contract_version_list: ContractVersion[];
  kanban_card_info: KanbanCardInfo;
  auto_extractions: any;
  attributes: ContractAttribute[];
  gmosign_info?: GMOSignInfo;
}

interface KanbanCardUser {
  avatar_color: string;
  avatar_name: string;
  department_name: string;
  role_name: string;
  user_id: string;
  user_name: string;
}

interface KanbanCardInfo {
  assignee: KanbanCardUser;
  can_back: boolean;
  can_cancel_signed: boolean;
  can_finish: boolean;
  can_next: boolean;
  can_upload_signed_file: boolean;
  card_id: string;
  column_id: string;
  column_name: string;
  created_at: string;
  created_by: KanbanCardUser;
  task_expiration: string;
  is_archived: boolean;
  archived_at: string;
}

// @see https://api.liris.work/contract-management/docs#/%E3%83%87%E3%82%A3%E3%83%AC%E3%82%AF%E3%83%88%E3%83%AA%E6%93%8D%E4%BD%9C/directory_move_trash_contract_management_directories_move_trash_put
interface TempDeletedFolder {
  directory_path: string;
}

// @see https://api.liris.work/contract-management/docs#/%E5%A5%91%E7%B4%84%E6%9B%B8%E6%93%8D%E4%BD%9C/contracts_move_to_trash_contract_management_contracts_move_trashes_put
interface TempDeletedFile {
  contract_id: string;
}

interface UploadJob {
  file_extension: string;
  file_name: string;
  group_id: string;
  job_id: string;
  upload_status: number;
}

interface FileStatus {
  contract_id: string;
  ocr_status: number;
  text_extraction_status: number;
  autoextraction_status: number;
  search_text_status: number;
  annotation_status: number;
  pdf2html_status: number;
}

const attachFileLimit: number = 15;

@Module({ dynamic: true, store: store, name: 'documents', namespaced: true })
export class Documents extends VuexModule {
  reloadRequestSources = [];
  current_directory: string = '/';
  current_directory_id: string = '';
  directory_uri: string = '';
  selected_files_of_table: ContractInfoBase[] = [];
  selected_folders_of_table: Folder[] = [];
  selected_trash_files_of_table: ContractInfoBase[] = [];
  selected_trash_folders_of_table: Folder[] = [];
  trashes: any = [];
  selected_trashes: any = [];
  files: ContractInfoBase[] = [];
  folders: Folder[] = [];
  recently_viewed_files: any = [];
  selectedFile: any = null;
  notifications: any = [];
  lirisNotifications: LirisNotificationList[] = [];
  control_numbers: string[] = [];
  contract_attributes: ContractAttribute[] = [];
  contract_labels: ContractLabel[] = [];

  allContractsDownloadJobId: string = '';
  selectedContractsDownloadJobId: string = '';

  is_only_comment: boolean = false;
  is_edit: boolean = false;
  is_trash_recovor: boolean = false;
  is_loading_trashes: boolean = false;

  activities: any = [];

  contract_detail: ContractDetail = {
    is_signed: false,
    is_stored: false,
    note: null,
    control_number: null,
    last_updated_at: null,
    last_updated_by: null,
    auto_extraction_done: null,
    directory_path: null,
    contract_info: {
      contract_id: null,
      is_shared: false,
      file_extension: null,
      contract_title: null,
      counterparty_name: null,
      contract_date: null,
      expiration: null,
      auto_update_date: null,
      directory_path: null,
      contract_labels: [],
      contract_type: {
        contract_type_id: null,
        contract_type_name: null
      },
      description: null,
      transaction_amount: 0,
      client_position: null,
      contract_start_date: null,
      auto_update: null,
      auto_update_rule: {
        is_active: false,
        pre_notice_number: null,
        pre_notice_unit: null,
        auto_update_number: null,
        auto_update_unit: null
      },
      is_scheduled_end: false,
      is_none_expiration: false,
      is_ended: false,
      attributes: [],
      is_already_autoextraction: true
    },
    activity_list: [],
    stakeholders: [],
    attachment_list: [],
    associated_contract_list: [],
    contract_version_list: [],
    kanban_card_info: null,
    auto_extractions: null,
    attributes: null,
    gmosign_info: {
      xid: null,
      document_status: null
    }
  };

  file_status: FileStatus = null;
  already_fetch_contract: boolean = false;

  selectedVersion = null;
  previewHtml: string = '';
  previewPdfLink: string = '';
  // 削除の取り消しのために一時的に格納するメンバ
  temp_deleted_folders: TempDeletedFolder[] = [];
  temp_deleted_files: TempDeletedFile[] = [];

  // ちゃんと初期値設定しておかないとWatchで検知できなかったためこれだけ型定義なども正確にしている
  editCommentItem: EditCommentItem = {
    comment_info: {
      comment_id: '',
      comment: '',
      created_at: '',
      contributer: null,
      mention_list: [],
      is_edited: false,
      can_delete: false,
      can_edit: false
    },
    created_at: '',
    is_comment: false,
    kanban_card_status: null,
    user_info: null
  };

  mention_users: any[] = [];

  is_back_page: boolean = false;

  is_delete_card: boolean = false;

  selectedTabForRecentlyViewed: string = TAB_MENUS.CONTRACTED_DOCUMENT;

  uploadJobs: UploadJob[] = [];

  uploadJobGroupIds: string[] = [];

  isRunningUploadJob: boolean = false;

  isDeletingFoldersAndFiles: boolean = false;

  fetchingContractDetail: boolean = false;

  get contractDetail(): ContractDetail {
    return this.contract_detail;
  }

  get isSigned(): boolean {
    return this.contractDetail.is_signed;
  }

  get attachFileLimit(): number {
    return attachFileLimit;
  }

  @Mutation
  SET_ALREADY_FETCH_CONTRACT(bool: boolean) {
    this.already_fetch_contract = bool;
  }

  @Mutation
  SET_CONTRACT_DETAIL_PROPS(obj: Record<string, any>) {
    for (const [key, value] of Object.entries(obj)) {
      this.contract_detail[key] = value;
    }
  }

  @Mutation
  SET_CONTRACT_INFO_PROPS(obj: Record<string, any>) {
    for (const [key, value] of Object.entries(obj)) {
      this.contract_detail.contract_info[key] = value;
    }
  }

  @Mutation
  SET_CONTRACT_INFO_CUSTOM_ATTRIBUTE_PROP(target: any) {
    const attribute = this.contract_detail.contract_info.attributes.find(
      attr => attr.id === target.contract_attribute_id
    );
    if (attribute) {
      attribute.values.unshift(target);
    }
  }

  @Mutation
  SET_KANBAN_CARD_PROPS(obj: Record<string, any>) {
    for (const [key, value] of Object.entries(obj)) {
      this.contract_detail.kanban_card_info[key] = value;
    }
  }

  @Mutation
  RESET_SELECTED_TABLE() {
    this.selected_files_of_table = [];
    this.selected_folders_of_table = [];
  }

  @Mutation
  SET_SELECTED_FILES_OF_TABLE(selected_files_of_table: ContractInfoBase[]) {
    this.selected_files_of_table = selected_files_of_table;
  }

  @Mutation
  SET_SELECTED_FOLDERS_OF_TABLE(selected_folders_of_table: Folder[]) {
    this.selected_folders_of_table = selected_folders_of_table;
  }

  @Mutation
  SET_SELECTED_TRASH_FILES_OF_TABLE(
    selected_trash_files_of_table: ContractInfoBase[]
  ) {
    this.selected_trash_files_of_table = selected_trash_files_of_table;
  }

  @Mutation
  SET_SELECTED_TRASH_FOLDERS_OF_TABLE(
    selected_trash_folders_of_table: Folder[]
  ) {
    this.selected_trash_folders_of_table = selected_trash_folders_of_table;
  }

  @Mutation
  SET_TEMP_DELETED_FILES(tempDeletedFiles: TempDeletedFile[]) {
    this.temp_deleted_files = tempDeletedFiles;
  }

  @Mutation
  SET_TEMP_DELETED_FOLDERS(tempDeletedFolders: TempDeletedFolder[]) {
    this.temp_deleted_folders = tempDeletedFolders;
  }

  @Mutation
  SET_CURRENT_DIRECTORY(current_directory: string) {
    this.current_directory = current_directory;
    if (this.current_directory == '/') {
      this.directory_uri = encodeURI(CorporateInfo.corporation_id);
    } else {
      this.directory_uri = encodeURI(
        String(CorporateInfo.corporation_id + this.current_directory)
      );
    }
  }

  @Mutation
  SET_CURRENT_DIRECTORY_ID(current_directory_id: string) {
    this.current_directory_id = current_directory_id;
  }

  @Action({ rawError: true })
  async getDirectoryInfo(currentDirectory: string) {
    DocumentRepository.getDirectoryInfo(currentDirectory)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_CURRENT_DIRECTORY_ID(res.data[0].directory_id);
        return Promise.resolve(res);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  @Action({ rawError: true })
  async getRootDirectory() {
    return await DocumentRepository.getRootDirectory()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_CURRENT_DIRECTORY_ID(res.data.directory_id);
        return Promise.resolve(res);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  @Mutation
  SET_NOTIFICATIONS(notifications: any) {
    this.notifications = notifications;
  }

  @Mutation
  SET_LIRIS_NOTIFICATIONS(lirisNotifications: LirisNotificationList[]) {
    this.lirisNotifications = lirisNotifications;
  }

  @Mutation
  SET_ACTIVITIES(activities: CommentContract[]) {
    this.activities = activities;
  }

  @Action({ rawError: true })
  async getNotifications() {
    return await DocumentRepository.getNotifications()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        this.SET_NOTIFICATIONS(res.data);
        return Promise.resolve(res);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  @Action({ rawError: true })
  async getLirisNotifications() {
    return await LirisNotificationRepository.index()
      .then(res => {
        // ヘルプセンターの情報をお知らせの先頭に追加
        const helpCenterNotification: LirisNotificationList = {
          liris_notification_id: '',
          title: 'ヘルプセンター',
          contents: '',
          url:
            'https://tayori.com/faq/b06d57c51f1b3d4af81b4f168f8f1af7dab460fc',
          is_read: true,
          type: 0,
          date: ''
        };

        res.data.unshift(helpCenterNotification);

        this.SET_LIRIS_NOTIFICATIONS(res.data);
        return Promise.resolve(res);
      })
      .catch(e => {
        return Promise.reject(e);
      });
  }

  @Action({ rawError: true })
  async change_current_directory(current_directory: string) {
    await this.SET_CURRENT_DIRECTORY(current_directory);
    await this.getFilesAndFolders();
  }

  @Mutation
  SET_FILES(files: ContractInfoBase[]) {
    this.files = files;
  }

  @Mutation
  SET_FOLDERS(folders: Folder[]) {
    this.folders = folders;
  }

  @Action({ rawError: true })
  async update_document_info() {
    HomeComponentInfo.change_tables_countup();
    await this.getFilesAndFolders();
    this.RESET_SELECTED_TABLE();
  }

  get searching(): boolean {
    return SearchInfo.searching;
  }

  @Action({ rawError: true })
  async getFilesAndFolders() {
    return await DocumentRepository.getFilesAndFolders(this.current_directory)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        // 検索中はフェッチしてても反映しない
        if (!this.searching) {
          this.SET_FILES(res.data['contract_list']);
          this.SET_FOLDERS(res.data['directory_list']);
        }

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async search(params) {
    return await DocumentRepository.getSearchFilesAndFoldersResult(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_FILES(res.data.contract_list);
        this.SET_FOLDERS(res.data.directory_list);
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Mutation
  SET_SELECTEDFILE(selectedFile: any) {
    this.selectedFile = selectedFile;
  }

  @Mutation
  SET_PREVIEW_HTML(previewHtml: any) {
    this.previewHtml = previewHtml;
  }

  @Mutation
  SET_PREVIEW_PDF_LINK(link: string) {
    this.previewPdfLink = link;
    Vue.cookies.set('pdf_link', link);
  }

  @Mutation
  SET_CONTRACT_DETAIL(info: ContractDetail) {
    this.contract_detail = info;
  }

  @Mutation
  SET_IS_ONLY_COMMENT(is_only_comment: boolean) {
    this.is_only_comment = is_only_comment;
  }

  @Mutation
  SET_IS_EDIT(is_edit: boolean) {
    this.is_edit = is_edit;
  }

  @Mutation
  SET_RECENTLY_VIEWED_FILES(files: any) {
    this.recently_viewed_files = files;
  }

  @Mutation
  SET_SELECTED_TAB_FOR_RECENTLY_VIEWED(tabName: string) {
    this.selectedTabForRecentlyViewed = tabName;
  }

  @Mutation
  SET_CONTROL_NUMBERS(numbers: any) {
    this.control_numbers = numbers;
  }

  @Mutation
  SET_MENTION_USERS(users: any) {
    this.mention_users = users;
  }

  @Mutation
  SET_UPLOAD_JOBS(uploadJobs: any) {
    this.uploadJobs = uploadJobs;
  }

  @Mutation
  SET_UPLOAD_JOB_GROUP_IDS(groupIds: any) {
    this.uploadJobGroupIds = groupIds;
  }

  @Mutation
  SET_IS_RUNNING_UPLOAD_JOB(isRunningUploadJob: boolean) {
    this.isRunningUploadJob = isRunningUploadJob;
  }

  @Mutation
  SET_IS_DELETING_FOLDER_AND_FILES(isDeletingFoldersAndFiles: boolean) {
    this.isDeletingFoldersAndFiles = isDeletingFoldersAndFiles;
  }

  @Mutation
  SET_FETCHING_CONTRACT_DETAIL(fetchingContractDetail: boolean) {
    this.fetchingContractDetail = fetchingContractDetail;
  }

  /**
   * 締結管理：新規依頼作成時にメンション可能ユーザーを取得
   */
  @Action({ rawError: true })
  async getCanMentionUsers() {
    return await DocumentRepository.getCanMentionUsers()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_MENTION_USERS(res.data);
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async getRecentlyViewedFiles(isSigned) {
    return await DocumentRepository.getRecentlyViewedFiles(isSigned)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_RECENTLY_VIEWED_FILES(res.data);
        return Promise.resolve(res);
      })
      .catch(err => {
        this.SET_RECENTLY_VIEWED_FILES([]);
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async getControlNumbers() {
    return await DocumentRepository.getControlNumbers()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_CONTROL_NUMBERS(res.data);
        return Promise.resolve(res);
      })
      .catch(err => {
        this.SET_CONTROL_NUMBERS([]);
        return Promise.reject(err);
      });
  }

  @Mutation
  SET_DETAIL_ONLY_AUTO_EXTRACTION_ITEM(data: any) {
    this.contract_detail.contract_info = Object.assign(
      {},
      this.contract_detail.contract_info,
      data.contract_info
    );
  }

  @Action({ rawError: true })
  async getContractDetailOnlyAutoextractionItems(
    contractId: string
  ): Promise<any> {
    this.SET_FETCHING_CONTRACT_DETAIL(true);
    return await DocumentRepository.getContractDetail(contractId)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        const data = res.data;
        if (data) {
          this.SET_DETAIL_ONLY_AUTO_EXTRACTION_ITEM(data);
        }
      })
      .catch(err => {
        return Promise.reject(err);
      })
      .finally(() => {
        this.SET_FETCHING_CONTRACT_DETAIL(false);
      });
  }

  @Action({ rawError: true })
  async getContractDetail(contractId: string): Promise<any> {
    this.SET_FETCHING_CONTRACT_DETAIL(true);
    // TODO: axios cancel処理
    return await DocumentRepository.getContractDetail(contractId)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        const data = res.data;

        const attributes = await DocumentRepository.getContractAttributes(
          contractId
        );
        if (!attributes) {
          return Promise.reject(attributes);
        }
        data.contract_info.attributes = attributes.data;

        this.SET_CONTRACT_DETAIL(data);
        this.SET_ACTIVITIES(data.activity_list);
        if (data.contract_version_list.length > 0 && !this.selectedVersion) {
          const version = data.contract_version_list[0];
          this.SELECT_VERSION(version);
          this.SET_PREVIEW_HTML('');
          this.getVersionPreviewPdfLink(version.contract_version_id);
        }
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      })
      .finally(() => {
        this.SET_FETCHING_CONTRACT_DETAIL(false);
      });
  }

  @Action({ rawError: true })
  async selectVersion(version) {
    if (version) {
      this.SELECT_VERSION(version);
      this.getVersionPreviewPdfLink(version.contract_version_id);
    }
  }

  @Action({ rawError: true })
  async reloadContractDetail(): Promise<any> {
    while (this.reloadRequestSources.length) {
      const source = this.reloadRequestSources.shift();
      source.cancel();
    }
    const contract_id = this.contract_detail.contract_info.contract_id;
    if (!contract_id) {
      throw new Error(
        '[warn] 事前にgetContractDetailでデータを取得してください'
      );
    }
    return this.getContractDetail(contract_id);
  }

  @Action({ rawError: true })
  async updateContractDetail({
    contract_id,
    is_stored,
    note,
    contract_type,
    contract_labels,
    description,
    contract_start_date,
    auto_update_rule,
    contract_title,
    counterparty_name,
    contract_date,
    expiration,
    auto_update_date,
    control_number,
    is_scheduled_end,
    is_none_expiration,
    is_ended
  }): Promise<void> {
    if (auto_update_rule && !auto_update_rule.is_active) {
      auto_update_rule = {
        is_active: false,
        auto_update_number: null,
        auto_update_unit: null,
        pre_notice_number: null,
        pre_notice_unit: null
      };
    }
    // storeの情報書き換えるだけ。Mutationに切り出してもここだけの利用で煩雑になりそうだったので一旦ここで実行
    if (is_stored != undefined || is_stored != null)
      this.contract_detail.is_stored = is_stored;
    if (note) this.contract_detail.note = note;
    if (contract_type && contract_type.contract_type_id != undefined)
      this.contract_detail.contract_info.contract_type = contract_type;
    if (contract_labels && contract_labels.length > 0)
      this.contract_detail.contract_info.contract_labels = contract_labels;
    // 空文字を許可する
    if (description != null)
      this.contract_detail.contract_info.description = description;
    if (contract_start_date != null)
      this.contract_detail.contract_info.contract_start_date = contract_start_date;
    if (auto_update_rule != undefined)
      this.contract_detail.contract_info.auto_update_rule = auto_update_rule;
    // 空文字を許可する
    if (contract_title != null)
      this.contract_detail.contract_info.contract_title = contract_title;
    // 空文字を許可する
    if (counterparty_name != null)
      this.contract_detail.contract_info.counterparty_name = counterparty_name;
    if (contract_date != null)
      this.contract_detail.contract_info.contract_date = contract_date;
    if (expiration != null)
      this.contract_detail.contract_info.expiration = expiration;
    if (auto_update_date != null)
      this.contract_detail.contract_info.auto_update_date = auto_update_date;
    // 空文字を許可する
    if (control_number != null)
      this.contract_detail.control_number = control_number;
    if (is_scheduled_end != undefined || is_scheduled_end != null)
      this.contract_detail.contract_info.is_scheduled_end = is_scheduled_end;
    if (is_none_expiration != undefined || is_none_expiration != null)
      this.contract_detail.contract_info.is_none_expiration = is_none_expiration;
    if (is_ended != undefined || is_ended != null)
      this.contract_detail.contract_info.is_ended = is_ended;

    const contractTypeId = contract_type
      ? contract_type.contract_type_id
      : null;
    const contractLabelIds = contract_labels
      ? contract_labels.map(v => v.label_id)
      : null;
    const contractVersionId = this.selectedVersion
      ? this.selectedVersion.contract_version_id
      : null;

    const params = {
      contract_id,
      is_stored,
      note,
      contract_type_id: contractTypeId,
      label_id_list: contractLabelIds,
      description,
      contract_start_date,
      auto_update_rule,
      contract_title,
      counterparty_name,
      contract_date,
      expiration,
      auto_update_date,
      control_number,
      is_scheduled_end,
      is_none_expiration,
      is_ended,
      contract_version_id: contractVersionId
    };

    return await DocumentRepository.updateContractDetail(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          return Promise.reject(res);
        }
        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  /**
   * 削除対象のファイルもしくはフォルダがある場合、それらを実行して Promise を返す。
   */
  @Action({ rawError: true })
  restoreDeletedFileOrFolder() {
    const requests = [];
    if (this.temp_deleted_files.length) {
      requests.push(this.restoreContractFiles());
    }
    if (this.temp_deleted_folders.length) {
      requests.push(this.restoreContractFolders());
    }
    return Promise.all(requests);
  }

  /**
   * ファイルの削除を取り消す処理。
   */
  @Action({ rawError: true })
  async restoreContractFiles() {
    return await DocumentRepository.restoreContractFiles(
      this.temp_deleted_files
    )
      .then(async res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          return Promise.reject(res);
        }

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      })
      .finally(() => {
        this.SET_TEMP_DELETED_FILES([]);
      });
  }

  /**
   * フォルダの削除を取り消す処理。
   */
  @Action({ rawError: true })
  async restoreContractFolders() {
    return await DocumentRepository.restoreContractFolders(
      this.temp_deleted_folders
    )
      .then(async res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          return Promise.reject(res);
        }

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      })
      .finally(() => {
        this.SET_TEMP_DELETED_FOLDERS([]);
      });
  }

  @Action({ rawError: true })
  async getVersionPreviewHtml(
    contractVersionId: string,
    ext: string = 'html',
    is_link: boolean = false
  ) {
    return await DocumentRepository.getVersionPreviewHtml(contractVersionId)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_PREVIEW_HTML(res.data);

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async getVersionPreviewPdfLink(
    contractVersionId: string,
    ext: string = 'pdf',
    is_link: boolean = true
  ) {
    return await DocumentRepository.getVersionPreviewHtml(
      contractVersionId,
      ext,
      is_link
    )
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_PREVIEW_PDF_LINK(res.data);

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Mutation
  SET_EDIT_COMMENT_ITEM(item) {
    this.editCommentItem = item;
  }

  @Mutation
  SET_TRASHES(trashes) {
    this.trashes = trashes;
  }

  @Mutation
  SELECT_VERSION(version) {
    this.selectedVersion = version;
  }

  @Mutation
  SET_IS_TRASH_RECOVOR(is_trash_recovor) {
    this.is_trash_recovor = is_trash_recovor;
  }

  @Mutation
  SET_IS_BACK_PAGE(result) {
    this.is_back_page = result;
  }

  @Mutation
  SET_IS_DELETE_CARD(data: boolean) {
    this.is_delete_card = data;
  }

  @Mutation
  SET_IS_LOADING_TRASHES(bool: boolean) {
    this.is_loading_trashes = bool;
  }

  @Mutation
  SET_CONTRACT_ATTRIBUTES(attributes: ContractAttribute[]) {
    this.contract_attributes = attributes;
  }

  @Mutation
  SET_CONTRACT_LABELS(labels: ContractLabel[]) {
    this.contract_labels = labels;
  }

  @Action({ rawError: true })
  async getComments(contractId: string) {
    return await DocumentRepository.getComments(contractId)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_ACTIVITIES(res.data);

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async postComment(params) {
    return await DocumentRepository.postComment(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_ACTIVITIES(res.data);

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async updateComment(params) {
    return await DocumentRepository.updateComment(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_ACTIVITIES(res.data);
        const item = {
          comment_info: {
            comment_id: '',
            comment: '',
            created_at: '',
            contributer: null,
            mention_list: [],
            is_edited: false,
            can_delete: false,
            can_edit: false
          },
          created_at: '',
          is_comment: false,
          kanban_card_status: null,
          user_info: null
        };
        this.SET_EDIT_COMMENT_ITEM(item);

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async deleteComment(commentId) {
    return await DocumentRepository.deleteComment(commentId)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        await this.getComments(this.contract_detail.contract_info.contract_id);

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async getTrashes(params) {
    if (this.is_loading_trashes) {
      return;
    }
    this.SET_IS_LOADING_TRASHES(true);
    return await DocumentRepository.getTrashes(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        this.SET_TRASHES(
          res.data.directory_list.concat(res.data.contract_list)
        );

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      })
      .finally(() => {
        this.SET_IS_LOADING_TRASHES(false);
      });
  }

  @Action({ rawError: true })
  async putTrashes(params) {
    const directory_path_list = this.selected_trash_folders_of_table.map(
      folder => {
        return folder.directory_path;
      }
    );

    const contract_id_list = this.selected_trash_files_of_table.map(file => {
      return file.contract_id;
    });

    params.directory_path_list = directory_path_list;
    params.contract_id_list = contract_id_list;

    return await DocumentRepository.putTrashes(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        this.SET_TRASHES(
          res.data.directory_list.concat(res.data.contract_list)
        );

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async deleteTrashes(params) {
    const directory_path_list: string[] = this.selected_trash_folders_of_table.map(
      folder => {
        return folder.directory_path;
      }
    );

    const contract_id_list: string[] = this.selected_trash_files_of_table.map(
      file => {
        return file.contract_id;
      }
    );

    params.directory_path_list = directory_path_list;
    params.contract_id_list = contract_id_list;

    return await DocumentRepository.deleteTrashes(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        if (Array.isArray(res.data.directory_list)) {
          this.SET_TRASHES(
            res.data.directory_list.concat(res.data.contract_list)
          );
        }

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      })
      .finally(() => {
        this.SET_SELECTED_TRASH_FILES_OF_TABLE([]);
        this.SET_SELECTED_TRASH_FOLDERS_OF_TABLE([]);
      });
  }

  @Action({ rawError: true })
  async deleteAllTrashes(isSigned: boolean) {
    return await DocumentRepository.deleteAllTrashes(isSigned)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }

        this.SET_TRASHES([]);

        return Promise.resolve(res);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async getContractAttributes() {
    return await DocumentRepository.getContractAttributes()
      .then(async response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }

        this.SET_CONTRACT_ATTRIBUTES(response.data);

        return Promise.resolve(response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async postContractAttributes(params) {
    return await DocumentRepository.postContractAttributes(params)
      .then(async response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        return Promise.resolve(response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async updateContractAttributes(params) {
    return await DocumentRepository.updateContractAttributes(params)
      .then(async response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        return Promise.resolve(response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Action({ rawError: true })
  async deleteContractAttributes(contractAttributeId) {
    return await DocumentRepository.deleteContractAttributes(
      contractAttributeId
    )
      .then(async response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        return Promise.resolve(response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  /**
   * 契約書追加項目のselectオプション行を追加
   */
  @Action({ rawError: true })
  async postContractAttributeItem(params) {
    return await DocumentRepository.postContractAttributeItem(params)
      .then(async response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        return Promise.resolve(response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  /**
   * 契約書追加項目のselectオプション行を更新
   */
  @Action({ rawError: true })
  async updateContractAttributeItem(params) {
    return await DocumentRepository.updateContractAttributeItem(params)
      .then(async response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        return Promise.resolve(response);
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  /**
   * 契約書ラベルの一覧を取得
   */
  @Action({ rawError: true })
  async getContractLabels() {
    return await DocumentRepository.getContractLabels()
      .then(response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        this.SET_CONTRACT_LABELS(response.data);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Mutation
  SET_FILESTATUS(data) {
    this.file_status = data;
  }

  /**
   * ファイルの処理ステータスを取得
   */
  @Action({ rawError: true })
  async getContractFileProcessStatus(contractId) {
    return await DocumentRepository.getContractFileProcessStatus(contractId)
      .then(response => {
        if (response.status !== HTTP_STATUS.OK) {
          return Promise.reject(response);
        }
        this.SET_FILESTATUS(response.data);
        return Promise.resolve(response);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Mutation
  SET_ALL_CONTRACTS_DOWNLOAD_JOB_ID(job_id) {
    this.allContractsDownloadJobId = job_id;
  }

  /**
   * ダウンロードジョブの登録
   */
  @Action({ rawError: true })
  async postAllContractDownloadJob() {
    return await DocumentRepository.postAllContractDownloadJob()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_ALL_CONTRACTS_DOWNLOAD_JOB_ID(res.data.job_id);
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Mutation
  SET_SELECTED_CONTRACTS_DOWNLOAD_JOB_ID(job_id) {
    this.selectedContractsDownloadJobId = job_id;
  }

  /**
   * ダウンロードジョブの登録
   */
  @Action({ rawError: true })
  async postSelectedContractDownloadJob(params) {
    return await DocumentRepository.postSelectedContractDownloadJob(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_SELECTED_CONTRACTS_DOWNLOAD_JOB_ID(res.data.job_id);
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /**
   * ダウンロードジョブの登録
   */
  @Action({ rawError: true })
  async doCompleteDownloadJobs() {
    return await TemplateInfoRepository.getMyDownloadJobIds()
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        const download_job_ids = res.data;
        if (download_job_ids.length < 1) {
          return Promise.resolve(res);
        }
        for (const job_id of download_job_ids) {
          await TemplateInfoRepository.getDownloadLink({ job_id: job_id })
            .then(res => {
              if (res.status !== HTTP_STATUS.OK) {
                return Promise.reject(res);
              }
              let downloadLink = res.data;
              if (downloadLink) {
                const link = document.createElement('a');
                link.href = downloadLink;
                link.click();
                downloadLink = '';
              }
              return Promise.resolve(res);
            })
            .catch(err => {
              console.log(err);
              return Promise.reject(err);
            });
        }
        return Promise.resolve(res);
      })
      .catch(err => {
        console.log(err);
        return Promise.reject(err);
      });
  }

  @Mutation
  DELETE_GMOSIGN() {
    this.contract_detail.gmosign_info.xid = null;
    this.contract_detail.gmosign_info.document_status = null;
  }
}

const DocumentsModule = getModule(Documents);

export {
  ContractInfoBase,
  EditCommentItem,
  MentionUser,
  UserInfo,
  GMOSignInfo
};
export default DocumentsModule;
