











































































































import { Component, Vue, Prop } from 'vue-property-decorator';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import ContractLabels from '@/components/molecules/labels/ContractLabels.vue';
import UserInfo from '@/store/UserInfo';
import AUTHORITIES from '@/consts/Authorities.js';
import { dateFormatter } from '@/functions/FormatDate';

import SettingsInfoModule, {
  AlertLimit,
  AlertLimitType
} from '@/store/SettingsInfo';

@Component({
  components: {
    ImageContent,
    ContractLabels
  }
})
export default class ObjectTableBodyRow extends Vue {
  @Prop({ required: true }) obj;
  @Prop({ default: false }) value: any[];
  @Prop()
  isContractedDocumentTabSelected: boolean;

  get checkId() {
    return `chk_${this.obj.contract_id || this.obj.directory_path}`;
  }

  get isGuest() {
    return UserInfo.authority === AUTHORITIES.GUEST;
  }

  get AlertLimitType() {
    return AlertLimitType;
  }

  get alertStatus(): AlertLimit {
    return SettingsInfoModule.alertStatus(
      this.obj.expiration,
      this.obj.auto_update_date,
      this.obj.is_scheduled_end,
      this.obj.is_ended
    );
  }

  get itemColor() {
    switch (this.alertStatus.type) {
      case AlertLimitType.DoUpdateOrScheduledEnd:
      case AlertLimitType.DoScheduledEnd:
        return 'item-alert';
      case AlertLimitType.End:
        return 'item-end';
    }
    return 'item-normal';
  }

  get selected(): boolean {
    return (
      this.value.filter(item => {
        if (this.obj.contract_id) {
          return item.contract_id == this.obj.contract_id;
        } else {
          return item.directory_path == this.obj.directory_path;
        }
      }).length > 0
    );
  }

  set selected(v) {
    if (v) {
      this.value.push(this.obj);
    } else {
      this.value.forEach((item, index) => {
        if (this.obj.contract_id) {
          if (this.obj.contract_id === item.contract_id) {
            this.value.splice(index, 1);
          }
        } else {
          if (this.obj.directory_path === item.directory_path) {
            this.value.splice(index, 1);
          }
        }
      });
    }

    this.$emit('input', this.value);
  }

  formatDate(date) {
    return dateFormatter(date);
  }
}
