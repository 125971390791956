
































































import { PropType } from 'vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { AssociationInfo } from '@/store/Associations';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import SelectContractModalItem from '@/components/molecules/modal_windows/SelectContractModalItem.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';

@Component({
  components: {
    AppButton,
    SelectContractModalItem,
    SelectBox,
    BaseWindow
  }
})
export default class SelectContractModal extends Vue {
  @Prop({ type: Array as PropType<AssociationInfo[]> })
  readonly associated_contracts: AssociationInfo[];
  @Prop({ type: Array as PropType<AssociationInfo[]> })
  readonly candidates: AssociationInfo[];

  @Prop()
  value!: boolean;

  @Prop()
  windowSize!: string;

  @Prop({ default: false })
  approvingContract: boolean;

  internal_select_values: AssociationInfo[] = [];
  select_options_proxy: AssociationInfo[] = [];
  fileLimit: number = 15;

  @Watch('value')
  changeValue(val) {
    if (!val) this.internal_select_values = [];
  }

  get approveButtonDisabled(): boolean {
    return this.selectedContractes.length < 2 || this.approvingContract;
  }

  get approveButtonText(): string {
    return this.approvingContract ? '保存中' : '保存';
  }

  get selectedContractes(): AssociationInfo[] {
    return [...this.associated_contracts, ...this.internal_select_values];
  }

  // 特定の契約書は表示しないようにフィルタする
  // - すでに紐付けとして選択されている契約書
  get selectableCandidates() {
    return this.candidates.filter(candidate => {
      return !this.selectedContractes.find(
        associated => associated.contract_id === candidate.contract_id
      );
    });
  }

  candidatesFilterBy(option, label, search) {
    const searchTargets = [
      label,
      option.file_name,
      option.file_path,
      option.file_extension,
      option.counterparty_name
    ];
    return searchTargets.some(t => {
      return t && t.toLowerCase().indexOf(search.toLowerCase()) > -1;
    });
  }

  @Watch('select_options_proxy')
  onChangeSelectOptionsProxy() {
    if (this.select_options_proxy.length) {
      this.internal_select_values.push(this.select_options_proxy.pop());
    }
  }

  approve() {
    this.$emit('approve', this.selectedContractes);
  }

  removeContract(contract_id) {
    this.internal_select_values.forEach((v, index) => {
      if (v.contract_id === contract_id) {
        this.internal_select_values.splice(index, 1);
      }
    });
  }

  cancel() {
    this.$emit('cancel');
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }
}
