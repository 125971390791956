import store from '@/store';
import Vue from 'vue';
import Vuex from 'vuex';
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';

import HTTP_STATUS from '@/consts/HttpStatus';

import {
  DocumentTypeObject,
  GMOSignAuth,
  GMOSignForm,
  GMOSignMember,
  GMOSignPartnerSigningField,
  GMOSignSigningField,
  GMOSignUserInfo
} from '@/interfaces/GMOSign';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const GMOSignRepository = RepositoryFactory.get('gmosign');

Vue.use(Vuex);

@Module({
  dynamic: true,
  store: store,
  name: 'gmosign',
  namespaced: true
})
export class GMOSignInfo extends VuexModule {
  gmosignAuth: GMOSignAuth = {};
  document_status: string = '0'; // 未署名: 0, 署名済み: 99

  gmosign_users: GMOSignMember[] = [
    {
      user_id: '',
      user_name: '',
      department_name: '',
      gmosign_user_name: '',
      is_gmosign_sign_role: false,
      error: ''
    }
  ];

  current_gmosign_users: GMOSignMember[] = [];
  selected_gmosign_users: GMOSignMember[] = [];

  documentTypeItems = [
    {
      label: '契約印タイプ(立会人型)',
      value: '3'
    },
    {
      label: '実印タイプ(当事者型)',
      value: '2'
    }
  ];

  signForm: GMOSignForm = {
    document_name: '',
    document_type: '3',
    document_type_obj: this.documentTypeItems[0],
    own_organize_nm: '',
    send_user: null,
    own_name_nm: '',
    our_signing_field: [],
    partner_signing_field: [],
    is_invisibled_signature: true,
    contract_id: '',
    is_compilation_mail: '1'
  };

  @Mutation
  RESET_SIGNFORM() {
    this.signForm = {
      document_name: '',
      document_type: '3',
      document_type_obj: this.documentTypeItems[0],
      own_organize_nm: '',
      send_user: null,
      own_name_nm: '',
      our_signing_field: [],
      partner_signing_field: [],
      is_invisibled_signature: true,
      contract_id: '',
      is_compilation_mail: '1'
    };
  }

  @Mutation
  SET_GMOSIGN_AUTH(gmosignAuth: GMOSignAuth) {
    this.gmosignAuth = gmosignAuth;
  }

  @Action({ rawError: true })
  async getGMOSignAuth() {
    return await GMOSignRepository.getGMOSignAuth()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_GMOSIGN_AUTH(res.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async updateGMOSignAuth(params: GMOSignAuth) {
    return await GMOSignRepository.updateGMOSignAuth(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_GMOSIGN_AUTH(res.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async deleteGMOSignAuth() {
    return await GMOSignRepository.deleteGMOSignAuth()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_GMOSIGN_AUTH(res.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Mutation
  SET_GMOSIGN_USERS(users: GMOSignMember[]) {
    this.gmosign_users = users;
  }

  @Mutation
  ADD_GMOSIGN_USERS() {
    this.gmosign_users.push({
      user_id: '',
      user_name: '',
      department_name: '',
      gmosign_user_name: '',
      is_gmosign_sign_role: false,
      error: ''
    });
  }

  @Mutation
  REMOVE_GMOSIGN_USERS(index: number) {
    this.gmosign_users.splice(index, 1);
  }

  @Mutation
  RESET_GMOSIGN_USERS() {
    this.gmosign_users = [
      {
        user_id: '',
        user_name: '',
        department_name: '',
        gmosign_user_name: '',
        is_gmosign_sign_role: false,
        error: ''
      }
    ];
  }

  @Mutation
  SET_GMOSIGN_USER_NAME(payload: { index: number; gmosign_user_name: string }) {
    this.gmosign_users[payload.index].gmosign_user_name =
      payload.gmosign_user_name;
  }

  @Mutation
  SET_GMOSIGN_LIRIS_USER(payload: { index: number; user: any }) {
    this.gmosign_users[payload.index].user_id = payload.user.user_id;
    this.gmosign_users[payload.index].user_name = payload.user.user_name;
  }

  @Mutation
  SET_GMOSIGN_SIGN_ROLE(payload: {
    index: number;
    is_gmosign_sign_role: boolean;
  }) {
    this.gmosign_users[payload.index].is_gmosign_sign_role =
      payload.is_gmosign_sign_role;
  }

  @Mutation
  SET_CURRENT_GMOSIGN_USERS(users: GMOSignMember[]) {
    this.current_gmosign_users = users;
  }

  @Mutation
  RESET_CURRENT_GMOSIGN_USERS() {
    this.current_gmosign_users = [];
  }

  @Mutation
  SET_SELECTED_GMOSIGN_USERS(users: GMOSignMember[]) {
    this.selected_gmosign_users = users;
  }

  @Mutation
  RESET_SELECTED_GMOSIGN_USERS() {
    this.selected_gmosign_users = [];
  }

  @Action({ rawError: true })
  async getGMOSignMembers() {
    return await GMOSignRepository.getGMOSignMembers()
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_CURRENT_GMOSIGN_USERS(res.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async updateGMOSignMembers(params: GMOSignMember[]) {
    return await GMOSignRepository.updateGMOSignMembers(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_CURRENT_GMOSIGN_USERS(res.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async deleteGMOSignMembers(params: GMOSignMember[]) {
    return await GMOSignRepository.deleteGMOSignMembers(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_CURRENT_GMOSIGN_USERS(res.data);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Mutation
  SET_DOCUMENT_NAME(val: string) {
    this.signForm.document_name = val;
  }

  @Mutation
  SET_DOCUMENT_TYPE(val: string) {
    this.signForm.document_type = val;
  }

  @Mutation
  SET_DOCUMENT_TYPE_OBJ(val: DocumentTypeObject) {
    this.signForm.document_type_obj = val;
  }

  @Mutation
  SET_SEND_USER(val: GMOSignUserInfo) {
    this.signForm.send_user = val;
  }

  @Mutation
  SET_OWN_NAME_NM(val: string) {
    this.signForm.own_name_nm = val;
  }

  @Mutation
  SET_OWN_ORGANIZE_NM(val: string) {
    this.signForm.own_organize_nm = val;
  }

  @Mutation
  SET_OUR_SIGNING_FIELD(arr: GMOSignSigningField[]) {
    this.signForm.our_signing_field = arr;
  }

  @Mutation
  SET_PARTNER_SIGNING_FIELD(arr: GMOSignPartnerSigningField[]) {
    this.signForm.partner_signing_field = arr;
  }

  @Mutation
  SET_IS_INVISIBLED_SIGNATURE(val: boolean) {
    this.signForm.is_invisibled_signature = val;
  }

  @Mutation
  SET_CONTRACT_ID(val: string) {
    this.signForm.contract_id = val;
  }

  @Action({ rawError: true })
  async degitalSigning(params: GMOSignForm) {
    return await GMOSignRepository.degitalSigning(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Mutation
  SET_DOCUMENT_STATUS(val: string) {
    this.document_status = val;
  }

  @Action({ rawError: true })
  async getDocumentStatus(contractId) {
    return await GMOSignRepository.getDocumentStatus(contractId)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        this.SET_DOCUMENT_STATUS(res.data.document_status);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  @Action({ rawError: true })
  async deleteDocument(contractId) {
    return await GMOSignRepository.deleteDocument(contractId).catch(error => {
      return Promise.reject(error);
    });
  }

  // 以下仕様変更のためコメントアウト
  // @Action({ rawError: true })
  // async saveFormData(contractId: string) {
  //   return await GMOSignRepository.saveFormData(
  //     contractId,
  //     this.signForm
  //   ).catch(error => {
  //     return Promise.reject(error);
  //   });
  // }

  // @Mutation
  // SET_SIGNFORM_FROM_SAVE_DATA(data) {
  //   this.signForm = data;
  // }

  // @Action({ rawError: true })
  // async getFormData(contractId: string) {
  //   return await GMOSignRepository.getFormData(contractId)
  //     .then(res => {
  //       if (res.data) {
  //         this.SET_SIGNFORM_FROM_SAVE_DATA(res.data.tmp_data);
  //       }
  //     })
  //     .catch(error => {
  //       return Promise.reject(error);
  //     });
  // }

  // @Action({ rawError: true })
  // async deleteFormData(contractId: string) {
  //   return await GMOSignRepository.deleteFormData(contractId).catch(error => {
  //     return Promise.reject(error);
  //   });
  // }
}

const GMOSignInfoModule = getModule(GMOSignInfo);

export default GMOSignInfoModule;
