


















import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SearchBoxFormRadioGroup extends Vue {
  @Prop({
    type: Object,
    default: () => []
  })
  items;
  @Prop({
    type: String,
    default: ''
  })
  name;
  @Prop({
    type: [String, Number],
    default: null
  })
  value;
  @Prop({
    type: String,
    default: null
  })
  disableValue;
  @Prop({
    type: String,
    default: null
  })
  size;

  isDisabled(key) {
    if (key === this.disableValue) {
      return true;
    }
    return false;
  }

  handleInput(value) {
    this.$emit('change', this.name, value);
  }

  isChecked(key) {
    return this.value === key;
  }
}
