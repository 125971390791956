import Vue from 'vue';
import Vuex from 'vuex';
import {
  Module,
  VuexModule,
  getModule,
  Action,
  Mutation
} from 'vuex-module-decorators';
import store from '@/store';

export type Attachment = {
  attachment_id: string;
  file_name: string;
  download_link: string;
};

Vue.use(Vuex);

@Module({ dynamic: true, store: store, name: 'Attachments', namespaced: true })
class Attachments extends VuexModule {
  axiosSource: any = {};

  // TODO: キャンセル処理対応後に、以下のコードの必要・不要を判断する
  @Mutation
  ADD_CANCEL_TOKEN(source) {
    this.axiosSource = source;
  }

  @Mutation
  CLEAR_CANCEL_TOKENS() {
    this.axiosSource = {};
  }

  @Action({ rawError: true })
  cancelRequest() {
    this.axiosSource.cancel();
  }
}

const AttachmentsModule = getModule(Attachments);
export default AttachmentsModule;
