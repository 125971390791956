import { render, staticRenderFns } from "./AddNotificationWindow.vue?vue&type=template&id=5ea61254&scoped=true&lang=pug&"
import script from "./AddNotificationWindow.vue?vue&type=script&lang=ts&"
export * from "./AddNotificationWindow.vue?vue&type=script&lang=ts&"
import style0 from "./AddNotificationWindow.vue?vue&type=style&index=0&id=5ea61254&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ea61254",
  null
  
)

export default component.exports