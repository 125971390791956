

































import { Component, Vue, Prop } from 'vue-property-decorator';
import IconContractManagementGray from '@/components/atoms/icons/IconContractManagementGray.vue';
import dayjs from 'dayjs';
import Documents from '@/store/Documents';
import { change_folder } from '@/functions/DocumentRouter';

@Component({
  components: {
    IconContractManagementGray
  }
})
export default class SelectedFile extends Vue {
  @Prop({ default: null })
  contractDetail: any;

  @Prop()
  currentDirectory!: string;

  get isSigned(): boolean {
    return this.contractDetail.is_signed;
  }

  get contractInfo() {
    return this.contractDetail.contract_info;
  }

  get taskExpiration() {
    if (this.contractDetail.kanban_card_info) {
      const date = this.contractDetail.kanban_card_info.task_expiration;
      return dayjs(date).format('YYYY/MM/DD');
    }
    return null;
  }

  get directories() {
    if (!this.currentDirectory) return '';

    const directories = this.contractDetail.directory_path.split('/');

    let name = '';
    let path = '/';
    const results = directories.map((x, i) => {
      if (i === 0) {
        name = '締結済ドキュメント';
      } else {
        name = x;

        if (i === 1) {
          path = `${path}${x}`;
        } else {
          path = `${path}/${x}`;
        }
      }

      return {
        name,
        path
      };
    });

    return results.filter(x => x.name);
  }

  onTopMenu(path) {
    Documents.SET_IS_BACK_PAGE(true);
    change_folder(this.$router, path);
  }
}
