

























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import Label from '@/components/atoms/labels/Label.vue';
import { ContractLabel } from '@/store/SettingsInfo';

import LABELS from '@/consts/Labels';

@Component({
  components: {
    BaseWindow,
    AppButton,
    TextForm,
    Label
  }
})
export default class AddLabelWindow extends Vue {
  @Prop()
  windowSize!: string;

  @Prop({ default: false })
  isWhiteSpace?: boolean;

  @Prop()
  updateLabel?: ContractLabel;

  @Prop(Boolean)
  value: boolean;

  formData: ContractLabel = {
    label_id: null,
    label_name: '',
    background_color: '',
    font_color: ''
  };

  formDirty = false;
  errorMessages = {
    label_name: ''
  };

  get labelColors(): ContractLabel[] {
    return LABELS.COLORS;
  }

  get validation(): boolean {
    return (
      this.formDirty &&
      this.formData.label_name &&
      !this.errorMessages.label_name
    );
  }

  get placeholderText() {
    return 'サンプルラベル';
  }

  @Watch('value')
  windowStatus(b) {
    this.clearError();

    if (!b) {
      return;
    }
    if (this.updateLabel) {
      this.formData.label_id = this.updateLabel.label_id;
      this.formData.label_name = this.updateLabel.label_name;
      this.formData.background_color = this.updateLabel.background_color;
      this.formData.font_color = this.updateLabel.font_color;
    } else {
      this.formData.label_id = null;
      this.formData.label_name = '';
      this.formData.background_color = this.labelColors[0].background_color;
      this.formData.font_color = this.labelColors[0].font_color;
    }
  }

  clearError() {
    this.formDirty = false;
    this.errorMessages.label_name = '';
  }

  validate() {
    this.errorMessages.label_name = '';

    if (this.formData.label_name === '') {
      this.errorMessages.label_name = 'ラベル名を入力してください';
    } else if (this.formData.label_name.length > 30) {
      this.errorMessages.label_name = 'ラベル名は30文字以内で入力してください';
    }
  }

  onChange() {
    this.formDirty = true;
    this.validate();
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }

  onClickLabelColor(label) {
    this.formDirty = true;
    this.formData.background_color = label.backgroundColor;
    this.formData.font_color = label.textColor;
  }
}
