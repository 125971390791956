























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import VueSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';

@Component({
  components: {
    VueSelect
  }
})
export default class SelectBox extends Vue {
  @Prop(Array)
  options: any[];

  @Prop()
  value: any;

  @Prop({ default: 'label' })
  label?: string;

  @Prop({ default: false })
  multiple?: boolean;

  @Prop({ default: false })
  clearable?: boolean;

  @Prop({ default: false })
  searchable?: boolean;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: true })
  closeOnSelect?: boolean;

  @Prop({ default: true })
  filterable?: boolean;

  @Prop()
  filterBy?: (option: any, label: string, search: string) => boolean;

  @Prop({ default: true })
  showActions?: boolean;

  @Prop({ default: false })
  error?: boolean;

  @Prop({ default: '' })
  placeholder?: string;

  editValue: any = null;

  get classObject(): object {
    return {
      'selectbox--actions-hide': !this.showActions,
      'selectbox--error': !!this.error
    };
  }

  mounted() {
    this.editValue = this.value;
  }

  @Watch('value')
  updateValue(new_value) {
    this.editValue = new_value;
  }

  input(val) {
    this.editValue = val;
    this.$emit('input', this.editValue);
  }

  clearSearchOnBlur({ clearSearchOnSelect }) {
    return clearSearchOnSelect;
  }
}
