









import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({
  components: {}
})
export default class SelectedItemsView extends Vue {
  @Prop({ default: 0 })
  num_folders!: number;
  @Prop({ default: 0 })
  num_files!: number;
}
