// 必要なiconが増えたら以下のURLから探してimportする
// see: https://github.com/FortAwesome/Font-Awesome/tree/master/js-packages/@fortawesome

import Vue from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowDown } from '@fortawesome/free-solid-svg-icons/faArrowDown';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons/faArrowUp';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import { faSync } from '@fortawesome/free-solid-svg-icons/faSync';

Vue.component('font-awesome-icon', FontAwesomeIcon);
library.add(
  faArrowDown,
  faArrowUp,
  faCheckCircle,
  faExclamationTriangle,
  faTimesCircle,
  faSync
);
