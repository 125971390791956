const SERVICE_PLANS = {
  FREE_TRIAL: {
    service_plan_id: 'c8841b4a9f3f4ef383905d4b7e2f6df3',
    sort_order: 1,
    service_plan: '無料トライアル'
  },
  SMALL: {
    service_plan_id: '602bee85c7844731967ab1dc35d78cb2',
    sort_order: 2,
    service_plan: 'スモール'
  },
  STANDARD: {
    service_plan_id: '974cbb2d9954491996c267e118b734f2',
    sort_order: 3,
    service_plan: 'スタンダード'
  },
  ENTERPRISE: {
    service_plan_id: '9b29ea5db18847ba93ccfac2dc85a1d5',
    sort_order: 4,
    service_plan: 'エンタープライズ'
  }
};

export default SERVICE_PLANS;
