


























import { Component, Vue } from 'vue-property-decorator';
import UserInfoModule from '@/store/UserInfo';
import notify from '@/functions/notify';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextFormWidth480WithTitle from '@/components/molecules/text_form_with_title/TextFormWidth480WithTitle.vue';
import KEY_CODE from '@/consts/KeyCode';
const axios = require('axios').default;
import { move_home } from '@/functions/DocumentRouter';

@Component({
  components: {
    FormTitle,
    TextFormWidth480WithTitle,
    AppButton
  }
})
export default class UserAccountInformationForm extends Vue {
  user_name: string = '';
  department_name: string = '';
  // フォームに入力する前から保持している部署情報
  already_department_name: string = '';

  async created() {
    await UserInfoModule.get_user_info();
    if (UserInfoModule.user_name) {
      notify.error({
        text: 'ユーザー情報はすでに登録されています。ログイン画面に移動します。'
      });
      this.$router.push({ name: 'login' });
    }
    this.department_name = UserInfoModule.department_name;
    this.already_department_name = this.department_name;
  }

  get button_disabled(): boolean {
    return !(
      this.user_name &&
      this.department_name &&
      this.user_name.length > 0 &&
      this.department_name.length > 0 &&
      this.user_name.match(/^\S+( |　)+\S+/) &&
      this.department_name.match(/\S+/)
    );
  }

  async register_user_account_info() {
    const url = `user-management/users/info/me`;
    const idToken = await UserInfoModule.getIdToken();
    const data = {
      user_name: this.user_name,
      department_name: this.department_name
    };

    await axios
      .post(url, data, {
        headers: { 'id-token': idToken }
      })
      .then(({ data }) => {
        UserInfoModule.SET_USER_ID(data.user_id);
        UserInfoModule.SET_ID_TOKEN(data.id_token);
        UserInfoModule.SET_DEPARTMENT(data.department);
        UserInfoModule.SET_DEPARTMENT_NAME(data.department.department_name);
        UserInfoModule.SET_USER_NAME(data.user_name);
        UserInfoModule.SET_EMAIL(data.email);
        UserInfoModule.SET_AUTHORITY(data);
        UserInfoModule.SET_REQUIRED_CHANGE_PASSWORD(
          data.is_required_changing_password
        );
        UserInfoModule.SET_AVATAR(data.avatar);
        move_home(this.$router);
      })
      .catch(err => {
        notify.error({
          text: 'アカウント情報の登録に失敗しました。再度お試しください。'
        });
      });
  }

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER && !this.button_disabled) {
      this.register_user_account_info();
    }
  }
}
