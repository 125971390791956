





























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';

import AUTHORITIES from '@/consts/Authorities';
import SettingsInfoModule from '@/store/SettingsInfo';
import UserInfoModule from '@/store/UserInfo';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import { sortBase } from '@/functions/Sort';

type Menmber = {
  department_id: string;
  department_name: string;
  email: string;
  logined_at: string;
  permission_default_id: string;
  permission_default_name: string;
  user_id: string;
  user_name: string;
};

@Component({
  components: {
    SelectBox,
    BaseTable
  }
})
export default class MembersTable extends Vue {
  @Prop(Array) readonly authorities: any[];
  selected: string[] = [];

  @Prop()
  value: Menmber[];

  beforeCreate() {
    if (!SettingsInfoModule.sort[this.$route.name]) {
      SettingsInfoModule.SET_SORT({
        pageName: this.$route.name,
        columnName: 'user_name_furigana'
      });
    }
  }

  mounted() {
    this.sortTableBodyData();
  }

  setSort(columnName: string) {
    SettingsInfoModule.SET_SORT({ pageName: this.$route.name, columnName });
    this.sortTableBodyData();
  }

  sortTableBodyData(): void {
    sortBase(this.value, this.sort.columnName, {
      isAsc: this.sort.asc
    });
  }

  get sort(): {
    columnName: string;
    asc: boolean;
  } {
    return SettingsInfoModule.sort[this.$route.name];
  }

  get selected_users() {
    return SettingsInfoModule.selected_users;
  }

  set selected_users(value) {
    SettingsInfoModule.SET_SELECTED_USERS(value);
  }

  /**
   * 権限説明モーダルを開く
   */
  openExplanationWindow() {
    SettingsInfoModule.SET_ADD_EXPLANATION_AUTHORITY_WINDOW(true);
  }

  private get user_authority() {
    return UserInfoModule.authority;
  }

  get can_user_edit() {
    return SettingsInfoModule.can_user_authority_edit;
  }

  /**
   * 日付フォーマットを変換して返す
   */
  formatDate(date): string {
    if (date) {
      return dayjs(date).format('YYYY/MM/DD HH:mm');
    } else {
      return '未登録';
    }
  }

  /**
   * Null値の場合は未登録という文字列を返す
   */
  showDataProcess(data: string) {
    if (!data) {
      return '未登録';
    } else {
      return data;
    }
  }

  /**
   * null判定
   */
  isNull(data) {
    if (data === null) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * チェックボックスがチェック可能かどうかを返す
   */
  isCheckable(member) {
    // オーナー権限で自分自身の時または管理者権限でオーナーは削除することができない
    return (
      !(
        this.user_authority === AUTHORITIES.OWNER &&
        UserInfoModule.user_id === member.user_id
      ) &&
      !(
        this.user_authority === AUTHORITIES.ADMINISTRATOR &&
        member.permission_default_name === AUTHORITIES.OWNER
      )
    );
  }

  @Watch('selected_users')
  set_store(new_value): void {
    SettingsInfoModule.SET_SELECTED_USERS(new_value);
  }

  @Watch('value', { deep: true })
  update_member(new_value): void {
    if (this.selected_users.length === 0) {
      return;
    }
    this.$emit('input', new_value);
  }
}
