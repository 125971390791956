










import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class TextAvatar extends Vue {
  @Prop()
  text!: string;

  @Prop({ default: '32px' })
  size?: string;

  @Prop({ default: '#002bff' })
  color?: string;

  @Prop({ default: 2 })
  displayTextCount?: number;

  /**
   * アバターの背景色を取得
   */
  get avatarColor() {
    if (this.color) {
      return this.color;
    }
    return '#002bff';
  }

  /**
   * 表示用テキストを取得
   * 親Componentから表示する文字数を指定する
   */
  public get displayText(): string {
    if (!this.text) {
      return '';
    }
    const spaceIndex = this.text.search(/( |　)/);
    const targetText =
      spaceIndex === 2
        ? this.text.substring(0, spaceIndex)
        : this.text.substring(0, 1);
    // const targetText = this.text.substring(0, this.displayTextCount)

    if (targetText.match(/^[a-z]*$/)) {
      return targetText.toUpperCase();
    }
    return targetText;
  }

  /**
   * 文字数に応じて文字サイズを調整する
   */
  public get font_size(): string {
    if (this.displayText.length == 1) {
      return '1.125';
    }
    if (this.displayText.length == 2) {
      return '0.687';
    }
    return '0.45';
  }
}
