
















































import { Component, Vue, Model, PropSync } from 'vue-property-decorator';

import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import SettingsInfoModule from '@/store/SettingsInfo';
import { sortBase } from '@/functions/Sort';

type Department = {
  department_id: string;
  department_name: string;
  department_name_furigana?: string;
  num_user: number;
};

@Component({
  components: {
    BaseTable
  }
})
export default class DepartmentTable extends Vue {
  @Model('change', { type: Array }) readonly selected: [];
  @PropSync('departments', { type: Array, default: [] })
  departmentsData: Department[];

  get sort(): {
    columnName: string;
    asc: boolean;
  } {
    return SettingsInfoModule.sort[this.$route.name];
  }

  beforeCreate() {
    if (!SettingsInfoModule.sort[this.$route.name]) {
      SettingsInfoModule.SET_SORT({
        pageName: this.$route.name,
        columnName: 'department_name_furigana'
      });
    }
  }

  mounted() {
    this.sortTableBodyData();
  }

  setSort(columnName: string) {
    SettingsInfoModule.SET_SORT({ pageName: this.$route.name, columnName });
    this.sortTableBodyData();
  }

  sortTableBodyData(): void {
    sortBase(this.departmentsData, this.sort.columnName, {
      isAsc: this.sort.asc
    });
  }

  /**
   * すべての項目のチェックボックスの checked を付けたり外したりする
   */
  changeCheckedAll(): void {
    if (this.areAllChecked) {
      this.internalSelected = [];
      return;
    }
    this.internalSelected = this.departmentsData;
  }

  /**
   * すべてのチェックボックスが checked かどうかを返す
   */
  get areAllChecked(): boolean {
    if (!this.internalSelected.length) return false;
    return this.internalSelected.length === this.departmentsData.length;
  }

  to_department_members(department) {
    const params = { id: department.department_id };
    const query = { name: encodeURI(department.department_name) };
    this.$router.push({
      name: 'department_members',
      params: params,
      query: query
    });
  }

  get internalSelected(): any[] {
    return this.selected;
  }
  set internalSelected(new_value) {
    this.$emit('change', new_value);
  }
}
