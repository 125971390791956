









































import { Component, Vue, Emit, Watch } from 'vue-property-decorator';
import IconZoomOut from '@/components/atoms/icons/IconZoomOut.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';

import ContractSearchDetail from '@/components/organisms/Home/ContractSearchDetail.vue';
import ClickOutside from 'vue-click-outside';

import SearchInfo from '@/store/SearchInfo';

@Component({
  components: {
    IconZoomOut,
    ImageContent,
    ContractSearchDetail
  },
  directives: {
    ClickOutside
  }
})
export default class HeaderSearchBox extends Vue {
  keyword: string = SearchInfo.free_keyword || '';
  emptyKeyword: string = '';

  isAndSearch: boolean = false;
  isAllFolderSearch: boolean = false;

  isClickClearBtn: boolean = false;

  get isOpenContractSearchDetail() {
    return SearchInfo.is_open_contract_search_detail;
  }

  @Watch('$route')
  onSearch() {
    this.keyword = SearchInfo.free_keyword;
    this.isClickClearBtn = false;
  }

  @Watch('keyword')
  changeKeyword() {
    if (!this.keyword) {
      if (this.isClickClearBtn) {
        this.isClickClearBtn = false;
        return;
      }
    }
    this.$emit('changeKeyword', this.keyword);
  }

  onKeypress(event) {
    if (event.which !== 13) return;
    this.trigger();
  }

  @Emit()
  async trigger() {
    if (!this.keyword) return;
    SearchInfo.SET_IS_SEARCHING_NOW(true);

    const query: any = await SearchInfo.get_query({
      all_docs: this.isAllFolderSearch,
      free_keyword: this.keyword,
      and_search: this.isAndSearch
    });
    this.$router
      .push({
        path: '/home/searching',
        query
      })
      .catch(err => {});
  }

  /**
   * 詳細検索を開く
   * 詳細検索が開かれた際は検索窓のテキストをクリアする（詳細検索のドロップダウン側に表示される）
   */
  openSearchMenu() {
    SearchInfo.SET_OPEN_CONTRACT_SEARCH_DETAIL(
      !this.isOpenContractSearchDetail
    );
    if (!this.isOpenContractSearchDetail) {
      this.keyword = SearchInfo.free_keyword;
    }
  }

  onClearKeyword() {
    SearchInfo.SET_SEARCHING(false);
    this.isClickClearBtn = true;
    this.keyword = '';
    SearchInfo.SET_OPEN_CONTRACT_SEARCH_DETAIL(false);
    this.$emit('clearKeyword');
  }

  detailSearch() {
    SearchInfo.SET_IS_SEARCHING_NOW(true);
    SearchInfo.SET_OPEN_CONTRACT_SEARCH_DETAIL(false);
    this.$emit('detailSearch');
  }

  /**
   * 検索ボックスの外を押下した際に検索ボックスを閉じる
   * ※ 「検索対象を詳細情報に絞る」のポップオーバー上のクリックは何もしない
   * ※ カレンダー押下時には非表示にしないようにClassNameによる判定を行う
   *  押下場所によってはevent.target.classNameがstringではなくArrayで取得される場合があるためその場合は非表示にする
   * ※ 閉じられた際にはもとの検索ワードにもどす
   */
  onClickOutside(event: any) {
    if (!this.isOpenContractSearchDetail) {
      return;
    }
    if (event.target.closest('.popover-base-exclude-file-text') !== null) {
      return;
    }
    const targetClassName = event.target.className;
    if (typeof targetClassName !== 'string') {
      SearchInfo.SET_OPEN_CONTRACT_SEARCH_DETAIL(false);
      return;
    }

    const class_name_list = targetClassName.split(' ');
    if (!class_name_list.includes('day') && !class_name_list.includes('cell')) {
      SearchInfo.SET_OPEN_CONTRACT_SEARCH_DETAIL(false);
    }
    this.keyword = SearchInfo.free_keyword;
  }
}
