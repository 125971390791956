






































































































import { Route } from 'vue-router';
import { Component, Vue, Watch } from 'vue-property-decorator';
import HomeBodyHeader from '@/components/organisms/Home/HomeBodyHeader.vue';
import UploadMenu from '@/components/molecules/UploadMenu.vue';
import UploadDialog from '@/components/molecules/UploadDialog.vue';
import ExtractDialog from '@/components/molecules/ExtractDialog.vue';
import SameNameConfirmDialog from '@/components/molecules/SameNameConfirmDialog.vue';
import DragAndDropImage from '@/components/molecules/DragAndDropImage.vue';
import FileUploadingPopupMessage from '@/components/molecules/popups/FileUploadingPopupMessage.vue';
import ObjectTable from '@/components/organisms/Home/tables/ObjectTable.vue';
import NoFilesImage from '@/components/molecules/NoFilesImage.vue';
import SignedFileUploader from '@/components/organisms/Home/SignedFileUploader.vue';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';
import Documents from '@/store/Documents';
import SearchInfo from '@/store/SearchInfo';
import Associations from '@/store/Associations';
import CorporateInfoModule from '@/store/CorporateInfo';
import notify from '@/functions/notify';
import FileUpload from '@/functions/FileUpload';
import { spaceReplaceUnderScore } from '@/functions/replaceString';
import UserInfoModule from '@/store/UserInfo';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import LAYOUT_UTIL from '@/consts/LayoutUtil';
const axios = require('axios').default;
import vLoading from 'vue-loading-overlay';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');

interface DropFile {
  size: number;
  name: string;
  type: string;
  file: File;
}

interface UploadArgs {
  fileList: DropFile[];
  linkAsRelatedContract: boolean;
  attachFileList: File[];
  selectedViewPermissionData: any;
}

interface Folder {
  directory_name: string;
  directory_name_furigana: string;
  directory_path: string;
}

@Component({
  components: {
    HomeBodyHeader,
    UploadMenu,
    UploadDialog,
    ExtractDialog,
    SameNameConfirmDialog,
    DragAndDropImage,
    NoFilesImage,
    SignedFileUploader,
    ObjectTable,
    FileUploadingPopupMessage,
    vLoading
  }
})
export default class HomeBody extends Vue {
  axiosSource: any = axios.CancelToken.source();
  dragstart: boolean = false;
  dragover: boolean = false;
  dragoverFolder: boolean = false;
  uploadTargetFolder: Folder = {
    directory_name: null,
    directory_name_furigana: null,
    directory_path: null
  };
  objects: any = [];
  // ファイルアップロード中の場合のみtrue。あとでアップロード機能つけるときに書き換える。
  file_upload: boolean = false;
  uploading_files_num: number = 0;
  existSameNameFileOrFolder: boolean = false;
  linkAsRelatedContract: boolean = false;
  makeVersionFinal: boolean = false;
  showUploadDialog: boolean = false;
  autoExtracted: boolean = false;
  body_height: number = 1280;
  body_width: number = 1024;

  fileList: DropFile[] = [];

  timeout_id: number = 0;

  isLoading: boolean = false;

  uploadingFilesText: string = NOTIFY_TEXT.SUCCESS.UPLOADING_FILES;

  LIMIT_DIRECTORY_COUNT_SMALL_PLAN: number = 4;

  arraySetIntervalId = [];

  async mounted() {
    window.addEventListener('resize', this.set_body_shape);
    await this.set_body_shape();
    Documents.SET_FOLDERS([]);
    Documents.SET_FILES([]);
  }

  beforeDestroy() {
    clearInterval(this.arraySetIntervalId.shift());
  }

  dragleaveHomeBody(event: DragEvent) {
    // sidebar, headerに行ったらleaveとする
    const sidebarWidth: number = this.sideBarStatus
      ? LAYOUT_UTIL.SIDE_MENU.WIDTH.OPEN
      : LAYOUT_UTIL.SIDE_MENU.WIDTH.CLOSE;
    const headerHeight: number = LAYOUT_UTIL.HEADER.HEIGHT;
    if (event.pageX < sidebarWidth || event.pageY < headerHeight)
      this.dragover = false;
    return;
  }

  get permission() {
    return UserInfoModule.permission;
  }

  get numFiles(): number {
    return this.files.length;
  }
  private get sideBarStatus(): boolean {
    return this.$store.state.home_component_info.side_bar_open;
  }
  private get uploadPopup() {
    return this.$store.state.home_component_info.upload_menu_switch;
  }
  private get folders() {
    return Documents.folders;
  }
  private get files() {
    return Documents.files;
  }
  private get numFolders(): number {
    return this.folders.length;
  }
  private get searching(): boolean {
    return this.$store.state.search_info.searching;
  }
  private get foldersAndFiles() {
    if (this.files.length) {
      const objects = [];
      this.folders
        .filter(folder => folder)
        .map(folder => {
          objects.push(folder);
        });
      return objects.concat(this.files);
    } else {
      return this.folders;
    }
  }

  get existProcessingFile() {
    if (!this.files) {
      return false;
    }
    for (const i in this.files) {
      if (this.files[i]) {
        const is_already_autoextraction = this.files[i]
          .is_already_autoextraction;
        if (!is_already_autoextraction) {
          return true;
        }
      }
    }
    return false;
  }

  /** アップロード中かどうかを返す */
  get isFileLoading(): boolean {
    return !HomeComponentInfoModule.uploaded || this.file_upload;
  }

  private get showDropzone(): boolean {
    if (this.searching || this.numFiles || this.numFolders) {
      return false;
    }
    return true;
  }

  private get isDelete(): boolean {
    return this.$store.state.popups.delete;
  }

  private get currentFolderNames(): string[] {
    const folderNames: string[] = [];
    this.foldersAndFiles.forEach(el => {
      if (el.directory_name) {
        folderNames.push(el.directory_name);
      }
    });
    return folderNames;
  }
  private get currentFileNames(): string[] {
    const fileNames: string[] = [];
    this.foldersAndFiles.forEach(el => {
      if (el.file_name && el.file_extension) {
        fileNames.push(el.file_name + '.' + el.file_extension);
      }
    });
    return fileNames;
  }

  get isSmallPlan(): boolean {
    return CorporateInfoModule.isSmallPlan;
  }

  get currentDirectoryCount(): number {
    if (Documents.current_directory === '/') return 1;

    const directory = decodeURI(Documents.current_directory).split('/');
    return directory.length;
  }

  get canCreateFolder(): boolean {
    // スモールプランの場合は4階層まで許容
    if (
      this.isSmallPlan &&
      this.currentDirectoryCount >= this.LIMIT_DIRECTORY_COUNT_SMALL_PLAN
    )
      return false;

    return true;
  }

  get uploadJobs() {
    return Documents.uploadJobs;
  }

  get uploadJobGroupIds() {
    return Documents.uploadJobGroupIds;
  }

  @Watch('$route')
  async change_directory(to: Route, from: Route) {
    Documents.RESET_SELECTED_TABLE();
    const path = to.path;
    if (!path.includes('/home/') || path.includes('searching')) {
      return;
    }

    if (path.includes('/home/')) {
      // 検索後ブラウザバックしてhomeに戻ったらsearching=Falseにする
      SearchInfo.SET_SEARCHING(false);
      const current = to.params.id.split('/');
      current.shift();
      let current_directory_path = '/';
      if (current.length) {
        current_directory_path = '/' + current.join('/');
      }
      this.isLoading = true;
      if (!SearchInfo.searching) {
        // TODO: WatchでAPIコールしない
        // @See: https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/2844
        // NOTE: ディレクトリが変わったので契約書の再取得をしている
        await Documents.change_current_directory(
          decodeURI(current_directory_path)
        );
      }
      this.isLoading = false;
    }
    this.fileList = [];
  }

  getFolderOrFileName(name: string): string {
    const index = name.indexOf('/');
    const spaceReplacedName = spaceReplaceUnderScore(name);
    if (index < 0) {
      // フォルダーではないのでファイル名を返す
      return spaceReplacedName;
    } else {
      // フォルダー名を返す
      return spaceReplacedName.substring(0, index);
    }
  }

  getSameNameFileOrFolder(fileList: DropFile[]): string[] {
    // fileListのなかに、何個同名ファイルと同名フォルダーがあるか
    const currentFolderFileNames: string[] = this.currentFolderNames.concat(
      this.currentFileNames
    );
    const sameNameList: string[] = [];
    let nameList: string[] = [];
    // ファイル名、フォルダ名の配列を作る
    fileList.forEach(file => {
      nameList.push(this.getFolderOrFileName(file.name));
    });
    // uniqな配列にする
    nameList = [...new Set(nameList)];
    // 同名をカウントする
    nameList.forEach((name: string) => {
      if (currentFolderFileNames.includes(name)) {
        sameNameList.push(name);
      }
    });
    return sameNameList;
  }

  // vue-upload-componentを用いたボタンでのアップロードをした際の処理
  async buttonUpload(fileList) {
    this.initializeUploadTargetFolder();
    const new_fileList = [];
    const file_names = [];
    for (let i = 0; i < fileList.length; i++) {
      if (file_names.indexOf(fileList[i].name) == -1) {
        file_names.push(fileList[i].name);
        // ファイル、フォルダ名に空白文字が入らないようにする
        fileList[i].name = spaceReplaceUnderScore(fileList[i].name);
        const isCheckFileSize = FileUpload.checkAllowedFileSize(
          fileList[i].size
        );
        if (isCheckFileSize) {
          new_fileList.push(fileList[i]);
        }
      }
    }

    // 拡張子偽装確認、あれば処理中止
    const is_fake_extension = await FileUpload.isFakeExtension(new_fileList);
    if (is_fake_extension) {
      return;
    }

    this.fileList = new_fileList;
    if (this.fileList.length) {
      this.addFilesNum(this.fileList.length);
      this.showUploadDialog = true;
    } else {
      this.showUploadDialog = false;
    }
  }

  initializeUploadTargetFolder(): void {
    this.uploadTargetFolder = {
      directory_name: null,
      directory_name_furigana: null,
      directory_path: null
    };
  }

  folderDragover({ folder }) {
    this.uploadTargetFolder = folder;
    this.dragoverFolder = true;
  }

  async dropOnFolder({ event }) {
    this.dragoverFolder = false;
  }

  async upload(uploadArgs: UploadArgs) {
    if (uploadArgs.linkAsRelatedContract) {
      this.linkAsRelatedContract = uploadArgs.linkAsRelatedContract;
    }
    this.fileList = uploadArgs.fileList.map((file: DropFile) => {
      // ファイル、フォルダ名に空白文字が入らないようにする
      file.name = spaceReplaceUnderScore(file.name);
      return file;
    });
    // 同名ファイルまたは同名フォルダが存在している
    if (this.getSameNameFileOrFolder(uploadArgs.fileList).length) {
      this.existSameNameFileOrFolder = true;
      return;
    }

    // アップロードするファイル数が制限数を超えている場合
    if (this.fileList.length > CorporateInfoModule.uploadFileLimit) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.OVER_UPLOAD_FILE_NUM_LIMIT.replace(
          /%n/g,
          String(CorporateInfoModule.uploadFileLimit)
        )
      });
      return;
    }

    this.file_upload = true;
    const contactIdList: string[] = [];

    //  ファイルアップロード処理
    const formData = new FormData();
    for (const i in this.fileList) {
      // 10MB以上のファイルは省く
      if (this.fileList[i].size >= 10 * 1024 * 1024) {
        continue;
      }
      const uploadFile = new File(
        [this.fileList[i].file],
        spaceReplaceUnderScore(this.fileList[i].name)
      );
      formData.append('contract_list', uploadFile);
    }
    if (formData.getAll('contract_list').length < 1) {
      this.fileList = [];
      this.showUploadDialog = false;
      this.file_upload = false;
      notify.error({
        text: NOTIFY_TEXT.ERROR.OVERSIZE_UPLOAD_SUFFIX
      });
      return;
    }
    formData.append(
      'current_directory',
      this.uploadTargetFolder.directory_path
        ? this.uploadTargetFolder.directory_path
        : Documents.current_directory
    );
    // 関連契約紐付け
    if (uploadArgs.linkAsRelatedContract) {
      formData.append('do_associate', String(true));
    }
    // 添付資料の添付
    if (uploadArgs.attachFileList && uploadArgs.attachFileList.length) {
      const attachFileList = uploadArgs.attachFileList;
      for (const i in attachFileList) {
        const attachFile = new File(
          [attachFileList[i]],
          spaceReplaceUnderScore(attachFileList[i].name)
        );
        formData.append('attachment_list', attachFile);
      }
    }
    // 閲覧権限設定
    if (uploadArgs.selectedViewPermissionData) {
      let viewable_user_id = '';
      let viewable_department_id = '';
      const data = uploadArgs.selectedViewPermissionData;
      if (data.selected_users) {
        const user_id_list = [];
        for (const i in data.selected_users) {
          user_id_list.push(data.selected_users[i].user_id);
        }
        viewable_user_id = user_id_list.join(',');
        formData.append('viewable_user_id', viewable_user_id);
      }
      if (data.selected_departments) {
        const department_id_list = [];
        for (const i in data.selected_departments) {
          department_id_list.push(data.selected_departments[i].department_id);
        }
        viewable_department_id = department_id_list.join(',');
        formData.append('viewable_department_id', viewable_department_id);
      }
    }

    // APIリクエスト開始
    await DocumentRepository.uploadSignedContract(formData)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
          return;
        }

        this.fileList = [];

        Documents.SET_UPLOAD_JOBS([...this.uploadJobs, ...res.data]);
        Documents.SET_UPLOAD_JOB_GROUP_IDS([
          ...this.uploadJobGroupIds,
          res.data[0].group_id
        ]);

        this.fileList = [];
      })
      .catch(err => {
        if (err.response && err.response.status === HTTP_STATUS.UNAUTHORIZED) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CAN_NOT_CREATE_FOLDER_FOR_SMALL_PLAN
          });
          return;
        }
        // 通信のキャンセルをした場合はエラー表示しない
        if (this.file_upload) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
        }
        return;
      })
      .finally(() => {
        this.showUploadDialog = false;
        this.file_upload = false;
      });
  }

  get showAutoExtractDialog(): boolean {
    const showAutoExtractDialog: boolean =
      this.autoExtracted && UserInfoModule.is_showing_extraction_dialog;
    return showAutoExtractDialog;
  }

  set showAutoExtractDialog(newVal: boolean) {
    this.autoExtracted = newVal;
  }

  get isRunningUploadJob() {
    return Documents.isRunningUploadJob;
  }

  closeExtractDialog({ hideExtractionDialog }): void {
    if (hideExtractionDialog)
      UserInfoModule.update_user_account_info({
        is_showing_extraction_dialog: false
      });
  }

  async dropProcess(event): Promise<void> {
    this.dragover = false;
    if (!this.permission.can_upload_file) return;

    // FIXME: バックグラウンドでのファイルアップロード処理の問題が解決する間の一時的対応
    // @see https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/3507
    if (this.isRunningUploadJob) {
      notify.error({
        text:
          '他のファイルをアップロード中です。アップロード完了後に再度お試しください'
      });
      return;
    }

    this.fileList = await FileUpload.addDataTransfer(event.dataTransfer);
    // まずは一般的な隠しファイル .DS_Store を除く(mac用の処理
    this.fileList = this.fileList.filter(x => !/\.(DS_Store)$/i.test(x.name));
    // docx, pdf以外を弾く前にそもそもファイルが存在しないフォルダーをアップロードしている場合は専用のエラーメッセージを表示
    if (!this.is_existed_upload_file(this.fileList)) return;

    // スモールプランの場合、4階層以上のフォルダ作成不可
    if (this.isSmallPlan) {
      // フォルダが含まれているか
      const isIncludeDirectory = FileUpload.checkIncludeDirectory(
        event.dataTransfer
      );

      // 3階層目にフォルダをDnDされたら処理終了
      if (
        isIncludeDirectory &&
        this.currentDirectoryCount >= this.LIMIT_DIRECTORY_COUNT_SMALL_PLAN
      ) {
        notify.error({
          text: NOTIFY_TEXT.ERROR.CAN_NOT_CREATE_FOLDER_FOR_SMALL_PLAN
        });
        return;
      }

      // DnDされたフォルダが現在の階層と合計して3階層以上になれば処理終了
      let fileNameLiist = this.fileList.map((file: DropFile) => {
        file.name = spaceReplaceUnderScore(file.name);
        return file.name;
      });
      fileNameLiist = fileNameLiist.filter(x => !/\.(DS_Store)$/i.test(x));
      const isOver = fileNameLiist.some(x => {
        // ディレクトリ階層を減算する数
        // アップロード対象の階層とアップロードするディレクトリの1階層目は同階層になるため -1
        // ファイル名を取り除くため -1
        const SUB_UPLOAD_DIRECTORY_COUNT = 2;

        const uploadFileDirectory = x.split('/');
        return (
          this.currentDirectoryCount +
            uploadFileDirectory.length -
            SUB_UPLOAD_DIRECTORY_COUNT >=
          this.LIMIT_DIRECTORY_COUNT_SMALL_PLAN
        );
      });

      if (isOver) {
        notify.error({
          text: NOTIFY_TEXT.ERROR.CAN_NOT_CREATE_FOLDER_FOR_SMALL_PLAN
        });
        return;
      }
    }

    // アップロード可能な拡張子のファイルを取得
    const collectFiles = this.fileList.filter(x =>
      /\.(pdf|docx|doc|xlsx|xls)$/i.test(x.name)
    );

    // アップロード可能なファイルがない場合は処理を中止
    if (!this.is_allowed_upload_file(collectFiles)) return;

    // 拡張子偽装確認、あれば処理中止
    const is_fake_extension = await FileUpload.isFakeExtension(collectFiles);
    if (is_fake_extension) {
      return;
    }

    this.fileList = collectFiles.filter(x => x.size <= 10 * 1024 * 1024);
    this.uploading_files_num = this.fileList.length;
    // ファイル、フォルダ名に空白文字が入らないようにする
    this.fileList = this.fileList.map((file: DropFile) => {
      file.name = spaceReplaceUnderScore(file.name);
      return file;
    });
    if (this.uploading_files_num) this.showUploadDialog = true;
    else this.showUploadDialog = false;
  }

  is_existed_upload_file(files: any[]): boolean {
    return FileUpload.checkAllowedUploadFile(
      files,
      NOTIFY_TEXT.ERROR.NOT_EXIST_UPLOAD_FILE
    );
  }

  is_allowed_upload_file(files: any): boolean {
    return FileUpload.checkAllowedUploadFile(
      files,
      NOTIFY_TEXT.ERROR.CANNOT_UPLOAD
    );
  }

  /** アップロード中のファイル数を返す */
  addFilesNum(num: number): void {
    this.uploading_files_num = num;
  }

  set_body_shape() {
    this.body_height = window.innerHeight - LAYOUT_UTIL.HEADER.HEIGHT;
    if (this.sideBarStatus) {
      this.body_width = window.innerWidth - LAYOUT_UTIL.SIDE_MENU.WIDTH.OPEN;
    } else {
      this.body_width = window.innerWidth - LAYOUT_UTIL.SIDE_MENU.WIDTH.CLOSE;
    }
  }

  cancelUploadingFiles() {
    this.file_upload = false;
    this.axiosSource.cancel();
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CANCEL_UPLOADING
    });
    Documents.update_document_info();
    Associations.getCandidates();
    // axiosオブジェクトを再生成
    const axios = require('axios').default;
    this.axiosSource = axios.CancelToken.source();
  }

  get dropzone_area() {
    this.body_height = window.innerHeight - LAYOUT_UTIL.HEADER.HEIGHT;
    if (this.sideBarStatus) {
      this.body_width = window.innerWidth - LAYOUT_UTIL.SIDE_MENU.WIDTH.OPEN;
    } else {
      this.body_width = window.innerWidth - LAYOUT_UTIL.SIDE_MENU.WIDTH.CLOSE;
    }
    return {
      height: String(this.body_height) + 'px',
      width: String(this.body_width) + 'px'
    };
  }
}
