













































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import MultipleSelectBox from '@/components/atoms/selectbox/MultipleSelectBox.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';

@Component({
  components: {
    AppButton,
    MultipleSelectBox,
    BaseWindow
  }
})
export default class AddUsersWithDepartmentWindow extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  windowSize!: string;

  @Prop()
  isWhiteSpace?: boolean;

  @Prop()
  usersCorporationsSameMe: any;

  @Prop()
  selectedUsersCorporationsSameMe: any;

  selectedUserList: any = [];

  get displayUsersCorporationsSameMe() {
    return this.usersCorporationsSameMe;
  }

  @Watch('selectedUserList')
  selectUser(newValues, oldValues) {
    if (newValues.length < 1) {
      return [];
    }

    if (oldValues.length > newValues.length) {
      const removedUser = oldValues.find(v => {
        return !newValues.some(n => v.user_id === n.user_id);
      });
      this.removeSelectedUser(removedUser);
    } else {
      this.setMentiondCmmentUsers(newValues[newValues.length - 1]);
    }
  }

  updated() {
    if (!this.value) {
      this.selectedUserList = [];
    }
  }

  setMentiondCmmentUsers(user) {
    this.$emit('setMentiondCmmentUsers', user);
  }

  removeSelectedUser(user) {
    this.$emit('removeSelectedUser', user);
  }

  get isOpenWindow(): boolean {
    return this.value;
  }

  set isOpenWindow(isOpenWindow) {
    this.$emit('input', isOpenWindow);
  }

  registerMember() {
    this.$emit('registerMember', this.selectedUsersCorporationsSameMe);
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }
}
