import { CreateAccountInterface } from '@/interfaces/Account';

const resource = '/user-management/accounts';
const axios = require('axios').default;

export default {
  /**
   * アカウント一覧取得
   *
   * @param { string } idToken
   */
  list(idToken: string) {
    return axios.get('/user-management/corporations/management/list', {
      headers: { 'id-token': idToken }
    });
  },

  /**
   * 新規アカウント作成
   *
   * @param { Object } params
   * @param { string } idToken
   */
  create(params: CreateAccountInterface, idToken: string) {
    return axios.post(resource, params, {
      headers: { 'id-token': idToken }
    });
  },

  /**
   * サービスプラン一覧取得
   *
   * @param { string } idToken
   */
  fetchServicePlanList(idToken: string) {
    return axios.get(`${resource}/services/master`, {
      headers: { 'id-token': idToken }
    });
  },

  /**
   * サービスプラン変更
   *
   * @param {Object } params
   * @param idToken
   */
  update(params, idToken: string) {
    return axios.patch(
      `/user-management/corporations/${params.corporation_id}`,
      {
        payterms: params.payterms,
        service_plan_id: params.service_plan_id
      },
      {
        headers: { 'id-token': idToken }
      }
    );
  }
};
