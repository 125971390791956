
















import { Component, Prop, Vue } from 'vue-property-decorator';
import UserInfoModule from '@/store/UserInfo';
import CorporateInfoModule from '@/store/CorporateInfo';

@Component
export default class TextFormMidSize extends Vue {
  text: string = '';

  interval_id_1: number = 0;

  @Prop({ default: 'text' })
  type?: string;
  @Prop()
  inputId?: string;
  @Prop()
  placeholder?: string;
  @Prop()
  val_key?: string;
  @Prop({ default: 128 })
  maxlength?: number;
  @Prop({ default: false })
  isError: boolean;
  @Prop()
  name?: string;
  @Prop()
  autocomplete?: string;

  created() {
    if (this.val_key == 'email') {
      this.text = UserInfoModule.email;
    }
  }

  beforeDestroy() {
    clearInterval(this.interval_id_1);
  }

  save_text_val() {
    if (this.val_key == 'email') {
      UserInfoModule.SET_EMAIL(this.text);
    }

    if (this.val_key == 'corporate_name') {
      CorporateInfoModule.SET_CORPORATE_NAME(this.text);
    }

    if (this.val_key == 'corporate_tel') {
      CorporateInfoModule.SET_CORPORATE_TEL(this.text);
    }

    if (this.val_key == 'password') {
      UserInfoModule.SET_PASSWORD(this.text);
    }

    if (this.val_key == 'one_time_password') {
      UserInfoModule.SET_ONE_TIME_PASSWORD(this.text);
    }
  }
}
