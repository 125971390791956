

































































































import { Component, Vue, Watch, Emit } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import UserInfoModule from '@/store/UserInfo';
import AUTHORITIES from '@/consts/Authorities.js';
import SettingsInfoModule from '@/store/SettingsInfo';
import IconZoomOut from '@/components/atoms/icons/IconZoomOut.vue';

@Component({
  components: {
    AppButton,
    IconZoomOut,
    ImageContent
  }
})
export default class SettingsMembersHeader extends Vue {
  allowed: boolean = false;
  searchKeyword: string = '';

  @Watch('authority')
  check_authority() {
    if (
      this.authority == AUTHORITIES.OWNER ||
      this.authority == AUTHORITIES.ADMINISTRATOR
    ) {
      this.allowed = true;
    } else {
      this.allowed = false;
    }
  }

  @Watch('searchKeyword')
  resetKeyword() {
    if (!this.searchKeyword) {
      this.clearKeyword();
    }
  }

  get authority() {
    return UserInfoModule.authority;
  }

  mounted() {
    this.check_authority();
  }

  get can_user_edit() {
    return SettingsInfoModule.can_user_authority_edit;
  }

  get user_count(): number {
    return SettingsInfoModule.selected_users.length;
  }

  @Emit()
  clearKeyword() {
    this.searchKeyword = '';
    return;
  }
}
