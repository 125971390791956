

















import { Component, Vue, Prop } from 'vue-property-decorator';

import SettingsInfoModule from '@/store/SettingsInfo';
import ExplanationAuthority from '@/components/atoms/tables/ExplanationAuthority.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';

@Component({
  components: {
    ExplanationAuthority,
    BaseWindow
  }
})
export default class ExplanationAuthorityWindow extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  windowSize!: string;

  closeWindow() {
    SettingsInfoModule.SET_ADD_EXPLANATION_AUTHORITY_WINDOW(false);
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }
}
