const axios = require("axios").default;

const resource = "/user-management/slack";

export default {
  /**
   * Slack連携解除
   */
  disconnectSlack() {
    return axios.delete(`${resource}`);
  },

  /**
   * Slack通知登録
   */
  updateSlackNotification(data) {
    return axios.put(`${resource}/settings`, data);
  },

  /**
   * Slack用State保管
   */
  postSlackState(state: string) {
    return axios.post(`${resource}/state?state=${state}`);
  }
};
