













































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import LIRIS_NOTIFICATION_TYPES from '@/consts/LirisNotificationTypes.js';

import { validateEmpty } from '@/functions/Validation';

import {
  LirisNotificationDetail,
  LirisNotificationDetailError,
  LirisNotificationType
} from '@/interfaces/LirisNotification';

@Component({
  components: {
    BaseWindow,
    AppButton,
    TextForm,
    SelectBox
  }
})
export default class AddNotificationWindow extends Vue {
  @Prop()
  windowSize!: string;

  @Prop({ default: false })
  isWhiteSpace?: boolean;

  @Prop()
  updateNotification?: LirisNotificationDetail;

  @Prop(Boolean)
  value: boolean;

  private form: LirisNotificationDetail = null;
  private errors: LirisNotificationDetailError = null;

  private types: LirisNotificationType[] = [
    { id: 0, value: 'その他' },
    { id: 1, value: 'メンテナンス' },
    { id: 2, value: 'アップデート' },
    { id: 3, value: '利用規約の改訂' }
  ];

  get isDisabled(): boolean {
    if (this.form.title && this.form.type > -1) {
      return false;
    }

    return true;
  }

  get needUrlAndFileType() {
    if (
      this.form.type === LIRIS_NOTIFICATION_TYPES.HELP_CENTER ||
      this.form.type === null ||
      this.form.type === LIRIS_NOTIFICATION_TYPES.MAINTENANCE
    ) {
      return false;
    }
    return true;
  }

  @Watch('value')
  windowStatus(b) {
    if (!b) {
      return;
    }

    this.init();

    // 新規の場合は初期値
    if (!this.updateNotification) {
      return;
    }

    // 更新の場合はフォームに値を入れる
    this.form.liris_notification_id = this.updateNotification.liris_notification_id;
    this.form.title = this.updateNotification.title;
    this.form.contents = this.updateNotification.contents;
    this.form.url = this.updateNotification.url;
    this.form.type = this.updateNotification.type;
  }

  @Watch('form.selected_type')
  update_types(selected_type: LirisNotificationType) {
    if (!selected_type) return;
    this.form.type = selected_type.id;

    // メンテナンスの場合はurlを初期化する
    if (this.form.type === LIRIS_NOTIFICATION_TYPES.MAINTENANCE) {
      this.form.url = '';
    }
  }

  @Watch('form.type')
  update_selected_types(type: number) {
    if (!type) return;
    const target = this.types.find(x => x.id === type);

    if (!target) return;

    this.form.selected_type = target;
  }

  created() {
    this.init();
  }

  init() {
    this.initialForm();
    this.initialErrors();
    this.scrollTop();
  }

  scrollTop() {
    const activeDialog = document.getElementsByClassName('v-dialog--active');
    if (!activeDialog || !activeDialog.length) {
      return;
    }
    // v-dialogのスクロール位置をtopに戻す
    activeDialog[0].scrollTop = 0;
  }

  /**
   * フォームの初期化
   */
  private initialForm() {
    this.form = {
      liris_notification_id: '',
      title: '',
      contents: '',
      url: '',
      selected_type: null,
      type: null,
      notification_file: new File([], '')
    };
  }

  /**
   * フォームのエラーの初期化
   */
  private initialErrors() {
    this.errors = {
      title: '',
      contents: '',
      url: '',
      type: ''
    };
  }

  cancelNotification() {
    this.init();

    this.$emit('cancelNotification');
  }

  sendNotification() {
    this.initialErrors();

    // バリデーション
    this.errors.title = validateEmpty(this.form.title, 'タイトル');
    this.errors.type = validateEmpty(this.form.type, 'タイプ');

    this.$emit('sendNotification', this.form);
  }

  onFileUploaded(e) {
    this.form.notification_file = e.target.files[0];
  }
}
