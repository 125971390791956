export default class Regex {
  static readonly email_pattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.(?:.[a-zA-Z0-9-]+)+$/
  );
  static readonly dev_email_pattern = new RegExp(
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+\.(?:.[a-zA-Z0-9-]+)+$/
  );
  static readonly email_top_end_pattern_str = /[a-zA-Z0-9]/;
  static readonly dev_email_local_pattern_str = /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+/g;
  static readonly email_local_pattern_str = /[a-zA-Z0-9.!#$%&'*/=?^_`{|}~-]+/g;
  static readonly email_domain_pattern_str = /[a-zA-Z0-9-.]+/g;
  static readonly tel_pattern = new RegExp('^0[0-9]{9,10}$');
  static readonly fax_pattern = new RegExp('^(0[0-9]{9,10})?$');
  static readonly upper_pattern = new RegExp('[A-Z]');
  static readonly lower_pattern = new RegExp('[a-z]');
  static readonly num_pattern = new RegExp('[0-9]');
}
