
























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import KEY_CODE from '@/consts/KeyCode';

@Component
export default class TextForm extends Vue {
  @Prop({ default: 'text' })
  type?: string;

  @Prop()
  label?: string;

  @Prop()
  value!: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: 128 })
  maxlength?: number;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: false })
  readonly?: boolean;

  @Prop()
  message?: string;

  @Prop({
    type: Object,
    default: () => {
      return {};
    }
  })
  classes?: object;

  @Prop()
  styleItem?: object;

  @Prop({ default: false })
  initFocus?: boolean;

  get classObject(): object {
    return Object.assign(this.classes, { 'input-error': this.message });
  }

  get refs(): any {
    return this.$refs;
  }

  @Watch('initFocus')
  changeInitFocus(val) {
    if (val)
      this.$nextTick(() => {
        this.focus();
      });
  }

  mounted() {
    if (this.initFocus)
      this.$nextTick(() => {
        this.focus();
      });
  }

  focus() {
    this.refs.input.focus();
  }

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.$emit('enter-key');
    }
  }
}
