







































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import SettingsInfoModule from '@/store/SettingsInfo';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';

@Component({
  components: {
    BaseTable
  }
})
export default class DepartmentMembersTable extends Vue {
  department_name: string;
  department: any;
  selected: any = [];

  @Prop()
  value: any;

  get users_with_department() {
    return SettingsInfoModule.users_with_department;
  }

  /**
   * すべてのチェックボックスが checked かどうかを返す
   */
  get areAllChecked(): boolean {
    if (this.selected.length === 0) return false;
    return this.selected.length === this.users_with_department.length;
  }

  /**
   * すべての項目のチェックボックスの checked を付けたり外したりする
   */
  changeCheckedAll(): void {
    if (this.areAllChecked) {
      this.selected = [];
      return;
    }
    this.selected = this.users_with_department;
  }

  @Watch('value')
  update_value(new_value) {
    this.selected = new_value;
  }

  @Watch('selected')
  update_selected(new_value) {
    this.$emit('input', new_value);
  }
}
