import { render, staticRenderFns } from "./EmailNotifications.vue?vue&type=template&id=34b11a15&scoped=true&lang=pug&"
import script from "./EmailNotifications.vue?vue&type=script&lang=ts&"
export * from "./EmailNotifications.vue?vue&type=script&lang=ts&"
import style0 from "./EmailNotifications.vue?vue&type=style&index=0&id=34b11a15&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b11a15",
  null
  
)

export default component.exports