






































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import { dateFormatterForNotification } from '@/functions/FormatDate';
import LIRIS_NOTIFICATION_TYPES from '@/consts/LirisNotificationTypes.js';

@Component({
  components: {
    BaseTable
  }
})
export default class NotificationsTable extends Vue {
  selected: any = [];

  @Prop()
  value: any;

  @Prop()
  notifications: any[];

  @Watch('value')
  update_value(new_value) {
    this.selected = new_value;
  }

  @Watch('selected')
  update_selected(new_value) {
    this.$emit('input', new_value);
  }

  /**
   * すべてのチェックボックスが checked かどうかを返す
   */
  get areAllChecked(): boolean {
    if (this.selected.length === 0) return false;
    return this.selected.length === this.notifications.length;
  }

  /**
   * すべての項目のチェックボックスの checked を付けたり外したりする
   */
  changeCheckedAll(): void {
    if (this.areAllChecked) {
      this.selected = [];
      return;
    }
    this.selected = this.notifications;
  }

  formatDate(date) {
    return dateFormatterForNotification(date);
  }

  notificationTypeToStr(type): string {
    if (type === LIRIS_NOTIFICATION_TYPES.MAINTENANCE) {
      return 'メンテナンス';
    }

    if (type === LIRIS_NOTIFICATION_TYPES.UPDATE) {
      return 'アップデート';
    }

    return 'ヘルプセンター';
  }

  trimString(str: string, length: number) {
    if (!str) return '';

    const trimString = str.substr(0, length);
    if (str.length > length) {
      return `${trimString}...`;
    }

    return trimString;
  }
}
