











































































































































































































































































































































































import AppCalendar from '@/components/atoms/AppCalendar.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import Icon from '@/components/atoms/icons/Icon.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import ExtractIcon from '@/components/molecules/ExtractIcon.vue';
import TextFormFlexibleWidth from '@/components/molecules/TextFormFlexibleWidth.vue';
import TextFormFlexibleWidthForNumber from '@/components/molecules/TextFormFlexibleWidthForNumber.vue';
import SelectLabels from '@/components/molecules/labels/SelectLabels.vue';
import InfoAutoUpdate from '@/components/organisms/DocumentDetail/DocumentInfo/InfoAutoUpdate.vue';
import InfoListItem from '@/components/organisms/DocumentDetail/DocumentInfo/InfoListItem.vue';
import AUTHORITIES from '@/consts/Authorities.js';
import ContractInfo from '@/store/ContractInfo';
import Documents from '@/store/Documents';
import SettingsInfoModule, { AlertLimit, AlertLimitType } from '@/store/SettingsInfo';
import UserInfo from '@/store/UserInfo';
import dayjs from 'dayjs';
import { Component, Emit, Vue, Watch } from 'vue-property-decorator';

import DocumentFunction from '@/functions/DocumentFunction';
import { catchOnGetContractDetail } from '@/functions/DocumentRouter';
import FileDownload from '@/functions/FileDownload';
import Validation from '@/functions/Validation';
import notify from '@/functions/notify';

import ATTRIBUTES from '@/consts/Attributes';
import HTTP_STATUS from '@/consts/HttpStatus';
import LOADING_STATUSES from '@/consts/LoadingStatuses';
import NOTIFY_TEXT from '@/consts/NotifyText';
import FileUpload from '@/functions/FileUpload';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');

@Component({
  components: {
    SelectBox,
    ExtractIcon,
    InfoAutoUpdate,
    InfoListItem,
    AppButton,
    AppCalendar,
    Icon,
    TextFormFlexibleWidth,
    TextFormFlexibleWidthForNumber,
    SelectLabels
  }
})
export default class DocumentInfoContract extends Vue {
  needToChangedExpiration: boolean = false;
  needToChangedAutoUpdateDate: boolean = false;

  inputCounterpartyName: string = '';
  inputContractTitle: string = '';
  inputContractDate: string = '';
  inputContractStartDate: string = '';
  inputExpiration: string = '';
  inputAutoUpdateDate: string = '';
  inputDescription: string = '';
  inputTransactionAmount: any = 0;
  // 自動更新の星アイコン算出用
  autoUpdateRuleDup: any;

  savingStatuses: object = {
    contract_number: LOADING_STATUSES.DEFAULT,
    contract_title: LOADING_STATUSES.DEFAULT,
    counterparty_name: LOADING_STATUSES.DEFAULT,
    contract_type_id: LOADING_STATUSES.DEFAULT,
    contract_labels: LOADING_STATUSES.DEFAULT,
    description: LOADING_STATUSES.DEFAULT,
    contract_date: LOADING_STATUSES.DEFAULT,
    contract_start_date: LOADING_STATUSES.DEFAULT,
    expiration: LOADING_STATUSES.DEFAULT,
    auto_update_rule: LOADING_STATUSES.DEFAULT,
    auto_update_date: LOADING_STATUSES.DEFAULT,
    transaction_amount: LOADING_STATUSES.DEFAULT
  };

  editDescription: boolean = false;
  editCustomAttribute: any = [];

  contractTitleErrorMessage: string = '';
  need_update_contract_info: boolean = false;

  created() {
    SettingsInfoModule.get_setting_alert();
    this.setSavingStatuses(
      this.contractAttributes.map(attr => attr.id),
      LOADING_STATUSES.DEFAULT
    );
    this.initEditCustomAttribute();
  }

  mounted() {
    this.inputCounterpartyName = this.contractInfo.counterparty_name;
    this.inputContractTitle = this.contractInfo.contract_title;
    this.inputContractDate = this.contractInfo.contract_date;
    this.inputContractStartDate = this.contractInfo.contract_start_date;
    this.inputExpiration = this.contractInfo.expiration;
    this.inputAutoUpdateDate = this.contractInfo.auto_update_date;
    this.inputTransactionAmount = this.contractInfo.transaction_amount;
    this.autoUpdateRuleDup = this.autoUpdateRule;
    this.inputDescription =
      this.contractInfo.description === 'null'
        ? ''
        : this.contractInfo.description;

    this.editDescription = false;
    this.initEditCustomAttribute();
  }

  initEditCustomAttribute() {
    this.editCustomAttribute = this.contractAttributes.map(attr => {
      return {
        id: attr.id,
        edit: false
      };
    });
  }

  falseEditCustomAttribute(id) {
    const targetIndex = this.editCustomAttribute.findIndex(
      editCustomAttribute => editCustomAttribute.id === id
    );
    this.editCustomAttribute[targetIndex].edit = false;
  }

  formatHrefLink(string) {
    const regex = /(https?:\/\/[\w\.\d:;?&=#/_+!$'()*@~%\-]+)/gm;
    const subst = `$1<a href="$1" target="_blank" rel="noopener noreferrer"><img id="openLinkIcon" src="/static/icons/external-link-blue.svg" style='width: 14px; margin: 0 8px;'></a>`;
    const result = string.replace(regex, subst);
    return result;
  }

  handleLinkClick(e) {
    const clickedElId = e.target.id
    if (clickedElId === 'openLinkIcon') {
      return true
    }
    return false
  }

  canEditAttribute(attr) {
    const targetIndex = this.editCustomAttribute.findIndex(
      editCustomAttribute => editCustomAttribute.id === attr.id
    );
    if (this.permission.can_edit_contract_info) {
      if (!attr.values[0].text) {
        this.editCustomAttribute[targetIndex].edit = true;
      }
    } else {
      this.editCustomAttribute[targetIndex].edit = false;
    }
    return this.editCustomAttribute[targetIndex].edit;
  }

  get canEditDescription() {
    if (this.permission.can_edit_contract_info) {
      if (!this.inputDescription) {
        this.editDescription = true;
      }
    } else {
      this.editDescription = false;
    }
    return this.editDescription;
  }

  clickDescription(e) {
    if (this.handleLinkClick(e)) {
      this.editDescription = false;
    } else {
      this.editDescription = true;
    }
  }

  clickAttribute(e, attr) {
    const targetIndex = this.editCustomAttribute.findIndex(
      editCustomAttribute => editCustomAttribute.id === attr.id
    );
    if (this.handleLinkClick(e)) {
      this.editCustomAttribute[targetIndex].edit = false;
    } else {
      this.editCustomAttribute[targetIndex].edit = true;
    }
  }

  get fileStatus() {
    return Documents.file_status;
  }

  get alreadyFetchContract() {
    return Documents.already_fetch_contract;
  }

  get versions() {
    const versions = this.contractDetail.contract_version_list;
    const lastestVersion = versions[0];
    if (lastestVersion && lastestVersion.is_last_version) {
      lastestVersion.version = '締結済';
    }
    return versions;
  }

  get isAlreadyAutoExtraction() {
    // 未作成の場合は自動抽出されたことにする
    if (!this.versions.length) {
      return true;
    }
    if (!this.fileStatus) {
      return false;
    }
    if (
      this.fileStatus &&
      (this.fileStatus.autoextraction_status === 0 ||
        this.fileStatus.autoextraction_status === 1)
    ) {
      return false;
    }
    if (!this.alreadyFetchContract) {
      return false;
    }

    return true;
  }

  get permission() {
    return UserInfo.permission;
  }

  get isGuest() {
    return UserInfo.authority === AUTHORITIES.GUEST;
  }

  get isShowingAlert() {
    if (
      this.alertStatus.type === AlertLimitType.DoUpdateOrScheduledEnd &&
      !this.needToChangedExpiration
    ) {
      return true;
    } else {
      return false;
    }
  }

  get contractTypes() {
    return ContractInfo.boardContractTypes;
  }

  get contractAllLabels() {
    return Documents.contract_labels;
  }

  get contractDetail() {
    return Documents.contract_detail;
  }

  get contractInfo() {
    if (this.contractDetail) {
      return this.contractDetail.contract_info;
    }
    return null;
  }

  get contractAttributes() {
    if (this.contractInfo) {
      return this.contractInfo.attributes;
    }
    return [];
  }

  get ATTRIBUTE_TYPES() {
    return ATTRIBUTES.TYPES;
  }

  get neededSignedFileColumn() {
    return ContractInfo.neededSignedFileColumn;
  }

  @Emit()
  startUpload(fileCount) {
    return fileCount;
  }

  @Emit()
  endUpload() {
    return;
  }

  @Watch('contractInfo', { deep: true })
  changeContractInfo(contractInfo) {
    this.inputCounterpartyName = contractInfo.counterparty_name;
    this.inputContractTitle = contractInfo.contract_title;
    this.inputContractDate = contractInfo.contract_date;
    this.inputContractStartDate = contractInfo.contract_start_date;
    this.inputExpiration = contractInfo.expiration;
    this.inputAutoUpdateDate = contractInfo.auto_update_date;
    this.inputTransactionAmount = this.contractInfo.transaction_amount;
    this.inputDescription =
      contractInfo.description === 'null' ? '' : contractInfo.description;
  }

  get isAfterApprovale(): boolean {
    if (this.contractDetail.is_signed) return true;

    const kanban_card_info = this.contractDetail.kanban_card_info;
    if (!kanban_card_info) return false;

    const columnInfo = ContractInfo.getColumnById(kanban_card_info.column_id);
    const neededSignedFileColumn = ContractInfo.neededSignedFileColumn;
    if (!columnInfo || !neededSignedFileColumn) return false;

    return columnInfo.column_order >= neededSignedFileColumn.column_order;
  }

  get autoExtractData() {
    return this.contractDetail.auto_extractions;
  }

  get selectedVersion() {
    return Documents.selectedVersion;
  }

  get autoUpdateRule() {
    if (!this.contractInfo.auto_update_rule) {
      return {
        is_active: false,
        pre_notice_number: null,
        pre_notice_unit: null,
        auto_update_number: null,
        auto_update_unit: null
      };
    }
    return this.contractInfo.auto_update_rule;
  }

  get contractType() {
    return this.contractInfo.contract_type;
  }
  set contractType(contract_type) {
    if (!contract_type) {
      contract_type = {
        contract_type_id: null,
        contract_type_name: null
      };
      Documents.SET_CONTRACT_INFO_PROPS({ contract_type });
      this.updateContractDetail({
        contract_type_id: 'delete'
      });
    } else {
      Documents.SET_CONTRACT_INFO_PROPS({ contract_type });
      this.updateContractDetail({
        contract_type_id: contract_type.contract_type_id
      });
    }
  }

  get controlNumber() {
    return this.contractDetail.control_number;
  }
  set controlNumber(control_number) {
    Documents.SET_CONTRACT_DETAIL_PROPS({ control_number });
    this.updateContractDetail({ control_number });
  }

  get contractLabels() {
    return this.contractInfo.contract_labels || null;
  }
  set contractLabels(contract_labels) {
    Documents.SET_CONTRACT_INFO_PROPS({ contract_labels });
    const label_id_list = contract_labels.map(v => v.label_id);
    this.setSavingStatuses(['contract_labels'], LOADING_STATUSES.LOADING);
    DocumentRepository.updateContractLabels({
      contract_id: this.contractInfo.contract_id,
      label_id_list
    })
      .then(() => {
        this.setSavingStatuses(['contract_labels'], LOADING_STATUSES.COMPLETE);
      })
      .catch(e => {
        this.setSavingStatuses(['contract_labels'], LOADING_STATUSES.FAILURE);
      });
  }

  get isNoneExpiration(): boolean {
    return this.contractInfo.is_none_expiration;
  }
  set isNoneExpiration(is_none_expiration: boolean) {
    const params = {
      is_none_expiration,
      expiration: this.inputExpiration
    };
    if (is_none_expiration) {
      Documents.SET_CONTRACT_INFO_PROPS({ expiration: '' });
      params.expiration = '';
    }
    Documents.SET_CONTRACT_INFO_PROPS({ is_none_expiration });
    this.updateContractDetail(params);
  }

  get isContractEnded(): boolean {
    return this.contractInfo.is_ended;
  }
  set isContractEnded(is_ended: boolean) {
    Documents.SET_CONTRACT_INFO_PROPS({ is_ended });
    this.updateContractDetail({ is_ended });
  }

  get isAlertStatus(): boolean {
    return (
      this.alertStatus.type === AlertLimitType.DoScheduledEnd ||
      this.alertStatus.type === AlertLimitType.DoUpdateOrScheduledEnd
    );
  }

  isAttributeAlert(attr): boolean {
    if (
      attr.alert &&
      attr.values[0].is_alert_timing &&
      !attr.values[0].hide_alert
    ) {
      return true;
    }
    return false;
  }

  get AlertLimitType() {
    return AlertLimitType;
  }

  get alertStatus(): AlertLimit {
    return SettingsInfoModule.alertStatus(
      this.contractInfo.expiration,
      this.contractInfo.auto_update_date,
      this.contractInfo.is_scheduled_end,
      this.contractInfo.is_ended
    );
  }

  get autoUpdateDate() {
    return this.contractInfo.auto_update_date;
  }

  @Watch('inputContractDate')
  changeContractDate(val) {
    this.inputContractDate = this.dateFormat(val);
  }

  @Watch('inputContractStartDate')
  changeContractStartDate(val) {
    this.inputContractStartDate = this.dateFormat(val);
  }

  @Watch('inputExpiration')
  changeExpiration(val) {
    this.inputExpiration = this.dateFormat(val);
  }

  @Watch('inputAutoUpdateDate')
  changeAutoUpdateDate(val) {
    this.inputAutoUpdateDate = this.dateFormat(val);
  }

  setSavingStatuses(keys, status) {
    keys.forEach(k => this.$set(this.savingStatuses, k, status));
  }

  updateContractDetail(params) {
    this.hideStarIcon(params);
    const paramKeys = Object.keys(params);
    this.setSavingStatuses(paramKeys, LOADING_STATUSES.LOADING);
    DocumentRepository.updateContractDetail({
      contract_id: this.contractInfo.contract_id,
      ...params
    })
      .then(() => {
        this.setSavingStatuses(paramKeys, LOADING_STATUSES.COMPLETE);
      })
      .catch(e => {
        if (e.response.status === HTTP_STATUS.CONFLICT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.ALREADY_EXIST_CONTROL_NUMBER
          });
          return;
        }
        this.setSavingStatuses(paramKeys, LOADING_STATUSES.FAILURE);
        return;
      });
  }

  updateContractDetailCustomAttributes(params) {
    const paramKeys = [params.contract_attribute_id];
    this.setSavingStatuses(paramKeys, LOADING_STATUSES.LOADING);
    DocumentRepository.postContractAttributeValue({
      contract_id: this.contractInfo.contract_id,
      ...params
    })
      .then(() => {
        this.setSavingStatuses(paramKeys, LOADING_STATUSES.COMPLETE);
      })
      .catch(e => {
        if (e.response.status === HTTP_STATUS.CONFLICT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.ALREADY_EXIST_CONTROL_NUMBER
          });
          return;
        }
        this.setSavingStatuses(paramKeys, LOADING_STATUSES.FAILURE);
        return;
      });
  }

  updateDescription(v) {
    const description = v.target.innerText;
    Documents.SET_CONTRACT_INFO_PROPS({ description });
    this.updateContractDetail({ description });
  }

  async uploadVersion(event) {
    const isCheckFileSize = FileUpload.checkAllowedFileSize(
      event.target.files[0].size
    );
    if (!isCheckFileSize) return;
    const formData = new FormData();
    formData.append('contract', event.target.files[0]);

    const fileCount = event.target.files.length;

    this.startUpload(fileCount);

    const params = {
      contract_id: this.contractDetail.contract_info.contract_id,
      is_last_version: false,
      is_stored: false,
      formData
    };
    await DocumentRepository.uploadNewVersion(params)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
        });
        return;
      });

    await Documents.reloadContractDetail().catch(err => {
      catchOnGetContractDetail(this.$router, err);
    });
    this.endUpload();

    // アップロード完了
    notify.success({
      text: fileCount + NOTIFY_TEXT.SUCCESS.UPLOAD_SUFFIX
    });
  }

  updateAutoUpdateRule(auto_update_rule) {
    Documents.SET_CONTRACT_INFO_PROPS({ auto_update_rule });
    this.updateContractDetail({ auto_update_rule });
    if (!auto_update_rule.is_active) {
      this.inputAutoUpdateDate = '';
      this.updateAutoUpdateDate(null, {
        auto_update_date: this.inputAutoUpdateDate
      });
    }
  }

  updateExpiration(event, targetObject) {
    if (targetObject.expiration != this.inputExpiration) {
      const is_scheduled_end = false;
      Documents.SET_CONTRACT_INFO_PROPS({ is_scheduled_end });
    }
    Documents.SET_CONTRACT_INFO_PROPS(targetObject);
    this.updateContractDetail(
      Object.assign({}, targetObject, {
        is_scheduled_end: false
      })
    );
    this.needToChangedExpiration = false;
  }

  updateAutoUpdateDate(event, targetObject) {
    Documents.SET_CONTRACT_INFO_PROPS(targetObject);
    this.updateContractDetail(
      Object.assign({}, targetObject, {
        is_scheduled_end: false
      })
    );
    this.needToChangedAutoUpdateDate = false;
  }

  hideStarIcon(params) {
    // autoExtractData.x は0または1で星アイコンが表示されるため、編集して応答が返ってくるまで-1の値を格納
    if ('contract_title' in params) {
      this.autoExtractData.contract_title = -1;
    }
    if ('counterparty_name' in params) {
      this.autoExtractData.counter_party = -1;
    }
    if ('description' in params) {
      this.autoExtractData.description = -1;
    }
    if ('contract_date' in params) {
      this.autoExtractData.contract_date = -1;
    }
    if ('contract_start_date' in params) {
      this.autoExtractData.contract_start_date = -1;
    }
    if ('contract_date' in params) {
      this.autoExtractData.contract_date = -1;
    }
    if ('expiration' in params || 'is_none_expiration' in params) {
      this.autoExtractData.expiration = -1;
    }
    if ('auto_update_rule' in params) {
      this.autoExtractData.auto_update = -1;
    }
    if ('auto_update_date' in params) {
      this.autoExtractData.auto_update_date = -1;
    }
  }

  changedItem(event, targetObject) {
    this.updateItem(targetObject, event);
    this.editDescription = false;
  }

  updateItem(target, event) {
    if ('contract_title' in target) {
      this.contractTitleErrorMessage = Validation.validateFileNameAndFolderName(
        target.contract_title
      );
      if (this.contractTitleErrorMessage) return;
    }

    // ドキュメント詳細の相手方の変更でnullの場合は空文字でリクエストする
    if ('counterparty_name' in target && target.counterparty_name === null) {
      target.counterparty_name = '';
    }

    if (
      'transaction_amount' in target &&
      !target.transaction_amount &&
      target.transaction_amount !== 0
    ) {
      target.transaction_amount = null;
    }

    Documents.SET_CONTRACT_INFO_PROPS({ ...target });
    this.updateContractDetail(target);
  }

  changedCustomAttributeSelectItem(targetObject, attributeId) {
    if (!targetObject) {
      this.updateCustomAttributeItem({
        contract_attribute_id: attributeId,
        contract_attribute_item_id: null,
        text: null,
        value: null,
        order: 0
      });
      return;
    }
    this.updateCustomAttributeItem({
      contract_attribute_id: targetObject.contract_attribute_id,
      contract_attribute_item_id: targetObject.id,
      text: targetObject.text,
      value: targetObject.text,
      order: 0
    });
  }

  changedCustomAttributeDatetimeItem(event, targetObject) {
    for (const [key, value] of Object.entries(targetObject)) {
      this.updateCustomAttributeItem({
        contract_attribute_id: key,
        text: value,
        // ↓日付の数字を削除したときにエラーになるため一旦コメントアウト
        // datetime: new Date(value as string).toISOString(),
        value,
        order: 0
      });
    }
  }

  changedCustomAttributeTextItem(event, targetObject) {
    for (const [ key, value ] of Object.entries(targetObject)) {
      this.falseEditCustomAttribute(key);
      this.updateCustomAttributeItem({
        contract_attribute_id: key,
        text: value,
        value,
        order: 0
      });
    }
  }

  updateCustomAttributeItem(target) {
    Documents.SET_CONTRACT_INFO_CUSTOM_ATTRIBUTE_PROP(target);

    this.updateContractDetailCustomAttributes(target);
  }

  hideAlert() {
    const is_scheduled_end = true;
    Documents.SET_CONTRACT_INFO_PROPS({ is_scheduled_end });
    this.updateContractDetail({ is_scheduled_end });
  }

  hideAttributeAlert(attr) {
    const value = attr.values[0].text;
    this.updateCustomAttributeItem({
      contract_attribute_id: attr.id,
      text: value,
      // ↓日付の数字を削除したときにエラーになるため一旦コメントアウト
      // datetime: new Date(value as string).toISOString(),
      value,
      order: 0,
      hide_alert: true
    });
  }

  onClickAutoUpdateDate() {
    this.needToChangedExpiration = true;
    this.needToChangedAutoUpdateDate = true;
    this.inputExpiration = '';
    this.inputAutoUpdateDate = '';
  }

  dateFormat(value) {
    if (!value) return '';
    return dayjs(value).format('YYYY-MM-DD');
  }

  isDisplayAutoExtract(autoExtract) {
    if (!this.selectedVersion) return false;
    return DocumentFunction.isDisplayAutoExtract(autoExtract);
  }

  async downloadSignedFile() {
    const contractVersionId = this.selectedVersion.contract_version_id;
    const fileExtension = this.selectedVersion.file_extension;
    const filename = `${this.selectedVersion.file_name}.${fileExtension}`;

    const result = await FileDownload.downloadSignedFile(
      contractVersionId,
      filename
    );

    if (!result) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.GENERAL
      });
    }
  }
}
