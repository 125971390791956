


































































import { Component, Vue } from 'vue-property-decorator';
import UserInfoModule from '@/store/UserInfo';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import NotificationsTable from '@/components/organisms/Settings/Liris/NotificationsTable.vue';
import AddNotificationWindow from '@/components/molecules/modal_windows/AddNotificationWindow.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import { LirisNotificationDetail } from '@/interfaces/LirisNotification';
import notify from '@/functions/notify';
import vLoading from 'vue-loading-overlay';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const LirisNotificationRepository = RepositoryFactory.get('lirisNotifications');

@Component({
  components: {
    AppButton,
    NotificationsTable,
    AddNotificationWindow,
    TextOnlyWindow,
    vLoading
  }
})
export default class Notifications extends Vue {
  notifications: LirisNotificationDetail[] = [];
  selected_notifications: LirisNotificationDetail[] = [];
  is_showing_add_notifications_window: boolean = false;
  is_showing_delete_notifications_window: boolean = false;
  isLoading: boolean = false;

  get is_selected(): boolean {
    return this.selected_notifications.length > 0;
  }

  get can_edit(): boolean {
    return this.selected_notifications.length === 1;
  }

  get updateTargetNotification(): LirisNotificationDetail {
    if (this.can_edit) {
      return this.selected_notifications[0];
    }
    return null;
  }

  async created() {
    await LirisNotificationRepository.index()
      .then(res => {
        this.notifications = res.data;
      })
      .catch(e => {
        notify.error({ text: 'お知らせの取得に失敗しました。' });
      });
  }

  openNotificationWindow() {
    this.is_showing_add_notifications_window = true;
  }

  closeNotificationWindow() {
    this.is_showing_add_notifications_window = false;
  }

  openRemoveNotification() {
    this.is_showing_delete_notifications_window = true;
  }

  closeDeleteNotifications() {
    this.is_showing_delete_notifications_window = false;
  }

  async sendNotification(notification: LirisNotificationDetail) {
    const idToken = await UserInfoModule.getIdToken();
    const method = notification.liris_notification_id ? 'update' : 'create';

    await LirisNotificationRepository[method](notification, idToken)
      .then(() => {
        notify.success({ text: 'お知らせを保存しました' });
      })
      .catch(e => {
        notify.error({ text: 'お知らせの保存に失敗しました。' });
      });

    if (notification.liris_notification_id) {
      const index = this.notifications.findIndex(
        n => n.liris_notification_id === notification.liris_notification_id
      );
      this.notifications[index] = notification;
    } else {
      this.notifications = [...this.notifications, notification];
    }

    this.closeNotificationWindow();
    this.selected_notifications = [];
  }

  async deleteNotifications() {
    this.isLoading = true;
    await this.execDeleteNotifications()
      .then(() => {
        this.selected_notifications = [];
        notify.success({ text: '削除しました' });
      })
      .catch(e => {
        notify.error({ text: 'お知らせの削除に失敗しました。' });
      });

    await LirisNotificationRepository.index()
      .then(res => {
        this.notifications = res.data;
      })
      .catch(e => {
        notify.error({ text: 'お知らせの取得に失敗しました。' });
      });

    this.isLoading = false;

    this.closeDeleteNotifications();
    this.selected_notifications = [];
  }

  async execDeleteNotifications() {
    const idToken = await UserInfoModule.getIdToken();
    this.selected_notifications.forEach(async selected_notification => {
      await LirisNotificationRepository.delete(
        selected_notification.liris_notification_id,
        idToken
      );
      this.notifications = this.notifications.filter(
        n =>
          n.liris_notification_id !==
          selected_notification.liris_notification_id
      );
    });
  }
}
