















import { Component, Vue, Prop } from 'vue-property-decorator';
import TextFormName from '@/components/atoms/texts/TextFormName.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';

@Component({
  components: {
    TextFormName,
    TextForm
  }
})
export default class TextFormWidth480WithTitle extends Vue {
  @Prop()
  title_text!: string;
  @Prop()
  message?: string;
  @Prop()
  maxlength?: number;
  @Prop({ default: false })
  readonly: boolean;
  @Prop({ default: '' })
  value: string;

  get internalValue() {
    return this.value;
  }
  set internalValue(v) {
    this.$emit('input', v);
  }
}
