





























import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class TextFormFlexibleWidth extends Vue {
  @Prop({ default: 'text' })
  type?: string;

  @Prop({ default: '' })
  name!: string;

  @Prop()
  placeholder?: string;

  @Prop({ default: '' })
  value!: string;

  @Prop({ default: '' })
  maxlength?: string;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ type: Object as () => {} })
  classes?: {};

  @Prop({ default: '8em' })
  minWidth?: string;

  @Prop({ default: '100%' })
  maxWidth?: string;

  @Prop({ default: false })
  multipleLine?: boolean;

  @Prop({ default: '' })
  errorMessage?: string;

  get styleObject() {
    return {
      minWidth: this.minWidth,
      maxWidth: this.maxWidth
    };
  }

  get inputValue() {
    return this.value;
  }

  set inputValue(value) {
    this.$emit('input', value);
    this.adjustHeight();
  }

  mounted() {
    this.adjustHeight();
  }

  change($event) {
    this.$emit('change', $event, {
      [this.name]: this.value
    });
  }

  blur($event) {
    this.$emit('blur', $event, {
      [this.name]: this.value
    });
  }

  adjustHeight() {
    if (!this.multipleLine) {
      return;
    }
    const textarea = this.$refs.textarea;
    textarea['style'].height = 'auto';
    this.$nextTick(() => {
      textarea['style'].height = textarea['scrollHeight'] + 'px';
    });
  }
}
