<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14.828"
    height="14.828"
    viewBox="0 0 14.828 14.828"
  >
    <g
      id="e-remove"
      :class="`is_${color}`"
      class="cls-1"
      transform="translate(-3.586 -3.586)"
    >
      <path
        id="線_53"
        :class="`is_${color}`"
        class="cls-2"
        d="M12 0L0 12"
        data-name="線 53"
        transform="translate(5 5)"
      />
      <path
        id="線_54"
        :class="`is_${color}`"
        class="cls-2"
        d="M12 12L0 0"
        data-name="線 54"
        transform="translate(5 5)"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'black',
      validator(value) {
        return ['black', ''].includes(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cls-1 {
  opacity: 0.699;
}

.cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-width: 2px;
}

.is_black {
  stroke: $Black25;
}
</style>
