





































































import { Component, Vue } from 'vue-property-decorator';
import SettingsInfoModule from '@/store/SettingsInfo';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

import DepartmentTable from '@/components/organisms/Settings/DepartmentTable.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import SingleInputFormWindow from '@/components/molecules/modal_windows/SingleInputFormWindow.vue';
import notify from '@/functions/notify';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';

/**
 * アカウント情報更新画面用Component
 * 入力部品をまとめて表示・更新を行う
 */
@Component({
  components: {
    DepartmentTable,
    TextOnlyWindow,
    SingleInputFormWindow,
    AppButton
  }
})
export default class Department extends Vue {
  selected_departments: any[] = [];
  is_showing_edit_department_name_window: boolean = false;
  is_showing_delete_department_name_window: boolean = false;

  async created() {
    await SettingsInfoModule.get_departments().catch(e =>
      notify.error({ text: '所属部署情報を取得できませんでした。' })
    );
  }

  get departments() {
    return SettingsInfoModule.departments;
  }

  get is_only_display_delete_button(): boolean {
    return this.selected_departments.length > 1;
  }

  get is_department_edit_mode(): boolean {
    return this.selected_departments.length !== 0;
  }

  get selected_department_for_edit() {
    return this.selected_departments[0];
  }

  add_department(): void {
    SettingsInfoModule.SET_CAN_OPEN_ADD_DEPARTMENT_WINDOW(true);
  }

  delete_department(): void {
    if (this.departments.length === this.selected_departments.length) {
      notify.error({
        text: '部署を全て削除することはできません'
      });
      return;
    }
    this.is_showing_delete_department_name_window = true;
  }

  open_edit_department_name_window(): void {
    this.is_showing_edit_department_name_window = true;
  }

  cancelEditDepartment() {
    this.is_showing_edit_department_name_window = false;
  }

  async editDepartment(value) {
    await SettingsInfoModule.put_departments({
      selected_departments: this.selected_departments,
      edit_name: value
    })
      .then(() => {
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.CHANGE_DEPARTMENT_NAME
        });
      })
      .catch((message: string) => {
        notify.error({ text: message });
      });
    this.selected_departments = [];
    this.is_showing_edit_department_name_window = false;
  }

  cancelDeleteDepartment() {
    this.is_showing_delete_department_name_window = false;
  }

  async deleteDepartment() {
    await SettingsInfoModule.delete_departments(this.selected_departments)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE_DEPARTMENT
          });
          return;
        }

        notify.success({
          text: NOTIFY_TEXT.SUCCESS.DELETE_DEPARTMENT
        });

        this.selected_departments = [];
        this.is_showing_delete_department_name_window = false;
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.DELETE_DEPARTMENT
        });
      });
  }
}
