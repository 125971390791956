import Vue from 'vue';
import Vuex from 'vuex';
import {
  Module,
  VuexModule,
  Mutation,
  getModule
} from 'vuex-module-decorators';
import store from '@/store';

Vue.use(Vuex);

@Module({
  dynamic: true,
  store: store,
  name: 'router',
  namespaced: true
  // preserveState: true
})
export class Router extends VuexModule {
  histories = window.sessionStorage.getItem('histories')
    ? window.sessionStorage.getItem('histories')
    : '';

  @Mutation
  SET_HISTORIES(url: string) {
    if (!this.histories) {
      this.histories = url;
    } else {
      const last_url = this.histories.split(',').slice(-1)[0];
      if (last_url != url) {
        this.histories = this.histories + ',' + url;
      }
    }
    window.sessionStorage.setItem('histories', this.histories);
  }
}

const RouterModule = getModule(Router);

export default RouterModule;
