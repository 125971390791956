





import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class RouterLinkText extends Vue {
  @Prop({ default: '' })
  text?: string;
  @Prop({ default: 'login' })
  path_name?: string;
}
