import notify from '@/functions/notify';
// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import { Service } from 'axios-middleware';
import VTooltip from 'v-tooltip';
import Vue from 'vue';
import VueCookies from 'vue-cookies-ts';
import Notifications from 'vue-notification';
import App from './App.vue';
import './plugins/fortawesome';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store';
const axios = require('axios').default;
const axiosRetry = require('axios-retry');

// const os = require('os');

// Sentry.init({
//   dsn:
//     'https://7a712cf279e145628d4ec4f19837168a@o374101.ingest.sentry.io/5191582',
//   integrations: [
//     new Integrations.Vue({
//       Vue,
//       attachProps: true,
//       logErrors: true
//     })
//   ]
// });

// Sentry.configureScope(scope => {
//   scope.setTag('environment', process.env.NODE_ENV);
//   scope.setTag('domain', os.hostname());
// });

Vue.use(VueCookies);
Vue.use(Notifications);
Vue.use(VTooltip);

axios.defaults.baseURL = process.env.VUE_APP_API_ENDPOINT;
axios.defaults.headers.post['Access-Control-Allow-Origin'] =
  process.env.VUE_APP_API_ENDPOINT;
// 180s でタイムアウト
axios.defaults.timeout = 10 * 60 * 1000;

axios.interceptors.request.use(config => {
  config.headers.common['id-token'] = Vue.cookies.get('id-token');
  config.headers.common['fingerprint'] = Vue.cookies.get('fingerprint');
  return config;
});

axiosRetry(axios, { retries: 3 });

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');

// axios middleware
// @see https://emileber.github.io/axios-middleware/#/?id=simplest-use-case
const service = new Service(axios);
const source = axios.CancelToken.source();

// リクエストエラーを格納
Vue.prototype.$requestErrors = [];

service.register({
  onRequest(config) {
    return config;
  },
  onSync(promise) {
    return promise;
  },
  onResponse(response) {
    return response;
  },
  onResponseError(error) {
    // Handle browser request cancellation (as opposed to a manual cancellation)
    if (error.message == 'Request aborted') {
      return;
    }
    // 通信がタイムアウトした場合
    if (error.code === 'ECONNABORTED') {
      // 通信をキャンセルする
      source.cancel();
      Vue.prototype.$requestErrors = [...Vue.prototype.$requestErrors, error];

      // スナックバーを一度だけ表示するようにする
      if (Vue.prototype.$requestErrors.length === 1) {
        notify.error({
          text: '通信がタイムアウトしました'
        });
      }
    }

    if (!error.response) {
      return Promise.reject(error);
    }

    if (error.response.status === 555 || error.response.status === 499) {
      Vue.cookies.set('is-logined', false);
      if (location.pathname != '/') {
        router.replace({ name: 'login' });
        notify.clearSuccess();
        notify.clearSuccessWithoutCloseButton();
        notify.clearError();
        if (error.response.status === 499) {
          notify.error({ text: 'アカウントを削除されました' });
        } else if (error.response.status === 555) {
          notify.error({ text: '他の端末でログインされました' });
        }
      }
    }
    return Promise.reject(error);
  }
});
