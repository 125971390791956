const axios = require('axios').default;

const resource = '/contract-management/attachments';

type UploadParams = {
  attach_files: File[];
  contract_id: string;
  formData: Blob;
};

export default {
  /**
   * リスト取得
   *
   * @param { String } contractId
   */
  getAttachmentList(contractId) {
    return axios.get(`${resource}/contracts/${contractId}`);
  },

  /**
   * アップロード
   *
   * @param { Object } params
   */
  upload(params: UploadParams) {
    // TODO: cancel処理
    return axios.post(
      `${resource}/contracts/${params.contract_id}`,
      params.formData,
      { timeout: 1200 * 1000 }
    );
  },

  // TODO: cancel処理参考ソース
  // async upload(params) {
  //   const url = `/contract-management/attachments/contracts/${params.contractId}`;
  //   return axios
  //     .post(url, params.formData, {
  //       cancelToken: this.axiosSource.token,
  //       timeout: 600 * 1000
  //     })
  //     .catch(err => {
  //       if (axios.isCancel(err)) {
  //         console.log('Request canceled');
  //         return err;
  //       } else {
  //         console.error(err);
  //         return err;
  //       }
  //     });
  // }

  /**
   * 削除
   *
   * @param { String } attachmentId
   */
  delete(attachmentId) {
    return axios.delete(`${resource}/${attachmentId}`);
  },

  /**
   * 添付資料ダウンロードリンク取得
   * @param {String} attachmentId
   */
  getDownloadLink(attachmentId: string) {
    return axios.get(`${resource}/download_link/${attachmentId}`);
  }
};
