











import { Component, Vue } from 'vue-property-decorator';
import LoginForm from '@/components/organisms/Top/login/LoginForm.vue';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import RouterLinkText from '@/components/atoms/links/RouterLinkText.vue';

@Component({
  components: {
    LoginForm,
    FormTitle,
    RouterLinkText
  }
})
export default class Login extends Vue {}
