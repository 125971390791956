import Vue from 'vue';
import Vuex from 'vuex';
import {
  Module,
  VuexModule,
  Mutation,
  getModule,
  Action
} from 'vuex-module-decorators';
import store from '@/store';
const axios = require('axios').default;
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');
import HTTP_STATUS from '@/consts/HttpStatus';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');
const TemplateRepository = RepositoryFactory.get('templates');

Vue.use(Vuex);

/**
 * {
 *  type: 'checkbox' || 'icon' || 'text',
 *  key: 'checkbox' || 'icon' || 'name' || 'download',
 *  label: String
 * }
 */
const lirisTemplateHeadData = [
  {
    type: 'checkbox',
    key: 'checkbox',
    label: ''
  },
  {
    type: 'icon',
    key: 'icon',
    label: ''
  },
  {
    type: 'text',
    key: 'name',
    label: 'フォルダ/契約書名'
  },
  {
    type: 'text',
    key: 'download',
    label: ''
  }
];

const clientTemplateHeadData = [
  {
    type: 'checkbox',
    key: 'checkbox',
    label: ''
  },
  {
    type: 'icon',
    key: 'icon',
    label: ''
  },
  {
    type: 'text',
    key: 'name',
    label: 'フォルダ/契約書名'
  },
  {
    type: 'text',
    key: 'created_at',
    label: '登録日'
  },
  {
    type: 'text',
    key: 'user_name',
    label: '登録者'
  }
];

interface DirectoryInterface {
  directory_path: string;
  directory_name: string;
}

interface LirisTemplateInterface {
  template_id: string;
  file_name: string;
  file_extension: string;
  is_downloaded: boolean;
}

interface ClientTemplateInterface {
  template_id: string;
  file_name: string;
  file_extension: string;
  created_at: string;
  created_user_name: string;
  created_user_department: string;
}

interface LirisTemplates {
  directory_path_list: DirectoryInterface[];
  liris_templates: LirisTemplateInterface[];
}

interface ClientTemplates {
  directory_path_list: DirectoryInterface[];
  client_templates: ClientTemplateInterface[];
}

interface SelectedDirectory {
  name: string;
  path: string;
}

@Module({
  dynamic: true,
  store: store,
  name: 'template_info',
  namespaced: true
})
export class TemplateInfo extends VuexModule {
  lirisTemplates: LirisTemplates = {
    directory_path_list: [],
    liris_templates: []
  };
  clientTemplates: ClientTemplates = {
    directory_path_list: [],
    client_templates: []
  };

  selectedDirectories: SelectedDirectory[] = [];

  /** liris テンプレート */

  get lirisTemplatesTableHeadData() {
    return lirisTemplateHeadData;
  }

  /**
   * テーブル表示用のデータを成形する
   */
  get lirisTemplatesTableBodyData() {
    const folderList = this.lirisTemplatesFolderList;
    const fileList = this.lirisTemplatesFileList;

    return [...folderList, ...fileList];
  }

  /**
   * 自社テンプレートのフォルダ一覧をテーブル用に成形
   */
  get lirisTemplatesFolderList() {
    return this.lirisTemplates.directory_path_list.map(directory => {
      return lirisTemplateHeadData.map(head => {
        const bodyData = {
          type: null,
          key: null,
          value: null,
          icon_type: null,
          label: null,
          label_color: null,
          icon_with_label: null,
          link: null
        };
        if (head.key === 'checkbox') {
          (bodyData.type = 'checkbox'), (bodyData.key = 'checkbox');
        }
        if (head.key === 'icon') {
          (bodyData.type = 'icon'),
            (bodyData.key = 'icon'),
            (bodyData.icon_type = 'folder');
        }
        if (head.key === 'name') {
          (bodyData.type = 'link'),
            (bodyData.key = 'folder_name'),
            (bodyData.label = directory.directory_name),
            (bodyData.link = directory.directory_path);
        }
        if (head.key === 'download') {
          (bodyData.type = 'text'), (bodyData.key = 'download');
        }
        return bodyData;
      });
    });
  }

  get lirisTemplatesFileList() {
    return this.lirisTemplates.liris_templates.map(file => {
      return lirisTemplateHeadData.map(head => {
        const bodyData = {
          id: file.template_id,
          type: null,
          key: null,
          value: null,
          icon_type: null,
          label: null,
          label_color: null,
          icon_with_label: null,
          sub_label: null,
          link: null
        };
        // チェックボックス
        if (head.key === 'checkbox') {
          (bodyData.type = 'checkbox'), (bodyData.key = 'checkbox');
        }
        // 拡張子を表すアイコン
        if (head.key === 'icon') {
          let iconType = '';
          if (file.file_extension === 'docx') {
            iconType = 'word';
          } else if (file.file_extension === 'pdf') {
            iconType = 'pdf';
          }
          (bodyData.type = 'icon'),
            (bodyData.key = 'icon'),
            (bodyData.icon_type = iconType);
        }
        // ファイル名
        if (head.key === 'name') {
          (bodyData.type = 'link'), (bodyData.key = 'name');
          (bodyData.label = file.file_name), (bodyData.link = file.template_id);
        }
        // ダウンロード済みか
        if (head.key === 'download') {
          (bodyData.type = 'text'),
            (bodyData.key = 'download'),
            (bodyData.label = file.is_downloaded ? 'ダウンロード済み' : ''),
            (bodyData.label_color = file.is_downloaded ? 'green' : ''),
            (bodyData.icon_with_label = file.is_downloaded
              ? 'iconCircledCheck'
              : '');
        }
        return bodyData;
      });
    });
  }

  /** client テンプレート */

  get clientTemplatesTableHeadData() {
    return clientTemplateHeadData;
  }

  /**
   * テーブル表示用のデータを成形する
   */
  get clientTemplatesTableBodyData() {
    const folderList = this.clientTemplatesFolderList;
    const fileList = this.clientTemplatesFileList;

    return [...folderList, ...fileList];
  }

  /**
   * 自社テンプレートのフォルダ一覧をテーブル用に成形
   */
  get clientTemplatesFolderList() {
    return this.clientTemplates.directory_path_list.map(directory => {
      return clientTemplateHeadData.map(head => {
        const bodyData = {
          type: null,
          key: null,
          value: null,
          icon_type: null,
          label: null,
          label_color: null,
          icon_with_label: null,
          link: null
        };
        if (head.key === 'checkbox') {
          (bodyData.type = 'checkbox'), (bodyData.key = 'checkbox');
        }
        if (head.key === 'icon') {
          (bodyData.type = 'icon'),
            (bodyData.key = 'icon'),
            (bodyData.icon_type = 'folder');
        }
        if (head.key === 'name') {
          (bodyData.type = 'link'),
            (bodyData.key = 'folder_name'),
            (bodyData.label = directory.directory_name),
            (bodyData.link = directory.directory_path);
        }
        // 登録日
        if (head.key === 'created_at') {
          (bodyData.type = 'text'), (bodyData.key = 'created_at');
        }
        // 登録者
        if (head.key === 'user_name') {
          (bodyData.type = 'text'), (bodyData.key = 'user_name');
        }
        return bodyData;
      });
    });
  }

  /**
   * 自社テンプレートのファイル一覧をテーブル用に成形
   */
  get clientTemplatesFileList() {
    return this.clientTemplates.client_templates.map(file => {
      return clientTemplateHeadData.map(head => {
        const bodyData = {
          id: file.template_id,
          type: null,
          key: null,
          value: null,
          icon_type: null,
          label: null,
          label_color: null,
          icon_with_label: null,
          sub_label: null,
          link: null
        };
        // チェックボックス
        if (head.key === 'checkbox') {
          (bodyData.type = 'checkbox'), (bodyData.key = 'checkbox');
        }
        // 拡張子を表すアイコン
        if (head.key === 'icon') {
          let iconType = '';
          if (file.file_extension === 'docx') {
            iconType = 'word';
          } else if (file.file_extension === 'pdf') {
            iconType = 'pdf';
          }
          (bodyData.type = 'icon'),
            (bodyData.key = file.file_extension),
            (bodyData.icon_type = iconType);
        }
        // ファイル名
        if (head.key === 'name') {
          (bodyData.type = 'link'), (bodyData.key = 'name');
          (bodyData.label = file.file_name), (bodyData.link = file.template_id);
        }
        // 登録日
        if (head.key === 'created_at') {
          (bodyData.type = 'text'),
            (bodyData.key = 'created_at'),
            (bodyData.label = dayjs(file.created_at).format('YYYY/MM/DD'));
        }
        // 登録者
        if (head.key === 'user_name') {
          (bodyData.type = 'text'),
            (bodyData.key = 'user_name'),
            (bodyData.label = file.created_user_name),
            (bodyData.sub_label = file.created_user_department);
        }
        return bodyData;
      });
    });
  }

  /**
   * @param directory_path_list
   */
  @Mutation
  SET_LIRIS_DIRECTRIES(directory_path_list: DirectoryInterface[]) {
    this.lirisTemplates.directory_path_list = directory_path_list;
  }

  /**
   * @param templates
   */
  @Mutation
  SET_LIRIS_TEMPLATES(templates: LirisTemplateInterface[]) {
    this.lirisTemplates.liris_templates = templates;
  }

  /**
   * @param directory_path_list
   */
  @Mutation
  SET_CLIENT_DIRECTRIES(directory_path_list: DirectoryInterface[]) {
    this.clientTemplates.directory_path_list = directory_path_list;
  }

  /**
   * @param templates
   */
  @Mutation
  SET_CLIENT_TEMPLATES(templates: ClientTemplateInterface[]) {
    this.clientTemplates.client_templates = templates;
  }

  @Mutation
  SET_SELECTED_DIRECTORIES(directories: SelectedDirectory[]) {
    this.selectedDirectories = directories;
  }

  @Action({ rawError: true })
  async getLirisTemplates(current_directory = '/') {
    await DocumentRepository.getLirisTemplates({ current_directory })
      .then(response => {
        if (response.status === HTTP_STATUS.OK) {
          this.SET_LIRIS_DIRECTRIES(response.data.directory_path_list);
          this.SET_LIRIS_TEMPLATES(response.data.liris_template_list);
        }
        return response;
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action({ rawError: true })
  async getClientTemplates(current_directory = '/') {
    await DocumentRepository.getClientTemplates({ current_directory })
      .then(response => {
        if (response.status === HTTP_STATUS.OK) {
          this.SET_CLIENT_DIRECTRIES(response.data.directory_path_list);
          this.SET_CLIENT_TEMPLATES(response.data.client_template_list);
        }
        return response;
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action({ rawError: true })
  async searchClientTemplate(keyword: string) {
    await TemplateRepository.searchClientTemplate(keyword)
      .then(response => {
        if (response.status === HTTP_STATUS.OK) {
          this.SET_CLIENT_DIRECTRIES(response.data.directory_path_list);
          this.SET_CLIENT_TEMPLATES(response.data.client_template_list);
        }
        return response;
      })
      .catch(error => {
        console.error(error);
      });
  }

  @Action({ rawError: true })
  async searchLirisTemplate(keyword: string) {
    await TemplateRepository.searchLirisTemplate(keyword)
      .then(response => {
        if (response.status === HTTP_STATUS.OK) {
          this.SET_LIRIS_DIRECTRIES(response.data.directory_path_list);
          this.SET_LIRIS_TEMPLATES(response.data.liris_template_list);
        }
        return response;
      })
      .catch(error => {
        console.error(error);
      });
  }
}

const TemplateInfoModule = getModule(TemplateInfo);

export default TemplateInfoModule;
