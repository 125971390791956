import AccountRepository from '@/repositories/AccountRepository';
import AssociationRepository from '@/repositories/AssociationRepository';
import DocumentRepository from '@/repositories/DocumentRepository';
import AttachmentRepository from '@/repositories/AttachmentRepository';
import ContractInfoRepository from '@/repositories/ContractInfoRepository';
import LirisNotificationRepository from '@/repositories/LirisNotificationRepository';
import TemplateRepository from '@/repositories/TemplateRepository';
import TemplateInfoRepository from '@/repositories/TemplateInfoRepository';
import UserInfoRepository from '@/repositories/UserInfoRepository';
import SettingRepository from '@/repositories/SettingRepository';
import ObjectPermissionRepository from '@/repositories/ObjectPermissionRepository';
import GMOSignRepository from '@/repositories/GMOSignRepository';
import CollaboRepository from '@/repositories/CollaboRepository';
import SlackRepository from '@/repositories/SlackRepository';

const repositories = {
  accounts: AccountRepository,
  associations: AssociationRepository,
  documents: DocumentRepository,
  attachments: AttachmentRepository,
  contractInfos: ContractInfoRepository,
  lirisNotifications: LirisNotificationRepository,
  templates: TemplateRepository,
  templateInfos: TemplateInfoRepository,
  userInfos: UserInfoRepository,
  settings: SettingRepository,
  objectPermissions: ObjectPermissionRepository,
  gmosign: GMOSignRepository,
  collabo: CollaboRepository,
  slack: SlackRepository
};

export const RepositoryFactory = {
  get: (name: string) => repositories[name]
};
