



































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
const axios = require('axios').default;
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';

import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import DirectoriesBreadCrumb from '@/components/atoms/breadcrumb/DirectoriesBreadCrumb.vue';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

import Documents from '@/store/Documents';
import SettingsInfoModule from '@/store/SettingsInfo';

const TemplateRepository = RepositoryFactory.get('templates');

type folder = {
  directory_id: string;
  directory_name: string;
  directory_name_furigana: string;
  directory_path: string;
};

@Component({
  components: {
    AppButton,
    BaseWindow,
    BaseTable,
    ImageContent,
    DirectoriesBreadCrumb
  }
})
export default class MoveTemplateModal extends Vue {
  folders: folder[] = [];
  directories_name: object[] = [];
  selected_path: string = '';
  current: string = '';
  selectedDirectoryCount: number = null;

  @Prop({ required: true, type: String })
  firstCurrent: string;

  @Prop({ required: true, type: Boolean })
  isClientTemplate: boolean;

  @Prop({ required: true, type: String })
  firstBreadClumbName: string;

  @Prop({ required: true, type: Boolean })
  isOpen: boolean;

  @Prop({ required: true, type: Array })
  targetFolderNames: any;

  @Emit()
  cancel() {
    return;
  }

  @Emit()
  selected() {
    return this.selected_path;
  }

  @Watch('selected_path', { immediate: true })
  changed_directories_name() {
    this.set_directories_name(this.selected_path);
  }

  async mounted() {
    this.current = this.firstCurrent;
    this.selected_path = this.firstCurrent;
    await this.get_folders(this.current);
    this.set_directories_name(this.current);
  }

  change_path(path: string) {
    if (this.checkIncludeSelf(path)) return;
    this.get_folders(path);
    this.current = path;
    this.selected_path = path;
    this.set_directories_name(path);
  }

  set_directories_name(path: string) {
    this.directories_name = [];
    this.directories_name.push({
      id: 1,
      name: this.firstBreadClumbName,
      path: '/'
    });
    const del_prefix = path.slice(1);
    let p = '';
    if (del_prefix.length) {
      const splited_path = del_prefix.split('/');
      for (const i in splited_path) {
        p += '/' + splited_path[i];
        const tmp = {
          id: i + 2,
          name: splited_path[i],
          path: p
        };
        this.directories_name.push(tmp);
      }
    }
  }

  async select_folder(path) {
    if (this.checkIncludeSelf(path)) return;

    if (this.firstCurrent !== path) {
      this.selected_path = path;
    }
  }

  checkIncludeSelf(path) {
    return this.selectedFolderPathList.some(x => x === path);
  }

  get sort(): {
    columnName: string;
    asc: boolean;
  } {
    return SettingsInfoModule.sort[this.$route.name];
  }

  async get_folders(path: string) {
    if (!this.isClientTemplate) return;
    if (this.checkIncludeSelf(path)) return;

    await TemplateRepository.getFolders(path)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        this.folders = res.data['directory_path_list'];

        // nameでsortしている時のみTemplateページのsortの設定を反映させる
        if (this.sort.columnName === 'name') {
          this.folders.sort((a: folder, b: folder) => {
            if (a.directory_name < b.directory_name)
              return this.sort.asc ? -1 : 1;
            if (a.directory_name > b.directory_name)
              return this.sort.asc ? 1 : -1;
            return 1;
          });
        }

        this.current = path;
        this.set_directories_name(path);
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  get moveButtonDisabled(): boolean {
    return this.firstCurrent === this.selected_path;
  }

  get selectedFolderPathList() {
    return Documents.selected_folders_of_table.map(x => x.directory_path);
  }
}
