





















































import {
  Component,
  Vue,
  Prop,
  Watch,
  Emit,
  PropSync
} from 'vue-property-decorator';
import vLoading from 'vue-loading-overlay';
import TrashBodyHeader from '@/components/organisms/Trash/TrashBodyHeader.vue';
import TrashTable from '@/components/organisms/Trash/TrashTable.vue';
import TableSelectTab from '@/components/molecules/Table/TableSelectTab.vue';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';
import CorporateInfo from '@/store/CorporateInfo';
import Documents from '@/store/Documents';
import LAYOUT_UTIL from '@/consts/LayoutUtil';
import TAB_MENUS from '@/consts/TabMenus';

const tabMenuNames = {
  contracted_document: '締結済ドキュメント',
  contract_management: '締結管理'
};
const tabMenuNamesSmallPlan = {
  contracted_document: '締結済ドキュメント'
};

interface DropFile {
  size: number;
  name: string;
  type: string;
  file: File;
}

interface UploadArgs {
  fileList: DropFile[];
  linkAsRelatedContract: boolean;
  attachFileList: File[];
}

@Component({
  components: {
    vLoading,
    TrashBodyHeader,
    TrashTable,
    TableSelectTab
  }
})
export default class TrashBody extends Vue {
  @Prop()
  files_length: number;
  @Prop()
  folders_length: number;
  @Prop()
  breadcrumbData: any;
  @Prop()
  canOpenDeleteWindow: boolean;
  @PropSync('isContractedDocumentTabSelectedSync', {
    type: Boolean,
    default: true
  })
  isContractedDocumentTabSelected: boolean;

  dragover: boolean = false;
  recently_viewed_files: any = Documents.recently_viewed_files;
  num_recently_viewed_files: number = 0;
  objects: any = [];
  // ファイルアップロード中の場合のみtrue。あとでアップロード機能つけるときに書き換える。
  file_upload: boolean = false;
  uploading_files_num: number = 0;
  existSameNameFileOrFolder: boolean = false;
  linkAsRelatedContract: boolean = false;
  makeVersionFinal: boolean = false;
  showUploadDialog: boolean = false;
  body_height: number = 1280;
  body_width: number = 1024;

  fileList: DropFile[] = [];

  is_loaded: boolean = false;

  selectedTab: string = TAB_MENUS.CONTRACTED_DOCUMENT;

  @Emit()
  changeTab() {
    this.isContractedDocumentTabSelected = Boolean(
      this.selectedTab === TAB_MENUS.CONTRACTED_DOCUMENT
    );
    return;
  }

  mounted() {
    this.is_loaded = true;
  }

  private get sideBarStatus(): boolean {
    return HomeComponentInfoModule.side_bar_open;
  }

  get trashes() {
    if (!this.is_loaded) {
      this.is_loaded = true;
    }

    return Documents.trashes;
  }

  /**
   * スモールプランかどうか確認
   */
  get isSmallPlan() {
    return CorporateInfo.isSmallPlan;
  }

  /**
   * タブのキー取得
   */
  get TAB_MENUS() {
    return TAB_MENUS;
  }

  /**
   * タブの種類の名称取得
   */
  get tabMenus() {
    if (this.isSmallPlan) {
      return tabMenuNamesSmallPlan;
    }
    return tabMenuNames;
  }

  @Watch('sideBarStatus')
  window_width() {
    let w = window.innerWidth;
    if (this.sideBarStatus) {
      w = w - LAYOUT_UTIL.SIDE_MENU.WIDTH.OPEN;
    } else {
      w = w - LAYOUT_UTIL.SIDE_MENU.WIDTH.CLOSE;
    }
    return {
      width: String(w) + 'px'
    };
  }

  @Watch('sideBarStatus')
  dropzone_area() {
    this.body_height = window.innerHeight - LAYOUT_UTIL.HEADER.HEIGHT;
    if (this.sideBarStatus) {
      this.body_width = window.innerWidth - LAYOUT_UTIL.SIDE_MENU.WIDTH.OPEN;
    } else {
      this.body_width = window.innerWidth - LAYOUT_UTIL.SIDE_MENU.WIDTH.CLOSE;
    }
    return {
      height: String(this.body_height) + 'px',
      width: String(this.body_width) + 'px'
    };
  }

  /**
   * フォルダ名をクリックしたときの処理
   * @param {Object} params
   */
  async moveDirectory(params) {
    this.$emit('moveDirectory', params);
  }

  /**
   * タブを切り替える
   */
  async selectTab(value) {
    if (this.selectedTab === value) {
      return;
    }

    this.selectedTab = value;
    this.changeTab();
  }
}
