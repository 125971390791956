const axios = require('axios').default;

const resource = '/user-management';

type CheckPasswordParams = {
  email: string;
  password: string;
};

type UpdatePasswordParams = {
  password: string;
  new_password: string;
};

export default {
  /**
   * パスワードチェック
   *
   * @param { Object } params
   */
  checkPassword(params: CheckPasswordParams) {
    return axios.post(`${resource}/users/check/password`, params);
  },

  /**
   * パスワード更新
   *
   * @param { Object } params
   */
  updatePassword(params: UpdatePasswordParams) {
    return axios
      .put(`${resource}/users/change/password`, params)
      .then(response => {
        if (response.status == 200) {
          return Promise.resolve(response.data);
        }
        return Promise.reject(response);
      });
  },

  /**
   * 郵便番号から住所を取得
   *
   * @param zipcode
   */
  getAddressFromZipcode(zipcode) {
    return axios.get(
      `${resource}/corporations/address/from/zipcode?zipcode=${zipcode}`
    );
  },

  /**
   * メールアドレス変更用の認証コードメール送信
   *
   * @param { String } email
   */
  sendChangeEmailVerifyEmail(email) {
    return axios.post(`${resource}/users/send/change/verify-email`, { email });
  },

  /**
   * メールアドレス更新
   *
   * @param { String } verificationCode
   */
  updateEmail(verificationCode) {
    return axios.put(`${resource}/users/change/email`, {
      verification_code: verificationCode
    });
  },

  /**
   * id-token更新
   */
  refreshIdToken() {
    return axios.get(`${resource}/auth/token/refresh`);
  }
};
