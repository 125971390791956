































import { Component, Vue, Prop } from 'vue-property-decorator';
import ClickOutside from 'vue-click-outside';

@Component({
  components: {},
  directives: {
    ClickOutside
  }
})
export default class DirectoriesBreadCrumb extends Vue {
  is_display_omitted_folders: boolean = false;

  @Prop()
  directories: any[];

  open_omit_path() {
    this.is_display_omitted_folders = true;
  }

  close_omit_path() {
    this.is_display_omitted_folders = false;
  }

  change_path(path) {
    if (!path) {
      return;
    }
    this.$emit('change', path);
    this.is_display_omitted_folders = false;
  }
}
