

























































import { Component, Vue, Watch } from 'vue-property-decorator';
import ImageContent from '@/components/atoms/images/ImageContent.vue';

import Documents from '@/store/Documents';
// import Associations from '@/store/Associations';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');

const STATUS = {
  WAIT: 0,
  UPLOADING: 1,
  FINISHED: 2
};

@Component({
  components: {
    ImageContent
  }
})
export default class SignedFileUploader extends Vue {
  isMin: boolean = false;
  selectedJobId: string = '';

  arraySetIntervalId = [];

  get uploadJobs() {
    return Documents.uploadJobs;
  }

  get uploadJobGroupIds() {
    return Documents.uploadJobGroupIds;
  }

  get isRunningUploadJob() {
    return Documents.isRunningUploadJob;
  }

  get allJobCount() {
    return this.uploadJobs.length;
  }

  get finishedJobCount() {
    const finishedUploadCount = this.uploadJobs.filter(
      x => x.upload_status === STATUS.FINISHED
    );
    return finishedUploadCount.length;
  }

  get uploadJobStatusClass() {
    return status => {
      if (status === STATUS.WAIT) return 'wait';
      if (status === STATUS.UPLOADING) return 'uploading';
      return 'finished';
    };
  }

  get uploadJobStatus() {
    return status => {
      if (status === STATUS.WAIT) return '待機中';
      if (status === STATUS.UPLOADING) return '実行中•••';
      return '完了';
    };
  }

  get isFinishedAllJob() {
    if (!this.uploadJobs.length) return false;
    return this.uploadJobs.every(x => x.upload_status === STATUS.FINISHED);
  }

  @Watch('uploadJobGroupIds')
  addUploadJobGroupIds() {
    if (this.isRunningUploadJob) return;
    this.fetchUploadJobStatus();
  }

  created() {
    this.fetchUploadJobStatus();
  }

  async fetchUploadJobStatus() {
    if (this.isFinishedAllJob) return;
    Documents.SET_IS_RUNNING_UPLOAD_JOB(true);
    const id = setInterval(async () => {
      if (!this.uploadJobGroupIds.length) return;

      // アップロードのたびに異なるgroup_idが発行されるため、
      // group_id別にアップロードジョブを取得する。
      // 同じgroup_idのジョブのステータスが全て完了になると、
      // 次回アップロード時にそのgroup_idのジョブレコードは削除される
      await Promise.all(
        this.uploadJobGroupIds.map(
          async groupId =>
            await DocumentRepository.getSignedContractUploadJobs(groupId)
        )
      ).then(async values => {
        let tasks = [];
        await values.forEach((x: any) => {
          tasks = [...tasks, ...x.data];
        });

        // APIから取得した完了ジョブの一覧
        const finishedJobIdsToTask = tasks
          .map(x => {
            if (x.upload_status === STATUS.FINISHED) return x.job_id;
          })
          .filter(x => x);

        // Storeに保持されている完了ジョブの一覧
        const finishedJobIdsToStore = this.uploadJobs
          .map(x => {
            if (x.upload_status === STATUS.FINISHED) return x.job_id;
          })
          .filter(x => x);

        const diffFinishedJobIds = finishedJobIdsToTask.filter(
          i => finishedJobIdsToStore.indexOf(i) === -1
        );

        Documents.SET_UPLOAD_JOBS(tasks);

        // アップロード中のジョブの全てのステータスが完了になればポーリングを停止する。
        if (tasks.every(task => task.upload_status === STATUS.FINISHED)) {
          clearInterval(this.arraySetIntervalId.shift());
          Documents.update_document_info();
          Documents.SET_IS_RUNNING_UPLOAD_JOB(false);
        }
      });
    }, 7000);
    this.arraySetIntervalId.push(id);
  }

  close() {
    Documents.SET_UPLOAD_JOBS([]);
    Documents.SET_UPLOAD_JOB_GROUP_IDS([]);
    Documents.SET_IS_RUNNING_UPLOAD_JOB(false);
  }

  beforeDestroy() {
    this.close();
    clearInterval(this.arraySetIntervalId.shift());
  }
}
