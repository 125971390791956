























import { Component, Vue, Watch } from 'vue-property-decorator';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import PasswordCheckItem from '@/components/molecules/icon_text/PasswordCheckItem.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import UserInfoModule from '../../../store/UserInfo';
import notify from '@/functions/notify';
import KEY_CODE from '@/consts/KeyCode';
import { validateEmail } from '@/functions/Validation';

const axios = require('axios').default;

@Component({
  components: {
    FormTitle,
    TextForm,
    PasswordCheckItem,
    AppButton
  }
})
export default class ForgotPassword extends Vue {
  inputed_email: string = '';
  button_disabled: boolean = true;
  email_error_message: string = '';

  mounted() {
    this.inputed_email = this.email;
  }

  get email() {
    return UserInfoModule.email;
  }

  get isEmail() {
    if (!this.inputed_email) {
      return false;
    }
    return true;
  }

  @Watch('inputed_email')
  change_email() {
    UserInfoModule.SET_EMAIL(this.inputed_email);
  }

  async request_password_reset() {
    this.email_error_message = validateEmail(this.inputed_email);
    if (this.email_error_message) {
      return;
    }

    const url = `user-management/auth/challenges/onetime/password`;

    await axios
      .get(`${url}`, {
        params: {
          email: this.email
        }
      })
      .then(response => {
        this.$router.push({ name: 'send_onetime_password' });
      })
      .catch(err => {
        notify.error({
          text: 'メールの送信に失敗しました'
        });
      });
  }

  async trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER) {
      await this.request_password_reset();
    }
  }
}
