


















































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import ContractInfo from '@/store/ContractInfo';

@Component({
  components: {
    BaseWindow,
    AppButton,
    TextForm
  }
})
export default class AddKanbanColumnWindow extends Vue {
  @Prop(Boolean)
  value: boolean;

  @Prop()
  windowSize!: string;

  @Prop({ default: false })
  isWhiteSpace?: boolean;

  @Prop()
  item!: any;

  formData = {
    column_id: null,
    column_name: '',
    column_order: 0,
    needed_signed_file: false
  };
  formDirty = false;
  errorMessages = {
    column_name: '',
    needed_signed_file: ''
  };

  get validation(): boolean {
    return (
      this.formDirty &&
      !this.errorMessages.column_name &&
      !this.errorMessages.needed_signed_file
    );
  }

  get isUpdate() {
    return this.item && !!this.item.column_id;
  }

  get neededSignedFileColumn() {
    return ContractInfo.neededSignedFileColumn;
  }

  @Watch('value')
  windowStatus(b) {
    this.reset();

    if (b) {
      this.formData.column_id = this.item.column_id || null;
      this.formData.column_name = this.item.column_name || '';
      this.formData.column_order = this.item.column_order || 0;
      this.formData.needed_signed_file =
        this.item.is_needed_signed_file || false;
    }
  }

  reset() {
    this.formData.column_name = '';
    this.formData.needed_signed_file = false;
    this.formDirty = false;
    this.errorMessages.column_name = '';
    this.errorMessages.needed_signed_file = '';
  }

  onChange() {
    this.formDirty = true;

    if (!this.formData.column_name) {
      this.errorMessages.column_name = 'ステータス名を入力してください';
    } else {
      this.errorMessages.column_name = '';
    }
    if (
      !this.formData.needed_signed_file &&
      this.item.column_id === this.neededSignedFileColumn.column_id
    ) {
      this.errorMessages.needed_signed_file =
        '自動抽出実行ステータスを未設定にすることはできません';
    } else {
      this.errorMessages.needed_signed_file = '';
    }
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }
}
