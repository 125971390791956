









































































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';

import AppButton from '@/components/atoms/buttons/AppButton.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import InfoAssigneeItem from '@/components/organisms/DocumentDetail/InfoAssigneeItem.vue';

import { GMOSignMember } from '@/interfaces/GMOSign';

@Component({
  components: {
    AppButton,
    TextForm,
    SelectBox,
    BaseWindow,
    InfoAssigneeItem
  }
})
export default class AddUsersWithGMOSignWindow extends Vue {
  @Prop()
  gmosignUsers: GMOSignMember[];

  @Prop()
  windowSize!: string;

  @Prop({ default: false })
  isWhiteSpace?: boolean;

  @Prop()
  isLoading: boolean;

  @Prop()
  usersCorporationsSameMe: any;

  @Prop()
  selectedUsersCorporationsSameMe: any;

  @Prop(Array)
  departments: any[];

  @Prop(Array)
  authorities: any[];

  @Prop(Boolean)
  value: boolean;

  selectedUserList: any = [];
  items: any = ['なし', 'あり'];

  validateAll() {
    return true;
  }

  get actionMark() {
    return '';
  }

  @Emit('inputGMOSignUserName')
  inputGMOSignUserName(index, gmosign_user_name) {
    return {
      index,
      gmosign_user_name
    };
  }

  @Emit('inputUser')
  inputUser(index, user) {
    return {
      index,
      user
    };
  }

  @Emit('inputIsSignRole')
  inputIsSignRole(index, event) {
    return {
      index: index,
      is_gmosign_sign_role: event.target.value
    };
  }

  @Emit('removeUser')
  removeUser(index) {
    return index;
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }
}
