



































































import { Component, Vue, Watch } from 'vue-property-decorator';
import AppNotifications from '@/components/atoms/AppNotifications.vue';
import HomeHeader from '@/components/organisms/Home/HomeHeader.vue';
import HomeSideMenuBar from '@/components/organisms/Home/HomeSideMenuBar.vue';
import HomeBody from '@/components/organisms/Home/HomeBody.vue';
import SingleInputFormWindow from '@/components/molecules/modal_windows/SingleInputFormWindow.vue';
import DocumentMoveWindow from '@/components/molecules/modal_windows/DocumentMoveWindow.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import HomeComponentInfoModule from '@/store/HomeComponentInfo';
import SettingsInfoModule from '@/store/SettingsInfo';
import SearchInfo, { SearchQuery } from '@/store/SearchInfo';
import UserInfoModule from '@/store/UserInfo';
import Documents from '@/store/Documents';
import CorporateInfo from '@/store/CorporateInfo';
import ContractManagementModule from '@/store/ContractInfo';

import vLoading from 'vue-loading-overlay';

const axios = require('axios').default;
import ViewPermissionCard from '@/components/molecules/ViewPermissionCard.vue';

import MoveObject from '@/functions/MoveObject';
import notify from '@/functions/notify';

import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import WINDOW_MESSAGES from '@/consts/TextWindowMessage';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');
const ObjectPermissionRepository = RepositoryFactory.get('objectPermissions');

@Component({
  components: {
    AppNotifications,
    HomeHeader,
    HomeBody,
    HomeSideMenuBar,
    SingleInputFormWindow,
    DocumentMoveWindow,
    TextOnlyWindow,
    ViewPermissionCard,
    vLoading
  }
})
export default class Home extends Vue {
  showPermissionModal: boolean = false;
  folders: any = [];
  isLoading: boolean = false;
  folderNameErrorMessage: string = '';
  permissionSaving: boolean = false;
  folderMoving: boolean = false;
  isOpenConfirmObjectMove: boolean = false;
  refreshIntervalId = null;

  @Watch('$route')
  async changeQuery(to, from) {
    await SearchInfo.set_query((this.$route.query as object) as SearchQuery);

    if (to.path.includes('searching')) {
      await this.search();
    }
  }

  async created() {
    await SearchInfo.set_query((this.$route.query as object) as SearchQuery);

    if (SearchInfo.is_searching_now || this.$route.path.includes('searching')) {
      await this.search();
      return;
    }

    this.isLoading = true;
    if (!Documents.is_back_page) {
      const path =
        this.$route.params.id
          .replace(`${CorporateInfo.corporation_id}`, '')
          .replace('contracted_document', '') || '/';
      await Documents.SET_CURRENT_DIRECTORY(path);
    }

    await UserInfoModule.get_user_info();
    await this.load();
    this.isLoading = false;
    SearchInfo.SET_SEARCHING(false);

    await Promise.all([
      CorporateInfo.get_service_plan(),
      Documents.getNotifications(),
      Documents.getLirisNotifications(),
      Documents.getRootDirectory(),
      Documents.doCompleteDownloadJobs(),
      SettingsInfoModule.get_setting_alert(),
      ContractManagementModule.getContractBoardData()
    ])
      .then(values => {
        if (values.find(x => x.status !== HTTP_STATUS.OK)) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
  }

  mounted() {
    if (Documents.is_delete_card) {
      notify.success({
        text: NOTIFY_TEXT.SUCCESS.DELETE,
        data: {
          cancelMethod: this.cancelDeleteFileOrFolder
        }
      });
      Documents.SET_IS_DELETE_CARD(false);
    }
    this.refreshIntervalId = setInterval(() => {
      UserInfoModule.refreshIdToken();
    }, 1000 * 60 * 5);
  }

  /**
   * 初期表示に必要なデータのみ読み込む
   */
  async load() {
    Documents.RESET_SELECTED_TABLE();

    await Promise.all([Documents.getFilesAndFolders()])
      .then(values => {
        if (values.find(x => x.status !== HTTP_STATUS.OK)) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });
  }

  destroyed() {
    Documents.SET_IS_BACK_PAGE(false);
    clearInterval(this.refreshIntervalId);
  }

  get currentDirectory() {
    return Documents.current_directory;
  }

  get sideBarStatus(): boolean {
    return HomeComponentInfoModule.side_bar_open;
  }

  get moveSelectedObjectsWindow(): boolean {
    if (HomeComponentInfoModule.move_selected_objects_window) {
      this.get_folders(this.currentDirectory);
    } else {
      this.folders = [];
    }
    return HomeComponentInfoModule.move_selected_objects_window;
  }

  toggleSideBar() {
    HomeComponentInfoModule.change_side_bar();
  }

  get selectedFileContractIdList() {
    const selected_file_contract_id_list = Documents.selected_files_of_table.map(
      function(data) {
        return data.contract_id;
      }
    );
    return selected_file_contract_id_list;
  }

  get selectedFolderPathList() {
    const selected_folder_path_list = Documents.selected_folders_of_table.map(
      function(data) {
        return data.directory_path;
      }
    );
    return selected_folder_path_list;
  }

  get selectedFolderIdList() {
    const selected_folder_id_list = Documents.selected_folders_of_table.map(
      function(data) {
        return data.directory_id;
      }
    );
    return selected_folder_id_list;
  }

  get selectedSingleFileContractId() {
    if (
      this.selectedFileContractIdList.length === 1 &&
      this.selectedFolderPathList.length === 0
    ) {
      return this.selectedFileContractIdList[0];
    }
    return null;
  }

  get selectedSingleDirectoryId() {
    if (
      this.selectedFolderIdList.length === 1 &&
      this.selectedFileContractIdList.length === 0
    ) {
      return this.selectedFolderIdList[0];
    }
    return null;
  }

  async cancelDeleteFileOrFolder(): Promise<void> {
    // 表示されているスナックバーを消す
    notify.clearSuccess();
    this.isLoading = true;
    const responses = await Documents.restoreDeletedFileOrFolder();
    this.isLoading = false;

    // ファイルもしくはフォルダの削除取り消しでエラーがあれば notify を表示し処理を終える。
    const failed: boolean = responses.some(
      res => !res || res.status !== HTTP_STATUS.NO_CONTENT
    );
    if (failed) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.CANCEL
      });
      return;
    }

    this.isLoading = true;
    // 締結済ドキュメント
    if (this.$route.name === 'home') {
      await Documents.update_document_info();
    }
    Documents.RESET_SELECTED_TABLE();
    this.isLoading = false;
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CANCEL
    });
  }

  /**
   * 閲覧権限設定
   */
  async saveViewPermission(data) {
    this.permissionSaving = true;
    const selected_file_contract_id_list = this.selectedFileContractIdList;

    const user_id_list = [];
    const department_id_list = [];
    if (data.selected_users) {
      for (const i in data.selected_users) {
        user_id_list.push(data.selected_users[i].user_id);
      }
    }
    if (data.selected_departments) {
      for (const i in data.selected_departments) {
        department_id_list.push(data.selected_departments[i].department_id);
      }
    }

    const request = {
      user_id_list: user_id_list,
      department_id_list: department_id_list,
      contract_id_list: selected_file_contract_id_list,
      directory_path_list: this.selectedFolderPathList,
      directory_id_list: this.selectedFolderIdList
    };

    await DocumentRepository.saveViewPermission(request)
      .then(res => {
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.SETTING_VIEW_PERMISSION
        });
        // 閲覧権限設定できたらテーブルのリセット
        Documents.RESET_SELECTED_TABLE();
        Documents.getFilesAndFolders();
        // v-ifでカードを表示してカード非表示時にカード内の値を初期化
        this.showPermissionModal = false;
      })
      .catch(err => {
        if (err.response.status === HTTP_STATUS.BAD_REQUEST) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.EMPTY
          });
        } else {
          notify.error({
            text: NOTIFY_TEXT.ERROR.SETTING_VIEW_PERMISSION.FAILED
          });
        }
      });

    this.permissionSaving = false;
  }

  /**
   * $refsのTypescript Error回避
   * https://qiita.com/tsumasakky/items/03a4bdf74e3c765c2077
   */
  get refs(): any {
    return this.$refs;
  }

  get windowMessageConfirmMove() {
    return WINDOW_MESSAGES.CONFIRM_MOVE;
  }

  /**
   * 移動ボタン押下時
   */
  async moveObject(): Promise<void> {
    this.isOpenConfirmObjectMove = false;
    this.folderMoving = true;
    await MoveObject.moveFileOrFolder();
    notify.success({
      text: '移動が完了しました'
    });
    this.folderMoving = false;
  }

  /**
   * 移動ボタン押下時
   */
  async checkViewPermissionWhenMoveObject() {
    this.isOpenConfirmObjectMove = true;
  }

  selectedPath(path) {
    HomeComponentInfoModule.SET_MOVE_TO_PATH(path);
  }

  changePath(path) {
    this.folders = [];
    this.get_folders(path);
  }

  async get_folders(path: string) {
    await DocumentRepository.getDirectoryList(path)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        this.folders = res.data;
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  cancelMove() {
    HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW(false);
  }

  changeKeyword(val) {
    SearchInfo.SET_FREE_KEYWORD(val);
  }

  async search() {
    SearchInfo.SET_IS_SEARCHING_NOW(true);
    SearchInfo.SET_OPEN_CONTRACT_SEARCH_DETAIL(false);

    this.isLoading = true;
    const query = SearchInfo.search_post_data;
    await Documents.search(query).catch(err => {
      notify.error({
        text: NOTIFY_TEXT.ERROR.SEARCH
      });
    });
    this.isLoading = false;
    SearchInfo.SET_IS_SEARCHING_NOW(false);
    SearchInfo.SET_SEARCHING(true);
  }

  async clearKeyword() {
    this.$router
      .push({
        path: '/home/contracted_document',
        query: {}
      })
      .catch(err => {});
  }

  onLogo() {
    this.clearKeyword();
  }
}
