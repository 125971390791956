

























































































































































import { Component, Vue, Prop } from 'vue-property-decorator';

import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import DocumentPreview from '@/components/organisms/GMOSign/DocumentPreview.vue';
import SearchBoxFormRadioGroup from '@/components/molecules/search_box/SearchBoxFormRadioGroup.vue';

import GMOSignInfoModule from '@/store/GMOSign';

import { GMOSignMember } from '@/interfaces/GMOSign';

@Component({
  components: {
    SelectBox,
    AppButton,
    DocumentPreview,
    SearchBoxFormRadioGroup
  }
})
export default class GMOSignFormSigner extends Vue {
  contractId = null;
  sign_user: GMOSignMember = {};
  checkOurSign: boolean = false;
  isValidateOk: boolean = false;
  validateCount: number = 0;

  @Prop({ default: '' })
  pdfLink!: string;

  async created() {
    this.contractId = this.$route.query.id;
    await GMOSignInfoModule.getGMOSignMembers();
    if (this.our_signing_field.length > 0) {
      this.checkOurSign = true;
    }
  }

  changeCheckOurSign() {
    if (this.checkOurSign === true) {
      this.our_signing_field = [
        {
          sign_user: null,
          signer_nm: '',
          request_mail: '1',
          is_compilation_mail: '1',
          comment: '',
          signing_point_start_x: null,
          signing_point_start_y: null,
          signing_point_end_x: null,
          signing_point_end_y: null,
          page_no: null,
          order_no: 1
        }
      ];
      return;
    }
    this.our_signing_field = [];
  }

  get documentTypeItems() {
    return GMOSignInfoModule.documentTypeItems;
  }

  get document_type_obj() {
    return GMOSignInfoModule.signForm.document_type_obj;
  }
  set document_type_obj(val) {
    GMOSignInfoModule.SET_DOCUMENT_TYPE_OBJ(val);
  }

  get our_signing_field() {
    return GMOSignInfoModule.signForm.our_signing_field;
  }
  set our_signing_field(val) {
    GMOSignInfoModule.SET_OUR_SIGNING_FIELD(val);
  }

  get partner_signing_field() {
    return GMOSignInfoModule.signForm.partner_signing_field;
  }
  set partner_signing_field(val) {
    GMOSignInfoModule.SET_PARTNER_SIGNING_FIELD(val);
  }

  get assignees() {
    return GMOSignInfoModule.current_gmosign_users;
  }

  setSignerNm(val) {
    this.our_signing_field[0].signer_nm = val.gmosign_user_name;
    this.our_signing_field[0].sign_user = val;
  }

  setDocumentType(val) {
    GMOSignInfoModule.SET_DOCUMENT_TYPE(val.value);
  }

  setPartnerDocumentType(event, index) {
    this.partner_signing_field[index].document_type = event.value;
  }

  addPartner() {
    const order = this.partner_signing_field.length + 2;
    const partner = {
      organize_nm: '',
      name_nm: '',
      email: '',
      is_signer_change: false,
      access_code: '',
      comment: '',
      signing_point_start_x: null,
      signing_point_start_y: null,
      signing_point_end_x: null,
      signing_point_end_y: null,
      page_no: null,
      order_no: order,
      document_type: '3',
      document_type_obj: this.documentTypeItems[0],
      is_send_mail: '1',
      is_compilation_mail: '1'
    };
    this.partner_signing_field.push(partner);
  }

  deletePartner(index) {
    this.partner_signing_field.splice(index, 1);
  }

  next() {
    this.validateCount += 1;
    if (!this.validateAll()) {
      return;
    }
    this.$router.push({
      name: 'gmosign_form_sign_order',
      query: {
        id: this.contractId
      }
    });
  }

  validateAll() {
    if (
      this.our_signing_field.length > 0 &&
      !this.our_signing_field[0].sign_user
    ) {
      return false;
    }
    // 相手先バリデーション
    for (const i in this.partner_signing_field) {
      if (!this.partner_signing_field[i].name_nm) {
        return false;
      }
      if (!this.partner_signing_field[i].email) {
        return false;
      }
      if (
        this.validateAccessCodeMaching(
          this.partner_signing_field[i].access_code
        )
      ) {
        return false;
      }
    }
    return true;
  }

  validateAccessCodeMaching(val: string) {
    if (!val) {
      return '';
    }
    const regex = new RegExp(/^[a-zA-Z0-9#$%&=*+<>?"!'()~|\-_^`{@[:;,./\\]+$/);
    if (!regex.test(val)) {
      return '半角英数記号のみ利用可能（¥は不可）';
    }
    return '';
  }
}
