




































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

import ImageContent from '@/components/atoms/images/ImageContent.vue';
import IconSvg from '@/components/atoms/icons/IconSvg.vue';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import ContractLabels from '@/components/molecules/labels/ContractLabels.vue';
import Documents, { ContractInfoBase } from '@/store/Documents';
import HomeComponentInfo from '@/store/HomeComponentInfo';
import SettingsInfoModule, { AlertLimitType } from '@/store/SettingsInfo';
import UserInfo from '@/store/UserInfo';
import AUTHORITIES from '@/consts/Authorities.js';
import TAB_MENUS from '@/consts/TabMenus';
import { sortBase, uniqueSort } from '@/functions/Sort';
import { dateFormatter } from '@/functions/FormatDate';

@Component({
  components: {
    ImageContent,
    IconSvg,
    BaseTable,
    ContractLabels
  }
})
export default class PartedFileTable extends Vue {
  select_all: boolean = false;
  selected: ContractInfoBase[] = [];

  @Prop()
  file_items!: {
    alert_setting: {
      auto_update_before_alert_day: number;
      expiration_before_alert_day: number;
    };
    one_month_ago: ContractInfoBase[];
    one_month_before: ContractInfoBase[];
    one_week_ago: ContractInfoBase[];
    today: ContractInfoBase[];
    yesterday: ContractInfoBase[];
  };
  @Prop()
  isContractedDocumentTabSelected: boolean;

  @Prop()
  selectedTab: string;

  get sort(): {
    columnName: string;
    asc: boolean;
  } {
    return SettingsInfoModule.sort[this.$route.name];
  }

  get isGuest() {
    return UserInfo.authority === AUTHORITIES.GUEST;
  }

  get getItemColor() {
    return file => {
      for (const i in this.selected) {
        if (file.contract_id === this.selected[i].contract_id) {
          return 'item-selected';
        }
      }

      switch (this.alertStatus(file).type) {
        case AlertLimitType.DoUpdateOrScheduledEnd:
        case AlertLimitType.DoScheduledEnd:
          return 'item-alert';
        case AlertLimitType.End:
          return 'item-end';
      }
      return 'item-normal';
    };
  }

  get changeTableEvent() {
    return HomeComponentInfo.change_tables;
  }

  get AlertLimitType() {
    return AlertLimitType;
  }

  get alertStatus() {
    return obj => {
      return SettingsInfoModule.alertStatus(
        obj.expiration,
        obj.auto_update_date,
        obj.is_scheduled_end,
        obj.is_ended
      );
    };
  }

  get dateRowOrder() {
    return [
      { key: 'today', name: '今日' },
      { key: 'yesterday', name: '昨日' },
      { key: 'one_week_ago', name: '1週間' },
      { key: 'one_month_ago', name: '1ヶ月' },
      { key: 'one_month_before', name: '1ヶ月以上前' },
      { key: '', name: '' }
    ];
    // ↑最後にダミーを入れないと1ヶ月以上前が表示されないので空の連想配列を入れている
  }

  get TAB_MENUS() {
    return TAB_MENUS;
  }

  @Watch('changeTableEvent')
  resetSelected() {
    this.selected = [];
    Documents.RESET_SELECTED_TABLE();
  }

  @Watch('isContractedDocumentTabSelected')
  clearAllCheck() {
    this.selected = [];
  }

  @Watch('file_items', { deep: true })
  initializeViews(newVal, oldval) {
    this.selected = [];
    if (newVal !== oldval) this.onSort();
  }

  @Watch('selected')
  changeSelectAll(val: string[], oldval: string[]) {
    const num_selected = val.length;
    const num_item = Object.keys(this.file_items).reduce((acc, key) => {
      if (!this.file_items[key].length) {
        return acc;
      }
      return +this.file_items[key].length + acc;
    }, 0);

    if (!num_selected && !num_item) {
      this.select_all = false;
      return;
    }
    if (num_selected !== num_item) {
      this.select_all = false;
      return;
    }
    this.select_all = true;
  }

  @Watch('selected')
  setStore() {
    Documents.SET_SELECTED_FILES_OF_TABLE(this.selected);
  }

  @Watch('selectedTab')
  changeTab() {
    SettingsInfoModule.SET_SORT({ pageName: this.$route.name, columnName: '' });
    SettingsInfoModule.SET_SORT({
      pageName: this.$route.name,
      columnName: 'counterparty_name_furigana'
    });
    this.$nextTick(() => {
      this.onSort();
    });
  }

  beforeCreate() {
    // デフォルトは「相手方」で「昇順」
    if (!SettingsInfoModule.sort[this.$route.name]) {
      SettingsInfoModule.SET_SORT({
        pageName: this.$route.name,
        columnName: 'counterparty_name_furigana'
      });
    }
  }

  mounted() {
    this.onSort();
  }

  beforeDestroy() {
    this.resetSelected();
  }

  getChecked(file) {
    if (
      this.selected.find(
        selectedItem => selectedItem.contract_id === file.contract_id
      )
    ) {
      return true;
    }
  }

  checkItem(file) {
    const selectedFileIndex = this.selected.findIndex(
      selectedItem => selectedItem.contract_id === file.contract_id
    );
    if (selectedFileIndex === -1) {
      this.selected.push(file);
      return;
    }
    this.selected.splice(selectedFileIndex, 1);
  }

  getVersion(last_version: boolean, version: string) {
    let val = version;
    if (last_version) {
      val = '最終版';
    } else {
      val = version;
    }
    return val;
  }

  allCheck() {
    this.selected = [];
    if (this.select_all) {
      return;
    }
    Object.keys(this.file_items).map(key => {
      if (this.file_items[key].length) {
        this.file_items[key].map(item => {
          this.checkItem(item);
        });
      }
    });
  }

  movePage(contract_id: string) {
    this.$router.push({ name: 'document_detail', params: { id: contract_id } });
  }

  formatDate(date) {
    return dateFormatter(date);
  }

  setSort(columnName: string) {
    SettingsInfoModule.SET_SORT({ pageName: this.$route.name, columnName });
    this.onSort();
  }

  onSort(): void {
    if (!Object.keys(this.file_items).length) return;
    const oneMonthAgoFiles = this.file_items.one_month_ago.filter(
      x => x.contract_id
    );
    const oneMonthBeforeFiles = this.file_items.one_month_before.filter(
      x => x.contract_id
    );
    const oneWeekAgoFiles = this.file_items.one_week_ago.filter(
      x => x.contract_id
    );
    const todayFiles = this.file_items.today.filter(x => x.contract_id);
    const yesterdayFiles = this.file_items.yesterday.filter(x => x.contract_id);

    this.file_items.one_month_ago = this.fetchSortedFiles(oneMonthAgoFiles);
    this.file_items.one_month_before = this.fetchSortedFiles(
      oneMonthBeforeFiles
    );
    this.file_items.one_week_ago = this.fetchSortedFiles(oneWeekAgoFiles);
    this.file_items.today = this.fetchSortedFiles(todayFiles);
    this.file_items.yesterday = this.fetchSortedFiles(yesterdayFiles);
  }

  fetchSortedFiles(files) {
    if (this.sort.columnName === 'contractName') {
      return files.sort((a, b) => {
        if (a.contract_title_furigana < b.contract_title_furigana)
          return this.sort.asc ? -1 : 1;
        if (a.contract_title_furigana > b.contract_title_furigana)
          return this.sort.asc ? 1 : -1;
        return uniqueSort(a, b);
      });
    } else if (this.sort.columnName === 'assignee') {
      return files.sort((a, b) => {
        if (a.assignee.user_name < b.assignee.user_name)
          return this.sort.asc ? -1 : 1;
        if (a.assignee.user_name > b.assignee.user_name)
          return this.sort.asc ? 1 : -1;
        return uniqueSort(a, b);
      });
    } else {
      const sortColumn = this.sort.columnName;
      const filesSortCulumsNull = files.filter(file => !file[sortColumn]);
      const filesSortCulumsNotNull = files.filter(file => file[sortColumn]);
      sortBase(filesSortCulumsNotNull, sortColumn, {
        isAsc: this.sort.asc
      });
      return filesSortCulumsNotNull.concat(filesSortCulumsNull);
    }
  }
}
