





























































import { Component, Vue } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import LabelsTable from '@/components/organisms/Settings/LabelsTable.vue';
import AddLabelWindow from '@/components/molecules/modal_windows/AddLabelWindow.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import SettingsInfoModule, { ContractLabel } from '@/store/SettingsInfo';
import notify from '@/functions/notify';

@Component({
  components: {
    AppButton,
    LabelsTable,
    AddLabelWindow,
    TextOnlyWindow
  }
})
export default class Labels extends Vue {
  selected_labels: any[] = [];
  is_showing_add_label_window: boolean = false;
  is_showing_delete_labels_window: boolean = false;

  get labels(): ContractLabel[] {
    return SettingsInfoModule.labels;
  }

  get is_selected(): boolean {
    return this.selected_labels.length > 0;
  }

  get can_edit(): boolean {
    return this.selected_labels.length === 1;
  }

  get updateTargetLabel(): ContractLabel {
    if (this.can_edit) {
      return this.selected_labels[0];
    }
    return null;
  }

  async created() {
    await SettingsInfoModule.get_labels().catch(e => {
      notify.error({ text: 'ラベル情報を取得できませんでした。' });
    });
  }

  openAddLabelWindow() {
    this.is_showing_add_label_window = true;
  }

  closeAddLabelWindow() {
    this.is_showing_add_label_window = false;
  }

  openEditLabelWindow() {
    this.is_showing_add_label_window = true;
  }

  confirmRemoveLabel() {
    this.is_showing_delete_labels_window = true;
  }

  closeDeleteLabels() {
    this.is_showing_delete_labels_window = false;
  }

  async sendLabel(label: ContractLabel) {
    const method = label.label_id ? 'update_label' : 'post_label';
    await SettingsInfoModule[method](label)
      .then(() => {
        notify.success({ text: 'ラベルを追加しました' });
      })
      .catch(e => {
        notify.error({ text: 'ラベルの追加に失敗しました。' });
      });
    this.closeAddLabelWindow();
    this.selected_labels = [];
  }

  async deleteLabels() {
    await SettingsInfoModule.delete_labels(this.selected_labels)
      .then(() => {
        this.selected_labels = [];
        notify.success({ text: '削除しました' });
      })
      .catch(e => {
        notify.error({ text: 'ラベルの削除に失敗しました。' });
      });
    this.closeDeleteLabels();
    this.selected_labels = [];
  }
}
