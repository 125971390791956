



































































































import { Component, Vue } from 'vue-property-decorator';
import HomeHeader from '@/components/organisms/Home/HomeHeader.vue';
import AppNotifications from '@/components/atoms/AppNotifications.vue';
import SettingsHeaderMenu from '@/components/organisms/Settings/SettingsHeaderMenu.vue';
import SettingsSideMenuBar from '@/components/organisms/Settings/SettingsSideMenuBar.vue';

import HomeSideMenuBar from '@/components/organisms/Home/HomeSideMenuBar.vue';
import AddUsersWithDepartmentWindow from '@/components/molecules/modal_windows/AddUsersWithDepartmentWindow.vue';
import ConfirmPasswordFormWindow from '@/components/molecules/modal_windows/ConfirmPasswordFormWindow.vue';
import ChangePasswordConfirmWindow from '@/components/molecules/modal_windows/ChangePasswordConfirmWindow.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import SingleInputFormWindow from '@/components/molecules/modal_windows/SingleInputFormWindow.vue';
import SettingsInfoModule from '@/store/SettingsInfo';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';
import UserInfoModule from '@/store/UserInfo';
import CorporateInfo from '@/store/CorporateInfo';
import Documents from '@/store/Documents';
import notify from '@/functions/notify';
import ExplanationAuthorityWindow from '@/components/molecules/modal_windows/ExplanationAuthorityWindow.vue';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
const axios = require('axios').default;
const UserInfoRepository = RepositoryFactory.get('userInfos');

@Component({
  components: {
    HomeHeader,
    HomeSideMenuBar,
    AppNotifications,
    SettingsHeaderMenu,
    SettingsSideMenuBar,
    AddUsersWithDepartmentWindow,
    ExplanationAuthorityWindow,
    ConfirmPasswordFormWindow,
    ChangePasswordConfirmWindow,
    SingleInputFormWindow,
    TextOnlyWindow
  }
})
export default class Settings extends Vue {
  change_table_counter: number = 0;
  password_error_message: string = '';

  get side_bar_status(): boolean {
    return HomeComponentInfoModule.side_bar_open;
  }

  async created() {
    Documents.SET_CURRENT_DIRECTORY('/');
    await SettingsInfoModule.get_users_corporations_same_me().catch(e => {
      notify.error({ text: '所属部署情報を削除できませんでした。' });
    });

    // TODO: APIからオーナーが作成した部署一覧を取得する
    if (this.departments.length === 0) {
      await SettingsInfoModule.get_departments().catch(e =>
        notify.error({ text: '所属部署情報を取得できませんでした。' })
      );
    }
  }

  async mounted() {
    await Promise.all([
      UserInfoModule.get_user_info(),
      Documents.getNotifications(),
      Documents.getLirisNotifications(),
      CorporateInfo.get_service_plan()
    ])
      .then(values => {
        if (values.find(x => x.status !== HTTP_STATUS.OK)) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
        return;
      });

    if (!this.authorities) {
      await SettingsInfoModule.get_authorities().catch(e =>
        notify.error({ text: '権限一覧を取得できませんでした。' })
      );
    }
  }

  public get openExplanationAuthorityWindow(): boolean {
    return SettingsInfoModule.add_explanation_authority_window;
  }
  public set openExplanationAuthorityWindow(val) {
    SettingsInfoModule.SET_ADD_EXPLANATION_AUTHORITY_WINDOW(val);
  }

  public get show_change_password_confirm_window(): boolean {
    return SettingsInfoModule.can_open_password_confirm_window;
  }

  public get show_change_email_confirm_window(): boolean {
    return SettingsInfoModule.can_open_email_confirm_window;
  }
  public set show_change_email_confirm_window(val) {
    SettingsInfoModule.SET_CAN_OPEN_EMAIL_CONFIRM_WINDOW(val);
  }

  public get show_add_department_window(): boolean {
    return SettingsInfoModule.can_open_add_department_window;
  }

  public set show_add_department_window(isOpenWindow) {
    SettingsInfoModule.SET_CAN_OPEN_ADD_DEPARTMENT_WINDOW(isOpenWindow);
  }

  get show_delete_users_with_department_window(): boolean {
    return SettingsInfoModule.can_open_delete_users_with_department_window;
  }

  set show_delete_users_with_department_window(val) {
    SettingsInfoModule.SET_CAN_OPEN_DELETE_USERS_WITH_DEPARTMENT_WINDOW(val);
  }

  get current_department() {
    return SettingsInfoModule.current_department;
  }

  get department_name() {
    return this.current_department.department_name;
  }

  get selected_users_with_department() {
    return SettingsInfoModule.selected_users_with_department;
  }

  get user_names() {
    const selected_users_with_department = this.selected_users_with_department;
    const user_names = [];

    for (const i in selected_users_with_department) {
      const user_name = selected_users_with_department[i].user_name
        ? selected_users_with_department[i].user_name
        : '未登録';
      user_names.push(user_name.trim());
      user_names.push('と');
    }
    user_names.pop();
    return user_names.join(' ') + ' ';
  }

  public get show_add_users_with_department_window(): boolean {
    return SettingsInfoModule.can_open_add_users_with_department_window;
  }

  public set show_add_users_with_department_window(is_open_dialog) {
    SettingsInfoModule.SET_CAN_OPEN_ADD_USERS_WITH_DEPARTMENT_WINDOW(false);
  }

  public get show_delete_member_window(): boolean {
    return SettingsInfoModule.can_open_delete_member_window;
  }

  public set show_delete_member_window(is_open_window) {
    SettingsInfoModule.SET_CAN_OPEN_DELETE_MEMBER_WINDOW(is_open_window);
  }

  get selected_users() {
    return SettingsInfoModule.selected_users;
  }

  get users_corporations_same_me() {
    return SettingsInfoModule.users_corporations_same_me;
  }

  get selected_users_corporations_same_me() {
    return SettingsInfoModule.selected_users_corporations_same_me;
  }

  get departments() {
    return SettingsInfoModule.departments;
  }

  get authorities() {
    if (UserInfoModule.authority !== 'オーナー') {
      return SettingsInfoModule.authorities.filter(
        x => x.permission_default_name !== 'オーナー'
      );
    } else {
      return SettingsInfoModule.authorities;
    }
  }

  get users_with_department() {
    return SettingsInfoModule.users_with_department;
  }

  get selectableUsers() {
    const selectedUsers = this.users_with_department.map(x => x.user_id);

    return this.users_corporations_same_me.filter(
      x => !selectedUsers.includes(x.user_id)
    );
  }

  get canSubmitPassword() {
    return UserInfoModule.can_submit_password;
  }

  toggleSideBar() {
    HomeComponentInfoModule.change_side_bar();
  }

  cancelDeleteMember() {
    SettingsInfoModule.SET_CAN_OPEN_DELETE_MEMBER_WINDOW(false);
  }

  toSettingUser() {
    this.$router.push({ name: 'members' });
    SettingsInfoModule.SET_CAN_OPEN_ADD_USERS_WITH_DEPARTMENT_WINDOW(false);
  }

  cancelAddUserWithDepartment() {
    // SET_USERS_CORPORATIONS_SAME_ME(users_corporations_same_me)
    SettingsInfoModule.RESET_SELECTED_USERS_CORPORATIONS_SAME_ME();
    SettingsInfoModule.SET_CAN_OPEN_ADD_USERS_WITH_DEPARTMENT_WINDOW(false);
    SettingsInfoModule.SET_IS_DISPLAY_SUGGEST_LIST(false);
  }

  async registerMember(addUsers) {
    await SettingsInfoModule.post_users_with_department(addUsers)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: 'ユーザーを追加できませんでした'
          });
          return;
        }

        notify.success({
          text: `${addUsers.length}人のユーザーを追加しました`
        });
        SettingsInfoModule.RESET_SELECTED_USERS_CORPORATIONS_SAME_ME();
        SettingsInfoModule.SET_CAN_OPEN_ADD_USERS_WITH_DEPARTMENT_WINDOW(false);
      })
      .catch(e => {
        notify.error({
          text: 'ユーザーを追加できませんでした'
        });
      });
  }

  /**
   * メンバーの削除処理
   */
  async deleteMember() {
    const params = this.selected_users
      .filter(m => m.user_id)
      .map(m => {
        return m.user_id;
      });

    await SettingsInfoModule.delete_members(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE_USER
          });
          SettingsInfoModule.SET_SELECTED_USERS([]);
          SettingsInfoModule.SET_CAN_OPEN_DELETE_MEMBER_WINDOW(false);
          return;
        }

        notify.success({
          text: NOTIFY_TEXT.SUCCESS.DELETE_USER
        });

        SettingsInfoModule.SET_SELECTED_USERS([]);
        SettingsInfoModule.SET_CAN_OPEN_DELETE_MEMBER_WINDOW(false);

        // 自分自身を削除した場合ログアウトしてログイン画面に戻す
        if (params.includes(UserInfoModule.user_id)) {
          const user_id = UserInfoModule.user_id;
          await axios.delete(`/user-management/auth?user_id=${user_id}`);

          Vue.cookies.remove('id-token');
          UserInfoModule.RESET_PERMISSION();
          this.$router.push({ name: 'login' });
        }
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.DELETE_USER
        });
        SettingsInfoModule.SET_SELECTED_USERS([]);
        SettingsInfoModule.SET_CAN_OPEN_DELETE_MEMBER_WINDOW(false);
      });
  }

  setMentiondCmmentUsers(user) {
    SettingsInfoModule.SET_SELECTED_USERS_CORPORATIONS_SAME_ME(user);
  }

  removeSelectedUser(user) {
    SettingsInfoModule.REMOVE_SELECTED_USERS_CORPORATIONS_SAME_ME(user);
  }

  async registerDepartment(departmentName) {
    await SettingsInfoModule.post_departments(departmentName)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.ADD_DEPARTMENT
          });
          return;
        }

        notify.success({
          text: NOTIFY_TEXT.SUCCESS.ADD_DEPARTMENT
        });

        SettingsInfoModule.SET_CAN_OPEN_ADD_DEPARTMENT_WINDOW(false);
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.ADD_DEPARTMENT
        });
      });
  }

  cancelAddDepartment() {
    SettingsInfoModule.SET_CAN_OPEN_ADD_DEPARTMENT_WINDOW(false);
  }

  async confirmPassword(password) {
    await UserInfoRepository.checkPassword({
      email: UserInfoModule.email,
      password
    })
      .then(response => {
        SettingsInfoModule.SET_CAN_CHANGE_EMAIL(true);
        SettingsInfoModule.SET_CAN_OPEN_EMAIL_CONFIRM_WINDOW(false);
        this.password_error_message = '';
        UserInfoModule.RESET_PASSWORD();
      })
      .catch(error => {
        UserInfoModule.RESET_PASSWORD();
        if (!error.response) {
          this.password_error_message = 'エラーが発生しました';
          return;
        }
        if (
          error.response.status == HTTP_STATUS.UNAUTHORIZED ||
          error.response.status == HTTP_STATUS.NOT_FOUND ||
          error.response.status == HTTP_STATUS.UNPROCESSABLE_ENTITY
        ) {
          this.password_error_message = 'パスワードに誤りがあります';
          return;
        } else if (error.response.status == HTTP_STATUS.TOO_EARLY) {
          this.password_error_message = '一時的にロックされています';
          return;
        }
        this.password_error_message = 'エラーが発生しました';
      });
  }

  /**
   * メールアドレス変更前のパスワード確認モーダルclose
   */
  cancelChangeEmail() {
    this.password_error_message = '';
    SettingsInfoModule.SET_CAN_OPEN_EMAIL_CONFIRM_WINDOW(false);
  }

  /**
   * パスワード変更
   */
  async changePassword(params) {
    this.password_error_message = '';
    await UserInfoRepository.updatePassword({
      password: params.password,
      new_password: params.newPassword
    })
      .then(response => {
        SettingsInfoModule.SET_CAN_OPEN_PASSWORD_CONFIRM_WINDOW(false);
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.CHANGE_PASSWORD
        });
      })
      .catch(error => {
        if (!error.response) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CHANGE_PASSWORD
          });
          return;
        }
        if (
          error.response.status == HTTP_STATUS.UNAUTHORIZED ||
          error.response.status == HTTP_STATUS.NOT_FOUND ||
          error.response.status == HTTP_STATUS.UNPROCESSABLE_ENTITY
        ) {
          this.password_error_message = 'パスワードに誤りがあります';
          return;
        }
        if (error.response.status == HTTP_STATUS.TOO_EARLY) {
          this.password_error_message = '一時的にロックされています';
          return;
        }
        if (error.response.status == 444) {
          this.password_error_message =
            'ワンタイムパスワードの期限が切れています';
          return;
        }
        notify.error({
          text: NOTIFY_TEXT.ERROR.CHANGE_PASSWORD
        });
      });
  }

  /**
   * パスワード変更モーダルclose
   */
  cancelChangePassword() {
    this.password_error_message = '';
    SettingsInfoModule.SET_CAN_OPEN_PASSWORD_CONFIRM_WINDOW(false);
  }

  changeNewPassword(val) {
    UserInfoModule.SET_PASSWORD_FOR_VALIDATE({ password: val });
  }

  cancelDeleteUsersWithDepartment() {
    SettingsInfoModule.SET_CAN_OPEN_DELETE_USERS_WITH_DEPARTMENT_WINDOW(false);
  }

  async deleteUsersWithDepartment() {
    await SettingsInfoModule.delete_users_with_department()
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE_DEPARTMENT_USER
          });
          return;
        }

        notify.success({
          text: `${this.selected_users_with_department.length}人のユーザーを削除しました`
        });

        SettingsInfoModule.SET_CAN_OPEN_DELETE_USERS_WITH_DEPARTMENT_WINDOW(
          false
        );
        SettingsInfoModule.SET_SELECTED_USERS_WITH_DEPARTMENT([]);
        await SettingsInfoModule.get_users_corporations_same_me().catch(e => {
          notify.error({ text: '所属部署情報を削除できませんでした。' });
        });
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.DELETE_DEPARTMENT_USER
        });
      });
  }

  closeExplanationAuthorityWindow() {
    SettingsInfoModule.SET_ADD_EXPLANATION_AUTHORITY_WINDOW(false);
  }
}
