/**
 * 自動抽出のアイコンを表示するか判定する
 *
 * @param number autoExtract
 * @return boolean
 */
function isDisplayAutoExtract(autoExtract: number): boolean {
  return autoExtract === 0 || autoExtract === 1;
}

export default { isDisplayAutoExtract };
