














import { Component, Prop, Vue } from 'vue-property-decorator';

// props の colorClass に選択可能なテキスト
const selectableColor = [
  'primary',
  'secondary',
  'tertiary',
  'text',
  'text2',
  'alert1',
  'alert2',
  'alert3'
];

@Component
export default class AppButton extends Vue {
  @Prop()
  name?: string;

  @Prop({
    default: 'submit',
    validator: type => ['submit', 'button'].includes(type)
  })
  type?: string;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: false })
  showTriangle?: boolean;

  /**
   * ボタンの色に関するクラスを指定
   * XDのスタイルガイド_Buttonを参照
   */
  @Prop({
    default: 'primary',
    validator: value => selectableColor.includes(value)
  })
  colorClass: string;

  /**
   * XDのスタイルガイド_Buttonを参照（高さ、文字サイズのみ）
   */
  @Prop({
    default: 'small',
    validator: value => ['small', 'middle'].includes(value)
  })
  sizeClass: string;

  @Prop({ default: 0 })
  width!: number;

  getColorClassName() {
    return `${this.colorClass}-color`;
  }
}
