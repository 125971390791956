

















































import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';
import AvatarCommentInput from '@/components/molecules/avatar/AvatarCommentInput.vue';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';
import Documents, { EditCommentItem } from '@/store/Documents';
import UserInfoModule from '@/store/UserInfo';
import dayjs from 'dayjs';
import sanitizeHTML from 'sanitize-html';
import { Component, Prop, Vue } from 'vue-property-decorator';
// v-htmlを使うので
Vue.prototype.$sanitize = sanitizeHTML;

dayjs.locale('ja');

// 画面側でKey→文言取得よりAPIdeそのまま文言返した方がいい気がする
const STATUS: { [key: string]: string } = {
  created: '作成・確認依頼中',
  internal_confirming: '内部確認中'
};

@Component({
  components: {
    TextAvatar,
    AvatarCommentInput
  }
})
export default class AvatarCommentItem extends Vue {
  @Prop()
  item: EditCommentItem;

  @Prop()
  isOnlyComment: boolean;

  isEdit: boolean = false;

  formatHrefLink(string) {
    const regex = /(https?:\/\/[\w\.\d:;?&=#/_+!$'()*@~%\-]+)/gm;
    const subst = `$1<a href="$1" target="_blank" rel="noopener noreferrer"><img id="openLinkIcon" src="/static/icons/external-link-blue.svg" style='width: 14px; margin: 0 8px;'></a>`;
    const result = string.replace(regex, subst);
    return result;
  }

  is_show(target, key) {
    return target === key;
  }

  get_status_text(status) {
    return STATUS[status];
  }

  get_update_date(target) {
    if (!target) return;
    const target_date = dayjs(target);
    const now = dayjs();
    const diff_date = now.get('date') - target_date.get('date');
    if (diff_date === 0) {
      return '今日 ' + target_date.format('HH:mm');
    } else if (diff_date === 1) {
      return '昨日 ' + target_date.format('HH:mm');
    } else {
      return target_date.format('YYYY/MM/DD HH:mm');
    }
  }

  get can_display_unit() {
    if (this.item.is_comment) {
      return true;
    }
    return !this.isOnlyComment;
  }

  get user_name(): string {
    return UserInfoModule.user_name;
  }

  get comment(): string {
    const regex = new RegExp('@\{(.*?)?\}', 'g');
    const new_text: string = this.item.comment_info.comment;
    return new_text.replace(regex, '<span class="mention">@$1</span>');
  }

  editComment(item) {
    this.isEdit = true;
  }

  /**
   * コメント送信処理
   */
  async submitComment(params): Promise<void> {
    const comment: string = params.comment;
    // コメントが変更されていない場合はリクエストしない
    // 変更するまで、inputEventではなくstringが返ってきてしまうための暫定対応
    if (params.comment) {
      const mentionUsers: string[] = params.selctedUsers.map(user => {
        return user.user_id;
      });

      const apiParams = {
        comment_id: this.item.comment_info.comment_id,
        comment,
        mention_users: mentionUsers
      };

      await Documents.updateComment(apiParams).catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.EDIT_COMMENT
        });
      });
      this.isEdit = false;
    }
  }
}
