<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12.025"
    viewBox="0 0 12 12.025"
  >
    <g transform="translate(0 .012)">
      <path
        :class="`is_${color}`"
        d="M11.125 8a5.5 5.5 0 0 1-9.731 1.008"
        class="cls-1"
        data-name="パス 91"
      />
      <path
        :class="`is_${color}`"
        d="M12 0v5l-4.5-.5z"
        class="cls-2"
        data-name="パス 92"
      />
      <path
        :class="`is_${color}`"
        d="M.875 4a5.5 5.5 0 0 1 9.731-1.008"
        class="cls-1"
        data-name="パス 93"
      />
      <path
        :class="`is_${color}`"
        d="M0 12V7l4.5.5z"
        class="cls-2"
        data-name="パス 94"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'IconReload',
  props: {
    color: {
      type: String,
      default: 'white',
      validator(value) {
        return ['white', ''].includes(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
}
.cls-2 {
  fill: #fff;
}
</style>
