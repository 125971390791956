










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Label extends Vue {
  @Prop()
  backgroundColor!: string;

  @Prop()
  textColor!: string;

  @Prop()
  text: string;

  @Prop({ default: false })
  mark: boolean;

  @Prop({ default: false })
  clickable: boolean;

  @Prop({ default: false })
  removable: boolean;

  get labelStyle() {
    return {
      backgroundColor: this.backgroundColor,
      color: this.textColor
    };
  }

  get labelClass() {
    return {
      mark: this.mark,
      clickable: this.clickable,
      deselect: this.removable
    };
  }

  click() {
    if (!this.clickable) {
      return;
    }
    this.$emit('click', this);
  }
}
