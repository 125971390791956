




































import { Component, Vue } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextFormMidSize from '@/components/atoms/text_forms/TextFormMidSize.vue';
import UserInfoModule from '@/store/UserInfo';
import CorporateInfo from '@/store/CorporateInfo';
const axios = require('axios').default;
import fingerprint2 from 'fingerprintjs2';
import HTTP_STATUS from '@/consts/HttpStatus';
import { move_home } from '@/functions/DocumentRouter';

interface Window {
  requestIdleCallback: any;
}

@Component({
  components: {
    AppButton,
    TextFormMidSize
  }
})
export default class LoginForm extends Vue {
  email_error_message: string = '';
  password_error_message: string = '';
  sign_in_error_message: string = '';

  mounted() {
    UserInfoModule.SET_PASSWORD('');
    Vue.cookies.keys().forEach(cookie => Vue.cookies.remove(cookie));
    setTimeout(this.setFingerprintHashVal, 100);
  }

  reset_messages() {
    this.email_error_message = '';
    this.password_error_message = '';
    this.sign_in_error_message = '';
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  setFingerprintHashVal() {
    // ユーザーエージェントは除外
    const options = { excludes: { userAgent: true } };
    fingerprint2.get(options, function(components) {
      const values = components.map(function(component) {
        return component.value;
      });
      const hash = fingerprint2.x64hash128(values.join(''), 31);
      Vue.cookies.set('fingerprint', hash, { expires: 60 * 60 * 24 });
    });
  }

  async login() {
    this.reset_messages();

    const email = UserInfoModule.email;
    const password = UserInfoModule.password;

    let error: boolean = false;

    if (!email) {
      this.email_error_message = 'メールアドレスを入力してください';
      error = true;
    }
    if (!password) {
      this.password_error_message = 'パスワードを入力してください';
      error = true;
    }
    if (!error) {
      const url = `user-management/auth`;
      const request_data = {
        email: email,
        password: password
      };

      await axios
        .post(url, request_data)
        .then(async response => {
          const data = response.data;

          Vue.cookies.set('is-logined', true);
          UserInfoModule.SET_USER_ID(data.user_id);
          UserInfoModule.SET_AUTHORITY(data);
          UserInfoModule.SET_ID_TOKEN(data.id_token);
          UserInfoModule.SET_DEPARTMENT_NAME(data.department.department_name);
          UserInfoModule.SET_EMAIL(data.email);
          UserInfoModule.SET_AUTHORITY(data);
          UserInfoModule.SET_AVATAR(data.avatar);
          UserInfoModule.SET_REQUIRED_CHANGE_PASSWORD(
            data.is_required_changing_password
          );
          CorporateInfo.SET_CORPORATION_ID(data.corporation_id);
          CorporateInfo.SET_SERVICE_PLANS(data.service_plan);

          if (data.is_required_changing_password) {
            // 初回ログイン、パスワード変更ページへ
            this.$router.push({ name: 'change_password' });
            return;
          }
          if (response.status == HTTP_STATUS.OK) {
            if (!data.user_name) {
              // user_nameが無い場合、アカウント情報登録ページへ
              this.$router.push({ name: 'user_account_information_form' });
              return;
            }

            // 通常のログイン。2.0 ドキュメント系へ遷移
            UserInfoModule.SET_USER_NAME(data.user_name);
            // リダイレクトURLが設定されていた場合
            if (this.$route.query.redirect) {
              const redirectUrl = this.$route.query.redirect as string;
              // リダイレクト先からの history.back にも対応するよう一度 home を経由してリダイレクトする
              await move_home(this.$router);
              this.$router.push({
                path: decodeURIComponent(redirectUrl)
              });
            } else {
              move_home(this.$router);
            }
          }
          UserInfoModule.SET_PASSWORD('');
        })
        .catch(async err => {
          if (
            err &&
            'name' in err &&
            (!err.response || err.name === 'NavigationDuplicated')
          ) {
            return;
          }
          if (!err) {
            this.sign_in_error_message = 'ログイン処理でエラーが発生しました';
            return;
          }
          if (
            err.response.status == HTTP_STATUS.UNAUTHORIZED ||
            err.response.status == HTTP_STATUS.NOT_FOUND ||
            err.response.status == HTTP_STATUS.UNPROCESSABLE_ENTITY
          ) {
            this.sign_in_error_message =
              'メールアドレスまたはパスワードが正しくありません';
            return;
          }
          if (err.response.status == HTTP_STATUS.TOO_EARLY) {
            this.sign_in_error_message = `このアカウントはロックされました。\n自動的にパスワードリセット画面に移動します。\nパスワードをリセットしてください。`;
            await this.sleep(5000);
            this.$router.push({ name: 'forgot_password' });
            return;
          }
          if (err.response.status == 444) {
            this.sign_in_error_message = `ワンタイムパスワードの期限が切れています`;
            return;
          }
          this.sign_in_error_message = 'ログイン処理でエラーが発生しました';
        });
    }
  }
}
