









import { Component, Vue, Prop } from 'vue-property-decorator';
import CorporateInfoModule from '@/store/CorporateInfo';

@Component
export default class UsageRateBar extends Vue {
  @Prop({ default: false })
  alert: boolean;

  max_bar_width: number = 136;

  get usage_rate() {
    return CorporateInfoModule.usage_rate;
  }

  get front_bar_style() {
    const width = Math.ceil(this.max_bar_width * this.usage_rate);
    return {
      width: String(width) + 'px'
    };
  }
}
