




































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import SettingsInfoModule, { Member } from '@/store/SettingsInfo';
import AddMemberWindow from '@/components/molecules/modal_windows/AddMemberWindow.vue';
import SettingsMembersHeader from '@/components/molecules/settings_headers/SettingsMembersHeader.vue';
import MembersTable from '@/components/organisms/Settings/MembersTable.vue';
import UserInfo from '@/store/UserInfo';
import AUTHORITIES from '@/consts/Authorities';
import HTTP_STATUS from '@/consts/HttpStatus';
import notify from '@/functions/notify';
const axios = require('axios').default;
import NOTIFY_TEXT from '@/consts/NotifyText';
import moment from 'moment';
import { sortBase } from '@/functions/Sort';
import { validateEmail } from '@/functions/Validation';

interface Permissions {
  permission_default_name: string;
  permission_default_id: string;
}

@Component({
  components: {
    AddMemberWindow,
    SettingsMembersHeader,
    MembersTable
  }
})
export default class Members extends Vue {
  @Prop({ default: 0 })
  change_table_counter: number;
  is_showing_add_member_window: boolean = false;
  is_add_member_sending: boolean = false;
  searchKeyword: string = '';
  viewMembers: Member[] = [];

  get permission() {
    return UserInfo.permission;
  }

  get invitations() {
    return SettingsInfoModule.invitations;
  }

  get departments() {
    return SettingsInfoModule.departments;
  }

  @Watch('searchKeyword')
  filterViewMembers(keyword: string) {
    if (!keyword) {
      this.viewMembers = this.members;
    } else {
      // キーワードを分割
      let keywords = keyword.split(/\s/);
      // 重複を削除する
      keywords = [...new Set(keywords)];

      let members = this.members;
      keywords.forEach(k => {
        if (!k) return;
        const filterResults = members.filter((m: Member) => {
          // 「最終アクセス日」のフォーマットを整形
          const logined_at = moment(m.logined_at).format('YYYY/MM/DD HH:mm');
          return (
            (m.user_name && m.user_name.match(k)) ||
            (m.email && m.email.match(k)) ||
            (m.department_name && m.department_name.match(k)) ||
            (m.permission_default_name && m.permission_default_name.match(k)) ||
            logined_at.match(k)
          );
        });
        members = filterResults;
      });

      this.viewMembers = members;
    }
  }

  @Watch('members')
  syncViewMembers(members: Member[]) {
    this.viewMembers = members;
  }

  get members(): Member[] {
    return SettingsInfoModule.members;
  }

  set members(members: Member[]) {
    SettingsInfoModule.SET_MEMBERS(members);
  }

  get authorities() {
    if (UserInfo.authority !== AUTHORITIES.OWNER) {
      return SettingsInfoModule.authorities.filter(
        x => x.permission_default_name !== AUTHORITIES.OWNER
      );
    } else {
      return SettingsInfoModule.authorities;
    }
  }

  async get_members() {
    await SettingsInfoModule.get_members();

    if (SettingsInfoModule.can_user_authority_edit) {
      SettingsInfoModule.SET_CAN_USER_AUTHORITY_EDIT(false);
    }
  }

  async mounted() {
    await SettingsInfoModule.get_authorities().catch(e =>
      notify.error({ text: '権限一覧を取得できませんでした。' })
    );
    await this.get_members();

    // membersをそのままMembersTableに渡すとソートされるデータの前のため表示時にちらつく。
    // よって、あらかじめソートしたデータを初期値としてセットする
    this.viewMembers = this.members;
    sortBase(
      this.viewMembers,
      SettingsInfoModule.sort[this.$route.name].columnName,
      { isAsc: true }
    );
  }

  beforeDestroy() {
    SettingsInfoModule.SET_SELECTED_USERS([]);
    SettingsInfoModule.SET_CAN_USER_AUTHORITY_EDIT(false);
  }

  async change_edit() {
    SettingsInfoModule.SET_CAN_USER_AUTHORITY_EDIT(true);
  }

  async saveAuthority() {
    if (!this.permission.can_change_permission) {
      notify.error({
        text: '変更権限がありません'
      });
      return;
    }

    const params = this.members
      .filter((m: Member) => m.user_id)
      .map((m: Member) => {
        const permission_default_id = m.permission_default_name
          .permission_default_id
          ? m.permission_default_name.permission_default_id
          : m.permission_default_id;

        return {
          user_id: m.user_id,
          permission_default_id
        };
      });

    const isExistsOwner = this.members.some((x: Member) =>
      x.user_name && typeof x.permission_default_name === 'string'
        ? x.permission_default_name === AUTHORITIES.OWNER
        : x.permission_default_name.permission_default_name ===
          AUTHORITIES.OWNER
    );

    if (!isExistsOwner) {
      notify.error({
        text: '未登録のユーザーを除いて、オーナーを0人にすることはできません'
      });
      return;
    }

    const url = '/user-management/users';
    await axios
      .put(url, params)
      .then(res => {
        if (res.status === HTTP_STATUS.FORBIDDEN) {
          notify.error({
            text: '変更権限がありません'
          });
          return;
        }

        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: 'ユーザーの権限を変更できませんでした'
          });
          return;
        }

        notify.success({
          text: `ユーザーの権限を変更しました`
        });

        SettingsInfoModule.SET_CAN_USER_AUTHORITY_EDIT(false);
        UserInfo.get_user_info();
        this.$emit('addTableCounter');
      })
      .catch(error => {
        notify.error({
          text: 'ユーザーの権限を変更できませんでした'
        });
      });
  }

  async cancelAuthority() {
    await this.get_members();
    this.viewMembers = this.members;
    sortBase(this.viewMembers, 'user_name_furigana', { isAsc: true });
    SettingsInfoModule.SET_CAN_USER_AUTHORITY_EDIT(false);
  }

  /**
   * メンバーの削除確認モーダル表示処理
   */
  async delete_member() {
    SettingsInfoModule.SET_CAN_OPEN_DELETE_MEMBER_WINDOW(true);
  }

  /**
   * ユーザー追加ボタン押下時
   */
  addUser() {
    SettingsInfoModule.RESET_INVITATIONS();
    this.is_showing_add_member_window = true;
  }

  /**
   * AddMemberWindow の複数ユーザー追加ボタン押下時
   */
  clickAddInvitation() {
    SettingsInfoModule.ADD_INVITATION();
  }

  clickRemoveInvitation(index) {
    SettingsInfoModule.REMOVE_INVITATION(index);
  }

  /**
   * AddMemberWindow, AddUsersForDepartmentWindow の送信ボタン押下時
   */
  async clickSendInvitation() {
    this.is_add_member_sending = true;
    let sendedEmail = false;
    const errorMessages = [];
    for (let i = 0; i < this.invitations.length; i++) {
      SettingsInfoModule.CLEAR_INVITATION_ERROR(i);

      const errorMessage = validateEmail(this.invitations[i].email);
      if (errorMessage) {
        errorMessages[i] = errorMessage;
        continue;
      }

      const res = await SettingsInfoModule.post_members(i);

      if (res.status !== HTTP_STATUS.OK) {
        if (res.status === HTTP_STATUS.CONFLICT) {
          errorMessages[i] = NOTIFY_TEXT.ERROR.ALREADY_EXIST_EMAIL;
          continue;
        }
        if (res.status === HTTP_STATUS.EXCEEDING_LIMIT) {
          errorMessages[i] = NOTIFY_TEXT.ERROR.EXCEEDING_LIMIT;
          continue;
        }
        errorMessages[i] = NOTIFY_TEXT.ERROR.INVITE_EMAIL;
        continue;
      }

      if (res instanceof Error) {
        errorMessages[i] = NOTIFY_TEXT.ERROR.INVITE_EMAIL;
        continue;
      }

      sendedEmail = true;
    }
    for (let i = this.invitations.length - 1; i >= 0; i--) {
      if (errorMessages[i]) {
        SettingsInfoModule.SET_INVITATION_ERROR({
          index: i,
          message: errorMessages[i]
        });
      } else {
        SettingsInfoModule.REMOVE_INVITATION(i);
      }
    }
    if (sendedEmail) {
      SettingsInfoModule.SET_COMPLETE_SENDING_EMAIL(true);
      SettingsInfoModule.get_members();
      notify.success({
        text: NOTIFY_TEXT.SUCCESS.INVITE_EMAIL
      });
    }
    this.is_add_member_sending = false;
    if (this.invitations.some(v => v.error)) {
      return;
    }
    this.is_showing_add_member_window = false;
    SettingsInfoModule.RESET_INVITATIONS();
  }

  inputSearchKeyword(keyword) {
    this.searchKeyword = keyword;
  }

  clearKeyword() {
    this.searchKeyword = '';
    this.viewMembers = this.members;
  }

  /**
   * AddMemberWindow, AddUsersForDepartmentWindow のキャンセルボタン押下時
   */
  clickCancelInvitation() {
    this.is_showing_add_member_window = false;
    SettingsInfoModule.RESET_INVITATIONS();
  }

  /**
   * InviteUserEmailTextForm テキスト入力時
   */
  setUserEmail(payload) {
    SettingsInfoModule.SET_INVITED_USER_EMAIL(payload);
  }

  setDepartment(payload) {
    SettingsInfoModule.SET_INVITED_USER_DEPARTMENT(payload);
  }

  setUserAuthority(payload) {
    SettingsInfoModule.SET_INVITED_USER_AUTHORITY(payload);
  }

  openAddingDepartmentDialog() {
    SettingsInfoModule.SET_CAN_OPEN_ADD_DEPARTMENT_WINDOW(true);
  }
}
