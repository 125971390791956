











































































































































































































































































































































































































































































import {
  Component,
  Prop,
  Watch,
  Emit,
  PropSync,
  Vue
} from 'vue-property-decorator';
import SelectContractModal from '@/components/molecules/modal_windows/SelectContractModal.vue';
import UploadButton from '@/components/atoms/buttons/UploadButton.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import AttachDocumentCard from '@/components/molecules/AttachDocumentCard.vue';
import DropdownMenuContainer from '@/components/molecules/DropdownMenuContainer.vue';
import MenuContent from '@/components/atoms/buttons/MenuContent.vue';
import MakeBreadCrumb from '@/components/atoms/breadcrumb/MakeBreadCrumb.vue';
import SelectedItemsView from '@/components/molecules/SelectedItemsView.vue';
import SingleInputFormWindow from '@/components/molecules/modal_windows/SingleInputFormWindow.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import Attachments from '@/store/Attachments';

import HomeComponentInfo from '@/store/HomeComponentInfo';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';

import Documents, { ContractInfoBase } from '@/store/Documents';
import AssociationsModule, { AssociationInfo } from '@/store/Associations';
import SearchInfo from '@/store/SearchInfo';
import UserInfo from '@/store/UserInfo';

const axios = require('axios').default;
import dayjs from 'dayjs';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import LAYOUT_UTIL from '@/consts/LayoutUtil';
import WINDOW_MESSAGES from '@/consts/TextWindowMessage';

import Validation from '@/functions/Validation';
import notify from '@/functions/notify';
import FileBackup from '@/functions/FileBackup';
import { spaceReplaceUnderScore } from '@/functions/replaceString';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const AssociationRepository = RepositoryFactory.get('associations');
const DocumentRepository = RepositoryFactory.get('documents');
const AttachmentRepository = RepositoryFactory.get('attachments');
const TemplateInfoRepository = RepositoryFactory.get('templateInfos');

const TAB_MENUS = {
  CONTRACTED_DOCUMENT: 'contracted_document',
  CONTRACT_MANAGEMENT: 'contract_management'
};

@Component({
  components: {
    UploadButton,
    AppButton,
    MakeBreadCrumb,
    SelectedItemsView,
    AttachDocumentCard,
    DropdownMenuContainer,
    MenuContent,
    SingleInputFormWindow,
    SelectContractModal,
    TextOnlyWindow
  }
})
export default class HomeBodyHeader extends Vue {
  @Prop({ default: true })
  selectedTabOnRecentlyViewed: boolean;
  @Prop({ default: true })
  side_bar_status?: boolean;
  @Prop({ default: false })
  isRecentlyViewedPage: boolean;
  // TODO: 場当たり対応なので修正する。
  @Prop({ default: false })
  isContractedDocumentTabSelected: boolean;
  @Prop({ default: false })
  isContractManagementTabSelected: boolean;
  @Prop({ default: [] })
  objects!: any;
  @PropSync('isLoadingParent', { type: Boolean, default: false })
  isLoading: boolean;
  @Prop({ default: false })
  canCreateFolder: boolean;

  isShowCreateAssociation: boolean = false;
  is_showing_attachment_document_window: boolean = false;
  is_showing_create_new_folder_window: boolean = false;
  folderNameErrorMessage: string = '';
  attachFileList: File[] = [];
  fileAttaching: boolean = false;
  alreadyAttachedFileLength: number = 0;
  rename_contract = {
    contract_title: '',
    contract_id: ''
  };
  rename_folder = {
    directory_name: ''
  };
  isShowingRenameFileModal: boolean = false;
  isShowingRenameFolderModal: boolean = false;
  associatedContracts: AssociationInfo[] | ContractInfoBase = [];
  showDeleteObjectModal: boolean = false;
  showBackupModal: boolean = false;
  backupModalDescription: string = WINDOW_MESSAGES.BACKUP_DESCRIPTION;
  fileLimit: number = 15;
  fileNameOrFolderNameErrorMessage: string = '';
  newFolderCreating: boolean = false;
  deletingFoldersAndFiles: boolean = false;
  folderNameEditing: boolean = false;
  approvingContract: boolean = false;
  fileNameEditing: boolean = false;
  isDisabledRefreshTableIcon: boolean = false;

  showDownloadModal: boolean = false;
  getDownloadInterval: any = null;
  downloadLink: string = '';

  get editFileNameButtonText(): string {
    return this.fileNameEditing ? '保存中' : '保存';
  }

  get editFolderNameButtonText(): string {
    return this.folderNameEditing ? '保存中' : '保存';
  }

  get createNewFolderButtonText(): string {
    return this.newFolderCreating ? '作成中' : '作成';
  }

  get attachDocumentButtonText(): string {
    return this.fileAttaching ? '保存中' : '保存';
  }

  get permission() {
    return UserInfo.permission;
  }

  get num_files(): number {
    return Documents.selected_files_of_table.length;
  }

  get num_folders(): number {
    return Documents.selected_folders_of_table.length;
  }

  get searching(): boolean {
    return SearchInfo.searching;
  }

  get is_searching_now(): boolean {
    return SearchInfo.is_searching_now;
  }

  get current_directory(): string {
    return Documents.current_directory;
  }

  get isIncludeNotVersionInSelectedFiles() {
    const selectedFiles = Documents.selected_files_of_table;
    const notVersionedFiles = selectedFiles.filter(x => !x.file_extension);

    return notVersionedFiles.length > 0;
  }

  get isShowDeleteButton() {
    if (
      this.isRecentlyViewedPage &&
      this.isContractManagementTabSelected &&
      !this.permission.can_delete_unsigned_file
    ) {
      return false;
    }
    if (!this.permission.can_delete_move_download_file) return false;
    return true;
  }

  get selected(): boolean {
    const files_length = Documents.selected_files_of_table.length;
    const folders_length = Documents.selected_folders_of_table.length;
    return files_length > 0 || folders_length > 0;
  }

  get candidates(): AssociationInfo[] {
    if (!AssociationsModule) return [];
    return AssociationsModule.candidates;
  }

  get isRunningUploadJob(): boolean {
    return Documents.isRunningUploadJob;
  }

  async cancelAttachDocs() {
    // 通信をキャンセルする
    await Attachments.cancelRequest();
    // キャンセル後にファイル情報を再取得する
    Documents.getFilesAndFolders();
  }

  async refreshTable() {
    this.isDisabledRefreshTableIcon = true;
    await Documents.getFilesAndFolders();
    this.isDisabledRefreshTableIcon = false;
  }

  async attachDocs() {
    const attachFileCount = this.attachFileList.length;
    this.fileAttaching = true;

    const source = axios.CancelToken.source();
    await Attachments.ADD_CANCEL_TOKEN(source);

    const formData = new FormData();
    for (const file of this.attachFileList) {
      formData.append('attachment_list', file);
    }

    const params = {
      contract_id: Documents.selected_files_of_table[0].contract_id,
      attach_files: this.attachFileList,
      formData
    };

    await AttachmentRepository.upload(params)
      .then(async res => {
        notify.clearSuccess();
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.ATTACHED_DOCUMENT
          });
          return;
        }

        notify.clearSuccess();
        this.fileAttaching = false;
        this.is_showing_attachment_document_window = false;

        // スナックバー(vue-notification)がtransitionで300msかけて消えるため合わせる
        setTimeout(() => {
          notify.success({
            text: `${attachFileCount}${NOTIFY_TEXT.SUCCESS.ATTACHED_DOCUMENT}`
          });
        }, 300);

        this.attachFileList = [];
        Documents.update_document_info();
        Documents.RESET_SELECTED_TABLE();
      })
      .catch(e => {
        notify.clearSuccess();
        this.fileAttaching = false;
        this.is_showing_attachment_document_window = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.ATTACHED_DOCUMENT
        });
      });
  }

  get attachDocsNum() {
    return this.alreadyAttachedFileLength + this.attachFileList.length;
  }

  get disabledAttachDocs(): boolean {
    if (this.attachFileList.length === 0 || this.fileAttaching) {
      return true;
    } else if (this.attachDocsNum > this.fileLimit) {
      return true;
    } else {
      return false;
    }
  }

  get deleteModalDescription(): string {
    const folderWord =
      this.num_folders > 0 ? `${this.num_folders}個のフォルダ` : '';
    const fileWord = this.num_files > 0 ? `${this.num_files}個のファイル` : '';
    const conjunction = folderWord && fileWord ? 'と' : '';
    return `${folderWord + conjunction + fileWord}を削除してもよろしいですか？`;
  }

  get showMoveButton() {
    if (this.isContractManagementTabSelected) return false;
    if (
      this.isRecentlyViewedPage &&
      Documents.selected_files_of_table.length > 1
    )
      return false;
    return true;
  }

  async deleteFoldersAndFiles() {
    this.deletingFoldersAndFiles = true;
    Documents.SET_IS_DELETING_FOLDER_AND_FILES(true);
    const selected_files = Documents.selected_files_of_table;
    const selected_folders = Documents.selected_folders_of_table;
    if (selected_files.length === 0 && selected_folders.length === 0) return;

    let isFileDeleteSuccess = true;
    let isFolderDeleteSuccess = true;

    // ファイル削除処理
    if (selected_files.length > 0) {
      isFileDeleteSuccess = await this.deleteFiles(selected_files);
    }

    // フォルダ削除処理
    if (selected_folders.length > 0) {
      isFolderDeleteSuccess = await this.deleteFolders(selected_folders);
    }

    if (!isFileDeleteSuccess || !isFolderDeleteSuccess) {
      Documents.SET_IS_DELETING_FOLDER_AND_FILES(false);
      return;
    }

    await Documents.getRecentlyViewedFiles(
      this.isContractedDocumentTabSelected
    );
    await Documents.update_document_info();

    this.showDeleteObjectModal = false;
    this.deletingFoldersAndFiles = false;
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.DELETE,
      data: {
        cancelMethod: this.cancelDeleteFileOrFolder
      }
    });
    Documents.RESET_SELECTED_TABLE();

    await AssociationsModule.getCandidates();

    // スナックバーの表示が終わったらリセットする
    setTimeout(() => {
      Documents.SET_TEMP_DELETED_FILES([]);
      Documents.SET_TEMP_DELETED_FOLDERS([]);
    }, 5500);

    Documents.SET_IS_DELETING_FOLDER_AND_FILES(false);
  }

  async cancelDeleteFileOrFolder(): Promise<void> {
    // 表示されているスナックバーを消す
    notify.clearSuccess();
    this.isLoading = true;
    const responses = await Documents.restoreDeletedFileOrFolder();
    this.isLoading = false;

    // ファイルもしくはフォルダの削除取り消しでエラーがあれば notify を表示し処理を終える。
    const failed: boolean = responses.some(
      res => !res || res.status !== HTTP_STATUS.NO_CONTENT
    );
    if (failed) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.CANCEL
      });
      return;
    }

    this.isLoading = true;
    // 締結済ドキュメント
    if (this.$route.name === 'home') await Documents.update_document_info();

    // 最近表示
    if (this.$route.name === 'recentlyView') {
      // 最近表示(締結済ドキュメント)
      let isContractedDocumentTabSelected = true;

      // 最近表示(締結管理)
      if (this.isContractManagementTabSelected)
        isContractedDocumentTabSelected = false;

      await Documents.getRecentlyViewedFiles(isContractedDocumentTabSelected)
        .then(res => {
          if (res.status !== HTTP_STATUS.OK) {
            notify.error({
              text: NOTIFY_TEXT.ERROR.GENERAL
            });
            return;
          }
        })
        .catch(err => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        });
    }

    Documents.RESET_SELECTED_TABLE();
    this.isLoading = false;
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CANCEL
    });
  }

  async deleteFiles(contracts: any[]) {
    return await DocumentRepository.deleteContractFile(contracts)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          this.isLoading = false;
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE
          });
          return false;
        }
        Documents.SET_TEMP_DELETED_FILES(contracts);
        return true;
      })
      .catch(err => {
        this.isLoading = false;
        this.deletingFoldersAndFiles = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.DELETE
        });
        return false;
      });
  }

  async deleteFolders(folders: any[]) {
    return await DocumentRepository.deleteContractFolder(folders)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          this.isLoading = false;
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE
          });
          return false;
        }
        Documents.SET_TEMP_DELETED_FOLDERS(folders);
        return true;
      })
      .catch(err => {
        this.isLoading = false;
        this.deletingFoldersAndFiles = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.DELETE
        });
        return false;
      });
  }

  closeFileModal() {
    this.isShowingRenameFileModal = false;
    this.fileNameOrFolderNameErrorMessage = '';
  }

  closeFolderModal() {
    this.isShowingRenameFolderModal = false;
    this.fileNameOrFolderNameErrorMessage = '';
  }

  async updateFileName(renameFile) {
    this.fileNameOrFolderNameErrorMessage = Validation.validateFileNameAndFolderName(
      renameFile
    );
    if (this.fileNameOrFolderNameErrorMessage) return;
    this.fileNameOrFolderNameErrorMessage = '';

    this.rename_contract.contract_title = renameFile;
    this.fileNameEditing = true;

    const params = {
      contract_title: this.rename_contract.contract_title,
      contract_id: this.rename_contract.contract_id
    };
    await DocumentRepository.renameContractFileName(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CHANGE_FILE_NAME
          });
          return;
        }

        Documents.update_document_info();
        await Documents.getRecentlyViewedFiles(
          this.selectedTabOnRecentlyViewed
        ).catch(err => {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
        });

        notify.success({
          text: NOTIFY_TEXT.SUCCESS.CHANGE_FILE_NAME
        });

        this.closeFileModal();
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.CHANGE_FILE_NAME
        });
      })
      .finally(() => {
        this.fileNameEditing = false;
      });
  }

  async updateFolderName(renameFolder) {
    if (this.rename_folder.directory_name === renameFolder) {
      this.closeFolderModal();
      return;
    }

    this.fileNameOrFolderNameErrorMessage = Validation.validateFileNameAndFolderName(
      renameFolder,
      true
    );
    if (this.fileNameOrFolderNameErrorMessage) return;
    this.fileNameOrFolderNameErrorMessage = '';
    this.folderNameEditing = true;

    const spaceReplacedFolderName: string = spaceReplaceUnderScore(
      renameFolder
    );
    this.rename_folder.directory_name = spaceReplacedFolderName;

    await DocumentRepository.renameContractFolderName(this.rename_folder)
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CHANGE_FOLDER_NAME
          });
          return;
        }
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.CHANGE_FOLDER_NAME
        });
        return;
      });

    await Documents.update_document_info();
    this.folderNameEditing = false;
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CHANGE_FOLDER_NAME
    });
    this.closeFolderModal();
  }

  async createAssociation(contracts) {
    const params = {
      select_contracts: contracts
    };

    this.approvingContract = true;
    await AssociationRepository.create(params)
      .then(async res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.RELATED_CONTRACT
          });
          return;
        }

        await AssociationsModule.getCandidates();
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.RELATED_CONTRACT
        });

        Documents.update_document_info();
        this.isShowCreateAssociation = false;
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.RELATED_CONTRACT
        });
      })
      .finally(() => {
        this.approvingContract = false;
      });
  }

  @Emit()
  openViewPermissionDialog() {
    return;
  }

  @Watch('current_directory')
  get_directories() {
    if (this.searching)
      return [{ name: `${this.objects.length}件の検索結果`, path: '' }];

    // TODO: 場当たり的な対応なので修正する
    let rootName = '';
    if (this.isRecentlyViewedPage) {
      rootName = '最近表示したファイル';
    } else {
      rootName = '締結済ドキュメント';
    }
    const directory_list = [
      {
        name: rootName,
        path: '/'
      }
    ];
    const del_prefix = this.current_directory.slice(1);
    let p = '';
    if (del_prefix.length > 0) {
      const splited_path = del_prefix.split('/');
      for (const i in splited_path) {
        p += '/' + splited_path[i];
        const tmp = {
          name: decodeURI(splited_path[i]),
          path: p
        };
        directory_list.push(tmp);
      }
    }
    return directory_list;
  }

  @Watch('side_bar_status')
  left_position() {
    let left = LAYOUT_UTIL.SIDE_MENU.WIDTH.OPEN;
    if (!this.side_bar_status) {
      left = LAYOUT_UTIL.SIDE_MENU.WIDTH.CLOSE;
    }
    return {
      left: String(left) + 'px'
    };
  }

  /**
   * ディレクトリと複数ファイル選択時のCSV出力を行う
   */
  async outputCSVs() {
    const selected_foleder_contract_id_list = Documents.selected_folders_of_table.map(
      folder => folder.directory_id
    );
    const selected_file_contract_id_list = Documents.selected_files_of_table.map(
      file => file.contract_id
    );

    const params = {
      directory_id_list: selected_foleder_contract_id_list,
      contract_id_list: selected_file_contract_id_list,
      is_signed: this.$route.name === 'contract_management' ? false : true,
    };

    await DocumentRepository.getCsvs(params)
      .then(response => {
        const blob = new Blob([response.data], { type: 'text/csv' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `LIRIS_${dayjs().format('YYYYMMDD')}.csv`;
        link.click();
        return;
      })
      .catch(() => {
        notify.error({
          text: 'CSV出力に失敗しました'
        });
      });
  }

  /**
   * 関連契約紐付ボタン押下時
   */
  openCreateAssociationModal() {
    this.associatedContracts = [];
    this.isShowCreateAssociation = true;
    AssociationsModule.getCandidates().then(() => {
      const selectedContractIds = Documents.selected_files_of_table.map(
        f => f.contract_id
      );
      this.associatedContracts = this.candidates.filter(c =>
        selectedContractIds.includes(c.contract_id)
      );
    });
  }

  /**
   * 資料添付ボタン押下時
   */
  async openAttachDocsModal() {
    const targetContract = Documents.selected_files_of_table[0];

    await AttachmentRepository.getAttachmentList(targetContract.contract_id)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: '添付ファイル数を確認できませんでした'
          });
          return;
        }

        this.alreadyAttachedFileLength = res.data.length;
        if (res.data.length < this.fileLimit) {
          this.is_showing_attachment_document_window = true;
        } else {
          notify.error({
            text: `添付ファイルがすでに${this.fileLimit}ファイルのため、これ以上資料添付できません`
          });
        }
      })
      .catch(e => {
        notify.error({
          text: '添付ファイル数を確認できませんでした'
        });
      });
  }

  /**
   * アップロードボタン押下時
   */
  clickUploadMenu(result) {
    HomeComponentInfoModule.change_upload_menu_switch(result);
  }

  /**
   * 新規フォルダボタン押下時
   */
  openNewFolderModal() {
    this.is_showing_create_new_folder_window = true;
  }

  closeCreateFolderWindow() {
    this.is_showing_create_new_folder_window = false;
    this.folderNameErrorMessage = '';
  }

  /**
   * 同名のフォルダがあるかどうかを判別する
   * this.createNewFolder で利用されている
   */
  isAlreadyExistFolder(folderName) {
    const folders = Documents.folders;
    for (const i in folders) {
      if (folders[i]) {
        if (folders[i].directory_name === folderName) {
          return true;
        }
      }
    }
    return false;
  }

  async createNewFolder(folderName): Promise<void> {
    if (this.isAlreadyExistFolder(folderName)) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.ALREADY_EXIST_FOLDER
      });
      return;
    }

    this.folderNameErrorMessage = Validation.validateFileNameAndFolderName(
      folderName,
      true
    );
    if (this.folderNameErrorMessage) return;
    this.folderNameErrorMessage = '';
    this.newFolderCreating = true;
    const current_directory = decodeURI(Documents.current_directory);
    let path = '';
    const spaceReplacedFolderName: string = spaceReplaceUnderScore(folderName);
    if (current_directory != '/') {
      path = current_directory + '/' + spaceReplacedFolderName;
    } else {
      path = current_directory + spaceReplacedFolderName;
    }
    const params = {
      new_directory_path: path
    };

    await DocumentRepository.createNewFolder(params)
      .then(res => {
        if (res.status !== 200) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.CREATED_FOLDER
          });
          return;
        }
        Documents.getFilesAndFolders();
        this.closeCreateFolderWindow();
        HomeComponentInfoModule.change_tables_countup();
        Documents.SET_SELECTED_FILES_OF_TABLE([]);
        Documents.SET_SELECTED_FOLDERS_OF_TABLE([]);
        notify.success({
          text: NOTIFY_TEXT.SUCCESS.CREATED_FOLDER
        });
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.CREATED_FOLDER
        });
      })
      .finally(() => {
        this.newFolderCreating = false;
      });
  }

  /**
   * 移動ボタン押下時
   */
  openMoveObjectWindow() {
    HomeComponentInfo.SET_MOVE_SELECTED_OBJECTS_WINDOW(true);
  }

  /**
   * 名前を変更ボタン押下時
   */
  openRenameModal() {
    if (this.num_files > 0) {
      this.rename_contract = { ...Documents.selected_files_of_table[0] };
      this.isShowingRenameFileModal = true;
    } else if (this.num_folders > 0) {
      this.rename_folder = { ...Documents.selected_folders_of_table[0] };
      this.isShowingRenameFolderModal = true;
    }
  }

  /**
   * 削除ボタン押下時
   */
  openDeleteObjectModal() {
    this.showDeleteObjectModal = true;
  }

  /**
   * バックアップメニュー押下時
   */
  async clickBackup() {
    const targetContract = Documents.selected_files_of_table[0];
    const result = FileBackup.downloadBackupFile(targetContract.contract_id);

    this.showBackupModal = false;

    if (!result)
      notify.error({
        text: NOTIFY_TEXT.ERROR.BACKUP_FILE
      });
    Documents.RESET_SELECTED_TABLE();
  }

  /**
   * バックアップメニュー押下時
   */
  openBackupModal() {
    this.showBackupModal = true;
  }

  /**
   * ダウンロードメニュー押下時
   */
  async downloadSelectedContracts() {
    const directory_id_list = Documents.selected_folders_of_table.map(
      folder => folder.directory_id
    );
    const contract_id_list = Documents.selected_files_of_table.map(
      file => file.contract_id
    );

    const params = {
      contract_id_list: contract_id_list,
      directory_id_list: directory_id_list
    };

    await Documents.postSelectedContractDownloadJob(params)
      .then(res => {
        this.showDownloadModal = false;
        notify.success({
          text:
            'ダウンロードの準備をしています。準備が完了次第、自動的にダウンロードを開始します。'
        });
        Documents.RESET_SELECTED_TABLE();
        // Fetch
        this.getDownloadInterval = setInterval(() => {
          this.getDownloadLink({
            job_id: this.downloadJobId
          });
        }, 10000);
      })
      .catch(error => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  get downloadJobId() {
    return Documents.selectedContractsDownloadJobId;
  }

  async getDownloadLink({ job_id }) {
    if (!this.downloadJobId) {
      return;
    }
    await TemplateInfoRepository.getDownloadLink({ job_id })
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
        if (res.data) {
          this.downloadLink = res.data;
          notify.clearSuccessWithoutCloseButton();
          clearInterval(this.getDownloadInterval);
          this.getDownloadInterval = null;
          this.downloadFile();
        }
        return;
      })
      .catch(e => {
        notify.clearSuccessWithoutCloseButton();
        clearInterval(this.getDownloadInterval);
        this.getDownloadInterval = null;
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
    return;
  }

  downloadFile() {
    if (this.downloadLink) {
      const link = document.createElement('a');
      link.href = this.downloadLink;
      link.click();
      this.downloadLink = '';
    }
  }
}
