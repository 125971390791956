











import { Component, Vue, Prop } from 'vue-property-decorator';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';

@Component({
  components: {
    TextAvatar
  }
})
export default class InfoAssigneeItem extends Vue {
  @Prop({ required: true }) user;
}
