import dayjs from 'dayjs';
import 'dayjs/locale/ja';
dayjs.locale('ja');

function dateFormatter(date: string) {
  if (date) {
    return dayjs(date).format('YYYY/M/D');
  } else {
    return '未設定';
  }
}

function dateFormatterForNotification(item) {
  const itemDate = dayjs(item);
  const today = dayjs();

  // dayjs の日数の diff は 24 時間単位で測るため、日付単位に戻す必要がある
  const diffDay = dayjs(today.format('YYYYMMDD')).diff(
    dayjs(itemDate.format('YYYYMMDD')),
    'day'
  );
  if (diffDay === 0) {
    return `今日 ${dayjs(item).format('HH:mm')}`;
  }
  if (diffDay === 1) {
    return `昨日 ${dayjs(item).format('HH:mm')}`;
  }
  if (diffDay < 7) {
    return `${diffDay}日前 ${dayjs(item).format('HH:mm')}`;
  }
  return dayjs(item).format('YYYY/MM/DD');
}

export { dateFormatter, dateFormatterForNotification };
