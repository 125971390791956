




































































































































































































































































































import AppCalendar from '@/components/atoms/AppCalendar.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import TextFormFlexibleWidth from '@/components/molecules/TextFormFlexibleWidth.vue';
import SelectLabels from '@/components/molecules/labels/SelectLabels.vue';
import DocumentInfoReferences from '@/components/organisms/DocumentDetail/DocumentInfo/DocumentInfoReferences.vue';
import InfoAutoUpdate from '@/components/organisms/DocumentDetail/DocumentInfo/InfoAutoUpdate.vue';
import InfoItem from '@/components/organisms/DocumentDetail/DocumentPreview/InfoItem.vue';
import InfoAssigneeItem from '@/components/organisms/DocumentDetail/InfoAssigneeItem.vue';
import ATTRIBUTES from '@/consts/Attributes';
import HTTP_STATUS from '@/consts/HttpStatus';
import LOADING_STATUSES from '@/consts/LoadingStatuses';
import NOTIFY_TEXT from '@/consts/NotifyText';
import DocumentFunction from '@/functions/DocumentFunction';
import FileDownload from '@/functions/FileDownload';
import notify from '@/functions/notify';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import ContractInfo from '@/store/ContractInfo';
import Documents from '@/store/Documents';
import SettingsInfoModule, {
AlertLimit,
AlertLimitType
} from '@/store/SettingsInfo';
import UserInfo from '@/store/UserInfo';
import dayjs from 'dayjs';
import { Component, Vue, Watch } from 'vue-property-decorator';

const ContractInfoRepository = RepositoryFactory.get('contractInfos');
const DocumentRepository = RepositoryFactory.get('documents');

interface Assignee {
  avatar_color: string;
  avatar_name: string;
  department_name: string;
  user_id: string;
  user_name: string;
}

@Component({
  components: {
    SelectBox,
    DocumentInfoReferences,
    InfoAssigneeItem,
    AppCalendar,
    InfoItem,
    InfoAutoUpdate,
    TextFormFlexibleWidth,
    SelectLabels
  }
})
export default class DocumentPreviewSidebarProperty extends Vue {
  isDisabledAssigneeSelect: boolean = false;
  selectableAssignees: Assignee[] = [];

  contractTransactionAmountInputType: string = 'text';

  savingStatuses: object = {
    assignee_user_id: LOADING_STATUSES.DEFAULT,
    task_expiration: LOADING_STATUSES.DEFAULT,
    control_number: LOADING_STATUSES.DEFAULT,
    contract_labels: LOADING_STATUSES.DEFAULT,
    contract_title: LOADING_STATUSES.DEFAULT,
    counterparty_name: LOADING_STATUSES.DEFAULT,
    contract_type_id: LOADING_STATUSES.DEFAULT,
    description: LOADING_STATUSES.DEFAULT,
    contract_date: LOADING_STATUSES.DEFAULT,
    contract_start_date: LOADING_STATUSES.DEFAULT,
    expiration: LOADING_STATUSES.DEFAULT,
    auto_update_rule: LOADING_STATUSES.DEFAULT,
    auto_update_date: LOADING_STATUSES.DEFAULT,
    transaction_amount: LOADING_STATUSES.DEFAULT
  };

  editDescription: boolean = false;
  editCustomAttribute: any = [];

  created() {
    this.initEditCustomAttribute();
  }

  mounted() {
    this.selectableAssignees = this.assignees.filter(
      x => x.user_id !== this.assignee.user_id
    );
    this.editDescription = false;
    this.initEditCustomAttribute();
  }

  initEditCustomAttribute() {
    this.editCustomAttribute = this.contractAttributes.map(attr => {
      return {
        id: attr.id,
        edit: false
      };
    });
  }

  falseEditCustomAttribute(id) {
    const targetIndex = this.editCustomAttribute.findIndex(
      editCustomAttribute => editCustomAttribute.id === id
    );
    this.editCustomAttribute[targetIndex].edit = false;
  }

  formatHrefLink(string) {
    const regex = /(https?:\/\/[\w\.\d:;?&=#/_+!$'()*@~%\-]+)/gm;
    const subst = `$1<a href="$1" target="_blank" rel="noopener noreferrer"><img id="openLinkIcon" src="/static/icons/external-link-blue.svg" style='width: 14px; margin: 0 8px;'></a>`;
    const result = string.replace(regex, subst);
    return result;
  }

  handleLinkClick(e) {
    const clickedElId = e.target.id
    if (clickedElId === 'openLinkIcon') {
      return true
    }
    return false
  }

  canEditAttribute(attr) {
    const targetIndex = this.editCustomAttribute.findIndex(
      editCustomAttribute => editCustomAttribute.id === attr.id
    );
    if (this.permission.can_edit_contract_info) {
      if (!attr.values[0].text) {
        this.editCustomAttribute[targetIndex].edit = true;
      }
    } else {
      this.editCustomAttribute[targetIndex].edit = false;
    }
    return this.editCustomAttribute[targetIndex].edit;
  }

  get canEditDescription() {
    if (this.permission.can_edit_contract_info) {
      if (!this.contractDescription) {
        this.editDescription = true;
      }
    } else {
      this.editDescription = false;
    }
    return this.editDescription;
  }

  clickDescription(e) {
    if (this.handleLinkClick(e)) {
      this.editDescription = false;
    } else {
      this.editDescription = true;
    }
  }

  clickAttribute(e, attr) {
    const targetIndex = this.editCustomAttribute.findIndex(
      editCustomAttribute => editCustomAttribute.id === attr.id
    );
    if (this.handleLinkClick(e)) {
      this.editCustomAttribute[targetIndex].edit = false;
    } else {
      this.editCustomAttribute[targetIndex].edit = true;
    }
  }

  get fileStatus() {
    return Documents.file_status;
  }

  get alreadyFetchContract() {
    return Documents.already_fetch_contract;
  }

  get versions() {
    const versions = this.contractDetail.contract_version_list;
    const lastestVersion = versions[0];
    if (lastestVersion && lastestVersion.is_last_version) {
      lastestVersion.version = '締結済';
    }
    return versions;
  }

  get isAlreadyAutoExtraction() {
    // 未作成の場合は自動抽出されたことにする
    if (!this.versions.length) {
      return true;
    }
    if (!this.fileStatus) {
      return false;
    }
    if (
      this.fileStatus &&
      (this.fileStatus.autoextraction_status === 0 ||
        this.fileStatus.autoextraction_status === 1)
    ) {
      return false;
    }
    if (!this.alreadyFetchContract) {
      return false;
    }
    return true;
  }

  selectedAssignee() {
    this.isDisabledAssigneeSelect = true;
    setTimeout(() => {
      this.isDisabledAssigneeSelect = false;
    }, 200);
  }

  @Watch('assignee')
  changeAssignee(assignee) {
    this.selectableAssignees = this.assignees.filter(
      x => x.user_id !== assignee.user_id
    );
  }

  get AlertLimitType() {
    return AlertLimitType;
  }

  get alertStatus(): AlertLimit {
    return SettingsInfoModule.alertStatus(
      this.contractInfo.expiration,
      this.contractInfo.auto_update_date,
      this.contractInfo.is_scheduled_end,
      this.contractInfo.is_ended
    );
  }

  get permission() {
    return UserInfo.permission;
  }

  get isAfterApprovale(): boolean {
    if (this.contractDetail.is_signed) return true;

    const kanban_card_info = this.contractDetail.kanban_card_info;
    if (!kanban_card_info) return false;

    const columnInfo = ContractInfo.getColumnById(kanban_card_info.column_id);
    const neededSignedFileColumn = ContractInfo.neededSignedFileColumn;
    if (!columnInfo || !neededSignedFileColumn) return false;

    return columnInfo.column_order >= neededSignedFileColumn.column_order;
  }

  get contractDetail() {
    return Documents.contract_detail;
  }

  get isSigned(): boolean {
    return Documents.isSigned;
  }

  get autoExtractData() {
    return this.contractDetail.auto_extractions;
  }

  get contractInfo() {
    if (!this.contractDetail) return null;
    return Documents.contract_detail.contract_info;
  }

  get task() {
    if (!this.contractDetail) return null;
    return Documents.contract_detail.kanban_card_info;
  }

  get selectedVersion() {
    return Documents.selectedVersion;
  }

  get assignees() {
    return ContractInfo.assigneesList;
  }

  get contractTypes() {
    return ContractInfo.boardContractTypes;
  }

  get contractAllLabels() {
    return Documents.contract_labels;
  }

  get task_expiration() {
    if (!this.task) return null;
    return this.task.task_expiration;
  }
  set task_expiration(date) {
    const task_expiration = dayjs(date).format('YYYY-MM-DD');
    Documents.SET_KANBAN_CARD_PROPS({ task_expiration });
    this.updateTask({ task_expiration });
  }

  get assignee() {
    if (!this.task) return this.assignees[0];
    return this.task.assignee;
  }
  set assignee(user) {
    Documents.SET_KANBAN_CARD_PROPS({ assignee: user });
    this.updateTask({ assignee_user_id: user.user_id });
  }

  get controlNumber() {
    return this.contractDetail.control_number;
  }
  set controlNumber(control_number) {
    Documents.SET_CONTRACT_DETAIL_PROPS({ control_number });
    this.updateContractDetail({ control_number });
  }

  get contractLabels() {
    return this.contractInfo.contract_labels || null;
  }
  set contractLabels(contract_labels) {
    Documents.SET_CONTRACT_INFO_PROPS({ contract_labels });
    const label_id_list = contract_labels.map(v => v.label_id);
    this.savingStatuses['contract_labels'] = LOADING_STATUSES.LOADING;
    DocumentRepository.updateContractLabels({
      contract_id: this.contractInfo.contract_id,
      label_id_list
    })
      .then(() => {
        this.savingStatuses['contract_labels'] = LOADING_STATUSES.COMPLETE;
      })
      .catch(e => {
        this.savingStatuses['contract_labels'] = LOADING_STATUSES.FAILURE;
      });
  }

  get contractTitle() {
    return this.contractInfo.contract_title;
  }
  set contractTitle(contract_title) {
    Documents.SET_CONTRACT_INFO_PROPS({ contract_title });
    this.updateContractDetail({ contract_title });
  }

  get counterpartyName() {
    return this.contractInfo.counterparty_name;
  }
  set counterpartyName(counterparty_name) {
    Documents.SET_CONTRACT_INFO_PROPS({ counterparty_name });
    this.updateContractDetail({ counterparty_name });
  }

  get neededSignedFileColumn() {
    return ContractInfo.neededSignedFileColumn;
  }

  // 契約種別
  get contractType() {
    return this.contractInfo.contract_type;
  }
  set contractType(contract_type) {
    if (!contract_type) {
      contract_type = {
        contract_type_id: null,
        contract_type_name: null
      };
      Documents.SET_CONTRACT_INFO_PROPS({ contract_type });
      this.updateContractDetail({
        contract_type_id: 'delete'
      });
    } else {
      Documents.SET_CONTRACT_INFO_PROPS({ contract_type });
      this.updateContractDetail({
        contract_type_id: contract_type.contract_type_id
      });
    }
  }

  // 契約締結日
  get contractDate() {
    return this.contractInfo.contract_date;
  }
  set contractDate(contract_date) {
    Documents.SET_CONTRACT_INFO_PROPS({ contract_date });
    this.updateContractDetail({
      contract_date: dayjs(contract_date).format('YYYY-MM-DD')
    });
  }

  // 契約開始日
  get contractStartDate() {
    return this.contractInfo.contract_start_date;
  }
  set contractStartDate(contract_start_date) {
    Documents.SET_CONTRACT_INFO_PROPS({ contract_start_date });
    this.updateContractDetail({
      contract_start_date: dayjs(contract_start_date).format('YYYY-MM-DD')
    });
  }

  // 契約終了日
  get expiration() {
    return this.contractInfo.expiration;
  }
  set expiration(expiration) {
    Documents.SET_CONTRACT_INFO_PROPS({ expiration });
    this.updateContractDetail({
      expiration: dayjs(expiration).format('YYYY-MM-DD')
    });
  }
  get isNoneExpiration(): boolean {
    return this.contractInfo.is_none_expiration;
  }
  set isNoneExpiration(is_none_expiration: boolean) {
    Documents.SET_CONTRACT_INFO_PROPS({ is_none_expiration });
    this.updateContractDetail({ is_none_expiration });
  }
  get isContractEnded(): boolean {
    return this.contractInfo.is_ended;
  }
  set isContractEnded(is_ended: boolean) {
    Documents.SET_CONTRACT_INFO_PROPS({ is_ended });
    this.updateContractDetail({ is_ended });
  }

  // 自動更新
  get autoUpdateRule() {
    if (!this.contractInfo.auto_update_rule) {
      return { is_active: false };
    }
    return this.contractInfo.auto_update_rule;
  }

  updateAutoUpdateRule(auto_update_rule) {
    this.updateContractDetail({ auto_update_rule });
  }

  // 更新期限日
  get autoUpdateDate() {
    return this.contractInfo.auto_update_date;
  }
  set autoUpdateDate(auto_update_date) {
    Documents.SET_CONTRACT_INFO_PROPS({ auto_update_date });
    this.updateContractDetail({
      auto_update_date: dayjs(auto_update_date).format('YYYY-MM-DD')
    });
  }

  // 取引内容
  get contractDescription() {
    return this.contractInfo.description === 'null'
      ? ''
      : this.contractInfo.description;
  }
  set contractDescription(contract_description) {
    Documents.SET_CONTRACT_INFO_PROPS({ description: contract_description });
    this.updateContractDetail({ description: contract_description });
  }

  // 取引金額
  get contractTransactionAmount() {
    let v = this.contractInfo.transaction_amount;
    if (this.contractTransactionAmountInputType === 'text' && v) {
      v = Number(v).toLocaleString();
    }
    if (
      this.contractTransactionAmountInputType === 'number' &&
      typeof v == 'string'
    ) {
      v = parseInt(v.replace(/,/g, ''), 10);
    }
    return v;
  }
  set contractTransactionAmount(transaction_amount) {
    if (!transaction_amount) {
      transaction_amount = 0;
    }
    Documents.SET_CONTRACT_INFO_PROPS({
      transaction_amount: transaction_amount
    });
  }

  isDisplayAutoExtract(autoExtract) {
    return DocumentFunction.isDisplayAutoExtract(autoExtract);
  }

  // カスタマイズ項目
  get contractAttributes() {
    if (this.contractInfo) {
      return this.contractInfo.attributes;
    }
    return [];
  }

  get ATTRIBUTE_TYPES() {
    return ATTRIBUTES.TYPES;
  }

  setSavingStatuses(keys, status) {
    keys.forEach(k => this.$set(this.savingStatuses, k, status));
  }

  async updateTask(params) {
    const paramKeys = Object.keys(params);
    this.setSavingStatuses(paramKeys, LOADING_STATUSES.LOADING);
    const res = await ContractInfoRepository.updateContractCardTask({
      card_id: this.task.card_id,
      ...params
    }).catch(e => {
      return e;
    });

    if (res.status !== HTTP_STATUS.NO_CONTENT) {
      this.setSavingStatuses(paramKeys, LOADING_STATUSES.FAILURE);
      return;
    }
    this.setSavingStatuses(paramKeys, LOADING_STATUSES.COMPLETE);
  }

  updateContractDetail(params) {
    this.hideStarIcon(params);
    const paramKeys = Object.keys(params);
    this.setSavingStatuses(paramKeys, LOADING_STATUSES.LOADING);
    DocumentRepository.updateContractDetail({
      contract_id: this.contractInfo.contract_id,
      ...params
    })
      .then(() => {
        this.setSavingStatuses(paramKeys, LOADING_STATUSES.COMPLETE);
      })
      .catch(e => {
        if (e.response.status === HTTP_STATUS.CONFLICT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.ALREADY_EXIST_CONTROL_NUMBER
          });
          return;
        }
        this.setSavingStatuses(paramKeys, LOADING_STATUSES.FAILURE);
        return;
      });
  }

  hideStarIcon(params) {
    // autoExtractData.x は0または1で星アイコンが表示されるため、編集して応答が返ってくるまで-1の値を格納
    if ('contract_title' in params) {
      this.autoExtractData.contract_title = -1;
    }
    if ('counterparty_name' in params) {
      this.autoExtractData.counter_party = -1;
    }
    if ('description' in params) {
      this.autoExtractData.description = -1;
    }
    if ('contract_date' in params) {
      this.autoExtractData.contract_date = -1;
    }
    if ('contract_start_date' in params) {
      this.autoExtractData.contract_start_date = -1;
    }
    if ('contract_date' in params) {
      this.autoExtractData.contract_date = -1;
    }
    if ('expiration' in params || 'is_none_expiration' in params) {
      this.autoExtractData.expiration = -1;
    }
    if ('auto_update_rule' in params) {
      this.autoExtractData.auto_update = -1;
    }
    if ('auto_update_date' in params) {
      this.autoExtractData.auto_update_date = -1;
    }
  }

  changedItem(event, targetObject) {
    this.updateItem(targetObject);
    this.editDescription = false;
  }

  updateItem(params) {
    // ドキュメント詳細の相手方の変更でnullの場合は空文字でリクエストする
    if ('counterparty_name' in params && params.counterparty_name === null) {
      params.counterparty_name = '';
    }
    if ('transaction_amount' in params && !params.transaction_amount) {
      params.transaction_amount = 0;
    }
    if ('transaction_amount' in params) {
      this.contractTransactionAmountInputType = 'text';
      if (typeof params.transaction_amount == 'string') {
        params.transaction_amount = parseInt(
          params.transaction_amount.replace(/,/g, ''),
          10
        );
      }
    }
    Documents.SET_CONTRACT_INFO_PROPS({ ...params });
    this.updateContractDetail(params);
  }

  updateContractDetailCustomAttributes(params) {
    const paramKeys = [params.contract_attribute_id];
    this.setSavingStatuses(paramKeys, LOADING_STATUSES.LOADING);
    DocumentRepository.postContractAttributeValue({
      contract_id: this.contractInfo.contract_id,
      ...params
    })
      .then(() => {
        this.setSavingStatuses(paramKeys, LOADING_STATUSES.COMPLETE);
      })
      .catch(e => {
        if (e.response.status === HTTP_STATUS.CONFLICT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.ALREADY_EXIST_CONTROL_NUMBER
          });
          return;
        }
        this.setSavingStatuses(paramKeys, LOADING_STATUSES.FAILURE);
        return;
      });
  }

  changedCustomAttributeSelectItem(targetObject, attributeId) {
    if (!targetObject) {
      this.updateCustomAttributeItem({
        contract_attribute_id: attributeId,
        contract_attribute_item_id: null,
        text: null,
        value: null,
        order: 0
      });
      return;
    }
    this.updateCustomAttributeItem({
      contract_attribute_id: targetObject.contract_attribute_id,
      contract_attribute_item_id: targetObject.id,
      text: targetObject.text,
      value: targetObject.text,
      order: 0
    });
  }

  changedCustomAttributeDatetimeItem(event, targetObject) {
    for (const [key, value] of Object.entries(targetObject)) {
      this.updateCustomAttributeItem({
        contract_attribute_id: key,
        text: value,
        datetime: new Date(value as string).toISOString(),
        value,
        order: 0
      });
    }
  }

  changedCustomAttributeTextItem(event, targetObject) {
    for (const [ key, value ] of Object.entries(targetObject)) {
      this.falseEditCustomAttribute(key);
      this.updateCustomAttributeItem({
        contract_attribute_id: key,
        text: value,
        value,
        order: 0
      });
    }
  }

  updateCustomAttributeItem(target) {
    Documents.SET_CONTRACT_INFO_CUSTOM_ATTRIBUTE_PROP(target);

    this.updateContractDetailCustomAttributes(target);
  }

  async downloadSignedFile() {
    const contractVersionId = this.selectedVersion.contract_version_id;
    const fileExtension = this.selectedVersion.file_extension;
    const filename = `${this.selectedVersion.file_name}.${fileExtension}`;

    const result = await FileDownload.downloadSignedFile(
      contractVersionId,
      filename
    );

    if (!result) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.GENERAL
      });
    }
  }
}
