

































































import { Component, Vue, Prop, PropSync } from 'vue-property-decorator';

import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import DocumentPreview from '@/components/organisms/GMOSign/DocumentPreview.vue';

import GMOSignInfoModule from '@/store/GMOSign';

import notify from '@/functions/notify';

import NOTIFY_TEXT from '@/consts/NotifyText';

import vLoading from 'vue-loading-overlay';

@Component({
  components: {
    AppButton,
    DocumentPreview,
    TextOnlyWindow,
    vLoading
  }
})
export default class GMOSignFormSignPosition extends Vue {
  items = [];
  contractId = null;
  isShowingModal = false;

  positions = [];
  tmp_positions = [];
  gmosignPositions = [];

  isDroppedOnIcon: boolean = false;
  ifdoc: any = null;
  el: any = null;
  widths = {
    viewer: 0,
    leftmenu: 0,
    canvas: 0,
    viewerPadding: 0
  };
  heights = {
    viewer: 0,
    pageHeader: 0,
    viewerHeader: 0,
    pageSpace: 25,
    canvas: 0
  };

  isLoading = false;

  @Prop({ default: 0 })
  headerHeight!: number;

  @PropSync('isDroppedHeader', { type: Boolean, default: false })
  noDropZone: boolean;

  @Prop({ default: '' })
  pdfLink!: string;

  async created() {
    this.contractId = this.$route.query.id;
    GMOSignInfoModule.SET_CONTRACT_ID(this.contractId);
    this.items = this.items.concat(this.our_signing_field);
    this.items = this.items.concat(this.partner_signing_field);
    this.sortItems();
    this.initPositions();
  }

  initPositions() {
    for (const i in this.items) {
      const init = {
        top: null,
        left: null,
        pageNum: 1
      };
      const tmp_init = {
        x: null,
        y: null
      };
      const signingInit = {
        signing_point_start_x: null,
        signing_point_start_y: null,
        signing_point_end_x: null,
        signing_point_end_y: null
      };
      this.positions.push(init);
      this.tmp_positions.push(tmp_init);
      this.gmosignPositions.push(signingInit);
    }
  }

  saveIconInitPosition(e, i) {
    const rect = e.target.getBoundingClientRect();
    this.tmp_positions[i].x = e.clientX - rect.left;
    this.tmp_positions[i].y = e.clientY - rect.top;
  }

  dropNow() {
    this.noDropZone = true;
  }

  changePosition(e, i) {
    if (this.noDropZone === true) {
      this.noDropZone = false;
      this.isDroppedOnIcon = false;
      return;
    }
    const el = document.getElementById('left-menu').getBoundingClientRect();
    this.widths.leftmenu = el.width;
    const biasX = this.isDroppedOnIcon ? 0 : el.right;
    const biasY = this.isDroppedOnIcon ? 0 : this.headerHeight;
    this.positions[i].left = biasX + e.clientX - this.tmp_positions[i].x + 'px';
    this.positions[i].top = biasY + e.clientY - this.tmp_positions[i].y + 'px';
    this.noDropZone = false;
    this.isDroppedOnIcon = false;

    // ページ番号
    const pageNum = this.ifdoc.getElementById('pageNumber').value;
    this.positions[i].pageNum = pageNum;

    this.calcSignPositions();
  }

  getPosition(i) {
    return this.positions[i];
  }

  sortItems() {
    this.items.sort((a, b) => a.order_no - b.order_no);
  }

  get signForm() {
    return GMOSignInfoModule.signForm;
  }

  get our_signing_field() {
    return GMOSignInfoModule.signForm.our_signing_field;
  }
  set our_signing_field(val) {
    GMOSignInfoModule.SET_OUR_SIGNING_FIELD(val);
  }

  get partner_signing_field() {
    return GMOSignInfoModule.signForm.partner_signing_field;
  }
  set partner_signing_field(val) {
    GMOSignInfoModule.SET_PARTNER_SIGNING_FIELD(val);
  }

  getIsOurSigner(item) {
    if ('signer_nm' in item) {
      return true;
    }
    return false;
  }

  getName(item) {
    if (this.getIsOurSigner(item) === true) {
      return item.signer_nm;
    }
    return item.name_nm;
  }

  getCorporationName(item) {
    if (this.getIsOurSigner(item) === true) {
      return this.signForm.own_organize_nm;
    }
    return item.organize_nm;
  }

  getEmail(item) {
    if (this.getIsOurSigner(item) === true) {
      return item.sign_user.email;
    }
    return item.email;
  }

  checkNoPositionAll() {
    let counter = 0;
    this.gmosignPositions.forEach(obj => {
      if (obj.signing_point_start_x === null) {
        counter += 1;
      }
    });
    if (counter === this.gmosignPositions.length) {
      return true;
    }
    return false;
  }

  checkNoPosition() {
    let isNonePoint = false;
    this.gmosignPositions.forEach(obj => {
      if (obj.signing_point_start_x === null) {
        isNonePoint = true;
      }
    });
    return isNonePoint;
  }

  closeModal() {
    this.isShowingModal = false;
  }

  calcSignPositions() {
    // PDFプレビュー上における相対位置をパーセンテージで計算
    // 署名アイコンの大きさ計算
    const signIconEl = document.getElementById('signIcon');
    const signIconRect = signIconEl.getBoundingClientRect();
    const signIconWidth = signIconRect.width;
    const signIconHeight = signIconRect.height;
    // iframe内で計算に必要な要素
    const rect = this.el.getBoundingClientRect();
    const viewerContainerWidth = rect.width;
    const viewerContainer = this.ifdoc.getElementById('viewerContainer');
    this.widths.viewer = viewerContainer.getBoundingClientRect().width;
    // console.log('widths.viewer: ' + this.widths.viewer);
    const canvas = this.ifdoc.getElementsByClassName('canvasWrapper');
    // プレビュー等の要素の幅を取得
    const canvasStyle = window
      .getComputedStyle(canvas[0])
      .getPropertyValue('width');
    this.widths.canvas = Number(canvasStyle.replace('px', ''));
    // console.log('widths.canvas: ' + this.widths.canvas);
    this.widths.viewerPadding =
      Math.abs(this.widths.viewer - this.widths.canvas) / 2;
    // console.log('widths.viewerPadding: ' + this.widths.viewerPadding);
    // プレビュー等の要素の高さを取得
    this.heights.pageHeader = this.headerHeight;
    this.heights.viewerHeader = this.ifdoc
      .getElementById('toolbarViewer')
      .getBoundingClientRect().height;
    const canvasHeightStyle = window
      .getComputedStyle(canvas[0])
      .getPropertyValue('height');
    this.heights.canvas = Number(canvasHeightStyle.replace('px', ''));

    for (const i in this.positions) {
      // ポジションがないものは処理しない
      if (this.positions[i].left === null) {
        continue;
      }
      // left計算
      const iconLeft = Number(this.positions[i].left.replace('px', ''));
      // console.log('iconLeft: ' + iconLeft);
      const inCanvasPositionLeft =
        iconLeft - (this.widths.leftmenu + this.widths.viewerPadding);
      // console.log('leftmenu: ' + this.widths.leftmenu);
      // console.log('inCanvasPositionLeft: ' + inCanvasPositionLeft);
      // top計算
      const tmpHeight =
        Number(this.positions[i].top.replace('px', '')) -
        this.heights.pageHeader -
        this.heights.viewerHeader;
      const canvasTotalHeight =
        (this.heights.canvas + this.heights.pageSpace) *
        this.positions[i].pageNum;
      const inCanvasPositionTop =
        this.heights.canvas -
        (canvasTotalHeight - viewerContainer.scrollTop - tmpHeight);
      // bottom計算
      const inCanvasPositionBottom = inCanvasPositionTop + signIconHeight;
      // right計算
      const inCanvasPositionRight = inCanvasPositionLeft + signIconWidth;

      // PDFプレビュー内の割合計算
      let inCanvasPositionTopRate = Math.round(
        (1 - inCanvasPositionTop / this.heights.canvas) * 100
      );
      if (inCanvasPositionTopRate > 100) {
        this.positions[i].pageNum = this.positions[i].pageNum - 1;
        inCanvasPositionTopRate = inCanvasPositionTopRate - 100;
      }
      // console.log('inCanvasPositionTopRate: ' + inCanvasPositionTopRate);

      let inCanvasPositionBottomRate = Math.round(
        (1 - inCanvasPositionBottom / this.heights.canvas) * 100
      );
      if (inCanvasPositionBottomRate > 100) {
        inCanvasPositionBottomRate = inCanvasPositionBottomRate - 100;
      }
      // console.log('inCanvasPositionBottomRate: ' + inCanvasPositionBottomRate);

      let inCanvasPositionLeftRate = Math.round(
        (inCanvasPositionLeft / this.widths.canvas) * 100
      );
      if (inCanvasPositionLeftRate > 100) {
        inCanvasPositionLeftRate = 100;
      }
      // console.log('inCanvasPositionLeftRate: ' + inCanvasPositionLeftRate);

      let inCanvasPositionRightRate = Math.round(
        (inCanvasPositionRight / this.widths.canvas) * 100
      );
      if (inCanvasPositionRightRate > 100) {
        inCanvasPositionRightRate = 100;
      }
      // console.log('inCanvasPositionRightRate: ' + inCanvasPositionRightRate);

      this.gmosignPositions[i].signing_point_start_x = inCanvasPositionLeftRate;
      this.gmosignPositions[
        i
      ].signing_point_start_y = inCanvasPositionBottomRate;
      this.gmosignPositions[i].signing_point_end_x = inCanvasPositionRightRate;
      this.gmosignPositions[i].signing_point_end_y = inCanvasPositionTopRate;
    }
  }

  async sendGMO() {
    if (this.checkNoPositionAll() === true) {
      this.isShowingModal = true;
      return;
    }
    if (this.checkNoPosition() === true) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.GMOSIGN.POSITION_ERROR
      });
      return;
    }
    this.calcSignPositions();
    // console.log(this.gmosignPositions);
    // ポジション情報をStoreに保存
    for (const i in this.items) {
      if (this.getIsOurSigner(this.items[i]) === true) {
        this.our_signing_field[0].signing_point_start_x = this.gmosignPositions[
          i
        ].signing_point_start_x;
        this.our_signing_field[0].signing_point_start_y = this.gmosignPositions[
          i
        ].signing_point_start_y;
        this.our_signing_field[0].signing_point_end_x = this.gmosignPositions[
          i
        ].signing_point_end_x;
        this.our_signing_field[0].signing_point_end_y = this.gmosignPositions[
          i
        ].signing_point_end_y;
        this.our_signing_field[0].page_no = this.positions[i].pageNum;
      } else {
        for (const j in this.partner_signing_field) {
          if (
            this.partner_signing_field[j].order_no === this.items[i].order_no
          ) {
            this.partner_signing_field[
              j
            ].signing_point_start_x = this.gmosignPositions[
              i
            ].signing_point_start_x;
            this.partner_signing_field[
              j
            ].signing_point_start_y = this.gmosignPositions[
              i
            ].signing_point_start_y;
            this.partner_signing_field[
              j
            ].signing_point_end_x = this.gmosignPositions[
              i
            ].signing_point_end_x;
            this.partner_signing_field[
              j
            ].signing_point_end_y = this.gmosignPositions[
              i
            ].signing_point_end_y;
            this.partner_signing_field[j].page_no = this.positions[i].pageNum;
          }
        }
      }
    }
    await this.next();
  }

  async next() {
    // 不可視署名かどうかの判定
    if (this.checkNoPositionAll() === false) {
      GMOSignInfoModule.SET_IS_INVISIBLED_SIGNATURE(false);
    }
    // 署名リクエスト
    this.isLoading = true;
    await GMOSignInfoModule.degitalSigning(this.signForm)
      .catch(err => {
        console.error(err.response);
        notify.error({ text: NOTIFY_TEXT.ERROR.GENERAL });
      })
      .finally(() => {
        this.isLoading = false;
      });
    this.$router.push({
      name: 'gmosign_form_completed',
      query: {
        id: this.contractId
      }
    });
  }
}
