













































import { Component, Vue, Prop } from 'vue-property-decorator';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

@Component({
  components: {
    BaseWindow,
    AppButton
  }
})
export default class DeleteTemplateWindow extends Vue {
  @Prop()
  value!: boolean;

  @Prop()
  windowSize!: string;

  @Prop()
  folderNames?: [];

  @Prop()
  fileNames?: [];

  @Prop()
  fileIds?: [];

  @Prop({ default: false })
  deleting: boolean;

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }

  get isOpen(): boolean {
    return this.value;
  }

  getFolderNameFromFolderPath(folderName) {
    return folderName.split('/').slice(-1)[0];
  }
}
