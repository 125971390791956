const axios = require('axios').default;

const resource = '/contract-management/signflow-management';

type UpdateRequestUserApplyToParams = {
  contract_board_id: string;
  user_id: string;
};

type UpdateContractCardStatusParams = {
  card_id: string;
  destination_column_id: string;
  is_finished: boolean;
  card_order?: number;
};

type UpdateContractCardTaskParams = {
  card_id: string;
  assignee_user_id: string;
  task_expiration: string;
  contract_id: string;
  contract_version_id: string;
};

type GetContractBoardDataParams = {
  keyword: string;
};

type KanbanStatusParams = {
  board_id: string;
  column_id?: string;
  column_name: string;
  column_order: number;
  can_create_card?: boolean;
  is_needed_signed_file: boolean;
};

export default {
  /**
   * 依頼先設定
   *
   * @param { Object } params
   */
  updateRequestUserApplyTo(params: UpdateRequestUserApplyToParams) {
    return axios.put(`${resource}/boards/${params.contract_board_id}`, {
      init_assignee_user_id: params.user_id
    });
  },

  /**
   * カードを作成
   *
   * @param { Blob } formData
   */
  createContractCard(formData: Blob) {
    const headers = { 'content-type': 'multipart/form-data' };

    return axios.post(`${resource}/cards`, formData, { headers });
  },

  /**
   * カードのステータスを更新
   *
   * @param { Object } params
   */
  updateContractCardStatus(params: UpdateContractCardStatusParams) {
    return axios.put(`${resource}/cards/${params.card_id}`, {
      destination_column_id: params.destination_column_id,
      is_finished: params.is_finished,
      card_order: params.card_order
    });
  },

  /**
   * カードのステータスを進める
   *
   * @param { String } cardId
   */
  cardNextStatus(cardId) {
    return axios.put(`${resource}/cards/${cardId}/next/status`);
  },

  /**
   * カードのステータスを戻す
   *
   * @param { String } cardId
   */
  cardPreStatus(cardId) {
    return axios.put(`${resource}/cards/${cardId}/pre/status`);
  },

  /**
   * カードの担当者、対応期限を更新
   *
   * @param { Object } params
   */
  updateContractCardTask(params: UpdateContractCardTaskParams) {
    return axios.put(`${resource}/cards/${params.card_id}/tasks`, {
      assignee_user_id: params.assignee_user_id,
      task_expiration: params.task_expiration,
      contract_id: params.contract_id,
      contract_version_id: params.contract_version_id
    });
  },

  /**
   * カンバンボードのカラム全体のデータを取得
   *
   * @param { Object } params
   */
  getContractBoardData(params: GetContractBoardDataParams) {
    return axios.get(`${resource}/boards/corporations/me`, { params });
  },

  /**
   * カンバンステータスの新規作成
   *
   * @param params
   */
  createKanbanColumn(params: KanbanStatusParams) {
    return axios.post(`${resource}/columns/`, params);
  },

  /**
   * カンバンステータスの編集
   *
   * @param params
   */
  updateKanbanColumn(params: KanbanStatusParams) {
    return axios.put(`${resource}/columns/`, params);
  },

  /**
   * カンバンステータスの編集
   *
   * @param column_id
   */
  deleteKanbanColumn(column_id: string) {
    return axios.delete(`${resource}/columns/${column_id}`);
  },

  /**
   * 依頼先一覧情報取得
   */
  getAssignees() {
    return axios.get(`${resource}/assignees`);
  },

  /**
   * カードのアーカイブ
   *
   * @param { String }  cardId
   * @param { Boolean }  isArchived
   */
  archiveCard(cardId, isArchived) {
    return axios.put(
      `${resource}/cards/${cardId}/archives?is_archived=${isArchived}`
    );
  }
};
