






















































































































































import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import vLoading from 'vue-loading-overlay';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import IconSvg from '@/components/atoms/icons/IconSvg.vue';
import SelectedFile from '@/components/organisms/SelectedFile.vue';
import DropdownMenuContainer from '@/components/molecules/DropdownMenuContainer.vue';
import MenuContent from '@/components/atoms/buttons/MenuContent.vue';
import AvatarInfoIcon from '@/components/molecules/avatar/AvatarInfoIcon.vue';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';
import dayjs from 'dayjs';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';

import Documents from '@/store/Documents';
import UserInfo from '@/store/UserInfo';
import CorporateInfoModule from '@/store/CorporateInfo';
import GMOSignInfoModule from '@/store/GMOSign';

import DeleteContractDetailCard from '@/components/molecules/modal_windows/DeleteContractDetailCard.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import ViewPermissionCard from '@/components/molecules/ViewPermissionCard.vue';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import WINDOW_MESSAGES from '@/consts/TextWindowMessage';

import notify from '@/functions/notify';
import FileBackup from '@/functions/FileBackup';
import FileDownload from '@/functions/FileDownload';
import { catchOnGetContractDetail } from '@/functions/DocumentRouter';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import { historyBackOrHome } from '@/functions/DocumentRouter';

const DocumentRepository = RepositoryFactory.get('documents');

@Component({
  components: {
    vLoading,
    DropdownMenuContainer,
    ViewPermissionCard,
    MenuContent,
    AppButton,
    IconSvg,
    SelectedFile,
    TextAvatar,
    AvatarInfoIcon,
    DeleteContractDetailCard,
    TextOnlyWindow
  }
})
export default class DocumentDetailHeader extends Vue {
  @Prop({ required: true })
  contractDetail: any;

  @Prop()
  currentDirectory!: string;

  @Prop()
  backToPath?: string;

  @Prop()
  isArchived!: boolean;

  DISPLAY_ASSIGNEES_NUM = 5;

  showingAssineesMore: boolean = false;
  showingAssineesMoreTimeoutId: number = NaN;
  isDisplayedPermissionModal: boolean = false;

  isDisplayDeleteContractModal: boolean = false;

  allVersionDelete: string = '1';
  showSelectDialog: boolean = false;
  showBackupModal: boolean = false;
  showDeleteGMOSignModal: boolean = false;
  backupModalDescription: string = WINDOW_MESSAGES.BACKUP_DESCRIPTION;
  deleteGMOSignModalDescription: string = WINDOW_MESSAGES.DELETE_GMOSIGN;

  deleteButtonName: string = '削除';
  deleteButtonSize: number = 96;
  deleteButtonDisabled: boolean = false;
  deleteModalText: string = '';

  viewable_user_list: any = [];
  viewable_department_list: any = [];
  isLoading: boolean = false;

  get permission() {
    return UserInfo.permission;
  }

  /**
   * $refsのTypescript Error回避
   * https://qiita.com/tsumasakky/items/03a4bdf74e3c765c2077
   */
  get refs(): any {
    return this.$refs;
  }

  /**
   * 閲覧権限設定の値を親コンポーネントにわたす
   */
  @Emit()
  selectViewPermissionData(data) {
    this.isDisplayedPermissionModal = false;
    return data;
  }

  get isLastVersion() {
    const versions = this.contractDetail.contract_version_list;
    if (versions) {
      return versions[0].is_last_version;
    }
    return false;
  }

  get version() {
    return Documents.selectedVersion;
  }

  get kanbanCardInfo() {
    return this.contractDetail.kanban_card_info;
  }

  get assignee() {
    if (this.kanbanCardInfo) {
      return this.kanbanCardInfo.assignee;
    }
    return null;
  }

  get created_by() {
    if (this.kanbanCardInfo) {
      return this.kanbanCardInfo.created_by;
    }
    return null;
  }

  get stakeholders(): any[] {
    if (!this.contractDetail) return [];
    const stakeholders = {};
    this.contractDetail.stakeholders.forEach(s => {
      if (stakeholders[s.user_id]) {
        stakeholders[s.user_id].role_name += `、${s.role_name}`;
        return;
      }
      stakeholders[s.user_id] = s;
    });
    return Object.keys(stakeholders).map(k => stakeholders[k]);
  }

  get displayStakeholders() {
    if (!this.stakeholders) return [];
    return this.stakeholders.slice(0, this.DISPLAY_ASSIGNEES_NUM);
  }

  get unDisplayAssignees() {
    return this.stakeholders.slice(this.DISPLAY_ASSIGNEES_NUM);
  }

  get fetchingContractDetail() {
    return Documents.fetchingContractDetail;
  }

  MENU_ACTION = {
    PERMISSION: '閲覧権限設定',
    DOWNLOAD: 'ダウンロード',
    GMOSIGN: 'GMOサインへアップロード',
    GMOSIGN_DELETE: 'GMOサインファイル削除',
    MOVE: '移動',
    OUTPUT_CSV: 'CSV出力',
    BACKUP: 'ファイルをバックアップ',
    ARCHIVE: 'アーカイブ',
    DELETE: '削除'
  };

  get isSigned(): boolean {
    return this.contractDetail.is_signed;
  }

  get selectedVersion() {
    return Documents.selectedVersion;
  }

  async menuSelect(val: string) {
    if (val === this.MENU_ACTION.ARCHIVE) {
      this.$emit('archived', true);
    }

    if (val === this.MENU_ACTION.PERMISSION) {
      Documents.SET_SELECTED_FILES_OF_TABLE([
        this.contractDetail.contract_info
      ]);
      this.isDisplayedPermissionModal = true;
      return;
    }

    if (val === this.MENU_ACTION.DOWNLOAD) {
      const contractVersionId = this.selectedVersion.contract_version_id;
      const fileExtension = this.selectedVersion.file_extension;
      const filename = `${this.selectedVersion.file_name}.${fileExtension}`;

      const result = await FileDownload.downloadSignedFile(
        contractVersionId,
        filename
      );

      if (!result) {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      }
      return;
    }

    if (val === this.MENU_ACTION.MOVE) {
      Documents.SET_SELECTED_FILES_OF_TABLE([
        this.contractDetail.contract_info.contract_id
      ]);
      HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW(true);
      return;
    }

    if (val === this.MENU_ACTION.OUTPUT_CSV) {
      const params = {
        contract_id_list: [ this.contractDetail.contract_info.contract_id ],
        is_signed: this.isSigned,
      };
      await DocumentRepository.getCsvs(params)
        .then(response => {
          const blob = new Blob([response.data], { type: 'text/csv' });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = `LIRIS_${dayjs().format('YYYYMMDD')}.csv`;
          link.click();
          return;
        })
        .catch(() => {
          notify.error({
            text: 'CSV出力に失敗しました'
          });
        });
    }

    if (val === this.MENU_ACTION.BACKUP) {
      this.showBackupModal = true;
    }

    if (val === this.MENU_ACTION.DELETE) {
      const SIGNED = '締結済';
      // 締結済みドキュメント
      if (this.isSigned) {
        this.deleteButtonName = '削除';
        this.deleteModalText = 'このファイルを削除しますか？';
        this.showSelectDialog = false;
      }
      // 締結管理 締結済み以外
      if (
        !this.isSigned &&
        this.version &&
        this.version.version &&
        this.version.version !== SIGNED
      ) {
        this.deleteButtonName = '選択した対象を削除';
        this.deleteModalText = '削除する対象を選んでください。';
        this.deleteButtonSize = 172;
        this.showSelectDialog = true;
      }
      // 締結管理 締結済み
      if (
        !this.isSigned &&
        this.version &&
        this.version.version &&
        this.version.version === SIGNED
      ) {
        this.deleteButtonName = '削除';
        this.deleteModalText =
          'すべてのバージョンを含むこのファイルを削除しますか？';
        this.showSelectDialog = false;
      }
      // 締結管理 未作成
      if (!this.isSigned && !this.version) {
        this.deleteButtonName = '削除';
        this.deleteModalText = 'このファイルを削除しますか？';
        this.showSelectDialog = false;
      }

      this.allVersionDelete = '1';
      this.isDisplayDeleteContractModal = true;
    }

    if (val === this.MENU_ACTION.GMOSIGN) {
      GMOSignInfoModule.SET_DOCUMENT_NAME(
        this.contractDetail.contract_info.contract_title
      );
      GMOSignInfoModule.SET_OWN_ORGANIZE_NM(CorporateInfoModule.corporate_name);
      this.$router.push({
        name: 'gmosign_form_contract',
        query: {
          id: this.contractDetail.contract_info.contract_id
        }
      });
    }

    if (val === this.MENU_ACTION.GMOSIGN_DELETE) {
      this.showDeleteGMOSignModal = true;
    }
  }

  clickBackup() {
    const result = FileBackup.downloadBackupFile(
      this.contractDetail.contract_info.contract_id
    );

    this.showBackupModal = false;

    if (!result)
      notify.error({
        text: NOTIFY_TEXT.ERROR.BACKUP_FILE
      });
  }

  get autoUpdateInfo() {
    const autoUpdateRule = this.contractDetail.contract_info.auto_update_rule;
    let autoUpdateInfo = 'なし';
    if (autoUpdateRule.is_active) {
      autoUpdateInfo = `${autoUpdateRule.pre_notice_number}${autoUpdateRule.pre_notice_unit}前に通知がなければ${autoUpdateRule.auto_update_number}${autoUpdateRule.auto_update_unit}間自動更新`;
    }
    return autoUpdateInfo;
  }

  async deleteContract() {
    this.deleteButtonDisabled = true;
    this.deleteButtonName = '削除中';
    // 削除確認モーダルにバージョンの選択肢がある場合の処理
    if (this.allVersionDelete !== '1') {
      const target_version = this.version.version.includes('.0')
        ? `V${parseInt(this.version.version)}`
        : this.version.version;

      await DocumentRepository.deleteVersion(this.version.contract_version_id)
        .then(res => {
          if (res.status !== HTTP_STATUS.NO_CONTENT) {
            this.isLoading = false;
            notify.error({
              text: `${target_version}の削除に失敗しました`
            });
            return;
          }
        })
        .catch(e => {
          this.isLoading = false;
          notify.error({
            text: `${target_version}の削除に失敗しました`
          });
          return;
        });

      await Documents.getContractDetail(this.$route.params.id).catch(err => {
        catchOnGetContractDetail(this.$router, err);
      });
      this.isDisplayDeleteContractModal = false;
      this.deleteButtonDisabled = false;
      this.deleteButtonName = '削除';
      const message = `${target_version}を削除しました`;
      notify.success({
        text: message
      });
      return;
    }

    // ドキュメントの削除の場合
    await DocumentRepository.deleteContract(this.$route.params['id'])
      .then(res => {
        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          this.isLoading = false;
          notify.error({
            text: NOTIFY_TEXT.ERROR.DELETE
          });
          return;
        }
      })
      .catch(e => {
        this.isLoading = false;
        notify.error({
          text: NOTIFY_TEXT.ERROR.DELETE
        });
        return;
      });

    const contract_id = this.$route.params.id;
    Documents.SET_TEMP_DELETED_FILES([
      {
        contract_id
      }
    ]);
    await Documents.getFilesAndFolders();
    this.isLoading = false;

    // 削除後、一覧画面へ遷移し取り消し操作を可能にするためstoreに状態を格納
    Documents.SET_IS_DELETE_CARD(true);

    this.back();
  }

  showAssineesMore() {
    clearTimeout(this.showingAssineesMoreTimeoutId);
    this.showingAssineesMore = true;
  }

  hideAssineesMore() {
    this.showingAssineesMoreTimeoutId = setTimeout(() => {
      this.showingAssineesMore = false;
    }, 500);
  }

  back() {
    Documents.SET_IS_BACK_PAGE(true);
    historyBackOrHome(
      this.$router,
      this.$route,
      this.isSigned,
      this.backToPath
    );
  }

  mounted() {
    window.addEventListener('popstate', function() {
      Documents.SET_IS_BACK_PAGE(true);
    });
  }

  beforeDestroy() {
    window.removeEventListener('popstate', function() {
      Documents.SET_IS_BACK_PAGE(true);
    });
  }

  async clickDeleteGMOSign() {
    const contract_id = this.contractDetail.contract_info.contract_id;
    await GMOSignInfoModule.deleteDocument(contract_id)
      .then(() => {
        Documents.DELETE_GMOSIGN();
        notify.success({ text: NOTIFY_TEXT.SUCCESS.GMOSIGN.DELETEDOCUMENT });
      })
      .catch(() => {
        notify.error({ text: NOTIFY_TEXT.ERROR.GMOSIGN.DELETEDOCUMENT });
      });
    this.showDeleteGMOSignModal = false;
  }
}
