


































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import UserInfoModule from '@/store/UserInfo';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import CreateAccountHeader from '@/components/molecules/settings_headers/CreateAccountHeader.vue';

import HTTP_STATUS from '@/consts/HttpStatus';

import NOTIFY_TEXT from '@/consts/NotifyText';
import vLoading from 'vue-loading-overlay';
import notify from '@/functions/notify';
import {
  validateCorporateTelNumber,
  validateEmail,
  validateEmpty,
  validateZipCode
} from '@/functions/Validation';

import {
  CreateAccountInterface,
  CreateAccountErrorInterface,
  ServicePlanInterface
} from '@/interfaces/Account';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const AccountRepository = RepositoryFactory.get('accounts');
const UserInfoRepository = RepositoryFactory.get('userInfos');

@Component({
  components: {
    AppButton,
    TextForm,
    SelectBox,
    CreateAccountHeader,
    vLoading
  }
})
export default class CreateAccount extends Vue {
  private form: CreateAccountInterface = null;
  private errors: CreateAccountErrorInterface = null;

  // サービスプラン一覧
  private servicePlanList: ServicePlanInterface[] = [];

  private isLoading: boolean = false;

  // 郵便番号の変更を監視
  @Watch('form.postal_code')
  changePostalCode(postal_code) {
    if (validateZipCode(postal_code)) {
      return;
    }
    this.fetchAddress(postal_code);
  }

  async created() {
    await this.load();
  }

  /**
   * 初期化
   */
  private async load() {
    this.isLoading = true;

    this.initialForm();
    this.initialErrors();
    this.fetchServicePlanList();

    this.isLoading = false;
  }

  /**
   * フォームの初期化
   */
  private initialForm() {
    this.form = {
      name: '',
      email: '',
      days: null,
      service_plan: {
        id: '974cbb2d9954491996c267e118b734f2',
        service_plan: 'スタンダード'
      },
      service_plan_id: '974cbb2d9954491996c267e118b734f2',
      corporation_name: '',
      furigana: '',
      postal_code: null,
      address: '',
      phone_number: null,
      liris_admin: false
    };
  }

  /**
   * フォームのエラーの初期化
   */
  private initialErrors() {
    this.errors = {
      name: '',
      email: '',
      days: '',
      service_plan_id: '',
      corporation_name: '',
      furigana: '',
      postal_code: '',
      address: '',
      phone_number: ''
    };
  }

  /**
   * ボタンの非活性制御
   */
  get isDisabled() {
    if (
      this.form.name &&
      this.form.email &&
      this.form.days &&
      this.form.service_plan_id &&
      this.form.corporation_name &&
      this.form.furigana &&
      this.form.postal_code &&
      this.form.address &&
      this.form.phone_number
    ) {
      return false;
    }
    return true;
  }

  /**
   * サービスプラン一覧を取得
   */
  private async fetchServicePlanList() {
    const idToken = await UserInfoModule.getIdToken();

    // サービスプランを取得する
    await AccountRepository.fetchServicePlanList(idToken)
      .then(response => {
        this.servicePlanList = response.data.map(servicePlan => {
          return {
            id: servicePlan.service_plan_id,
            service_plan: servicePlan.service_plan
          };
        });
      })
      .catch(() => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  /**
   * 郵便番号から住所取得し、住所にセット
   *
   * @param { string } postal_code
   */
  private async fetchAddress(postal_code: string) {
    await UserInfoRepository.getAddressFromZipcode(postal_code)
      .then(response => {
        if (response.status !== HTTP_STATUS.OK) {
          return;
        }
        this.form.address = response.data;
      })
      .catch(() => {});
  }

  private selectedServicePlan(servicePlan: ServicePlanInterface) {
    this.form.service_plan_id = servicePlan.id;
  }

  /**
   * 新規アカウントを作成しメールを送信する
   */
  private async send_email() {
    this.initialErrors();

    let hasError: boolean = false;

    // バリデーション
    this.errors.name = validateEmpty(this.form.name, '氏名');
    this.errors.email = validateEmail(this.form.email);
    this.errors.service_plan_id = validateEmpty(
      this.form.service_plan_id,
      'サービスプラン'
    );
    this.errors.corporation_name = validateEmpty(
      this.form.corporation_name,
      '会社名'
    );
    this.errors.furigana = validateEmpty(this.form.furigana, 'フリガナ');
    this.errors.postal_code = validateZipCode(this.form.postal_code);
    this.errors.address = validateEmpty(this.form.address, '住所');
    this.errors.phone_number = validateCorporateTelNumber(
      this.form.phone_number
    );

    // エラーがひとつでもある場合はバリデーションエラー
    hasError = Object.values(this.errors).some((error: string) => {
      return error;
    });

    if (hasError) {
      return;
    }

    this.isLoading = true;

    // バリデーションエラーがない時に新規アカウントを作成しメールを送信する
    const idToken = await UserInfoModule.getIdToken();

    await AccountRepository.create(this.form, idToken)
      .then(() => {
        notify.success({
          text: '新規アカウントを作成いたしました'
        });
        this.load();
      })
      .catch(err => {
        if (err.response.status !== HTTP_STATUS.OK)
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
      });

    this.isLoading = false;
  }
}
