














import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import UserInfoModule from '@/store/UserInfo';

@Component
export default class TextFormMidSize_Password extends Vue {
  text: string = '';
  show: string = '表示';
  type: string = 'password';

  @Prop()
  inputId?: string;
  @Prop()
  placeholder?: string;
  @Prop({ default: 128 })
  maxlength?: number;
  @Prop()
  classes?: object;
  @Prop()
  value?: string;
  @Prop({ default: true })
  isNeededToCheck?: boolean;

  mounted() {
    this.text = '';
    this.show = '表示';
    this.type = 'password';
  }

  @Watch('value')
  changeValue(value) {
    this.text = value;
  }

  @Watch('text')
  syncUserInfo(value: string) {
    if (this.isNeededToCheck) {
      UserInfoModule.SET_PASSWORD(value);
    }
  }

  change_type() {
    if (this.show == '表示' && this.type == 'password') {
      this.show = '非表示';
      this.type = 'text';
    } else {
      this.show = '表示';
      this.type = 'password';
    }
  }
}
