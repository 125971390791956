



























































































import { Component, Vue, Emit, Prop } from 'vue-property-decorator';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';

import AppButton from '@/components/atoms/buttons/AppButton.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';

import SettingsInfoModule from '@/store/SettingsInfo';

@Component({
  components: {
    AppButton,
    TextForm,
    SelectBox,
    BaseWindow
  }
})
export default class AddMemberWindow extends Vue {
  @Prop()
  invitations: any[];

  @Prop()
  windowSize!: string;

  @Prop({ default: false })
  isWhiteSpace?: boolean;

  @Prop()
  isLoading: boolean;

  @Prop(Array)
  departments: any[];

  @Prop(Array)
  authorities: any[];

  @Prop(Boolean)
  value: boolean;

  get isOpenWindow(): boolean {
    return this.value;
  }

  set isOpenWindow(isOpenWindow) {
    this.$emit('input', isOpenWindow);
  }

  validateAll() {
    return this.invitations.every(f => {
      return f.email !== '' && f.department && f.authority;
    });
  }

  @Emit('inputEmail')
  inputEmail(index, email) {
    return {
      index,
      email
    };
  }

  @Emit('setDepartment')
  setDepartment(index, department) {
    return {
      index,
      department
    };
  }

  @Emit('setUserAuthority')
  setUserAuthority(index, authority) {
    return {
      index,
      authority
    };
  }

  @Emit('removeUser')
  removeUser(index) {
    return index;
  }

  changeWindowStatus(val) {
    this.$emit('changeWindowStatus');
  }

  /**
   * 権限説明モーダルを開く
   */
  openExplanationWindow() {
    SettingsInfoModule.SET_ADD_EXPLANATION_AUTHORITY_WINDOW(true);
  }
}
