import store from '@/store';
import Vue from 'vue';
import VueCookies from 'vue-cookies-ts';
import Vuex from 'vuex';
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';
import Regex from '../consts';
const axios = require('axios').default;

import { GMOSignUserInfo } from '@/interfaces/GMOSign';

import Authorities from '@/consts/Authorities';
import HTTP_STATUS from '@/consts/HttpStatus';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import CorporateInfo from '@/store/CorporateInfo';

const SettingRepository = RepositoryFactory.get('settings');
const UserInfoRepository = RepositoryFactory.get('userInfos');

Vue.use(VueCookies);
Vue.use(Vuex);

interface Avatar {
  avatar_name?: string;
  avatar_color?: string;
}

interface Permission {
  can_setting_account?: boolean; // 自己のアカウント設定
  can_setting_company_info?: boolean; // 会社基本情報設定
  can_view_user?: boolean; // ユーザー閲覧
  can_add_user?: boolean; // ユーザー追加
  can_delete_user?: boolean; // ユーザー削除
  can_change_permission?: boolean; // ユーザーの権限変更
  can_add_delete_department?: boolean; // 部署管理・追加・削除
  can_setting_alert?: boolean; // 契約終了・自動更新アラート設定
  can_view_service_plan?: boolean; // サービスプラン閲覧
  can_create_new_folder?: boolean; // 新規フォルダ作成
  can_view_folder?: boolean; // フォルダ閲覧
  can_edit_folder_name?: boolean; // フォルダ名編集
  can_delete_move_folder?: boolean; // フォルダ削除・移動
  can_view_file?: boolean; // ファイル閲覧
  can_upload_file?: boolean; // ファイルアップロード
  can_version_up_file?: boolean; // ファイルバージョンアップ
  can_edit_file_info?: boolean; // ファイル情報の編集
  can_delete_move_download_file?: boolean; // ファイル削除・移動・ダウンロード
  can_delete_unsigned_file?: boolean; // 締結管理中のドキュメントの削除
  can_output_csv_file?: boolean; // ファイル情報のCSV出力
  can_backup_file?: boolean; // ファイルのバックアップ
  can_add_delete_permission?: boolean; // 閲覧権限付与・削除
  can_related_contract?: boolean; // 関連契約紐付・紐付解除
  can_attached_file?: boolean; // 資料添付・資料削除
  can_post_delete_comment?: boolean; // コメント・コメント削除
  can_setting_contract_number?: boolean; // 契約書作成・確認依頼カードの作成
  can_create_contract?: boolean; // 契約書作成・確認依頼カードの作成
  can_edit_contract_info?: boolean; // 契約書作成・確認依頼カード情報の編集
  can_post_delete_contract_comment?: boolean; // 契約書作成・確認依頼カードへのコメント・コメント削除
  can_archive_contract_status?: boolean; // 契約書のアーカイブ
  can_move_delete_contract_status?: boolean; // 契約書作成・確認依頼カードのステータス移動・削除
  can_operation_folder_own_template?: boolean; // 自社契約書テンプレートの新規フォルダ作成・移動・名前変更・削除
  can_operation_file_own_template?: boolean; // 自社契約書テンプレートファイルのアップロード・ダウンロード・移動・名前変更・削除
  can_download_folder_file_liris_template?: boolean; // LIRIS契約書テンプレートフォルダとファイルのダウンロード
  can_operation_folder_file_liris_template?: boolean; // LIRIS契約書テンプレートフォルダとファイルの移動・名前変更・削除
  can_empty_trash?: boolean; // ゴミ箱を空にする
  can_restore_trash?: boolean; // ゴミ箱から復元
  can_permanently_deleted_trash?: boolean; // ゴミ箱から完全に削除
  can_contracted_document_version_upload?: boolean; // 締結済みドキュメント画面で新しいバージョンをアップロード
  can_edit_labels?: boolean; // ラベルの追加・編集
  can_edit_alert?: boolean; // アラートの設定
  can_edit_contract_attributes?: boolean; // カスタマイズ項目の追加・編集
  can_edit_column?: boolean; // 締結管理のステータス編集、追加、削除
  can_gmosign?: boolean; // GMOサインの機能が使えるかどうか
  can_gmosign_sign?: boolean; // GMOサインで署名できるユーザーかどうか
}

@Module({ dynamic: true, store: store, name: 'user_info', namespaced: true })
export class UserInfo extends VuexModule {
  // TODO: ログインしたときと、初回登録完了時にユーザー名、メールアドレス、所属を保持する。
  user_id: string = '';
  is_required_changing_password: boolean = false;
  email: string = '';
  password: string = '';
  one_time_password: string = '';
  authenticated: boolean = false;
  user_name: string = '';
  department_name: string = '';
  department:
    | string
    | {
      department_id?: string;
      department_name?: string;
    } = {};
  // authority不要かも。要確認
  authority: string = '';
  isAdmin: boolean = false;
  isOwner: boolean = false;
  isNormal: boolean = !(this.isAdmin || this.isOwner);
  avatar: Avatar = {};

  is_email: boolean = false;

  pw_length: number = this.password.length;
  pw_has_lower_case: boolean = false;
  pw_has_upper_case: boolean = false;
  pw_has_number: boolean = false;
  can_submit_password: boolean = false;
  is_showing_extraction_dialog: boolean = true;

  permission: Permission = {};

  gmosign_user_info: GMOSignUserInfo = {};

  slack_member_id: string = null;
  slack_notification: boolean = false;
  is_cooperated_slack: boolean = false;

  @Action({ rawError: true })
  getIdToken(): string {
    // Cookieから取得する
    const idToken = Vue.cookies.get('id-token').toString();
    return idToken;
  }

  @Action({ rawError: true })
  refreshIdToken() {
    UserInfoRepository.refreshIdToken()
      .then(res => {
        const idToken = res.data.id_token;
        this.SET_ID_TOKEN(idToken);
      })
      .catch(err => {
        console.log(err);
      });
  }

  @Mutation
  SET_ID_TOKEN(idToken: string) {
    // Cookieに保存, expireTimes = 1day
    Vue.cookies.set('id-token', idToken, { expires: 60 * 60 * 24 });
  }

  @Mutation
  SET_GMOSIGN_USER_INFO(user: GMOSignUserInfo) {
    this.gmosign_user_info = user;
    if (this.gmosign_user_info.gmosign_user_name) {
      this.permission.can_gmosign = true;
    }
    if (this.gmosign_user_info.is_gmosign_sign_role === true) {
      this.permission.can_gmosign_sign = true;
    }
  }

  @Mutation
  SET_SLACK_NOTIFICATION(slack_notification: boolean) {
    this.slack_notification = slack_notification;
  }

  @Mutation
  SET_IS_COOPERATED_SLACK(is_cooperated_slack: boolean) {
    this.is_cooperated_slack = is_cooperated_slack;
  }

  @Action({ rawError: true })
  async get_user_info() {
    const idToken = await this.getIdToken();

    return await SettingRepository.getUserInfo(idToken)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          return Promise.reject(res);
        }
        const data = res.data;
        this.SET_USER_ID(data.user_id);
        this.SET_ID_TOKEN(data.id_token);
        this.SET_DEPARTMENT_NAME(data.department.department_name);
        this.SET_DEPARTMENT(data.department);
        this.SET_USER_NAME(data.user_name);
        this.SET_EMAIL(data.email);
        this.SET_AUTHORITY(data);
        this.SET_REQUIRED_CHANGE_PASSWORD(data.is_required_changing_password);
        this.SET_AVATAR(data.avatar);
        this.SET_SHOWS_EXTRACT_DIALOG(data.is_showing_extraction_dialog);
        this.SET_GMOSIGN_USER_INFO(data.gmosign);
        this.SET_SLACK_NOTIFICATION(data.slack_notification);
        this.SET_IS_COOPERATED_SLACK(data.is_cooperated_slack);
        CorporateInfo.SET_CORPORATION_ID(data.corporation_id);
        if (data.permission.permission_default_name === Authorities.OWNER) {
          this.permission.can_setting_account = true; // 自己のアカウント設定
          this.permission.can_setting_company_info = true; // 会社基本情報設定
          this.permission.can_view_user = true; // ユーザー閲覧
          this.permission.can_add_user = true; // ユーザー追加
          this.permission.can_delete_user = true; // ユーザー削除
          this.permission.can_change_permission = true; // ユーザーの権限変更
          this.permission.can_add_delete_department = true; // 部署管理・追加・削除
          this.permission.can_setting_alert = true; // 契約終了・自動更新アラート設定
          this.permission.can_view_service_plan = true; // サービスプラン閲覧
          this.permission.can_create_new_folder = true; // 新規フォルダ作成
          this.permission.can_view_folder = true; // フォルダ閲覧
          this.permission.can_edit_folder_name = true; // フォルダ名編集
          this.permission.can_delete_move_folder = true; // フォルダ削除・移動
          this.permission.can_view_file = true; // ファイル閲覧
          this.permission.can_upload_file = true; // ファイルアップロード
          this.permission.can_version_up_file = true; // ファイルバージョンアップ
          this.permission.can_edit_file_info = true; // ファイル情報の編集
          this.permission.can_delete_move_download_file = true; // ファイル削除・移動・ダウンロード
          this.permission.can_output_csv_file = true; // ファイル情報のCSV出力
          this.permission.can_backup_file = true; // ファイルのバックアップ
          this.permission.can_add_delete_permission = true; // 閲覧権限付与・削除
          this.permission.can_related_contract = !CorporateInfo.isSmallPlan; // 関連契約紐付・紐付解除
          this.permission.can_attached_file = true; // 資料添付・資料削除
          this.permission.can_post_delete_comment = true; // コメント・コメント削除
          this.permission.can_setting_contract_number = true; // 契約書No設定
          this.permission.can_create_contract = true; // 契約書作成・確認依頼カードの作成
          this.permission.can_edit_contract_info = true; // 契約書作成・確認依頼カード情報の編集
          this.permission.can_post_delete_contract_comment = true; // 契約書作成・確認依頼カードへのコメント・コメント削除
          this.permission.can_archive_contract_status = true; // 契約書のアーカイブ
          this.permission.can_move_delete_contract_status = true; // 契約書作成・確認依頼カードのステータス移動・削除
          this.permission.can_operation_folder_own_template = true; // 自社契約書テンプレートの新規フォルダ作成・移動・名前変更・削除
          this.permission.can_operation_file_own_template = true; // 自社契約書テンプレートファイルのアップロード・ダウンロード・移動・名前変更・削除
          this.permission.can_download_folder_file_liris_template = true; // LIRIS契約書テンプレートフォルダとファイルのダウンロード
          this.permission.can_operation_folder_file_liris_template = false; // LIRIS契約書テンプレートフォルダとファイルの移動・名前変更・削除
          this.permission.can_empty_trash = true; // ゴミ箱を空にする
          this.permission.can_restore_trash = true; // ゴミ箱から復元
          this.permission.can_permanently_deleted_trash = true; // ゴミ箱から完全に削除
          this.permission.can_delete_unsigned_file = true; // 締結管理中のドキュメントの削除
          this.permission.can_contracted_document_version_upload = true; // 締結済みドキュメント画面で新しいバージョンをアップロード
          this.permission.can_edit_labels = true; // ラベルの追加・編集
          this.permission.can_edit_alert = true; // アラートの設定
          this.permission.can_edit_contract_attributes = true; // カスタマイズ項目の追加・編集
          this.permission.can_edit_column = true; // 締結管理のステータス編集、追加、削除
        }

        if (
          data.permission.permission_default_name === Authorities.ADMINISTRATOR
        ) {
          this.permission.can_setting_account = true; // 自己のアカウント設定
          this.permission.can_setting_company_info = true; // 会社基本情報設定
          this.permission.can_view_user = true; // ユーザー閲覧
          this.permission.can_add_user = true; // ユーザー追加
          this.permission.can_delete_user = true; // ユーザー削除
          this.permission.can_change_permission = true; // ユーザーの権限変更
          this.permission.can_add_delete_department = true; // 部署管理・追加・削除
          this.permission.can_setting_alert = true; // 契約終了・自動更新アラート設定
          this.permission.can_view_service_plan = false; // サービスプラン閲覧
          this.permission.can_create_new_folder = true; // 新規フォルダ作成
          this.permission.can_view_folder = true; // フォルダ閲覧
          this.permission.can_edit_folder_name = true; // フォルダ名編集
          this.permission.can_delete_move_folder = true; // フォルダ削除・移動
          this.permission.can_view_file = true; // ファイル閲覧
          this.permission.can_upload_file = true; // ファイルアップロード
          this.permission.can_version_up_file = true; // ファイルバージョンアップ
          this.permission.can_edit_file_info = true; // ファイル情報の編集
          this.permission.can_delete_move_download_file = true; // ファイル削除・移動・ダウンロード
          this.permission.can_output_csv_file = true; // ファイル情報のCSV出力
          this.permission.can_backup_file = true; // ファイルのバックアップ
          this.permission.can_add_delete_permission = true; // 閲覧権限付与・削除
          this.permission.can_related_contract = !CorporateInfo.isSmallPlan; // 関連契約紐付・紐付解除
          this.permission.can_attached_file = true; // 資料添付・資料削除
          this.permission.can_post_delete_comment = true; // コメント・コメント削除
          this.permission.can_setting_contract_number = true; // 契約書No設定
          this.permission.can_create_contract = true; // 契約書作成・確認依頼カードの作成
          this.permission.can_edit_contract_info = true; // 契約書作成・確認依頼カード情報の編集
          this.permission.can_post_delete_contract_comment = true; // 契約書作成・確認依頼カードへのコメント・コメント削除
          this.permission.can_archive_contract_status = true; // 契約書のアーカイブ
          this.permission.can_move_delete_contract_status = true; // 契約書作成・確認依頼カードのステータス移動・削除
          this.permission.can_operation_folder_own_template = true; // 自社契約書テンプレートの新規フォルダ作成・移動・名前変更・削除
          this.permission.can_operation_file_own_template = true; // 自社契約書テンプレートファイルのアップロード・ダウンロード・移動・名前変更・削除
          this.permission.can_download_folder_file_liris_template = true; // LIRIS契約書テンプレートフォルダとファイルのダウンロード
          this.permission.can_operation_folder_file_liris_template = false; // LIRIS契約書テンプレートフォルダとファイルの移動・名前変更・削除
          this.permission.can_empty_trash = true; // ゴミ箱を空にする
          this.permission.can_restore_trash = true; // ゴミ箱から復元
          this.permission.can_permanently_deleted_trash = true; // ゴミ箱から完全に削除
          this.permission.can_delete_unsigned_file = true; // 締結管理中のドキュメントの削除
          this.permission.can_contracted_document_version_upload = true; // 締結済みドキュメント画面で新しいバージョンをアップロード
          this.permission.can_edit_labels = true; // ラベルの追加・編集
          this.permission.can_edit_alert = true; // アラートの設定
          this.permission.can_edit_contract_attributes = true; // カスタマイズ項目の追加・編集
          this.permission.can_edit_column = true; // 締結管理のステータス編集、追加、削除
        }

        if (
          data.permission.permission_default_name ===
          Authorities.GENERA_MOVEABLE_CARD
        ) {
          this.permission.can_setting_account = true; // 自己のアカウント設定
          this.permission.can_setting_company_info = false; // 会社基本情報設定
          this.permission.can_view_user = false; // ユーザー閲覧
          this.permission.can_add_user = false; // ユーザー追加
          this.permission.can_delete_user = false; // ユーザー削除
          this.permission.can_change_permission = false; // ユーザーの権限変更
          this.permission.can_add_delete_department = false; // 部署管理・追加・削除
          this.permission.can_setting_alert = false; // 契約終了・自動更新アラート設定
          this.permission.can_view_service_plan = false; // サービスプラン閲覧
          this.permission.can_create_new_folder = true; // 新規フォルダ作成
          this.permission.can_view_folder = true; // フォルダ閲覧
          this.permission.can_edit_folder_name = true; // フォルダ名編集
          this.permission.can_delete_move_folder = true; // フォルダ削除・移動
          this.permission.can_view_file = true; // ファイル閲覧
          this.permission.can_upload_file = true; // ファイルアップロード
          this.permission.can_version_up_file = true; // ファイルバージョンアップ
          this.permission.can_edit_file_info = true; // ファイル情報の編集
          this.permission.can_delete_move_download_file = true; // ファイル削除・移動・ダウンロード
          this.permission.can_output_csv_file = true; // ファイル情報のCSV出力
          this.permission.can_backup_file = false; // ファイルのバックアップ
          this.permission.can_add_delete_permission = true; // 閲覧権限付与・削除
          this.permission.can_related_contract = !CorporateInfo.isSmallPlan; // 関連契約紐付・紐付解除
          this.permission.can_attached_file = true; // 資料添付・資料削除
          this.permission.can_post_delete_comment = true; // コメント・コメント削除
          this.permission.can_setting_contract_number = false; // 契約書No設定
          this.permission.can_create_contract = true; // 契約書作成・確認依頼カードの作成
          this.permission.can_edit_contract_info = true; // 契約書作成・確認依頼カード情報の編集
          this.permission.can_post_delete_contract_comment = true; // 契約書作成・確認依頼カードへのコメント・コメント削除
          this.permission.can_archive_contract_status = true; // 契約書のアーカイブ
          this.permission.can_move_delete_contract_status = true; // 契約書作成・確認依頼カードのステータス移動・削除
          this.permission.can_operation_folder_own_template = true; // 自社契約書テンプレートの新規フォルダ作成・移動・名前変更・削除
          this.permission.can_operation_file_own_template = true; // 自社契約書テンプレートファイルのアップロード・ダウンロード・移動・名前変更・削除
          this.permission.can_download_folder_file_liris_template = true; // LIRIS契約書テンプレートフォルダとファイルのダウンロード
          this.permission.can_operation_folder_file_liris_template = false; // LIRIS契約書テンプレートフォルダとファイルの移動・名前変更・削除
          this.permission.can_empty_trash = true; // ゴミ箱を空にする
          this.permission.can_restore_trash = true; // ゴミ箱から復元
          this.permission.can_permanently_deleted_trash = true; // ゴミ箱から完全に削除
          this.permission.can_delete_unsigned_file = true; // 締結管理中のドキュメントの削除
          this.permission.can_contracted_document_version_upload = false; // 締結済みドキュメント画面で新しいバージョンをアップロード
          this.permission.can_edit_labels = false; // ラベルの追加・編集
          this.permission.can_edit_alert = false; // アラートの設定
          this.permission.can_edit_contract_attributes = false; // カスタマイズ項目の追加・編集
          this.permission.can_edit_column = false; // 締結管理のステータス編集、追加、削除
        }

        if (
          data.permission.permission_default_name ===
          Authorities.GENERAL_CANNOT_MOVE_CARD
        ) {
          this.permission.can_setting_account = true; // 自己のアカウント設定
          this.permission.can_setting_company_info = false; // 会社基本情報設定
          this.permission.can_view_user = false; // ユーザー閲覧
          this.permission.can_add_user = false; // ユーザー追加
          this.permission.can_delete_user = false; // ユーザー削除
          this.permission.can_change_permission = false; // ユーザーの権限変更
          this.permission.can_add_delete_department = false; // 部署管理・追加・削除
          this.permission.can_setting_alert = false; // 契約終了・自動更新アラート設定
          this.permission.can_view_service_plan = false; // サービスプラン閲覧
          this.permission.can_create_new_folder = true; // 新規フォルダ作成
          this.permission.can_view_folder = true; // フォルダ閲覧
          this.permission.can_edit_folder_name = true; // フォルダ名編集
          this.permission.can_delete_move_folder = true; // フォルダ削除・移動
          this.permission.can_view_file = true; // ファイル閲覧
          this.permission.can_upload_file = true; // ファイルアップロード
          this.permission.can_version_up_file = true; // ファイルバージョンアップ
          this.permission.can_edit_file_info = true; // ファイル情報の編集
          this.permission.can_delete_move_download_file = true; // ファイル削除・移動・ダウンロード
          this.permission.can_output_csv_file = true; // ファイル情報のCSV出力
          this.permission.can_backup_file = false; // ファイルのバックアップ
          this.permission.can_add_delete_permission = true; // 閲覧権限付与・削除
          this.permission.can_related_contract = !CorporateInfo.isSmallPlan; // 関連契約紐付・紐付解除
          this.permission.can_attached_file = true; // 資料添付・資料削除
          this.permission.can_post_delete_comment = true; // コメント・コメント削除
          this.permission.can_setting_contract_number = false; // 契約書No設定
          this.permission.can_create_contract = true; // 契約書作成・確認依頼カードの作成
          this.permission.can_edit_contract_info = true; // 契約書作成・確認依頼カード情報の編集
          this.permission.can_post_delete_contract_comment = true; // 契約書作成・確認依頼カードへのコメント・コメント削除
          this.permission.can_archive_contract_status = false; // 契約書のアーカイブ
          this.permission.can_move_delete_contract_status = false; // 契約書作成・確認依頼カードのステータス移動・削除
          this.permission.can_operation_folder_own_template = true; // 自社契約書テンプレートの新規フォルダ作成・移動・名前変更・削除
          this.permission.can_operation_file_own_template = true; // 自社契約書テンプレートファイルのアップロード・ダウンロード・移動・名前変更・削除
          this.permission.can_download_folder_file_liris_template = true; // LIRIS契約書テンプレートフォルダとファイルのダウンロード
          this.permission.can_operation_folder_file_liris_template = false; // LIRIS契約書テンプレートフォルダとファイルの移動・名前変更・削除
          this.permission.can_empty_trash = true; // ゴミ箱を空にする
          this.permission.can_restore_trash = true; // ゴミ箱から復元
          this.permission.can_permanently_deleted_trash = true; // ゴミ箱から完全に削除
          this.permission.can_delete_unsigned_file = false; // 締結管理中のドキュメントの削除
          this.permission.can_contracted_document_version_upload = false; // 締結済みドキュメント画面で新しいバージョンをアップロード
          this.permission.can_edit_labels = false; // ラベルの追加・編集
          this.permission.can_edit_alert = false; // アラートの設定
          this.permission.can_edit_contract_attributes = false; // カスタマイズ項目の追加・編集
          this.permission.can_edit_column = false; // 締結管理のステータス編集、追加、削除
        }

        if (data.permission.permission_default_name === Authorities.GUEST) {
          this.permission.can_setting_account = true; // 自己のアカウント設定
          this.permission.can_setting_company_info = false; // 会社基本情報設定
          this.permission.can_view_user = false; // ユーザー閲覧
          this.permission.can_add_user = false; // ユーザー追加
          this.permission.can_delete_user = false; // ユーザー削除
          this.permission.can_change_permission = false; // ユーザーの権限変更
          this.permission.can_add_delete_department = false; // 部署管理・追加・削除
          this.permission.can_setting_alert = false; // 契約終了・自動更新アラート設定
          this.permission.can_view_service_plan = false; // サービスプラン閲覧
          this.permission.can_create_new_folder = false; // 新規フォルダ作成
          this.permission.can_view_folder = true; // フォルダ閲覧
          this.permission.can_edit_folder_name = false; // フォルダ名編集
          this.permission.can_delete_move_folder = false; // フォルダ削除・移動
          this.permission.can_view_file = false; // ファイル閲覧
          this.permission.can_upload_file = false; // ファイルアップロード
          this.permission.can_version_up_file = false; // ファイルバージョンアップ
          this.permission.can_edit_file_info = false; // ファイル情報の編集
          this.permission.can_delete_move_download_file = false; // ファイル削除・移動・ダウンロード
          this.permission.can_output_csv_file = false; // ファイル情報のCSV出力
          this.permission.can_backup_file = false; // ファイルのバックアップ
          this.permission.can_add_delete_permission = false; // 閲覧権限付与・削除
          this.permission.can_related_contract = false; // 関連契約紐付・紐付解除
          this.permission.can_attached_file = false; // 資料添付・資料削除
          this.permission.can_post_delete_comment = true; // コメント・コメント削除
          this.permission.can_setting_contract_number = false; // 契約書No設定
          this.permission.can_create_contract = false; // 契約書作成・確認依頼カードの作成
          this.permission.can_edit_contract_info = false; // 契約書作成・確認依頼カード情報の編集
          this.permission.can_post_delete_contract_comment = true; // 契約書作成・確認依頼カードへのコメント・コメント削除
          this.permission.can_archive_contract_status = false; // 契約書のアーカイブ
          this.permission.can_move_delete_contract_status = false; // 契約書作成・確認依頼カードのステータス移動・削除
          this.permission.can_operation_folder_own_template = false; // 自社契約書テンプレートの新規フォルダ作成・移動・名前変更・削除
          this.permission.can_operation_file_own_template = false; // 自社契約書テンプレートファイルのアップロード・ダウンロード・移動・名前変更・削除
          this.permission.can_download_folder_file_liris_template = false; // LIRIS契約書テンプレートフォルダとファイルのダウンロード
          this.permission.can_operation_folder_file_liris_template = false; // LIRIS契約書テンプレートフォルダとファイルの移動・名前変更・削除
          this.permission.can_empty_trash = false; // ゴミ箱を空にする
          this.permission.can_restore_trash = false; // ゴミ箱から復元
          this.permission.can_permanently_deleted_trash = false; // ゴミ箱から完全に削除
          this.permission.can_delete_unsigned_file = false; // 締結管理中のドキュメントの削除
          this.permission.can_contracted_document_version_upload = false; // 締結済みドキュメント画面で新しいバージョンをアップロード
          this.permission.can_edit_labels = false; // ラベルの追加・編集
          this.permission.can_edit_alert = false; // アラートの設定
          this.permission.can_edit_contract_attributes = false; // カスタマイズ項目の追加・編集
          this.permission.can_edit_column = false; // 締結管理のステータス編集、追加、削除
        }
        if (data.is_liris_admin) {
          this.permission.can_operation_folder_file_liris_template = true; // LIRIS契約書テンプレートフォルダとファイルの移動・名前変更・削除
        }
        return Promise.resolve(res);
      })
      .catch(error => {
        return Promise.reject(error);
      });
  }

  /**
   * ユーザー情報の更新
   *
   * @param param0
   */
  @Action({ rawError: true })
  async update_user_account_info({
    user_name = null,
    department_id = null,
    email = null,
    is_showing_extraction_dialog = null
  }) {
    const params: any = {
      user_name,
      department_id,
      email,
      is_showing_extraction_dialog
    };

    return await SettingRepository.updateUserInfo(params)
      .then(async response => {
        if (response.status == HTTP_STATUS.OK) {
          if (response.data.user_name)
            this.SET_USER_NAME(response.data.user_name);
          if (response.data.department)
            this.SET_DEPARTMENT(response.data.department);
          if (response.data.email) this.SET_EMAIL(response.data.email);
          if (response.data.is_showing_extraction_dialog === false)
            this.SET_SHOWS_EXTRACT_DIALOG(false);
        }
      })
      .catch(err => {
        return Promise.reject(err);
      });
  }

  @Mutation
  SET_USER_ID(userId: string) {
    this.user_id = userId;
  }

  @Mutation
  SET_REQUIRED_CHANGE_PASSWORD(isFirstLogin: boolean) {
    this.is_required_changing_password = isFirstLogin;
  }

  @Mutation
  SET_ONE_TIME_PASSWORD(one_time_password: string) {
    this.one_time_password = one_time_password;
  }

  @Mutation
  SET_ADMIN(bool: boolean) {
    this.isAdmin = bool;
  }

  @Mutation
  SET_OWNER(bool: boolean) {
    this.isOwner = bool;
  }

  @Mutation
  SET_AVATAR(avatar: Avatar) {
    this.avatar = avatar;
  }

  @Mutation
  SET_AUTHORITY(data) {
    this.authority = data.permission.permission_default_name;
    if (data.is_liris_admin) {
      this.isOwner = true;
      this.isAdmin = true;
    } else {
      this.isOwner = false;
      this.isAdmin = false;
    }
    if (data.is_owner) this.isOwner = true;
  }

  @Mutation
  SET_AUTHENTICATED(authenticated: boolean) {
    this.authenticated = authenticated;
  }

  @Mutation
  SET_EMAIL(email: string) {
    if (email) {
      this.email = email.replace(/\.delete\.[0-9a-z]+$/, '');
    } else {
      this.email = email;
    }
    let pattern = null;

    if (process.env.VUE_APP_IS_LOCALHOST === 'true') {
      pattern = Regex.dev_email_pattern;
    } else {
      pattern = Regex.email_pattern;
    }
    if (pattern.test(this.email)) {
      this.is_email = true;
    } else {
      this.is_email = false;
    }
  }

  @Mutation
  SET_SHOWS_EXTRACT_DIALOG(is_showing_extraction_dialog: boolean) {
    this.is_showing_extraction_dialog = is_showing_extraction_dialog;
  }

  /**
   * @deprecated
   * パスワードをstore登録しているためValidateするならSET_PASSWORD_FOR_VALIDATEを使う
   *
   * @param password
   */
  @Mutation
  SET_PASSWORD(password: string) {
    this.password = password;
    this.pw_length = password.length;
    this.pw_has_lower_case = Regex.lower_pattern.test(password);
    this.pw_has_upper_case = Regex.upper_pattern.test(password);
    this.pw_has_number = Regex.num_pattern.test(password);
  }

  @Mutation
  RESET_PASSWORD() {
    this.password = '';
    this.pw_length = 0;
    this.pw_has_lower_case = false;
    this.pw_has_upper_case = false;
    this.pw_has_number = false;
    this.can_submit_password = false;
  }

  /**
   * 入力されたパスワードの登録可否判定用
   * ※ password自体は保存しないようにする
   *
   * @param { password: パスワード }
   */
  @Mutation
  SET_PASSWORD_FOR_VALIDATE({ password }) {
    this.pw_length = password.length;
    this.pw_has_lower_case = Regex.lower_pattern.test(password);
    this.pw_has_upper_case = Regex.upper_pattern.test(password);
    this.pw_has_number = Regex.num_pattern.test(password);
    if (
      this.pw_length > 7 &&
      this.pw_has_lower_case &&
      this.pw_has_upper_case &&
      this.pw_has_number
    ) {
      this.can_submit_password = true;
    } else {
      this.can_submit_password = false;
    }
  }

  @Mutation
  SET_USER_NAME(user_name: string) {
    this.user_name = user_name;
  }

  // TODO: departmentにstringをセットさせてはいけない
  @Mutation
  SET_DEPARTMENT(department: string) {
    this.department = department;
  }

  @Mutation
  SET_DEPARTMENT_NAME(department_name: string) {
    this.department_name = department_name;
  }

  @Mutation
  RESET_PERMISSION() {
    this.permission = {};
  }
}

const UserInfoModule = getModule(UserInfo);

export default UserInfoModule;
