





























































































































import { Component, Vue } from 'vue-property-decorator';
import UserInfoModule from '@/store/UserInfo';
import SettingsInfoModule from '@/store/SettingsInfo';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import AUTHORITIES from '@/consts/Authorities.js';

/**
 * 設定画面HeaderTabComponent
 */
@Component({
  components: {
    TextOnlyWindow
  }
})
export default class SettingsHeaderMenu extends Vue {
  // liris専用画面に遷移するためのroute
  liris_route_name: string = '';

  /**
   * 現在のRouting名称を取得
   */
  private get route_name() {
    return this.$route.name;
  }

  /**
   * 謳歌されたタブに応じて画面遷移
   */
  push_page(page_name): void {
    if (this.route_name === page_name) {
      return;
    }
    this.$router.push({ name: page_name });
  }

  /**
   * LIRIS専用画面遷移
   *
   */
  push_liris_admin_page(): void {
    SettingsInfoModule.SET_CAN_OPEN_LIRIS_ADMIN_PASSWORD_WINDOW(false);

    this.push_page(this.liris_route_name);
  }

  /**
   * タブがアクティブかどうかを判定
   */
  is_active(page_name): boolean {
    return this.route_name == page_name;
  }

  get authority() {
    return UserInfoModule.authority;
  }

  get is_owner() {
    if (this.authority == AUTHORITIES.OWNER) {
      return true;
    }
    return false;
  }

  get is_over_admin() {
    if (
      this.authority == AUTHORITIES.OWNER ||
      this.authority == AUTHORITIES.ADMINISTRATOR
    ) {
      return true;
    }
    return false;
  }

  get is_over_owner() {
    if (this.authority == AUTHORITIES.OWNER) {
      return true;
    }
    return false;
  }

  get is_liris_owner() {
    return UserInfoModule.isOwner && UserInfoModule.isAdmin;
  }

  /**
   * LIRIS管理者用パスワードモーダル関連
   */
  openLirisAdminConfirmation(liris_route_name: string) {
    SettingsInfoModule.SET_CAN_OPEN_LIRIS_ADMIN_PASSWORD_WINDOW(true);
    this.liris_route_name = liris_route_name;
  }

  cancelLirisAdminConfirmation() {
    SettingsInfoModule.SET_CAN_OPEN_LIRIS_ADMIN_PASSWORD_WINDOW(false);
  }

  public get show_liris_admin_password_window(): boolean {
    return SettingsInfoModule.can_open_liris_admin_password_window;
  }

  public set show_liris_admin_password_window(isOpenWindow) {
    SettingsInfoModule.SET_CAN_OPEN_LIRIS_ADMIN_PASSWORD_WINDOW(isOpenWindow);
  }
}
