










































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import TextForm from '@/components/atoms/text_forms/TextForm.vue';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';

import DocumentsModule from '@/store/Documents';
import ATTRIBUTES from '@/consts/Attributes';

const NEW_ATTRIBUTE_ID = 'NEW_ATTRIBUTE';

@Component({
  components: {
    TextForm,
    SelectBox,
    BaseTable,
    AppButton,
    ImageContent,
    TextOnlyWindow
  }
})
export default class Attributes extends Vue {
  selected: any = [];

  customAttributes: any = [];
  customAttributeOrders: any = [];

  formData: any = {
    id: '',
    key: '',
    type: '',
    order: 0,
    items: [],
    alert: false,
    alertExtensionDate: 14,
    sendEmail: false,
    errors: {
      key: '',
      items: []
    }
  };
  isEditing: boolean = false;

  is_showing_delete_attributes_window: boolean = false;

  get defaultAttributes() {
    return ATTRIBUTES.DEFAULT_ATTRIBUTES;
  }

  get attributes() {
    return this.customAttributeOrders.map(id => {
      if (id === NEW_ATTRIBUTE_ID) {
        return this.formData;
      }
      return this.customAttributes.find(a => a.id === id);
    });
  }

  get ATTRIBUTE_TYPES() {
    return ATTRIBUTES.TYPES;
  }

  get NEW_ATTRIBUTE_ID() {
    return NEW_ATTRIBUTE_ID;
  }

  get attributeTypeOptions() {
    return Object.entries(ATTRIBUTES.TYPES).map(([key, value]) => {
      return Object.assign({ key }, value);
    });
  }

  get is_selected(): boolean {
    return this.selected.length > 0;
  }

  get can_edit(): boolean {
    return this.selected.length === 1;
  }

  /**
   * すべてのチェックボックスが checked かどうかを返す
   */
  get areAllChecked(): boolean {
    if (this.selected.length === 0) return false;
    return this.selected.length === this.attributes.length;
  }

  get isEditingAttribute() {
    return id => {
      return this.formData.id === id;
    };
  }

  get reservedAttributeKeys() {
    return ignoreId => {
      return this.defaultAttributes
        .map(attr => attr.key)
        .concat(
          this.customAttributes.map(attr => {
            if (attr.id === ignoreId) {
              return false;
            }
            return attr.key;
          })
        );
    };
  }

  /**
   * すべての項目のチェックボックスの checked を付けたり外したりする
   */
  changeCheckedAll(): void {
    if (this.areAllChecked) {
      this.selected = [];
      return;
    }
    this.selected = this.attributes;
  }

  async created() {
    await this.getContractAttributes();
  }

  async getContractAttributes() {
    await DocumentsModule.getContractAttributes().then(response => {
      this.customAttributes = response.data;
      this.customAttributeOrders = this.customAttributes.map(a => a.id);
    });
  }

  changeAttributeType(val) {
    if (val.key === 'select') {
      this.appendSelectOptionItem();
    }
  }

  appendSelectOptionItem() {
    if (!this.formData.items) {
      this.formData.items = [];
    }
    this.formData.items.push({
      text: ''
    });
  }

  deleteSelectOptionAt(index) {
    this.formData.items.splice(index, 1);
    this.formData.errors.items.splice(index, 1);
  }

  openNewAttributeForm() {
    this.selected = [];
    this.formData = {
      id: NEW_ATTRIBUTE_ID,
      key: '',
      type: ATTRIBUTES.TYPES['text'],
      order: this.customAttributes.length + 1,
      items: [],
      alert: false,
      alertExtensionDate: 14,
      sendEmail: false,
      errors: {
        key: '',
        items: []
      }
    };
    this.customAttributeOrders.push(NEW_ATTRIBUTE_ID);
    this.isEditing = true;
    this.$nextTick(() => {
      this.$refs.formInputElement[0].focus();
    });
  }

  openEdiAttributeForm() {
    if (!this.can_edit) {
      return;
    }
    const target = this.selected[0];
    this.formData = {
      id: target.id,
      key: target.key,
      type: ATTRIBUTES.TYPES[target.type],
      order: target.order,
      items: [].concat(target.items),
      alert: target.alert,
      alertExtensionDate: target.alert_days_before || 14,
      sendEmail: target.send_email || false,
      errors: {
        key: '',
        items: []
      }
    };
    this.isEditing = true;
    this.$nextTick(() => {
      this.$refs.formInputElement[0].focus();
    });
  }

  async closeEditAttributeForm() {
    this.formData = {
      id: '',
      key: '',
      type: '',
      order: 0,
      items: [],
      alert: false,
      alertExtensionDate: 14,
      sendEmail: false,
      errors: {
        key: '',
        items: []
      }
    };
    if (this.customAttributeOrders.includes(NEW_ATTRIBUTE_ID)) {
      this.customAttributeOrders.splice(
        this.customAttributeOrders.indexOf(NEW_ATTRIBUTE_ID),
        1
      );
    }
    this.selected = [];
    this.isEditing = false;
    await this.getContractAttributes();
  }

  confirmRemoveAttribute() {
    this.is_showing_delete_attributes_window = true;
  }

  closeDeleteAttributes() {
    this.is_showing_delete_attributes_window = false;
  }

  validate(): boolean {
    this.formData.errors = {
      key: '',
      items: []
    };
    if (this.formData.key === '') {
      this.formData.errors.key = '項目名を入力してください';
      return false;
    }
    if (
      this.reservedAttributeKeys(this.formData.id).includes(this.formData.key)
    ) {
      this.formData.errors.key = 'その項目名はすでに使用されています';
      return false;
    }
    if (
      this.formData.type.key === ATTRIBUTES.TYPES.select.key &&
      !this.formData.items.every(item => item.text !== '')
    ) {
      this.formData.errors.items = this.formData.items.map((item, index) => {
        if (item.text === '') {
          return '内容を入力してください';
        }
        return '';
      });
      return false;
    }
    return true;
  }

  async saveAttributeOrder(offsetOrder) {
    const order = this.formData.order + offsetOrder;
    if (order < 1 || order > this.attributes.length) {
      return;
    }
    const index = this.formData.order - 1;
    if (offsetOrder < 0) {
      this.customAttributeOrders.splice(
        index - 1,
        2,
        this.customAttributeOrders[index],
        this.customAttributeOrders[index - 1]
      );
    } else if (offsetOrder > 0) {
      this.customAttributeOrders.splice(
        index,
        2,
        this.customAttributeOrders[index + 1],
        this.customAttributeOrders[index]
      );
    }
    this.formData.order = order;
  }

  async submitAttribute() {
    if (!this.validate()) {
      return Promise.reject();
    }
    let method = 'postContractAttributes';
    const params = {
      id: undefined,
      key: this.formData.key,
      type: this.formData.type.key,
      order: this.formData.order,
      items: [],
      alert: this.formData.alert,
      alert_days_before: this.formData.alertExtensionDate,
      send_email: this.formData.sendEmail
    };
    if (this.formData.id !== NEW_ATTRIBUTE_ID) {
      method = 'updateContractAttributes';
      params.id = this.formData.id;
    }
    if (this.formData.type.key === 'select') {
      let i = 0;
      params.items = this.formData.items.map(item => {
        return {
          order: i++,
          text: item.text,
          id: item.id ? item.id : null
        };
      });
    }

    const isNeedReloadNotifications =
      this.selected[0] &&
      this.formData.type.key === 'datetime' &&
      (this.selected[0].alert !== this.formData.alert ||
        this.selected[0].alert_days_before !==
          this.formData.alertExtensionDate);
    return await DocumentsModule[method](params).then(async response => {
      await this.getContractAttributes();
      await this.closeEditAttributeForm();
      if (isNeedReloadNotifications) {
        await DocumentsModule.getNotifications();
      }
    });
  }

  async deleteAttributes() {
    const isNeedReloadNotifications = this.selected.some(
      s => s.type === 'datetime'
    );
    await Promise.all(
      this.selected.map(async attr => {
        await DocumentsModule.deleteContractAttributes(attr.id);
      })
    );
    await this.getContractAttributes();
    this.selected = [];
    this.closeDeleteAttributes();
    if (isNeedReloadNotifications) {
      await DocumentsModule.getNotifications();
    }
  }
}
