
























import { Component, Vue, Prop } from 'vue-property-decorator';
import ImageContent from '@/components/atoms/images/ImageContent.vue';

import Documents from '@/store/Documents';

@Component({
  components: {
    ImageContent
  }
})
export default class AppPopup extends Vue {
  @Prop()
  message!: string;
  @Prop()
  subMessage!: string;
  @Prop()
  index: boolean;
  @Prop({ default: true })
  showButton: boolean;

  mounted() {
    /**
     * 表示から 5000ms で消える
     */
    setTimeout(() => {
      this.close_popup();
      // ファイル削除時に取り消しをしなかった場合
      Documents.SET_TEMP_DELETED_FILES([]);
      Documents.SET_TEMP_DELETED_FOLDERS([]);
      Documents.RESET_SELECTED_TABLE();
    }, 5000);
  }

  close_popup() {
    this.$emit('click', this.index);
  }

  click_text_button() {
    this.$emit('click_text_button');
  }
}
