






























import { Component, Vue } from 'vue-property-decorator';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import Documents from '@/store/Documents.ts';
import { RepositoryFactory } from '@/repositories/RepositoryFactory';
import HTTP_STATUS from '@/consts/HttpStatus';
import NOTIFY_TEXT from '@/consts/NotifyText';
import notify from '@/functions/notify';

const DocumentRepository = RepositoryFactory.get('documents');
const TemplateInfoRepository = RepositoryFactory.get('templateInfos');

interface Window {
  webkitURL: any;
  URL: any;
  FormData: any;
}

declare let window: Window;

@Component({
  components: {
    TextOnlyWindow
  }
})
export default class AllFileDownload extends Vue {
  files: File[] = [];
  showDownloadModal: boolean = false;
  downloadLink: string = '';
  getDownloadInterval = null;
  modalBody: string =
    'ダウンロード完了までお時間がかかる場合がございます。<br />ダウンロードしますか？';

  async download() {
    await Documents.postAllContractDownloadJob();
    notify.success({
      text:
        '全ファイルダウンロードの準備をしています。準備が完了次第、自動的にダウンロードを開始します。'
    });
    this.showDownloadModal = false;
    // Fetch
    this.getDownloadInterval = setInterval(() => {
      this.getDownloadLink({
        job_id: this.downloadJobId
      });
    }, 10000);
  }

  get downloadJobId() {
    return Documents.allContractsDownloadJobId;
  }

  async getDownloadLink({ job_id }) {
    if (!this.downloadJobId) {
      return;
    }
    await TemplateInfoRepository.getDownloadLink({ job_id })
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }
        if (res.data) {
          this.downloadLink = res.data;
          clearInterval(this.getDownloadInterval);
          notify.clearSuccessWithoutCloseButton();
          this.getDownloadInterval = null;
          this.downloadFile();
        }
        return;
      })
      .catch(e => {
        clearInterval(this.getDownloadInterval);
        notify.clearSuccessWithoutCloseButton();
        this.getDownloadInterval = null;
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
    return;
  }

  downloadFile() {
    const link = document.createElement('a');
    link.href = this.downloadLink;
    link.click();
    this.downloadLink = '';
  }

  closeDownloadModal() {
    this.showDownloadModal = false;
  }
}
