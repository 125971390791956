





































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import Label from '@/components/atoms/labels/Label.vue';
import IconCheck from '@/components/atoms/icons/IconCheck.vue';

@Component({
  components: {
    SelectBox,
    Label,
    IconCheck
  }
})
export default class SelectLabels extends Vue {
  @Prop()
  options!: any[];

  @Prop()
  value: any;

  @Prop({ default: false })
  searchable?: boolean;

  @Prop({ default: true })
  clearable?: boolean;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: false })
  closeOnSelect?: boolean;

  @Prop({ default: 'label_name' })
  label?: string;

  @Prop({ default: '' })
  placeholder?: string;

  editValue: any[] = [];

  @Watch('value')
  updateValue(new_value) {
    this.editValue = new_value;
  }

  mounted() {
    this.editValue = this.value;
  }

  getOptionLabel(option) {
    return option[`${this.label}`];
  }

  isSelected(option) {
    return this.editValue.some(o => o.label_id === option.label_id);
  }

  input(val) {
    this.editValue = val;
    this.$emit('input', this.editValue);
  }

  deselect(e, option) {
    if (this.editValue.some(o => o.label_id === option.label_id)) {
      setTimeout(() => {
        this.editValue = this.editValue.filter(
          o => o.label_id !== option.label_id
        );
        this.$emit('input', this.editValue);
      }, 1);
    }
  }

  get actionMark() {
    return '';
  }
}
