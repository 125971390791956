




import { Component, Vue, Prop } from 'vue-property-decorator';
@Component({
  components: {}
})
export default class TextFormName extends Vue {
  @Prop()
  text!: string;

  @Prop()
  labelFor?: string;
}
