const axios = require('axios').default;

const resource = '/contract-management/templates';

type MoveTemplateParams = {
  directory_path_list: [string];
  client_template_id_list: [string];
  move_to: string;
};

export default {
  /**
   * フォルダ情報取得
   *
   * @param { String } path
   */
  getFolders(path: string) {
    return axios.get(`${resource}/clients?current_directory=${path}`);
  },

  /**
   * テンプレート移動
   *
   * @param { Object } params
   */
  moveTemplate(params: MoveTemplateParams) {
    // TODO: URLクエリのcurrent_directoryは削除できそう
    return axios.put(
      `${resource}/clients/move?current_directory=${params.move_to}`,
      params
    );
  },

  /**
   * クライアントテンプレート検索
   *
   * @param { String } keyword
   */
  searchClientTemplate(keyword: string) {
    return axios.get(`${resource}/clients/search?keyword=${keyword}`);
  },

  /**
   * LIRISテンプレート検索
   *
   * @param { String } keyword
   */
  searchLirisTemplate(keyword: string) {
    return axios.get(`${resource}/liris/search?keyword=${keyword}`);
  }
};
