



































































import { Component, Vue, Watch } from 'vue-property-decorator';

import SettingsInfoModule from '@/store/SettingsInfo';
import AlertTermRadioButton from '@/components/atoms/inputs/AlertTermRadioButton.vue';
import dayjs, { Dayjs } from 'dayjs';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import notify from '@/functions/notify';

Component.registerHooks(['beforeRouteLeave']);

/**
 * アラート画面Component
 */
@Component({
  components: {
    AlertTermRadioButton,
    AppButton,
    TextOnlyWindow
  }
})
export default class Alert extends Vue {
  auto_update_date: string = '';
  expiration_date: string = '';
  expired_date: Dayjs = dayjs()
    .month(7)
    .date(31);
  expired_date_text: string = this.expired_date.format('M月DD日');

  auto_update_alert_date: string = '';
  expiration_alert_date: string = '';

  form_dirty: boolean = false;
  route_leave_with_dirty: object = null;

  created() {
    SettingsInfoModule.get_setting_alert().then(() => {
      this.auto_update_date =
        SettingsInfoModule.setting_alerts.auto_update_before_alert_day;
      this.expiration_date =
        SettingsInfoModule.setting_alerts.expiration_before_alert_day;
    });
  }

  @Watch('auto_update_date')
  update_auto_update_date(new_value) {
    this.auto_update_alert_date = dayjs()
      .month(7)
      .date(31)
      .add(-Number(new_value), 'day')
      .format('M月D日');
  }

  @Watch('expiration_date')
  update_expiration_date(new_value) {
    this.expiration_alert_date = dayjs()
      .month(7)
      .date(31)
      .add(-Number(new_value), 'day')
      .format('M月D日');
  }

  get isDirty() {
    return this.form_dirty;
  }

  onChange() {
    this.form_dirty = true;
  }

  change_alert_date() {
    SettingsInfoModule.put_setting_alert({
      auto_update_date: Number(this.auto_update_date),
      expiration_date: Number(this.expiration_date)
    }).catch(e =>
      notify.error({ text: 'アラート設定の権限を更新できませんでした。' })
    );

    this.form_dirty = false;
  }

  get isLeaveWithDirty() {
    return this.route_leave_with_dirty !== null;
  }

  beforeRouteLeave(to, from, next) {
    if (this.form_dirty) {
      this.route_leave_with_dirty = to;
      return;
    }
    next();
  }

  leaveAndDiscard() {
    this.form_dirty = false;
    this.$router.push(this.route_leave_with_dirty);
    this.route_leave_with_dirty = null;
  }

  cancelLeave() {
    this.route_leave_with_dirty = null;
  }
}
