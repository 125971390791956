

















import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import DocumentTabBar from '@/components/organisms/DocumentDetail/DocumentTabBar.vue';
import DocumentPreviewSearchBar from '@/components/organisms/DocumentDetail/DocumentPreview/DocumentPreviewSearchBar.vue';
import IconSvg from '@/components/atoms/icons/IconSvg.vue';
import Documents from '@/store/Documents';
import vLoading from 'vue-loading-overlay';

@Component({
  components: {
    DocumentTabBar,
    IconSvg,
    DocumentPreviewSearchBar,
    vLoading
  }
})
export default class DocumentPreview extends Vue {
  @Prop({ required: true, type: String })
  selectedTab: string;

  searchBar: boolean = false;
  zoomButtonsPositionLeft = (window.innerWidth - 328) / 2 - 225 / 2;
  searchButtonPositionLeft = 0;
  zoomRatio = 1.3;
  maxPage = 1;
  currentPage = 1;
  isDisplayZoom = false;
  isLoading: boolean = true;
  loadingTime = 1000;

  @Watch('selectedTab')
  resetLoading() {
    this.isLoading = true;
    if (this.selectedTab === 'DocumentPreview') {
      this.loadingProcess();
    }
  }

  mounted() {
    this.loadingProcess();
  }

  disabledLoading() {
    this.isLoading = false;
  }

  loadingProcess() {
    setTimeout(this.disabledLoading, this.loadingTime);
  }

  get getFilePath() {
    return `/generic/web/viewer.html?file=${encodeURIComponent(this.pdfLink)}`;
  }

  get pdfLink() {
    return Documents.previewPdfLink;
  }

  get refs(): any {
    return this.$refs;
  }

  get viewer() {
    return this.refs.viewer;
  }

  get selectedVersion() {
    return Documents.selectedVersion;
  }

  get isSigned(): boolean {
    return Documents.contract_detail.is_signed;
  }

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  }

  centeringViewer() {
    if (!this.viewer) {
      return;
    }
    const viewerWidth = this.viewer.clientWidth || window.innerWidth - 48;
    this.viewer.scrollLeft = (viewerWidth * this.zoomRatio - viewerWidth) / 2;
  }

  calcButtonPosition() {
    const viewerWidth = this.viewer.clientWidth || window.innerWidth - 48;
    this.zoomButtonsPositionLeft = viewerWidth / 2 - 225 / 2;
    this.searchButtonPositionLeft = Math.min(
      viewerWidth / 2 + (595 * this.zoomRatio) / 2 + 40,
      viewerWidth - 48 - 40
    );
  }

  onToggleSideBar() {
    this.$nextTick(() => {
      this.centeringViewer();
      this.calcButtonPosition();
    });
  }

  handleResize() {
    this.centeringViewer();
    this.calcButtonPosition();
  }
}
