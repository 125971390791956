





















import { Component, Vue } from 'vue-property-decorator';
import CorporateInfoModule from '@/store/CorporateInfo';
import dayjs from 'dayjs';

@Component({})
export default class ServicePlanAlert extends Vue {
  private get service_plan() {
    return CorporateInfoModule.service_plans;
  }

  get service_plan_name() {
    const servicePlanName = this.service_plan.service_plan_name;
    return this.is_free_trial ? servicePlanName : `${servicePlanName}プラン`;
  }

  get is_free_trial(): boolean {
    return CorporateInfoModule.isFreeTrialPlan;
  }

  get service_plan_expiration(): number {
    const now = dayjs(dayjs().format('YYYY-MM-DD')).add(9, 'h');
    const limit = dayjs(this.service_plan.service_plan_expiration).add(9, 'h');
    const diff = dayjs(limit).diff(now, 'day');

    return diff;
  }
}
