





























import {
  Component,
  Vue,
  PropSync,
  Watch,
  Emit,
  Prop
} from 'vue-property-decorator';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

@Component({
  components: {
    BaseWindow,
    AppButton
  }
})
export default class ExtractDialog extends Vue {
  @PropSync('showUploadDialog', { type: Boolean, default: false })
  showDialog: boolean;

  @Prop()
  windowSize!: string;

  hideExtractionDialog: boolean = false;

  @Emit()
  close(): object {
    const hideExtractionDialog: boolean = this.hideExtractionDialog;
    this.showDialog = false;

    return { hideExtractionDialog };
  }

  @Watch('showDialog')
  resetDialog(newVal: boolean, oldVal: boolean) {
    if (oldVal === true && newVal === false) {
      this.hideExtractionDialog = false;
    }
  }
}
