



















import { Component, Vue, Prop } from 'vue-property-decorator';
import TextFormName from '@/components/atoms/texts/TextFormName.vue';
import TextFormMidSize_Password from '@/components/atoms/text_forms/TextFormMidSize_Password.vue';

@Component({
  components: {
    TextFormName,
    TextFormMidSize_Password
  }
})
export default class TextFormWithTitle_PasswordChange extends Vue {
  @Prop()
  title_text!: string;
  @Prop()
  message?: string;
  @Prop()
  maxlength?: number;

  input(val) {
    this.$emit('input', val);
  }
}
