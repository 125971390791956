













































































import { Component, Vue, Prop, Watch, Emit } from 'vue-property-decorator';
import AttachDocumentCard from '@/components/molecules/AttachDocumentCard.vue';
import ViewPermissionCard from '@/components/molecules/ViewPermissionCard.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import BaseWindow from '@/components/molecules/modal_windows/BaseWindow.vue';
import CorporateInfo from '@/store/CorporateInfo';
import Documents from '@/store/Documents';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';
const ObjectPermissionRepository = RepositoryFactory.get('objectPermissions');

interface UploadFile {
  size: number;
  name: string;
  type: string;
  file: File;
}

interface Folder {
  directory_name: string;
  directory_name_furigana?: string;
  directory_path: string;
}

@Component({
  components: {
    AttachDocumentCard,
    ViewPermissionCard,
    AppButton,
    BaseWindow
  }
})
export default class UploadDialog extends Vue {
  @Prop()
  value!: boolean;
  @Prop({ default: [] })
  fileList: File[];
  @Prop({ default: true })
  requireAttachmentFile: boolean;
  @Prop({ default: true })
  showSettingRestriction: boolean;
  @Prop({ default: true })
  showLinkAsRelatedContract: boolean;
  @Prop({
    default: () => ({
      directory_name: null,
      directory_name_furigana: null,
      directory_path: null
    })
  })
  @Prop()
  windowSize!: string;
  @Prop()
  targetFolder: Folder;
  @Prop({ default: false })
  isUploading: boolean;

  linkAsRelatedContract: boolean = false;
  attachFileList: File[] = [];

  isShowingViewPermissionCard: boolean = false;
  selectedViewPermissionData: any = null;

  get uploadFileLimit(): number {
    return CorporateInfo.uploadFileLimit;
  }
  get attachFileLimit(): number {
    return Documents.attachFileLimit;
  }

  get uploadButtonText(): string {
    return this.isUploading ? 'アップロード中' : 'アップロード';
  }

  // アップロードするファイル数が制限数を超えている
  get uploadFileNumWithinLimit() {
    return this.fileList.length <= this.uploadFileLimit;
  }

  get uploadButtonDisabled(): boolean {
    return (
      !this.uploadFileNumWithinLimit ||
      this.attachFileList.length > this.attachFileLimit ||
      this.isUploading
    );
  }

  get isSmallPlan() {
    return CorporateInfo.isSmallPlan;
  }

  get currentDirectoryId(): string {
    return Documents.current_directory_id;
  }

  setViewPermissionData(data) {
    this.selectedViewPermissionData = data;
    this.isShowingViewPermissionCard = false;
  }

  @Watch('value')
  async checkParentFolderPermissions() {
    if (!this.value) {
      return;
    }
    const directoryId = this.currentDirectoryId;
    if (directoryId) {
      await ObjectPermissionRepository.getDirectoryPermissions(
        directoryId
      ).then(response => {
        const permissions = {
          selected_users: response.data.user_list,
          selected_departments: response.data.department_list
        };
        this.setViewPermissionData(permissions);
      });
    }
  }

  @Emit()
  upload(): object {
    const linkAsRelatedContract: boolean = this.linkAsRelatedContract;
    const attachFileList: File[] = this.attachFileList;
    const selectedViewPermissionData = this.selectedViewPermissionData;

    return {
      fileList: this.fileList,
      linkAsRelatedContract,
      attachFileList,
      selectedViewPermissionData
    };
  }

  @Emit()
  cancel() {
    this.isShowingViewPermissionCard = false;
    this.selectedViewPermissionData = null;
    return;
  }

  /**
   * $refsのTypescript Error回避
   * https://qiita.com/tsumasakky/items/03a4bdf74e3c765c2077
   */
  get refs(): any {
    return this.$refs;
  }

  @Watch('value')
  resetDialog(newVal: boolean, oldVal: boolean) {
    if (oldVal === true && newVal === false) {
      this.linkAsRelatedContract = false;
      this.isShowingViewPermissionCard = false;
      this.selectedViewPermissionData = null;
      this.attachFileList = [];
    }
  }

  get showAttachDocsDialog() {
    if (this.fileList.length === 1 && this.requireAttachmentFile) {
      return true;
    } else {
      return false;
    }
  }

  get isPermissionAdded() {
    return (
      this.selectedViewPermissionData &&
      ((this.selectedViewPermissionData.selected_departments &&
        this.selectedViewPermissionData.selected_departments.length > 0) ||
        (this.selectedViewPermissionData.selected_users &&
          this.selectedViewPermissionData.selected_users.length > 0))
    );
  }

  changeWindowStatus(val) {
    this.selectedViewPermissionData = null;
    this.$emit('changeWindowStatus');
  }
}
