











































import { Component, Vue } from 'vue-property-decorator';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import TextFormWithTitle from '@/components/molecules/text_form_with_title/TextFormWithTitle.vue';
import PasswordCheckItem from '@/components/molecules/icon_text/PasswordCheckItem.vue';
import TextFormWithTitle_PasswordChange from '@/components/molecules/text_form_with_title/TextFormWithTitle_PasswordChange.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

import UserInfoModule from '@/store/UserInfo';

import HTTP_STATUS from '@/consts/HttpStatus';
import KEY_CODE from '@/consts/KeyCode';
const axios = require('axios').default;

@Component({
  components: {
    FormTitle,
    PasswordCheckItem,
    TextFormWithTitle,
    TextFormWithTitle_PasswordChange,
    AppButton
  }
})
export default class OneTimePasswordForm extends Vue {
  one_time_password_error: string = '';

  get pw_length() {
    return UserInfoModule.pw_length;
  }

  get pw_has_lower_case() {
    return UserInfoModule.pw_has_lower_case;
  }

  get pw_has_upper_case() {
    return UserInfoModule.pw_has_upper_case;
  }

  get pw_has_number() {
    return UserInfoModule.pw_has_number;
  }

  get one_time_password() {
    return UserInfoModule.one_time_password;
  }

  get new_password() {
    return UserInfoModule.password;
  }

  mounted() {
    UserInfoModule.SET_PASSWORD('');
    UserInfoModule.SET_ONE_TIME_PASSWORD('');
  }

  get button_disabled() {
    if (
      this.one_time_password.length &&
      this.pw_length > 7 &&
      this.pw_has_lower_case &&
      this.pw_has_upper_case &&
      this.pw_has_number
    ) {
      return false;
    } else {
      return true;
    }
  }

  beforeDestroy() {
    UserInfoModule.SET_PASSWORD('');
    UserInfoModule.SET_ONE_TIME_PASSWORD('');
  }

  request_new_password() {
    if (this.button_disabled) return;
    const url = 'user-management/auth/challenges/password/forgot';

    let email: any = UserInfoModule.email;
    if (!email) {
      email = this.$route.query.email;
      email = decodeURI(email);
    }

    const post_data = {
      email: email,
      new_password: UserInfoModule.password,
      one_time_password: UserInfoModule.one_time_password
    };
    axios
      .put(url, post_data)
      .then(response => {
        this.$router.push({ name: 'forgot_password_change_complete' });
      })
      .catch(err => {
        console.log({ err });
        if (post_data.one_time_password.length < 6) {
          this.one_time_password_error = 'ワンタイムパスワードに誤りがあります';
          return;
        }
        if (!err.response) {
          this.one_time_password_error = 'エラーが発生しました';
          return;
        }
        if (
          err.response.status == HTTP_STATUS.UNAUTHORIZED ||
          err.response.status == HTTP_STATUS.NOT_FOUND ||
          err.response.status == HTTP_STATUS.UNPROCESSABLE_ENTITY
        ) {
          this.one_time_password_error = 'ワンタイムパスワードに誤りがあります';
          return;
        }
        if (err.response.status == HTTP_STATUS.TOO_EARLY) {
          this.one_time_password_error = '一時的にロックされています';
          return;
        }
        if (err.response.status == 444) {
          this.one_time_password_error =
            'ワンタイムパスワードの期限が切れています';
          return;
        }
        this.one_time_password_error = 'エラーが発生しました';
      });
  }

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.request_new_password();
    }
  }
}
