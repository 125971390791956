






















import { Component, Vue, Prop } from 'vue-property-decorator';
import IconSvg from '@/components/atoms/icons/IconSvg.vue';

@Component({
  components: {
    IconSvg
  }
})
export default class SearchLabel extends Vue {
  @Prop({ required: true, type: String })
  html: string;
  @Prop({ required: true, type: Object })
  label: {
    keyword: string;
    colorType: string;
    className: string;
    matches: any;
  };

  count: number = 1;

  get countMax(): number {
    if (this.label.matches) {
      return this.label.matches.length;
    }

    return 0;
  }

  get isHit(): boolean {
    return this.label.keyword.length > 0 && this.countMax > 0;
  }

  countUp() {
    if (this.count == this.countMax) {
      this.count = 1;
    } else {
      this.count += 1;
    }

    this.jumpTo();
  }

  countDown() {
    if (this.count == 1) {
      this.count = this.countMax;
    } else {
      this.count -= 1;
    }

    this.jumpTo();
  }

  /**
   * mark.jsでハイライトされた要素に移動する
   */
  jumpTo() {
    this.$emit('jump', {
      className: this.label.className,
      count: this.count
    });
  }
}
