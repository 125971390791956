















































































import { Component, Vue, Emit } from 'vue-property-decorator';
import HeaderLogo from '@/components/atoms/logos/HeaderLogo.vue';
import HeaderSearchBox from '@/components/molecules/search_box/HeaderSearchBox.vue';
import NotificationMenu from '@/components/molecules/NotificationMenu.vue';
import LirisNotificationMenu from '@/components/molecules/LirisNotificationMenu.vue';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';

import AvatarMenu from '@/components/molecules/avatar/AvatarMenu.vue';
import ClickOutside from 'vue-click-outside';
import Documents from '@/store/Documents';
import UserInfo from '../../../store/UserInfo';
import ContractInfo from '@/store/ContractInfo';
import UserInfoModule from '@/store/UserInfo';
import AUTHORITIES from '@/consts/Authorities.js';
import { move_home } from '@/functions/DocumentRouter';
import SearchInfo from '@/store/SearchInfo';

const axios = require('axios').default;

@Component({
  components: {
    HeaderLogo,
    HeaderSearchBox,
    NotificationMenu,
    LirisNotificationMenu,
    TextAvatar,
    AvatarMenu
  },
  directives: {
    ClickOutside
  }
})
export default class HomeHeader extends Vue {
  isDisplaySettingsMenu: boolean = false;
  isDisplayAccountMenu: boolean = false;
  isDisplayNotificationMenu: boolean = false;
  isDisplayLirisNotificationMenu: boolean = false;

  @Emit('changeKeyword')
  changeKeyword(val) {
    return val;
  }

  onClickOutsideAccountMenu() {
    this.isDisplayAccountMenu = false;
  }

  onClickOutsideSettingsMenu() {
    this.isDisplaySettingsMenu = false;
  }

  onClickOutsideNotificationMenu() {
    this.isDisplayNotificationMenu = false;
  }

  onClickOutsideLirisNotificationMenu() {
    this.isDisplayLirisNotificationMenu = false;
  }

  onClickNotificationMenu() {
    this.isDisplayNotificationMenu = !this.isDisplayNotificationMenu;
  }

  onClickLirisNotificationMenu() {
    this.isDisplayLirisNotificationMenu = !this.isDisplayLirisNotificationMenu;
  }

  get notifications() {
    return this.$store.state.documents.notifications;
  }

  get lirisNotifications() {
    return this.$store.state.documents.lirisNotifications;
  }

  public get user_name(): string {
    return UserInfo.user_name;
  }

  public get user_authority(): string {
    return UserInfo.authority;
  }

  public get avatar_color() {
    return UserInfo.avatar.avatar_color;
  }

  public get department_name(): string {
    return UserInfo.department_name;
  }

  get isOverOwner() {
    const authority = UserInfoModule.authority;
    if (
      authority === AUTHORITIES.OWNER ||
      authority === AUTHORITIES.ADMINISTRATOR
    ) {
      return true;
    }
    return false;
  }

  moveToAccountSettings() {
    this.$router.push({ name: 'accounts' });
    this.isDisplaySettingsMenu = false;
  }

  moveToDocumentSettings() {
    this.$router.push({ name: 'control_number_rule' });
    this.isDisplaySettingsMenu = false;
  }

  moveToCoporateSettings() {
    this.$router.push({ name: 'settings_corporation_info' });
    this.isDisplaySettingsMenu = false;
  }

  async logout() {
    // バックエンドにログアウトリクエストを投げてログイン画面に戻す。
    const user_id = UserInfo.user_id;
    await axios.delete(`/user-management/auth?user_id=${user_id}`);

    Vue.cookies.remove('id-token');
    Documents.RESET_SELECTED_TABLE();
    Documents.SET_FOLDERS([]);
    Documents.SET_FILES([]);
    Documents.SET_RECENTLY_VIEWED_FILES([]);
    UserInfo.RESET_PERMISSION();
    ContractInfo.RESET_ASSIGNEE_USER_INFO();
    this.$router.push({ name: 'login' });
  }

  redirect_tayori() {
    window.open(
      'https://tayori.com/faq/b06d57c51f1b3d4af81b4f168f8f1af7dab460fc',
      '_blank',
      'noopener=yes,noreferrer=yes'
    );
  }

  async onLogo() {
    SearchInfo.SET_SEARCHING(false);

    await move_home(this.$router);
  }

  get tooltipContent() {
    let content = this.user_name;
    if (this.department_name) {
      content = this.department_name + '<br>' + content;
    }
    if (this.user_authority) {
      content =
        content +
        '<br><span class="text-xs-w3">' +
        this.user_authority +
        '<\/span>';
    }
    return content;
  }
}
