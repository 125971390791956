



















import { Component, Vue, Prop } from 'vue-property-decorator';
import AppPopup from '@/components/atoms/popups/AppPopup.vue';
import IconReload from '@/components/atoms/icons/IconReload.vue';

@Component({
  components: {
    AppPopup,
    IconReload
  }
})
export default class FileUploadingPopupMessage extends Vue {
  @Prop()
  message: string[];
  @Prop()
  subMessage!: string;
  @Prop({ default: true })
  showButton: boolean;
  @Prop({ default: false })
  showIconReload!: boolean;
  @Prop({ default: '' })
  textButtonLabel!: string;

  closePopup() {
    this.$emit('click');
  }

  clickTextButton() {
    this.$emit('click_text_button');
  }
}
