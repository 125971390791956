








































import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import vUploader from 'vue-upload-component';
import ImageContent from '@/components/atoms/images/ImageContent.vue';
import Documents from '@/store/Documents';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';
import CorporateInfoModule from '@/store/CorporateInfo';
import notify from '@/functions/notify';
import FileUpload from '@/functions/FileUpload';
import NOTIFY_TEXT from '@/consts/NotifyText';

interface Window {
  webkitURL: any;
  URL: any;
  FormData: any;
}

declare let window: Window;

@Component({
  components: {
    vUploader,
    ImageContent
  }
})
export default class UploadMenu extends Vue {
  @Prop({
    default:
      'application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
  })
  accept?: string;

  @Prop()
  isTemplate?: boolean;

  @Prop({ default: false })
  canUploadFolder: boolean;

  url: string = '';
  files: any = [];
  directory: boolean = false;
  headers: object = {};

  get uploaded(): boolean {
    const upload: any = this.$refs.upload;
    return upload ? upload.uploaded : true;
  }

  get uploadFileLimit(): number {
    return CorporateInfoModule.uploadFileLimit;
  }

  mounted() {
    this.set_headers();
    this.files = [];
  }

  @Watch('uploaded')
  set_uploaded_store(newVal: boolean, oldVal: boolean) {
    HomeComponentInfoModule.SET_UPLOADED(newVal);
    HomeComponentInfoModule.SET_UPLOAD_MENU_SWITCH(false);
    if (oldVal == false && newVal == true) {
      // TODO: WatchでAPIコールしない
      // @See: https://github.com/liris-legal/Contract_Lifecycle_Management_Vue/issues/2844
      // NOTE: 契約書アップロード完了時、契約書リストの同期とアップロード上限の再取得をしている
      Documents.getFilesAndFolders();
      CorporateInfoModule.get_service_plan();
    }
  }

  set_headers() {
    this.headers = {
      current_directory: Documents.current_directory
    };
  }

  async custom_upload(file, component) {
    const form = new window.FormData();
    let value;
    this.$emit('selectFiles', this.files.length);
    this.$emit('upload', this.files);
    this.files = [];
  }

  input_file(new_file, old_file) {
    const upload: any = this.$refs.upload;
    if (
      Boolean(new_file) !== Boolean(old_file) ||
      old_file.error !== new_file.error
    ) {
      if (!upload.active) {
        upload.active = true;
      }
    }
  }
  input_filter(new_file, old_file, prevent) {
    if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(new_file.name)) {
      this.$emit('error', new Error(NOTIFY_TEXT.ERROR.NOT_EXIST_UPLOAD_FILE));
      return prevent();
    }
    if (/\.(php5?|html?|jsx?)$/i.test(new_file.name)) {
      this.$emit('error', new Error(NOTIFY_TEXT.ERROR.NOT_EXIST_UPLOAD_FILE));
      return prevent();
    }
    const isCheckFileSize = FileUpload.checkAllowedFileSize(new_file.size);
    if (!isCheckFileSize) return prevent();
    if (new_file && !old_file) {
      if (this.isTemplate && !/\.(docx)$/i.test(new_file.name)) {
        this.$emit(
          'error',
          new Error(NOTIFY_TEXT.ERROR.CANNOT_UPLOAD_DOCX_ONLY)
        );
        return prevent();
      }
      if (!/\.(pdf|docx|doc|xlsx|xls)$/i.test(new_file.name)) {
        return prevent();
      }
    }
    if (new_file && (!old_file || new_file.file !== old_file.file)) {
      new_file.blob = '';
      const URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        new_file.blob = URL.createObjectURL(new_file.file);
      }
    }
  }
  input_folder() {
    this.set_headers();
    const upload: any = this.$refs.upload;
    if (!upload.features.directory) {
      notify.error({
        text: 'お使いのブラウザで、この機能は対応していません。'
      });
      return;
    }
    const input = upload.$el.querySelector('input');
    input.directory = true;
    input.webkitdirectory = true;
    this.directory = true;

    input.onclick = null;
    input.click();
    input.onclick = e => {
      input.directory = false;
      input.webkitdirectory = false;
      this.directory = false;
    };
  }
}
