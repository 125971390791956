






























import { Component, Prop, Vue, Emit, PropSync } from 'vue-property-decorator';
import SelectBox from '@/components/atoms/selectbox/SelectBox.vue';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';

@Component({
  components: {
    SelectBox,
    TextAvatar
  }
})
export default class MultipleSelectBox extends Vue {
  @PropSync('data', { type: Array, default: [] })
  selectedData: any[];

  @Prop({ default: null })
  title?: string;

  @Prop()
  options!: any[];

  @Prop({ default: false })
  searchable?: boolean;

  @Prop({ default: true })
  clearable?: boolean;

  @Prop({ default: false })
  disabled?: boolean;

  @Prop({ default: true })
  closeOnSelect?: boolean;

  @Prop({ default: 'label' })
  label?: string;

  @Prop({ default: null })
  sublabel?: string;

  @Prop({ default: true })
  filterable?: boolean;

  @Prop({ default: false })
  // テキストアバターの表示が必要ならTrue
  haveAvatar?: boolean;

  @Emit()
  input() {
    return;
  }

  resetSelectedData() {
    this.selectedData = [];
  }

  getLabelVal(option) {
    return option[`${this.label}`];
  }

  getSublabelVal(option) {
    if (this.sublabel) {
      return option[`${this.sublabel}`];
    } else {
      return;
    }
  }

  getAvatarName(option) {
    const keys = Object.keys(option);
    if (keys.indexOf('user_name')) {
      return option['user_name'];
    } else {
      return;
    }
  }

  getAvatarColor(option) {
    const keys = Object.keys(option);
    if (keys.indexOf('avatar_color')) {
      return option['avatar_color'];
    } else {
      return;
    }
  }

  get actionMark() {
    return '';
  }
}
