


























































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import BaseTable from '@/components/molecules/Table/BaseTable.vue';
import ObjectTableBodyRow from '@/components/organisms/Trash/ObjectTableBodyRow.vue';
import Documents from '@/store/Documents';
import UserInfo from '@/store/UserInfo';
import AUTHORITIES from '@/consts/Authorities.js';
import SettingsInfoModule from '@/store/SettingsInfo';
import { sortDocuments } from '@/functions/Sort';

@Component({
  components: {
    ObjectTableBodyRow,
    BaseTable
  }
})
export default class TrashTable extends Vue {
  @Prop({ default: '' })
  selectedTab!: string;
  @Prop({ default: [] })
  objects!: any;
  @Prop({ default: false })
  concluded: boolean;
  @Prop()
  canOpenDeleteWindow: boolean;
  @Prop()
  isContractedDocumentTabSelected: boolean;

  select_all: boolean = false;
  selected: any[] = [];
  filesAndFolders: any = this.objects;

  @Watch('selectedTab')
  reset() {
    this.select_all = false;
    this.selected = [];
  }

  get sort(): {
    columnName: string;
    asc: boolean;
  } {
    return SettingsInfoModule.sort[this.$route.name];
  }

  private get exsistsFolder(): boolean {
    return (
      this.objects.filter(({ directory_name }) => {
        return directory_name !== null;
      }).length > 0
    );
  }

  allCheck() {
    this.selected = [];
    if (!this.select_all) {
      for (const i in this.objects) {
        const item = this.objects[i];
        if (Object.keys(item).length) {
          this.selected.push(item);
        }
      }
    }
  }

  get isGuest() {
    return UserInfo.authority === AUTHORITIES.GUEST;
  }

  get selected_trash_files_of_table(): any {
    return Documents.selected_trash_files_of_table;
  }

  get selected_trash_folders_of_table(): any {
    return Documents.selected_trash_folders_of_table;
  }

  get is_trash_recovor() {
    return Documents.is_trash_recovor;
  }

  @Watch('canOpenDeleteWindow')
  update_can_open_delete_window(new_value) {
    if (new_value) {
      return;
    }
    this.selected = this.selected_trash_files_of_table.concat(
      this.selected_trash_folders_of_table
    );
  }

  @Watch('is_trash_recovor')
  update_is_trash_recovor(new_value) {
    if (new_value) {
      this.selected = [];
      Documents.SET_IS_TRASH_RECOVOR(false);
    }
  }

  @Watch('selected')
  update_selected(val) {
    const num_selected = val.length;
    const num_item = this.objects.length;
    if (num_selected != num_item) {
      this.select_all = false;
    } else {
      this.select_all = true;
    }

    const files = this.selected.filter(x => 'contract_id' in x);
    const folders = this.selected.filter(x => 'directory_path' in x);
    Documents.SET_SELECTED_TRASH_FILES_OF_TABLE(files);
    Documents.SET_SELECTED_TRASH_FOLDERS_OF_TABLE(folders);
  }

  @Watch('$route.query.dir')
  changeCurrentDirectory() {
    this.selected = [];
  }

  @Watch('objects', { deep: true })
  setDocuments() {
    this.filesAndFolders = this.objects;
    this.onSort();
  }

  beforeCreate() {
    // デフォルトは「相手方」で「昇順」
    if (!SettingsInfoModule.sort[this.$route.name]) {
      SettingsInfoModule.SET_SORT({
        pageName: this.$route.name,
        columnName: 'counterparty_name_furigana'
      });
    }
  }

  mounted() {
    this.$store.subscribe(mutation => {
      if (mutation.type === 'documents/SET_TRASHES') this.selected = [];
    });
    this.onSort();
  }

  moveDirectory(params) {
    this.reset();
    this.$emit('moveDirectory', params);
  }

  setSort(columnName: string) {
    SettingsInfoModule.SET_SORT({ pageName: this.$route.name, columnName });
    this.onSort();
  }

  onSort() {
    this.filesAndFolders = sortDocuments(
      this.filesAndFolders,
      'trash_directory_name',
      'trash_directory_name',
      this.sort.columnName,
      this.sort.asc
    );
  }
}
