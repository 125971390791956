import Documents from '@/store/Documents';
import VueRouter from 'vue-router';

import HTTP_STATUS from '@/consts/HttpStatus';

async function change_folder(router: VueRouter, path: string) {
  Documents.SET_CURRENT_DIRECTORY(path);
  await Documents.getDirectoryInfo(path);
  const current_path = router.currentRoute.path;
  if (current_path !== `/home/${encodeURIComponent(Documents.directory_uri)}`) {
    return router.push({
      name: 'home',
      params: { id: Documents.directory_uri }
    });
  }
  return Promise.resolve();
}

async function move_home(
  router: VueRouter,
  path: string = '/home/contracted_document'
) {
  Documents.SET_CURRENT_DIRECTORY('/');
  router
    .push({
      path: path,
      query: {}
    })
    .catch(err => {});
  return Promise.resolve();
}

function historyBackOrHome(
  router: VueRouter,
  route: any,
  isSigned: boolean,
  backToPath: string = null
): void {
  if (backToPath.includes('/searching') && window.history.length > 2) {
    window.history.back();
  } else if (backToPath.includes('/gmosign')) {
    router.push({
      name: 'contract_management',
      query: {}
    });
  } else if (backToPath && backToPath != route.path) {
    router.push({
      path: backToPath,
      query: {}
    });
  } else if (window.history.length > 2) {
    window.history.back();
  } else {
    if (isSigned) {
      move_home(router);
    } else {
      move_home(router, '/contract-management');
    }
  }
}

function catchOnGetContractDetail(router: VueRouter, error) {
  if (
    error.response &&
    (error.response.status === HTTP_STATUS.FORBIDDEN ||
      error.response.status === HTTP_STATUS.CONFLICT)
  ) {
    router.push({
      name: 'not_found'
    });
  }
}

export {
  move_home,
  change_folder,
  historyBackOrHome,
  catchOnGetContractDetail
};
