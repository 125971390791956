



























































































































import { Component, Vue, Emit } from 'vue-property-decorator';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import SignedUploadModal from '@/components/molecules/modal_windows/SignedUploadModal.vue';
import TextOnlyWindow from '@/components/molecules/modal_windows/TextOnlyWindow.vue';
import DocumentMoveWindowForContractManagement from '@/components/molecules/modal_windows/DocumentMoveWindowForContractManagement.vue';

import Documents from '@/store/Documents';
import ContractInfo from '@/store/ContractInfo';
import UserInfo from '@/store/UserInfo';
import GMOSignInfoModule from '@/store/GMOSign';
import HomeComponentInfoModule from '@/store/HomeComponentInfo';

import vLoading from 'vue-loading-overlay';

import HTTP_STATUS from '@/consts/HttpStatus';
import CONTRACT_MANAGEMENT_UTIL from '@/consts/ContractManagementUtil';
import NOTIFY_TEXT from '@/consts/NotifyText';
import WINDOW_MESSAGES from '@/consts/TextWindowMessage';

import MoveObject from '@/functions/MoveObject';
import notify from '@/functions/notify';
import { catchOnGetContractDetail } from '@/functions/DocumentRouter';

import { RepositoryFactory } from '@/repositories/RepositoryFactory';

const DocumentRepository = RepositoryFactory.get('documents');
const ContractInfoRepository = RepositoryFactory.get('contractInfos');

@Component({
  components: {
    AppButton,
    SignedUploadModal,
    TextOnlyWindow,
    vLoading,
    DocumentMoveWindowForContractManagement
  }
})
export default class ProgressBar extends Vue {
  isShowingSignedUploadModal = false;
  isLoading: boolean = false;
  showDeleteGMOSignModal: boolean = false;
  deleteGMOSignModalDescription: string = WINDOW_MESSAGES.DELETE_GMOSIGN;
  isButtonDisabled: boolean = false;

  folders: any = [];
  folderMoving: boolean = false;
  isOpenConfirmObjectMove: boolean = false;

  get permission() {
    return UserInfo.permission;
  }

  get contractDetail() {
    return Documents.contract_detail;
  }

  get kanbanCardInfo() {
    return this.contractDetail.kanban_card_info;
  }

  get columns() {
    return ContractInfo.sortedContractBoardColumnsData;
  }

  get useGMOSign() {
    if (this.contractDetail.gmosign_info.xid) {
      return true;
    }
    return false;
  }

  get activeColumn() {
    const targetColumn = this.columns.find(
      c => this.kanbanCardInfo.column_id === c.column_id
    );
    if (targetColumn) return targetColumn;

    // データが未取得の場合以下のデフォルト値が変えるようにする
    return {
      column_order: CONTRACT_MANAGEMENT_UTIL.COLUMN_ORDER.NONE,
      is_finish_column: false,
      column_name: ''
    };
  }

  get previousColumn() {
    if (
      this.activeColumn.column_order ===
      CONTRACT_MANAGEMENT_UTIL.COLUMN_ORDER.NONE
    ) {
      return null;
    }
    const activeIndex = this.columns.findIndex(
      c => this.kanbanCardInfo.column_id === c.column_id
    );
    return this.columns[activeIndex - 1];
  }

  get nextColumn() {
    if (
      this.activeColumn.column_order ===
      CONTRACT_MANAGEMENT_UTIL.COLUMN_ORDER.NONE
    ) {
      return null;
    }
    const activeIndex = this.columns.findIndex(
      c => this.kanbanCardInfo.column_id === c.column_id
    );
    return this.columns[activeIndex + 1];
  }

  get isFirstColumns(): boolean {
    return (
      this.activeColumn.column_order ===
      CONTRACT_MANAGEMENT_UTIL.COLUMN_ORDER.CREATE_OR_CONFIRM_REQUEST
    );
  }

  get isLastColumns(): boolean {
    return this.activeColumn.is_finish_column;
  }

  get isApprovale(): boolean {
    return (
      this.activeColumn &&
      ContractInfo.neededSignedFileColumn &&
      this.activeColumn.column_order >=
        ContractInfo.neededSignedFileColumn.column_order
    );
  }

  async updateStatus(status) {
    let res = null;
    const currentColumnName = this.activeColumn.column_name;
    const currentColumnIndex = this.columns.findIndex(
      x => x.column_name === currentColumnName
    );
    let columnName = '';
    let columnId = '';

    this.isButtonDisabled = true;

    if (status === 'pre') {
      if (this.useGMOSign) {
        this.showDeleteGMOSignModal = true;
        return;
      }
      columnName = this.columns[currentColumnIndex - 1].column_name;
      columnId = this.columns[currentColumnIndex - 1].column_id;
      this.kanbanCardInfo.column_id = columnId;
      this.kanbanCardInfo.column_name = columnName;
      res = await ContractInfoRepository.cardPreStatus(
        this.kanbanCardInfo.card_id
      )
        .catch(e => {
          return e;
        })
        .finally(() => {
          this.isButtonDisabled = false;
        });
    }

    if (status === 'next') {
      columnName = this.columns[currentColumnIndex + 1].column_name;
      columnId = this.columns[currentColumnIndex + 1].column_id;
      this.kanbanCardInfo.column_id = columnId;
      this.kanbanCardInfo.column_name = columnName;
      res = await ContractInfoRepository.cardNextStatus(
        this.kanbanCardInfo.card_id
      )
        .catch(e => {
          return e;
        })
        .finally(() => {
          this.isButtonDisabled = false;
        });
    }

    if (res instanceof Error) {
      notify.error({
        text: `${this.contractDetail.contract_info.contract_title}を${columnName}に移動できませんでした`
      });
      this.isButtonDisabled = false;
      return;
    }

    if (res.status === HTTP_STATUS.FORBIDDEN) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.NOT_ALLOWED.MOVE_CARD
      });
      this.isButtonDisabled = false;
      return;
    }

    if (res.status !== HTTP_STATUS.NO_CONTENT) {
      notify.error({
        text: `${this.contractDetail.contract_info.contract_title}を${columnName}に移動できませんでした`
      });
      this.isButtonDisabled = false;
      return;
    }

    if (this.contractDetail.is_signed) {
      notify.success({
        text: `${this.contractDetail.contract_info.contract_title} ${NOTIFY_TEXT.SUCCESS.MOVED_SIGNE_DOCUMENT}`
      });
      this.isButtonDisabled = false;
    }

    await Documents.getContractDetail(
      this.contractDetail.contract_info.contract_id
    )
      .catch(err => {
        catchOnGetContractDetail(this.$router, err);
      })
      .finally(() => {
        this.isButtonDisabled = false;
      });

    if (status === 'pre' && this.isApprovale) {
      await Documents.SELECT_VERSION(
        this.contractDetail.contract_version_list[0]
      );
    }
    this.isButtonDisabled = false;
  }

  async cancelFinishedContractCardStatus() {
    this.isLoading = true;
    notify.clearSuccess();
    const res = await this.exeUpdateCardStatusApi({
      card_id: this.kanbanCardInfo.card_id,
      destination_column_id: null,
      is_finished: false
    });
    await Documents.reloadContractDetail().catch(err => {
      catchOnGetContractDetail(this.$router, err);
    });
    this.isLoading = false;
    if (res instanceof Error) {
      notify.error({
        text: NOTIFY_TEXT.ERROR.CANCEL
      });
      return;
    }
    notify.success({
      text: NOTIFY_TEXT.SUCCESS.CANCEL
    });
  }

  async lastVersionUpload(params) {
    this.isShowingSignedUploadModal = false;

    this.startUpload();

    const formData = new FormData();
    formData.append('contract', params.file);

    const apiParams = {
      contract_id: this.contractDetail.contract_info.contract_id,
      is_last_version: true,
      is_stored: params.is_stored,
      formData
    };

    await DocumentRepository.uploadNewVersion(apiParams)
      .then(res => {
        if (res.status === HTTP_STATUS.FORBIDDEN) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UNAUTHORIZED
          });
        }

        if (res.status !== HTTP_STATUS.NO_CONTENT) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
          });
        }
        return;
      })
      .catch(e => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.UPLOAD_FILE
        });
        return;
      })
      .finally(async () => {
        notify.clearSuccess();
        await Documents.reloadContractDetail().catch(err => {
          catchOnGetContractDetail(this.$router, err);
        });
      });

    const version_list = this.contractDetail.contract_version_list;
    if (version_list.length) {
      await Documents.selectVersion(version_list[0]);
    }
    this.changeVersion();
    this.endUpload();
    return;
  }

  @Emit()
  endUpload() {
    return;
  }

  @Emit()
  startUpload() {
    return;
  }

  @Emit()
  changeVersion() {
    return;
  }

  /**
   * カードステータス更新API実行
   */
  async exeUpdateCardStatusApi({
    card_id,
    destination_column_id,
    is_finished
  }) {
    return await ContractInfoRepository.updateContractCardStatus({
      card_id,
      destination_column_id,
      is_finished
    }).catch(e => {
      return e;
    });
  }

  async clickDeleteGMOSign() {
    const contract_id = this.contractDetail.contract_info.contract_id;
    await GMOSignInfoModule.deleteDocument(contract_id)
      .then(() => {
        Documents.DELETE_GMOSIGN();
        notify.success({ text: NOTIFY_TEXT.SUCCESS.GMOSIGN.DELETEDOCUMENT });
      })
      .catch(() => {
        notify.error({ text: NOTIFY_TEXT.ERROR.GMOSIGN.DELETEDOCUMENT });
      });
    this.showDeleteGMOSignModal = false;
    await this.updateStatus('pre');
  }

  // 完了後のファイル移動
  async get_folders(path: string) {
    await DocumentRepository.getDirectoryList(path)
      .then(res => {
        if (res.status !== HTTP_STATUS.OK) {
          notify.error({
            text: NOTIFY_TEXT.ERROR.GENERAL
          });
          return;
        }

        this.folders = res.data;
      })
      .catch(err => {
        notify.error({
          text: NOTIFY_TEXT.ERROR.GENERAL
        });
      });
  }

  get selectedFolderPathList() {
    const selected_folder_path_list = Documents.selected_folders_of_table.map(
      function(data) {
        return data.directory_path;
      }
    );
    return selected_folder_path_list;
  }

  async checkViewPermissionWhenMoveObject() {
    this.isOpenConfirmObjectMove = true;
  }

  get currentDirectory() {
    return Documents.current_directory;
  }

  get moveSelectedObjectsWindow(): boolean {
    if (
      HomeComponentInfoModule.move_selected_objects_window_for_contract_management
    ) {
      this.get_folders(this.currentDirectory);
    } else {
      this.folders = [];
    }
    return HomeComponentInfoModule.move_selected_objects_window_for_contract_management;
  }

  get windowMessageConfirmMove() {
    return WINDOW_MESSAGES.CONFIRM_MOVE_FOR_CONTRACT_MANAGEMENT;
  }

  async moveObject(): Promise<void> {
    this.isOpenConfirmObjectMove = false;
    this.folderMoving = true;

    this.isLoading = true;

    const res = await this.exeUpdateCardStatusApi({
      card_id: this.kanbanCardInfo.card_id,
      destination_column_id: null,
      is_finished: true
    });

    await MoveObject.moveFileOrFolder(false);
    this.folderMoving = false;

    await Documents.reloadContractDetail().catch(err => {
      catchOnGetContractDetail(this.$router, err);
    });
    this.isLoading = false;

    notify.success({
      text: `${this.contractDetail.contract_info.contract_title} ${NOTIFY_TEXT.SUCCESS.MOVED_SIGNE_DOCUMENT}`,
      data: {
        cancelMethod: this.cancelFinishedContractCardStatus
      }
    });
    this.cancelMove();
  }

  selectedPath(path) {
    HomeComponentInfoModule.SET_MOVE_TO_PATH(path);
  }

  changePath(path) {
    this.folders = [];
    this.get_folders(path);
  }

  cancelMove() {
    HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW_FOR_CONTRACT_MANAGEMENT(
      false
    );
  }

  onFinishCard() {
    Documents.SET_SELECTED_FILES_OF_TABLE([this.contractDetail.contract_info]);
    this.setMoveDialog();
  }

  setMoveDialog() {
    HomeComponentInfoModule.SET_MOVE_SELECTED_OBJECTS_WINDOW_FOR_CONTRACT_MANAGEMENT(
      true
    );
  }
}
