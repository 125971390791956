




















import { Component, Vue } from 'vue-property-decorator';
import FormTitle from '@/components/atoms/texts/FormTitle.vue';
import CheckCircle from '@/components/atoms/images/CheckCircle.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';
import Icon from '@/components/atoms/icons/Icon.vue';
import KEY_CODE from '@/consts/KeyCode';

@Component({
  components: {
    FormTitle,
    Icon,
    AppButton,
    CheckCircle
  },

  directives: {
    focus: {
      inserted(el) {
        el.focus();
      }
    }
  }
})
export default class ForgotPasswordChangeComplete extends Vue {
  icon_class: string[] = ['far', 'check-circle'];

  trigger(event) {
    if (event.keyCode === KEY_CODE.ENTER) {
      this.next_page();
    }
  }

  next_page() {
    this.$router.push({ name: 'login' });
  }
}
