



























































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import TextAvatar from '@/components/atoms/avatar/TextAvatar.vue';
import AppButton from '@/components/atoms/buttons/AppButton.vue';

import Documents, {
  EditCommentItem,
  MentionUser,
  UserInfo
} from '@/store/Documents';

import ClickOutside from 'vue-click-outside';
import { Mentionable } from 'vue-mention';

Mentionable.methods.onKeyDown = function(e) {
  if (this.key) {
    if (e.key === 'ArrowDown' || e.keyCode === 40) {
      this.selectedIndex++;
      if (this.selectedIndex >= this.displayedItems.length) {
        this.selectedIndex = 0;
      }
      this.cancelEvent(e);
    }
    if (e.key === 'ArrowUp' || e.keyCode === 38) {
      this.selectedIndex--;
      if (this.selectedIndex < 0) {
        this.selectedIndex = this.displayedItems.length - 1;
      }
      this.cancelEvent(e);
    }
    if (
      (e.keyCode === 13 || e.keyCode === 9) &&
      this.displayedItems.length > 0
    ) {
      this.applyMention(this.selectedIndex);
      this.cancelEvent(e);
    }
    if (e.key === 'Escape' || e.keyCode === 27) {
      this.closeMenu();
      this.cancelEvent(e);
    }
  }
};

@Component({
  components: {
    TextAvatar,
    AppButton,
    Mentionable
  },
  directives: {
    ClickOutside
  }
})
export default class AvatarCommentInput extends Vue {
  @Prop({ default: true })
  is_display_button: boolean;

  @Prop({ default: true })
  is_display_avatar: boolean;

  @Prop({ default: '' })
  user_name: string;

  @Prop({ default: '' })
  avatar_color: string;

  @Prop()
  edit_comment_item: EditCommentItem;

  @Prop({ default: false })
  is_edit: boolean;

  @Prop({ default: 500 })
  max_length: number;

  comment: string = '';
  is_expansion: boolean = false;
  submitting: boolean = false;
  text_length: number = 0;

  @Watch('is_expansion')
  chage_is_expansion(val) {
    if (!val) {
      setTimeout(() => {
        this.comment = '';
        this.$emit('cancel');
      }, 500);
    }
  }

  @Watch('is_edit')
  update_comment(val) {
    const regex = new RegExp('@\{(.*?)?\}', 'g');
    const comment = this.edit_comment_item.comment_info.comment;
    this.comment = comment.replace(regex, '@$1');
    this.focus();
    this.calcCommentLength();
  }

  @Watch('remaining_text')
  changeRemainingText(val) {
    this.$emit('remainingText', val);
  }

  get remaining_text() {
    return this.max_length - this.text_length;
  }

  get mention_users(): UserInfo[] {
    const users = Documents.mention_users.map(user => {
      user.value = user.user_name;
      return user;
    });
    // user_nameが長い順にsort（user_idに変換する時のために）
    const sortedUsers = users.slice().sort((a, b) => {
      return b.user_name.length - a.user_name.length;
    });
    return sortedUsers;
  }

  convertUserNameToId(text: string) {
    let comment_for_save: string = escape(text);
    this.mention_users.forEach(user => {
      const escapedStr: string = escape('@' + user.user_name);
      const regex = new RegExp(escapedStr, 'g');
      comment_for_save = comment_for_save.replace(
        regex,
        '@{' + user.user_id + '}'
      );
    });
    return unescape(comment_for_save);
  }

  focus() {
    this.is_expansion = true;
  }

  /**
   * キャンセルボタン押下時・コメント送信完了時の動作
   */
  async cancel() {
    this.comment = '';
    this.text_length = 0;
    this.is_expansion = false;
    this.submitting = false;
  }

  /**
   * コメント送信処理
   */
  async submit() {
    if (this.submitting) return;
    this.submitting = true;
    // FIXME: API update - サーバーサイドにselectedUsersを渡さなくても良いようにする
    const selctedUsers: MentionUser[] = [];
    const comment_for_save = await this.convertUserNameToId(this.comment);
    const params = {
      comment: comment_for_save,
      selctedUsers
    };
    await this.$emit('submitComment', params);
    this.cancel();
  }

  calcCommentLength() {
    if (!this.comment) return;
    // 改行が <div><br></div> と変換され innerText で取得すると改行2つになるため1つに変換してカウント
    this.text_length = this.comment.replace(/\n\n/g, '\n').length;
  }

  replaceZenkakuAt() {
    this.comment = this.comment.replace('＠', '@');
  }

  inputCommentProcess() {
    this.replaceZenkakuAt();
    this.calcCommentLength();
  }
}
