













import { Component, Prop, Vue } from 'vue-property-decorator';
import Label from '@/components/atoms/labels/Label.vue';
import { ContractLabel } from '@/store/SettingsInfo';

@Component({
  components: {
    Label
  }
})
export default class ContractLabels extends Vue {
  @Prop()
  labels: ContractLabel[];
}
